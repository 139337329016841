import React from 'react';

export const YlmCheckbox = ({ text, value, onChange }) => {
  return (
    <div 
        className={`ylm-checkbox ${value && 'active'}`}
        onClick={ onChange }
    >
        <div className='checkbox-item'></div>
        <p>{ text }</p>
    </div>
  )
}
