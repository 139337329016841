import { useCallback, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';
import useClickOutside from '../../../../hooks/useClickOutside';

export const SelectCategoria = ({ objServicio, dataChange, big = false, top = false, left = false }) => {
    const { categorias } = useSelector(state => state.categorias);

    const [selectText, setSelectText] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        if (categorias.length > 0) {
            if (objServicio?.categorias) {
                let seleccionadas = 0;
                categorias.forEach(categoria => {
                    if (objServicio.categorias.includes(categoria._id) || categoria.deleted) {
                        seleccionadas++;
                    }
                });
                if (seleccionadas === 0) {
                    setSelectText('Ninguna');
                } else if (seleccionadas === categorias.length) {
                    setSelectText('Todas');
                } else {
                    setSelectText('Varias');
                }
            }
        } else {
            setSelectText('No existen');
        }
    }, [objServicio, categorias]);


    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle(!isOpen);
    }

    const clickCategoria = (idCategoria) => {
        let newCategorias = [];

        if (!objServicio.categorias) {
            objServicio.categorias = [];
        }

        if (objServicio.categorias.includes(idCategoria)) {
            newCategorias = objServicio.categorias.filter(suc => {
                return suc !== idCategoria;
            });
        } else {
            newCategorias = [
                ...objServicio.categorias,
                idCategoria
            ]
        }
        dataChange('categorias', newCategorias);
    }

    return (
        <div
            className='ylm-sucursal-select'
        >
            <div
                className='select-main'
                onClick={clickSelect}
            >
                <p>{selectText}</p>
                <ChevronDownIcon color={'#000000'} />
            </div>
            {
                isOpen &&
                <ul
                    className={`select-options std-ul ${big && 'big'} ${top && 'top'} ${left && 'left'}`}
                    ref={popover}
                >
                    {
                        categorias.map(categoria => (
                            !categoria.deleted &&
                            <li
                                key={categoria._id}
                                className={`std-li ${objServicio?.categorias?.includes(categoria._id) ? 'active' : ''}`}
                                onClick={() => clickCategoria(categoria._id)}
                            >
                                <div className='checkbox-bg'>
                                    <span className='checkbox-item'></span>
                                </div>
                                <p>{categoria.nombre}</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
