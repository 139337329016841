import React from 'react';
import { useSelector } from 'react-redux';
import { YlmRadio } from '../../../components/forms/YlmRadio';

// eslint-disable-next-line no-empty-character-class
const rx_live = /^[+-]?\d*(?:[]\d*)?$/;

export const Reservas = ({objEmpresa, setObjEmpresa, cambiado, guardar, cancelar}) => {
  const { colores } = useSelector(state => state.ui);

  const changeFranja = ( newTime ) => {
    // console.log( objEmpresa );
    setObjEmpresa({ ...objEmpresa, intervaloTiempo: newTime });
  }

  const horasAntesChange = ( value ) => {
    if( rx_live.test(value) && value.length < 3)
      setObjEmpresa({ ...objEmpresa, horasCancelacion: value });
  }

  const cancelacionChange = ( value ) => {
    setObjEmpresa({ ...objEmpresa, retornoApp: value });
  }
  
  const recogidaChange = ( value ) => {
    if( rx_live.test(value) && value.length < 3)
      setObjEmpresa({ ...objEmpresa, diasRecogida: value });
  }

  const diasPrepagoChange = ( value ) => {
    if( rx_live.test(value) && value.length < 3)
      setObjEmpresa({ ...objEmpresa, diasPrepago: value });
  }

  return (
    <div className='configuracion-wrapper'>
      <div className='header'>
        <h1 style={{color: colores.colorPrincipal}}>Reservas</h1>
        {
          cambiado &&
          <div className='botonera'>
            <button 
              className='btn btn-primary'
              style={{ color: colores.color6, backgroundColor: colores.colorPrincipal }}
              onClick={ guardar }
            >
              Aplicar
            </button>
            <button 
              className='btn btn-secondary'
              style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
              onClick={ cancelar }
            >
              Cancelar
            </button>
          </div>
        }
      </div>
      <div className='section franja'>
        <h3 style={{ color: colores.colorSecundario }}>Dividir franja horaria</h3>
        <h5>¿En qué unidades de tiempo (minutos) quieres que se muestre el tiempo disponible?</h5>
        <div className='franja-items'>
          <YlmRadio
            active={ objEmpresa.intervaloTiempo === '5' }
            clickFunction={ () => changeFranja('5') }
            text={`5'`}
            color={ colores.colorPrincipal }
            bgColor={ colores.colorSecundario }
          />
          <YlmRadio
            active={ objEmpresa.intervaloTiempo === '10' }
            clickFunction={ () => changeFranja('10') }
            text={`10'`}
            color={ colores.colorPrincipal }
            bgColor={ colores.colorSecundario }
          />
          <YlmRadio
            active={ objEmpresa.intervaloTiempo === '15' }
            clickFunction={ () => changeFranja('15') }
            text={`15'`}
            color={ colores.colorPrincipal }
            bgColor={ colores.colorSecundario }
          />
          <YlmRadio
            active={ objEmpresa.intervaloTiempo === '20' }
            clickFunction={ () => changeFranja('20') }
            text={`20'`}
            color={ colores.colorPrincipal }
            bgColor={ colores.colorSecundario }
          />
          <YlmRadio
            active={ objEmpresa.intervaloTiempo === '30' }
            clickFunction={ () => changeFranja('30') }
            text={`30'`}
            color={ colores.colorPrincipal }
            bgColor={ colores.colorSecundario }
          />
          <YlmRadio
            active={ objEmpresa.intervaloTiempo === '60' }
            clickFunction={ () => changeFranja('60') }
            text={`60'`}
            color={ colores.colorPrincipal }
            bgColor={ colores.colorSecundario }
          />
        </div>
      </div>
      {/* <div className='section horario'>
        <h3>Horario agenda</h3>
        <h5>El horario de tu agenda de reservas se rige por una de estas dos opciones:</h5>
        <div className='horario-items'>
          <div 
            className={`ylm-radio ${horario === 'profesionales' && 'active'}`}
            onClick={ () => setHorario('profesionales') }
          >
            <div className='radio-item'></div>
            <p>Horario profesionales</p>
          </div>
          <div 
            className={`ylm-radio ${horario === 'sucursales' && 'active'}`}
            onClick={ () => setHorario('sucursales') }
          >
            <div className='radio-item'></div>
            <p>Horario sucursales</p>
          </div>
        </div>
      </div> */}
      <div className='section cancelacion'>
        <h3 style={{ color: colores.colorSecundario }}>Cancelación reserva</h3>
        <h5>Tus clientes podrán cancelar una reserva hasta 
          <input 
            type='text'
            pattern="[+-]?\d+(?:[.,]\d+)?"
            value={ objEmpresa.horasCancelacion }
            onChange= { (e) => horasAntesChange( e.target.value )}
          />
          horas antes</h5>
        <h5>En caso de cancelación de reserva en pagos por adelantado, se devolverá el importe a través de:</h5>
        <div className='cancelacion-items'>
          <YlmRadio
            active={ objEmpresa.retornoApp }
            clickFunction={ () => cancelacionChange( true ) }
            text={`App (quedará el importe almacenado en la cuenta de usuario)`}
            color={ colores.colorPrincipal }
            bgColor={ colores.colorSecundario }
          />
          <YlmRadio
            active={ !objEmpresa.retornoApp }
            clickFunction={ () => cancelacionChange( false ) }
            text={`Forma de pago`}
            color={ colores.colorPrincipal }
            bgColor={ colores.colorSecundario }
          />
        </div>
      </div>
      <div className='section productos'>
        <h3 style={{ color: colores.colorSecundario }}>Productos</h3>
        <h5>Tus clientes tendrán un margen de 
          <input 
            type='text'
            pattern="[+-]?\d+(?:[.,]\d+)?"
            style={{ backgroundColor: colores.color6 }}
            value={ objEmpresa.diasRecogida }
            onChange= { (e) => recogidaChange( e.target.value )}
          />
           días para recoger su producto. Pasados esos días, el producto volverá a su venta.</h5>
      </div>
      <div className='section prepago'>
        <h3 style={{ color: colores.colorSecundario }}>Prepago</h3>
        <h5>Tus clientes deberán pagar la reserva si la realizan con más de  
          <input 
            type='text'
            pattern="[+-]?\d+(?:[.,]\d+)?"
            style={{ backgroundColor: colores.color6 }}
            value={ objEmpresa.diasPrepago }
            onChange= { (e) => diasPrepagoChange( e.target.value )}
          />
           días de antelación.</h5>
      </div>
    </div>
  )
}
