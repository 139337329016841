import React, { useEffect, useState } from 'react'
import { ProfesionalAvatar } from '../../../assets/svg/iconos'

export const AvatarProfesional = ({prof, clase}) => {

    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        //console.log( 'profesional', profesional );
        if( prof.img.length > 0 ){
            getImagenPrincipal( `${prof.img[0]}` );
        }
    }, [prof]);

    
    const getImagenPrincipal = async( imagen ) => {
        setImageUrl( { url: imagen } );
    }

    return (
        <div 
            key={ prof._id } 
            className={ clase }
            title={ prof.nombre }
            style={{ 
                backgroundImage: `${imageUrl.url ? 'url('+ imageUrl.url +'?'+ imageUrl.url +')' : 'none'}`,
                borderColor: `${ prof.color }`
            }}
        >
            {
                !imageUrl.url &&
                <ProfesionalAvatar />
            }
        </div>
    )
}
