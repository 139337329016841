
import { types } from "../types/types";

const initialState = {
    variantes: [],
    active: null
}

export const variantesReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.variantesActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.variantesUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.variantesAddNew:
            return {
                ...state,
                variantes: [ ...state.variantes, action.payload ]
            }

        case types.variantesLoad:
            return {
                ...state,
                variantes: [ ...action.payload ]
            }
        
        case types.variantesUpdated:
            return {
                ...state,
                variantes: state.variantes.map(
                    variante => variante._id === action.payload._id
                        ? action.payload
                        : variante
                )
            }

        case types.variantesDelete:
            return {
                ...state,
                active: null,
                variantes: state.variantes.filter( variante => variante._id !== action.payload )
            }
    
        case types.variantesLogoutCleaning:
            return {
                ...state,
                active: null,
                variantes: []
            }
    

        default:
            return state;
    }
}