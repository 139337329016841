
import { types } from "../types/types";

const initialState = {
    usuarios: [],
    total: 0,
    active: null
}

export const usuariosReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.usuariosActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.usuariosUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.usuariosAddNew:
            return {
                ...state,
                usuarios: [ ...state.usuarios, action.payload ]
            }

        case types.usuariosLoad:
            return {
                ...state,
                usuarios: [ ...action.payload.usuarios ],
                total: action.payload.total
            }
        
        case types.usuariosUpdated:
            return {
                ...state,
                usuarios: state.usuarios.map(
                    usuario => usuario._id === action.payload._id
                        ? action.payload
                        : usuario
                )
            }

        case types.usuariosDelete:
            return {
                ...state,
                active: null,
                usuarios: state.usuarios.filter( usuario => usuario._id !== action.payload )
            }
    
        case types.usuariosLogoutCleaning:
            return {
                ...state,
                active: null,
                usuarios: []
            }
    

        default:
            return state;
    }
}