import Swal from 'sweetalert2';

import { types } from "../types/types";
import { fetchConToken, archivoConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewPedido = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'pedidos', values, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                /* const datos = {
                    title: 'Creado',
                    text: [ values.nombre ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) ); */
                const datos = {
                    text: [ `El pedido ha sido creado` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewPedido( body.pedido._id, body.pedido ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activePedido = ( _id, pedido ) => ({
    type: types.pedidosActive,
    payload: {
        _id,
        ...pedido
    }
});

export const unactivePedido = () => ({
    type: types.pedidosUnactive
});

export const addNewPedido = ( _id, pedido ) => ({
    type: types.pedidosAddNew,
    payload: {
        _id,
        ...pedido
    }
})

export const startLoadingPedido = ( id, empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `pedidos/pedido/${ id }`, {empresa}, 'POST' );
            const body = await resp.json();
            if( body.pedido ){
                dispatch( activePedido( body.pedido._id, body.pedido ));
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingPedidos = ( empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `pedidos/empresa/${empresa}` );
            const body = await resp.json();
            dispatch( setPedidos( body.pedidos, body.total, body.avisos ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingPedidosPendientes = ( empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `pedidos/pendientes/${empresa}` );
            const body = await resp.json();
            dispatch( setPedidos( body.pedidos, body.total, body.avisos ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setPedidos = ( pedidos, total, avisos ) => ({
    type: types.pedidosLoad,
    payload: {pedidos, total, avisos}
})

export const startSavePedido = ( pedido, swal = true ) => {
    return async( dispatch, getState ) => {
        
        try {
            const resp = await fetchConToken( `pedidos/${ pedido._id }`, pedido, 'PUT' );
            const body = await resp.json();
            if ( body.ok ) {
                //dispatch( refreshPedido( pedido._id, body.pedido ) );
                dispatch( startLoadingPedidosPendientes( pedido.empresa ) );
                const datos = {
                    text: [ `Pedido guardado` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            } else {
                console.log( body );
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshPedido = ( id, pedido ) => ({
    type: types.pedidosUpdated,
    payload: {
        ...pedido
    }
});

export const startUploading = ( archivo, pedido, nombre, previa ) => {

    return async ( dispatch, getState ) => {
        try {           
            const formData = new FormData();
            console.log( archivo );
            formData.append('archivo', archivo);
            formData.append('empresa', pedido.empresa);
            formData.append('nombre', nombre);
            formData.append('previa', previa);
            formData.append('file_name', 'archivo');

            //console.log( formData );
    
            await archivoConToken( `pedidos/upload/${ pedido._id }`, formData, 'POST' );
            //const body = await resp.json();

            //console.log( body );

            //Swal.close();
        } catch (error) {
            throw new Error();
        }  

        Swal.close();
    }
};

export const pedidoStartDelete = ( id, empresa ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `pedidos/${ id }`, {empresa}, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                dispatch( pedidoDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

export const pedidosReorder = ( pedidosList, empresa ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( `pedidos/reorder`, {pedidosList, empresa}, 'POST' );
            const body = await resp.json();
            if ( body.ok ) {
                console.log( 'Reordered' );
            }
            
        } catch (error) {
            console.log(error);            
        }
    }
};

const pedidoDeleted = ( id ) => ({
    type: types.pedidosDelete,
    payload: id
});

export const pedidoLogout = () => ({
    type: types.pedidosLogoutCleaning
});
