import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';

import { promocionStartDelete, startNewPromocion, startSavePromocion } from '../../../../actions/promociones';
import { ArrowBackIcon, BirthdateIcon, CaducidadIcon, ChevronDownIcon, HorarioEspecialIcon, PrepayIcon, ProfesionalIcon, PuntoVentaIcon, SucursalesFilledIcon, TrashIcon } from '../../../../assets/svg/iconos';
import { YlmRadio } from '../../../../components/forms/YlmRadio';
import { PriceSelect } from './PriceSelect';
import { SelectServicio } from '../selectores/SelectServicio';
import { SelectSucursal } from '../selectores/SelectSucursal';
import { SelectCaducidad } from '../selectores/SelectCaducidad';
import { SelectProfesional } from '../selectores/SelectProfesional';
import { SelectBirthdate } from '../selectores/SelectBirthdate';
import { SelectHorarioEspecial } from '../selectores/SelectHorarioEspecial';
import { openModalBottom } from '../../../../actions/ui';
import { newPromocion } from '../../../../helpers/helpers';
import { YlmModalTwoButtons } from '../../../../components/main/YlmModalTwoButtons';

const timezone = process.env.REACT_APP_TIMEZONE;

export const Descuento = ({ history }) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { colores, miniLateral } = useSelector(state => state.ui);

    const [currentPromocion, setCurrentPromocion] = useState(history.location.state.descuento || null);
    const [currentScreen] = useState((history.location.state.descuento) ? 'edit' : 'new');

    const [objPromocion, setObjPromocion] = useState({});
    const [iniciado, setIniciado] = useState(false);
    const [cambiado, setCambiado] = useState(false);

    const [objErrores, setObjErrores] = useState({});

    const [editMode, setEditMode] = useState(true);
    const [showHorarioEspecial, setShowHorarioEspecial] = useState(false);

    const [dataModal, setDataModal] = useState({
        title: '¿Eliminar descuento?',
        text: ['¿Estás seguro de que deseas eliminar el descuento?'],
        buttonText: 'Estoy seguro',
        button2Text: 'Cancelar'
    });
    const [modalTwoButtonsOpened, setModalTwoButtonsOpened] = useState(false);
    const [modalEliminarDescuentoResponse, setModalEliminarDescuentoResponse] = useState(100);
    const [, setModalEliminarDescuentoWaiting] = useState({});

    const [precio, setPrecio] = useState(0);
    const [precioReal, setPrecioReal] = useState(0);
    const [typePrecio, setTypePrecio] = useState(null);


    // const [idiomaActive, setIdiomaActive] = useState('ES');

    useEffect(() => {
        if (currentPromocion) {
            // console.log( currentServicio );
            setObjPromocion(currentPromocion);
            if (currentPromocion.porcentaje) {
                setPrecioInicial(currentPromocion.porcentaje);
                setTypePrecio('porcentaje');
            } else {
                setPrecioInicial(currentPromocion.cifra);
                setTypePrecio('cifra');
            }
            setIniciado(true);
        } else {
            setEditMode(true);
            setCambiado(true);
            const nuevo = newPromocion(empresa);
            setCurrentPromocion(nuevo);
            setObjPromocion(nuevo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPromocion]);

    useEffect(() => {
        // console.log( 'objPromocion', objPromocion );
        if (currentPromocion) {
            const promocionOriginal = { ...currentPromocion };
            if (iniciado) {
                let igual = true;
                for (const propiedad in promocionOriginal) {
                    //console.log( `${nEmpresa[propiedad]} !== ${objEmpresa[propiedad]}`)
                    if (promocionOriginal[propiedad] !== objPromocion[propiedad]) {
                        igual = false;
                        // console.log(`Cambio en ${propiedad}`);
                    }
                }
                if (typePrecio === 'cifra' && precioReal !== currentPromocion.cifra) {
                    igual = false;
                }
                if (typePrecio === 'porcentaje' && precioReal !== currentPromocion.porcentaje) {
                    igual = false;
                }
                setCambiado(!igual);
            }
        }
    }, [iniciado, currentPromocion, objPromocion, precioReal, typePrecio]);

    useEffect(() => {
        if (modalEliminarDescuentoResponse === 1) {
            dispatch(promocionStartDelete(objPromocion._id, empresa));
            history.push({ pathname: `/precios-especiales/descuentos` });
        }
        setModalEliminarDescuentoWaiting({});
        setModalEliminarDescuentoResponse(100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalEliminarDescuentoResponse]);

    const backClick = () => {
        history.push({
            pathname: `/precios-especiales/descuentos`
        });
    };

    const setPrecioInicial = (valor) => {
        setPrecioReal(valor);
        const newValue = valor / 100;
        setPrecio(newValue);
    };

    const guardar = () => {
        // console.log( 'currentScreen', currentScreen );
        let correcto = true;
        const newObjErrores = {};
        if (objPromocion.titulo.trim() === '') {
            correcto = false;
            newObjErrores.titulo = 'Debes escribir un nombre';
        }

        if (objPromocion.descripcion.trim() === '') {
            correcto = false;
            newObjErrores.descripcion = 'Debes escribir una descripción';
        }

        if (objPromocion.servicios.length <= 0) {
            correcto = false;
            newObjErrores.servicios = 'Debes seleccionar algún servicio';
        }

        setObjErrores(newObjErrores);
        if (typePrecio === 'porcentaje') {
            objPromocion.porcentaje = precioReal;
            objPromocion.cifra = null;
        } else {
            objPromocion.cifra = precioReal;
            objPromocion.porcentaje = null;
        }
        if (correcto) {
            if (currentScreen === 'new') {
                dispatch(startNewPromocion(objPromocion));
                history.push({ pathname: `/precios-especiales/descuentos` });
            } else {
                dispatch(startSavePromocion(objPromocion));
                setCambiado(false);
            }
        } else {
            showModalErrores(newObjErrores);
        }
    };

    const showModalErrores = (objErrores) => {
        let arrayErrores = [];
        for (const key in objErrores) {
            arrayErrores = [...arrayErrores, objErrores[key]];
        }
        //console.log( 'arrayErrores', arrayErrores );
        dispatch(openModalBottom({ text: arrayErrores, timeOut: 5 }));
    }

    const cancelar = () => {
        if (currentScreen !== 'new') {
            setTypePrecio(currentPromocion.porcentaje ? 'porcentaje' : 'cifra');
            setPrecioReal(currentPromocion.porcentaje ? currentPromocion.porcentaje : currentPromocion.cifra);
            setPrecio(currentPromocion.porcentaje ? currentPromocion.porcentaje / 100 : currentPromocion.cifra / 100);
            setObjPromocion(currentPromocion);
        } else {
            history.goBack();
        }
    }

    // const resetInicial = () => {
    //     const jsonActiva = JSON.stringify(promocionActiva)
    //     setObjPromocion( JSON.parse( jsonActiva ) );
    // }

    const deletePromocion = () => {
        // console.log( 'deleteDescuento', descuento );
        setDataModal({
            title: '¿Eliminar descuento?',
            text: ['¿Estás seguro de que deseas eliminar el descuento?'],
            buttonText: 'Estoy seguro',
            button2Text: 'Cancelar'
        })
        setModalTwoButtonsOpened(true);
        setModalEliminarDescuentoWaiting();
    };

    const onFormChange = (e) => {
        let correcto = true;
        if (e.target.name === 'precio') {
            const validNumber = new RegExp(/^\d*\.?\d*$/);
            if (!validNumber.test(e.target.value)) {
                correcto = false;
            }
        }
        if (correcto) {
            dataChange(e.target.name, e.target.value);
        }
    };

    const dataChange = (name, value) => {
        if (editMode) {
            setObjPromocion({
                ...objPromocion,
                [name]: value
            });
        } else {
            dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
        }
    };

    const handlePrecioChange = (event) => {
        const newValue = event.target.value;
        setPrecioReal(Number(event.target.value) * 100);
        if (newValue !== '') {
            setPrecio(Number(event.target.value) / 100);
        } else {
            setPrecio(0);
        }
        setPrecio(newValue);
    };

    const changeHoras = (arrayHoras, nueva = false) => {
        console.log('arrayHoras', arrayHoras);
        const idxDia = arrayHoras.idxDay;
        const idxHora = arrayHoras.idxHora;
        let newHoras;
        newHoras = objPromocion.horariosPromocion.map((horario, idxDia2) => {
            let newHorario = {
                diasSemana: horario.diasSemana
            };
            let newHoras = horario.horas.map((hora, idxHora2) => {
                let newHora = hora;
                //console.log( `${idxDia} === ${idxDia2} && ${idxHora2} === ${idxHora}` );
                if (idxDia === idxDia2 && idxHora2 === idxHora) {
                    const stringHoraInicio = moment.tz(hora.horaInicio, timezone).format('YYYY-MM-DD');
                    const stringHoraFin = moment.tz(hora.horaFin, timezone).format('YYYY-MM-DD');
                    newHora = {
                        horaInicio: moment.tz(`${stringHoraInicio} ${arrayHoras.newHoras[0]}`, timezone).toISOString(),
                        horaFin: moment.tz(`${stringHoraFin} ${arrayHoras.newHoras[1]}`, timezone).toISOString()
                    }
                }
                return newHora;
            });
            newHorario.horas = newHoras;
            return idxDia === idxDia2 ? newHorario : horario;
        });

        setObjPromocion({
            ...objPromocion,
            horariosPromocion: newHoras
        });
    };

    const addHorario = (newHorario) => {
        //console.log( newHorario );
        if (editMode) {
            const newHoras = objPromocion.horariosPromocion.map((horario) => {
                let nuevoHorario = horario;
                if (newHorario.diasSemana === horario.diasSemana) {
                    nuevoHorario.horas = newHorario.newHoras;
                }
                return nuevoHorario;
            });

            setObjPromocion({
                ...objPromocion,
                horariosPromocion: newHoras
            });
        } else {
            dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
        }
    }

    const deleteHorario = (idxDia, idxHora) => {
        if (editMode) {
            const newHoras = objPromocion.horariosPromocion.map((horario, idxDia2) => {
                let newHorario = {
                    diasSemana: horario.diasSemana
                };
                let newHoras = horario.horas.filter((hora, idxHora2) => idxHora2 !== idxHora);
                newHorario.horas = newHoras;
                return idxDia === idxDia2 ? newHorario : horario;
            });
            //console.log( newHoras );
            setHorarioPromocion(newHoras);
        } else {
            dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
        }
    };

    const setHorarioPromocion = (horario) => {
        setObjPromocion({
            ...objPromocion,
            horariosPromocion: horario
        });
    };

    const toggleHorarioEspecial = () => {
        if (editMode) {
            setShowHorarioEspecial(objPromocion.horarioEspecial ? false : true);
            dataChange('horarioEspecial', !objPromocion.horarioEspecial);
        } else {
            dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
        }
    }

    const togglePrepago = () => {
        if (editMode) {
            dataChange('pagoAdelantado', !objPromocion.pagoAdelantado);
        } else {
            dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
        }
    }

    return (
        <div
            className='descuento'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className='descuento-wrapper'>
                <div className='single-descuento'>
                    <div className={`header ${!miniLateral && 'compress'}`}>
                        <div
                            className='nav-back'
                            onClick={backClick}
                        >
                            <ArrowBackIcon color={colores.colorSecundario} />
                            <p style={{ color: colores.colorSecundario }}>Descuentos</p>
                        </div>
                        <div className='botonera'>
                            {/* {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-editar'
                                    style={{ backgroundColor: editMode ? colores.colorPrincipal : colores.color6 }}
                                    onClick={ () => setEditMode( !editMode ) }
                                >
                                    <EditarIcon color={ editMode ? colores.color6 : colores.colorPrincipal } />
                                </button>
                            } */}
                            {
                                (!cambiado && currentScreen !== 'new') &&
                                <button
                                    className='btn-mini btn-eliminar'
                                    style={{ backgroundColor: colores.color6 }}
                                    onClick={() => deletePromocion()}
                                >
                                    <TrashIcon color={colores.colorPrincipal} />
                                </button>
                            }
                            {
                                cambiado &&
                                <button
                                    className='btn btn-crear'
                                    style={{ backgroundColor: colores.colorPrincipal }}
                                    onClick={guardar}
                                >
                                    {`${currentScreen === 'new' ? 'Crear' : 'Guardar'}`}
                                </button>
                            }
                            {
                                cambiado &&
                                <button
                                    className='btn btn-cancelar'
                                    style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
                                    onClick={cancelar}
                                >
                                    Cancelar
                                </button>
                            }
                        </div>
                    </div>
                    <div className='name'>
                        <input
                            style={{
                                backgroundColor: editMode ? '#ffffff' : 'transparent',
                                border: `${objErrores.titulo ? '2px solid ' + colores.color5 : 'none'}`
                            }}
                            autoComplete='off'
                            type="text"
                            placeholder='Nueva promoción'
                            name="titulo"
                            disabled={!editMode}
                            value={objPromocion?.titulo || ''}
                            onChange={(e) => onFormChange(e)}
                        />
                    </div>
                    <div className='descuento-main'>
                        <div className='descuento-data-row'>
                            <div className='descuento-data'>
                                <h3 style={{ color: colores.colorSecundario }}>Tipo de promoción</h3>
                                <div className='descuento-radios'>
                                    <div className='descuento-radio-group'>
                                        <YlmRadio
                                            active={objPromocion?.tipo === 'descuento'}
                                            clickFunction={() => dataChange('tipo', 'descuento')}
                                            text={`Descuento`}
                                            color={colores.colorPrincipal}
                                            bgColor={colores.color6}
                                        />
                                        <YlmRadio
                                            active={objPromocion?.tipo === 'pack'}
                                            clickFunction={() => dataChange('tipo', 'pack')}
                                            text={`Pack`}
                                            color={colores.colorPrincipal}
                                            bgColor={colores.color6}
                                        />
                                    </div>
                                    <div className='descuento-radio-group'>
                                        <YlmRadio
                                            active={objPromocion?.destino === 'servicio'}
                                            clickFunction={() => dataChange('destino', 'servicio')}
                                            text={`Servicio`}
                                            color={colores.colorPrincipal}
                                            bgColor={colores.color6}
                                        />
                                        <YlmRadio
                                            active={objPromocion?.destino === 'producto'}
                                            clickFunction={() => dataChange('destino', 'producto')}
                                            text={`Producto`}
                                            color={colores.colorPrincipal}
                                            bgColor={colores.color6}
                                        />
                                    </div>
                                </div>
                                <h3 style={{ color: colores.colorSecundario }}>Descripción</h3>
                                <textarea
                                    style={{
                                        border: `${objErrores.descripcion ? '2px solid ' + colores.color5 : 'none'}`
                                    }}
                                    name="descripcion"
                                    placeholder='Añadir descripción'
                                    disabled={!editMode}
                                    value={objPromocion?.descripcion || ''}
                                    onChange={(e) => onFormChange(e)}
                                ></textarea>
                            </div>
                            <div className='descuento-price'>
                                <PriceSelect
                                    objPromocion={objPromocion}
                                    setObjPromocion={setObjPromocion}
                                    onFormChange={onFormChange}
                                    dataChange={dataChange}
                                    editMode={editMode}
                                    precio={precio}
                                    setPrecio={setPrecio}
                                    precioReal={precioReal}
                                    setPrecioReal={setPrecioReal}
                                    typePrecio={typePrecio}
                                    setTypePrecio={setTypePrecio}
                                    handlePrecioChange={handlePrecioChange}
                                />
                            </div>

                        </div>
                        <h3 className='selectors-title' style={{ color: colores.colorSecundario }}>Condiciones</h3>
                        <div className='descuento-selectors'>
                            <div
                                className='descuento-selector left top'
                                style={{
                                    backgroundColor: colores.colorSecundario,
                                    border: `${objErrores.servicios ? '2px solid ' + colores.color5 : 'none'}`
                                }}
                            >
                                <div className='left'>
                                    <div className='icon-bg i-categorias'>
                                        <PuntoVentaIcon color={colores.colorPrincipal} />
                                    </div>
                                </div>
                                <div className='right'>
                                    <div className='title' style={{ color: colores.colorPrincipal }}>Servicios</div>
                                    <SelectServicio
                                        editMode={editMode}
                                        objPromocion={objPromocion}
                                        dataChange={dataChange}
                                    />
                                </div>
                            </div>
                            <div
                                className='descuento-selector top left'
                                style={{
                                    backgroundColor: colores.colorSecundario,
                                    border: `${objErrores.sucursales ? '2px solid ' + colores.color5 : 'none'}`
                                }}
                            >
                                <div className='left'>
                                    <div className='icon-bg i-sucursales'>
                                        <SucursalesFilledIcon color={colores.colorPrincipal} />
                                    </div>
                                </div>
                                <div className='right'>
                                    <div className='title' style={{ color: colores.colorPrincipal }}>Sucursales</div>
                                    <SelectSucursal
                                        editMode={editMode}
                                        objPromocion={objPromocion}
                                        dataChange={dataChange}
                                    />
                                </div>
                            </div>
                            <div
                                className='descuento-selector sel-caducidad left top'
                                style={{ backgroundColor: colores.colorSecundario }}
                            >
                                <div className='left'>
                                    <div className='icon-bg i-caducidad'>
                                        <CaducidadIcon color={colores.colorPrincipal} />
                                    </div>
                                </div>
                                <div className='right'>
                                    <div className='title' style={{ color: colores.colorPrincipal }}>Caducidad</div>
                                    <SelectCaducidad
                                        editMode={editMode}
                                        objPromocion={objPromocion}
                                        dataChange={dataChange}
                                    />
                                </div>
                            </div>
                            <div
                                className='descuento-selector top'
                                style={{
                                    backgroundColor: colores.colorSecundario,
                                    border: `${objErrores.profesionales ? '2px solid ' + colores.color5 : 'none'}`
                                }}
                            >
                                <div className='left'>
                                    <div className='icon-bg i-sucursales'>
                                        <ProfesionalIcon color={colores.colorPrincipal} />
                                    </div>
                                </div>
                                <div className='right'>
                                    <div className='title' style={{ color: colores.colorPrincipal }}>Profesional</div>
                                    <SelectProfesional
                                        editMode={editMode}
                                        objPromocion={objPromocion}
                                        dataChange={dataChange}
                                    />
                                </div>
                            </div>
                            <div
                                className='descuento-selector sel-birthdate left top'
                                style={{ backgroundColor: colores.colorSecundario }}
                            >
                                <div className='left'>
                                    <div className='icon-bg i-caducidad'>
                                        <BirthdateIcon color={colores.colorPrincipal} />
                                    </div>
                                </div>
                                <div className='right'>
                                    <div className='title' style={{ color: colores.colorPrincipal }}>Fecha nacimiento</div>
                                    <SelectBirthdate
                                        editMode={editMode}
                                        objPromocion={objPromocion}
                                        dataChange={dataChange}
                                    />
                                </div>
                            </div>
                            <div
                                className='descuento-selector horario-especial'
                                style={{ backgroundColor: `${objPromocion.horarioEspecial ? colores.colorSecundario : '#F7F7F7'}` }}
                            >
                                <div className='left'>
                                    <div className='icon-bg'>
                                        <HorarioEspecialIcon color={`${objPromocion.horarioEspecial ? colores.colorPrincipal : '#7B7B7B'}`} />
                                    </div>
                                </div>
                                <div className='right'>
                                    <div
                                        className={`ylm-toggle ${objPromocion.horarioEspecial && 'active'}`}
                                        onClick={() => toggleHorarioEspecial()}
                                    >
                                        <p style={{ color: objPromocion.horarioEspecial ? colores.colorPrincipal : '#707070' }}>{`Horario especial`}</p>
                                        <div
                                            className='toggle-chevron'
                                        >
                                            <div
                                                className={`toggle-bg`}
                                                style={{ backgroundColor: objPromocion.horarioEspecial ? '#FFFFFF' : '#C9C5C5' }}
                                            >
                                                <span
                                                    className={`toggle-item`}
                                                    style={{ backgroundColor: objPromocion.horarioEspecial ? colores.colorPrincipal : '#7B7B7B' }}
                                                ></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='btn-chevron'
                                        onClick={() => objPromocion.horarioEspecial && setShowHorarioEspecial(!showHorarioEspecial)}
                                        style={{ transform: showHorarioEspecial && 'rotate(180deg)' }}
                                    >
                                        <ChevronDownIcon color={objPromocion.horarioEspecial ? 'black' : '#7B7B7B'} />
                                    </div>
                                </div>
                                {
                                    showHorarioEspecial &&
                                    <SelectHorarioEspecial
                                        showHorarioEspecial={showHorarioEspecial}
                                        objPromocion={objPromocion}
                                        changeHoras={changeHoras}
                                        editMode={editMode}
                                        deleteHorario={deleteHorario}
                                        addHorario={addHorario}
                                    />
                                }
                            </div>
                            <div
                                className='descuento-selector prepago'
                                style={{ backgroundColor: `${objPromocion.pagoAdelantado ? colores.colorSecundario : '#F7F7F7'}` }}
                            >
                                <div className='left'>
                                    <div className='icon-bg'>
                                        <PrepayIcon color={`${objPromocion.pagoAdelantado ? colores.colorPrincipal : '#7B7B7B'}`} />
                                    </div>
                                </div>
                                <div className='right'>
                                    <div
                                        className={`ylm-toggle ${objPromocion.pagoAdelantado && 'active'}`}
                                        onClick={() => togglePrepago()}
                                    >
                                        <p style={{ color: objPromocion.pagoAdelantado ? colores.colorPrincipal : '#707070' }}>{`Prepago`}</p>
                                        <div
                                            className='toggle-chevron'
                                        >
                                            <div
                                                className={`toggle-bg`}
                                                style={{ backgroundColor: objPromocion.pagoAdelantado ? '#FFFFFF' : '#C9C5C5' }}
                                            >
                                                <span
                                                    className={`toggle-item`}
                                                    style={{ backgroundColor: objPromocion.pagoAdelantado ? colores.colorPrincipal : '#7B7B7B' }}
                                                ></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <YlmModalTwoButtons
                data={dataModal}
                setModalResponse={setModalEliminarDescuentoResponse}
                modalTwoButtonsOpened={modalTwoButtonsOpened}
                setModalTwoButtonsOpened={setModalTwoButtonsOpened}
            />
        </div>
    )
}
