import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { FromToTimeSelector } from './FromToTimeSelector';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

const PeriodSelectTime = ({initDate, endDate, changeHours, changeMinutes, allDaySelected, toggleDaySelected}) => {
    const { colores } = useSelector( state => state.ui );

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();


    const showDropdown = () => {
        setVisibility(true);
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

    return (
        <>
        <div 
            className='selector init-date'
            style = {{ 
                backgroundColor: colores.color6,
                borderColor: colores.colorPrincipal,
            }}
        >
            <div 
                className='time-text'
                onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
            >
                <p>{ `${allDaySelected ? 'Toda la jornada' : moment(initDate).format('H') + ':' + moment(initDate).format('mm') + ' - ' + moment(endDate).format('H') + ':' + moment(endDate).format('mm') +  ' h.' }` }</p>
            </div>
            <div
                className={`ylm-select-options vacacional-period-options ${ visibility ? 'active' : '' }`}>

                <div
                    ref={ dropdownMenuRef }
                    className='period-time'
                >
                    <div className='time-selector'>
                        {
                            !allDaySelected &&
                            <>
                                <FromToTimeSelector
                                    changeHours={ changeHours }
                                    changeMinutes={ changeMinutes }
                                    time={ initDate }
                                />
                                <FromToTimeSelector 
                                    changeHours={ changeHours }
                                    changeMinutes={ changeMinutes }
                                    time={ endDate }
                                    from={ false }
                                />
                            </>
                        }
                        <div 
                            className='all-time-selector'
                            onClick={ (e) => toggleDaySelected(e) }
                        >
                            <div 
                                className='check'
                            >
                                {
                                    allDaySelected &&
                                    <div className='checked'></div>
                                }
                            </div>
                            <p>Toda la jornada</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default PeriodSelectTime