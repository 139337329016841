import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { citaStartDelete } from '../../../actions/citas';
import { SocketContext } from '../../../context/SocketContext';

const usarSockets = process.env.REACT_SOCKETS;

export const ModalDeleteCita = ({activeModalDeleteCita, setActiveModalDeleteCita, estadoCita}) => {
    const dispatch = useDispatch();
    const { active:citaActive } = useSelector(state => state.citas);
    const { uid, empresa } = useSelector(state => state.auth);
    const { colores } = useSelector(state => state.ui);
    const { socket } = useContext( SocketContext );

    const deleteCita = () => {
        if( usarSockets ){
            socket?.emit( 'eliminar-cita', { uid, citaId: citaActive._id } );
        } else {
            dispatch( citaStartDelete( citaActive._id, empresa, estadoCita ));
        }
        closeModal();
    };

    const closeModal = () => {
        setActiveModalDeleteCita( false );
    };

    return (
        <div className={`modal-gestion ${(activeModalDeleteCita) ? 'active' : ''}`}>
            <div className="modal-bg"></div>
            <div className="modal-wrapper">
                <div className="modal-title">
                    <h3 style={{ color: colores.colorSecundario }}>Eliminar citas</h3>
                </div>
                <div className="modal-body">
                    {estadoCita===""?
                      <p>Tu cliente recibirá una notificación informándole de que su reserva no ha podido ser aceptada.<br />¿Estas seguro de confirmar esta acción?</p>
                    : <p>¿Estas seguro de confirmar esta acción?</p>
                    }
                </div>
                <div className="modal-footer">
                    <div className="buttons">
                        <div 
                            className="btn btn-confirm"
                            style={{ backgroundColor: colores.colorPrincipal }}
                            onClick={ () => deleteCita() }
                        >Confirmar</div>
                        <div 
                            className="btn btn-cancel"
                            onClick={ closeModal }
                        >Cancelar</div>
                    </div>
                </div>
                <div
                    className="modal-close"
                    onClick={ closeModal }
                ><span></span></div>
            </div>
        </div>
    )
}
