import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setMenuLateral } from '../../actions/ui';
import { AddIcon, BuscarIcon, DownloadIcon } from '../../assets/svg/iconos';
import { removeAccents } from '../../helpers/helpers';
import { Descuentos } from './components/Descuentos';

export const PreciosEspeciales = ({history}) => {
    const dispatch = useDispatch();
    const { colores, miniLateral } = useSelector(state => state.ui);
    const { promociones } = useSelector(state => state.promociones);

    const { tab } = useParams();

    const [searchActive, setSearchActive] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [contentList, setContentList] = useState([]);

    const [activeTab] = useState(tab || 'descuentos');


    useEffect(() => {
        dispatch( setMenuLateral('precios-especiales') );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect( () => {
        if( searchTxt.trim().length < 3 ) {
            let list = [];
            if( activeTab === 'descuentos' ){ list = [ ...promociones ] }
            let newList = [];
            list.forEach(item => {
                let existe = false;
                newList.forEach(ser => {
                    if( ser._id === item._id ){
                        existe = true;
                    }
                });
                if( !existe ){
                    newList.push( item );
                }
            });
            setContentList( newList );
        } else {
            let list = [];
            if( activeTab === 'descuentos' ){ list = [ ...promociones ] }
            let newList = [];
            const filterData = async() => {
                newList = await list.filter( item => {
                    let field = '';
                    if( activeTab === 'descuentos' ){ field = item.titulo }

                    let retorno;
                    retorno = removeAccents(field).toLowerCase().includes( removeAccents(searchTxt.toLowerCase()) );
                    return retorno;
                });
                setContentList( newList );
            }
            filterData();
        }
    }, [promociones, searchTxt, activeTab]);    

    const clickSearch = () => {
        setSearchActive( !searchActive );
    };

    const handleSearchChange = (e) => {
        setSearchTxt( e.target.value );
    }

    const clickAdd = () => {
        let pathname = '';
        if( activeTab === 'descuentos' ){ pathname = '/descuento/new' }
        if( activeTab === 'suplementos' ){ pathname = '/suplemento/new' }
        if( activeTab === 'bonos' ){ pathname = '/bono/new' }
        if( pathname !== ''){
            history.push({
                pathname,
                state: {
                    activeTab: 'ultima'
                }
            });
        }
    };
    
    const clickUpload = () => {
        console.log( 'clickUpload' );
    };

    const navigateTo = ( tab ) => {
        if( tab === 'descuentos' ){ history.push({ pathname: `/precios-especiales/descuentos` }) };
        if( tab === 'suplementos' ){ history.push({ pathname: `/precios-especiales/suplementos` }) };
        if( tab === 'bonos' ){ history.push({ pathname: `/precios-especiales/bonos` }) };
    }

    return (
        <div className='precios-especiales'>
            <div className={`preheader ${!miniLateral && 'compress'}`}>
                <div className="title">
                    <h1 style={{color: colores.colorPrincipal}}>Precios especiales</h1>
                </div>
                <div className='tabs'>
                    <div
                        className={ `tab ${activeTab === 'descuentos' ? 'active' : ''}` } onClick={ () => navigateTo('descuentos') }
                        style={{ backgroundColor: activeTab === 'descuentos' ? colores.colorSecundario : 'transparent'}}
                    >
                        <p style={{ color: activeTab === 'descuentos' ? colores.colorPrincipal : colores.colorSecundario}}>Descuentos</p>
                    </div>
                    <div
                        className={ `tab ${activeTab === 'suplementos' ? 'active' : ''}` } onClick={ () => navigateTo('suplementos') }
                        style={{ backgroundColor: activeTab === 'suplementos' ? colores.colorSecundario : 'transparent'}}
                    >
                        <p style={{ color: activeTab === 'suplementos' ? colores.colorPrincipal : colores.colorSecundario}}>Suplementos</p>
                    </div>
                    
                    <div
                        className={ `tab ${activeTab === 'bonos' ? 'active' : ''}` } onClick={ () => navigateTo('bonos') }
                        style={{ backgroundColor: activeTab === 'bonos' ? colores.colorSecundario : 'transparent'}}
                    >
                        <p style={{ color: activeTab === 'bonos' ? colores.colorPrincipal : colores.colorSecundario}}>Bonos</p>
                    </div>
                </div>
                <div className="botonera">
                    <div 
                        className={`btn-search ${searchActive && 'active'}`}
                    >
                        <div className='search-input'>
                            <input 
                                type={'text'}
                                placeholder={'Buscar por nombre'}
                                value={ searchTxt }
                                onChange={ handleSearchChange }
                            />
                            {
                                searchTxt.trim().length > 2 &&
                                <span className='cantidad'>
                                    ({ contentList.length })
                                </span>
                            }
                        </div>
                        <div
                            className='icono'
                            onClick={ clickSearch }
                        >
                            <BuscarIcon
                                color={ colores.colorPrincipal }
                                className="icon-inverse"
                            />
                        </div>
                    </div>
                    <div 
                        className="btn-add"
                        onClick={ clickAdd }
                    >
                        <div className='icono'>
                            <AddIcon
                                color={ colores.colorPrincipal }
                            />
                        </div>
                        
                    </div>
                    <div 
                        className="btn-upload"
                        onClick={ clickUpload }
                    >
                        <div className='icono'>
                            <DownloadIcon color={ colores.colorPrincipal }/>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className='tabs'>
                {
                    activeTab === 'descuentos'
                    &&
                    <Descuentos
                        history={ history }
                        descuentosList={ contentList }
                    />
                }
            </div>
        </div>
    )
}
