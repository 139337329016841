import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ChevronDownIcon } from '../../../assets/svg/iconos';
import { getDifusion } from '../../../helpers/helpers';
import useClickOutside from '../../../hooks/useClickOutside';

const difusionOptions = [
    { _id: '1', nombre: 'todos', text: 'Todos' },
    { _id: '2', nombre: 'geografica', text: 'Geográfica' },
    { _id: '3', nombre: 'opcion2', text: 'Opción 2' },
]

export const SelectDifusion = ({objNotificacion, dataChange, editMode}) => {
    // const { sucursales } = useSelector(state => state.sucursales);

    const [selectText, setSelectText] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        // console.log( difusionOptions );
        // console.log( objNotificacion?.lista );
        if( objNotificacion.lista?.difusion ){
            setSelectText( getDifusion( objNotificacion.lista.difusion )  );
        }
    }, [objNotificacion, /* sucursales */]);
    

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    const clickOption = ( option ) => {
        console.log( option );
        let newLista = {
            ...objNotificacion.lista,
        }
        newLista.difusion = option.nombre;
        dataChange('lista', newLista );
        close();
        // let newSucursales = [];
        // if( objNotificacion.sucursales.includes( idSucursal ) ){
        //     newSucursales = objNotificacion.sucursales.filter( suc => {
        //         return suc !== idSucursal;
        //     });
        // } else {
        //     newSucursales = [
        //         ...objNotificacion.sucursales,
        //         idSucursal
        //     ]
        // }
        // dataChange('sucursales', newSucursales );
    }

    return (
        <div 
            className='ylm-difusion-select'
        >
            <div 
                className='select-main'
                onClick={ clickSelect }
            >
                <p>{ selectText }</p>
                <ChevronDownIcon color={ '#000000' }/>
            </div>
            {
                isOpen &&
                <ul 
                    className='select-options' 
                    ref={popover}
                >
                    {
                        difusionOptions.map( option => (
                            <li
                                key={ option._id }
                                onClick={ () => clickOption( option )}
                            >
                                <p>{ option.text }</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
