import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { startLoadingClienteSaldos } from '../../../actions/clientesaldos';

export const ClienteSaldos = ({objCliente, saveCliente}) => {

  const dispatch = useDispatch();

  const { empresa } = useSelector(state => state.auth);
  const { colores } = useSelector(state => state.ui);
  const { clienteSaldos } = useSelector(state=>state.clienteSaldos);

  useEffect(() => {
    console.log("Listado clienteSaldos...", saveCliente);
    dispatch(startLoadingClienteSaldos(objCliente._id));
  }, [saveCliente]);
  
  return (
    <div className='cliente-saldos'>
        {
            clienteSaldos &&
            clienteSaldos.map( (clienteSaldo, idx) => (
                <div 
                    key={ idx }
                    className='cliente-saldos-row'
                    style={{ backgroundColor: colores.color6 }}
                >
                    <div className='cliente-saldos-date'>
                        <p><b>{ moment( clienteSaldo?.fecha ).format('D/M/YY') }</b> { moment( clienteSaldo?.fecha ).format('H:mm') }h</p>
                    </div>
                    <div className='cliente-saldos-text'>
                        <p>{ clienteSaldo.monto }</p>
                    </div>
                    <div className='cliente-saldos-text'>
                        <p>{ clienteSaldo.updatedbyadmin?'Modificado por Admin':''}</p>
                    </div>
                    {clienteSaldo.cita && 
                        <>
                            <div className='cliente-saldos-text'>
                                <p>{ clienteSaldo.monto<0?'Reserva':'Cancelación Reserva'}</p>
                            </div>
                            <div className='cliente-saldos-date'>
                                <p><b>{ moment( clienteSaldo.cita.fecha ).format('D/M/YY') }</b> { moment( clienteSaldo.cita.fecha ).format('H:mm') }h</p>
                            </div>
                            <div className='cliente-saldos-text'>
                                <p>{ clienteSaldo.cita.sucursal.nombre}</p>
                            </div>
                        </>
                    }
                </div>
            ))
        }
    </div>
  )
}

