import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useSelector } from 'react-redux';
import { YlmRadio } from '../../../components/forms/YlmRadio';
import { YlmToggle } from '../../../components/forms/YlmToggle';
import { DdSucursal } from './general/DdSucursal';

export const General = ({objEmpresa, setObjEmpresa, cambiado, guardar, cancelar}) => {
  const { colores } = useSelector(state => state.ui);
  const { sucursales } = useSelector(state => state.sucursales);

  const [ funcionalidades, setFuncionalidades] = useState([
    { _id: 'comentarios', active: true, text: 'Habilitar comentarios usuarios'},
    { _id: 'huecos', active: true, text: 'Habilitar próximos huecos disponibles'},
    { _id: 'avisoCancelacion', active: true, text: 'Habilitar aviso por cancelación'},
    { _id: 'notificacionesEmail', active: true, text: 'Enviar notificaciones por email'},
    { _id: 'valoraciones', active: true, text: 'Activar valoraciones de tus servicios a tus clientes'},
    { _id: 'pagoAdelantado', active: false, text: 'Activar pago por adelantado en todos los productos / servicios'},
    { _id: 'reservaMultiple', active: false, text: 'Habilitar reserva multiple'},
  ]);

  const [recomendacionVentas, setRecomendacionVentas] = useState(false);

  const [ itemsOrdenacion, setItemsOrdenacion] = useState([
    { _id: 'valoracion', text: 'Valoración usuarios', order: 1 },
    { _id: 'profesional', text: 'Diponibilidad profesional', order: 2 },
    { _id: 'sucursal', text: 'Orden preestablecido según sucursal', order: 3 },
  ]);

  useEffect(() => {
    setFuncionalidades([
      { _id: 'comentarios', active: objEmpresa.comentarios, text: 'Habilitar comentarios usuarios'},
      { _id: 'huecos', active: objEmpresa.huecos, text: 'Habilitar próximos huecos disponibles'},
      { _id: 'avisoCancelacion', active: objEmpresa.avisoCancelacion, text: 'Habilitar aviso por cancelación'},
      { _id: 'notificacionesEmail', active: objEmpresa.notificacionesEmail, text: 'Enviar notificaciones por email'},
      { _id: 'valoraciones', active: objEmpresa.valoraciones, text: 'Activar valoraciones de tus servicios a tus clientes'},
      { _id: 'pagoAdelantado', active: objEmpresa.pagoAdelantado, text: 'Activar pago por adelantado en todos los productos / servicios'},
      { _id: 'reservaMultiple', active: objEmpresa.reservaMultiple, text: 'Habilitar reserva multiple'},
    ]);
    setRecomendacionVentas( objEmpresa.recomendacionVentas );

    if( objEmpresa.ordenSeleccion ){
      let newOrden = [];
      objEmpresa.ordenSeleccion.forEach((item, idx) => {
        let newItem;
        if( item === 'valoracion' ){
          newItem = { _id: 'valoracion', text: 'Valoración usuarios', order: idx + 1 };
        }
        if( item === 'profesional' ){
          newItem = { _id: 'profesional', text: 'Diponibilidad profesional', order: idx + 1 };
        }
        if( item === 'sucursal' ){
          newItem = { _id: 'sucursal', text: 'Orden preestablecido según sucursal', order: idx + 1 };
        }
        newOrden = [...newOrden, newItem];
      });
      setItemsOrdenacion( newOrden );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objEmpresa]);

  // useEffect(() => {
  //   console.log( 'itemsOrdenacion', itemsOrdenacion );
  // }, [itemsOrdenacion]);
  
  const clickToggle = ( id ) => {
    const newObject = {
      ...objEmpresa
    };
    newObject[id] = !newObject[id];
    setObjEmpresa( newObject );
  };

  const clickVentas = ( valor ) => {
    setObjEmpresa({ ...objEmpresa, recomendacionVentas: valor });
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    //console.log( 'result', result );

    const newArray = [ ...itemsOrdenacion ];
    const element = newArray.splice( result.source.index, 1 )[0];
    newArray.splice(result.destination.index, 0, element);

    setItemsOrdenacion( newArray );
    reorderItems( newArray );

    /* const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    }); */
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    /* userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`, */
  
    // change background colour if dragging
    //background: isDragging ? "lightgreen" : "grey",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? "lightblue" : "lightgrey",
    //padding: grid,
    //width: 250
  });

  const reorderItems = ( items ) => {
    let newOrdenacion = [];

    items.forEach(item => {
      newOrdenacion = [ ...newOrdenacion, item._id ];  
    });

    setObjEmpresa({ ...objEmpresa, ordenSeleccion: newOrdenacion });
  };

  return (
    <div className='configuracion-wrapper'>
      <div className='header'>
        <h1 style={{color: colores.colorPrincipal}}>General</h1>
        {
          cambiado &&
          <div className='botonera'>
            <button 
              className='btn btn-primary'
              style={{ color: colores.color6, backgroundColor: colores.colorPrincipal }}
              onClick={ guardar }
            >
              Aplicar
            </button>
            <button 
              className='btn btn-secondary'
              style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
              onClick={ cancelar }
            >
              Cancelar
            </button>
          </div>
        }
      </div>
      <div className='sections'>
        <div className='section funcionalidades'>
          <h3 style={{ color: colores.colorSecundario }}>Funcionalidades</h3>
          <div className='selectors'>
              { funcionalidades.map( item => (
                <div className='selector' key={ item._id }>
                  <YlmToggle 
                    item={ item }
                    clickToggle={ clickToggle }
                    color={ colores.colorPrincipal }
                    bgColor={ colores.colorSecundario }
                  />
                </div>
                ))
              }
          </div>
        </div>
        <div className='section recomendacion'>
          <h3 style={{ color: colores.colorSecundario }}>Algoritmos recomendación productos según:</h3>
          <div className='recomendacion-items'>
            <YlmRadio 
              active={ recomendacionVentas }
              clickFunction={ () => clickVentas( true ) }
              text={`ventas`}
              color={ colores.colorPrincipal }
              bgColor={ colores.colorSecundario }
            />
            <YlmRadio 
              active={ !recomendacionVentas }
              clickFunction={ () => clickVentas( false ) }
              text={`productos relacionados`}
              color={ colores.colorPrincipal }
              bgColor={ colores.colorSecundario }
            />
          </div>
        </div>
        <div className='section distribucion'>
          <h3 style={{ color: colores.colorSecundario }}>Distribución personal aleatorio</h3>
          <DragDropContext 
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className={`dist-container`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {
                    itemsOrdenacion &&
                    itemsOrdenacion.map( (item, index) => (
                      <Draggable key={ item._id } draggableId={ item._id } index={ index }>
                        {(provided, snapshot) => (
                          <div 
                            className='dist-item'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div className='dist-main'>
                              <div
                                style={{ backgroundColor: colores.colorPrincipal }}
                                className='dist-number'
                              >
                                <p>{ index + 1 }</p>
                              </div>
                              <div className='dist-name'>
                                <p>{ item.text }</p>
                              </div>
                            </div>
                            {
                              item._id === 'sucursal'
                              &&
                              <div className='dd-sucursales'>
                                {
                                  sucursales.map( (sucursal) => (
                                    <DdSucursal
                                      key={ sucursal._id }
                                      sucursal={ sucursal }
                                    />
                                  ))
                                }
                              </div>
                            }
                          </div>
                        )}
                      </Draggable>
                    ))
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  )
}
