import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { EyeIcon, TrashIcon } from '../../../../assets/svg/iconos';
import useClickOutside from '../../../../hooks/useClickOutside';

export const ProductoCard = ({producto, editProducto, deleteProducto}) => {
    const { colores } = useSelector(state => state.ui);

    const [productoUrl, setProductoUrl] = useState('');

    /* useEffect( () => {
        if( producto.imagenPrincipal ){
            getImagenPrincipal( producto.imagenPrincipal );
        }
    }, [producto]); */

    useEffect( () => {
        if( producto.img.length > 0 ){
            getImagenPrincipal( `${producto.img[0]}` );
        }
    }, [producto]);
    
    const getImagenPrincipal = async( imagen ) => {
        setProductoUrl( {url: imagen } );
    }

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }
    
    return (
        
        <div 
            className='producto-card'
            onClick={ clickSelect }
        >
            <div className='card-main'>
                <div 
                    className='producto-img'
                    style={{ backgroundImage: `url(${productoUrl.url})` }}
                ></div>
                <div className='producto-title' style={{ color: colores.colorPrincipal }}>
                    <p>{ producto.titulo }</p>
                </div>
                <div className='producto-marca'>
                    <p>{ producto.marca }</p>
                </div>
            </div>
            {
                isOpen && (
                    <div className='producto-overlay' ref={popover}>
                        <div className='overlay-buttons'>
                            <button 
                                className='eye'
                                onClick={ () => editProducto( producto ) }
                            >
                                <EyeIcon color={ colores.colorPrincipal } />
                            </button>
                            <button 
                                className='trash'
                                onClick={ () => deleteProducto( producto ) }
                            >
                                <TrashIcon color={ colores.colorPrincipal } />
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
