import React, { useState } from 'react'
import { ChevronDownIcon, DeliveredFalseIcon, DeliveredIcon, DeliveredPartialIcon, PayIcon } from '../../../assets/svg/iconos';

export const ProductosViewRow = ({pedido, entregarProducto}) => {

    const [show, setShow] = useState(false);    

    const getTotalPedidoItems = () => {
        let retorno = 0;
        pedido.productos.forEach( arrayItem => {
            retorno += arrayItem.cantidad;
        });
        return retorno;
    };

    const getSumPedidoItems = () => {
        let retorno = 0;
        pedido.productos.forEach( arrayItem => {
            retorno += (arrayItem.cantidad * arrayItem.producto.precio) / 100;
        });
        return retorno;
    }

    const getTodoPagado = () => {
        let todoPagado = true;
        pedido.productos.forEach(arrayItem => {
            if( !arrayItem.pagado ){ todoPagado = false; }
        });
        return todoPagado;
    }

    const getEntregado = () => {
        let retorno = 1; // 1 - Todo entregado; 2 - Algunas cosas entregadas; 3 - Nada entregado
        let entregados = 0;
        let sinEntregar = false;
        pedido.productos.forEach(arrayItem => {
            arrayItem.entregado ? entregados++ : sinEntregar++; 
        });
        if( pedido.productos.length > entregados && pedido.productos.length > sinEntregar ) {
            retorno = 2;
        } else if( pedido.productos.length === sinEntregar ) {
            retorno = 3;
        }
        return retorno;
    };

    const changeStateEntregada = ( e, linea ) => {
        e.stopPropagation();
        if( linea === -1 && pedido.productos.length === 1 ){
            if( !linea.entregado ){
                entregarProducto( pedido, pedido.productos[0].producto );
            }
        } else if( linea !== -1 && pedido.productos.length > 1) {
            if( !linea.entregado ){
                entregarProducto( pedido, linea.producto );
            }
        } else {
            setShow(!show);
        }
    };

    return (
        <div 
            className={`table-row ${ show ? 'active' : ''}`}
            onClick={ () => setShow( !show ) }
        >
            <div className='pedido-row first-row'>
                <div className='col col-pedido'>
                    <span>{ pedido.numeroPedido }</span>
                    {
                        pedido.productos.length > 1 &&
                        <span><ChevronDownIcon /></span>
                    }
                </div>
                <div className='col col-cliente'>{ pedido.usuario.nombre }</div>
                <div className={`col col-producto ${ pedido.productos.length > 1 ? 'bold' : ''}`}>{ pedido.productos.length > 1 ? 'Varios' : pedido.productos[0].producto.titulo }</div>
                <div className={`col col-referencia ${ pedido.productos.length > 1 ? 'bold' : ''}`}>{ pedido.productos.length > 1 ? 'Varias' : pedido.productos[0].producto.referencia }</div>
                <div className='col col-unidades center'>{ getTotalPedidoItems()}</div>
                <div className={`col col-total ${ pedido.productos.length > 1 ? 'bold' : ''}`}>{ getSumPedidoItems() }€</div>
                <div className='col col-pago center'>{ getTodoPagado() ? <PayIcon /> : 'Pendiente' }</div>
                <div 
                    className='col col-entregado center'
                    onClick={ (e) => changeStateEntregada( e, -1 )}
                >{ getEntregado() === 1 ? <DeliveredIcon /> : getEntregado() > 2 ? <DeliveredFalseIcon /> : <DeliveredPartialIcon />}</div>
                <div className='col col-btns'></div>
            </div>
            {
                pedido.productos.length > 1 &&
                <div className='pedido-detail'>
                    {
                    pedido.productos.map( (linea, idx) => (
                        <div key={ idx } className={`pedido-row`}>
                            <div className='col col-pedido'></div>
                            <div className='col col-cliente'></div>
                            <div className='col col-producto'>{ linea.producto.titulo }</div>
                            <div className='col col-referencia'>{ linea.producto.referencia }</div>
                            <div className='col col-unidades center'>{ linea.cantidad }</div>
                            <div className='col col-total'>{ `${ linea.producto.precio / 100 }€/ud.` }</div>
                            <div className='col col-pago center'>{ linea.pagado ? <PayIcon /> : 'Pendiente' }</div>
                            <div 
                                className='col col-entregado center'
                                onClick={ (e) => changeStateEntregada( e, linea )}
                            >{ linea.entregado ? <DeliveredIcon /> : <DeliveredFalseIcon />}</div>
                            <div className='col col-btns'></div>
                        </div>
                    ))
                    }
                </div>
            }
        </div>
    )
}
