import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useOutsideClick } from './useOutsideClick';
import { startLoadingSucursales } from '../../actions/sucursales';


export const DropdownSucursales = ({ selectServicio, texto, handleSucursalSelected, selectSucursal, editCita }) => {
    const { colores } = useSelector(state => state.ui);
    const { empresa } = useSelector(state => state.auth);
    const { sucursales } = useSelector(state => state.sucursales);

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const [sucursalesFiltered, setSucursalesFiltered] = useState([]);

    const dispatch = useDispatch();

    const showDropdown = () => {
        if (selectSucursal.visible) {
            setVisibility(true);
        }
    };

    const hideDropdown = () => {
        setVisibility(false);
    };

    const selectItem = (index) => {
        hideDropdown();
        setSelectedIndex(index);
        handleSucursalSelected(sucursalesFiltered[index]);
    };

    useOutsideClick(dropdownMenuRef, hideDropdown, visibility);

    useEffect(() => {
      dispatch(startLoadingSucursales(empresa));
    }, [])

    useEffect(() => {
        if (selectServicio && !editCita) {
            // Crear un conjunto de IDs únicos de sucursales
            const sucursalIds = new Set(selectServicio.flatMap(servicio => servicio.sucursales));
            if(sucursales.length>1){
                // Filtrar las sucursales para crear sucursalesFiltered
                let newSucursales = sucursales.filter(sucursal => sucursalIds.has(sucursal._id));
                setSucursalesFiltered(newSucursales);
            }else if(sucursales.length==1) {
                setSucursalesFiltered(sucursales);
                selectItem(selectServicio.length>0?0:-1);
            }
        } else {
            setSucursalesFiltered(sucursales);
        }
    }, [selectServicio, sucursales, editCita])


    useEffect(() => {
        if (!selectSucursal.selected) {
            setSelectedIndex(-1);
        } else {
            sucursalesFiltered.map((sucursal, idx) => {
                if (sucursal._id === selectSucursal.selected._id) {
                    setSelectedIndex(idx);
                }
                return sucursal;
            });
        }
    }, [sucursalesFiltered, selectSucursal]);

    return (
        <div className="brb-select">
            <div
                className={`brb-select-container ${selectSucursal.visible ? 'visible' : ''} ${visibility ? 'active' : ''}`}
                onClick={() => visibility ? hideDropdown() : showDropdown()}
            >
                <p>{(selectedIndex > -1) && !!sucursalesFiltered.length ? sucursalesFiltered[selectedIndex].nombre : texto}</p>
                <div className="right-zone">
                    <p></p>
                    <span
                        className="select-arrow select-arrow-down"
                    ></span>
                </div>
            </div>
            <div ref={dropdownMenuRef} className={`brb-select-options select-sucursal ${visibility ? 'active' : ''}`}>
                {
                    sucursalesFiltered.map((sucursal, idx) => (
                        <div
                            className="brb-select-option"
                            style={{ backgroundColor: colores.color6 }}
                            key={sucursal._id}
                            onClick={() => selectItem(idx)}
                        >
                            <p>{sucursal.nombre}</p>
                            <div className="right-container">
                                <div className="adress">{sucursal.direccion}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
