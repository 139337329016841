import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowBackIcon, TrashIcon } from '../../../../assets/svg/iconos';
import { useParams } from 'react-router-dom';
import { newVariante } from '../../../../helpers/helpers';
import { ModalEliminarVariante } from '../modales/ModalEliminarVariante';

import { startLoadingVariante, startNewVariante, startSaveVariante, unactiveVariante, varianteStartDelete } from '../../../../actions/variantes';
import { SelectValor } from './SelectValor';
import { SelectServicios } from './SelectServicios';
import { SelectProductos } from './SelectProductos';

export const Variante = ({history}) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector( state => state.auth );
    const { colores, miniLateral } = useSelector( state => state.ui );
    const { active: varianteActive } = useSelector( state => state.variantes );

    const { varianteId } = useParams();
    
    const [currentScreen] = useState( (history.location.state.variante) ? 'edit' : 'new' );

    const [objVariante, setObjVariante] = useState({});
    const [iniciado, setIniciado] = useState(false);
    const [cambiado, setCambiado] = useState(false);

    const [editMode, setEditMode] = useState(true);
    
    const [idiomaActive, ] = useState('ES');
    const [ , setObjErrores] = useState({});

    const [modalEliminarVarianteOpened, setModalEliminarVarianteOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar variante?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    useEffect(() => {
        if( varianteId !== 'new' ){
            dispatch(startLoadingVariante( varianteId, empresa ));
        } else {
            const laVariante = newVariante(empresa);
            setObjVariante( laVariante );
            setEditMode( true );
            setCambiado( true );
        }
        return () => {
            dispatch( unactiveVariante() );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if( varianteActive !== null ){
            setIniciado( true );
            setObjVariante( varianteActive );
        }
    }, [varianteActive]);

    useEffect(() => {
        // console.log( 'objVariante', objVariante );
        if( varianteActive && objVariante ){
            //console.log( 'varianteActive', varianteActive );
            const varianteOriginal = { ...varianteActive };
            if( iniciado ){
                let igual = true;
                for( const propiedad in varianteOriginal ){
                    //console.log( `${nEmpresa[propiedad]} !== ${objEmpresa[propiedad]}`)
                    if( varianteOriginal[propiedad] !== objVariante[propiedad] ){
                    igual = false;
                    //console.log(`Cambio en ${propiedad}`);
                    }
                }
                setCambiado( !igual );
            }
        }
    }, [iniciado, varianteActive, objVariante]); 

    const backClick = () => {
        history.push({
            pathname: `/punto-venta/variantes`
        });
    }

    const guardar = () => {
        /* dispatch( startSaveVariante( objVariante ) );
        dispatch( activeVariante( objVariante._id, objVariante ));
        setEditMode( false ); */
        let correcto = true;
        const newObjErrores = {};
        if( objVariante.nombre.trim() === '' ){ 
            correcto = false;
            newObjErrores.nombre = 'Debe escribir un nombre';
        }

        if( objVariante.descripcion.trim() === '' ){ 
            correcto = false;
            newObjErrores.telefono = 'Debe escribir una descripción';
        }

        setObjErrores( newObjErrores );
        let newObjeto = { ...objVariante };
        // console.log( newObjeto );
        if( correcto ){
            //console.log('correcto');
            
            if( currentScreen === 'new' ){
                delete newObjeto._id;
                dispatch( startNewVariante( newObjeto ) );
                history.push({ pathname: `/punto-venta/variantes` });
            } else {
                dispatch( startSaveVariante( newObjeto ) );
                setCambiado( false );
                /* setObjServicio( newObjeto );
                setCurrentServicio( newObjeto ); */
            }

        }
    }

    const cancelar = () => {
        //setObjProducto( productoActive );
        if( currentScreen !== 'new' ){
            setObjVariante( varianteActive );
        } else {
            history.goBack();
        }
    }

    /* const deleteProducto = () => {
        console.log( 'deleteServicio' );
        history.goBack();
    } */
    
    const onFormChange = (e) => {
        let correcto = true;
        const isValor = e.target.name === 'valor';
        if( isValor ){
            const validNumber = new RegExp(/^\d*\.?\d*$/);
            if( !validNumber.test( e.target.value ) ){
                correcto = false;
            }
        }

        if( correcto ){ 
            dataChange( e.target.name, e.target.value * (isValor ? 100 : 1) );
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        dataChange( name, value );
    }

    const dataChange = (name, value) => {
        setObjVariante({
            ...objVariante,
            [name]: value
        });
    }

    const deleteVarianteModal = () => {
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar ${objVariante.nombre}?`]
        })
        setModalEliminarVarianteOpened( true );
    };

    const deleteVarianteResponse = ( value ) => {
        if( value === 1 ){
          console.log( 'value = 1' );
          let selectedId = objVariante._id;
          
          dispatch( varianteStartDelete( selectedId, empresa ) );
          history.push({ pathname: `/punto-venta/variantes` })
        }
    }
    
    return (
        <>
        <div 
            className='variante'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className='variante-wrapper'>
                <div className='single-variante'>
                    <div className={`header ${!miniLateral && 'compress'}`}>
                        <div 
                            className='nav-back'
                            onClick={ backClick }
                        >
                            <ArrowBackIcon color={ colores.colorSecundario } />
                            <p style={{ color: colores.colorSecundario }}>Variantes</p>
                        </div>
                        <div className='botonera'>
                            {/* {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-editar'
                                    style={{ backgroundColor: editMode ? colores.colorPrincipal : colores.color6 }}
                                    onClick={ () => setEditMode( !editMode ) }
                                >
                                    <EditarIcon color={ editMode ? colores.color6 : colores.colorPrincipal } />
                                </button>
                            } */}
                            {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-eliminar'
                                    style={{ backgroundColor: colores.color6 }}
                                    onClick={ deleteVarianteModal }
                                >
                                    <TrashIcon color={ colores.colorPrincipal } />
                                </button>
                            }
                            {
                            cambiado &&
                                <button 
                                    className='btn btn-crear'
                                    style={{ backgroundColor: colores.colorPrincipal }}
                                    onClick={ guardar }
                                >
                                    {`${currentScreen === 'new' ? 'Crear' : 'Guardar'}`}
                                </button>
                            }
                            {
                            cambiado &&
                                <button 
                                    className='btn btn-cancelar'
                                    style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
                                    onClick={ cancelar }
                                >
                                    Cancelar
                                </button>
                            }
                        </div>
                        
                    </div>
                    <div className='name'>
                        <input
                            autoComplete='off'
                            type="text"
                            placeholder='Nueva variante'
                            name="nombre"
                            disabled={ !editMode }
                            value={ objVariante?.nombre || '' }
                            onChange={handleChange}
                        />
                    </div>
                    <div className='variante-main'>
                        <div className='variante-description'>
                            <h3>Descripción</h3>
                            <textarea
                                name="descripcion"
                                placeholder='Añadir descripción'
                                disabled={ !editMode }
                                value={ objVariante?.descripcion || '' }
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <div className='variante-values'>
                            <div className='value-item'>
                                <h3>Tiempo</h3>
                                <div className='value-selectors'>
                                    <div className='value-selector'>
                                        <input
                                            autoComplete='off'
                                            type='number'
                                            placeholder='Añadir tiempo'
                                            name='tiempo'
                                            disabled={ !editMode }
                                            value={ objVariante?.tiempo || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        />
                                    </div>
                                    <div className='value-selector'>
                                        <div className='time-select'>
                                            Minutos
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='value-item'>
                                <h3>Valor</h3>
                                <div className='value-selectors'>
                                    <div className='value-selector'>
                                        <input
                                            autoComplete='off'
                                            type='number'
                                            placeholder='Añadir valor'
                                            name='valor'
                                            disabled={ !editMode }
                                            value={ objVariante?.valor / 100 || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        />
                                    </div>
                                    <div className='value-selector'>
                                        <SelectValor
                                            objVariante={ objVariante }
                                            dataChange = { dataChange }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='variante-selectors'>
                            <SelectServicios
                                objVariante ={ objVariante }
                                dataChange = { dataChange }
                            />
                            <SelectProductos
                                objVariante ={ objVariante }
                                dataChange = { dataChange }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalEliminarVariante
            data = { dataModalEliminar }
            setModalResponse = { deleteVarianteResponse }
            modalOpened = { modalEliminarVarianteOpened }
            setModalOpened = { setModalEliminarVarianteOpened }
        />
        </>
    )
}
