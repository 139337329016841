import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModalBottom } from '../../../../actions/ui';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const SectorSelect = ({ objEmpresa, dataChange, editMode }) => {

    const dispatch = useDispatch();
    const { sectores } = useSelector(state => state.sectores);

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    /* useEffect(() => {
      console.log( objEmpresa );
    }, [objEmpresa]) */
    

    const showDropdown = () => {
        if( editMode ){
            setVisibility(true);
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = ( valor ) => {
        let newSector = sectores.filter( plan => {
            return plan._id === valor;
        })[0];

        dataChange( 'sector', newSector );
        hideDropdown();
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );
    
  return (
    <div 
        className={ `business-sector`}
        onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
    >
        <div 
            className='ylm-select business-sector-select'
        >
            <div className='selector'>
                <p>{ objEmpresa.sector?.nombre || '' }</p>
                <span><ChevronDownIcon color={ `#000000` } /></span>
            </div>
            <ul
                ref={ dropdownMenuRef }
                className={`ylm-select-options business-sector-options ${ visibility ? 'active' : '' }`}
            >
                {
                    sectores.map( sector => (
                        <li
                            key={ sector._id }
                            className={ `select-option check-option ${ objEmpresa.sector?._id === sector._id ? 'active' :  objEmpresa.sector?._id === sector._id ? 'active' : ''}` }
                            onClick={ () => selectItem(sector._id)}
                        >
                            <span></span>
                            <p>{ sector.nombre }</p>
                        </li>
                    ))
                }
            </ul>
        </div>
    </div>
  )
}