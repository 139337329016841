import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { StarIcon, TrashIcon } from '../../../assets/svg/iconos';
import { YlmRadio } from '../../../components/forms/YlmRadio';
import { IdiomaDropdown } from './idiomas/IdiomaDropdown';
import { NavIdiomaDropdown } from './idiomas/NavIdiomaDropdown';

export const Idiomas = () => {
  const { colores } = useSelector(state => state.ui);

  // TODO: Los idiomas del usuario deben venir de su perfil.
  const [ businessLangs, setBusinessLangs ] = useState([
    { _id: 'lgBs1', lang: { _id: 'lang1', name: 'Español', code: 'es-ES' }, active: true, fav: true, navDefault: true  },
    { _id: 'lgBs2', lang: { _id: 'lang2', name: 'Català', code: 'ca-ES'}, active: false, fav: false, navDefault: false }
  ]);

  // TODO: El idioma del navegador debe venir de la BD
  // Podrá ser default o 
  const [ idiomaNav, setIdiomaNav ] = useState('default');

  const handleLangChange = (currentLang, value) => {
    setBusinessLangs(
      businessLangs.map( lang => {
        if( lang._id === currentLang._id && lang.fav !== true ) {
          lang.active = value;
        }
        return lang;
      })
    )
  };

  const handleFavChange = ( currentLang ) => {
    if( !currentLang.fav ){
      setBusinessLangs(
        businessLangs.map( lang => {
          lang.fav = ( lang._id === currentLang._id ) ? true : false;
          lang.active = ( lang._id === currentLang._id ) ? true : lang.active;
          return lang;
        })
      )
    }
  };

  const handleDeleteLang = ( currentLang ) => {
    if( businessLangs.length <= 1 || currentLang.fav ) return;
    setBusinessLangs(
      businessLangs.filter( lang => lang._id !== currentLang._id )
    )
  };

  const handleNavDefaultChange = ( currentLang ) => {
    businessLangs.map( lang => {
      lang.navDefault = ( lang._id === currentLang._id ) ? true : false;
      return lang;
    })
  }

  return (
    <div className='configuracion-wrapper'>
      <div className='header'>
        <h1 style={{color: colores.colorPrincipal}}>Idiomas</h1>
        <div className='botonera'>
            <button 
              className='btn btn-primary'
              style={{ color: colores.color6, backgroundColor: colores.colorPrincipal }}
            >
              Aplicar
            </button>
            <button 
              className='btn btn-secondary'
              style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
            >
              Cancelar
            </button>
        </div>
      </div>
      <div className='section preferencias'>
        <h3>Orden de preferencia</h3>
        <div className='preferencias-items'>
          {
            businessLangs.map( lang => (
              <div
                key={ lang._id }
                className='pref-row'
              >
                <div 
                  className={ `fav ${ lang.fav && 'active' }` }
                  style={{ backgroundColor: colores.colorPrincipal }}
                  onClick={ () => handleFavChange( lang ) }
                >
                  <StarIcon />
                </div>
                <div
                  className='name'
                >
                  { lang.lang.name }
                </div>
                <div
                  className={`delete ${lang.fav && 'no-delete'}`}
                  onClick={ () => handleDeleteLang( lang ) }
                >
                  <TrashIcon />
                </div>
                <IdiomaDropdown
                  classes={ `${ lang.active ? 'published' : '' }`}
                  currentLang = { lang }
                  langName = { lang.lang.name }
                  businessLangs= { businessLangs }
                  onChange={ handleLangChange }
                />
              </div>
            ))
          }
          <div className='pref-row'>
            <div className='btn-add-lang' style={{ color: colores.colorPrincipal }}>
              <p>Añadir idioma</p>
            </div>
          </div>
        </div>
      </div>
      <div className='section visualizacion'>
        <h3>Visualización de idioma para user</h3>
        <h5>¿En que idioma verá tu cliente la app por defecto?</h5>
        <div className='visualizacion-items'>
          <YlmRadio
            active={ idiomaNav === 'default' }
            clickFunction={ () => setIdiomaNav('default') }
            text={`Predeterminado por su navegador`}
            color={ colores.colorPrincipal }
            bgColor={ colores.colorSecundario }
          />
          <div 
            className={`ylm-radio ${idiomaNav !== 'default' && 'active' }`}
            onClick={ () => setIdiomaNav('es-ES') }
          >
            <div className='radio-bg' style={{ backgroundColor: colores.colorSecundario }}>
              <span className='radio-item' style={{ backgroundColor: colores.colorPrincipal }}></span>
            </div>
            <p>Idioma determinado</p>
            <NavIdiomaDropdown 
              values={ businessLangs }
              classes={'select-nav-default'}
              onChange={ handleNavDefaultChange }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
