import React from 'react'
import { TableCheckIcon, TableCloseIcon } from '../../../assets/svg/iconos'

const ModalTableRow = ({ title, val1, val2, val3, txt1 = '', txt2 = '', txt3 = ''  }) => {
  return (
    <div className='table-row'>
        <div className='modal-col'>
            <p>{ title }</p>
        </div>
        <div className='modal-col'>{ val1 ? txt1 !== '' ? txt1 : <TableCheckIcon /> : <TableCloseIcon />}</div>
        <div className='modal-col'>{ val2 ? txt2 !== '' ? txt2 : <TableCheckIcon /> : <TableCloseIcon />}</div>
        <div className='modal-col'>{ val3 ? txt3 !== '' ? txt3 : <TableCheckIcon /> : <TableCloseIcon />}</div>
    </div>
  )
}

export default ModalTableRow