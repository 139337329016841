
import { types } from "../types/types";

const initialState = {
    servicios: [],
    active: null
}

export const serviciosReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.serviciosActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.serviciosUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.serviciosAddNew:
            return {
                ...state,
                servicios: [ ...state.servicios, action.payload ]
            }

        case types.serviciosLoad:
            return {
                ...state,
                servicios: [ ...action.payload ]
            }
        
        case types.serviciosUpdated:
            return {
                ...state,
                servicios: state.servicios.map(
                    servicio => servicio._id === action.payload._id
                        ? action.payload
                        : servicio
                )
            }

        case types.serviciosDelete:
            return {
                ...state,
                active: null,
                servicios: state.servicios.filter( servicio => servicio._id !== action.payload )
            }
    
        case types.serviciosLogoutCleaning:
            return {
                ...state,
                active: null,
                servicios: []
            }
    

        default:
            return state;
    }
}