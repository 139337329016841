import React, { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import moment from 'moment-timezone';
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';

const timezone = process.env.REACT_APP_TIMEZONE;

export const HorarioSelect = ({ fechaCalendar, timeCalendar, setTimeCalendar, setProfSelected, morning, afternoon, editCita, activeModalReserva, selectTime, setSelectTime }) => {

    const { colores } = useSelector(state => state.ui);
    
    const [firstTime, setFirstTime] = useState(true);

    const [horarioUnido, setHorarioUnido] = useState([]);
    const [horarioFiltrado, sethorarioFiltrado] = useState([]);
    const [inicioFiltro, setInicioFiltro] = useState(8);
    const [finFiltro, setFinFiltro] = useState(20); 

    useEffect(() => {
        if( !editCita ){
            const newDate = new Date(1972,10,13,0,0);
            if( timeCalendar.getTime() !== newDate.getTime() && firstTime ){
                setFirstTime( false );
                setTimeCalendar( newDate );
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fechaCalendar, timeCalendar]);

    useEffect(() => {
        if( !activeModalReserva ){
            changeSlider([8,20]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeModalReserva]);

    useEffect(() => {
        if( morning.length || afternoon.length ){
            setHorarioUnido([
                ...morning,
                ...afternoon
            ])
            sethorarioFiltrado([
                ...morning,
                ...afternoon
            ])
        } else {
            setHorarioUnido([]);
            sethorarioFiltrado([]);
        }
    }, [morning, afternoon]);

    

    const timeSelected = time => {
        setFirstTime( false );
        setSelectTime({ ...selectTime, touched: true });
        if( time.available ){
            //let profSelected = time.profesional;
            //if(selectProfesional.selected._id==='333') profSelected = randomProfesionalPriority();
            setTimeCalendar( time.date );
            //setProfSelected( profSelected );
            setProfSelected( time.profesional );
        }
    };

    const changeSlider = (e) => {
        // El slider manda un array con el valor inicial y el valor final
        setInicioFiltro(e[0]);
        setFinFiltro(e[1]);

        const fecha = moment.tz(fechaCalendar, timezone).format("YYYY-MM-DD");
        const inicioSlider = String(e[0]).length < 2 ? `0${e[0]}:00` : `${e[0]}:00`;
        const finalSlider = String(e[1]).length < 2 ? `0${e[1]}:00` : `${e[1]}:00`;
        const horaInicio = moment.tz(`${fecha} ${inicioSlider}`, timezone).format();
        const horaFin = moment.tz(`${fecha} ${finalSlider}`, timezone).format();
        
        sethorarioFiltrado( horarioUnido.filter( horario => {
            return moment.tz( horario.date, timezone ).isSameOrAfter(horaInicio) && moment.tz( horario.date, timezone ).isSameOrBefore(horaFin);
        }));
    };

    const resetFiltrosHorarios = () => {
        changeSlider([8,20]);
    };

    return (
        <div 
            className="horarios-container"
            style={{
                '--time-slider-color': colores.colorPrincipal
            }}
        >
            <div className="horarios-header">
                <div 
                    className="horario-view"
                    style={{ color: colores.colorPrincipal }}
                >
                    Hora <span className="hora">{`${inicioFiltro}:00 - ${finFiltro}:00`}</span>
                </div>
                <div 
                    className="delete-btn"
                    onClick={resetFiltrosHorarios}
                >
                    Borrar
                </div>
            </div>
            <div className="horario-slider">
                <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="punto"
                    trackClassName="pista"
                    defaultValue={[0, 20]}
                    ariaLabel={['Lower thumb', 'Upper thumb']}
                    ariaValuetext={state => `Thumb value ${state.valueNow}`}
                    renderThumb={(props, state) => <div {...props}><span>{`${state.valueNow}:00`}</span></div>}
                    pearling
                    min={8}
                    max={20}
                    minDistance={1}
                    onAfterChange={changeSlider}
                    value={[inicioFiltro,finFiltro]}
                />
            </div>
            {
                ( horarioFiltrado.length > 0 )
                &&
                <div className="single-container morning">
                    {/* <h4>Mañana</h4> */}
                    <div className="horas">
                    {
                        horarioFiltrado.map( hora => (
                            <div 
                                className={`horario-item ${ (!hora.available) ? 'blocked' : ''} ${ (moment(timeCalendar).format('YYYY-MM-DD H:mm') === moment(hora.date).format('YYYY-MM-DD H:mm')) ? 'active' : '' }`}
                                key={ uuid() }
                                onClick={ () => { timeSelected(hora) } }
                            >
                                <span className="check"></span>
                                <p>{ moment.tz(hora.date, timezone).format('H:mm') }</p>
                            </div>
                        ))
                    }
                    </div>
                </div>
            }
            {/* {
                ( afternoon.length > 0 )
                &&
                <div className="single-container afternoon">
                    <h4>Tarde</h4>
                    <div className="horas">
                    {
                        afternoon.map( hora => (
                            <div 
                                className={`horario-item ${ (!hora.available) ? 'blocked' : ''} ${ (moment(timeCalendar).format('YYYY-MM-DD H:mm') === moment(hora.date).format('YYYY-MM-DD H:mm')) ? 'active' : '' }`}
                                key={ uuid() }
                                onClick={ () => timeSelected(hora) }
                            >
                                <span className="check"></span>
                                <p>{ moment.tz(hora.date, timezone).format('H:mm') }</p>
                            </div>
                        ))
                    }
                    </div>
                </div>
            } */}
            {
                (morning.length < 1 && afternoon.length < 1)
                &&
                <div className="no-data">
                    <h4>El profesional seleccionado no está disponible este día</h4>
                </div>
            }
        </div>
    )
}