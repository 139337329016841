import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowBackIcon, TrashIcon } from '../../../../assets/svg/iconos';
import { useParams } from 'react-router-dom';
import { newCategoria } from '../../../../helpers/helpers';
import { ModalEliminarCategoria } from '../modales/ModalEliminarCategoria';

import { startLoadingCategoria, startNewCategoria, startSaveCategoria, unactiveCategoria, categoriaStartDelete } from '../../../../actions/categorias';
import { SelectServicios } from './SelectServicios';
import { SelectProductos } from './SelectProductos';

export const Categoria = ({history}) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector( state => state.auth );
    const { colores, miniLateral } = useSelector( state => state.ui );
    const { active: categoriaActive } = useSelector( state => state.categorias );

    const { categoriaId } = useParams();
    
    const [currentScreen] = useState( (history.location.state.categoria) ? 'edit' : 'new' );

    const [objCategoria, setObjCategoria] = useState({});
    const [iniciado, setIniciado] = useState(false);
    const [cambiado, setCambiado] = useState(false);

    const [editMode, setEditMode] = useState(true);
    
    const [idiomaActive, ] = useState('ES');
    const [ , setObjErrores] = useState({});

    const [modalEliminarCategoriaOpened, setModalEliminarCategoriaOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar categoría?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    useEffect(() => {
        if( categoriaId !== 'new' ){
            dispatch(startLoadingCategoria( categoriaId, empresa ));
        } else {
            const laCategoria = newCategoria(empresa);
            setObjCategoria( laCategoria );
            setEditMode( true );
            setCambiado( true );
        }
        return () => {
            dispatch( unactiveCategoria() );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if( categoriaActive !== null ){
            setIniciado( true );
            setObjCategoria( categoriaActive );
        }
    }, [categoriaActive]);

    useEffect(() => {
        // console.log( 'objCategoria', objCategoria );
        if( categoriaActive && objCategoria ){
            //console.log( 'categoriaActive', categoriaActive );
            const categoriaOriginal = { ...categoriaActive };
            if( iniciado ){
                let igual = true;
                for( const propiedad in categoriaOriginal ){
                    //console.log( `${nEmpresa[propiedad]} !== ${objEmpresa[propiedad]}`)
                    if( categoriaOriginal[propiedad] !== objCategoria[propiedad] ){
                    igual = false;
                    //console.log(`Cambio en ${propiedad}`);
                    }
                }
                setCambiado( !igual );
            }
        }
    }, [iniciado, categoriaActive, objCategoria]); 

    const backClick = () => {
        history.push({
            pathname: `/punto-venta/categorias`
        });
    }

    const guardar = () => {
        /* dispatch( startSaveCategoria( objCategoria ) );
        dispatch( activeCategoria( objCategoria._id, objCategoria ));
        setEditMode( false ); */
        let correcto = true;
        const newObjErrores = {};
        if( objCategoria.nombre.trim() === '' ){ 
            correcto = false;
            newObjErrores.nombre = 'Debe escribir un nombre';
        }

        if( objCategoria.descripcion.trim() === '' ){ 
            correcto = false;
            newObjErrores.telefono = 'Debe escribir una descripción';
        }

        setObjErrores( newObjErrores );
        let newObjeto = { ...objCategoria };
        // console.log( newObjeto );
        if( correcto ){
            //console.log('correcto');
            
            if( currentScreen === 'new' ){
                delete newObjeto._id;
                dispatch( startNewCategoria( newObjeto ) );
                history.push({ pathname: `/punto-venta/categorias` });
            } else {
                dispatch( startSaveCategoria( newObjeto ) );
                setCambiado( false );
                /* setObjServicio( newObjeto );
                setCurrentServicio( newObjeto ); */
            }

        }
    }

    const cancelar = () => {
        //setObjProducto( productoActive );
        if( currentScreen !== 'new' ){
            setObjCategoria( categoriaActive );
        } else {
            history.goBack();
        }
    }

    /* const deleteProducto = () => {
        console.log( 'deleteServicio' );
        history.goBack();
    } */
    
    const onFormChange = (e) => {
        let correcto = true;
        if( e.target.name === 'valor' ){
            const validNumber = new RegExp(/^\d*\.?\d*$/);
            if( !validNumber.test( e.target.value ) ){
                correcto = false;
            }
        }
        if( correcto ){
            dataChange( e.target.name, e.target.value );
        }
    }

    const dataChange = (name, value) => {
        setObjCategoria({
            ...objCategoria,
            [name]: value
        });
    }

    const deleteCategoriaModal = () => {
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar ${objCategoria.nombre}?`]
        })
        setModalEliminarCategoriaOpened( true );
    };

    const deleteCategoriaResponse = ( value ) => {
        if( value === 1 ){
          console.log( 'value = 1' );
          let selectedId = objCategoria._id;
          
          dispatch( categoriaStartDelete( selectedId, empresa ) );
          history.push({ pathname: `/punto-venta/categorias` })
        }
    }
    
    return (
        <>
        <div 
            className='categoria'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className='categoria-wrapper'>
                <div className='single-categoria'>
                    <div className={`header ${!miniLateral && 'compress'}`}>
                        <div 
                            className='nav-back'
                            onClick={ backClick }
                        >
                            <ArrowBackIcon color={ colores.colorSecundario } />
                            <p style={{ color: colores.colorSecundario }}>Categorias</p>
                        </div>
                        <div className='botonera'>
                            {/* {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-editar'
                                    style={{ backgroundColor: editMode ? colores.colorPrincipal : colores.color6 }}
                                    onClick={ () => setEditMode( !editMode ) }
                                >
                                    <EditarIcon color={ editMode ? colores.color6 : colores.colorPrincipal } />
                                </button>
                            } */}
                            {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-eliminar'
                                    style={{ backgroundColor: colores.color6 }}
                                    onClick={ deleteCategoriaModal }
                                >
                                    <TrashIcon color={ colores.colorPrincipal } />
                                </button>
                            }
                            {
                            cambiado &&
                                <button 
                                    className='btn btn-crear'
                                    style={{ backgroundColor: colores.colorPrincipal }}
                                    onClick={ guardar }
                                >
                                    {`${currentScreen === 'new' ? 'Crear' : 'Guardar'}`}
                                </button>
                            }
                            {
                            cambiado &&
                                <button 
                                    className='btn btn-cancelar'
                                    style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
                                    onClick={ cancelar }
                                >
                                    Cancelar
                                </button>
                            }
                        </div>
                        
                    </div>
                    <div className='name'>
                        <input
                            autoComplete='off'
                            type="text"
                            placeholder='Nueva categoria'
                            name="nombre"
                            disabled={ !editMode }
                            value={ objCategoria?.nombre || '' }
                            onChange={ (e) => onFormChange( e )}
                        />
                    </div>
                    <div className='categoria-main'>
                        <div className='categoria-description'>
                            <h3>Descripción</h3>
                            <textarea
                                name="descripcion"
                                placeholder='Añadir descripción'
                                disabled={ !editMode }
                                value={ objCategoria?.descripcion || '' }
                                onChange={ (e) => onFormChange( e )}
                            ></textarea>
                        </div>
                        <div className='categoria-selectors'>
                            <SelectServicios
                                objCategoria ={ objCategoria }
                                dataChange = { dataChange }
                            />
                            <SelectProductos
                                objCategoria ={ objCategoria }
                                dataChange = { dataChange }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalEliminarCategoria
            data = { dataModalEliminar }
            setModalResponse = { deleteCategoriaResponse }
            modalOpened = { modalEliminarCategoriaOpened }
            setModalOpened = { setModalEliminarCategoriaOpened }
        />
        </>
    )
}
