import React, { useState } from 'react';
import { useSelector } from 'react-redux';


export const Colores = ({ objEmpresa, setObjEmpresa, coloresList, setColorThemeSelected }) => {
  const getThemeSelected = (colorPrincipal) => {
    return coloresList.find(theme => theme.main === colorPrincipal)?._id ?? "1";
  }

  const { colores } = useSelector(state => state.ui);
  const [themeSelected, setThemeSelected] = useState(getThemeSelected(objEmpresa.colorPrincipal));

  const selectTheme = (id) => {
    coloresList.forEach(theme => {
      if (theme._id === id) {
        setObjEmpresa({
          ...objEmpresa,
          colorPrincipal: theme.main,
          colorSecundario: theme.secondary[0],
          color3: theme.secondary[1],
          color4: theme.secondary[2],
          color5: theme.secondary[3],
          color6: theme.secondary[4],
          color7: theme.secondary[5],
		  
        })
        setColorThemeSelected(theme);
      }
    });
    setThemeSelected(id);
  };

  return (
    <div className='panel-container color-container'>
      {
        coloresList.map(colorItem => (
          <div
            key={colorItem._id}
            className='color-selector'
            onClick={() => selectTheme(colorItem._id)}
          >
            <div
              className={`ylm-radio ${colorItem._id === themeSelected && 'active'}`}
            >
              <div
                className='radio-bg'
                style={{ backgroundColor: colores.color6 }}
              >
                <span
                  className='radio-item'
                  style={{ backgroundColor: colores.colorPrincipal }}
                ></span>
              </div>
            </div>
            <div className='color-theme'>
              <div className='main-color color' style={{ backgroundColor: `${colorItem.main}` }}></div>
              <div className='secondary-colors'>
                {
                  colorItem.secondary.map((second, idx) => (
                    <div key={`secondary${idx}`} className='color' style={{ backgroundColor: `${second}` }}></div>
                  ))
                }
              </div>
            </div>
          </div>
        ))
      }

    </div>
  )
}
