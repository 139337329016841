import { types } from "../types/types";
import { fetchConToken } from "../helpers/fetch";

export const startCheckingVersion = () => {
    return async( dispatch ) => {
        let currentVersion = 'provisional';
        const resp = await fetchConToken( 'auth/version' );
        const body = await resp.json();
        if(body.ok){
            currentVersion = body.version;
        }
        let deviceVersion = localStorage.getItem('barberia-version');

        if( !deviceVersion ){
            let swExists = false;
            if ('serviceWorker' in navigator) {
                swExists = navigator.serviceWorker.controller;
            }
            if( !swExists ){
                console.log('No existe SW');
                localStorage.setItem('barberia-version', currentVersion);
                deviceVersion = currentVersion;
            }
        }

        if( deviceVersion !== currentVersion && currentVersion !== 'provisional' ){
            console.log( `${currentVersion} !== ${deviceVersion}` )
            let msgText = 'Para llevarla a cabo, pulsa actualizar.';
            const datos = {
                title: 'Actualización disponible',
                text: [ msgText ],
                buttonText: 'Actualizar',
                updateAvailable: true
            };
            localStorage.setItem('barberia-version', currentVersion);
            dispatch( openModal( datos ) );
        }
    }
};

export const setTitle = ( title ) => ({
    type: types.uiSetTitle,
    payload: title
});

export const setBodyClass = ( name ) => ({
    type: types.uiBodyClass,
    payload: name
});

export const setDarkHeader = ( dark ) => ({
    type: types.uiSetDarkHeader,
    payload: dark
});

export const setAdminHeader = ( active ) => ({
    type: types.uiSetAdminHeader,
    payload: active
});

export const setError = ( err ) => ({
    type: types.uiSetError,
    payload: err
});

export const removeError = ( err ) => ({
    type: types.uiRemoverror
});

export const startLoading = () => ({
    type: types.uiStartLoading
});

export const finishLoading = () => ({
    type: types.uiFinishLoading
});

export const toggleMenu = ( opened ) => ({
    type: types.uiToggleMenu,
    payload: opened
});

export const openLogin = () => ({
    type: types.uiOpenLogin
});

export const closeLogin = () => ({
    type: types.uiCloseLogin
});

export const openRegister = () => ({
    type: types.uiOpenRegister
});

export const closeRegister = () => ({
    type: types.uiCloseRegister
});

export const openRenew = () => ({
    type: types.uiOpenRenew
});

export const closeRenew = () => ({
    type: types.uiCloseRenew
});

export const openModal = ( datos ) => ({
    type: types.uiOpenModal,
    payload: datos
});

export const closeModal = () => ({
    type: types.uiCloseModal
});

export const openLoading = () => ({
    type: types.uiOpenLoading
});

export const closeLoading = () => ({
    type: types.uiCloseLoading
});

export const openModalBottom = ( datos ) => ({
    type: types.uiOpenModalBottom,
    payload: datos
});

export const closeModalBottom = () => ({
    type: types.uiCloseModalBottom
});

export const clockUpdate = ( date ) => ({
    type: types.uiClockUpdate,
    payload: date
});

export const setLanguage = ( language ) => ({
    type: types.uiSetLanguage,
    payload: language
});

export const setMenuLateral = ( value ) => ({
    type: types.uiSetMenuLateral,
    payload: value
});

export const setMiniLateral = ( value ) => ({
    type: types.uiSetMiniLateral,
    payload: value
});

export const showModal = ( visible ) => ({
    type: types.uiModalShow,
    payload: visible
});

export const setColores = ( value ) => ({
    type: types.uiSetColores,
    payload: value
});