/* eslint-disable no-unused-vars */
import moment from 'moment-timezone';
import { fetchConToken } from './fetch';
import { sortArray } from '../../src/utils/utils';

const timezone = process.env.REACT_APP_TIMEZONE;

export const fetchAvailableSlots = async ({ empresa, profesionalId, sucursalId, sucursales, serviciosId, totalTime, intervaloTiempo, year, month, citaIdExcluir }) => {
    const monthName = moment().month(month).format('MMMM');

    try {
        const response = await fetchConToken('citas/cliente/citas-sucursal-profesional-mes',
            {
                empresa,
                monthName,
                year,
                profesionalId,
                sucursal: sucursalId,
                servicios: serviciosId,
                serviceDuration: totalTime,
                intervaloTiempo,
				citaIdExcluir
            },
            'POST'
        );

        if (!response.ok) throw new Error('Failed to fetch available slots');

        const data = await response.json();

        if (data.ok) {
            return data.availableSlotsByDay;
        } else {
            return {};
        }

    } catch (error) {
        console.error('Error fetching available slots:', error);
        return {};
    }
};

export const getProfesionalesOld = async (servicioActive, selectSucursal, promociones, rdProfesionales) => {
    // Esta función salta cuando se elige cliente -> servicio -> sucursal y devolverá un array de profesionales:
    // El primer profesional será el aleatorio para que lo puedan elegir
    // Vendrán los profesionales que presten el servicio elegido
    // Que estén en la sucursal elegida
    // Buscará en las promociones si hay alguna que se activa con el profesional aleatorio. Si es así el profesional aleatorio
    // incluirá un campo promo con un objeto que indica el tipo y la cifra a aplicar
    let profesionalesFiltered;
    profesionalesFiltered = filtraProfesionales(rdProfesionales, servicioActive?._id, selectSucursal, promociones);
    return profesionalesFiltered;
};

export const getProfesionales = async (services, selectSucursal, promociones, rdProfesionales, rdSucursales) => {
    // Esta función salta cuando se elige cliente -> servicio -> sucursal y devolverá un array de profesionales:
    // El primer profesional será el aleatorio para que lo puedan elegir
    // Vendrán los profesionales que presten el servicio elegido
    // Que estén en la sucursal elegida
    // Buscará en las promociones si hay alguna que se activa con el profesional aleatorio. Si es así el profesional aleatorio
    // incluirá un campo promo con un objeto que indica el tipo y la cifra a aplicar
    let profesionalesFiltered;
    profesionalesFiltered = filtraProfesionales(rdProfesionales, services, selectSucursal, promociones, rdSucursales);
    return sortArray(profesionalesFiltered);
};

export const getProfesionalesDisabled = async (profesionals, profesionalsFiltered) => {
    let profesionalesDisabled;
    profesionalesDisabled = profesionals.filter(staff => !profesionalsFiltered.some(s => s._id === staff._id));
    return sortArray(profesionalesDisabled);
};

export const gestionaHorarios = async (profesionales, fechaCalendar, servicioActive, selectSucursal, profSelected, horarios, citas, ordenSeleccion, usuario, promociones, editando = false, citaActive = null, vacaciones, intervaloTiempo = 10) => {
    // Esta función salta cuando se elige cliente -> servicio -> sucursal -> profesional y devolverá un array de horarios:
    // Convierto en string AAAA/MM/DD la fecha elegida
    const fechaElegidaString = moment(fechaCalendar).format('YYYY-MM-DD');
    // newFecha será la fecha elegida, traspasada al horario local y a las 0h 0m 0s
    const newFecha = moment.tz(fechaElegidaString, timezone).toDate();
    // Devuelvo las vacaciones de la sucursal elegida y las de los profesionales
    const vacacionesFiltradas = vacaciones.filter(item => {
        let retorno = true;
        if (item.tipo === 'sucursal' && item.sucursal !== selectSucursal._id) {
            retorno = false;
        }
        //console.log( `${item.profesional} === ${profSelected._id}` );
        if (['vacaciones', 'ausencia'].includes(item.tipo)) {
            if (profSelected._id !== '333' && item.profesional !== profSelected._id) {
                retorno = false;
            }
        }
        return retorno;
    });

    // console.log( 'vacacionesFiltradas', vacacionesFiltradas );

    // Voy a llamar a paso1:
    // Esta función recibe:
    // newFecha: La fecha del calendario [Date]
    // servicioActive: El servicio seleccionado: [Object]
    // selectSucursal: La sucursal seleccionada: [Object]
    // profSelected: La profesional seleccionado: [Object] Si el profesional es aleatorio tiene la _id: 333
    // horarios: Todos los horarios de la tabla horarios
    // profesionales: Todos los profesionales de la tabla profesionales

    // Devuelve un array con los horarios disponibles de este dia

    const { arrayHorarios } = await paso1(newFecha, servicioActive, selectSucursal, profSelected, horarios, profesionales);
    let respuesta;

    // Recorro los horarios disponibles
    if (arrayHorarios.length > 0) {
        // Voy a llamar a getHorasCualquieraFromArrayHorarios:
        // Esta función recibe:
        // newFecha: La fecha del calendario [Date]
        // servicioActive: El servicio seleccionado: [Object]
        // selectSucursal: La sucursal seleccionada: [Object]
        // idProf: La id profesional seleccionado: String -> Si el profesional es aleatorio envia null
        // arrayHorarios: Todos los horarios ya filtrados en paso1 [Object]
        // citas: Todas las citas de la BD
        // usuario: El usuario seleccionado en el selector
        //console.log( 'citas', citas );

        // Devuelve un array con los horarios disponibles de este dia
        if (profSelected._id === '333') {
            // Se ha elegido profesional aleatorio
            // console.log( 'Se ha elegido profesional aleatorio' );
            const filtradas = await getHorasCualquieraFromArrayHorarios(newFecha, servicioActive, selectSucursal, null, arrayHorarios, citas, ordenSeleccion, usuario, promociones, editando, citaActive, vacacionesFiltradas, intervaloTiempo, profesionales);
            respuesta = await muestraHorasFromArrayHorarios(filtradas, promociones);
        } else {
            // Se ha elegido un profesional concreto
            // console.log( 'Se ha elegido un profesional concreto' );
            const filtradas = await getHorasCualquieraFromArrayHorarios(newFecha, servicioActive, selectSucursal, profSelected._id, arrayHorarios, citas, ordenSeleccion, usuario, promociones, editando, citaActive, vacacionesFiltradas, intervaloTiempo, profesionales);
            respuesta = await muestraHorasFromArrayHorarios(filtradas, promociones);
        }
        // console.log( 'respuesta', respuesta );
        return respuesta;
    } else {
        // console.log( 'No hay respuesta' );
        return { morning: [], afternoon: [] };
    }
};

const paso1 = async (fechaCalendar, servicioActive, selectSucursal, profSelected, horarios, profesionales) => {
    // Esta funcion devolverá un array de Horarios

    //console.log( 'servicioActive', servicioActive );
    //console.log( moment.tz(fechaCalendar, timezone).format() );

    // Obtengo el número de dia de la semana elegido
    const weekDay = moment.tz(fechaCalendar, timezone).isoWeekday();
    let arrayHorarios = [];

    // console.log( 'profesionales', profesionales ); 
    // console.log( 'selectSucursal', selectSucursal );
    // console.log( 'profSelected', profSelected );
    // console.log( weekDay );

    // Voy a recorrer los profesionales. Ya vienen filtrados.
    profesionales.forEach(prof => {
        // Voy a recorrer el array horarioSucursales del profesional
        // Este contiene un item por cada sucursal en la que trabaja
        // Dentro de esta sucursal están los horarios de los 7 días de la semana, y la prioridad del profesional en esta sucursal

        prof.horarioSucursales.forEach(hor => {
            // Cada item hor es un objeto de horario:
            // - diasSemana / String -> el dia de la semana del 1 al 7 
            // - horas / [Object]
            // --- horaInicio / DateISOString -> Hora inicio de disponibilidad
            // --- horaFin / DateISOString -> Hora final de disponibilidad

            // Elijo el dia que me ocupa de todo el array
            const horarioDelDia = hor.horarios[weekDay - 1];
            //console.log( horarioDelDia );

            // Comprueba que el horario sea de la sucursal elegida, y que el horario del dia tenga horas disponibles
            if ((hor.sucursal._id === selectSucursal._id) && horarioDelDia.horas.length > 0) {
                if (profSelected._id !== '333') {
                    // Si el profesional seleccionado no es el aleatorio, compruebo que el profesional en el que estoy, sea el elegido
                    if (prof._id === profSelected._id) {
                        //console.log( horarioDelDia );
                        //console.log( horarioDelDia.horas );
                        // Si lo es y no está vacío, lo añado al array de horarios
                        arrayHorarios = [
                            ...arrayHorarios,
                            {
                                diasSemana: [horarioDelDia.diasSemana],
                                empresa: prof.empresa,
                                horas: horarioDelDia.horas,
                                profesional: prof,
                                sucursal: hor.sucursal._id
                            }
                        ]
                    }
                } else {
                    // Si el profesional seleccionado es el aleatorio, incluyo el horario si o si
                    arrayHorarios = [
                        ...arrayHorarios,
                        {
                            diasSemana: [horarioDelDia.diasSemana],
                            empresa: prof.empresa,
                            horas: horarioDelDia.horas,
                            profesional: prof,
                            sucursal: hor.sucursal._id
                        }
                    ]
                }
            }
        });
    });

    // console.log( arrayHorarios );
    // console.log( fechaCalendar );

    // Compruebo si se ha elegido profesional aleatorio
    if (profSelected._id !== '333') {
        // Recupero las ausencias de dia y profesional

    } else {
        // Recupero las ausencias del dia
        // console.log('Profesional aleatorio');
    }



    // Ahora ya tengo los horarios fijos de todos los profesionales. Voy a por los variables, que son los que tienen un día concreto. El siguiente horarios.map llenará un array llamado profesionalesConFecha.

    let profesionalesConFecha = [];
    horarios.map(horario => {
        // Los nombres de las variables hablan por si solos
        const mismoDiaSemana = horario.diasSemana.includes(String(weekDay));
        const mismaSucursal = horario.sucursal === selectSucursal._id;
        const elegidoProfesional = profSelected._id !== '333';
        const mismoProfesional = profSelected._id === horario.profesional._id;

        if (mismaSucursal) {
            if (elegidoProfesional) {
                // Se ha elegido a un profesional concreto
                if (mismoProfesional) {
                    // Voy a comprobar que el horario tenga fecha. Si no tiene fecha, no me interesa.
                    if (horario.fecha) {
                        const fechaHorario = moment.tz(horario.fecha, timezone).format("DD-MM-YYYY");
                        const fechaElegida = moment.tz(fechaCalendar, timezone).format("DD-MM-YYYY");
                        if (fechaHorario === fechaElegida) {
                            //console.log( 'horario con fecha', horario );
                            // Como el horario tiene la misma fecha que la elegida, hago que el array tenga como único elemento este horario. Si luego sale otro, sustituirá a este.
                            arrayHorarios = [horario];
                        }
                    }
                }
            } else {
                // Si el profesional elegido es aleatorio
                if (horario.fecha && horario.profesional.servicios.indexOf(servicioActive._id) > -1) {
                    const fechaHorario = moment.tz(horario.fecha, timezone).format("DD-MM-YYYY");
                    const fechaElegida = moment.tz(fechaCalendar, timezone).format("DD-MM-YYYY");
                    // Si tiene fecha, compruebo que la fecha sea la elegida
                    if (fechaHorario === fechaElegida) {
                        // Si lo es, la añado y también añado al profesional a un array de las ids de los que tienen fecha concreta.
                        profesionalesConFecha = [...profesionalesConFecha, horario.profesional._id];
                        arrayHorarios = [...arrayHorarios, horario];
                    }
                } else {
                    // Si no es aleatorio, compruebo que sea el mismo dia de la semana y que el profesional del horario preste este servicio
                    if (mismoDiaSemana && horario.profesional.servicios.indexOf(servicioActive._id) > -1) {
                        if (!horario.profesional.deleted) {
                            // Elimino los horarios previos que el profesional pueda tener
                            arrayHorarios = arrayHorarios.filter(horarioItem => {
                                return horarioItem.profesional._id.toString() !== horario.profesional._id.toString();
                            });
                            arrayHorarios = [...arrayHorarios, horario];
                        }
                    }
                }
            }
        }
        return horario; // No sirve. Esto lo hice cuando no tenía claro para qué servía Array.map pero ahora no puedo refactorizar.
    });

    // Recorrere los profesionales que tienen horario especial en este día y los meto en un array diferente, que llamaré horariosConFecha.
    let horariosConFecha = [];
    profesionalesConFecha.forEach(prof => {
        let horarioSel = {};
        arrayHorarios.forEach(horario => {
            if (horario.profesional._id === prof) {
                horarioSel = {
                    prof,
                    horario
                };
            }
        });
        horariosConFecha = [...horariosConFecha, horarioSel];
    });

    // Ahora recorro el array de Horarios y elimino todos los horarios de los profesionales que tienen hoy como fecha especial. Con esto consigo que todos los horarios fijos de estos profesionales, desaparezcan
    arrayHorarios = arrayHorarios.filter(horario => {
        let retorno = false;
        if (profesionalesConFecha.indexOf(horario.profesional._id) < 0) {
            retorno = true;
        }
        return retorno;
    });

    // Ahora incluyo en el array final los horarios de los profesionales que tienen hoy como fecha especial.
    horariosConFecha.forEach(item => {
        arrayHorarios = [...arrayHorarios, item.horario];
    });

    //console.log( 'arrayHorarios', arrayHorarios );
    // Retorno los horarios
    return {
        arrayHorarios
    }
}

// export const getSucursalesOld = async (servicioActive, profesionales, horarios, sucursales) => {
//     // console.log( 'profesionales', profesionales );
//     // console.log( 'sucursales', sucursales );
//     // console.log( 'horarios', horarios );
//     const horariosProfesionales = getHorariosProfesionales(profesionales, servicioActive?._id, sucursales);
//     // console.log( 'horariosProfesionales', horariosProfesionales );
//     const newHorarios = [...horariosProfesionales, ...horarios];
//     // console.log( 'newHorarios', newHorarios );
//     const sucursalesFiltered = await filtraSucursales(newHorarios, servicioActive?._id, sucursales);
//     return sucursalesFiltered;
// };

export const getSucursales = async (servicesActive, profesionales, horarios, sucursales) => {
    //const horariosProfesionales = getHorariosProfesionales(profesionales, servicesActive[0]?._id, sucursales);
    const horariosProfesionales = getHorariosProfesionales(profesionales, servicesActive, sucursales);
    const newHorarios = [...horariosProfesionales, ...horarios];
    //const sucursalesFiltered = await filtraSucursales(newHorarios, servicesActive[0]?._id, sucursales);
    const sucursalesFiltered = await filtraSucursales(newHorarios, servicesActive, sucursales);
    return sucursalesFiltered;
};

//TODO: (TONNY) --> Este metodo debe aceptar el array de servicios.
//La sucursal debe realizar todos los servicios seleccionados.
const filtraSucursales = (horas, servicios, sucursales) => {
    let arraySucs = [];
    for (let i = 0; i < servicios.length; i++) {
        const idServicio = servicios[i];
        horas.map(horario => {
            if (horario.profesional) {
                let servicioOk = false;

                // Voy a averiguar si el profesional presta el servicio
                horario.profesional.servicios.map(idSer => {
                    if (idSer === idServicio._id) {
                        servicioOk = true;
                    }
                    return idSer;
                });

                if (servicioOk) {
                    // Ahora que se que el profesional presta este servicio
                    // *********** inici prova
                    let existe = false;
                    arraySucs.map(arraySuc => {
                        if (arraySuc._id === horario.sucursal) {
                            existe = true;
                        }
                        return arraySuc;
                    });
                    if (!existe) {
                        sucursales.forEach(item => {
                            if ((item._id === horario.sucursal) && !item.deleted) {
                                arraySucs = [
                                    ...arraySucs,
                                    item
                                ]
                            }
                        });
                    }
                }
            }
            return horario;
        });
    }
    return arraySucs;
}

// const filtraSucursales = (horas, idServicio, sucursales) => {
//     let arraySucs = [];
//     // console.log( 'sucursales', sucursales );

//     horas.map(horario => {
//         if (horario.profesional) {
//             let servicioOk = false;

//             // Voy a averiguar si el profesional presta el servicio
//             horario.profesional.servicios.map(idSer => {
//                 if (idSer === idServicio) {
//                     servicioOk = true;
//                 }
//                 return idSer;
//             });

//             if (servicioOk) {
//                 // Ahora que se que el profesional presta este servicio
//                 // *********** inici prova
//                 let existe = false;
//                 arraySucs.map(arraySuc => {
//                     if (arraySuc._id === horario.sucursal) {
//                         existe = true;
//                     }
//                     return arraySuc;
//                 });
//                 if (!existe) {
//                     sucursales.forEach(item => {
//                         if ((item._id === horario.sucursal) && !item.deleted) {
//                             arraySucs = [
//                                 ...arraySucs,
//                                 item
//                             ]
//                         }
//                     });
//                 }
//             }
//         }
//         return horario;
//     });
//     return arraySucs;
// }

//TODO: (TONNY) --> Aqui deberíamos aceptar un array de servicios para la comprobacion de los profesionales
//El profesional debe realizar todos los servicios seleccionados

const getHorariosProfesionales = (rdProfesionales, servicios, rdSucursales) => {
    // Esta función salta cuando se elige un servicio y le llegan:
    // rdProfesionales: Todos los profesionales de la BD / [Object]
    // idServicio: La ID del servicio elegido / String
    // rdSucursales: Todas las sucursales de la BD / [Object]
    //
    // Va a devolver horarios en formato horario (BD) para que se mezclen con los ya existentes y así

    let arrayProfs = [];
    let arrayIds = [];
    for (let i = 0; i < servicios.length; i++) {
        const idServicio = servicios[i];
        rdProfesionales.forEach(prof => {
            if (prof._id) { prof._id = prof._id };
            if (!prof.deleted) {
                // Compruebo si el profesional presta el servicio solicitado. Si es así lo añado al listado
                prof.servicios.map(idSer => {
                    if (idSer === idServicio._id) {
                        if (arrayIds.indexOf(prof._id) === -1) {
                            arrayIds = [...arrayIds, prof._id];
                            arrayProfs = [
                                ...arrayProfs,
                                prof
                            ]
                        }
                    }
                    return idSer;
                });

            }
        });
    }
    // console.log( 'arrayProfs', arrayProfs );

    // Aquí ya tengo la variable arrayProfs que incluye:
    // Profesionales que no estén borrados
    // Que presten el servicio elegido

    // Ahora voy a recorrer arrayProfs

    let horarios = [];

    if (arrayProfs.length > 0) {
        // console.log( 'rdSucursales', rdSucursales );
        arrayProfs.forEach(prof => {
            // console.log( 'prof', prof );
            // Voy a recorrer los horarios de cada sucursal del profesional
            // console.log( prof );
            prof.horarioSucursales.forEach(suc => {
                // Dentro de la sucursal, recorro los horarios de cada dia
                suc.horarios.forEach(horario => {
                    // Si el dia tiene algún horario establecido
                    if (horario.horas.length > 0) {
                        let profSucursales = [];
                        // Recorro las sucursales en las que trabaja el profesional
                        prof.sucursales.forEach(theSuc => {
                            let newSuc = {};
                            // Recorro todas las sucursales de la empresa
                            rdSucursales.forEach(rdSuc => {
                                // Si el profesional tiene esta sucursal, la incluyo en el array de sucursales del prof
                                if (rdSuc._id === theSuc) {
                                    newSuc = rdSuc;
                                    newSuc._id = rdSuc._id
                                }
                            });
                            if (newSuc._id) {
                                profSucursales = [
                                    ...profSucursales,
                                    newSuc
                                ]
                            }
                        });

                        // console.log( 'suc', suc );

                        if (!suc.sucursal?.deleted) {
                            let newHorario = {
                                diasSemana: [horario.diasSemana],
                                empresa: prof.empresa,
                                profesional: {
                                    _id: prof._id,
                                    servicios: prof.servicios,
                                    sucursales: profSucursales
                                },
                                sucursal: suc.sucursal?._id ? suc.sucursal?._id : suc.sucursal,
                                horas: horario.horas
                            }
                            horarios = [...horarios, newHorario];
                        }
                    }
                });
            });
        });
    }

    return horarios;
}

// const getHorariosProfesionales = (rdProfesionales, idServicio, rdSucursales) => {
//     // Esta función salta cuando se elige un servicio y le llegan:
//     // rdProfesionales: Todos los profesionales de la BD / [Object]
//     // idServicio: La ID del servicio elegido / String
//     // rdSucursales: Todas las sucursales de la BD / [Object]
//     //
//     // Va a devolver horarios en formato horario (BD) para que se mezclen con los ya existentes y así

//     let arrayProfs = [];
//     let arrayIds = [];

//     rdProfesionales.forEach(prof => {
//         if (prof._id) { prof._id = prof._id };
//         if (!prof.deleted) {
//             // Compruebo si el profesional presta el servicio solicitado. Si es así lo añado al listado
//             prof.servicios.map(idSer => {
//                 if (idSer === idServicio) {
//                     if (arrayIds.indexOf(prof._id) === -1) {
//                         arrayIds = [...arrayIds, prof._id];
//                         arrayProfs = [
//                             ...arrayProfs,
//                             prof
//                         ]
//                     }
//                 }
//                 return idSer;
//             });

//         }
//     });

//     // console.log( 'arrayProfs', arrayProfs );

//     // Aquí ya tengo la variable arrayProfs que incluye:
//     // Profesionales que no estén borrados
//     // Que presten el servicio elegido

//     // Ahora voy a recorrer arrayProfs

//     let horarios = [];

//     if (arrayProfs.length > 0) {
//         // console.log( 'rdSucursales', rdSucursales );
//         arrayProfs.forEach(prof => {
//             // console.log( 'prof', prof );
//             // Voy a recorrer los horarios de cada sucursal del profesional
//             // console.log( prof );
//             prof.horarioSucursales.forEach(suc => {
//                 // Dentro de la sucursal, recorro los horarios de cada dia
//                 suc.horarios.forEach(horario => {
//                     // Si el dia tiene algún horario establecido
//                     if (horario.horas.length > 0) {
//                         let profSucursales = [];
//                         // Recorro las sucursales en las que trabaja el profesional
//                         prof.sucursales.forEach(theSuc => {
//                             let newSuc = {};
//                             // Recorro todas las sucursales de la empresa
//                             rdSucursales.forEach(rdSuc => {
//                                 // Si el profesional tiene esta sucursal, la incluyo en el array de sucursales del prof
//                                 if (rdSuc._id === theSuc) {
//                                     newSuc = rdSuc;
//                                     newSuc._id = rdSuc._id
//                                 }
//                             });
//                             if (newSuc._id) {
//                                 profSucursales = [
//                                     ...profSucursales,
//                                     newSuc
//                                 ]
//                             }
//                         });

//                         // console.log( 'suc', suc );

//                         if (!suc.sucursal?.deleted) {
//                             let newHorario = {
//                                 diasSemana: [horario.diasSemana],
//                                 empresa: prof.empresa,
//                                 profesional: {
//                                     _id: prof._id,
//                                     servicios: prof.servicios,
//                                     sucursales: profSucursales
//                                 },
//                                 sucursal: suc.sucursal?._id ? suc.sucursal?._id : suc.sucursal,
//                                 horas: horario.horas
//                             }
//                             horarios = [...horarios, newHorario];
//                         }
//                     }
//                 });
//             });
//         });
//     }

//     return horarios;

// }

const filtraProfesionalesOld = (rdProfesionales, idServicio, sucursal, promociones) => {
    // Esta función salta cuando se elige una sucursal y le llegan:
    // rdProfesionales: Todos los profesionales de la BD / [Object]
    // idServicio: La ID del servicio elegido / String
    // promociones: Todas las promociones de la BD [Object]
    //

    let arrayProfs = [];
    let arrayIds = [];

    rdProfesionales.forEach(prof => {
        if (prof._id) { prof._id = prof._id };
        if (!prof.deleted) {
            let servicioOk = false;
            let sucursalOk = false;

            prof.servicios.map(idSer => {
                if (idSer === idServicio) {
                    servicioOk = true;
                }
                return idSer;
            });
            prof.sucursales.map(idSuc => {
                if (idSuc === sucursal._id) {
                    sucursalOk = true;
                }
                return idSuc;
            });
            if (servicioOk && sucursalOk) {
                if (arrayIds.indexOf(prof._id) === -1) {
                    arrayIds = [...arrayIds, prof._id];
                    arrayProfs = [
                        ...arrayProfs,
                        prof
                    ]
                }
            }

        }
    });

    // Aquí ya tengo la variable arrayProfs que incluye:
    // Profesionales que no estén borrados
    // Que presten el servicio elegido
    // Que estén en la sucursal elegida

    if (arrayProfs.length > 1) {
        const allProfs = {
            nombre: 'Profesional aleatorio',
            _id: '333',
            horarioTxt: sucursal.horarioTxt,
            prioridad: 0
        };
        if (promociones.length > 0) {
            promociones.forEach(promo => {
                if (promo.profAleatorio && promo.servicios.indexOf(idServicio) > -1) {
                    allProfs.promo = {
                        tipo: (promo.cifra) ? 'cifra' : 'porcentaje',
                        texto: (promo.cifra) ? promo.cifra : promo.porcentaje
                    }
                }
            });
        }
        arrayProfs = [
            allProfs,
            ...arrayProfs
        ]
    }

    // A la lista de profesionales obtenida le añado el profesional aleatorio para que pueda ser elegido.
    //console.log( arrayProfs );
    return arrayProfs;
}

const getProfesionalsByServices = (staffArray, serviceIds) => staffArray.filter((staff) => {
    return serviceIds.every((serviceId) => staff.servicios.some((servicio) => servicio.id === serviceId.id));
});

const filtraProfesionales = (rdProfesionales, servicios, sucursal, promociones, sucursales) => {
    // Esta función salta cuando se elige una sucursal y le llegan:
    // rdProfesionales: Todos los profesionales de la BD / [Object]
    // idServicio: La ID del servicio elegido / String
    // promociones: Todas las promociones de la BD [Object]
    //

    let arrayProfs = new Set();
    let arrayIds = [];

    // Permite agrupar las sucursales según los servicios seleccionados.
    // const sucursalIds = new Set();
    // servicios.forEach(servicio => {
    //     servicio.sucursales.forEach(sucursalId => {
    //         sucursalIds.add(sucursalId);
    //     });
    // });

    // Crear un set de IDs de Sucursales
    const sucursalIds = new Set(servicios.flatMap(servicio => servicio.sucursales));
    
    // Crear un conjunto de IDs de Servicios
    const servicesIds = new Set(servicios.map(servicio => servicio._id));

    // Filtrar las sucursales en professionals
    const promocionesFiltered = promociones.map(promocion => {
        return {
                ...promocion,
                promociones: promocion.servicios.filter(servicioId => servicesIds.has(servicioId))
            };
    });

    const professionalsWithSucursales = rdProfesionales.filter(professional => professional.sucursales !== undefined);

    // Permite traer los profesionales según las sucursales donde atienden.
    const professionalsBySucursal = professionalsWithSucursales.filter(professional => 
        professional.sucursales.some(sucursalId => sucursalIds.has(sucursalId))
    );

    // Permite traer los profesionales según los servicios.
    // arrayProfs = professionalsBySucursal.filter(professional => 
    //     professional.servicios.some(serviceId => servicesIds.has(serviceId))
    // );

    arrayProfs = getProfesionalsByServices(professionalsBySucursal, servicios);

    // Aquí ya tengo la variable arrayProfs que incluye:
    // Profesionales que no estén borrados
    // Que presten el servicio elegido
    // Que estén en la sucursal elegida

    if (arrayProfs.length > 1) {
        let horarioTemp = {};
        if(sucursal.horarioTxt===undefined||sucursal.horarioTxt==null){
            horarioTemp = sucursales.find(x=>x._id===sucursal._id);
        }else{
            horarioTemp = sucursal.horarioTxt;
        }
        const allProfs = {
            nombre: 'Profesional aleatorio',
            _id: '333',
            //horarioTxt: sucursal.horarioTxt,
            horarioTxt: horarioTemp,
            prioridad: 0
        };
        if (promociones.length > 0) {
            promociones.forEach(promo => {
                //if (promo.profAleatorio && promo.servicios.indexOf(idServicio) > -1) {
                if (promo.profAleatorio && promocionesFiltered.length>0) {
                    allProfs.promo = {
                        tipo: (promo.cifra) ? 'cifra' : 'porcentaje',
                        texto: (promo.cifra) ? promo.cifra : promo.porcentaje
                    }
                }
            });
        }
        arrayProfs = [
            allProfs,
            ...arrayProfs
        ]
    }

    // A la lista de profesionales obtenida le añado el profesional aleatorio para que pueda ser elegido.
    return arrayProfs;
}

const roundTime = (hora) => {
    const start = moment.tz(hora, timezone).subtract(1, 'minutes');
    const remainder = 10 - (start.minute() % 10);
    const dateTime = moment.tz(start, timezone).add(remainder, "minutes").toDate();
    return dateTime;
}

const getHorasCualquieraFromArrayHorarios = (fechaCalendar, servicioActive, selectSucursal, idProf, arrHorarios, citas, ordenSeleccion, usuario, promociones, editando, citaActive, vacacionesFiltradas, intervaloTiempo, profesionales) => {
    // console.log( 'getHorasCualquieraFromArrayHorarios' );
    // console.log( 'intervaloTiempo', intervaloTiempo );
    // Aquí los horarios disponibles para este día
    // console.log( 'arrHorarios', arrHorarios );
    // console.log( 'servicioActive', servicioActive );
    // console.log( 'citaActive', citaActive );
    // console.log( 'usuario', usuario );
    let arrayHoras = [];
    // console.log( 'fechaCalendar', fechaCalendar );
    const hoy = moment.tz(fechaCalendar, timezone).format('YYYYMMDD');
    // console.log( 'hoy: ' + hoy );

    // Voy a buscar qué profesionales están en los horarios que me han pasado. Si la petición incluye una ID de profesional, sólo devolveré a ese.
    let arrProfesionales = [];
    if (idProf) {
        arrProfesionales = [idProf];
    } else {
        arrHorarios.map(horario => {
            if (arrProfesionales.indexOf(horario.profesional._id) === -1) {
                arrProfesionales = [...arrProfesionales, horario.profesional._id];
            }
            return horario;
        });
    }

    // console.log( 'arrProfesionales', arrProfesionales );

    let citasDelDia = [];
    let citasDelUsuario = [];
    let tiemposProfesionales = [];

    // Voy a buscar las citas que son de ese día y, si hay profesional definido, de ese profesional.
    citas.map(cita => {
        //console.log( moment.tz(cita.fecha, timezone).format('YYYYMMDD') );
        const mismoDia = (moment.tz(cita.fecha, timezone).format('YYYYMMDD') === hoy);
        // Veo si el profesional de la cita está en el array de profesionales
        const mismoProfesional = arrProfesionales.indexOf(cita.profesional._id) > -1;
        // Elimino esta línea para evitar que bloquee reservas a la misma hora con el mismo usuario
        //const mismoUsuario = (editando) ? false : String(cita.usuario._id) === usuario;
        const mismoUsuario = false;
        if (mismoDia && mismoUsuario) {
            // console.log( 'mismoUsuario' );
            const inicioBloqueo = moment.tz(cita.fecha, timezone).subtract(parseInt(servicioActive.duracion) - intervaloTiempo, 'minutes');
            const finBloqueo = moment.tz(cita.fecha, timezone).add(parseInt(cita.servicio.duracion), 'minutes');
            citasDelUsuario = [
                ...citasDelUsuario,
                {
                    _id: cita._id,
                    inicio: inicioBloqueo.toDate(),
                    fin: finBloqueo.toDate(),
                    profesional: cita.profesional,
                    usuario: cita.usuario
                }
            ];
        }
        //console.log( 'mismoDia', mismoDia );
        //console.log( 'mismoProfesional', mismoProfesional );
        if ((mismoDia && mismoProfesional)) {
            // Aquí sé que la cita es de un profesional del listado
            //console.log( 'mismoProfesional' );
            let profDelay = 0;
            if (cita.profesionalDelayType === 'time') {
                profDelay = parseInt(cita.profesionalDelay);
            } else {
                if (parseInt(cita.profesionalDelay) > 0) {
                    const porcentaje = parseInt(cita.profesionalDelay) / 100;
                    const duracionServicio = parseInt(cita.servicio.duracion);
                    profDelay = duracionServicio * porcentaje;
                }
            }

            let usuarioDelay = 0;
            if (usuario.delayType === 'time') {
                usuarioDelay = parseInt(usuario.delay);
            } else {
                if (parseInt(usuario.delay) > 0) {
                    const porcentaje = parseInt(usuario.delay) / 100;
                    const duracionServicio = parseInt(cita.servicio.duracion);
                    usuarioDelay = duracionServicio * porcentaje;
                }
            }

            // console.log('profDelay', profDelay);
            // console.log('usuarioDelay', usuarioDelay);

            /* const inicioBloqueo = moment.tz(cita.fecha, timezone);
            const finBloqueo = moment.tz(cita.fecha, timezone).add(parseInt(cita.servicio.duracion), 'minutes'); */
            //console.log( 'cita', cita );
            const inicioBloqueo = moment.tz(cita.fecha, timezone).subtract(parseInt(servicioActive.duracion) + profDelay + usuarioDelay, 'minutes');
            const finBloqueo = moment.tz(cita.fecha, timezone).add(parseInt(cita.duracion), 'minutes');
            /* console.log( 'servicioActive.duracion', parseInt(servicioActive.duracion) );
            console.log( 'profDelay', profDelay );
            console.log( 'intervaloTiempo', intervaloTiempo ); */
            /* console.log( 'cita.fecha', moment.tz(cita.fecha, timezone).format() );
            console.log( 'inicioBloqueo', inicioBloqueo.format());
            console.log( 'finBloqueo', finBloqueo.format()); */
            citasDelDia = [
                ...citasDelDia,
                {
                    _id: cita._id,
                    inicio: inicioBloqueo.toDate(),
                    fin: finBloqueo.toDate(),
                    profesional: cita.profesional,
                    usuario: cita.usuario
                }
            ];
            let existeProf = false;
            tiemposProfesionales.map(prof => {
                if (prof._id === cita.profesional._id) {
                    existeProf = true;
                    if (citaActive && citaActive._id === cita._id) {

                    } else {
                        prof.tiempo += parseInt(cita.servicio.duracion);
                    }

                }
                return prof;
            });
            if (!existeProf) {
                let prioridad = "1";
                let profSel = profesionales.filter(pr => (pr._id === cita.profesional._id))[0];
                //console.log( 'profSel', profSel );
                profSel.horarioSucursales.forEach(horario => {
                    if (String(horario.sucursal._id) === String(selectSucursal._id)) {
                        prioridad = horario.prioridad;
                    }
                });
                let nuevoTiempo = {
                    _id: profSel._id,
                    tiempo: 0,
                    prioridad,
                    valoracion: profSel.valoracion,
                }
                if (citaActive && citaActive._id === cita._id) {

                } else {
                    nuevoTiempo.tiempo = parseInt(cita.servicio.duracion)
                }
                tiemposProfesionales = [
                    ...tiemposProfesionales,
                    nuevoTiempo
                ]
            }
            /* console.log('Empiezo bucle');
            tiemposProfesionales.forEach(prof => {
                console.log( prof );
            }); */
        };
        return cita;
    });

    // Aquí tengo todas las citas que son de este dia y de algun profesional del listado
    // console.log( 'citasDelDia', citasDelDia );
    // Aquí todas las citas que son de este día y del usuario que pide esta. No lo uso porqué Juanjo me pidió que lo desactivara
    // console.log( 'citasDelUsuario', citasDelUsuario );
    // Aquí todos los tiempos de los profesionales acumulados
    // console.log( 'tiemposProfesionales', tiemposProfesionales );

    // Voy a recorrer los horarios disponibles
    // console.log( 'arrHorarios', arrHorarios );
    arrHorarios.map(horario => {
        //console.log( 'horario', horario );
        // Y dentro del horario, las horas
        horario.horas.map(hora => {
            //console.log( hora );
            const dateInicio = moment.tz(roundTime(hora.horaInicio), timezone);
            const dateFin = moment.tz(roundTime(hora.horaFin), timezone);
            const anyo = moment.tz(fechaCalendar, timezone).year();
            const mes = moment.tz(fechaCalendar, timezone).month();
            const dia = moment.tz(fechaCalendar, timezone).date();
            dateInicio.year(anyo);
            dateInicio.month(mes);
            dateInicio.date(dia);
            dateFin.year(anyo);
            dateFin.month(mes);
            dateFin.date(dia);

            const serieInicio = dateInicio;
            const serieFin = dateFin;
            /* console.log( 'serieInicio', serieInicio.format() );
            console.log( 'serieFin', serieFin.format() ); */

            while (serieInicio.isBefore(serieFin)) {
                let available = true;

                // --------- INICIO CALCULO DEL DELAY
                let profDelay = 0;
                if (horario.profesional.delayType === 'time') {
                    profDelay = parseInt(horario.profesional.delay);
                } else {
                    if (parseInt(horario.profesional.delay) > 0) {
                        const porcentaje = parseInt(horario.profesional.delay) / 100;
                        const duracionServicio = parseInt(servicioActive.duracion);
                        profDelay = duracionServicio * porcentaje;
                    }
                }
                // console.log( 'profDelay', profDelay );
                // --------- FIN CALCULO DEL DELAY

                // --------- INICIO CALCULO DEL DELAY DE USUARIO
                let usuarioDelay = 0;
                if (usuario.delayType === 'time') {
                    usuarioDelay = parseInt(usuario.delay);
                } else {
                    if (parseInt(horario.profesional.delay) > 0) {
                        const porcentaje = parseInt(usuario.delay) / 100;
                        const duracionServicio = parseInt(servicioActive.duracion);
                        usuarioDelay = duracionServicio * porcentaje;
                    }
                }
                // console.log( 'usuarioDelay', usuarioDelay );
                // --------- FIN CALCULO DEL DELAY DE USUARIO

                // Recorro las citas del dia para saber si el profesional de este horario está ocupado
                citasDelDia.map(cita => {
                    const citaInicio = moment.tz(cita.inicio, timezone);
                    const citaFin = moment.tz(cita.fin, timezone);
                    // console.log( 'serieInicio', serieInicio.format() );
                    // console.log( 'citaInicio', citaInicio.format() );
                    // console.log( 'citaFin', citaFin.format() );
                    if ((serieInicio.isAfter(citaInicio) && serieInicio.isBefore(citaFin)) && (String(horario.profesional._id) === String(cita.profesional._id))) {
                        if (!(citaActive && String(cita._id) === String(citaActive._id))) {
                            available = false;
                        }
                    }
                    return cita;
                });
                citasDelUsuario.map(cita => {
                    if (serieInicio.isAfter(moment.tz(cita.inicio, timezone)) && serieInicio.isBefore(moment.tz(cita.fin, timezone))) {
                        available = false;
                    }
                    return cita;
                });
                if (serieInicio.isBefore(moment().tz(timezone))) {
                    available = false;
                }




                //console.log( 'servicioActive', servicioActive );

                const inicioCopia = moment.tz(serieInicio.toDate(), timezone);
                // console.log( 'inicioCopia', inicioCopia.format() );
                const inicioMasDuracion = inicioCopia.add(parseInt(servicioActive.duracion) + profDelay + usuarioDelay, 'minutes');
                // console.log( 'serieInicio', serieInicio.format() );
                // console.log( 'inicioMasDuracion', inicioMasDuracion.format() );
                // console.log( 'serieFin', serieFin.format() );
                if (inicioMasDuracion.isAfter(serieFin)) {
                    available = false;
                }

                // Tienes que pasar por las vacaciones y si el horario coincide con ellas setear available como false
                // console.log( 'horario.profesional._id', horario.profesional._id );
                // console.log( vacacionesFiltradas );
                vacacionesFiltradas.forEach(itemVac => {
                    if (itemVac.tipo === 'sucursal' || (['vacaciones', 'ausencia'].includes(itemVac.tipo) && itemVac.profesional === horario.profesional._id)) {
                        const inicioVac = moment.tz(itemVac.fechaInicio, timezone);
                        const finalVac = moment.tz(itemVac.fechaFin, timezone);
                        const selDateInicio = moment.tz(fechaCalendar, timezone);
                        const selDateFinal = moment.tz(fechaCalendar, timezone).hours(23).minutes(59).seconds(59);;
                        let procesarVacaciones = true;
                        let procesarAusencia = true;

                        const dateInicioVac = moment.tz(inicioVac, timezone).hours(0).minutes(0).seconds(0);
                        const dateFinalVac = moment.tz(finalVac, timezone).hours(23).minutes(59).seconds(59);
                        const diaSemana = selDateInicio.weekday().toString();

                        // Comprobaré que encaje en los días
                        if (['vacaciones', 'sucursal'].includes(itemVac.tipo) && (dateInicioVac.isSameOrBefore(selDateInicio) && dateFinalVac.isSameOrAfter(selDateFinal))) {
                            if (itemVac.diasSemana.length > 0) {
                                if (!itemVac.diasSemana.includes(diaSemana)) {
                                    procesarVacaciones = false;
                                } else {
                                    procesarVacaciones = true;
                                }
                            }
                        } else {
                            // Si no encaja, no proceso el horario
                            procesarVacaciones = false;
                        }

                        // O en el día en caso de ser ausencia
                        if (['ausencia'].includes(itemVac.tipo) && dateInicioVac.isSameOrBefore(selDateInicio)) {
                            // Ahora comprobaré que tenga dias concretos a la semana. De ser así solo se aplicará esos días, pero todas las semanas futuras.
                            if (itemVac.diasSemana.length > 0) {
                                if (!itemVac.diasSemana.includes(diaSemana)) {
                                    // Si tiene días concretos y el seleccionado no está entre ellos, no lo proceso.
                                    procesarAusencia = false;
                                } else {
                                    procesarAusencia = true;
                                }
                            } else {
                                // En el caso de las ausencias, no tener días concretos de la semana implica que se aplicará solo el día seleccionado. Ninguno más en el futuro.
                                if (!(dateInicioVac.isSameOrBefore(selDateInicio) && dateFinalVac.isSameOrAfter(selDateFinal))) {
                                    procesarAusencia = false;
                                }
                            }
                        } else {
                            // Si no encaja, no proceso el horario
                            procesarAusencia = false;
                        }

                        // Si encaja en los días, comprobaré que encaje en las horas
                        if (procesarVacaciones || procesarAusencia) {
                            const horasInicio = inicioVac.hours();
                            const minutosInicio = inicioVac.minutes();
                            const horasFinal = finalVac.hours();
                            const minutosFinal = finalVac.minutes();
                            const horarioInicio = selDateInicio.clone().hours(horasInicio).minutes(minutosInicio);
                            const horarioFinal = selDateFinal.clone().hours(horasFinal).minutes(minutosFinal);
                            if ((horarioInicio.isSameOrBefore(serieInicio) && horarioFinal.isAfter(serieInicio)) || (horarioInicio.isBefore(inicioMasDuracion) && horarioFinal.isSameOrAfter(inicioMasDuracion))) {
                                available = false;
                            }
                        }
                    }
                });

                let anadir = false;

                if (idProf) {
                    if (idProf === horario.profesional._id) {
                        anadir = true;
                    }
                } else {
                    if (!horario.profesional.deleted) {
                        anadir = true;
                    }
                }
                if (anadir) {
                    const object = {
                        available,
                        hora: serieInicio.toDate(),
                        profesional: horario.profesional,
                        servicio: servicioActive._id,
                        sucursal: selectSucursal._id
                    };
                    arrayHoras = [
                        ...arrayHoras,
                        object
                    ]
                }

                serieInicio.add(intervaloTiempo, 'minutes');
            }

            return hora; // ESLint
        });
        return horario; // ESLint
    });
    return procesaArrayHorasMultiple(arrayHoras, tiemposProfesionales, selectSucursal, promociones, ordenSeleccion);
};

const procesaArrayHorasMultiple = async (arrayHoras, tiemposProfesionales, selectSucursal, promociones, ordenSeleccion) => {
    // Me llegan arrays de horas en la medida del tiempo establecida (p.ej: cada 10 minutos). Me llegan tantos como profesionales disponibles hay
    //console.log( 'procesaArrayHorasMultiple', arrayHoras );
    /* let arrayProcesado = await(convierteArray(arrayHoras));
    console.log( arrayProcesado ); */
    let nuevoProcesado = [];
    // Tengo que ordenar arrayHoras por horas
    arrayHoras.sort(comparaFechas);
    // console.log( 'procesaArrayHorasMultiple 2', arrayHoras );

    let consola = false;
    arrayHoras.map(horaP => {
        let existe = false;
        nuevoProcesado.map(nuevoP => {
            if (moment.tz(horaP.hora, timezone).isSame(moment.tz(nuevoP.hora, timezone))) {
                existe = true;
                if (moment.tz(horaP.hora, timezone).format('HH:mm') === '09:00') {
                    consola = true;
                    // console.log( 'tenia', horaP );
                    // console.log( 'llega', nuevoP );
                } else {
                    consola = false;
                }
                const mejor = establecePrioridad(horaP, nuevoP, tiemposProfesionales, selectSucursal, ordenSeleccion, consola);
                if (moment.tz(horaP.hora, timezone).format('HH:mm') === '09:00') {
                    // console.log( `Me quedo con ${mejor ? 'la nueva' : 'la antigua'}`);
                }


                if (mejor.ok) {
                    nuevoP.available = mejor.ok;
                    nuevoP.profesional = mejor.profesional;
                }
            }
            return nuevoP;
        });
        // Añadiendo al if horaP.available hago que no aparezcan las horas ocupadas
        if (!existe && horaP.available) {
            nuevoProcesado = [...nuevoProcesado, horaP];
        }
        return horaP;
    });
    nuevoProcesado.sort(comparaFechas);
    return nuevoProcesado;
};

const comparaFechas = (a, b) => {
    if (moment.tz(a.hora, timezone).isSameOrBefore(moment.tz(b.hora, timezone))) { return -1; }
    if (moment.tz(b.hora, timezone).isBefore(moment.tz(a.hora, timezone))) { return 1; }
    return 0;
};

const establecePrioridad = (nuevaHora, horaExistente, tiemposProfesionales, selectSucursal, ordenSeleccion, consola) => {
    let retorno = {
        ok: false
    };

    let profActual = {};
    let profNuevo = {}; //Puede ser que el profNuevo no figure en el array. Querrá decir que no tiene ninguna hora asignada.

    // console.log( 'tiemposProfesionales', tiemposProfesionales );
    tiemposProfesionales.map(prof => {
        // console.log( `${prof._id} === ${horaExistente.profesional._id}` );
        if (prof._id === horaExistente.profesional._id) {
            profActual = prof;
        }
        if (prof._id === nuevaHora.profesional._id) {
            profNuevo = prof;
        }
        return prof;
    });

    let profActualPrioridad = "1";
    let profNuevoPrioridad = "1";

    horaExistente.profesional.horarioSucursales.forEach(horario => {
        if (String(horario.sucursal._id) === String(selectSucursal._id)) {
            profActualPrioridad = horario.prioridad;
        }
    });
    nuevaHora.profesional.horarioSucursales.forEach(horario => {
        if (String(horario.sucursal._id) === String(selectSucursal._id)) {
            profNuevoPrioridad = horario.prioridad;
        }
    });

    if (!profActual._id) {
        let actualPrioridad = '1';
        horaExistente.profesional.horarioSucursales.forEach(hora => {
            if (hora.sucursal._id === selectSucursal._id) {
                actualPrioridad = hora.prioridad;
            }
        });
        profActual = {
            _id: horaExistente.profesional._id,
            tiempo: 0,
            prioridad: actualPrioridad,
            valoracion: horaExistente.profesional.valoracion
        }
    }
    if (!profNuevo._id) {
        let nuevoPrioridad = '1';
        nuevaHora.profesional.horarioSucursales.forEach(hora => {
            if (hora.sucursal._id === selectSucursal._id) {
                nuevoPrioridad = hora.prioridad;
            }
        });
        profNuevo = {
            _id: nuevaHora.profesional._id,
            tiempo: 0,
            prioridad: nuevoPrioridad,
            valoracion: nuevaHora.profesional.valoracion
        }
    }

    // console.log( 'profNuevo', profNuevo );
    // console.log( 'profActual', profActual );
    // const mejorValoracion = ( parseInt( profNuevo.valoracion ) > parseInt( profActual.valoracion ) );
    // const igualValoracion = ( parseInt( profNuevo.valoracion ) === parseInt( profActual.valoracion ) );

    let elijoAlNuevo = evaluaCriterios(ordenSeleccion, profNuevo, profActual, consola);
    if (consola) {
        // console.log( 'elijoAlNuevo', elijoAlNuevo );
    }
    /* if( nuevaHora.available ){
        if( !mejorValoracion ){
            if( igualValoracion ){
                // Paso a comparar los tiempos ocupados
                if( profNuevo.tiempo < profActual.tiempo ){
                    elijoAlNuevo = true;
                } else if( profNuevo.tiempo === profActual.tiempo ){
                    if( parseInt( profNuevo.prioridad ) < parseInt( profActual.prioridad ) ){
                        elijoAlNuevo = true;
                    }
                }
            }
        } else {
            elijoAlNuevo = true;
        }
    } */

    if (!horaExistente.available) {
        if (nuevaHora.available) {
            retorno = {
                ok: true,
                profesional: nuevaHora.profesional
            }
        }
    } else {
        if (nuevaHora.available) {
            retorno = {
                ok: true,
                profesional: (elijoAlNuevo) ? nuevaHora.profesional : horaExistente.profesional
            }
        }
    }
    // if( consola ){
    //     console.log( 'retorno', retorno );
    // }


    // Esto es lo que haré cuando la devuelva
    /* if( mejor ){
        nuevoP.available = horaP.available;
        nuevoP.prioridad = parseInt(horaP.prioridad);
        nuevoP.profesional = horaP.profesional;
    } */
    return retorno;
};

const evaluaCriterios = (criterios, profNuevo, profActual, consola) => {
    // Funcion para evaluar el profesional que elijo en funcion de los criterios de la empresa
    // Responderá true si elige al nuevo o false si elige al viejo
    let response = 'igual';
    criterios.forEach((criterio, idx) => {
        if (criterio === 'valoracion') {
            response = evaluaValoracion(profNuevo, profActual);
            // if( consola ){ console.log( 'valoracion retorna ' + response ) };
        }
        if (response === 'igual' && criterio === 'profesional') {
            response = evaluaProfesional(profNuevo, profActual);
            // if( consola ){ console.log( 'profesional retorna ' + response ) };
        }
        if (response === 'igual' && criterio === 'sucursal') {
            response = evaluaSucursal(profNuevo, profActual);
            // if( consola ){ console.log( 'sucursal retorna ' + response ) };
        }
        if (idx === (criterio.length - 1)) {
            response = 'igual'
            return false;
        }
    });

    // console.log('acabo ciclo');

    if (response === 'nuevo') {
        // if( consola ){ console.log('retorno nuevo') }; 
        return true;
    } else if (response === 'actual') {
        // if( consola ){ console.log('retorno actual') }; 
        return false;
    } else {
        return false;
    }
}

const evaluaValoracion = (profNuevo, profActual) => {
    const mejorValoracion = (parseInt(profNuevo.valoracion) > parseInt(profActual.valoracion));
    const peorValoracion = (parseInt(profNuevo.valoracion) < parseInt(profActual.valoracion));
    const igualValoracion = (parseInt(profNuevo.valoracion) === parseInt(profActual.valoracion));
    if (mejorValoracion) {
        return 'nuevo';
    } else if (peorValoracion) {
        return 'actual';
    } else if (igualValoracion) {
        return 'igual';
    }
}

const evaluaProfesional = (profNuevo, profActual) => {
    if (profNuevo.tiempo < profActual.tiempo) {
        return 'nuevo';
    } else if (profNuevo.tiempo > profActual.tiempo) {
        return 'actual';
    } else if (profNuevo.tiempo === profActual.tiempo) {
        return 'igual';
    }
}

const evaluaSucursal = (profNuevo, profActual) => {
    if (parseInt(profNuevo.prioridad) < parseInt(profActual.prioridad)) {
        return 'nuevo';
    } else if (parseInt(profNuevo.prioridad) > parseInt(profActual.prioridad)) {
        return 'actual';
    } else if (parseInt(profNuevo.prioridad) === parseInt(profActual.prioridad)) {
        return 'igual';
    }
}

const muestraHorasFromArrayHorarios = async (horas, promociones) => {
    let arrayMorning = [];
    let arrayAfternoon = [];
    horas.map(hora => {
        const horaActual = moment.tz(hora.hora, timezone).hour();
        let tienePromocion = false;
        if (promociones.length > 0) {
            promociones.forEach(promo => {
                const caducada = moment.tz(promo.caducidad, timezone).isSameOrBefore(hora.hora);
                let sucursalOk = true;
                if (promo.sucursales.length > 0) {
                    sucursalOk = promo.sucursales.indexOf(hora.sucursal) !== -1;
                }
                // Que tenga horarios
                if (sucursalOk && promo.horariosPromocion.length > 0 && promo.servicios.indexOf(hora.servicio) > -1) {
                    //console.log( promo.titulo, promo.horariosPromocion.length );
                    promo.horariosPromocion.forEach(horario => {
                        const diaCita = moment.tz(hora.hora, timezone).isoWeekday().toString();

                        if (!caducada && horario.diasSemana.indexOf(diaCita) !== -1) {
                            /* console.log( 'diaCita', diaCita );
                            console.log( horario.diasSemana );
                            console.log( horario.diasSemana.indexOf( diaCita ) ); */
                            horario.horas.forEach(item => {
                                const fecha = moment.tz(hora.hora, timezone).format('YYYY-MM-DD');
                                const horas = moment.tz(item.horaInicio, timezone).format('HH');
                                const minutos = moment.tz(item.horaInicio, timezone).format('mm');
                                const horasFin = moment.tz(item.horaFin, timezone).format('HH');
                                const minutosFin = moment.tz(item.horaFin, timezone).format('mm');
                                const horaInicio = moment.tz(`${fecha} ${horas}:${minutos}`, timezone);
                                const horaFin = moment.tz(`${fecha} ${horasFin}:${minutosFin}`, timezone);
                                if (moment.tz(hora.hora, timezone).isSameOrAfter(horaInicio) && moment.tz(hora.hora, timezone).isSameOrBefore(horaFin)) {
                                    tienePromocion = {
                                        tipo: (promo.porcentaje) ? 'porcentaje' : 'cifra',
                                        texto: (promo.porcentaje) ? promo.porcentaje : promo.cifra
                                    }
                                }
                            });
                        }
                    });
                }
                //console.log( 'fechaPromo', moment.tz(promo.fecha).format() );
            });
        }
        if (horaActual < 15) {
            arrayMorning = [...arrayMorning, {
                available: hora.available,
                date: hora.hora,
                profesional: hora.profesional,
                promocion: tienePromocion
            }];
        } else {
            arrayAfternoon = [...arrayAfternoon, {
                available: hora.available,
                date: hora.hora,
                profesional: hora.profesional,
                promocion: tienePromocion
            }];
        }
        return hora;
    });
    const obj = {
        morning: arrayMorning,
        afternoon: arrayAfternoon
    };
    return (obj);
};

export const filtraPromociones = async (servicio, sucursal, profesional, timeCalendar, promociones, citaActive) => {
    const filtradas = promociones.filter(promo => {
        let retorno = true;
        if (promo.servicios.indexOf(servicio._id) === -1) { retorno = false };
        if (promo.sucursales.indexOf(sucursal._id) === -1) { retorno = false };
        if (promo.profesionales.length > 0 && promo.profesionales.indexOf(profesional._id) === -1) { retorno = false };
        if (promo.profAleatorio && profesional._id !== '333' && !citaActive) { retorno = false };
        if (promo.profAleatorio && citaActive && !citaActive.isProfesionalRandom) { retorno = false };
        if (promo.horariosPromocion.length > 0 && !promo.profAleatorio) {
            let horarioIncluido = false;
            promo.horariosPromocion.forEach(horarioPromo => {
                const horaCita = moment.tz(timeCalendar, timezone);
                const diaCita = horaCita.isoWeekday().toString();
                if (horarioPromo.diasSemana.indexOf(diaCita) > -1) {
                    horarioPromo.horas.forEach(itemHoras => {
                        const fechaCita = horaCita.format('YYYY-MM-DD');
                        const inicioPromo = moment.tz(`${fechaCita} ${moment.tz(itemHoras.horaInicio, timezone).format('HH:mm')}`, timezone);
                        const finPromo = moment.tz(`${fechaCita} ${moment.tz(itemHoras.horaFin, timezone).format('HH:mm')}`, timezone);
                        if (horaCita.isSameOrAfter(inicioPromo) && horaCita.isSameOrBefore(finPromo)) {
                            horarioIncluido = true;
                        }
                    });
                }
            });
            if (!horarioIncluido) { retorno = false; }
        }

        return retorno;
    });

    //console.log( 'filtradas', filtradas );
    return filtradas;
};

export const getHorariosOcupados = async (prof) => {
    let respuesta = [
        { diasSemana: '1', horas: [] },
        { diasSemana: '2', horas: [] },
        { diasSemana: '3', horas: [] },
        { diasSemana: '4', horas: [] },
        { diasSemana: '5', horas: [] },
        { diasSemana: '6', horas: [] },
        { diasSemana: '7', horas: [] },
    ];
    const { horarioSucursales } = prof;

    horarioSucursales.forEach(horarioSucursal => {
        const { sucursal } = horarioSucursal;
        if (!sucursal.deleted) {
            const { horarios } = horarioSucursal;
            horarios.forEach(horario => {
                if (horario.horas.length) {
                    respuesta.forEach(item => {
                        if (horario.diasSemana === item.diasSemana) {
                            horario.horas.forEach(nuevaHora => {
                                item.horas.push(nuevaHora);
                            });
                        }
                    });
                }
            });
        }
    });

    return respuesta;
};

export const testHorarios = (arrayOcupadas) => {
    // console.log( 'arrayOcupadas', arrayOcupadas );
    let posible = true;

    arrayOcupadas.forEach(sucursal => {
        if (sucursal.horas.length) {
            //console.log( 'horas', sucursal.horas );
            let revisados = [];
            sucursal.horas.forEach(horario => {
                //console.log( 'horario', horario );
                if (revisados.length) {
                    revisados.forEach(revisado => {
                        const inicioExistente = moment(revisado.horaInicio);
                        const finalExistente = moment(revisado.horaFin);
                        const inicioPropuesto = moment(horario.horaInicio);
                        const finalPropuesto = moment(horario.horaFin);

                        /* console.log( 'inicioPropuesto', inicioPropuesto.toISOString() );
                        console.log( 'finalPropuesto', finalPropuesto.toISOString() );
                        console.log( 'inicioExistente', inicioExistente.toISOString() );
                        console.log( 'finalExistente', finalExistente.toISOString() ); */

                        const inicioCoincide = inicioPropuesto.isSameOrAfter(inicioExistente) && inicioPropuesto.isBefore(finalExistente);
                        const finalCoincide = finalPropuesto.isAfter(inicioExistente) && finalPropuesto.isSameOrBefore(finalExistente);
                        const coincideTodo = inicioPropuesto.isSameOrBefore(inicioExistente) && finalPropuesto.isSameOrAfter(finalExistente);

                        if (inicioCoincide || finalCoincide || coincideTodo) {
                            /* console.log( 'inicioCoincide', inicioCoincide );
                            console.log( 'finalCoincide', finalCoincide );
                            console.log( 'coincideTodo', coincideTodo ); */
                            posible = false;
                            // console.log('NO es posible');
                        } else {
                            revisados = [...revisados, horario];
                            // console.log('Es posible');
                        }
                        // console.log('------------------');
                    })
                } else {
                    revisados = [...revisados, horario];
                }
            })
        }
    });

    return posible;
};