import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const AddServicioSelect = ({ updateServicio, serviciosProf }) => {

    const { servicios } = useSelector(state => state.servicios);
    const { colores } = useSelector(state => state.ui);

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const showDropdown = () => {
        setVisibility(true);
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = ( e, servicio ) => {
        e.stopPropagation();
        updateServicio( servicio );
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );
    return (
        <div 
            className='ylm-select add-servicio-select'
            onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
        >
            <p>Seleccionar Servicios</p>
            <span><ChevronDownIcon color={ colores.colorPrincipal } /></span>
            <ul
                ref={ dropdownMenuRef }
                className={`ylm-select-options add-servicio-options ${ visibility ? 'active' : '' }`}
            >
                {
                    servicios.map( servicio => (
                        !servicio.deleted &&
                        <li
                            key={ servicio._id }
                            className={ `select-option check-option ${ serviciosProf.includes( servicio._id ) && 'active' }` }
                            onClick={ (e) => selectItem(e, servicio)}
                        >
                            <span></span>
                            { servicio.nombre }
                        </li>
                    ))
                }
            </ul>
        </div>
  )
}
