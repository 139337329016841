import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { VarianteRowSelect } from './VarianteRowSelect'

export const VarianteRowItem = ({variante, editVariante, deleteVariante}) => {

    const { servicios } = useSelector(state => state.servicios);
    const { productos } = useSelector(state => state.productos);
    const [serviciosTxt, setServiciosTxt] = useState('');
    const [productosTxt, setProductosTxt] = useState('');

    useEffect(() => {
      procesaServicios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [servicios]);
    
    useEffect(() => {
        procesaProductos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productos]);
    

    const procesaServicios = () => {
        const totalIncluidos = servicios.filter( servicio => {
            return variante.servicios.includes( servicio._id );
        });
        if( totalIncluidos.length === 0 ){
            setServiciosTxt( 'Ninguno' );
        } else if( totalIncluidos.length === servicios.length ){
            setServiciosTxt( 'Todos' );
        } else {
            setServiciosTxt( 'Algunos' );
        }
    };
    
    const procesaProductos = () => {
        const totalIncluidos = productos.filter( producto => {
            return variante.productos.includes( producto._id );
        });
        if( totalIncluidos.length === 0 ){
            setProductosTxt( 'Ninguno' );
        } else if( totalIncluidos.length === productos.length ){
            setProductosTxt( 'Todos' );
        } else {
            setProductosTxt( 'Algunos' );
        }
    };

    const clickDelete = () => {
        deleteVariante( variante );
    };

    return (
        <div 
            key={ variante._id } 
            className='variante-item'
            onClick={() => editVariante( variante )}
        >
            <div className='item-col col-variante'>
                <h5>Variante</h5>
                <p>{ variante.nombre }</p>
            </div>
            <div className='item-col col-tiempo'>
                <h5>Tiempo</h5>
                <p>+{ variante.tiempo || 0 }min.</p>
            </div>
            <div className='item-col col-valor'>
                <h5>Valor</h5>
                <p>+{ variante.valor / 100 || 0 }€</p>
            </div>
            <div className='item-col col-servicios'>
                <h5>Servicios</h5>
                <p>{ serviciosTxt }</p>
            </div>
            <div className='item-col col-productos'>
                <h5>Productos</h5>
                <p>{ productosTxt }</p>
            </div>
            <div className='item-col col-btns'>
                <VarianteRowSelect
                    clickDelete={ clickDelete }
                />
            </div>
        </div>
    )
}
