import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

const permisos = [
    { key: 'editar', text: 'Ver y editar (Admin)' },
    { key: 'ver', text: 'Solo ver' }
];

export const AddPermisoSelect = ({ updatePermisos, objProfesional }) => {
    const { colores } = useSelector( state => state.ui );

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const [permisosProf, setpermisosProf] = useState([]);

    useEffect(() => {
      if( objProfesional?.permisos ){
        setpermisosProf( objProfesional?.permisos );
      }
    }, [objProfesional])
    

    const showDropdown = () => {
        setVisibility(true);
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = ( e, permiso ) => {
        e.stopPropagation();
        updatePermisos( permiso );
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );
    return (
        <div 
            className='ylm-select add-permiso-select'
            style={{ color: colores.colorPrincipal }}
            onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
        >
            { permisosProf.length === 0 && <p>Configurar Permisos</p> }
            { permisosProf[0] === 'editar' && <p>Ver y editar (Admin)</p> }
            { permisosProf[0] === 'ver' && <p>Solo ver</p> }
            <span><ChevronDownIcon color={ colores.colorPrincipal } /></span>
            <ul
                ref={ dropdownMenuRef }
                className={`ylm-select-options add-permiso-options ${ visibility ? 'active' : '' }`}
            >
                {
                    permisos.map( permiso => (
                        <li
                            key={ permiso.key }
                            className={ `select-option check-option ${ permisosProf.includes( permiso.key ) && 'active' }` }
                            onClick={ (e) => selectItem(e, permiso)}
                        ><span></span>{ permiso.text }</li>
                    ))
                }
            </ul>
        </div>
  )
}
