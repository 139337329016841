import React, { useState } from 'react'
import { useSelector } from 'react-redux';

export const Tipografia = ({ setFontThemeSelected }) => {

  const { colores } = useSelector(state => state.ui);

  const [ fontSelected, setFontSelected ] = useState('1');

  const typos = [
    { _id: '1',
      name: 'Poppins',
      class: 'poppins'
    },
    { _id: '2',
      name: 'Work Sans',
      class: 'worksans'
    },
    { _id: '3',
      name: 'Gastromond',
      class: 'gastromond'
    },
    { _id: '4',
      name: 'Fraunces',
      class: 'fraunces'
    }
  ]

  const onFontSelected = ( typo ) => {
    console.log( typo );
    setFontSelected( typo._id );
    setFontThemeSelected( typo.name );
  };

  return (
    <div className='panel-container typo-container'>
      { typos.map( typo => (
        <div
          key={ typo._id }
          className='typo-selector'
          onClick={ () => onFontSelected( typo ) }
        >
          {/* <div 
            className={`ylm-radio ${ typo._id === fontSelected && 'active'}`}
          >
            <div className='radio-item'></div>
          </div> */}
          <div 
              className={`ylm-radio ${ typo._id === fontSelected && 'active'}`}
            >
              <div 
                className='radio-bg'                
                style={{ backgroundColor: colores.color6 }}
              >
                <span
                  className='radio-item'                
                  style={{ backgroundColor: colores.colorPrincipal }}
                ></span>
              </div>
            </div>
          <div className='typo-theme'>
            <div className={ `font-big ${typo.class}`}>Aa</div>
            <div className={ `font-name ${typo.class}`}>{ typo.name }</div>
          </div>
        </div>
        
      ))}
    </div>
  )
}
