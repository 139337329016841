import Swal from 'sweetalert2';

import { types } from "../types/types";
import { fetchConToken, archivoConToken } from '../helpers/fetch';
import { openModal } from './ui';

export const startNewHorario = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'horarios', values, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                /* const datos = {
                    text: [ `El horario ha sido creado` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) ); */
                dispatch( addNewHorario( body.horario._id, body.horario ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activeHorario = ( _id, horario ) => ({
    type: types.horariosActive,
    payload: {
        _id,
        ...horario
    }
});

export const unactiveHorario = () => ({
    type: types.horariosUnactive
});

export const addNewHorario = ( _id, horario ) => ({
    type: types.horariosAddNew,
    payload: {
        _id,
        ...horario
    }
})

export const startLoadingHorarios = ( empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'horarios/empresa', {empresa}, 'POST' );
            const body = await resp.json();
            dispatch( setHorarios( body.horarios ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setHorarios = ( horarios ) => ({
    type: types.horariosLoad,
    payload: horarios
})

export const startSaveHorario = ( horario, swal = true ) => {
    return async( dispatch, getState ) => {
        
        try {
            const resp = await fetchConToken( `horarios/${ horario._id }`, horario, 'PUT' );
            const body = await resp.json();
            if ( body.ok ) {
                //console.log( body );
                dispatch( refreshHorario( horario._id, body.horario ) );
                /* const datos = {
                    text: [ `El horario ha sido modificado` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) ); */
            } else {
                console.log( body );
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshHorario = ( id, horario ) => ({
    type: types.horariosUpdated,
    payload: {
        ...horario
    }
});

export const startUploading = ( archivo, idHorario ) => {

    return async ( dispatch, getState ) => {
        try {
            Swal.fire({
                title: 'Subiendo...',
                text: 'Por favor, espere...',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading();
                }
            });

            const formData = new FormData();
            formData.append('archivo', archivo);
            formData.append('file_name', 'archivo');
    
            const resp = await archivoConToken( `horarios/upload/${ idHorario }`, formData, 'POST' );
            const body = await resp.json();

            console.log( body );

            Swal.close();
        } catch (error) {
            throw new Error();
        }  

        Swal.close();
    }
};

export const horarioStartDelete = ( id ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `horarios/${ id }`, {}, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                console.log( body, id );
                dispatch( horarioDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

const horarioDeleted = ( id ) => ({
    type: types.horariosDelete,
    payload: id
});

export const horarioLogout = () => ({
    type: types.horariosLogoutCleaning
});
