import { useCallback, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon, PuntoVentaIcon } from '../../../../assets/svg/iconos';

import useClickOutside from '../../../../hooks/useClickOutside';

export const SelectServicios = ({objVariante, dataChange}) => {

    const { servicios } = useSelector(state => state.servicios);

    const [selectText, setSelectText] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        /* console.log( servicios );
        console.log( objPromocion ); */
        if( objVariante?.servicios?.length > 0 ){
            let seleccionadas = 0;
            servicios.forEach( servicio => {
                if( objVariante.servicios.includes( servicio._id ) && !servicio.deleted ){
                    seleccionadas++;
                }
            });
            if( seleccionadas === 0 ){
                setSelectText( 'Ninguno' );
            } else if( seleccionadas === servicios.length ){
                setSelectText( 'Todos' );
            } else {
                setSelectText( 'Algunos' );
            }
        } else {
            setSelectText( 'Ninguno' );
        }
    }, [objVariante, servicios]);
    

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    const clickServicio = ( idServicio ) => {
        let newServicios = [];
            if( objVariante.servicios.includes( idServicio ) ){
                newServicios = objVariante.servicios.filter( serv => {
                    return serv !== idServicio;
                });
                // console.log( newServicios );
            } else {
                newServicios = [
                    ...objVariante.servicios,
                    idServicio
                ]
            }
            dataChange('servicios', newServicios );
    }

    return (
        <div 
            className='ylm-variante-select variante-servicios'
        >
            <div 
                className='select-main'
                onClick={ clickSelect }
            >
                <div className='var-icon'>
                    <PuntoVentaIcon />
                </div>
                <div className='var-data'>
                    <h4>Servicios que aplican</h4>
                    <p>{ selectText }</p>
                </div>
                <div 
                    className={`btn-chevron ${isOpen ? 'active' : ''}`}
                >
                    <ChevronDownIcon />
                </div>
            </div>
            {
                isOpen &&
                <ul 
                    className={`select-options opt-servicios std-ul big`}
                    ref={popover}
                >
                    {
                        servicios.map( servicio => (
                            !servicio.deleted &&
                            <li
                                className={`std-li ${objVariante?.servicios.includes( servicio._id ) ? 'active' : ''}`}
                                key={ servicio._id }
                                onClick={ () => clickServicio( servicio._id )}
                            >
                                <div  
                                    className={`checkbox-bg`}
                                >
                                    {
                                        objVariante?.servicios.includes( servicio._id ) &&
                                        <span 
                                            className='checkbox-item'
                                        ></span>
                                    }
                                </div>
                                <p>{ servicio.nombre }</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
