import { useEffect, useState } from "react";


export const DatosPago = ({ active, setTab, empresa, setEmpresa, frameUrl, setFrameUrl }) => {

    const [pagoRealizado, setPagoRealizado] = useState(false);
    useEffect(() => {
      console.log('empresa', empresa);
    }, [empresa])
    

    const forwardBtn = () => {
        if( pagoRealizado ){
            setTab(4);
        }
    }

    return (    
        <div className={`steps-tab tab-pago ${ active ? 'active' : ''}`}>
            <h2>Datos de pago</h2>
            <div className="payment">
            {
                frameUrl !== '' &&
                <iframe id="paylands"
                    title="Paylands"
                    src={`${ frameUrl }`}>
                </iframe>
            }
            </div>
            {/* <div className='form-btn'>
                <button
                    onClick={ () => forwardBtn() }
                >
                    Siguiente
                </button>
            </div> */}
        </div>
    )
}
