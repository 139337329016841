import { useCallback, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon, PuntoVentaIcon } from '../../../../assets/svg/iconos';

import useClickOutside from '../../../../hooks/useClickOutside';

export const SelectProductos = ({objVariante, dataChange}) => {

    const { productos } = useSelector(state => state.productos);

    const [selectText, setSelectText] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        /* console.log( productos );
        console.log( objPromocion ); */
        if( objVariante?.productos?.length > 0 ){
            let seleccionadas = 0;
            productos.forEach( producto => {
                if( objVariante.productos.includes( producto._id ) && !producto.deleted ){
                    seleccionadas++;
                }
            });
            if( productos.length > 0 ){
                if( seleccionadas === 0 ){
                    setSelectText( 'Ninguno' );
                } else if( seleccionadas === productos.length ){
                    setSelectText( 'Todos' );
                } else {
                    setSelectText( 'Algunos' );
                }
            } else {
                setSelectText( 'No hay productos' );
            }
        } else {
            if( productos.length > 0 ){
                setSelectText( 'Ninguno' );
            } else {
                setSelectText( 'No existen productos' );
            }
        }
    }, [objVariante, productos]);
    

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    const clickProducto = ( idProducto ) => {
        let newProductos = [];
            if( objVariante.productos.includes( idProducto ) ){
                newProductos = objVariante.productos.filter( prod => {
                    return prod !== idProducto;
                });
                // console.log( newProductos );
            } else {
                newProductos = [
                    ...objVariante.productos,
                    idProducto
                ]
            }
            dataChange('productos', newProductos );
    }

    return (
        <div 
            className='ylm-variante-select variante-productos'
        >
            <div 
                className='select-main'
                onClick={ clickSelect }
            >
                <div className='var-icon'>
                    <PuntoVentaIcon />
                </div>
                <div className='var-data'>
                    <h4>Productos que aplican</h4>
                    <p>{ selectText }</p>
                </div>
                <div 
                    className={`btn-chevron ${isOpen ? 'active' : ''}`}
                >
                    <ChevronDownIcon />
                </div>
            </div>
            {
                (isOpen && productos.length > 0) &&
                <ul 
                    className={`select-options opt-productos std-ul big`}
                    ref={popover}
                >
                    {
                        productos.map( producto => (
                            !producto.deleted &&
                            <li
                                className={`std-li ${objVariante?.productos.includes( producto._id ) ? 'active' : ''}`}
                                key={ producto._id }
                                onClick={ () => clickProducto( producto._id )}
                            >
                                <div  
                                    className={`checkbox-bg`}
                                >
                                    <span 
                                        className='checkbox-item'
                                    ></span>
                                </div>
                                <p>{ producto.titulo }</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
