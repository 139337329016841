import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profesionalStartDelete } from '../../actions/profesionales';
import { setMenuLateral } from '../../actions/ui';
//import moment from 'moment-timezone';

import { AddIcon, BuscarIcon } from '../../assets/svg/iconos';
import { removeAccents } from '../../helpers/helpers';
import { ModalEliminarProfesional } from './components/modales/ModalEliminarProfesional';
import { ProfesionalesRowItem } from './components/ProfesionalesRowItem';

export const Profesionales = ({ history }) => {
    const dispatch = useDispatch();

    const { empresa } = useSelector(state => state.auth);
    const { colores, miniLateral } = useSelector(state => state.ui);
    const { profesionales } = useSelector(state => state.profesionales);
    const [currentProfesional, setCurrentProfesional] = useState(null);
    const [currentScreen, setCurrentScreen] = useState('main');
    const [activeTab, setActiveTab] = useState('horarios');

    const [searchActive, setSearchActive] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [profesionalesList, setProfesionalesList] = useState([]);

    const [modalEliminarProfesionalOpened, setModalEliminarProfesionalOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar profesional?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [selectedId, setSelectedId] = useState('');

    useEffect(() => {
        dispatch(setMenuLateral('profesionales'));
    }, [dispatch]);

    useEffect(() => {
        if (searchTxt.trim().length < 3) {
            setProfesionalesList(profesionales);
        } else {
            let newProfesionales = [];
            const filterProfesionales = async () => {
                newProfesionales = await profesionales.filter(item => {
                    let retorno;
                    retorno = removeAccents(item.nombre).toLowerCase().includes(removeAccents(searchTxt.toLowerCase())) && !item.deleted;
                    return retorno;
                });
                setProfesionalesList(newProfesionales);
            }
            filterProfesionales();
        }
    }, [profesionales, searchTxt]);

    const clickSearch = () => {
        setSearchActive(!searchActive);
    };

    const handleSearchChange = (e) => {
        setSearchTxt(e.target.value);
    }

    useEffect(() => {
        if (currentProfesional) {
            setCurrentScreen('edit');
        }
    }, [currentProfesional])


    const clickAdd = () => {
        setCurrentScreen('new');
        history.push({
            pathname: `/profesional/new`,
            state: {
                activeTab: 'horarios'
            }
        });
    };

    const editProfesional = (profesional, boton = 'horarios') => {
        history.push({
            pathname: `/profesional/${profesional._id}`,
            state: {
                activeTab: boton,
                profesional
            }
        });
    };

    const deleteProfesionalModal = (profId, profName) => {
        setSelectedId(profId);
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar a ${profName}?`]
        })
        setModalEliminarProfesionalOpened(true);
    };

    const deleteProfesionalResponse = (value) => {
        if (value === 1 && selectedId !== '') {
            console.log('Borrar id:', selectedId);
            dispatch(profesionalStartDelete(selectedId, empresa));
        }
    }

    return (
        <>
            {
                currentScreen === 'main' &&
                <div className="profesionales">
                    <div className={`preheader ${!miniLateral && 'compress'}`}>
                        <div className="title">
                            <h1 style={{ color: colores.colorPrincipal }}>Profesionales</h1>
                        </div>
                        <div className="botonera">
                            <div
                                className={`btn-search ${searchActive && 'active'}`}
                            >
                                <div className='search-input'>
                                    <input
                                        type={'text'}
                                        placeholder={'Buscar por nombre'}
                                        value={searchTxt}
                                        onChange={handleSearchChange}
                                    />
                                    {
                                        searchTxt.trim().length > 2 &&
                                        <span className='cantidad'>
                                            ({profesionalesList.length})
                                        </span>
                                    }
                                </div>
                                <div
                                    className='icono'
                                    onClick={clickSearch}
                                >
                                    <BuscarIcon
                                        color={colores.colorPrincipal}
                                        className="icon-inverse"
                                    />
                                </div>
                            </div>
                            <div
                                className="btn-add"
                                onClick={clickAdd}
                            >
                                <AddIcon
                                    color={colores.colorPrincipal}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="table-profesionales">
                        {
                            profesionalesList.map(profesional => (
                                (!profesional.deleted)
                                &&
                                <ProfesionalesRowItem
                                    key={profesional._id}
                                    setCurrentProfesional={setCurrentProfesional}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    profesional={profesional}
                                    editProfesional={editProfesional}
                                    deleteProfesional={deleteProfesionalModal}
                                />
                            ))
                        }
                    </div>
                </div>
            }
            <ModalEliminarProfesional
                data={dataModalEliminar}
                setModalResponse={deleteProfesionalResponse}
                modalOpened={modalEliminarProfesionalOpened}
                setModalOpened={setModalEliminarProfesionalOpened}
            />
        </>
    )
}
