import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { ArrowBackIcon, AvatarIcon, DownloadIcon, TrashIcon, UploadImageIcon } from '../../../assets/svg/iconos';
import useClickOutside from '../../../hooks/useClickOutside';
import { isEmail } from '../../../helpers/forms';
import { openModalBottom } from '../../../actions/ui';
import { fetchSinToken } from '../../../helpers/fetch';
import { startNewEmpresa, activeEmpresa, empresaStartDelete, startUploading, unactiveEmpresa, startSaveEmpresa } from '../../../actions/empresas';
import { ModalEliminarEmpresa } from './modales/ModalEliminarEmpresa';
import { PlanSelect } from './selectores/PlanSelect';
import { formatCurrency, newEmpresa } from '../../../helpers/helpers';
import { SectorSelect } from './selectores/SectorSelect';

export const Empresa = ({ history }) => {

  const dispatch = useDispatch();
  const { empresa } = useSelector(state => state.auth);
  const { active: empresaActive } = useSelector(state => state.empresas);
  const { sectores } = useSelector(state => state.sectores);
  const { planes } = useSelector(state => state.planes);
  const { colores } = useSelector(state => state.ui);

  const [objEmpresa, setObjEmpresa] = useState({});
  const [emailEmpresa, setEmailEmpresa] = useState('');
  const [iniciado, setIniciado] = useState(false);
  const [cambiado, setCambiado] = useState(false);

  const [editMode, setEditMode] = useState(true);

  const [, setCurrentEmpresa] = useState(history.location.state?.empresa || null);
  const [currentScreen] = useState((history.location.state?.empresa) ? 'edit' : 'new');
  const [objErrores, setObjErrores] = useState({});

  const popover = useRef();
  const [, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const [modalEliminarEmpresaOpened, setModalEliminarEmpresaOpened] = useState(false);
  const [dataModalEliminar, setDataModalEliminar] = useState({
    title: '¿Eliminar empresa?',
    text: [],
    buttonText: 'Eliminar',
    button2Text: 'Cancelar'
  });

  const [imageUrl, setImageUrl] = useState('');
  const [file, setFile] = useState(null);
  const [empresaImage, setEmpresaImage] = useState(null);

  useEffect(() => {
    if (history.location.state?.empresa._id) {
      dispatch(activeEmpresa(history.location.state?.empresa._id, history.location.state?.empresa));
    }
    return () => {
      dispatch(unactiveEmpresa());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log( 'objEmpresa', objEmpresa );
  // }, [empresaActive, objEmpresa]);

  /* useEffect(() => {
    console.log( currentScreen );
  }, [currentScreen]) */


  useEffect(() => {
    if (empresaActive) {
      setObjEmpresa(empresaActive);
      setIniciado(true);
      // setEditMode( true );
      if (empresaActive._id && empresaActive.img.length > 0) {
        getImagenPrincipal(`${empresaActive.img[0]}`);
      }
    } else {
      // setEditMode( true )
      const nuevo = newEmpresa(sectores, planes);
      setCurrentEmpresa(nuevo);
      setObjEmpresa(nuevo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaActive]);

  useEffect(() => {
    if (empresaActive) {
      const empresaOriginal = { ...empresaActive };
      if (iniciado) {
        let igual = true;
        for (const propiedad in empresaOriginal) {
          //console.log( `${nEmpresa[propiedad]} !== ${objEmpresa[propiedad]}`)
          if (empresaOriginal[propiedad] !== objEmpresa[propiedad]) {
            igual = false;
            //console.log(`Cambio en ${propiedad}`);
          }
        }
        setCambiado(!igual);
      }
    }
    if (empresaImage) {
      setCambiado(true);
    }

  }, [iniciado, empresaActive, objEmpresa, empresaImage]);

  useEffect(() => {
    // console.log( objEmpresa );
    if (objEmpresa.email) {
      setEmailEmpresa(objEmpresa.email);
    }
  }, [objEmpresa]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      /* if( !usuarioSaved ){
        return false;
      } */
    });

    return () => {
      unblock();
    }
  }, [history]);


  const onFormChange = (e) => {
    dataChange(e.target.name, e.target.value);
  }

  const dataChange = (name, value) => {
    if (editMode) {
      setObjEmpresa({
        ...objEmpresa,
        [name]: value
      });
      console.log(objEmpresa);
    } else {
      dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
    }
  }

  const onChangeEmail = (e) => {
    setEmailEmpresa(e.target.value);
    setObjEmpresa({
      ...objEmpresa,
      email: e.target.value
    });
  }

  const cancelClick = (e) => {
    if (!cambiado) {
      history.goBack();
    }
    /* if( usuarioSaved ){
      history.goBack();
    } */
  }

  const guardar = async () => {
    let newObj = {
      ...objEmpresa,
      _id: objEmpresa._id,
      plan: objEmpresa.plan._id,
      sector: objEmpresa.sector._id,
    }
    if (empresaImage) {
      let previa = '';
      if (objEmpresa.img.length > 0) {
        previa = objEmpresa.img[0];
      }
      const imgName = `${objEmpresa._id}-${new Date().getTime()}`;
      const archivo = file;
      const nombreCortado = archivo.name.split('.');
      const extension = nombreCortado[nombreCortado.length - 1];
      const nombreCompleto = `${imgName}.${extension}`;

      newObj = { ...newObj, img: [nombreCompleto] };

      dispatch(startUploading(file, newObj, nombreCompleto, previa));
    }

    const errores = await checkFields();

    if (errores === 0) {
      if (currentScreen === 'new') {
        delete newObj['usuario'];
        dispatch(startNewEmpresa(newObj));
        history.push({ pathname: `/empresas` });
      } else {
        dispatch(startSaveEmpresa(newObj));
        setCambiado(false);
      }
    } else {
      console.log(objErrores);
      dispatch(openModalBottom({ text: ['Hay errores en el formulario, los campos marcados en rojo deben ser rellenados.'], timeOut: 3 }));
    }
  }
  const cancelar = () => {
    if (currentScreen !== 'new') {
      setEditMode(true);
      setEmpresaImage(null);
      setFile(null);
      setObjEmpresa(empresaActive);
      setObjErrores({});
    } else {
      history.goBack();
    }
  }

  const deleteClienteModal = () => {
    setDataModalEliminar({
      ...dataModalEliminar,
      text: [`¿Estás seguro de que deseas eliminar a ${objEmpresa.nombre}?`]
    })
    setModalEliminarEmpresaOpened(true);
  };

  const deleteEmpresaResponse = (value) => {
    if (value === 1) {
      console.log('value = 1');
      let selectedId = objEmpresa._id;

      if (!selectedId) {
        if (empresaActive._id) {
          selectedId = empresaActive._id;
        }
      };

      console.log('Borrar id:', selectedId);
      dispatch(empresaStartDelete(selectedId, empresa));
      history.push({ pathname: `/empresas` })
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    /* console.log( 'onDrop' );
    console.log( 'objServicio', objServicio );
    console.log( acceptedFiles );

    dispatch( startUploading( acceptedFiles[0], objServicio._id, objServicio.empresa )); */
    const file = acceptedFiles[0];
    setFile(file);
    setEmpresaImage(URL.createObjectURL(file));

    //dispatch( startUploading( file, objProfesional._id, objProfesional.empresa ) );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objEmpresa]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    onDrop
  });

  const getImagenPrincipal = async (nombre) => {
    const imagen = await fetchSinToken(`imagen/user/${nombre}`);
    setImageUrl(imagen);
  }

  const checkFields = async () => {
    let ejecutar = false;

    const newObjErrores = { ...objErrores };

    if (objEmpresa.nombre.trim() === '') {
      newObjErrores.nombre = 'Debe escribir un nombre';
    } else {
      delete newObjErrores.nombre;
    }
    if (!isEmail(objEmpresa.email)) {
      newObjErrores.email = 'Debe escribir un email correcto';
    } else {
      delete newObjErrores.email;
    }

    if (objEmpresa.telefono.trim() === '') {
      newObjErrores.telefono = 'Debe escribir un teléfono';
    } else {
      delete newObjErrores.telefono;
    }

    try {
      new URL(objEmpresa.url);
      delete newObjErrores.url;
    } catch {
      newObjErrores.url = 'La url no es válida';
    }

    if (currentScreen === 'new') {
      ejecutar = true;
    } else if (objEmpresa.email !== empresaActive.email) {
      ejecutar = true;
    }
    if (ejecutar) {
      const resp = await fetchSinToken('auth/test-email-admin', { email: emailEmpresa }, 'POST');
      const body = await resp.json();

      if (!body.ok) {
        newObjErrores.emailExists = 'El email solicitado ya existe';
      } else {
        if (objErrores.emailExists) {
          delete newObjErrores['emailExists'];
        }
      }
    } else {
      if (objErrores.emailExists) {
        delete newObjErrores['emailExists'];
      }
    }

    setObjErrores(newObjErrores);
    return Object.keys(newObjErrores).length;
  };

  return (
    <>
      <div
        className='empresa'
        style={{
          '--colorPrincipal': colores.colorPrincipal,
          '--colorSecundario': colores.colorSecundario,
          '--color3': colores.color3,
          '--color4': colores.color4,
          '--color5': colores.color5,
          '--color6': colores.color6,
          '--color7': colores.color7,
        }}
      >
        <div className='empresa-wrapper'>
          <div className='single-empresa'>
            <div className='header'>
              <div
                className='nav-back'
                onClick={(e) => cancelClick(e)}
              >
                <ArrowBackIcon color={colores.colorSecundario} />
                <p style={{ color: colores.colorSecundario }}>Empresas</p>
              </div>
              <div className='botonera'>
                {/* {
                            (!cambiado && currentScreen !== 'new') &&
                            <button
                                className='btn-mini btn-editar'
                                style={{ backgroundColor: editMode ? colores.colorPrincipal : colores.color6 }}
                                onClick={ () => setEditMode( !editMode ) }
                            >
                                <EditarIcon color={ editMode ? colores.color6 : colores.colorPrincipal } />
                            </button>
                        } */}
                {
                  (!cambiado && currentScreen !== 'new') &&
                  <button
                    className='btn-mini btn-eliminar'
                    style={{ backgroundColor: colores.color6 }}
                    onClick={deleteClienteModal}
                  >
                    <TrashIcon color={colores.colorPrincipal} />
                  </button>
                }
                {
                  (cambiado || currentScreen === 'new') &&
                  <button
                    className='btn btn-crear'
                    style={{ backgroundColor: colores.colorPrincipal }}
                    onClick={guardar}
                  >
                    {`${currentScreen === 'new' ? 'Crear' : 'Guardar'}`}
                  </button>
                }
                {
                  (cambiado || currentScreen === 'new') &&
                  <button
                    className='btn btn-cancelar'
                    style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
                    onClick={cancelar}
                  >
                    Cancelar
                  </button>
                }
              </div>
            </div>
            <div className='empresa-body'>
              <h1>{`${objEmpresa?.nombre ? objEmpresa?.nombre : 'Nombre'}`}</h1>
              <section className='user-main'>
                <div
                  className='empresa-col col-1'
                >
                  <div
                    {...getRootProps({ className: `dropzone avatar` })}
                    style={{
                      backgroundImage: `url(${empresaImage ? empresaImage : imageUrl.url + '?' + uuidv4()})`
                    }}
                  >
                    <input {...getInputProps({ className: `${(!empresaImage && !imageUrl) ? 'no-image' : ''}` })} />
                    {
                      (!empresaImage && !imageUrl) &&
                      <>
                        <AvatarIcon />
                        <div className='avatar-bg'>+</div>
                      </>
                    }
                  </div>
                  <div className='data-group'>
                    <div className='data'>
                      <div className='name'>
                        <input
                          style={{
                            backgroundColor: editMode ? '#ffffff' : 'transparent',
                            border: `${objErrores.nombre ? '2px solid ' + colores.color5 : 'none'}`
                          }}
                          autoComplete='off'
                          type="text"
                          placeholder='Nombre'
                          name='nombre'
                          value={objEmpresa.nombre || ''}
                          onChange={(e) => onFormChange(e)}
                        />
                      </div>
                    </div>
                    <div className='contact-data'>
                      <div className='contact-input phone'>
                        <input
                          style={{
                            backgroundColor: `${editMode ? '#FFFFFF' : colores.color6}`,
                            border: `${objErrores.telefono ? '2px solid ' + colores.color5 : 'none'}`
                          }}
                          autoComplete='off'
                          type={`number`}
                          placeholder='Teléfono'
                          name='telefono'
                          value={objEmpresa.telefono || ''}
                          onChange={(e) => onFormChange(e)}
                        />
                      </div>
                      <div className='contact-input email'>
                        <input
                          style={{
                            backgroundColor: `${editMode ? 'rgba(239,239,239, 0.3)' : colores.color6}`,
                            border: `${objErrores.email || objErrores.emailExists ? '2px solid ' + colores.color5 : 'none'}`
                          }}
                          autoComplete='off'
                          type={`email`}
                          placeholder='Email'
                          //disabled={ currentScreen === 'edit' }
                          name='email'
                          value={emailEmpresa}
                          onChange={(e) => onChangeEmail(e)}
                          onBlur={() => checkFields()}
                        />
                        {
                          objErrores.emailExists &&
                          <div className='email-msg'>
                            <p>{objErrores.emailExists}</p>
                          </div>
                        }
                      </div>
                      {/* <div className='contact-input sector'>
                              <input
                                style={{ 
                                    backgroundColor: `${editMode ? '#FFFFFF' : colores.color6 }`,
                                  border: `${objErrores.sector ? '2px solid ' + colores.color5 : 'none'}`
                                }}
                                autoComplete='off'
                                type={ `text` }
                                placeholder='Sector'
                                name='sector'
                                value={ objEmpresa.sector?.nombre || '' }
                                onChange={ (e) => onFormChange( e )}                                
                              />
                            </div> */}
                      <div className='contact-input sector'>
                        <SectorSelect
                          objEmpresa={objEmpresa}
                          dataChange={dataChange}
                          editMode={editMode}
                        />
                      </div>
                    </div>
                    <div className='contact-data' style={{
                      marginTop: "5px"
                    }}>
                      <div className='contact-input email'>
                        <input
                          style={{
                            backgroundColor: editMode ? '#ffffff' : 'transparent',
                            border: `${objErrores.url ? '2px solid ' + colores.color5 : 'none'}`
                          }}
                          autoComplete='off'
                          type="text"
                          placeholder='URL'
                          name='url'
                          value={objEmpresa.url || ''}
                          onChange={(e) => onFormChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='empresa-col col-2'>
                  <div
                    className='col-item plan-select'
                  >
                    <PlanSelect
                      objEmpresa={objEmpresa}
                      dataChange={dataChange}
                      editMode={editMode}
                    />
                  </div>
                  <div
                    className='col-item plan-price'
                  >
                    <p>{`${objEmpresa.plan?.mensual ? formatCurrency(objEmpresa.plan?.mensual) : '0'}`}€<span>/mes</span></p>
                  </div>
                </div>
              </section>
              <section className='user-contract'>
                <div className='contract'>
                  <h3>Contrato</h3>
                  <div className='contract-file'>
                    <p>
                      <span><UploadImageIcon /></span>
                      Subir documento.pdf
                    </p>
                    <button>
                      Subir
                    </button>
                  </div>
                </div>
                <div className='lock'>
                  <h3>Acceso App</h3>
                  <div className={`lock-selector ${objEmpresa?.bloqueo ? 'active' : ''}`}>
                    <p>Bloqueo <i>{`${objEmpresa?.bloqueo ? 'activo' : 'inactivo'}`}</i></p>
                    <div
                      className='lock-toggle'
                      onClick={() => dataChange('bloqueo', !objEmpresa?.bloqueo)}
                    >
                      <div className='toggle-bg'>
                        <div className='toggle-switch'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='lock'>
                  <h3>Código Comercio</h3>
                  <div className={`lock-selector active`}>
                    <p>Merchant Id: </p>
                    <input
                        style={{
                          width: '80px',
                          backgroundColor: editMode ? '#ffffff' : 'transparent',
                          border: `'2px solid ' + colores.color5`
                        }}
                        autoComplete='off'
                        type="text"
                        placeholder='Merchant Id'
                        name='merchantId'
                        value={objEmpresa.merchantId || ''}
                        onChange={(e) => onFormChange(e)}
                    />
                  </div>
                </div>
              </section>
              <section className='section pagos'>
                <h3>Pagos</h3>
                <div className='pagos-table'>
                  <div className='pagos-row headers'>
                    <div className='plan'>Plan</div>
                    <div className='facturado'>Facturado</div>
                    <div className='cuota'>Cuota</div>
                    <div className='pago'>Pago</div>
                    <div className='factura'>Factura</div>
                  </div>
                  <div
                    className='pagos-row'
                  >
                    <div className='plan'>{`Emprendedor`}</div>
                    <div className='facturado'>{`22/1/22`}</div>
                    <div className='cuota'>{`39`}€</div>
                    <div className={`pago pendiente`}>{'Pendiente'}</div>
                    <div className='factura'><span className='reverse-icon'><DownloadIcon /></span></div>
                  </div>
                  <div
                    className='pagos-row'
                  >
                    <div className='plan'>{`Emprendedor`}</div>
                    <div className='facturado'>{`22/12/21`}</div>
                    <div className='cuota'>{`39`}€</div>
                    <div className={`pago`}>{'Pagado'}</div>
                    <div className='factura'><span className='reverse-icon'><DownloadIcon /></span></div>
                  </div>
                  {/* {
                                pagos.map( pago => (
                                    <div
                                        key={ pago._id }
                                        className='pagos-row'
                                    >
                                        <div className='plan'>{ pago.plan }</div>
                                        <div className='facturado'>{ pago.facturado }</div>
                                        <div className='cuota'>{ pago.cuota }€</div>
                                        <div className={`pago ${ pago.pago === 'Pendiente' && 'pendiente'}`}>{ pago.pago }</div>
                                        <div className='factura'><span className='reverse-icon'><DownloadIcon /></span></div>
                                    </div>
                                ))
                            } */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <ModalEliminarEmpresa
        data={dataModalEliminar}
        setModalResponse={deleteEmpresaResponse}
        modalOpened={modalEliminarEmpresaOpened}
        setModalOpened={setModalEliminarEmpresaOpened}
      />
    </>
  )
}
