import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AddSucursalIcon } from '../../../../assets/svg/iconos';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const AddSucursalSelect = ({ setAddSucursalValue, sucursalesProf, addSucursal }) => {

    const { sucursales } = useSelector(state => state.sucursales);
    const { colores } = useSelector( state => state.ui );

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const [disponibles, setDisponibles] = useState([]);

    const showDropdown = () => {
        setVisibility(true);
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = ( sucursal ) => {
        const newArray = sucursalesProf.filter( item => item.sucursal._id === sucursal._id );

        if( newArray.length === 0 ){
            addSucursal( sucursal );
        }
        hideDropdown();
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

    useEffect(() => {
        // console.log( 'sucursales', sucursales );
        // console.log( 'sucursalesProf', sucursalesProf );
        let newDisponibles = [];
        sucursales.forEach( item => {
            let existe = false;
            sucursalesProf.forEach(sucProf => {
                const idSucursal = item._id ? item._id : item._id;
                const idSucursalProf = sucProf.sucursal._id ? sucProf.sucursal._id : sucProf.sucursal;
                // console.log( idSucursal );
                // console.log( idSucursalProf );
                if( (idSucursalProf === idSucursal) && !item.deleted  ) {
                    existe = true;
                }
            });
            if( !existe ){
                // console.log('No existe');
                newDisponibles = [ ...newDisponibles, item ];
            }
        });
        // console.log( 'newDisponibles', newDisponibles );
        setDisponibles(newDisponibles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sucursales, sucursalesProf]);

    // useEffect(() => {
    //   console.log( disponibles );
    // }, [disponibles])
    
    
    
  return (
    <div 
        className={ `add-sucursal`}
        onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
    >
        <div 
            className='ylm-select add-sucursal-select'
        >
            <p><AddSucursalIcon color={ colores.colorPrincipal } /></p>
            <span></span>
            <ul
                ref={ dropdownMenuRef }
                className={`ylm-select-options add-sucursal-options ${ visibility ? 'active' : '' }`}
            >
                {
                    disponibles.length
                    ?
                    disponibles.map( sucursal => (
                        (!sucursal.deleted)
                        &&
                        <li
                            key={ sucursal._id }
                            className={ `select-option check-option ${ sucursalesProf.includes( sucursal._id ) && 'active' }` }
                            onClick={ () => selectItem(sucursal)}
                        ><span></span>{ sucursal.nombre }</li>
                    ))
                    :
                    <div className='sin-sucursales'>
                        <p style={{ color: colores.colorPrincipal }}>El profesional está asignado a todas las sucursales disponibles</p>
                    </div>
                }
                {/* {
                    disponibles
                    ?
                    sucursales.map( sucursal => (                        
                        (!sucursalesProf.includes( sucursal._id ) && !sucursal.deleted)
                        &&
                        <li
                            key={ sucursal._id }
                            className={ `select-option check-option ${ sucursalesProf.includes( sucursal._id ) && 'active' }` }
                            onClick={ () => selectItem(sucursal)}
                        ><span></span>{ sucursal.nombre }</li>
                    ))
                    :
                    <div className='sin-sucursales'>
                        <p style={{ color: colores.colorPrincipal }}>El profesional está asignado a todas las sucursales disponibles</p>
                    </div>
                } */}
            </ul>
        </div>
    </div>
  )
}
