import moment from 'moment-timezone';

const timezone = process.env.REACT_APP_TIMEZONE;

const monthsNames = [
    { _id: 1, name: 'Enero' },
    { _id: 2, name: 'Febrero' },
    { _id: 3, name: 'Marzo' },
    { _id: 4, name: 'Abril' },
    { _id: 5, name: 'Mayo' },
    { _id: 6, name: 'Junio' },
    { _id: 7, name: 'Julio' },
    { _id: 8, name: 'Agosto' },
    { _id: 9, name: 'Septiembre' },
    { _id: 10, name: 'Octubre' },
    { _id: 11, name: 'Noviembre' },
    { _id: 12, name: 'Diciembre' },
];

export const getMonthName = ( date ) => {
    const mes = date.month() + 1;
    return monthsNames.filter( month => (month._id === mes));
}

export const getCalendarDays = ( date, citas, arrSucursales, arrProfesionales ) => {
    const mes = date.month();
    const anyo = date.year();

    const diaSemana1 = moment.tz([anyo, mes, 1], timezone);
    const diasDelMes = date.daysInMonth();
    const ultimoDiaSemana = moment.tz([anyo, mes, diasDelMes], timezone).isoWeekday();
    const totalRecuadros = (diaSemana1.isoWeekday() - 1) + diasDelMes + (7 - ultimoDiaSemana );
    const primerDia = diaSemana1.subtract( (diaSemana1.isoWeekday() - 1), 'days' );

    let casillas = [];
    for( let i=0; i < totalRecuadros; i++){
        const newDay = moment.tz(primerDia, timezone);
        const date = newDay.add( i, 'days' );
        let calendarItems = [];
        let citasArray = [];
        citas.map( cita => {
            if( moment.tz(cita.fecha, timezone).format('D-M-YYYY') === newDay.format('D-M-YYYY') ){
                citasArray = [ ...citasArray, cita ];
                let repetido = false;
                calendarItems.forEach( item => {
                    if( item.profesional._id === cita.profesional._id && item.sucursal._id === cita.sucursal._id ){
                        repetido = true;
                    }
                });
                let idxProf = null;
                let sucursalSelected = false;
                let profSelected = false;
                arrSucursales.forEach(sucursal => {
                    // console.log( 'sucursal.sucursal', sucursal.sucursal );
                    // console.log( 'cita.sucursal', cita.sucursal );
                    // console.log( 'cita', cita );
                    // console.log('--------------------')
                    if( sucursal.sucursal._id === cita.sucursal._id ){
                        sucursalSelected = sucursal.selected;
                    }
                });
                arrProfesionales.forEach(prof => {
                    // console.log( prof );
                    if( prof.profesional._id === cita.profesional._id ){
                        idxProf = prof.idx;
                        profSelected = prof.selected;
                    }
                });
                if( idxProf ){
                    calendarItems = [
                        ...calendarItems,
                        {
                            cita,
                            sucursal: cita.sucursal,
                            profesional: cita.profesional,
                            idxProf,
                            profSelected,
                            sucursalSelected,
                            fecha: cita.fecha,
                            repetido
                        }
                    ]
                }
            }
            return cita;
        });
        if( calendarItems.length > 0 ){
            calendarItems.sort( compareDates );
            calendarItems = ordenaCalendarItems( calendarItems );
            //calendarItems = tabulaCitas( calendarItems );
        };
        const dia = {
            _id: i,
            date,
            current: ( mes === date.month() ) ? true : false,
            //citas: citasArray,
            calendarItems: calendarItems
        }
        casillas = [ ...casillas, dia ];
    }
    // console.log( casillas );
    return casillas;
};

export const compareDates = (a,b) => {
    if( moment.tz(a.fecha, timezone).isBefore(moment.tz(b.fecha, timezone)) ){
        return -1;
    }
    if( moment.tz(a.fecha, timezone).isAfter(moment.tz(b.fecha, timezone)) ){
        return 1;
    }
    return 0;
}

const ordenaCalendarItems = ( calendarItems ) => {
    //console.log( calendarItems );
    let columnas = [];
    if( calendarItems.length > 0 ){
        calendarItems.forEach( (item, idx) => {
            let existeProf = false;
            columnas.forEach( columna => {
                if( item.profesional._id === columna.profesional ){
                    existeProf = true;
                    columna.items = [ ...columna.items, item ];
                }
            });
            if( !existeProf ){
                const newColumna = {
                    fecha: item.fecha,
                    profesional: item.profesional._id,
                    items: [ item ]
                }
                columnas = [ ...columnas, newColumna ];
            }
        });
    }
    return columnas;
};

export const calculaDistancias = ( columnas ) => {
    let retorno = [];
    columnas.forEach((franjas, idxColumna) => {
        let indices = [];
        franjas.forEach( (item, idxFranja) => {
            if( item.duracion ){
                indices.push( idxFranja );
            }
        });
        marcaColumna( columnas[idxColumna], indices );
    });
    return retorno;
};

const marcaColumna = ( columna, indices ) => {
    let newColumna = [];
    console.log('indices', indices );
    let prevIndice = 0;
    let nextIndice = 0;
    let newFranja = [];
    columna.forEach((franja, idx) => {
        let getBig = false;
        prevIndice = 0;
        indices.forEach( (indexId, indicesIdx) => {
            if( indexId > idx && !getBig ){
                getBig = true;
                nextIndice = indexId - idx;
                if( indicesIdx > 0){
                    prevIndice = indexId - 1;
                }
            }
            if( !getBig && indicesIdx === indices.length - 1 ){
                nextIndice = columna.length - idx;
            }
        });
        newFranja = {
            ...franja,
            prev: prevIndice,
            next: nextIndice - 1,
        }
        newColumna = [
            ...newColumna,
            newFranja
        ]
    });
    console.log( newColumna );
};