import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { startLogout } from '../../actions/auth';
import { openLogin } from '../../actions/ui';
import { LogoExora } from '../../assets/svg/images';
//import { coloresBase } from '../../helpers/colors';
import { YlmLoading } from './YlmLoading';
/* import { YlmModal } from './YlmModal';
import { YlmModalBottom } from './YlmModalBottom'; */

export const Navbar = () => {
    const dispatch = useDispatch();
    const { role } = useSelector(state => state.auth);
    const { bodyClass, darkHeader, adminHeader, colores } = useSelector(state => state.ui);
    const [opened, setOpened] = useState(false);    

    const clickMenu = () => {
        setOpened( !opened );
    };

    const clickMenuItem = () => {
        setOpened( false );
    };

    const clickLogout = () => {
        dispatch( startLogout() );
    };

    const iniciarSesion = (e) => {
        e.preventDefault();
        setOpened( false );
        if( role ){
            clickLogout();
        } else {
            dispatch( openLogin() );
        }
    };
    
    return (
        <header 
            className={`${(darkHeader) ? 'dark' : ''} ${bodyClass} ${(opened) ? 'opened' : ''} ${(adminHeader) ? 'admin' : ''}`}
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className={ `user-header ${(adminHeader) ? 'hidden' : ''}` }>
                <Link className="logo" to="/"></Link>
                <div 
                    className={`btn-login ${(role) ? 'logged' : ''}`}
                    onClick={ iniciarSesion }
                ></div>
                <div 
                    className={`btn-menu`}
                    onClick={ clickMenu }
                ></div>
            </div>
            <div className={ `admin-header ${(!adminHeader) ? 'hidden' : ''}` }>
                <div className="left">
                    <Link className="logo" to="/">
                        <LogoExora />
                    </Link>
                </div>
                <div className="right">
                    <div 
                        className="btn-logout"
                        onClick={ clickLogout }
                    ><span></span></div>
                </div>
            </div>
            <div className={`menu ${(opened) ? 'opened' : ''}`}>
                <div className="menu-body-container">
                    <div className="menu-body">
                        <div className="separador"><span></span></div>
                        <Link onClick={ clickMenuItem } to="/">La barbería</Link>
                        <Link onClick={ clickMenuItem } to="/reservar">Reservar</Link>
                        {
                            (role)
                            &&
                            <Link onClick={ clickMenuItem } to="/mi-cuenta">Mi cuenta</Link>
                        }
                        {
                            (role && role === 'BUSINESS_ROLE')
                            &&
                            <Link onClick={ clickMenuItem } to="/gestion">Gestión</Link>
                        }
                        <HashLink smooth onClick={ clickMenuItem } to="/#contacto-adresses">Contacto</HashLink>
                        {
                            <Link onClick={ iniciarSesion } className={`mobile-login ${(role) ? 'logged' : ''}`} to="#"><span></span></Link>
                        }
                        <div className="separador"><span></span></div>
                    </div>
                </div>
            </div>
            <YlmLoading />
        </header>
    )
}
