
import { types } from "../types/types";

const initialState = {
    productos: [],
    active: null
}

export const productosReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.productosActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.productosUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.productosAddNew:
            return {
                ...state,
                productos: [ ...state.productos, action.payload ]
            }

        case types.productosLoad:
            return {
                ...state,
                productos: [ ...action.payload ]
            }
        
        case types.productosUpdated:
            return {
                ...state,
                productos: state.productos.map(
                    producto => producto._id === action.payload._id
                        ? action.payload
                        : producto
                )
            }

        case types.productosDelete:
            return {
                ...state,
                active: null,
                productos: state.productos.filter( producto => producto._id !== action.payload )
            }
    
        case types.productosLogoutCleaning:
            return {
                ...state,
                active: null,
                productos: []
            }
    

        default:
            return state;
    }
}