
import { types } from "../types/types";

const initialState = {
    profesionales: [],
    active: null
}

export const profesionalesReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.profesionalesActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.profesionalesUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.profesionalesAddNew:
            return {
                ...state,
                profesionales: [ ...state.profesionales, action.payload ]
            }

        case types.profesionalesLoad:
            return {
                ...state,
                profesionales: [ ...action.payload ]
            }
        
        case types.profesionalesUpdated:
            return {
                ...state,
                profesionales: state.profesionales.map(
                    profesional => profesional._id === action.payload._id
                        ? action.payload
                        : profesional
                )
            }

        case types.profesionalesDelete:
            return {
                ...state,
                active: null,
                profesionales: state.profesionales.filter( profesional => profesional._id !== action.payload )
            }
    
        case types.profesionalesLogoutCleaning:
            return {
                ...state,
                active: null,
                profesionales: []
            }
    

        default:
            return state;
    }
}