
import { types } from "../types/types";

const initialState = {
    citas: [],
    active: null
}

export const citasReducer = ( state = initialState, action ) => {
    
    switch (action.type) {

        case types.citasActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.citasUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.citasAddNew:
            return {
                ...state,
                citas: [ ...state.citas, action.payload ]
            }
        
        case types.citasAddNewDouble:
            return {
                ...state,
                citas: [ ...state.citas, action.payload ]
            }

        case types.citasLoad:
            return {
                ...state,
                citas: [ ...action.payload ]
            }
        
        case types.citasUpdated:
            return {
                ...state,
                citas: state.citas.map(
                    cita => cita._id === action.payload._id
                        ? action.payload
                        : cita
                )
            }

        case types.citasDelete:
            return {
                ...state,
                active: null,
                citas: state.citas.filter( cita => cita._id !== action.payload )
            }
    
        case types.citasLogoutCleaning:
            return {
                ...state,
                citas: [],
                active: null
            }
    

        default:
            return state;
    }
}