import React from 'react'
import { BellIcon, CustomizeArrowRight, FooterAdd, FooterBonos, FooterHome, FooterProductos, FooterReservas, UserIcon } from '../../../../assets/svg/iconos'

export const CustomScreen = ({ colorThemeSelected, fontThemeSelected, objEmpresa }) => {
    return (
        <div className='slider-item'>
            <div className='first-header'>
                <div
                    className='bg-btn'
                    style={{
                        backgroundColor: colorThemeSelected.secondary[0]
                    }}
                >
                    <div className='header-btn'>
                        <BellIcon color={colorThemeSelected.main} />
                        <div
                            className='badge'
                            style={{
                                backgroundColor: colorThemeSelected.main
                            }}
                        >
                            <span>3</span>
                        </div>
                    </div>
                </div>
                <div
                    className='bg-btn'
                    style={{
                        backgroundColor: colorThemeSelected.secondary[4]
                    }}
                >
                    <div className='header-btn'>
                        <UserIcon color={colorThemeSelected.main} />
                    </div>
                </div>
            </div>
            <div className='next-reservation'>
                <h3
                    style={{
                        color: colorThemeSelected.main
                    }}
                ><FooterReservas width={'12'} height={'12'} color={colorThemeSelected.main} /> <span>Próxima reserva</span></h3>
                <div
                    className='next-row'
                    style={{
                        backgroundColor: colorThemeSelected.secondary[4],
                        color: colorThemeSelected.main
                    }}
                >
                    <div className='row-date'>08/01/22</div>
                    <div className='row-time'>12.30h</div>
                    <div className='row-amount'>22€</div>
                    <div className='row-arrow'><CustomizeArrowRight color={colorThemeSelected.main} /></div>
                </div>
            </div>
            <div
                className='hero'
                style={{
                    backgroundColor: colorThemeSelected.main,
                    backgroundImage: objEmpresa.img[0] && objEmpresa.img[0] instanceof File ?
                        `url('${URL.createObjectURL(objEmpresa.img[0])}')`
                        : `url('${objEmpresa.img[0]}')`,
                    backgroundSize: "cover"
                }}
            >
                <div
                    className='hero-content'
                    style={{
                        fontFamily: `${fontThemeSelected}, sans-serif`,
                    }}
                >
                    <img src={objEmpresa.icon} alt='logo'
                        width="35px"
                    />
                    <h3>{objEmpresa.titulo.length > 0 ? objEmpresa.titulo : 'Título de dos líneas de largo'}</h3>
                    <p>{objEmpresa.descripcion.length > 0 ? objEmpresa.descripcion : 'Aqui pueden poner el texto que quieran como reclamo a la acción y ocupará 5 lineas como mucho de largo.'}</p>
                </div>
            </div>
            <div
                className='common-footer'
                style={{
                    backgroundColor: colorThemeSelected.main
                }}
            >
                <div className='footer-btn'>
                    <FooterHome color={colorThemeSelected.secondary[0]} width={13} height={13} />
                </div>
                <div className='footer-btn'>
                    <FooterReservas color={colorThemeSelected.main} width={13} height={13} active={false} />
                </div>
                <div className='footer-btn central'>
                    <div className='central-wrapper'>
                        <div
                            className='central-btn'
                            style={{
                                backgroundColor: colorThemeSelected.secondary[1]
                            }}
                        ><FooterAdd color={colorThemeSelected.main} /></div>
                        <div
                            className='central-txt'
                            style={{
                                color: colorThemeSelected.secondary[1]
                            }}
                        >Reservar</div>
                    </div>
                </div>
                <div className='footer-btn'>
                    <FooterProductos color={colorThemeSelected.main} width={13} height={13} active={false} />
                </div>
                <div className='footer-btn'>
                    <FooterBonos color={colorThemeSelected.main} width={13} height={13} active={false} />
                </div>
            </div>
        </div>
    )
}
