import { types } from "../types/types";
import { fetchConToken } from '../helpers/fetch';
import { closeLoading, openModal, openModalBottom } from './ui';

export const startNewTextLegal = (values, swal = true) => {
    return async (dispatch, getState) => {
        try {
            const resp = await fetchConToken('textoslegales', values, 'POST');
            const body = await resp.json();
            if (body.ok) {
                if (swal) {
                    const datos = {
                        text: ['El Texto Legal ha sido creado con éxito'],
                        timeOut: 3
                    }
                    dispatch(openModalBottom(datos));
                }
                dispatch(addNewTextlegal(body.textLegal._id, body.textLegal));
                if (body.textLegal2) {
                    dispatch(addNewTextlegal(body.textLegal2._id, body.textLegal2));
                }
            } else {
                const datos = {
                    title: 'Error',
                    text: [body.msg || 'Ha ocurrido un error al crear el texto legal'],
                    buttonText: 'Cerrar'
                }
                dispatch(openModal(datos));
            }
        } catch (error) {
            const datos = {
                title: 'Error',
                text: [error.response?.data?.msg || 'Ha habido un error al conectar con el servidor'],
                buttonText: 'Cerrar'
            }
            dispatch(openModal(datos));
        }
    }
};

export const startSaveTextLegal = (id, values, swal = true) => {
    return async (dispatch, getState) => {
        try {
            const resp = await fetchConToken(`textoslegales/${id}`, values, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                if (swal) {
                    const datos = {
                        text: ['Los textos Legales han sido actualizados éxitosamente'],
                        timeOut: 3
                    }
                    dispatch(openModalBottom(datos));
                }
            } else {
                const datos = {
                    title: 'Error',
                    text: [body.msg || 'Ha ocurrido un error al actualizar el texto legal'],
                    buttonText: 'Cerrar'
                }
                dispatch(openModal(datos));
            }
        } catch (error) {
            const datos = {
                title: 'Error',
                text: [error.response?.data?.msg || 'Ha habido un error al conectar con el servidor'],
                buttonText: 'Cerrar'
            }
            dispatch(openModal(datos));
        }
    }
};

export const startLoadTextLegal = (id) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`textoslegales/${id}`, 'GET');
            const body = await resp.json();
            dispatch(setTextLegalLoad(body.textoLegal));
        } catch (error) {
            console.log('Hay error');
            console.log(error);
        }
    }
};

export const addNewTextlegal = (_id, textLegal) => ({
    type: types.textLegalNew,
    payload: {
        _id,
        ...textLegal
    }
});

export const setTextLegalLoad = (textLegal) => ({
    type: types.textLegalLoad,
    payload: textLegal
});