import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { planesStartDelete, planLogout } from '../../actions/planes';
import { setMenuLateral } from '../../actions/ui';
import { formatCurrency } from '../../helpers/helpers';

export const Planes = ({ history }) => {

    const dispatch = useDispatch();
    const { planes } = useSelector(state => state.planes);
    const { colores } = useSelector(state => state.ui);

    const [searchActive, setSearchActive] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [planesList, setPlanesList] = useState([]);

    const [modalEliminarPlanOpened, setModalEliminarPlanOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar plan?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [selectedId, setSelectedId] = useState('');

    useEffect(() => {
        dispatch(setMenuLateral('planes'));

        return () => {
            dispatch(planLogout());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //console.log( 'planes', planes );
        procesaPlanes(planes, false);
    }, [planes]);

    /* useEffect(() => {
        console.log('planesList', planesList);
    }, [planesList]) */



    useEffect(() => {
        /* if( searchTxt.trim().length > 3 ){
            dispatch( startSearchEmpresas( searchTxt.trim(), pagNumber | 1, LIMITE_EMPRESAS, orderBy, asc ) );
            setInitialSearch( false );
        } */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTxt]);

    const procesaPlanes = (planes, filtro = true) => {
        let newPlanes = [];
        //console.log( 'procesaPlanes', planes );
        //console.log( 'totalPlanes', totalPlanes );
        let contador = 0;
        planes.forEach(plan => {
            if (!plan.deleted) {
                newPlanes = [...newPlanes, plan];
                contador++;
            }
            if (filtro) {
                if (contador > 14) {
                    return;
                }
            }
        });
        setPlanesList(newPlanes);
    }

    const clickSearch = () => {
        if (searchActive) {
            setSearchTxt('');
        }
        setSearchActive(!searchActive);
    };

    const clickDeleteSearch = () => {
        setSearchTxt('');
    }

    const handleSearchChange = (e) => {
        setSearchTxt(e.target.value);
    }

    const clickAdd = () => {
        history.push({
            pathname: `/plan/new`
        });
    };

    const editPlan = (plan) => {
        history.push({
            pathname: `/plan/${plan._id}`,
            state: {
                plan
            }
        });
    };

    const deletePlanModal = (planId, name) => {
        setSelectedId(planId);
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar el plan ${name}?`]
        })
        setModalEliminarPlanOpened(true);
    };

    const deletePlanResponse = (value) => {
        if (value === 1 && selectedId !== '') {
            // console.log( 'Borrar id:', selectedId );
            dispatch(planesStartDelete(selectedId));
        }
    }

    return (
        <>
            <div
                className='empresas'
                style={{
                    '--colorPrincipal': colores.colorPrincipal,
                    '--colorSecundario': colores.colorSecundario,
                    '--color3': colores.color3,
                    '--color4': colores.color4,
                    '--color5': colores.color5,
                    '--color6': colores.color6,
                    '--color7': colores.color7,
                }}
            >
                <div className="preheader">
                    <div className="title">
                        <h1 style={{ color: colores.colorPrincipal }}>Planes</h1>
                    </div>
                    <div className="botonera">
                        {/* <div 
                        className={`btn-search ${searchActive && 'active'}`}
                    >
                        <div className='search-input'>
                            <input 
                                type={'text'}
                                placeholder={'Buscar por nombre'}
                                value={ searchTxt }
                                onChange={ handleSearchChange }
                            />
                            {
                                searchTxt.trim().length > 2 &&
                                <span className='cantidad'>
                                    ({ planesList.length })
                                </span>
                            }
                        </div>
                        {
                            searchTxt.trim().length < 1 &&
                            <div
                                className='icono'
                                onClick={ clickSearch }
                            >
                                <BuscarIcon
                                    color={ colores.colorPrincipal }
                                    className="icon-inverse"
                                />
                            </div>
                        }
                        {
                            searchTxt.trim().length >= 1 &&
                            <div
                                className='icono'
                                onClick={ clickDeleteSearch }
                            >
                                <CloseIcon
                                    color={ colores.colorPrincipal }
                                    className="icon-inverse"
                                />
                            </div>
                        }
                    </div> */}
                        {/* <div 
                        className="btn-add"
                        style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                        onClick={ clickAdd }
                    >
                        <div className='icono'>
                            <AddIcon
                                color={ colores.colorPrincipal }
                            />
                        </div>
                        
                    </div> */}
                    </div>
                </div>
                <div className='planes-list'>
                    {
                        planesList.map(plan => (
                            <div
                                key={plan._id}
                                className='plan-item'
                                onClick={() => editPlan(plan)}
                            >
                                <div className='plan-header'>
                                    {
                                        plan.recomendado &&
                                        <div className='recomendado'>Recomendado</div>
                                    }
                                    <h5>Plan {plan.nombre}</h5>
                                    <div className='price'>
                                        <p>{formatCurrency(plan.mensual)}€<span>/ mes</span></p>
                                    </div>
                                </div>
                                <div className='plan-body'>
                                    <div className='plan-feature'>
                                        <div className='plan-check'>
                                            <div className='plan-check-item'></div>
                                        </div>
                                        <p>{plan.profesionales} profesionales</p>
                                    </div>
                                    <div className='plan-feature'>
                                        <div className='plan-check'>
                                            <div className='plan-check-item'></div>
                                        </div>
                                        <p>{plan._idiomas} {plan._idiomas > 1 ? 'idiomas' : 'idioma'}</p>
                                    </div>
                                    <div className='plan-feature'>
                                        <div className='plan-check'>
                                            <div className='plan-check-item'></div>
                                        </div>
                                        <p>{plan.promociones} {plan.promociones > 1 ? 'promociones' : 'promoción'}</p>
                                    </div>
                                    <div className='plan-feature'>
                                        <div className='plan-check'>
                                            <div className='plan-check-item'></div>
                                        </div>
                                        <p>Logotipo corporativo</p>
                                    </div>
                                    <div className='plan-feature'>
                                        <div className='plan-check'>
                                            <div className='plan-check-item'></div>
                                        </div>
                                        <p>{plan.gamaColores} Gamas de colores</p>
                                    </div>
                                    <div className='plan-feature'>
                                        <div className='plan-check'>
                                            <div className='plan-check-item'></div>
                                        </div>
                                        <p>{plan.tipografias} Tipografías a escoger</p>
                                    </div>
                                    {
                                        plan.recordatorios &&
                                        <div className='plan-feature'>
                                            <div className='plan-check'>
                                                <div className='plan-check-item'></div>
                                            </div>
                                            <p>Recordatorios de citas</p>
                                        </div>
                                    }
                                    {
                                        plan.listasEspera &&
                                        <div className='plan-feature'>
                                            <div className='plan-check'>
                                                <div className='plan-check-item'></div>
                                            </div>
                                            <p>Listas de espera</p>
                                        </div>
                                    }
                                    {
                                        plan.banners > 0 &&
                                        <div className='plan-feature'>
                                            <div className='plan-check'>
                                                <div className='plan-check-item'></div>
                                            </div>
                                            <p>{plan.banners} Banners personalizables</p>
                                        </div>
                                    }
                                    {
                                        plan.valoraciones &&
                                        <div className='plan-feature'>
                                            <div className='plan-check'>
                                                <div className='plan-check-item'></div>
                                            </div>
                                            <p>Valoraciones</p>
                                        </div>
                                    }
                                    {
                                        plan.iconoApp &&
                                        <div className='plan-feature'>
                                            <div className='plan-check'>
                                                <div className='plan-check-item'></div>
                                            </div>
                                            <p>Icono App personalizable</p>
                                        </div>
                                    }
                                </div>
                                <div className='plan-footer'>
                                    <p>{plan.formacion}h. de formación</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {/* <ModalEliminarPlan
            data = { dataModalEliminar }
            setModalResponse = { deleteClienteResponse }
            modalOpened = { modalEliminarPlanOpened }
            setModalOpened = { setModalEliminarPlanOpened }
        /> */}
        </>
    )
}
