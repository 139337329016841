
import { types } from "../types/types";

const initialState = {
    comentarios: [],
    active: null
}

export const comentariosReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.comentariosActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.comentariosUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.comentariosAddNew:
            return {
                ...state,
                comentarios: [ ...state.comentarios, action.payload ]
            }

        case types.comentariosLoad:
            return {
                ...state,
                comentarios: [ ...action.payload ]
            }
        
        case types.comentariosUpdated:
            return {
                ...state,
                comentarios: state.comentarios.map(
                    comentario => comentario._id === action.payload._id
                        ? action.payload
                        : comentario
                )
            }

        case types.comentariosDelete:
            return {
                ...state,
                active: null,
                comentarios: state.comentarios.filter( comentario => comentario._id !== action.payload )
            }
    
        case types.comentariosLogoutCleaning:
            return {
                ...state,
                active: null,
                comentarios: []
            }
    

        default:
            return state;
    }
}