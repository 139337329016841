
import { types } from "../types/types";

const initialState = {
    vacaciones: [],
    active: null
}

export const vacacionesReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.vacacionesActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.vacacionesUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.vacacionesAddNew:
            return {
                ...state,
                vacaciones: [ ...state.vacaciones, action.payload ]
            }

        case types.vacacionesLoad:
            return {
                ...state,
                vacaciones: [ ...action.payload ]
            }
        
        case types.vacacionesUpdated:
            return {
                ...state,
                vacaciones: state.vacaciones.map(
                    vacacion => vacacion._id === action.payload._id
                        ? action.payload
                        : vacacion
                )
            }

        case types.vacacionesDelete:
            return {
                ...state,
                active: null,
                vacaciones: state.vacaciones.filter( vacacion => vacacion._id !== action.payload )
            }
    
        case types.vacacionesLogoutCleaning:
            return {
                ...state,
                active: null,
                vacaciones: []
            }
    

        default:
            return state;
    }
}