import React from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../helpers/helpers';
import ModalPlanesTableRow from './ModalPlanesTableRow';

const tableItems = [
    { title: 'Citas ilimitadas', val1: true, txt1: '', val2: true, txt2: '', val3: true, txt3: '' },
    { title: 'Servicios ilimitados', val1: true, txt1: '', val2: true, txt2: '', val3: true, txt3: '' },
    { title: 'Gestión de clientes', val1: true, txt1: '', val2: true, txt2: '', val3: true, txt3: '' },
    { title: 'Anotaciones internas', val1: true, txt1: '', val2: true, txt2: '', val3: true, txt3: '' },
    { title: 'Gestión de profesionales', val1: true, txt1: '3', val2: true, txt2: '7', val3: true, txt3: 'ilimitados' },
    { title: 'Personalización tiempos', val1: true, txt1: '', val2: true, txt2: '', val3: true, txt3: '' },
    { title: 'Profesional aleatorio', val1: true, txt1: '', val2: true, txt2: '', val3: true, txt3: '' },
    { title: 'Multidioma', val1: false, txt1: '', val2: true, txt2: '3', val3: true, txt3: '8' },
    { title: 'Promociones ilimitadas', val1: false, txt1: '', val2: false, txt2: '', val3: true, txt3: '' },
    { title: 'Recordatorios de citas', val1: false, txt1: '', val2: true, txt2: '', val3: true, txt3: '' },
    { title: 'Listas de espera', val1: false, txt1: '', val2: true, txt2: '', val3: true, txt3: '' },
    { title: 'Logotipo corporativo', val1: true, txt1: '', val2: true, txt2: '', val3: true, txt3: '' },
    { title: 'Gamas de colores', val1: true, txt1: '3', val2: true, txt2: '6', val3: true, txt3: '15' },
    { title: 'Tipografías personalizadas', val1: true, txt1: '2', val2: true, txt2: '4', val3: true, txt3: '8' },
    { title: 'Icono App personalizable', val1: false, txt1: '', val2: false, txt2: '', val3: true, txt3: '' },
    { title: 'Banners personalizables', val1: false, txt1: '', val2: true, txt2: '', val3: true, txt3: '' },
    { title: 'Valoraciones', val1: false, txt1: '', val2: true, txt2: '', val3: true, txt3: '' },
    { title: 'Formación', val1: true, txt1: '2h.', val2: true, txt2: '3h.', val3: true, txt3: '5h.' }
]

export const ModalPlanesRegister = ( { modalPlanesOpened, setModalPlanesOpened, currentEmpresa } ) => {
    const { planes } = useSelector(state => state.planes);  

    const clickCloseModal = () => {
        setModalPlanesOpened( false );
    };

    return (
        <div className={`modal-ylm modal-planes ${(modalPlanesOpened) ? 'active' : ''}`}>
            <div className="modal-bg"></div>
            <div className="modal-wrapper">
                <div
                    className="modal-close"
                    onClick={ () => clickCloseModal() }
                ><span></span></div>
                <div className='modal-top'>
                    <div className='modal-col'></div>
                    {
                        planes.map( plan => (
                            <div key={plan._id} className='modal-col'>
                                <div className='plan-header'>
                                    {
                                        plan.recomendado &&
                                        <div className='recomendado'>Recomendado</div>
                                    }
                                    <h2>Plan { plan.nombre }</h2>
                                    <div className='plan-price'>
                                        { formatCurrency( plan.mensual ) }<span>€ / mes</span>
                                    </div>
                                    <div className='plan-excerpt'>
                                        { plan.excerpt }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='modal-table'>
                    <div className='table-header'>
                        <div className='header-text'><p>Funcionalidades</p></div>
                        <div className='table-line'></div>
                    </div>
                    <div className='table-body'>
                        {
                            tableItems.map( item => (
                                <ModalPlanesTableRow
                                    key = { item.title }
                                    title = { item.title }
                                    val1 = { item.val1 }
                                    val2 = { item.val2 }
                                    val3 = { item.val3 }
                                    txt1 = { item.txt1 }
                                    txt2 = { item.txt2 }
                                    txt3 = { item.txt3 }
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
