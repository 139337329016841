const baseUrl = process.env.REACT_APP_API_URL + '/api';

const fetchSinToken = (endpoint, data, method = 'GET') => {

    const url = `${baseUrl}/${endpoint}`;

    if (method === 'GET') {
        return fetch(url);
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
    }

}

const fetchConToken = (endpoint, data, method = 'GET') => {

    const url = `${baseUrl}/${endpoint}`;

    const token = localStorage.getItem('barbgest-token') || '';

    if (method === 'GET') {
        return fetch(url, {
            method,
            headers: {
                'x-token': token
            }
        });
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify(data)
        })
    }
}

const archivoConToken = (endpoint, data, method = 'GET') => {

    const url = `${baseUrl}/${endpoint}`;

    const token = localStorage.getItem('barbgest-token') || '';

    if (method === 'GET') {
        return fetch(url, {
            method,
            headers: {
                'x-token': token
            }
        });
    } else {
        // console.log('url', url );
        // console.log( 'data', data );
        return fetch(url, {
            method,
            headers: {
                'x-token': token
            },
            body: data
        })
    }
}

const uploadFile = (folder, file, name) => {
    const token = localStorage.getItem('barbgest-token') || '';

    const url = `${baseUrl}/resources/upload`;
    const formData = new FormData();
    formData.append('archivo', file);
    formData.append("folder", folder)
    formData.append("name", name)

    return fetch(url, {
        method: "POST",
        headers: {
            'x-token': token
        },
        body: formData
    })
}


export {
    fetchSinToken,
    fetchConToken,
    archivoConToken,
    uploadFile
}