import React from 'react'

export const YlmToggle = ({ item, clickToggle, color, bgColor }) => {
  return (
    <div 
        className={ `ylm-toggle ${ item.active && 'active'}`}
        onClick={ () => clickToggle( item._id )}
    >
        <div 
            className={ `toggle-bg`}
            style={{ backgroundColor: item.active ? bgColor : '#C9C5C5' }}
        >
            <span
                className={`toggle-item`}
                style={{ backgroundColor: item.active ? color : '#7B7B7B' }}
            ></span>
        </div>

        <p>{ item.text }</p>
    </div>
  )
}
