import React, { useRef, useState } from 'react'
import { ListManager } from 'react-beautiful-dnd-grid';
import { useDispatch, useSelector } from 'react-redux';
import { variantesReorder, varianteStartDelete } from '../../../actions/variantes';
import { DndIcon } from '../../../assets/svg/iconos';
import { ModalEliminarVariante } from './modales/ModalEliminarVariante';
import { VarianteRowItem } from './variantes/VarianteRowItem';

export const Variantes = ({ history, variantesList, setVariantesList }) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { colores } = useSelector(state => state.ui);
    const wrapper = useRef();

    const [modalEliminarVarianteOpened, setModalEliminarVarianteOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar variante?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [selectedId, setSelectedId] = useState('');
    const [columnas, ] = useState(5);

    const [activeDnd, setActiveDnd] = useState(false);
    
    const editVariante = ( variante ) => {
        history.push({
            pathname: `/variante/${variante._id}`,
            state: {
                variante
            }
        });
    };

    /* const deleteVariante = ( variante ) => {
        console.log( 'deleteVariante', variante );
    }  */   

    const deleteVarianteModal = ( variante ) => {
        setSelectedId( variante._id );
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar ${variante.nombre}?`]
        })
        setModalEliminarVarianteOpened( true );
    };

    const deleteVarianteResponse = ( value ) => {
        if( value === 1 && selectedId !== '' ){
            console.log( 'Borrar id:', selectedId );
            dispatch( varianteStartDelete( selectedId, empresa ) );
        }
    }

    const noop = (oldPos, newPos) => {
        const itemMoved = variantesList[oldPos];
        const newList = variantesList.map( variante => {
            if( variante._id === itemMoved._id ){
                variante.orden = newPos;
            }
            if( newPos < oldPos ){
                if( variante._id !== itemMoved._id && variante.orden < oldPos && variante.orden >= newPos ){
                    variante.orden = variante.orden + 1;
                }
            }
            if( newPos > oldPos ){
                if( variante._id !== itemMoved._id && variante.orden > oldPos && variante.orden <= newPos ){
                    variante.orden = variante.orden - 1;
                }
            }
            return variante;
        });

        const sortByOrden = (a,b) => {
            if( a.orden > b.orden ) {
                return 1;
            }
            if( a.orden < b.orden ) {
                return -1;
            } else {
                return 0;
            }
        }

        newList.sort( sortByOrden );
        setVariantesList( newList );
        dispatch( variantesReorder( newList, empresa ));
    };

    const toggleDnd = () => {
        setActiveDnd( !activeDnd );
    }

    return (
        <>
        {
            !activeDnd &&
            <div 
                className='variantes'
                style={{
                    '--colorPrincipal': colores.colorPrincipal,
                    '--colorSecundario': colores.colorSecundario,
                    '--color3': colores.color3,
                    '--color4': colores.color4,
                    '--color5': colores.color5,
                    '--color6': colores.color6,
                    '--color7': colores.color7,
                }}
            >
                <div className='variantes-table'>
                  {
                    variantesList.map( variante => (
                      <VarianteRowItem
                        key={ variante._id }
                        variante={ variante }
                        editVariante={ editVariante }
                        deleteVariante={deleteVarianteModal}
                      />
                    ))
                  }
                </div>
            </div>
        }
        {
            activeDnd
            &&
            <div className='variantes'>
                <div className='variantes-grid dnd' ref={wrapper}>
                    <ListManager
                        items={variantesList}
                        direction="horizontal"
                        maxItems={columnas}
                        render={variante => !variante.deleted && 
                            <></>
                        }
                        onDragEnd={noop}
                    />
                </div>
            </div>
        }
        <div 
            className={ `toggle-dnd ${activeDnd ? 'active' : 'unactive'}`}
            onClick={ toggleDnd }
        ><DndIcon color={ activeDnd ? 'white' : colores.colorPrincipal } /><p>Drag & Drop</p></div>
        <ModalEliminarVariante
            data = { dataModalEliminar }
            setModalResponse = { deleteVarianteResponse }
            modalOpened = { modalEliminarVarianteOpened }
            setModalOpened = { setModalEliminarVarianteOpened }
        />
        </>
    )
}
