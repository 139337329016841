import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { startSaveProfesional } from '../../../../actions/profesionales';
import { SucArrowIcon } from '../../../../assets/svg/iconos';

export const DdSucursal = ({ sucursal }) => {
    const dispatch = useDispatch();
    const { profesionales } = useSelector(state => state.profesionales);
    const [listado, setListado] = useState([]);
    const [firstTimeBool, setFirstTimeBool] = useState(true);

    const [activeDnd, ] = useState(false);

    useEffect(() => {
        console.log("Sucursal: ", sucursal);
        console.log("Profesionales: ", profesionales);
        const profesionalesVisibles = profesionales.filter(prof=>prof.visibleEnApp==true);

        
        if( profesionalesVisibles.length > 0 ){
            let newListado = [];
            let profesionalesListado = [];
            profesionalesVisibles.forEach(prof => {
                let colocado = false;
                //console.log( 'prof', prof );
                prof.horarioSucursales.forEach( horario => {
                    if( profesionalesListado.includes( prof._id ) ){
                        //console.log('Está en el listado');
                        colocado = true;
                    }
                    //console.log( 'horario', horario );
                    if( horario.sucursal !== null ){
                        const idSucursalHorario = horario.sucursal._id ? horario.sucursal._id : horario.sucursal;
                        //console.log( 'idSucursalHorario', idSucursalHorario );
                        if( idSucursalHorario === sucursal._id && !prof.deleted && !colocado ){
                            //console.log( 'horario', horario );
                            const newProf = {
                                _id: prof._id,
                                color: prof.color,
                                nombre: prof.nombre,
                                prioridad: horario.prioridad
                            }
                            newListado = [...newListado, newProf ];
                            //console.log( 'newListado', newListado );
                        }
                    }
                })
            });

            newListado.sort( (a,b) => {
                if( parseInt(a.prioridad) > parseInt(b.prioridad) ){
                    return 1;
                }
                if( parseInt(a.prioridad) < parseInt(b.prioridad) ){
                    return -1;
                }
                return 0;
            });

            if( firstTimeBool ){
                reorderItems( newListado, false );
                setListado( newListado );
                setFirstTimeBool( false );
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sucursal, profesionales]);

    /* useEffect(() => {
      console.log( 'listado', listado );
    }, [listado]) */
    
    

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        // console.log( 'result', result );
    
        const newArray = [ ...listado ];
        const element = newArray.splice( result.source.index, 1 )[0];
        newArray.splice(result.destination.index, 0, element);
    
        reorderItems( newArray );
    }
    
    const arrowClick = ( item, index, up = true ) => {

        const compruebaLimites = (idx) => {
            if( idx < 0 ){
                return false;
            }
            if( idx > listado.length - 1 ){
                return false
            }

            return true;
        }
        const newIndex = up ? index - 1 : index + 1;
        const esPosible = compruebaLimites( newIndex );

        if( esPosible ){
            const newArray = [ ...listado ];
            const element = newArray.splice( index, 1 )[0];
            newArray.splice( newIndex, 0, element);
        
            reorderItems( newArray );
        }
    }

    const reorderItems = ( items, save = true ) => {
        console.log( 'reorderItems', items );

        items.map( (item, idx) => {
            item.prioridad = `${idx + 1}`;
            return item;
        });

        items.forEach( item => {
            let profesional = {};
            profesionales.forEach(prof => {
                if( prof._id === item._id ){
                    prof.horarioSucursales.forEach(horario => {
                        if( horario.sucursal !== null ){
                            const idSucursalHorario = horario.sucursal._id ? horario.sucursal._id : horario.sucursal;
                            if( idSucursalHorario === sucursal._id ){
                                // console.log( `${prof.nombre} tenia prioridad ${ horario.prioridad } y ahora tendrá ${ item.prioridad }`)
                                horario.prioridad = item.prioridad;
                            }
                        }
                    });
                    profesional = prof;
                }
            });
            if( profesional._id ){
                // console.log(`Grabo profesional ${profesional.nombre}`);
                // console.log( profesional );
                if( save ){
                    dispatch( startSaveProfesional( profesional ) );
                }
            }
        });

        setListado( items );

        console.log( 'Items profesionales: ', items );
        
      };

    const getListStyle = isDraggingOver => ({
        //background: isDraggingOver ? "lightblue" : "lightgrey",
        //padding: grid,
        //width: 250
    });

    const getItemStyle = (isDragging, draggableStyle, itemColor, itemNombre ) => ({
        // some basic styles to make the items look a bit nicer
        /* userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`, */
      
        // change background colour if dragging
        //background: isDragging ? "lightgreen" : itemColor,
        backgroundColor: itemColor,
        fontSize: itemNombre.length > 14 ? sucursal.nombre.length > 25 ? '12px' : '14px' : '16px',
      
        // styles we need to apply on draggables
        ...draggableStyle
      });

    return (
            <div 
                key={ sucursal._id } 
                className='dd-sucursal'
            >
                <div 
                    className='suc-title'
                    style={{ fontSize: `${ sucursal.nombre.length > 14 ? sucursal.nombre.length > 25 ? '12px' : '14px' : '16px' }`}}
                >{ sucursal.nombre }</div>
                {
                    activeDnd 
                    ?
                    <DragDropContext 
                        onDragEnd={onDragEnd}
                    >
                        <Droppable droppableId={`droppable${ sucursal._id}`}>
                            {(provided, snapshot) => (
                            <div 
                                className='dropp'
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {
                                    listado.map( (item, index) => (
                                        <Draggable key={ item._id } draggableId={ item._id } index={ index }>
                                            {(provided, snapshot) => (
                                            <div
                                                className='suc-prof'
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                  snapshot.isDragging,
                                                  provided.draggableProps.style,
                                                  item.color,
                                                  item.nombre
                                                )}
                                            >
                                                { item.nombre }
                                            </div>
                                            )}
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                :
                listado.map( (item, index) => (
                    <div
                        key={ item._id }
                        className='suc-prof'
                        style={{
                            backgroundColor: item.color
                        }}
                    >
                        { item.nombre }
                        <div className='prof-arrows'>
                            <div 
                                className='btn-arrow'
                                onClick={ () => arrowClick( item, index )}
                            >
                                <SucArrowIcon />
                            </div>
                            <div 
                                className='btn-arrow arrow-down'
                                onClick={ () => arrowClick( item, index, false )}
                            >
                                <SucArrowIcon />
                            </div>
                        </div>
                    </div>
                ))
                }
            </div>
    )
}
