import { useCallback, useRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import Calendar from 'react-calendar';

import useClickOutside from '../../../../hooks/useClickOutside';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';
import { openModalBottom } from '../../../../actions/ui';
import { useDispatch } from 'react-redux';

const timezone = process.env.REACT_APP_TIMEZONE;

export const SelectCaducidad = ({objPromocion, dataChange, editMode}) => {

    const dispatch = useDispatch();
    const [dateClicked, setDateClicked] = useState( false );
    const [selectText, setSelectText] = useState('');
    const [fechaCalendar, setFechaCalendar] = useState( new Date() );

    const popover = useRef();
    const [isOpen, toggle] = useState(false);
    

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    useEffect(() => {
      if( objPromocion?.caducidad ){
        if( moment.utc( objPromocion?.caducidad ).format('DD-MM-YYYY') === '31-12-3100' ){
            setSelectText( 'Nunca' );
        } else {
            setSelectText( moment.tz( objPromocion?.caducidad, timezone ).format('D/M/YYYY') );
            setDateClicked( true );
            setFechaCalendar( moment.tz(objPromocion?.caducidad, timezone).toDate() );
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objPromocion]);

    const dayClicked = () => {
        /* setTimeCalendar(new Date(1972,10,13,0,0));
        (visibility) ? hideDropdown() : showDropdown() */
    };

    const dateChange = (e) => {
        if( editMode ){
            setFechaCalendar( e );
            const stringDate = `${moment(e).format('YYYY-MM-DD')} 23:59:59`;
            setSelectText( moment.tz( stringDate, timezone ).format('D/M/YYYY') );
            dataChange( 'caducidad', moment.tz( stringDate, timezone ).toISOString() );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    };

    const radioClicked = ( option ) => {
        if( editMode ){
            console.log( 'radioClicked' );
            if( option === 'nunca' ){
                dataChange( 'caducidad', moment.utc('3100-12-31 23:59:59').toISOString() );
                setDateClicked( false );
                setSelectText( 'Nunca' );
                setFechaCalendar( new Date() );
            } else {
                dataChange( 'caducidad', moment().tz( timezone ).toISOString() );
                setFechaCalendar( moment().tz( timezone ).toDate() );
                setSelectText( moment.tz( fechaCalendar, timezone ).format('D/M/YYYY') );
                setDateClicked( true );
            }
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    return (
        <div 
            className='ylm-caducidad-select promocion-selector'
        >
            <div 
                className='select-main'
                onClick={ clickSelect }
            >
                <p>{ selectText }</p>
                <div 
                    className='btn-chevron'
                    style={{ transform: isOpen && 'rotate(180deg)'}}
                >
                    <ChevronDownIcon
                        color={ '#000000' }
                    />
                </div>
            </div>
            {
                isOpen &&
                <ul 
                    className='select-options calendario' 
                    ref={popover}
                >
                    <li className={`with-calendar`}>
                        <div className={`descuento-select-date`}>
                            <Calendar
                                onClickDay={ () => dayClicked() }
                                onChange={ dateChange }
                                value={ fechaCalendar }
                                locale={`es-ES`}
                            />
                            <div className='nunca'>
                                <div 
                                    className='nunca-check'
                                    onClick={ () => radioClicked(`${dateClicked ? 'nunca' : 'fecha'}`) }
                                >
                                    <div 
                                        className='checkbox-bg'
                                    >
                                        {
                                            !dateClicked &&
                                            <span 
                                                className='checkbox-item'
                                            ></span>
                                        }
                                    </div>
                                    <p>{ `Nunca` }</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            }
        </div>
    )
}
