import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModalBottom } from "../../../actions/ui";
import { ArrowBackIcon, SegmentarIcon, SendSelectorIcon, StarSelectorIcon, TrashIcon } from "../../../assets/svg/iconos";
import { YlmModalTwoButtons } from "../../../components/main/YlmModalTwoButtons";
import { newNotificacion } from "../../../helpers/helpers";
import { SelectDifusion } from "./SelectDifusion";
import { SelectSegmentacion } from "./SelectSegmentacion";


const Notificacion = ({ history }) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector( state => state.auth );
    const { colores } = useSelector( state => state.ui );

    const [currentNotificacion, setCurrentNotificacion] = useState( history.location.state.notificacion || null );
    const [currentScreen] = useState( (history.location.state.notificacion) ? 'edit' : 'new' );

    const [objNotificacion, setObjNotificacion] = useState({});
    const [iniciado, setIniciado] = useState(false);
    const [cambiado, setCambiado] = useState(false);

    const [editMode, setEditMode] = useState(true);

    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar notificación?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });
    const [modalTwoButtonsOpened, setModalTwoButtonsOpened] = useState(false);

    useEffect(() => {  
        if( currentNotificacion ){  
            setObjNotificacion( currentNotificacion );
            setIniciado( true );
        } else {
                setEditMode( true );
                setCambiado(true);
                const nuevo = newNotificacion( empresa );
                setCurrentNotificacion( nuevo );
                setObjNotificacion( nuevo );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNotificacion]);  

    useEffect(() => {
        // if( objNotificacion._id ){
        //     console.log( objNotificacion );
        // }

        if( currentNotificacion ){
            const profesionalOriginal = { ...currentNotificacion };
            if( iniciado ){
                let igual = true;
                for( const propiedad in profesionalOriginal ){
                    //console.log( `${nEmpresa[propiedad]} !== ${objEmpresa[propiedad]}`)
                    if( profesionalOriginal[propiedad] !== objNotificacion[propiedad] ){
                        igual = false;
                        //console.log(`Cambio en ${propiedad}`);
                    }
                }
                setCambiado( !igual );
            }
        }

    }, [iniciado, currentNotificacion, objNotificacion]);

    const backClick = () => {
        history.goBack();
    }

    const guardar = () => {
        if( editMode ){
            console.log('Guardar');
        }
    }
    
    const cancelar = () => {
        if( currentScreen !== 'new' ){
            if( cambiado ){
                setEditMode( true );
                setObjNotificacion( currentNotificacion );
            }
        } else {
            history.goBack();
        }
    }

    const deleteNotificacionModal = () => {
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar la notificación ${objNotificacion.nombre}?`]
        })
        setModalTwoButtonsOpened( true );
    };

    const deleteNotificacionResponse = ( value ) => {
        if( value === 1 ){
            // let selectedId = objNotificacion._id;
            // dispatch( profesionalStartDelete( selectedId, empresa ) );
            history.push({ pathname: `/notificaciones` })
        }
    }
    
    const onFormChange = (e) => {
        let correcto = true;
        if( e.target.name === 'precio' ){
            const validNumber = new RegExp(/^\d*\.?\d*$/);
            if( !validNumber.test( e.target.value ) ){
                correcto = false;
            }
        }
        if( correcto ){
            dataChange( e.target.name, e.target.value );
        }
    }

    const dataChange = (name, value) => {
        if( editMode ){
            setObjNotificacion({
                ...objNotificacion,
                [name]: value
            });
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    return (
        <>
        <div 
            className='notificacion'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className='notificacion-wrapper'>
                <div className='single-notificacion'>
                    <div className='header'>
                        <div 
                            className='nav-back'
                            onClick={ backClick }
                        >
                            <ArrowBackIcon color={ colores.colorSecundario } />
                            <p style={{ color: colores.colorSecundario }}>Notificaciones</p>
                        </div>
                        <div className='botonera'>
                            {/* {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-editar'
                                    style={{ backgroundColor: editMode ? colores.colorPrincipal : colores.color6 }}
                                    onClick={ () => setEditMode( !editMode ) }
                                >
                                    <EditarIcon color={ editMode ? colores.color6 : colores.colorPrincipal } />
                                </button>
                            } */}
                            {
                                (!cambiado) &&
                                <button
                                    className='btn-mini btn-eliminar'
                                    style={{ backgroundColor: colores.color6 }}
                                    onClick={ deleteNotificacionModal }
                                >
                                    <TrashIcon color={ colores.colorPrincipal } />
                                </button>
                            }
                            {
                            (cambiado) &&
                                <button 
                                    className='btn btn-crear'
                                    style={{ backgroundColor: colores.colorPrincipal }}
                                    onClick={ guardar }
                                >
                                    {`${currentScreen === 'new' ? 'Crear' : 'Guardar'}`}
                                </button>
                            }
                            {
                            (cambiado) &&
                                <button 
                                    className='btn btn-cancelar'
                                    style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
                                    onClick={ cancelar }
                                >
                                    Cancelar
                                </button>
                            }
                        </div>
                    </div>
                    <div className='notificacion-body'>
                        <input
                            style={{ 
                                backgroundColor: editMode ? '#ffffff' : 'transparent',
                                // border: `${objErrores.nombre ? '2px solid ' + colores.color5 : 'none'}`
                            }}
                            autoComplete='off'
                            type="text"
                            placeholder='Nuevo servicio'
                            name="nombre"
                            disabled={ !editMode }
                            value={ objNotificacion.nombre || '' }
                            onChange={ (e) => onFormChange( e )}
                        />
                        <div className='noti-main'>
                            <div className="mensaje-container">
                                <h3>Mensaje</h3>
                                <textarea
                                    name="mensaje"
                                    placeholder='Añadir notificación'
                                    disabled={ !editMode }
                                    value={ objNotificacion.mensaje }
                                    onChange={ (e) => onFormChange( e )}
                                ></textarea>
                            </div>
                        </div>
                        <div className='noti-selectors'>
                            <div
                                className={`noti-selector segmentar ${objNotificacion.segmentacion && 'active'}`}
                            >
                                <div className='left'>
                                    <div className='icon-bg'>
                                        <SegmentarIcon color={ `${objNotificacion.segmentacion ? colores.colorPrincipal : '#7B7B7B'}` }/>
                                    </div>
                                </div>
                                <div className='right'>
                                    <div 
                                        className={ `ylm-toggle ${ objNotificacion.segmentacion && 'active'}`}
                                        onClick={ () => editMode && dataChange( 'segmentacion', !objNotificacion.segmentacion )}
                                    >
                                        <p>{ `Segmentar notificación` }</p>
                                        <div 
                                            className={ `toggle-bg`}
                                        >
                                            <span
                                                className={`toggle-item`}
                                            ></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`segmentar-row ${objNotificacion.segmentacion && 'active'}`}>
                                <div 
                                    className='row-selector difusion'
                                    style={{ 
                                        backgroundColor: colores.colorSecundario,
                                        //border: `${objErrores.sucursales ? '2px solid ' + colores.color5 : 'none'}`
                                    }}
                                >
                                    <div className='right'>
                                        <div className='title' style={{ color: colores.colorPrincipal }}>Difusión</div>
                                        <SelectDifusion
                                            editMode={ editMode }
                                            objNotificacion={ objNotificacion }
                                            dataChange={ dataChange }
                                        />
                                    </div>
                                </div>
                                <div 
                                    className='row-selector segmentacion'
                                    style={{ 
                                        backgroundColor: colores.colorSecundario,
                                        //border: `${objErrores.sucursales ? '2px solid ' + colores.color5 : 'none'}`
                                    }}
                                >
                                    <div className='right'>
                                        <div className='title' style={{ color: colores.colorPrincipal }}>Segmentación</div>
                                        <SelectSegmentacion
                                            editMode={ editMode }
                                            objNotificacion={ objNotificacion }
                                            dataChange={ dataChange }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`noti-selector destacar ${objNotificacion.destacar && 'active'}`}
                            >
                                <div className='left'>
                                    <div className='icon-bg'>
                                        <StarSelectorIcon color={ `${objNotificacion.destacar ? colores.colorPrincipal : '#7B7B7B'}` }/>
                                    </div>
                                </div>
                                <div className='right'>
                                    <div 
                                        className={ `ylm-toggle ${ objNotificacion.destacar && 'active'}`}
                                        onClick={ () => editMode && dataChange( 'destacar', !objNotificacion.destacar )}
                                    >
                                        <p>{ `Destacar notificación` }</p>
                                        <div 
                                            className={ `toggle-bg`}
                                        >
                                            <span
                                                className={`toggle-item`}
                                            ></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`noti-selector enviar ${objNotificacion.enviar && 'active'}`}
                            >
                                <div className='left'>
                                    <div className='icon-bg'>
                                        <SendSelectorIcon color={ `${objNotificacion.enviar ? colores.colorPrincipal : '#7B7B7B'}` }/>
                                    </div>
                                </div>
                                <div className='right'>
                                    <div 
                                        className={ `ylm-toggle ${ objNotificacion.enviar && 'active'}`}
                                        onClick={ () => editMode && dataChange( 'enviar', !objNotificacion.enviar )}
                                    >
                                        <p>{ `Enviar notificación` }</p>
                                        <div 
                                            className={ `toggle-bg`}
                                        >
                                            <span
                                                className={`toggle-item`}
                                            ></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <YlmModalTwoButtons
            data = { dataModalEliminar }
            setModalResponse = { deleteNotificacionResponse }
            modalTwoButtonsOpened = { modalTwoButtonsOpened }
            setModalTwoButtonsOpened = { setModalTwoButtonsOpened }
        />
        </>
    )
}

export default Notificacion