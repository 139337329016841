import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutosizeInput from 'react-input-autosize';

import { activePlanes, planesStartDelete, startNewPlan, startSavePlan, unactivePlanes } from '../../actions/planes';
import { openModalBottom } from '../../actions/ui';
import { ArrowBackIcon } from '../../assets/svg/iconos';
import { newPlan } from '../../helpers/helpers';
import useClickOutside from '../../hooks/useClickOutside';
import { ModalEliminarPlan } from './components/ModalEliminarPlan';
import PermisoItem from './components/PermisoItem';

export const Plan = ({ history }) => {

    const dispatch = useDispatch();
    const { plan } = useSelector(state => state.auth);
    const { active: planActive } = useSelector(state => state.planes);
    const { colores } = useSelector(state => state.ui);

    const [objPlan, setObjPlan] = useState({});
    const [iniciado, setIniciado] = useState(false);
    const [cambiado, setCambiado] = useState(false);

    const [editMode, setEditMode] = useState(true);

    const [, setCurrentPlan] = useState( history.location.state?.plan || null );
    const [currentScreen] = useState( (history.location.state?.plan) ? 'edit' : 'new' );
    const [objErrores, setObjErrores] = useState({});

    const popover = useRef();
    const [, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const [modalEliminarPlanOpened, setModalEliminarPlanOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar plan?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [precio, setPrecio] = useState(0);
    const [precioAnual, setPrecioAnual] = useState(0);
    const [precioReal, setPrecioReal] = useState(0);
    const [precioRealAnual, setPrecioRealAnual] = useState(0);

    useEffect(() => {
      if( history.location.state?.plan._id ){
        dispatch( activePlanes( history.location.state?.plan._id, history.location.state?.plan ));
      }
      return () => {
        dispatch( unactivePlanes() );
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    /* useEffect(() => {
      console.log( 'planActive', planActive );
      console.log( 'objPlan', objPlan );
    }, [planActive, objPlan]); */
    
    /* useEffect(() => {
      console.log( currentScreen );
    }, [currentScreen]) */
    

    useEffect(() => {
      if( planActive ){
        setObjPlan( planActive );
        setPrecioInicial( planActive.mensual );
        setPrecioInicialAnual( planActive.anual );
        setIniciado( true );
        // setEditMode( true );
      } else {
        // setEditMode( true )
        const nuevo = newPlan();
        setCurrentPlan( nuevo );
        setObjPlan( nuevo );
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planActive]); 

    useEffect(() => {
      if( planActive ){
        const planOriginal = { ...planActive };
        if( iniciado ){
            let igual = true;
            for( const propiedad in planOriginal ){
                //console.log( `${nPlan[propiedad]} !== ${objPlan[propiedad]}`)
                if( planOriginal[propiedad] !== objPlan[propiedad] ){
                  igual = false;
                  //console.log(`Cambio en ${propiedad}`);
                }
            }
            if( precioReal !== planOriginal.mensual ){
                igual = false;
            }
            if( precioRealAnual !== planOriginal.anual ){
                igual = false;
            }
            setCambiado( !igual );
        }
      }

    }, [iniciado, planActive, objPlan, precioReal, precioRealAnual]);

    useEffect(() => {
      const unblock = history.block((location, action) => {
        /* if( !usuarioSaved ){
          return false;
        } */
      });

      return () => {
        unblock();
      }
    }, [history]);   

    const setPrecioInicial = ( valor ) => {
        setPrecioReal( valor );
        const newValue = valor / 100;
        setPrecio(newValue);
    };
    
    const setPrecioInicialAnual = ( valor ) => {
        setPrecioRealAnual( valor );
        const newValue = valor / 100;
        setPrecioAnual(newValue);
    };

    const handlePrecioChange = (event) => {
        const newValue = event.target.value;
        setPrecioReal( Number(event.target.value) * 100 );
        if( newValue !== ''){
            setPrecio( Number(event.target.value) / 100 );
        } else {
            setPrecio(0);
        }
        setPrecio(newValue);
    };  
    
    const handlePrecioAnualChange = (event) => {
        const newValue = event.target.value;
        setPrecioRealAnual( Number(event.target.value) * 100 );
        if( newValue !== ''){
            setPrecioAnual( Number(event.target.value) / 100 );
        } else {
            setPrecioAnual(0);
        }
        setPrecioAnual(newValue);
    };  

    const onFormChange = (e, number = false ) => {
        let value = e.target.value;
        if( number ){
            value = parseInt( e.target.value );
        }
        dataChange( e.target.name, value );
    }

    const dataChange = (name, value) => {
      if( editMode ){
        setObjPlan({
              ...objPlan,
              [name]: value
          });
      } else {
        dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
      }
    }
    
    const cancelClick = (e) => {
      if( !cambiado ){
        history.goBack();
      }
      /* if( usuarioSaved ){
        history.goBack();
      } */
    }

    const guardar = () => {
      let newObj = { 
        ...objPlan,
        _id: objPlan._id
      }
      let correcto = true;
      const newObjErrores = {};
      if( objPlan?.nombre.trim() === '' ){ 
        correcto = false;
        newObjErrores.nombre = 'Debe escribir un nombre';
      }
      if( isNaN(objPlan?.mensual) ){ 
        correcto = false;
        newObjErrores.mensual = 'El valor del precio mensual no es válido';
      }
      if( objPlan?.mensual === 0 ){ 
        correcto = false;
        newObjErrores.mensual = 'Debe escribir un precio mensual mayor que 0';
      }
      if( isNaN(objPlan?.anual) ){ 
        correcto = false;
        newObjErrores.anual = 'El valor del precio anual no es válido';
      }
      if( objPlan?.anual === 0 ){ 
        correcto = false;
        newObjErrores.anual = 'Debe escribir un precio anual mayor que 0';
      }
      
      //console.log( objPlan );
      //console.log('Correcto:', correcto );
      //console.log('Errores:', newObjErrores );
      setObjErrores( newObjErrores );
      let newObjeto = { ...newObj, mensual: precioReal, anual: precioRealAnual };
      if( correcto ){
        if( currentScreen === 'new' ){
            dispatch( startNewPlan( newObjeto ) );
            history.push({ pathname: `/planes` })
        } else {
            dispatch( startSavePlan( newObjeto ) );
            dispatch( activePlanes( newObjeto._id, newObjeto ));
        }
      } else {
        console.log( newObjErrores );
      }
    }

    const cancelar = () => {
      if( currentScreen !== 'new' ){
          setEditMode( true );
          setObjPlan( planActive );
      } else {
          history.goBack();
      }
    }

    const deletePlanResponse = ( value ) => {
        if( value === 1 ){
          console.log( 'value = 1' );
          let selectedId = objPlan._id;
          
          if( !selectedId ){
              if( planActive._id ){
                  selectedId = planActive._id;
              }
          };
          
          dispatch( planesStartDelete( selectedId, plan ) );
          history.push({ pathname: `/planes` })
      }
    }

    const toggleFunctionality = ( item, valor ) => {
      //console.log( item, valor );
    };
    
  return (
    <>
    <div 
      className='plan'
      style={{
          '--colorPrincipal': colores.colorPrincipal,
          '--colorSecundario': colores.colorSecundario,
          '--color3': colores.color3,
          '--color4': colores.color4,
          '--color5': colores.color5,
          '--color6': colores.color6,
          '--color7': colores.color7,
      }}
    >
        <div className='plan-wrapper'>
            <div className='single-plan'>
                <div className='header'>
                    <div
                        className='nav-back'
                        onClick={ (e) =>  cancelClick(e) }
                    >
                      <ArrowBackIcon color={ colores.colorSecundario } />
                      <p style={{ color: colores.colorSecundario }}>Planes</p>
                    </div>
                    <div className='botonera'>
                        {/* {
                            (!cambiado && currentScreen !== 'new') &&
                            <button
                                className='btn-mini btn-editar'
                                style={{ backgroundColor: editMode ? colores.colorPrincipal : colores.color6 }}
                                onClick={ () => setEditMode( !editMode ) }
                            >
                                <EditarIcon color={ editMode ? colores.color6 : colores.colorPrincipal } />
                            </button>
                        } */}
                        {/* {
                            (!cambiado && currentScreen !== 'new') &&
                            <button
                                className='btn-mini btn-eliminar'
                                style={{ backgroundColor: colores.color6 }}
                                onClick={ deleteClienteModal }
                            >
                                <TrashIcon color={ colores.colorPrincipal } />
                            </button>
                        } */}
                        {
                        (cambiado || currentScreen === 'new') &&
                            <button 
                                className='btn btn-crear'
                                style={{ backgroundColor: colores.colorPrincipal }}
                                onClick={ guardar }
                            >
                                {`${currentScreen === 'new' ? 'Crear' : 'Guardar'}`}
                            </button>
                        }
                        {
                        (cambiado || currentScreen === 'new') &&
                            <button 
                                className='btn btn-cancelar'
                                style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
                                onClick={ cancelar }
                            >
                                Cancelar
                            </button>
                        }
                    </div>
                </div>
                <div className='plan-body'>
                    <div className='plan-data'>
                        <div className={`plan-item nombre ${ objErrores.nombre && 'error' }`}>
                            <h5>Nombre plan</h5>
                            <div className='form-item'>
                                <input
                                    autoComplete='off'
                                    type="text"
                                    placeholder='Nombre'
                                    name='nombre'
                                    value={ objPlan?.nombre || '' }
                                    onChange={ (e) => onFormChange( e )}
                                />
                            </div>
                        </div>
                        <div className={ `plan-item mensual ${ objErrores.mensual && 'error' }` }>
                            <div className='form-item'>
                                <AutosizeInput
                                    autoComplete='off'
                                    type="text"
                                    placeholder='--'
                                    name="mensual"
                                    disabled={ !editMode }
                                    value={ precio || '' }
                                    onChange={ handlePrecioChange }
                                />
                                <span className='eur'>€</span><span>/ mes</span>
                            </div>
                        </div>
                        <div className={ `plan-item anual ${ objErrores.mensual && 'error' }` }>
                            <div className='form-item'>
                                <AutosizeInput
                                    autoComplete='off'
                                    type="text"
                                    placeholder='--'
                                    name="anual"
                                    disabled={ !editMode }
                                    value={ precioAnual || '' }
                                    onChange={ handlePrecioAnualChange }
                                />
                                <span className='eur'>€</span><span>/ año</span>
                            </div>
                        </div>
                    </div>
                    <div className='plan-description'>
                        <h3>Descripción</h3>
                        <textarea
                            name="descripcion"
                            placeholder='Añadir descripción'
                            disabled={ !editMode }
                            value={ objPlan.descripcion }
                            onChange={ (e) => onFormChange( e )}
                        ></textarea>
                    </div>
                    {
                        true &&
                        <div className='plan-permisos'>
                            <h3>Funcionalidades específicas</h3>
                            <div className='permisos-row'>
                                <PermisoItem
                                  propiedad={ 'profesionales' }
                                  nombre={ 'Profesionales'}
                                  valor={ objPlan.profesionales }
                                  toggleFunctionality={ toggleFunctionality }
                                />
                                <PermisoItem
                                  propiedad={ 'idiomas' }
                                  nombre={ 'Idiomas'}
                                  valor={ objPlan._idiomas }
                                  toggleFunctionality={ toggleFunctionality }
                                />
                                <PermisoItem
                                  propiedad={ 'promociones' }
                                  nombre={ 'Promociones'}
                                  valor={ objPlan.promociones }
                                  toggleFunctionality={ toggleFunctionality }
                                />
                                <PermisoItem
                                  propiedad={ 'recordatorios' }
                                  nombre={ 'Recordatorios'}
                                  valor={ objPlan.recordatorios }
                                  toggleFunctionality={ toggleFunctionality }
                                />
                                <PermisoItem
                                  propiedad={ 'listasEspera' }
                                  nombre={ 'Listas Espera'}
                                  valor={ objPlan.listasEspera }
                                  toggleFunctionality={ toggleFunctionality }
                                />
                                <PermisoItem
                                  propiedad={ 'valoraciones' }
                                  nombre={ 'Valoraciones'}
                                  valor={ objPlan.valoraciones }
                                  toggleFunctionality={ toggleFunctionality }
                                />
                                <PermisoItem
                                  propiedad={ 'gamaColores' }
                                  nombre={ 'Gama Colores'}
                                  valor={ objPlan.gamaColores }
                                  toggleFunctionality={ toggleFunctionality }
                                />
                                <PermisoItem
                                  propiedad={ 'tipografias' }
                                  nombre={ 'Tipografias'}
                                  valor={ objPlan.tipografias }
                                  toggleFunctionality={ toggleFunctionality }
                                />
                                <PermisoItem
                                  propiedad={ 'iconoApp' }
                                  nombre={ 'Icono App'}
                                  valor={ objPlan.iconoApp }
                                  toggleFunctionality={ toggleFunctionality }
                                />
                                <PermisoItem
                                  propiedad={ 'banners' }
                                  nombre={ 'Banners'}
                                  valor={ objPlan.banners }
                                  toggleFunctionality={ toggleFunctionality }
                                />
                                <PermisoItem
                                  propiedad={ 'formacion' }
                                  nombre={ 'Formación'}
                                  valor={ objPlan.formacion }
                                  toggleFunctionality={ toggleFunctionality }
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
    <ModalEliminarPlan
        data = { dataModalEliminar }
        setModalResponse = { deletePlanResponse }
        modalOpened = { modalEliminarPlanOpened }
        setModalOpened = { setModalEliminarPlanOpened }
    />
    </>
  )
}
