import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { DeleteIcon } from '../../../../assets/svg/iconos';
import { getDia } from '../../../profesionales/components/horario/helpersHorario';
import { HorarioDescuentoInput } from '../descuentos/HorarioDescuentoInput';

const timezone = process.env.REACT_APP_TIMEZONE;


export const SelectHorarioEspecial = ({ objPromocion, changeHoras, editMode, deleteHorario, addHorario, showHorarioEspecial }) => {
    const { colores } = useSelector( state => state.ui );

    const newHorario = ( day ) => {
        //console.log( day );
        const currentHoraInicio = moment().tz(timezone).year(2021).month(2).date(19).hours(8).minutes(0).seconds(0).milliseconds(0).toISOString();
        const currentHoraFin = moment().tz(timezone).year(2021).month(2).date(19).hours(20).minutes(0).seconds(0).milliseconds(0).toISOString();
        addHorario(
            {
                diasSemana: day.diasSemana,
                newHoras: [
                    ...day.horas,
                    {
                        horaInicio: currentHoraInicio,
                        horaFin: currentHoraFin,
                    }
                ]
            }
        );
    }

    useEffect(() => {
      console.log( objPromocion?.horariosPromocion );
    }, [objPromocion]);
    

    return (
        <div className='horarios-especial-dialog'>
        <AnimateHeight
            duration = { 500 }
            height={ showHorarioEspecial ? 'auto' : 0 }
        >
            <div className='horarios'>
                <div className='horarios-days-columns-row'>
                    {
                    objPromocion?.horariosPromocion?.map( (day, idxDay) => (
                        <div
                            key={ `154677${idxDay}` }
                            className='horario-day-column'
                        >
                            <div className='horario-column-header'>{ getDia( day.diasSemana ) }</div>
                            {
                                day?.horas.map( (hora, idxHora) => (
                                    <div
                                        className='horario-item'
                                        key={ `174677${idxHora}` }
                                    >
                                        <HorarioDescuentoInput
                                            dia={ day }
                                            hora={ hora }
                                            idx={ idxHora }
                                            idxDay={ idxDay }
                                            idxHora={ idxHora }
                                            changeHoras={ changeHoras }
                                            intMinutos={ 5 }
                                            editMode={ editMode }
                                            newHorario={ false }
                                        />
                                        {
                                            editMode &&
                                            <div 
                                                className='horario-delete'
                                                onClick={ () => deleteHorario( idxDay, idxHora )}
                                            >
                                                <DeleteIcon
                                                    color={ '#ffffff' } 
                                                    background={ colores.color5 } 
                                                />
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                            {
                                editMode &&
                                <div 
                                    className='horario-item empty'
                                    onClick={ () => newHorario( day )}
                                >
                                    <p>+</p>
                                </div>
                            }
                        </div>
                    ))
                    }
                </div>
            </div>
        </AnimateHeight>
        </div>
    )
}
