import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { HorarioSelect } from '../tools/HorarioSelect';

import { startLoadingHorarios } from '../../actions/horarios';

const timezone = process.env.REACT_APP_TIMEZONE;

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const DropdownTime = ( { selectTime, setSelectTime, timeCalendar, setTimeCalendar, selectSucursal, profesionales, setProfesionales, selectProfesional, setProfSelected, fechaCalendar, setFechaCalendar, morning, afternoon, editCita = false, activeModalReserva } ) => {
    const [visibility, setVisibility] = useState(false);
    const {empresa} = useSelector(state => state.auth);
    const dropdownMenuRef = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startLoadingHorarios(empresa));
    }, []);    

    const showDropdown = () => {
        if( selectTime.visible ){
            setVisibility(true);
        }
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

    return (
        <div className="brb-select">
            <div
                className={`brb-select-container ${ (selectTime.visible) ? 'visible' : '' } ${ visibility ? 'active' : '' }`}
                onClick={ () => visibility ? hideDropdown() : showDropdown() }
            >
                <p>{ ( moment(timeCalendar).year() === 1972) ? 'Selecciona la hora' : moment.tz(timeCalendar, timezone).format("HH:mm") }</p>
                <div className="right-zone">
                    <p></p>
                    <span 
                        className="select-arrow select-arrow-down"
                    ></span>
                </div>
            </div>
            <div ref={dropdownMenuRef} className={`brb-select-options select-time ${ visibility ? 'active' : '' }`}>
                <HorarioSelect
                    activeModalReserva={activeModalReserva}
                    selectSucursal={selectSucursal}
                    profesionales={profesionales}
                    setProfesionales={setProfesionales}
                    selectProfesional={selectProfesional}
                    setProfSelected={setProfSelected}
                    fechaCalendar={fechaCalendar}
                    timeCalendar={timeCalendar}
                    setTimeCalendar={setTimeCalendar}
                    selectTime={ selectTime }
                    setSelectTime={ setSelectTime }
                    morning={morning}
                    afternoon={afternoon}
                    editCita = { editCita }
                />
            </div>
        </div>
    )
}
