import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModalBottom } from '../../../actions/ui';
import { AddPermisoSelect } from './permisos/AddPermisoSelect';
import { AddSeccionesSelect } from './permisos/AddSeccionesSelect';

const secciones = [
    { key: 'reservas', text: 'Reservas' },
    { key: 'profesionales', text: 'Profesionales' },
    { key: 'clientes', text: 'Clientes' },
    { key: 'punto', text: 'Punto de venta' },
    { key: 'precios', text: 'Precios especiales' },
    { key: 'notificaciones', text: 'Notificaciones' },
    { key: 'locales', text: 'Locales' },
    { key: 'configuracion', text: 'Configuración' },
    { key: 'admin', text: 'Admin' },
];

export const Permisos = ({ objProfesional, setObjProfesional, editMode }) => {

    const dispatch = useDispatch();
    const { colores } = useSelector( state => state.ui );

    const updatePermisos = ( permiso ) => {
        if( editMode ){
            let newPermisos = [];
    
            if( !objProfesional.permisos.includes( permiso.key ) ) {
                newPermisos = [permiso.key];
            }
            const newProfesional = { ...objProfesional };
            newProfesional.permisos = newPermisos;
            setObjProfesional( newProfesional );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }
    
    const updateSecciones = ( seccion ) => {
        if( editMode ){
            let newSecciones = [];

            if( seccion.key === 'todas' ){
                if( !checkAllSeccionesSelected() ){
                    secciones.forEach(item => {
                        newSecciones = [ ...newSecciones, item.key ];
                    });
                }
            }
    
            if( seccion.key !== 'todas' ){
                if( !objProfesional.secciones.includes( seccion.key ) ) {
                    newSecciones = [ ...objProfesional.secciones, seccion.key];
                } else {
                    newSecciones = objProfesional.secciones.filter( item => item !== seccion.key );
                }
            }

            const newProfesional = { ...objProfesional };
            newProfesional.secciones = newSecciones;
            setObjProfesional( newProfesional );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    const checkAllSeccionesSelected = () => {
        let allSelected = true;
        secciones.forEach(seccion => {
            if( seccion.key !== 'todas' ){
                if(!objProfesional.secciones?.includes(seccion.key)){ allSelected = false; }
            }
        });
        return allSelected;
    }

    return (
        <div className='permisos'>
            <div
                className='add-permiso'
                style={{ backgroundColor: colores.colorSecundario }}
            >
                <AddPermisoSelect
                    objProfesional={objProfesional}
                    updatePermisos={updatePermisos}
                />
                <AddSeccionesSelect
                    secciones={ secciones }
                    objProfesional={objProfesional}
                    updateSecciones={updateSecciones}
                    checkAllSeccionesSelected={ checkAllSeccionesSelected }
                />
            </div>
        </div>
    )
}
