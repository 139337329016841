import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { promocionStartDelete } from '../../../actions/promociones';
import { YlmModalTwoButtons } from '../../../components/main/YlmModalTwoButtons';
import { DescuentoCard } from './descuentos/DescuentoCard';

export const Descuentos = ({ history, descuentosList}) => {

    const dispatch = useDispatch();
    const { colores } = useSelector(state => state.ui);
    const { empresa } = useSelector(state => state.auth);

    const [dataModal, setDataModal] = useState({
        title: '¿Eliminar descuento?',
        text: ['¿Estás seguro de que deseas eliminar el descuento?'],
        buttonText: 'Estoy seguro',
        button2Text: 'Cancelar'
    });
    const [modalTwoButtonsOpened, setModalTwoButtonsOpened] = useState(false);
    const [modalEliminarDescuentoResponse, setModalEliminarDescuentoResponse] = useState(100);
    const [modalEliminarDescuentoWaiting, setModalEliminarDescuentoWaiting] = useState({});

    useEffect(() => {
        if( modalEliminarDescuentoWaiting._id ){
            if( modalEliminarDescuentoResponse === 1 ){
                dispatch( promocionStartDelete( modalEliminarDescuentoWaiting._id, empresa ) );
            }
            setModalEliminarDescuentoWaiting({});
            setModalEliminarDescuentoResponse( 100 );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalEliminarDescuentoResponse]);    
    
    const editDescuento = ( descuento ) => {
        history.push({
            pathname: `/descuento/${descuento._id}`,
            state: {
                descuento
            }
        });
    };

    const deleteDescuento = ( descuento ) => {
        // console.log( 'deleteDescuento', descuento );
        setDataModal({
            title: '¿Eliminar descuento?',
            text: ['¿Estás seguro de que deseas eliminar el descuento?'],
            buttonText: 'Estoy seguro',
            button2Text: 'Cancelar'
        })
        setModalTwoButtonsOpened( true );
        setModalEliminarDescuentoWaiting( descuento );
        
    }

    return (
        <div 
            className='descuentos'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className='descuentos-grid'>
                {
                    descuentosList.map( descuento => (
                        !descuento.deleted &&
                        <DescuentoCard
                            key={ descuento._id }
                            descuento={ descuento }
                            editDescuento={ editDescuento }
                            deleteDescuento={ deleteDescuento }
                        />
                    ))
                }
            </div>
            <YlmModalTwoButtons
                data = { dataModal }
                setModalResponse = { setModalEliminarDescuentoResponse }
                modalTwoButtonsOpened = { modalTwoButtonsOpened }
                setModalTwoButtonsOpened = { setModalTwoButtonsOpened }
            />
        </div>
    )
}
