import React from 'react';
import { useSelector } from 'react-redux';

const initialData = {
    title: 'Título',
    text: ['Contenido 1', 'Contenido 2'],
    buttonText: 'Cambiar',
    button2Text: 'Cancelar',
}

export const YlmModalTwoButtons = ( { data = initialData, setModalResponse, modalTwoButtonsOpened, setModalTwoButtonsOpened } ) => {
    const { colores } = useSelector(state => state.ui);

    const clickCloseModal = ( fromButton = false ) => {
        if( fromButton ) {
            setModalResponse(1);
        } else {
            setModalResponse(0);
        }
        setModalTwoButtonsOpened( false );
    };

    return (
        <div className={`modal-ylm ${(modalTwoButtonsOpened) ? 'active' : ''}`}>
            <div className="modal-bg"></div>
            <div className="modal-wrapper">
                <div className="modal-title">
                    <h3 style={{ color: colores.colorPrincipal }}>{ data.title }</h3>
                </div>
                <div className="modal-body">
                    {
                        data.text.map( (line, idx) => (
                            <p key={ idx }>{ line }</p>
                        ))
                    }
                </div>
                <div className="modal-footer">
                    <div className="buttons">
                        <div 
                            className="btn btn-confirm"
                            style={{ backgroundColor: colores.colorPrincipal }}
                            onClick={ () => clickCloseModal( true ) }
                        >{ data.buttonText }</div>
                        <div 
                            className="btn btn-cancel"
                            style={{ backgroundColor: '#F2F2F2', color: '#393D40' }}
                            onClick={ () => clickCloseModal() }
                        >{ data.button2Text }</div>
                    </div>
                </div>
                <div
                    className="modal-close"
                    onClick={ () => clickCloseModal() }
                ><span></span></div>
            </div>
        </div>
    )
}
