import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { empresaLogout, empresaStartDelete, startLoadingEmpresas, startSearchEmpresas } from '../../actions/empresas';
import { openModalBottom, setMenuLateral } from '../../actions/ui';

import { AddIcon, ArrowDownSmallIcon, BuscarIcon, CloseIcon } from '../../assets/svg/iconos';
import { EmpresaRowItem } from './components/EmpresaRowItem';
import { ModalEliminarEmpresa } from './components/modales/ModalEliminarEmpresa';

export const Empresas = ({history}) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { empresas, total: totalEmpresas } = useSelector(state => state.empresas);
    const { colores } = useSelector(state => state.ui);

    const [initialSearch, setInitialSearch] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [empresasList, setEmpresasList] = useState([]);
    const [ , setCurrentScreen] = useState('main');
    const [pagNumber, setPagNumber] = useState(1);

    const [isLoading, setIsLoading] = useState(false);

    const [modalEliminarClienteOpened, setModalEliminarClienteOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar empresa?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [selectedId, setSelectedId] = useState(''); 
    const myTimer = useRef(null);

    const [orderBy, setOrderBy] = useState('nombre');
    const [asc, setAsc] = useState(true);
    const [ascNombre, setAscNombre] = useState(true);
    const [ascTelefono, setAscTelefono] = useState(true);
    const [ascEmail, setAscEmail] = useState(true);
    const [ascSector, setAscSector] = useState(true);
    const [ascPlan, setAscPlan] = useState(true);

    const LIMITE_EMPRESAS = 10;

    useEffect(() => {
        //dispatch( startLoadingEmpresas( pagNumber | 1, LIMITE_EMPRESAS, orderBy, asc ) );
        setInitialSearch( true );
        dispatch( setMenuLateral('empresas') );

        return () => {
            dispatch( empresaLogout() );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    

    useEffect(() => {
        // console.log( 'empresas', empresas );
        // console.log( 'empresasList', empresasList );
        function startTimer() {
            myTimer.current = setTimeout( () => {
                if( isLoading ){
                    dispatch( openModalBottom({text: ['No se han encontrado empresas o ha expirado el tiempo de espera'], timeOut: 4}) );
                }
                setIsLoading( false );
            }, (3000));
        }

        startTimer();
        
        return () => {
            setIsLoading( false );
            clearTimeout(myTimer.current);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empresas, empresasList]);

    useEffect(() => {
        procesaEmpresas( empresas, false );
    }, [empresas]);
    

    useEffect( () => {
        if( searchTxt.trim().length < 3 ){
            if( !initialSearch ){
                setPagNumber( 1 );
                dispatch( startLoadingEmpresas( 1, LIMITE_EMPRESAS, orderBy, asc ) );
            }
        } else {
            dispatch( startSearchEmpresas( searchTxt.trim(), pagNumber | 1, LIMITE_EMPRESAS, orderBy, asc ) );
            setInitialSearch( false );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTxt]);    

    const paginacion = ( valor ) => {
        if( searchTxt.trim().length < 3 ){
            if( valor === -1000 ) {
                setPagNumber( 1 );
                dispatch( startLoadingEmpresas( 1, LIMITE_EMPRESAS, orderBy, asc ) );
            } else if( valor === 1 ){
                setPagNumber( pagNumber + 1 );
                dispatch( startLoadingEmpresas( pagNumber + 1, LIMITE_EMPRESAS, orderBy, asc ) );
            } else if( valor === 1000000000 ){
                setPagNumber( Math.ceil( totalEmpresas / LIMITE_EMPRESAS) );
                dispatch( startLoadingEmpresas( Math.ceil( totalEmpresas / LIMITE_EMPRESAS), LIMITE_EMPRESAS, orderBy, asc ) );
            } else {
                if( pagNumber <= 1 ) return;
                setPagNumber( pagNumber - 1 );
                dispatch( startLoadingEmpresas( pagNumber - 1, LIMITE_EMPRESAS, orderBy, asc ) );
            }
        } else {
            if( valor === -1000 ) {
                setPagNumber( 1 );
                dispatch( startSearchEmpresas( searchTxt.trim(), 1, LIMITE_EMPRESAS, orderBy, asc ) );
            } else if( valor === 1 ){
                setPagNumber( pagNumber + 1 );
                dispatch( startSearchEmpresas( searchTxt.trim(), pagNumber + 1, LIMITE_EMPRESAS, orderBy, asc ) );
            } else if( valor === 1000000000 ){
                setPagNumber( Math.ceil( totalEmpresas / LIMITE_EMPRESAS) );
                dispatch( startSearchEmpresas( searchTxt.trim(), Math.ceil( totalEmpresas / LIMITE_EMPRESAS), LIMITE_EMPRESAS, orderBy, asc ) );
            } else {
                if( pagNumber <= 1 ) return;
                setPagNumber( pagNumber - 1 );
                dispatch( startSearchEmpresas( searchTxt.trim(), pagNumber - 1, LIMITE_EMPRESAS, orderBy, asc ) );
            }
        }
    }

    const procesaEmpresas = ( empresas, filtro = true ) => {
        let newEmpresas = [];
        //console.log( 'procesaEmpresas', empresas );
        //console.log( 'totalEmpresas', totalEmpresas );
        let contador = 0;
        empresas.forEach( empresa => {
            if( !empresa.deleted ){
                newEmpresas = [ ...newEmpresas, empresa ];
                contador++;
            }
            if( filtro ){
                if( contador > 14 ){
                    return;
                }
            }
        });
        console.log( 'newEmpresas', newEmpresas );
        setEmpresasList( newEmpresas );
    }

    const changeOrderItem = ( item ) => {
        let newAsc = true;
        const mismaColumna = orderBy === item;
        if( mismaColumna ){
        switch (item) {
            case 'nombre':
                newAsc = !ascNombre;
                setAscNombre( !ascNombre );
                break;
            case 'telefono':
                newAsc = !ascTelefono;
                setAscTelefono( !ascTelefono );
                break;
            case 'email':
                newAsc = !ascEmail;
                setAscEmail( !ascEmail );
                break;
            case 'sector':
                newAsc = !ascSector;
                setAscSector( !ascSector );
                break;
            case 'plan':
                newAsc = !ascPlan;
                setAscPlan( !ascPlan );
                break;
            default:
                break;
        }
        }
        if( !mismaColumna ){
            setOrderBy( item );
        }
        setAsc( newAsc );
        resetAscs( item );
        
        dispatch( startLoadingEmpresas( pagNumber | 1, LIMITE_EMPRESAS, item, newAsc ) );
        setInitialSearch( true );
    }

    const resetAscs = ( value = 'nombre' ) => {
        if (value !== 'nombre'){ setAscNombre( true ) };
        if (value !== 'telefono'){ setAscTelefono( true ) };
        if (value !== 'email'){ setAscEmail( true ) };
        if (value !== 'sector'){ setAscSector( true ) };
        if (value !== 'plan'){ setAscPlan( true ) };
    }

    const clickSearch = () => {
        if( searchActive ){
            setSearchTxt('');
        }
        setSearchActive( !searchActive );
    };

    const clickDeleteSearch = () => {
        setSearchTxt('');
    }

    const handleSearchChange = (e) => {
        setSearchTxt( e.target.value );
    }

    const clickAdd = () => {
        setCurrentScreen('new');
        history.push({
            pathname: `/empresa/new`
        });
    };
    
    const editCliente = ( empresa ) => {
        history.push({
            pathname: `/empresa/${empresa._id}`,
            state: {
                empresa
            }
        });
    };

    const deleteClienteModal = ( profId, name ) => {
        setSelectedId( profId );
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar a ${name}?`]
        })
        setModalEliminarClienteOpened( true );
    };

    const deleteClienteResponse = ( value ) => {
        if( value === 1 && selectedId !== '' ){
            // console.log( 'Borrar id:', selectedId );
            dispatch( empresaStartDelete( selectedId, empresa ) );
        }
    }

    return (
        <>
        <div 
            className='empresas'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className="preheader">
                <div className="title">
                    <h1 style={{color: colores.colorPrincipal}}>Empresas</h1>
                </div>
                <div className="botonera">
                    <div 
                        className={`btn-search ${searchActive && 'active'}`}
                    >
                        <div className='search-input'>
                            <input 
                                type={'text'}
                                placeholder={'Buscar por nombre'}
                                value={ searchTxt }
                                onChange={ handleSearchChange }
                            />
                            {
                                searchTxt.trim().length > 2 &&
                                <span className='cantidad'>
                                    ({ totalEmpresas })
                                </span>
                            }
                        </div>
                        {
                            searchTxt.trim().length < 1 &&
                            <div
                                className='icono'
                                onClick={ clickSearch }
                            >
                                <BuscarIcon
                                    color={ colores.colorPrincipal }
                                    className="icon-inverse"
                                />
                            </div>
                        }
                        {
                            searchTxt.trim().length >= 1 &&
                            <div
                                className='icono'
                                onClick={ clickDeleteSearch }
                            >
                                <CloseIcon
                                    color={ colores.colorPrincipal }
                                    className="icon-inverse"
                                />
                            </div>
                        }
                    </div>
                    <div 
                        className="btn-add"
                        style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                        onClick={ clickAdd }
                    >
                        <div className='icono'>
                            <AddIcon
                                color={ colores.colorPrincipal }
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="table-empresas">
                <div className='table-row headers-row'>
                    <div 
                        className='column name'
                        onClick={ () => changeOrderItem('nombre') }
                    >
                        <p>Cliente</p>
                        {
                            (orderBy === 'nombre') &&
                            <span
                                className={`${!ascNombre && 'desc'}`}
                            ><ArrowDownSmallIcon /></span>
                        }
                    </div>
                    <div 
                        className='column phone'
                        onClick={ () => changeOrderItem('telefono') }
                    >
                        <p>Teléfono</p>
                        {
                            (orderBy === 'telefono') &&
                            <span
                                className={`${!ascTelefono && 'desc'}`}
                            ><ArrowDownSmallIcon /></span>
                        }
                    </div>
                    <div 
                        className='column email'
                        onClick={ () => changeOrderItem('email') }
                    >
                        <p>E-mail</p>
                        {
                            (orderBy === 'email') &&
                            <span
                                className={`${!ascEmail && 'desc'}`}
                            ><ArrowDownSmallIcon /></span>
                        }
                    </div>
                    <div 
                        className='column sector'
                        onClick={ () => changeOrderItem('sector') }
                    >
                        <p>Sector</p>
                        {
                            (orderBy === 'sector') &&
                            <span
                                className={`${!ascSector && 'desc'}`}
                            ><ArrowDownSmallIcon /></span>
                        }
                    </div>
                    <div 
                        className='column plan'
                        onClick={ () => changeOrderItem('plan') }
                    >
                        <p>Plan</p>
                        {
                            (orderBy === 'plan') &&
                            <span
                                className={`${!ascPlan && 'desc'}`}
                            ><ArrowDownSmallIcon /></span>
                        }
                    </div>
                </div>
                <div className='table-scrollable'>
                {
                    !isLoading &&
                    empresasList.map( empresa => (
                        <EmpresaRowItem
                            key={ empresa._id }
                            empresa={ empresa }
                            editCliente={editCliente}
                            deleteCliente={deleteClienteModal}
                        />
                    ))
                }
                {
                    searchTxt.length > 0 && empresasList.length === 0 &&
                    <h3>No hay clientes que cumplan con el criterio de búsqueda</h3>
                }
                </div>
            </div>
            {
                ( totalEmpresas > LIMITE_EMPRESAS ) &&
                <div className='paginacion'>
                    <div 
                        className='pag-btn'
                        style={{ 
                            backgroundColor: colores.color6, 
                            color: colores.colorPrincipal,
                            opacity: pagNumber > 1 ? 1 : 0
                        }}
                        onClick={ () => paginacion(-1000) }
                    >{ '<<' }</div>
                    <div 
                        className='pag-btn'
                        style={{ 
                            backgroundColor: colores.color6,
                            color: colores.colorPrincipal,
                            opacity: pagNumber > 1 ? 1 : 0
                        }}
                        onClick={ () => paginacion(-1) }
                    >{ '<' }</div>
                    <div className='pag-number'>
                        Página: { pagNumber }
                    </div>
                    <div 
                        className='pag-btn'
                        onClick={ () => paginacion(1) }
                        style={{ 
                            backgroundColor: colores.color6,
                            color: colores.colorPrincipal,
                            opacity: pagNumber < Math.ceil( totalEmpresas / LIMITE_EMPRESAS) ? 1 : 0
                        }}
                    >{ '>' }</div>
                    <div 
                        className='pag-btn'
                        onClick={ () => paginacion(1000000000) }
                        style={{ 
                            backgroundColor: colores.color6,
                            color: colores.colorPrincipal,
                            opacity: pagNumber < Math.ceil( totalEmpresas / LIMITE_EMPRESAS) ? 1 : 0
                        }}
                    >{ '>>' }</div>
                </div>
            }
            {
                isLoading &&
                <div 
                    className='loading-clientes'
                >
                    <div className='bg-loading'></div>   
                    <p
                        style={{ color: colores.colorPrincipal }}
                    >Cargando...</p>
                </div>
            }
        </div>
        <ModalEliminarEmpresa
            data = { dataModalEliminar }
            setModalResponse = { deleteClienteResponse }
            modalOpened = { modalEliminarClienteOpened }
            setModalOpened = { setModalEliminarClienteOpened }
        />
        </>
    )
}
