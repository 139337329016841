import React, { useEffect, useRef, useState } from 'react';
import { DotsIcon } from '../../../../assets/svg/iconos';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const VarianteRowSelect = ({ varianteId, clickEdit, clickDelete }) => {

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const hideDropdown = () => {
        setVisibility(false);
    }

    const toggleDropdown = (e, open) => {
        e.stopPropagation();
        setVisibility(open);
    }

    const selectItem = ( cmd ) => {
        hideDropdown();
        if( cmd === 'edit' ){
            clickEdit( varianteId );
        }
        if( cmd === 'delete' ){
            clickDelete( varianteId )
        }
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );
  return (
    <div 
        className="btn-menu"
        onClick={ (e) => (visibility) ? toggleDropdown( e, false) : toggleDropdown( e, true) }
    >
                <span><DotsIcon /></span>
        <ul 
            ref={ dropdownMenuRef }
            className={ `submenu ${ visibility ? 'active' : '' }` }
        >
            {/* <li
                onClick={ () => selectItem('edit')}
            ><span></span>Editar</li> */}
            <li
                onClick={ () => selectItem('delete')}
            ><span></span>Eliminar</li>
        </ul>
    </div>
  )
}
