export const RemoveImage = ({ objEmpresa, setObjEmpresa, keyToRemove, colorPrincipal, children }) => {
  return <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "start",
    }}
  >
    <span
      onClick={(e => {
        const updatedObjEmpresa = { ...objEmpresa };
        updatedObjEmpresa[keyToRemove] = null;

        setObjEmpresa({
          ...updatedObjEmpresa
        });
      })}
      style={{
        cursor: 'pointer',
        position: 'absolute',
        fontSize: "smaller",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14px',
        height: '14px',
        zIndex: 99,
        color: "white",
        backgroundColor: colorPrincipal,
      }}
    >X</span>
    {children}
  </div>
}