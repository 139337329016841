
import { types } from "../types/types";

const initialState = {
    textLegal: {},
}

export const textLegalReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.textLegalNew:
            return {
                ...state,
                textLegal: action.payload
            }
        
        case types.textLegalLoad:
            return {
                ...state,
                textLegal: action.payload
            }
        
        case types.textLegalUpdated:
            return {
                ...state,
                textLegal: action.payload
            }

        default:
            return state;
    }
}

