import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { openModalBottom } from '../../actions/ui';
import useClickOutside from '../../hooks/useClickOutside';

export const DiaSemanaSelect = ({editMode = false}) => {

    const dispatch = useDispatch();

    const diasSemana = [
        { value: 1, short: 'LU', name: 'Lunes' },
        { value: 2, short: 'MA', name: 'Martes' },
        { value: 3, short: 'MI', name: 'Miércoles' },
        { value: 4, short: 'JU', name: 'Jueves' },
        { value: 5, short: 'VI', name: 'Viernes' },
        { value: 6, short: 'SA', name: 'Sábado' },
        { value: 7, short: 'DO', name: 'Domingo' },
    ];

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const [selectedValue, setSelectedValue] = useState('LU');

    useEffect(() => {
      console.log( isOpen );
    }, [isOpen])
    

    const dayClick = ( value ) => {
        diasSemana.forEach(dia => {
            if( dia.value === value ) {
                setSelectedValue( dia.short );
            }
        });
    }

    const clickSelect = () => {
        if( editMode ){
            toggle( !isOpen );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    return (
        <div
            className='dia-semana-select'
            onClick={ clickSelect }
        >
            <p>{ selectedValue }</p>
            {
                isOpen && (
                    <ul
                        className='dia-semana-options' ref={popover}
                    >
                        {
                            diasSemana.map( dia => (
                                <li 
                                    key={ dia.value }
                                    onClick={ () => dayClick( dia.value )}
                                >{ dia.name }</li>
                            ))
                        }
                    </ul>
                )
            }
        </div>
    )
}
