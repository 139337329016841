import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

const timezone = process.env.REACT_APP_TIMEZONE;

const useOutsideClick = (ref, callback, when) => {
    const savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const HorarioProfesionalInput = ({ dia, hora, idx, idxDay, idxHora, changeHoras, intMinutos = 5, editMode = false, newHorario, sucursal, horasOcupadas = [] }) => {
    const { colores } = useSelector(state => state.ui);

    const [horaInicioText, setHoraInicioText] = useState(9);
    const [minutosInicioText, setMinutosInicioText] = useState(0);

    const [horaFinText, setHoraFinText] = useState(9);
    const [minutosFinText, setMinutosFinText] = useState(0);

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const showDropdown = () => {
        setVisibility(true);
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    useOutsideClick(dropdownMenuRef, hideDropdown, visibility);

    useEffect(() => {
        updateStateHoras();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateStateHoras();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hora, horaInicioText, minutosInicioText, horaFinText, minutosFinText]);


    const [isPressed, setIsPressed] = useState({
        event: "",
        pressing: false
    });

    const handleMouseDown = (event, func) => {
        setIsPressed({
            event,
            func,
            pressing: true

        });
    };

    const handleMouseUp = () => {
        setIsPressed({
            pressing: false
        });
    };

    useLayoutEffect(() => {
        if (isPressed.pressing) {
            const interval = setInterval(() => {
                console.log("a")
                clickSelector(isPressed.event, isPressed.func);
            }, 80);

            return () => clearInterval(interval);
        }
    }, [isPressed, horaInicioText, minutosInicioText, horaFinText, minutosFinText]);


    const updateStateHoras = () => {
        const currentHoraInicio = moment().tz(timezone).hours(8).minutes(0);
        const currentHoraFin = moment().tz(timezone).hours(20).minutes(0);
        let inicioHoras = parseInt(currentHoraInicio.format('HH'));
        let inicioMinutos = parseInt(currentHoraInicio.format('mm'));
        let finHoras = parseInt(currentHoraFin.format('HH'));
        let finMinutos = parseInt(currentHoraFin.format('mm'));

        if (hora?.horaInicio) {
            inicioHoras = parseInt(moment.tz(hora.horaInicio, timezone).format('HH'));
            inicioMinutos = parseInt(moment.tz(hora.horaInicio, timezone).format('mm'));
            finHoras = parseInt(moment.tz(hora.horaFin, timezone).format('HH'));
            finMinutos = parseInt(moment.tz(hora.horaFin, timezone).format('mm'));
        }
        setHoraInicioText(inicioHoras);
        setMinutosInicioText(inicioMinutos);
        setHoraFinText(parseInt(finHoras));
        setMinutosFinText(finMinutos);
    }

    const clickSelector = (selector, operation) => {
        let newHoraInicio = parseInt(horaInicioText);
        let newMinutosInicio = parseInt(minutosInicioText);
        let newHoraFin = parseInt(horaFinText);
        let newMinutosFin = parseInt(minutosFinText);

        if (selector === 'hours-inicio') {
            if (operation === 'add') {
                if (newHoraInicio < 23) {
                    newHoraInicio++;
                } else {
                    newHoraInicio = 0;
                }
            } else {
                if (newHoraInicio > 0) {
                    newHoraInicio--;
                } else {
                    newHoraInicio = 23;
                }
            }
        }
        if (selector === 'minutes-inicio') {
            if (operation === 'add') {
                if (newMinutosInicio < (60 - intMinutos)) {
                    newMinutosInicio += intMinutos;
                } else {
                    newMinutosInicio = 0;
                }
            } else {
                if (newMinutosInicio > 0) {
                    newMinutosInicio -= intMinutos;
                } else {
                    newMinutosInicio = 60 - intMinutos;
                }
            }
        }
        if (selector === 'hours-fin') {
            if (operation === 'add') {
                if (newHoraFin < 23) {
                    newHoraFin++;
                } else {
                    newHoraFin = 0;
                }
            } else {
                if (newHoraFin > 0) {
                    newHoraFin--;
                } else {
                    newHoraFin = 23;
                }
            }
        }
        if (selector === 'minutes-fin') {
            if (operation === 'add') {
                if (newMinutosFin < (60 - intMinutos)) {
                    newMinutosFin += intMinutos;
                } else {
                    newMinutosFin = 0;
                }
            } else {
                if (newMinutosFin > 0) {
                    newMinutosFin -= intMinutos;
                } else {
                    newMinutosFin = 60 - intMinutos;
                }
            }
        }

        if (newHoraInicio > newHoraFin) { newHoraFin = newHoraInicio };
        if (newHoraInicio === newHoraFin) {
            if (newMinutosInicio > newMinutosFin) { newMinutosFin = newMinutosInicio };
        }

        setHoraInicioText(`${newHoraInicio}`);
        setHoraFinText(`${newHoraFin}`);
        if (newHoraInicio === newHoraFin) {
            setMinutosInicioText(`${newMinutosInicio}`);
            setMinutosFinText(`${newMinutosFin}`);
        }

        changeHoras(
            {
                sucursal,
                idx,
                idxDay,
                idxHora,
                newHoras: [
                    `${newHoraInicio < 10 ? `0${newHoraInicio}` : newHoraInicio}:${newMinutosInicio < 10 ? `0${newMinutosInicio}` : newMinutosInicio}`,
                    `${newHoraFin < 10 ? `0${newHoraFin}` : newHoraFin}:${newMinutosFin < 10 ? `0${newMinutosFin}` : newMinutosFin}`
                ]
            }
        );
    }

    return (
        <div
            className='doble-hora-input'
        >
            <div
                className={`horario-label ${visibility ? 'active' : ''}`}
                onClick={() => (visibility) ? hideDropdown() : editMode && showDropdown()}
                style={{ backgroundColor: !newHorario ? '#B9FFC4' : 'transparent' }}
            >
                {
                    newHorario
                        ?
                        <p>+</p>
                        :
                        <p>{horaInicioText}:{minutosInicioText < 10 ? `0${minutosInicioText}` : minutosInicioText}h - {horaFinText}:{minutosFinText < 10 ? `0${minutosFinText}` : minutosFinText}h</p>
                }
            </div>
            <div
                ref={dropdownMenuRef}
                className={`selector-wrapper ${visibility ? 'active' : ''}`}
            >
                <div
                    className='selectors'
                    style={{ color: colores.colorPrincipal, borderColor: colores.colorPrincipal }}
                >
                    <div className='selector'>
                        <div
                            className='btn btn-substract'
                            onClick={() => clickSelector('hours-inicio', 'substract')}
                            onMouseDown={() => handleMouseDown('hours-inicio', "substract")}
                            onMouseUp={handleMouseUp}
                            onTouchStart={() => handleMouseDown('hours-inicio', "substract")}
                            onTouchCancel={handleMouseUp}
                        >
                            -
                        </div>
                        <div className='selector-value'>
                            {horaInicioText}
                        </div>
                        <div
                            className='btn btn-add'
                            onClick={() => clickSelector('hours-inicio', 'add')}
                            onMouseDown={() => handleMouseDown('hours-inicio', "add")}
                            onMouseUp={handleMouseUp}
                            onTouchStart={() => handleMouseDown('hours-inicio', "add")}
                            onTouchCancel={handleMouseUp}
                        >
                            +
                        </div>
                    </div>
                    <div className='separator'>:</div>
                    <div className='selector'>
                        <div
                            className='btn btn-substract'
                            onClick={() => clickSelector('minutes-inicio', 'substract')}
                            onMouseDown={() => handleMouseDown('minutes-inicio', "substract")}
                            onMouseUp={handleMouseUp}
                            onTouchStart={() => handleMouseDown('minutes-inicio', "substract")}
                            onTouchCancel={handleMouseUp}
                        >
                            -
                        </div>
                        <div className='selector-value'>
                            {minutosInicioText < 10 ? `0${minutosInicioText}` : minutosInicioText}
                        </div>
                        <div
                            className='btn btn-add'
                            onClick={() => clickSelector('minutes-inicio', 'add')}
                            onMouseDown={() => handleMouseDown('minutes-inicio', "add")}
                            onMouseUp={handleMouseUp}
                            onTouchStart={() => handleMouseDown('minutes-inicio', "add")}
                            onTouchCancel={handleMouseUp}
                        >
                            +
                        </div>
                    </div>
                </div>
                <div
                    className='selectors'
                    style={{ color: colores.colorPrincipal, borderColor: colores.colorPrincipal }}
                >
                    <div className='selector'>
                        <div
                            className='btn btn-substract'
                            onClick={() => clickSelector('hours-fin', 'substract')}
                            onMouseDown={() => handleMouseDown('hours-fin', "substract")}
                            onMouseUp={handleMouseUp}
                            onTouchStart={() => handleMouseDown('hours-fin', "substract")}
                            onTouchCancel={handleMouseUp}
                        >
                            -
                        </div>
                        <div className='selector-value'>
                            {horaFinText}
                        </div>
                        <div
                            className='btn btn-add'
                            onClick={() => clickSelector('hours-fin', 'add')}
                            onMouseDown={() => handleMouseDown('hours-fin', "add")}
                            onMouseUp={handleMouseUp}
                            onTouchStart={() => handleMouseDown('hours-fin', "add")}
                            onTouchCancel={handleMouseUp}
                        >
                            +
                        </div>
                    </div>
                    <div className='separator'>:</div>
                    <div className='selector'>
                        <div
                            className='btn btn-substract'
                            onClick={() => clickSelector('minutes-fin', 'substract')}
                            onMouseDown={() => handleMouseDown('minutes-fin', "substract")}
                            onMouseUp={handleMouseUp}
                            onTouchStart={() => handleMouseDown('minutes-fin', "substract")}
                            onTouchCancel={handleMouseUp}
                        >
                            -
                        </div>
                        <div className='selector-value'>
                            {minutosFinText < 10 ? `0${minutosFinText}` : minutosFinText}
                        </div>
                        <div
                            className='btn btn-add'
                            onClick={() => clickSelector('minutes-fin', 'add')}
                            onMouseDown={() => handleMouseDown('minutes-fin', "add")}
                            onMouseUp={handleMouseUp}
                            onTouchStart={() => handleMouseDown('minutes-fin', "add")}
                            onTouchCancel={handleMouseUp}
                        >
                            +
                        </div>
                    </div>
                </div>
                {/* <button
                    onClick={ selectItem }
                >Guardar</button> */}
            </div>
        </div >
    )
}
