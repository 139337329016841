import { useCallback, useRef, useState, useEffect } from 'react';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';
import useClickOutside from '../../../../hooks/useClickOutside';

const tiposValor = [
    {
        _id: 1,
        value: 'money',
        text: '€'
    },
    {
        _id: 2,
        value: 'percent',
        text: '%'
    },
];

export const SelectValor = ({objVariante, dataChange, editMode}) => {

    const [selectText, setSelectText] = useState('€');

    const popover = useRef();
    const [isOpen, toggle] = useState(false); 

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    const clickItem = ( tipoValor ) => {
        dataChange('valorType', tipoValor.value );
        setSelectText( tipoValor.text );
        toggle( !isOpen );
    }

    return (
        <div 
            className={`ylm-value-select ${isOpen && 'active'}`}
        >
            <div 
                className='select-main'
                onClick={ clickSelect }
            >
                <p>{ selectText }</p>
                <ChevronDownIcon color={ '#000000' }/>
            </div>
            {
                isOpen &&
                <ul 
                    className='select-options std-ul' 
                    ref={popover}
                >
                    {
                        tiposValor.map( tipoValor => (
                            <li
                                key={ tipoValor._id }
                                className={`std-li ${tipoValor.value === objVariante.valorType ? 'active' : ''}`}
                                onClick={ () => clickItem( tipoValor )}
                            >
                                <div className='checkbox-bg'>
                                    {
                                        tipoValor.value === objVariante.valorType &&
                                        <span className='checkbox-item'></span>
                                    }
                                </div>
                                <p>{ tipoValor.text }</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
