import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { AltaCliente } from '../pages/public/AltaCliente';
import { PublicRoute } from './PublicRoute';

export const PublicRouter = () => {
    return (
        <div className="auth__main">
            <div className="auth__box-container">
                <Switch>
                    <PublicRoute
                        exact
                        path="/public/alta-cliente"
                        component={ AltaCliente }
                    />

                    <Redirect to="/public/alta-cliente" />

                </Switch>
            </div>
        </div>
    )
}
