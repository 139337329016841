import Swal from 'sweetalert2';

import { types } from "../types/types";
import { fetchConToken, archivoConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewSucursal = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'sucursales', { sucursal: values, empresa: values.empresa }, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                const datos = {
                    text: [ `Sucursal ${ values.nombre } creada correctamente` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewSucursal( body.sucursal._id, body.sucursal ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activeSucursal = ( _id, sucursal ) => ({
    type: types.sucursalesActive,
    payload: {
        _id,
        ...sucursal
    }
});

export const unactiveSucursal = () => ({
    type: types.sucursalesUnactive
});

export const addNewSucursal = ( _id, sucursal ) => ({
    type: types.sucursalesAddNew,
    payload: {
        _id,
        ...sucursal
    }
})

export const startLoadingSucursales = ( empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'sucursales/empresa', {empresa}, 'POST' );
            const body = await resp.json();
            dispatch( setSucursales( body.sucursales ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setSucursales = ( sucursales ) => ({
    type: types.sucursalesLoad,
    payload: sucursales
})

export const startSaveSucursal = ( sucursal, swal = true ) => {
    return async( dispatch, getState ) => {
        
        try {
            const resp = await fetchConToken( `sucursales/${ sucursal._id }`, { sucursal, empresa: sucursal.empresa }, 'PUT' );
            const body = await resp.json();
            if ( body.ok ) {
                console.log( body );
                dispatch( refreshSucursal( sucursal._id, body.sucursal ) );
                const datos = {
                    text: [ `Se han guardado los cambios en ${ sucursal.nombre }` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            } else {
                console.log( body );
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshSucursal = ( id, sucursal ) => ({
    type: types.sucursalesUpdated,
    payload: {
        ...sucursal
    }
});

export const startUploading = ( archivo, idSucursal ) => {

    return async ( dispatch, getState ) => {
        try {
            Swal.fire({
                title: 'Subiendo...',
                text: 'Por favor, espere...',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading();
                }
            });

            const formData = new FormData();
            formData.append('archivo', archivo);
            formData.append('file_name', 'archivo');
    
            const resp = await archivoConToken( `sucursales/upload/${ idSucursal }`, formData, 'POST' );
            const body = await resp.json();

            console.log( body );

            Swal.close();
        } catch (error) {
            throw new Error();
        }  

        Swal.close();
    }
};

export const sucursalStartDelete = ( id, empresa ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `sucursales/${ id }`, { empresa }, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                console.log( body, id );
                dispatch( sucursalDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

const sucursalDeleted = ( id ) => ({
    type: types.sucursalesDelete,
    payload: id
});

export const sucursalLogout = () => ({
    type: types.sucursalesLogoutCleaning
});
