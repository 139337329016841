import moment from 'moment';

export const FromToTimeSelector = ({ changeHours, changeMinutes, time, from = true }) => {    

    return (
        
        <div className='time-selector'>
            
            <div className='time-row'>
                <div
                    className='time-item'
                >
                    <div
                        className='time-item-title'
                    >
                        <p>Hora</p>
                    </div>
                    <div className='item-command'>
                        <div 
                            className='cmd-btn substract-btn'
                            onClick={ () => changeHours('substract', from) }
                        >-</div>
                        <div className='item-number'>{ moment(time).format('H') }</div>
                        <div
                            className='cmd-btn add-btn'
                            onClick={ () => changeHours('add', from) }
                        >+</div>
                    </div>
                </div>
                <div
                    className="item-title"
                >{ from ? 'De' : 'A' }</div>
                <div
                    className='time-item'
                >
                    <div
                        className='time-item-title'
                    >
                        <p>Min</p>
                    </div>
                    <div className='item-command'>
                        <div
                            className='cmd-btn substract-btn'
                            onClick={ () => changeMinutes('substract', from) }
                        >-</div>
                        <div className='item-number'>{ moment(time).format('mm') }</div>
                        <div
                            className='cmd-btn add-btn'
                            onClick={ () => changeMinutes('add', from) }
                        >+</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
