import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { TrashIcon } from '../../../../assets/svg/iconos';
import { PeriodSelect } from './PeriodSelect';
import { TypeSelect } from './TypeSelect';

const timezone = process.env.REACT_APP_TIMEZONE;

const diasBtns = [
    { _id: '1', nombre: 'Lunes', abr: 'Lu', active: false },
    { _id: '2', nombre: 'Martes', abr: 'Ma', active: false },
    { _id: '3', nombre: 'Miércoles', abr: 'Mi', active: false },
    { _id: '4', nombre: 'Jueves', abr: 'Ju', active: false },
    { _id: '5', nombre: 'Viernes', abr: 'Vi', active: false },
    { _id: '6', nombre: 'Sábado', abr: 'Sa', active: false },
    { _id: '7', nombre: 'Domingo', abr: 'Do', active: false }
];

export const VacacionalItem = ({ editMode, descanso, descansosTemp, editDescanso, deleteVacaciones }) => {

    const { colores } = useSelector( state => state.ui );

    const [diasSemana, setDiasSemana] = useState(descanso.diasSemana);
    const [tipo, setTipo] = useState(descanso.tipo);

    useEffect(() => {
        // console.log( descanso );
        setDiasSemana( descanso.diasSemana );
    }, [descanso]);

    useEffect(() => {
        saveDiasSemana();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diasSemana]);

    const clickRepeat = (id) => {
        if( editMode ){
            // console.log( id );
            let newArray = [];
            if( diasSemana.includes(id) ){
                newArray = diasSemana.filter( item => item !== id );
            } else {
                newArray = [ ...diasSemana, id ];
            }
            newArray.sort((a, b) => { 
                return a - b; 
            })
            setDiasSemana( newArray );
        } else {
            // dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    const saveChanges = ( horaInicio, horaFin ) => {
        let newDescanso = { 
            ...descanso,
            fechaInicio: moment.tz( horaInicio, timezone ).toISOString(),
            fechaFin: moment.tz( horaFin, timezone ).toISOString(),
        };
        editDescanso( newDescanso );
    }

    const saveDiasSemana = () => {
        let newDescanso = { 
            ...descanso,
            diasSemana
        };
        editDescanso( newDescanso );
    }
    
    const saveTipo = (tipo) => {
        let fechaInicio = descanso.fechaInicio;
        let fechaFin = descanso.fechaFin;
        setTipo(tipo)

        if( tipo === 'ausencia'){
            fechaInicio = moment.tz(descanso.fechaInicio, timezone).hours(0).minutes(0).seconds(0).toISOString();
            fechaFin = moment.tz(descanso.fechaInicio, timezone).hours(23).minutes(59).seconds(0).toISOString();
        }

        const newDescanso = { 
            ...descanso,
            fechaInicio,
            fechaFin,
            tipo
        };
        editDescanso( newDescanso );
    }

    return (
        <div
            style={{ backgroundColor: '#FAFAFA' }}
            className={`vacacional-item`}
        >
            <div className='item-tipology'>
                <div className='item-header'>Tipología</div>
                <div className='item-content'>
                    <TypeSelect
                        tipo={ descanso.tipo }
                        setTipo={ saveTipo }
                        editMode={ editMode }
                    />
                </div>
            </div>
            <div className='item-period'>
                <div className='item-header'>Ausencia</div>
                <div className='item-content'>
                    <PeriodSelect
                        editMode={ editMode }
                        type={ descanso.tipo }
                        descanso = { descanso }
                        descansosTemp = { descansosTemp }
                        editDescanso = { editDescanso }
                        saveChanges = { saveChanges }
                    />
                </div>
            </div>
            <div className='item-repeat'>
                <div className='item-header'>Repetición</div>
                <div className='item-content'>
                    <div className='repeat-selector'>
                        {
                            diasBtns.map( dia => (
                                <div 
                                    key={ `dia${ dia._id }`} 
                                    className={`repeat-item ${ diasSemana.includes(dia._id) ? 'active' : ''}`}
                                    style={{ 
                                        borderColor: colores.colorPrincipal,
                                    }}
                                    onClick={ () => clickRepeat(dia._id)}
                                >
                                    { dia.abr }
                                </div>
                            ))
                        }
                        
                    </div>
                </div>
            </div>
            <div 
                className='item-borrar'
                onClick={ () => deleteVacaciones( descanso ) }
            >
                {
                    descanso._id.substring(0, 3) !== 'new' &&
                    
                        <TrashIcon />
                }
            </div>
        </div>
    )
}
