import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { sucursalStartDelete } from '../../actions/sucursales';
import { setMenuLateral } from '../../actions/ui';
import { AddIcon } from '../../assets/svg/iconos';
import { ModalEliminarSucursal } from './components/modales/ModalEliminarSucursal';
import { SucursalRowItem } from './components/SucursalRowItem';

export const Sucursales = ({history}) => {

    const dispatch = useDispatch();
    const { colores, miniLateral } = useSelector(state => state.ui);
    const { empresa } = useSelector(state => state.auth);
    const { sucursales } = useSelector(state => state.sucursales);
    const { profesionales } = useSelector(state => state.profesionales);

    const [modalEliminarSucursalOpened, setModalEliminarSucursalOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar sucursal?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [selectedId, setSelectedId] = useState('');

    useEffect(() => {
        dispatch( setMenuLateral('sucursales') );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* useEffect(() => {
      console.log( sucursales );
    }, [sucursales]) */
    

    const clickAdd = () => {
        history.push({
            pathname: `/sucursal/new`,
            state: {
                sucursal: 'new'
            }
        });
    };
    
    const editSucursal = ( sucursal ) => {
        history.push({
            pathname: `/sucursal/${sucursal._id}`,
            state: {
                sucursal
            }
        });
    };

    const getProfs = ( idSucursal ) => {
        return profesionales.filter( prof => {
            return prof.sucursales.includes( idSucursal ) && !prof.deleted;
        });
    }

    const deleteSucursalModal = ( sucursalId, name ) => {
        setSelectedId( sucursalId );
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar la sucursal ${ name }?`]
        })
        setModalEliminarSucursalOpened( true );
    };

    const deleteSucursalResponse = ( value ) => {
        if( value === 1 ){
          console.log( 'Borrar id:', selectedId );
          dispatch( sucursalStartDelete( selectedId, empresa ) );
          history.push({ pathname: `/sucursales` })
        }
    }

    return (
        <>
            <div 
                className='sucursales'
                style={{
                    '--colorPrincipal': colores.colorPrincipal,
                    '--colorSecundario': colores.colorSecundario,
                    '--color3': colores.color3,
                    '--color4': colores.color4,
                    '--color5': colores.color5,
                    '--color6': colores.color6,
                    '--color7': colores.color7,
                }}
            >
                <div className={`preheader ${!miniLateral && 'compress'}`}>
                    <div className="title">
                        <h1 style={{color: colores.colorPrincipal}}>Sucursales</h1>
                    </div>
                    <div className="botonera">
                        <div 
                            className="btn-add"
                            onClick={ clickAdd }
                        >
                            <div className='icono'>
                                <AddIcon
                                    color={ colores.colorPrincipal }
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className='table-container'>
                    {
                        sucursales.map( (sucursal, idx) => (
                            !sucursal.deleted &&
                            <SucursalRowItem
                                key={ sucursal._id }
                                index={ idx }
                                sucursal={ sucursal }
                                editSucursal={ editSucursal }
                                deleteSucursal={ deleteSucursalModal }
                                profesionales = { getProfs( sucursal._id ) }
                            />
                        ))
                    }
                </div>
            </div>
        <ModalEliminarSucursal
            data = { dataModalEliminar }
            setModalResponse = { deleteSucursalResponse }
            modalOpened = { modalEliminarSucursalOpened }
            setModalOpened = { setModalEliminarSucursalOpened }
        />
        </>
    )
}
