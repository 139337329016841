import React from 'react';
import { useSelector } from 'react-redux';

export const YlmPromocionSelectCheckbox = ({ promocion, clickFunction }) => {
  const { colores } = useSelector(state => state.ui);

  return (
    <label
        className={`ylm-select-checkbox ${(promocion.selected) ? 'checked' : ''}`}
        onClick={ clickFunction } 
    >{ `${promocion.titulo} - ${(promocion.cifra) ? (promocion.cifra / 100) + ' €' : (promocion.porcentaje / 100) + ' %'}` }
        
        <span 
          className="checkmark"
          style={{ backgroundColor: promocion.selected ? colores.colorPrincipal : colores.color6, borderColor: colores.colorPrincipal }}
        ></span>
    </label>
  )
}
