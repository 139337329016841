import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { closeModalBottom } from '../../actions/ui';

export const YlmModalBottom = () => {

    const dispatch = useDispatch();
    const { modalBottomOpened, modalBottomData } = useSelector(state => state.ui);

    const { text, timeOut } = modalBottomData;

    useEffect(() => {
        if( modalBottomOpened ){
            setTimeout(() => {
                dispatch( closeModalBottom() );
            }, timeOut * 1000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalBottomOpened]);

    return (
        <div className={ `modal-bottom-wrapper ${(modalBottomOpened) ? 'active' : ''}`}>
            <div className="modal-body">
                {
                    text.map( (linea, idx) => (
                        <p key={ idx }>{ linea }</p>
                    ))
                }
            </div>
        </div>
    )
}
