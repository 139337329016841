import Swal from 'sweetalert2';

import { types } from "../types/types";
import { fetchConToken, archivoConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewPromocion = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'promociones', values, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                const datos = {
                    text: [ `Promoción creada` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewPromocion( body.promocion._id, body.promocion ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activePromocion = ( _id, promocion ) => ({
    type: types.promocionesActive,
    payload: {
        _id,
        ...promocion
    }
});

export const unactivePromocion = () => ({
    type: types.promocionesUnactive
});

export const addNewPromocion = ( _id, promocion ) => ({
    type: types.promocionesAddNew,
    payload: {
        _id,
        ...promocion
    }
})

export const startLoadingPromociones = ( empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'promociones/empresa', {empresa}, 'POST' );
            const body = await resp.json();
            dispatch( setPromociones( body.promociones ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingPromocion = ( id, empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `promociones/promocion/${ id }`, {empresa}, 'POST' );
            const body = await resp.json();
            if( body.promocion ){
                dispatch( activePromocion( body.promocion._id, body.promocion ));
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const setPromociones = ( promociones ) => ({
    type: types.promocionesLoad,
    payload: promociones
})

export const startSavePromocion = ( promocion, swal = true ) => {
    return async( dispatch, getState ) => {
        
        try {
            const resp = await fetchConToken( `promociones/${ promocion._id }`, promocion, 'PUT' );
            const body = await resp.json();
            if ( body.ok ) {
                dispatch( refreshPromocion( promocion._id, body.promocion ) );
                const datos = {
                    text: [ `Se han guardado los cambios en la promoción` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            } else {
                console.log( body );
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshPromocion = ( id, promocion ) => ({
    type: types.promocionesUpdated,
    payload: {
        ...promocion
    }
});

export const startUploading = ( archivo, idPromocion ) => {

    return async ( dispatch, getState ) => {
        try {
            Swal.fire({
                title: 'Subiendo...',
                text: 'Por favor, espere...',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading();
                }
            });

            const formData = new FormData();
            formData.append('archivo', archivo);
            formData.append('file_name', 'archivo');
    
            const resp = await archivoConToken( `promociones/upload/${ idPromocion }`, formData, 'POST' );
            const body = await resp.json();

            console.log( body );

            Swal.close();
        } catch (error) {
            throw new Error();
        }  

        Swal.close();
    }
};

export const promocionStartDelete = ( id, empresa ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `promociones/${ id }`, { empresa }, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                console.log( body, id );
                dispatch( promocionDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

const promocionDeleted = ( id ) => ({
    type: types.promocionesDelete,
    payload: id
});

export const promocionLogout = () => ({
    type: types.promocionesLogoutCleaning
});
