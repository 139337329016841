
import { types } from "../types/types";

const initialState = {
    listadoClientesEmpresa: [],
}

export const listadoClientesEmpresaReducer = ( state = initialState, action ) => {
    
    switch (action.type) {

        case types.listadoClientesEmpresa:
            return {
                ...state,
                listadoClientesEmpresa: [
                    ...action.payload
                ]
            }
        
        default:
            return state;
    }
}