import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startSaveEmpresa } from '../../../actions/empresas';

export const ModalEmailNotificaciones = ( { modalEmailNotificacionesOpened, setModalEmailNotificacionesOpened } ) => {
    const dispatch = useDispatch();

    const { colores } = useSelector(state => state.ui);
    const { empresas } = useSelector(state => state.empresas);

    const resetForm = {
        emailSMTP: '',
        smtp: '',
        smtpPort: '',
        smtpPass: ''
    };

    const [newSMTPData, setNewSMTPData] = useState( resetForm );
    const [currentEmpresa, setCurrentEmpresa] = useState(null);

    useEffect(() => {
        if( empresas[0]?.nombre ){
            setCurrentEmpresa( empresas[0] );
            setNewSMTPData({
                ...newSMTPData,
                emailSMTP: empresas[0].emailSMTP,
                smtp: empresas[0].smtp,
                smtpPort: empresas[0].smtpPort
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empresas]);
    

    const clickCloseModal = ( fromButton = false ) => {
        if( fromButton ) {
            //setModalResponse(1);
            let newPassword = false;

            let empresaUpdated = {
                ...currentEmpresa,
                emailSMTP: newSMTPData.emailSMTP,
                smtp: newSMTPData.smtp,
                smtpPort: newSMTPData.smtpPort
            };

            if( newSMTPData.smtpPass.trim().length > 6 ){
                empresaUpdated.smtpPass = newSMTPData.smtpPass;
                newPassword = true;
            }

            dispatch( startSaveEmpresa( empresaUpdated, newPassword ));
            
        }
        setModalEmailNotificacionesOpened( false );
    };

    const inputChange = (e) => {
        setNewSMTPData({
            ...newSMTPData,
            [e.target.name]: e.target.value
        })
    } 

    return (
        <div className={`modal-ylm email-notifications ${(modalEmailNotificacionesOpened) ? 'active' : ''}`}>
            <div className="modal-bg"></div>
            <div className="modal-wrapper">
                <div className="modal-title">
                    <h3 style={{ color: colores.colorPrincipal }}>{ `Email notificaciones` }</h3>
                    <p>Email con el que enviarás todas tus notificaciones a tus users.</p>
                </div>
                <div className="modal-body">
                    <div className='en-input'>
                        <label 
                            htmlFor="email"
                            style={{ color: colores.colorPrincipal }}
                        >Email:</label>
                        <input 
                            type={`email`}
                            name='emailSMTP'
                            value={ newSMTPData.emailSMTP }
                            onChange={ inputChange } 
                        />
                    </div>
                    <div className='en-input'>
                        <label 
                            htmlFor="smtpPort"
                            style={{ color: colores.colorPrincipal }}
                        >Puerto:</label>
                        <input 
                            type={`text`}
                            name='smtpPort'
                            value={ newSMTPData.smtpPort }
                            onChange={ inputChange } 
                        />
                    </div>
                    <div className='en-input'>
                        <label 
                            htmlFor="smtp"
                            style={{ color: colores.colorPrincipal }}
                        >SMTP:</label>
                        <input 
                            type={`text`}
                            name='smtp'
                            value={ newSMTPData.smtp }
                            onChange={ inputChange } 
                        />
                    </div>
                    <div className='en-input'>
                        <label 
                            htmlFor="smtpPass"
                            style={{ color: colores.colorPrincipal }}
                        >Password:</label>
                        <input 
                            type={`password`}
                            name='smtpPass'
                            value={ newSMTPData.smtpPass }
                            onChange={ inputChange } 
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="buttons">
                        <div 
                            className="btn btn-confirm"
                            style={{ backgroundColor: colores.colorPrincipal }}
                            onClick={ () => clickCloseModal( true ) }
                        >{ `Guardar` }</div>
                        <div 
                            className="btn btn-cancel"
                            style={{ backgroundColor: '#F2F2F2', color: '#393D40' }}
                            onClick={ () => clickCloseModal() }
                        >{ `Cancelar` }</div>
                    </div>
                </div>
                <div
                    className="modal-close"
                    onClick={ () => clickCloseModal() }
                ><span></span></div>
            </div>
        </div>
    )
}
