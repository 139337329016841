import React from 'react';
import { useSelector } from 'react-redux';
import { TrashIcon } from '../../../assets/svg/iconos';

export const Semanal = ({editMode, horario, changeHorario}) => {
    const { colores } = useSelector(state => state.ui);

    const onDataChange = ( index, value ) => {
        const newArray = [ ...horario ];
        newArray[ index ] = value;
        changeHorario( newArray );
    };

    const onClickDelete = ( index ) => {
        const newArray = horario.filter( (item, idx) => {
            return index !== idx;
        });
        changeHorario( newArray );
    };

    const addLine = () => {
        const newArray = [ ...horario, '' ];
        changeHorario( newArray );
    };

    return (
        <div className='semanal'>
            <div className='horarios-container'>
                {
                    horario?.map( (linea, idx) => (
                        <div 
                            key={ idx }
                            className='horario-row'
                        >
                            <input
                                style={{ color: colores.colorPrincipal }}
                                autoComplete='off'
                                type="text"
                                placeholder='Introduce nuevo texto'
                                disabled={ !editMode }
                                value={ linea }
                                onChange={ (e) => onDataChange( idx, e.target.value )}
                            />
                            {
                                editMode &&
                                <button
                                    onClick={() => onClickDelete(idx)}
                                >
                                    <TrashIcon color={ colores.colorPrincipal } />
                                </button>
                            }
                        </div>
                    ))
                }
                {
                    editMode &&
                    <div 
                        className='add-line'
                        style={{ backgroundColor: colores.colorSecundario }}
                        onClick={ addLine }
                    >
                        <p style={{ color: colores.colorPrincipal }}>+ Añadir línea</p>
                    </div>
                }
            </div>
        </div>
    )
}
