export const isEmail = ( email ) => {
    // eslint-disable-next-line no-useless-escape
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( re.test(email) ) {
        // this is a valid email address
        // call setState({email: email}) to update the email
        // or update the data in redux store.
        return true;
    }
    else {
        // invalid email, maybe show an error to the user.
        return false;
    }
}

export const isDateBars = ( date ) => {
    // eslint-disable-next-line no-useless-escape
    let re = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if ( re.test(date) ) {
        return true;
    }
    else {
        return false;
    }
}