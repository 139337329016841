import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModalBottom } from '../../../actions/ui';
import { ChevronDownIcon } from '../../../assets/svg/iconos';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const DelaySelectValue = ({ delayType, delay, setDelay, editMode }) => {

    const dispatch = useDispatch();

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const showDropdown = () => {
        if( editMode ){ 
            setVisibility(true); 
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = ( cmd ) => {
        setDelay( delayType, cmd );
        hideDropdown();
        
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

  return (
      
    <div>
        { !(delayType === 'time' && delay === '0') &&
            <div 
                className={ `delay-time`}
                onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
            >
                <div className='delay-time-select'>
                    <p
                        className={ `${(delayType === 'time' && delay === '0') && 'underline'}`}
                    >{ !(delayType === 'time' && delay === '0') ? `${delay} ${delayType === 'time' ? 'min.' : '%'}` : '--'}</p>
                    <span><ChevronDownIcon color={ '#000000' } /></span>
                    <ul
                        ref={ dropdownMenuRef }
                        className={`delay-time-options ${ visibility ? 'active' : '' }`}
                    >
                        {
                            delayType === 'time' &&
                            <li
                                onClick={ () => selectItem('5')}
                            ><span className={`option-check ${(delayType === 'time' && delay === '5') ? 'active' : ''}`}><span></span></span><p>5 min.</p></li>
                        }
                        <li
                            onClick={ () => selectItem('10')}
                        ><span className={`option-check ${(delay === '10') ? 'active' : ''}`}><span></span></span><p>10 { delayType === 'time' ? 'min.' : '%'}</p></li>
                        <li
                            onClick={ () => selectItem('20')}
                        ><span className={`option-check ${(delay === '20') ? 'active' : ''}`}><span></span></span><p>20 { delayType === 'time' ? 'min.' : '%'}</p></li>
                        <li
                            onClick={ () => selectItem('30')}
                        ><span className={`option-check ${(delay === '30') ? 'active' : ''}`}><span></span></span><p>30 { delayType === 'time' ? 'min.' : '%'}</p></li>
                        {
                            delayType !== 'time' &&
                            <li
                                onClick={ () => selectItem('50')}
                            ><span className={`option-check ${(delayType !== 'time' && delay === '50') ? 'active' : ''}`}><span></span></span><p>50 { delayType === 'time' ? 'min.' : '%'}</p></li>
                        }
                        {
                            delayType !== 'time' &&
                            <li
                                onClick={ () => selectItem('100')}
                            ><span className={`option-check ${(delayType !== 'time' && delay === '100') ? 'active' : ''}`}><span></span></span><p>100 %</p></li>
                        }
                    </ul>
                </div>
            </div>
        }
    </div>
  )
}
