import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import AutosizeInput from 'react-input-autosize';

import { productoStartDelete, startLoadingProducto, startNewProducto, startSaveProducto, startUploading, unactiveProducto } from '../../../../actions/productos';
import { ArrowBackIcon, CategoriesIcon, OutStockIcon, PrepayIcon, TrashIcon } from '../../../../assets/svg/iconos';
import { fetchSinToken } from '../../../../helpers/fetch';
import { useParams } from 'react-router-dom';
import { newProducto } from '../../../../helpers/helpers';
import { ModalEliminarProducto } from '../modales/ModalEliminarProducto';

import imagePlaceholder from '../../../../assets/img/service-image-placeholder.jpg';
import { SelectCategoria } from './SelectCategoria';

export const Producto = ({history}) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector( state => state.auth );
    const { colores, miniLateral } = useSelector( state => state.ui );
    const { active: productoActive } = useSelector( state => state.productos );

    const { productoId } = useParams();
    
    const [currentScreen] = useState( (history.location.state.producto) ? 'edit' : 'new' );

    const [objProducto, setObjProducto] = useState({});
    const [iniciado, setIniciado] = useState(false);
    const [cambiado, setCambiado] = useState(false);

    const [editMode, setEditMode] = useState(true);

    const [imageUrl, setImageUrl] = useState('');
    const [file, setFile] = useState(null);
    const [productoImage, setProductoImage] = useState(null);
    
    const [idiomaActive, ] = useState('ES');
    const [ , setObjErrores] = useState({});

    const [modalEliminarProductoOpened, setModalEliminarProductoOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar producto?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [precio, setPrecio] = useState(0);
    const [precioReal, setPrecioReal] = useState(0);

    useEffect(() => {
        if( productoId !== 'new' ){
            dispatch(startLoadingProducto( productoId, empresa ));
        } else {
            const elProducto = newProducto(empresa);
            setObjProducto( elProducto );
            setEditMode( true );
            setCambiado( true );
        }
        return () => {
            dispatch( unactiveProducto() );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if( empresa && productoActive ){
            // console.log( productoActive )
            setIniciado( true );
            setPrecioInicial( productoActive.precio );
            if( productoActive.img.length > 0 ){
                getImagenPrincipal( `${productoActive.img[0]}` );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productoActive]);

    useEffect(() => {
        if( productoActive !== null ){
            setObjProducto( productoActive );
        }
    }, [productoActive]);

    useEffect(() => {
        //console.log( 'objProducto', objProducto );
        if( productoActive && objProducto ){
            //console.log( 'productoActive', productoActive );
            const servicioOriginal = { ...productoActive };
            if( iniciado ){
                let igual = true;
                for( const propiedad in servicioOriginal ){
                    //console.log( `${nEmpresa[propiedad]} !== ${objEmpresa[propiedad]}`)
                    if( servicioOriginal[propiedad] !== objProducto[propiedad] ){
                    igual = false;
                    //console.log(`Cambio en ${propiedad}`);
                    }
                }
                if( precioReal !== productoActive.precio ){
                    igual = false;
                }
                setCambiado( !igual );
            }
        }
        if( productoImage ){
            setCambiado( true );
        }
    }, [iniciado, productoActive, objProducto, productoImage, precioReal]); 

    const backClick = () => {
        history.push({
            pathname: `/punto-venta/productos`
        });
    }

    const setPrecioInicial = ( valor ) => {
        setPrecioReal( valor );
        const newValue = valor / 100;
        setPrecio(newValue);
    };

    const handlePrecioChange = (event) => {
        const newValue = event.target.value;
        setPrecioReal( Number(event.target.value) * 100 );
        if( newValue !== ''){
            setPrecio( Number(event.target.value) / 100 );
        } else {
            setPrecio(0);
        }
        setPrecio(newValue);
    }; 

    const guardar = () => {
        /* dispatch( startSaveProducto( objProducto ) );
        dispatch( activeProducto( objProducto._id, objProducto ));
        setEditMode( false ); */
        let correcto = true;
        const newObjErrores = {};
        if( objProducto.titulo.trim() === '' ){ 
            correcto = false;
            newObjErrores.titulo = 'Debe escribir un nombre';
        }

        if( objProducto.descripcion.trim() === '' ){ 
            correcto = false;
            newObjErrores.telefono = 'Debe escribir una descripción';
        }

        setObjErrores( newObjErrores );
        let newObjeto = { ...objProducto, precio: precioReal };
        // console.log( newObjeto );
        if( correcto ){
            //console.log('correcto');
            if( productoImage ){
                let previa = '';
                if( productoActive !== null && productoActive.img.length > 0) {
                    previa = productoActive.img[0];
                }
                if( !newObjeto._id ){
                    newObjeto._id = newObjeto.empresa + 'prod';
                }
                const imgName = `${newObjeto._id}-${new Date().getTime()}`;
                const archivo = file;
                const nombreCortado = archivo.name.split('.');
                const extension = nombreCortado[ nombreCortado.length -1 ];
                const nombreCompleto = `${imgName}.${extension}`;

                newObjeto = { ...newObjeto, img: [nombreCompleto]};

                dispatch( startUploading( file, newObjeto, nombreCompleto, previa ) );
            }
            
            if( currentScreen === 'new' ){
                delete newObjeto._id;
                dispatch( startNewProducto( newObjeto ) );
                history.push({ pathname: `/punto-venta/productos` });
            } else {
                dispatch( startSaveProducto( newObjeto ) );
                setCambiado( false );
                /* setObjServicio( newObjeto );
                setCurrentServicio( newObjeto ); */
            }

        }
    }

    const cancelar = () => {
        //setObjProducto( productoActive );
        if( currentScreen !== 'new' ){
            setPrecioReal( productoActive.precio );
            setPrecio( productoActive.precio / 100 );
            setObjProducto( productoActive );
            setProductoImage(null);
            setFile(null);
        } else {
            history.goBack();
        }
    }

    /* const deleteProducto = () => {
        console.log( 'deleteServicio' );
        history.goBack();
    } */
    
    const onFormChange = (e) => {
        let correcto = true;
        if( e.target.name === 'precio' ){
            const validNumber = new RegExp(/^\d*\.?\d*$/);
            if( !validNumber.test( e.target.value ) ){
                correcto = false;
            }
        }
        if( correcto ){
            dataChange( e.target.name, e.target.value );
        }
    }

    const dataChange = (name, value) => {
        setObjProducto({
            ...objProducto,
            [name]: value
        });
    }

    const getImagenPrincipal = async( nombre ) => {
        const imagen = await fetchSinToken( `imagen/product/${nombre}` );
        setImageUrl( imagen );
    }

    const onDrop = useCallback( (acceptedFiles) => {
        /* console.log( 'onDrop' );
        console.log( 'objServicio', objServicio );
        console.log( acceptedFiles );

        dispatch( startUploading( acceptedFiles[0], objServicio._id, objServicio.empresa )); */
        const file = acceptedFiles[0];
        setFile(file);
        setProductoImage(URL.createObjectURL(file));
        
        //dispatch( startUploading( file, objServicio ) );
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objProducto]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png",
        noKeyboard: true,
        onDrop
    });

    const deleteProductoModal = () => {
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar ${objProducto.titulo}?`]
        })
        setModalEliminarProductoOpened( true );
    };

    const deleteProductoResponse = ( value ) => {
        if( value === 1 ){
          console.log( 'value = 1' );
          let selectedId = objProducto._id;
          
          dispatch( productoStartDelete( selectedId, empresa ) );
          history.push({ pathname: `/punto-venta/productos` })
        }
    }
    
    return (
        <>
        <div 
            className='producto'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className='producto-wrapper'>
                <div className='single-producto'>
                    <div className={`header ${!miniLateral && 'compress'}`}>
                        <div 
                            className='nav-back'
                            onClick={ backClick }
                        >
                            <ArrowBackIcon color={ colores.colorSecundario } />
                            <p style={{ color: colores.colorSecundario }}>Productos</p>
                        </div>
                        <div className='botonera'>
                            {/* {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-editar'
                                    style={{ backgroundColor: editMode ? colores.colorPrincipal : colores.color6 }}
                                    onClick={ () => setEditMode( !editMode ) }
                                >
                                    <EditarIcon color={ editMode ? colores.color6 : colores.colorPrincipal } />
                                </button>
                            } */}
                            {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-eliminar'
                                    style={{ backgroundColor: colores.color6 }}
                                    onClick={ deleteProductoModal }
                                >
                                    <TrashIcon color={ colores.colorPrincipal } />
                                </button>
                            }
                            {
                            cambiado &&
                                <button 
                                    className='btn btn-crear'
                                    style={{ backgroundColor: colores.colorPrincipal }}
                                    onClick={ guardar }
                                >
                                    {`${currentScreen === 'new' ? 'Crear' : 'Guardar'}`}
                                </button>
                            }
                            {
                            cambiado &&
                                <button 
                                    className='btn btn-cancelar'
                                    style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
                                    onClick={ cancelar }
                                >
                                    Cancelar
                                </button>
                            }
                        </div>
                        
                    </div>
                    <div className='name'>
                        <input
                            autoComplete='off'
                            type="text"
                            placeholder='Nuevo producto'
                            name="titulo"
                            disabled={ !editMode }
                            value={ objProducto?.titulo || '' }
                            onChange={ (e) => onFormChange( e )}
                        />
                    </div>
                    <div className='producto-main'>
                        <div className='producto-left'>
                            <div
                                {...getRootProps({className: 'dropzone image-container'})}
                                //style={{ backgroundImage: `url(${productoImage ? productoImage : imageUrl.url + '?' + uuidv4() })`}}
                                style={{ backgroundImage: `url(${productoImage ? productoImage : ( imageUrl.url ? imageUrl.url + '?' + uuidv4() : imagePlaceholder) })`}}
                            >
                                <input {...getInputProps()} />
                            </div>
                            {/* <div 
                                className='image-container'
                                style={{ backgroundImage: `url(${productoImageUrl.url})` }}
                            >
                            </div> */}
                        </div>
                        <div className='producto-right'>
                            <div className='producto-data'>
                                <div className='producto-texts'>
                                    <div className='idiomas'>
                                        <div 
                                            className='btn-idioma'
                                            style={{ backgroundColor: idiomaActive === 'ES' ? colores.colorPrincipal : colores.colorSecundario}}
                                        >ES</div>
                                        <div 
                                            className='btn-idioma'
                                            style={{ backgroundColor: idiomaActive === 'CT' ? colores.colorPrincipal : colores.colorSecundario}}
                                        >CT</div>
                                    </div>
                                    <div className='formato'>
                                        <div className='formato-item'>
                                            <h3 style={{ color: colores.colorSecundario }}>Tamaño</h3>
                                            <input
                                                autoComplete='off'
                                                type='text'
                                                placeholder='Tamaño'
                                                name='size'
                                                disabled={ !editMode }
                                                value={ objProducto?.size || '' }
                                                onChange={ (e) => onFormChange( e )}
                                            />
                                        </div>
                                        <div className='formato-item'>
                                            <h3 style={{ color: colores.colorSecundario }}>Referencia</h3>
                                            <input
                                                autoComplete='off'
                                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                                type='text'
                                                placeholder='Introduce referencia'
                                                name='referencia'
                                                disabled={ !editMode }
                                                value={ objProducto?.referencia || '' }
                                                onChange={ (e) => onFormChange( e )}
                                            />
                                        </div>
                                    </div>
                                    <div className='producto-text'>
                                        <h3 style={{ color: colores.colorSecundario }}>Descripción</h3>
                                        <textarea
                                            name="descripcion"
                                            placeholder='Añadir descripción'
                                            disabled={ !editMode }
                                            value={ objProducto?.descripcion || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        ></textarea>
                                    </div>
                                    <div className='producto-text'>
                                        <h3 style={{ color: colores.colorSecundario }}>Ingredientes</h3>
                                        <textarea
                                            name="ingredientes"
                                            placeholder='Añadir ingredientes'
                                            disabled={ !editMode }
                                            value={ objProducto?.ingredientes || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        ></textarea>
                                    </div>
                                    <div className='producto-text'>
                                        <h3 style={{ color: colores.colorSecundario }}>Modo de empleo</h3>
                                        <textarea
                                            name="empleo"
                                            placeholder='Añadir modo de empleo'
                                            disabled={ !editMode }
                                            value={ objProducto?.empleo || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className='producto-prices'>
                                    <div 
                                        className='prices-container'
                                        style={{ color: colores.colorPrincipal, backgroundColor: colores.colorSecundario }}
                                    >
                                        <div className='price-container stock'>
                                            <div className='prices-title'>Unidades</div>
                                            <div 
                                                className='price-item'
                                            >
                                                <AutosizeInput
                                                    inputStyle={{ color: colores.colorPrincipal }}
                                                    autoComplete='off'
                                                    type="text"
                                                    placeholder='--'
                                                    name="stock"
                                                    disabled={ !editMode }
                                                    value={ String(objProducto?.stock) || '' }
                                                    onChange={ (e) => onFormChange( e )}
                                                />
                                            </div>
                                        </div>
                                        <div className='price-container price'>
                                            <div className='prices-title'>Precio U.</div>
                                            <div 
                                                className='price-item'
                                            >
                                                <AutosizeInput
                                                    inputStyle={{ color: colores.colorPrincipal }}
                                                    autoComplete='off'
                                                    type="text"
                                                    placeholder='--'
                                                    name="precio"
                                                    disabled={ !editMode }
                                                    value={ precio }
                                                    onChange={ handlePrecioChange}
                                                />
                                                <span>€</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='producto-selectors'>
                                <div
                                    className='producto-selector prepago'
                                    style={{ backgroundColor: `${objProducto?.prepago ? colores.colorSecundario : '#F7F7F7'}`}}
                                >
                                    <div className='left'>
                                        <div className='icon-bg'>
                                            <PrepayIcon color={ `${objProducto?.prepago ? colores.colorPrincipal : '#7B7B7B'}` }/>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <div 
                                            className={ `ylm-toggle ${ objProducto?.prepago && 'active'}`}
                                            onClick={ () => editMode && dataChange( 'prepago', !objProducto?.prepago )}
                                        >
                                            <p style={{ color: objProducto?.prepago ? colores.colorPrincipal: '#707070' }}>{ `Prepago` }</p>
                                            <div 
                                                className={ `toggle-bg`}
                                                style={{ backgroundColor: objProducto?.prepago ? '#FFFFFF' : '#C9C5C5' }}
                                            >
                                                <span
                                                    className={`toggle-item`}
                                                    style={{ backgroundColor: objProducto?.prepago ? colores.colorPrincipal : '#7B7B7B' }}
                                                ></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='producto-selector fuera-stock'
                                    style={{ backgroundColor: `${objProducto?.fueraStock ? colores.colorSecundario : '#F7F7F7'}`}}
                                >
                                    <div className='left'>
                                        <div className='icon-bg'>
                                            <OutStockIcon color={ `${objProducto?.fueraStock ? colores.colorPrincipal : '#7B7B7B'}` }/>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <div 
                                            className={ `ylm-toggle ${ objProducto?.fueraStock && 'active'}`}
                                            onClick={ () => editMode && dataChange( 'fueraStock', !objProducto?.fueraStock )}
                                        >
                                            <p style={{ color: objProducto?.fueraStock ? colores.colorPrincipal: '#707070' }}>{ `Fuera de stock` }</p>
                                            <div 
                                                className={ `toggle-bg`}
                                                style={{ backgroundColor: objProducto?.fueraStock ? '#FFFFFF' : '#C9C5C5' }}
                                            >
                                                <span
                                                    className={`toggle-item`}
                                                    style={{ backgroundColor: objProducto?.fueraStock ? colores.colorPrincipal : '#7B7B7B' }}
                                                ></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='producto-selector'
                                    style={{ backgroundColor: colores.colorSecundario }}
                                >
                                    <div className='left'>
                                        <div className='icon-bg'>
                                            <CategoriesIcon color={ colores.colorPrincipal }/>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <div className='title' style={{ color: colores.colorPrincipal }}>Categorías</div>
                                        <SelectCategoria
                                            disabled={ !editMode }
                                            objProducto={ objProducto }
                                            dataChange={ dataChange }
                                            big={ true }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalEliminarProducto
            data = { dataModalEliminar }
            setModalResponse = { deleteProductoResponse }
            modalOpened = { modalEliminarProductoOpened }
            setModalOpened = { setModalEliminarProductoOpened }
        />
        </>
    )
}
