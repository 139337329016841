import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { MailIcon, TelephoneIcon, UploadImageIcon } from '../../../assets/svg/iconos';

export const Ayuda = () => {
  const { colores } = useSelector(state => state.ui);

  // TODO: Las FAQ's deben venir de la BD
  const bdFaqs = [
    {
      _id: 'faq1',
      title: '¿Como canjeo mi bono regalo?',
      text: 'Realiza una reserva. Una vez finalizado el proceso y procedas al pago, haz clic en “Pagar ahora” y selecciona la opción Importe disponible. Se canjeará el importe necesario y el restante quedará en tu bono monedero. En caso de hacer una reserva con un importe superior, podrás abonar la diferencia de manera online o en la sucursal.'
    }
  ];
  const [ faqs, setFaqs ] = useState([ ...bdFaqs ]);  

  const handleInputChange = (e, faq, name) => {
    setFaqs(
      faqs.map( faq => {
        faq.title = name === 'title' ? e.target.value : faq.title;
        faq.text = name === 'text' ? e.target.value : faq.text;
        return faq;
      })
    )
  };

  return (
    <div className='configuracion-wrapper'>
      <div className='header'>
        <h1 style={{color: colores.colorPrincipal}}>Ayuda</h1>
        <div className='botonera'>
            <button 
              className='btn btn-primary'
              style={{ color: colores.color6, backgroundColor: colores.colorPrincipal }}
            >
              Aplicar
            </button>
            <button 
              className='btn btn-secondary'
              style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
            >
              Cancelar
            </button>
        </div>
      </div>
      <div className='section contacto'>
        <h3 style={{color: colores.colorSecundario}}>Contacto</h3>
        <div className='contacto-items'>
          <div className='contacto-item'>
            <div className='icon'>
              <TelephoneIcon color={ colores.colorPrincipal } />
            </div>
            <input
              type='text'
              placeholder='Teléfono'
            />
          </div>
          <div className='contacto-item'>
            <div className='icon'>
              <MailIcon color={ colores.colorPrincipal } />
            </div>
            <input
              type='text'
              placeholder='Email'
            />
          </div>
        </div>
      </div>
      <div className='section cancelaciones'>
        <h3 style={{color: colores.colorSecundario}}>Cancelaciones y devoluciones</h3>
        <div className='cancelaciones-items'>
          <div className='input-container'>
            <div className='cancelacion-input icon'>
                <div className='icon'><UploadImageIcon /></div>
                <input type='text' disabled placeholder='Sube tu política de devoluciones y cancelaciones'/>
                <button 
                  className='btn-upload-icon'
                  style={{ backgroundColor: colores.colorPrincipal, color: 'white' }}
                >Subir</button>
            </div>
          </div>
        </div>
      </div>

      <div className='section faqs'>
        <h3 style={{color: colores.colorSecundario}}>FAQ'S</h3>
        <div className='faqs-items'>
          {
            faqs.map( faq => (
              <div
                key={ faq._id }
                className='faq-item'>
                  <input
                    type='text'
                    placeholder={`Título`}
                    value={ faq.title }
                    onChange={ (e) => handleInputChange( e, faq, 'title' ) }
                  />
                  <textarea
                    onChange={ (e) => handleInputChange( e, faq, 'text' ) }
                    value={ faq.text }
                  >
                  </textarea>
              </div>
            ))
          }
          <div className='faq-item'>
            <button className='btn btn-add' style={{ color: colores.colorPrincipal }}>Añadir</button>
          </div>
        </div>
      </div>
    </div>
  )
}
