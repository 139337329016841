import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { AddSucursalIcon } from '../../../../assets/svg/iconos';

import { VacacionalItem } from './VacacionalItem';

const timezone = process.env.REACT_APP_TIMEZONE;

export const Vacacional = ({ editMode, objProfesional, descansosTemp, setDescansosTemp, deleteVacaciones }) => {
    const { colores } = useSelector( state => state.ui );
    const { uid } = useSelector( state => state.auth );

    const initDate = new Date(); 
    const endDate = new Date(); 
    initDate.setHours(0,0,0,0);
    endDate.setHours(23,59,0,0);

    const [contadorNewItems, setContadorNewItems] = useState(0);

    const initialFechaInicio = new Date().setHours(0,0,0,0);
    const initialFechaFin = new Date().setHours(23,59,0,0);

    const initialDescanso = {
        empresa: objProfesional.empresa,
        diasSemana: [],
        fechaFin: moment.tz(initialFechaFin, timezone).toISOString(),
        fechaInicio: moment.tz(initialFechaInicio, timezone).toISOString(),
        festivo: false,
        profesional: objProfesional._id,
        profesionalSucursals: [],
        repetir: false,
        tipo: 'ausencia',
        usuario: uid
    }

    // useEffect(() => {
    //   console.log( objProfesional );
    // }, [objProfesional])
    
  
    // const cancelNewItem = () => {
    //   initDate.setHours(0,0,0,0);
    //   endDate.setHours(23,59,0,0);
    //   setNewItem( false );
    //   setDelValue( initDate );
    //   setAlValue( endDate );
    // }

    // const saveNewItem = () => {
    //     console.log('Save new item');
    // };

    const addDescanso = () => {
        let newDescanso = { ...initialDescanso };
        newDescanso._id = `new-${contadorNewItems}`;

        setDescansosTemp([
            newDescanso,
            ...descansosTemp
        ]);

        setContadorNewItems( contadorNewItems + 1 );
    }

    const editDescanso = ( descanso ) => {
        setDescansosTemp(
            descansosTemp.map( des => {
                if( des._id === descanso._id ){
                    return descanso;
                }
                return des;
            })
        )
    }
    

    return (
        <div className='vacacional'>
            <div
                style={{ 
                    backgroundColor: colores.color6,
                    color: colores.colorPrincipal
                }}
                className={`vacacional-add`}
            >
                <p>Añadir</p>
                <span
                    onClick={ () => addDescanso() }
                ><AddSucursalIcon color={ colores.colorPrincipal } /></span>
            </div>
            {
                descansosTemp.map( (descanso) => (
                    descanso._id !== 'new' &&
                    <VacacionalItem
                        key={ descanso._id }
                        editMode={ editMode }
                        descanso = { descanso }
                        descansosTemp = { descansosTemp }
                        editDescanso = { editDescanso }
                        deleteVacaciones={ deleteVacaciones }
                    />
                ))
            }
        </div>
    )
}
