import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMiniLateral } from '../../actions/ui';
import { ClientesIcon, ConfigIcon, PreciosEspecialesIcon, ProfesionalesIcon, PuntoVentaIcon, ReservasIcon, SelectArrowIcon, SucursalesIcon, SuperAdminIcon, EmpresasIcon, IdiomasIcon, SectorIcon, PlanesIcon } from '../../assets/svg/iconos';

export const MenuLateral = ({ history, setMenuActive }) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const { menuLateral, miniLateral, colores } = useSelector(state => state.ui);
    const { profesionales } = useSelector(state => state.profesionales);

    //const [permisos, setPermisos] = useState([]);
    const [hovering, setHovering] = useState('');

    useEffect(() => {
      if( auth?.role === 'BUSINESS_ROLE' || auth?.role === 'PROFESSIONAL_ROLE' ){
          //console.log( auth );
        //const profesional = profesionales.filter( prof => prof._id === auth.profesional );
        //console.log( profesional );
      }
    }, [auth, profesionales]);

    

    const menuItemClick = ( item ) => {
        switch (item) {
            case 'reservas':
                history.push('/reservas');
                break;

            case 'profesionales':
                history.push('/profesionales');
                break;

            case 'clientes':
                history.push('/clientes');
                break;
            
            case 'punto-venta':
                history.push('/punto-venta');
                break;
            
            case 'precios-especiales':
                history.push('/precios-especiales');
                break;
            
            case 'notificaciones':
                history.push('/notificaciones');
                break;

            case 'sucursales':
                history.push('/sucursales');
                break;

            case 'configuracion':
                history.push('/configuracion');
                break;

            case 'admin':
                history.push('/admin');
                break;
            
            case 'empresas':
                history.push('/empresas');
                break;
            
            case 'idiomas':
                history.push('/idiomas');
                break;
            
            case 'sectores':
                history.push('/sectores');
                break;
            
            case 'planes':
                history.push('/planes');
                break;
        
            default:
                break;
        }
    }    

    const minimize = () => {
        //setMiniLateral(!miniLateral);
        dispatch( setMiniLateral( !miniLateral ) );
    }

	    
    return (
        
        <div 
            className={`menu-lateral  ${(miniLateral ? 'mini' : '')}`}
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            {
                ((auth.role !== 'SUPERADMIN_ROLE' && auth.role !== 'ADMIN_ROLE') || window.location.hostname != process.env.REACT_APP_ADMIN_URL) &&
                (
                    <>
                    <div
                        className={`btn-minimize`}
                        style={{ backgroundColor: colores.colorSecundario }}
                        onClick={ minimize }
                    >
                        <SelectArrowIcon />
                    </div>
                    {
                        true &&
                        <div 
                            className={ `menu-item menu-reservas ${(menuLateral === 'reservas' ? 'active' : '')}`}
                            onClick={ () => menuItemClick('reservas') }
                            style={{ backgroundColor: menuLateral ===  'reservas' ? colores.color3 : hovering === 'reservas' ? colores.color3 : 'transparent' }}
                            onMouseEnter={ () => setHovering('reservas') }
                            onMouseLeave={ () => setHovering('') }
                        >
                            <div className="menu-icon">
                                <ReservasIcon color={ colores.colorSecundario } />
                            </div>
                            <p style={{ color: colores.colorPrincipal }}>Reservas</p>
                        </div>
                    }
                    {
                        true &&
                        <div 
                            className={ `menu-item menu-profesionales ${(menuLateral === 'profesionales' ? 'active' : '')}` }
                            onClick={ () => menuItemClick('profesionales') }
                            style={{ backgroundColor: menuLateral ===  'profesionales' ? colores.color3 : hovering === 'profesionales' ? colores.color3 : 'transparent' }}
                            onMouseEnter={ () => setHovering('profesionales') }
                            onMouseLeave={ () => setHovering('') }
                        >
                            <div className="menu-icon">
                                <ProfesionalesIcon color={ colores.colorSecundario } />
                            </div>
                            <p style={{ color: colores.colorPrincipal }}>Profesionales</p>
                        </div>
                    }
                    <div 
                        className={ `menu-item menu-clientes ${(menuLateral === 'clientes' ? 'active' : '')}` }
                        onClick={ () => menuItemClick('clientes') }
                        style={{ backgroundColor: menuLateral ===  'clientes' ? colores.color3 : hovering === 'clientes' ? colores.color3 : 'transparent' }}
                        onMouseEnter={ () => setHovering('clientes') }
                        onMouseLeave={ () => setHovering('') }
                    >
                        <div className="menu-icon">
                            <ClientesIcon color={ colores.colorSecundario } />
                        </div>
                        <p style={{ color: colores.colorPrincipal }}>Clientes</p>
                    </div>
                    <div 
                        className={ `menu-item menu-punto-venta ${(menuLateral === 'punto-venta' ? 'active' : '')}` }
                        onClick={ () => menuItemClick('punto-venta') }
                        style={{ backgroundColor: menuLateral ===  'punto-venta' ? colores.color3 : hovering === 'punto-venta' ? colores.color3 : 'transparent' }}
                        onMouseEnter={ () => setHovering('punto-venta') }
                        onMouseLeave={ () => setHovering('') }
                    >
                        <div className="menu-icon">
                            <PuntoVentaIcon color={ colores.colorSecundario } />
                        </div>
                        <p style={{ color: colores.colorPrincipal }}>Punto de venta</p>
                    </div>
                    <div 
                        className={ `menu-item menu-servicios ${(menuLateral === 'precios-especiales' ? 'active' : '')}` }
                        onClick={ () => menuItemClick('precios-especiales') }
                        style={{ backgroundColor: menuLateral ===  'precios-especiales' ? colores.color3 : hovering === 'precios-especiales' ? colores.color3 : 'transparent' }}
                        onMouseEnter={ () => setHovering('precios-especiales') }
                        onMouseLeave={ () => setHovering('') }
                    >
                        <div className="menu-icon">
                            <PreciosEspecialesIcon color={ colores.colorSecundario } />
                        </div>
                        <p style={{ color: colores.colorPrincipal }}>Precios especiales</p>
                    </div>
                    {/* <div 
                        className={ `menu-item menu-notificaciones ${(menuLateral === 'notificaciones' ? 'active' : '')}` }
                        onClick={ () => menuItemClick('notificaciones') }
                        style={{ backgroundColor: menuLateral ===  'notificaciones' ? colores.color3 : hovering === 'notificaciones' ? colores.color3 : 'transparent' }}
                        onMouseEnter={ () => setHovering('notificaciones') }
                        onMouseLeave={ () => setHovering('') }
                    >
                        <div className="menu-icon">
                            <NotificacionesIcon color={ colores.colorPrincipal } />
                        </div>
                        <p style={{ color: colores.colorPrincipal }}>Notificaciones</p>
                    </div> */}
                    <div 
                        className={ `menu-item menu-sucursales ${(menuLateral === 'sucursales' ? 'active' : '')}` }
                        onClick={ () => menuItemClick('sucursales') }
                        style={{ backgroundColor: menuLateral ===  'sucursales' ? colores.color3 : hovering === 'sucursales' ? colores.color3 : 'transparent' }}
                        onMouseEnter={ () => setHovering('sucursales') }
                        onMouseLeave={ () => setHovering('') }
                    >
                        <div className="menu-icon">
                            <SucursalesIcon color={ colores.colorSecundario } />
                        </div>
                        <p style={{ color: colores.colorPrincipal }}>Locales</p>
                    </div>
                    <div 
                        className={ `menu-item menu-configuracion ${(menuLateral === 'configuracion' ? 'active' : '')}` }
                        onClick={ () => menuItemClick('configuracion') }
                        style={{ backgroundColor: menuLateral ===  'configuracion' ? colores.color3 : hovering === 'configuracion' ? colores.color3 : 'transparent' }}
                        onMouseEnter={ () => setHovering('configuracion') }
                        onMouseLeave={ () => setHovering('') }
                    >
                        <div className="menu-icon">
                            <ConfigIcon color={ colores.colorSecundario } />
                        </div>
                        <p style={{ color: colores.colorPrincipal }}>Configuracion</p>
                    </div>
                    <div 
                        className={ `menu-item menu-super-admin ${(menuLateral === 'admin' ? 'active' : '')}` }
                        onClick={ () => menuItemClick('admin') }
                        style={{ backgroundColor: menuLateral ===  'admin' ? colores.color3 : hovering === 'admin' ? colores.color3 : 'transparent' }}
                        onMouseEnter={ () => setHovering('admin') }
                        onMouseLeave={ () => setHovering('') }
                    >
                        <div className="menu-icon">
                            <SuperAdminIcon color={ colores.colorSecundario } />
                        </div>
                        <p style={{ color: colores.colorPrincipal }}>Admin</p>
                    </div>
                    </>
                )
            }
            {
                ((auth.role === 'SUPERADMIN_ROLE' || auth.role === 'ADMIN_ROLE') && window.location.hostname == process.env.REACT_APP_ADMIN_URL)&&
                (
                    <>
                    <div
                        className={`btn-minimize`}
                        onClick={ minimize }
                    >
                        <SelectArrowIcon />
                    </div>
                    <div 
                        className={ `menu-item menu-empresas ${(menuLateral === 'empresas' ? 'active' : '')}` }
                        onClick={ () => menuItemClick('empresas') }
                    >
                        <div className="menu-icon">
                            <EmpresasIcon />
                        </div>
                        <p>Empresas</p>
                    </div>
                    {/* <div 
                        className={ `menu-item menu-idiomas ${(menuLateral === 'idiomas' ? 'active' : '')}` }
                        onClick={ () => menuItemClick('idiomas')}
                    >
                        <div className="menu-icon">
                            <IdiomasIcon />
                        </div>
                        <p>Idiomas</p>
                    </div> */}
                    <div 
                        className={ `menu-item menu-sector ${(menuLateral === 'sectores' ? 'active' : '')}` }
                        onClick={ () => menuItemClick('sectores')}
                    >
                        <div className="menu-icon">
                            <SectorIcon />
                        </div>
                        <p>Sectores</p>
                    </div>
                    <div 
                        className={ `menu-item menu-planes ${(menuLateral === 'planes' ? 'active' : '')}` }
                        onClick={ () => menuItemClick('planes')}
                    >
                        <div className="menu-icon">
                            <PlanesIcon />
                        </div>
                        <p>Planes</p>
                    </div>
                    </>
                )
            }
            
        </div>
    )
}
