
import { types } from "../types/types";

const initialState = {
    pedidos: [],
    total: 0,
    avisos: 0,
    active: null
}

export const pedidosReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.pedidosActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.pedidosUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.pedidosAddNew:
            return {
                ...state,
                pedidos: [ ...state.pedidos, action.payload ]
            }

        case types.pedidosLoad:
            return {
                ...state,
                total: action.payload.total,
                avisos: action.payload.avisos,
                pedidos: [ ...action.payload.pedidos ]
            }
        
        case types.pedidosUpdated:
            console.log( action.payload );
            return {
                ...state,
                pedidos: state.pedidos.map(
                    pedido => pedido._id === action.payload._id
                        ? action.payload
                        : pedido
                )
            }

        case types.pedidosDelete:
            return {
                ...state,
                active: null,
                pedidos: state.pedidos.filter( pedido => pedido._id !== action.payload )
            }
    
        case types.pedidosLogoutCleaning:
            return {
                ...state,
                active: null,
                pedidos: []
            }
    

        default:
            return state;
    }
}