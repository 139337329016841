
import { types } from "../types/types";

const initialState = {
    promociones: [],
    active: null
}

export const promocionesReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.promocionesActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.promocionesUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.promocionesAddNew:
            return {
                ...state,
                promociones: [ ...state.promociones, action.payload ]
            }

        case types.promocionesLoad:
            return {
                ...state,
                promociones: [ ...action.payload ]
            }
        
        case types.promocionesUpdated:
            return {
                ...state,
                promociones: state.promociones.map(
                    promocion => promocion._id === action.payload._id
                        ? action.payload
                        : promocion
                )
            }

        case types.promocionesDelete:
            return {
                ...state,
                active: null,
                promociones: state.promociones.filter( promocion => promocion._id !== action.payload )
            }
    
        case types.promocionesLogoutCleaning:
            return {
                ...state,
                active: null,
                promociones: []
            }
    

        default:
            return state;
    }
}