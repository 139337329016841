import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { startLoadingVacacionesSucursal, startNewVacaciones } from '../../../actions/vacaciones';
import { SaveIcon, TrashIcon } from '../../../assets/svg/iconos';
import { DiaSemanaSelect } from '../../../components/tools/DiaSemanaSelect';
import { FinalizaSelect } from '../../../components/tools/FinalizaSelect';
import { FestivoCalendar } from './FestivoCalendar';

export const Festivos = ({editMode, sucursal}) => {
  
  const dispatch = useDispatch();
  const { empresa } = useSelector(state => state.auth);
  const { vacaciones } = useSelector(state => state.vacaciones);
  const { colores } = useSelector(state => state.ui);

  let initDate = new Date();
  let endDate = new Date();
  initDate.setHours(0,0,0,0);
  endDate.setHours(23,59,0,0);

  const [newItem, setNewItem] = useState(false);
  const [delValue, setDelValue] = useState(initDate);

  useEffect(() => {
      dispatch( startLoadingVacacionesSucursal( empresa, sucursal ) );
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, empresa]);

  const resetForm = () => {
      initDate = new Date(); 
      endDate = new Date(); 
      initDate.setHours(0,0,0,0);
      endDate.setHours(23,59,0,0);
      setDelValue(initDate);
  }

  const cancelNewItem = () => {
      initDate.setHours(0,0,0,0);
      endDate.setHours(23,59,0,0);
      setNewItem( false );
      setDelValue( [initDate, endDate] );
  }

  const saveNewItem = () => {
      setNewItem( false );
      resetForm();
      const newVacaciones = {
          empresa,
          sucursal: sucursal._id,
          fechaInicio: moment.utc( delValue[0] ).toISOString(),
          fechaFin: moment.utc( delValue[1] ).toISOString(),
      }
      dispatch( startNewVacaciones( newVacaciones ) );
  };

  return (
    <div className='festivos'>
      {
        vacaciones.map( vacacion => (
          (!vacacion.deleted && vacacion.festivo) &&
          <div className={`festivos-item`}>
            <div className='new-item-row'>
                <FestivoCalendar
                    editMode={ editMode }
                    value={ delValue }
                    onChange={ setDelValue }
                />
                <div className='select-dia'>
                  <p>Repetir descanso</p>
                  <DiaSemanaSelect
                    editMode={ editMode }
                  />
                </div>
                <div className='select-fin'>
                  <p>Finaliza</p>
                  <FinalizaSelect
                    editMode={ editMode }
                  />
                </div>
                <div className='botonera'>
                    {/* <div 
                        className='btn save'
                        onClick={ saveNewItem }
                    ><SaveIcon /></div> */}
                    {
                        editMode &&
                        <div
                            className='btn delete'
                            onClick={ cancelNewItem }
                        ><TrashIcon /></div>
                    }
                </div>
            </div>
          </div>              
        ))
      }
      
      {
          !newItem ?
          <div className={`festivos-item`} style={{ backgroundColor: colores.color6 }}>
            <p
                className='new-item-txt'
                style={{ color: colores.colorPrincipal }}
                onClick={ () => setNewItem(true) }
            >+ Añadir festivo</p>
          </div>
          :
          <div className={`festivos-item`}>
            <div className='new-item-row'>
                <FestivoCalendar
                    editMode={ true }
                    value={ delValue }
                    onChange={ setDelValue }
                />
                <div className='select-dia'>
                  <p>Repetir descanso</p>
                  <DiaSemanaSelect
                    editMode={ true }
                  />
                </div>
                <div className='select-fin'>
                  <p>Finaliza</p>
                  <FinalizaSelect
                    editMode={ true }
                  />
                </div>
                <div className='botonera'>
                    <div 
                        className='btn save'
                        onClick={ saveNewItem }
                    ><SaveIcon /></div>
                    <div
                        className='btn delete'
                        onClick={ cancelNewItem }
                    ><TrashIcon /></div>
                </div>
            </div>
          </div>
      }
    </div>
  )
}
