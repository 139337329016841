import React from 'react';
import { useSelector } from 'react-redux';

export const ModalEliminarServicio = ( { data, setModalResponse, modalOpened, setModalOpened } ) => {
    const { colores } = useSelector(state => state.ui);

    const clickCloseModal = ( fromButton = false ) => {
        if( fromButton ) {
            setModalResponse(1);
        }
        setModalOpened( false );
    };    

    return (
        <div className={`modal-ylm ${(modalOpened) ? 'active' : ''}`}>
            <div className="modal-bg"></div>
            <div className="modal-wrapper">
                <div className="modal-title">
                    <h3 style={{ color: colores.colorSecundario }}>{ data?.title }</h3>
                </div>
                <div className="modal-body">
                    {
                        data?.text.map( (linea, idx) => (
                            <p key={ idx }>{ linea }</p>
                        ))
                    }
                </div>
                <div className="modal-footer">
                    <div className="buttons">
                        <div 
                            className="btn btn-confirm"
                            style={{ backgroundColor: colores.colorPrincipal }}
                            onClick={ () => clickCloseModal( true ) }
                        >{ data?.buttonText }</div>
                        <div 
                            className="btn btn-cancel"
                            style={{ backgroundColor: '#F2F2F2', color: '#393D40' }}
                            onClick={ () => clickCloseModal() }
                        >{ data?.button2Text }</div>
                    </div>
                </div>
                <div
                    className="modal-close"
                    onClick={ () => clickCloseModal() }
                ><span></span></div>
            </div>
        </div>
    )
}
