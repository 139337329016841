import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuLateral } from '../../actions/ui';

import { AddIcon, BuscarIcon, CloseIcon, UploadImageIcon } from '../../assets/svg/iconos';

export const Idiomas = ({history}) => {

    const dispatch = useDispatch();
    const { colores } = useSelector(state => state.ui);

    const [initialSearch, setInitialSearch] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [idiomasList, setIdiomasList] = useState([
        { _id: '1', nombre: 'Catalán', slug: 'CA' },
        { _id: '2', nombre: 'Español', slug: 'ES' },
        { _id: '3', nombre: 'Inglés', slug: 'EN' },
        { _id: '4', nombre: 'Francés', slug: 'FR' },
    ]);
    const [newIdioma, setNewIdioma] = useState({
        _id: 'new',
        nombre: '',
        slug: ''
    });

    const [isLoading, setIsLoading] = useState(false);

    const [modalEliminarIdiomaOpened, setModalEliminarIdiomaOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar idioma?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [selectedId, setSelectedId] = useState(''); 

    useEffect(() => {
        //dispatch( startLoadingEmpresas( pagNumber | 1, LIMITE_EMPRESAS, orderBy, asc ) );
        setInitialSearch( true );
        dispatch( setMenuLateral('idiomas') );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    
    

    useEffect( () => {
        if( searchTxt.trim().length > 3 ){
            
            //dispatch( startSearchEmpresas( searchTxt.trim(), pagNumber | 1, LIMITE_EMPRESAS, orderBy, asc ) );
            //setInitialSearch( false );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTxt]); 

    const clickSearch = () => {
        if( searchActive ){
            setSearchTxt('');
        }
        setSearchActive( !searchActive );
    };

    const clickDeleteSearch = () => {
        setSearchTxt('');
    }

    const handleSearchChange = (e) => {
        setSearchTxt( e.target.value );
    }

    const clickAdd = () => {
        console.log( 'add idioma');
    };

    const deleteIdiomaModal = ( idiomaId, name ) => {
        setSelectedId( idiomaId );
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar el idioma ${name}?`]
        })
        setModalEliminarIdiomaOpened( true );
    };

    const deleteIdiomaResponse = ( value ) => {
        if( value === 1 && selectedId !== '' ){
            console.log( 'Borrar _id:', selectedId );
            //dispatch( empresaStartDelete( selectedId, empresa ) );
        }
    }

    return (
        <>
        <div 
            className='idiomas-superadmin'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className="preheader">
                <div className="title">
                    <h1 style={{color: colores.colorPrincipal}}>Idiomas</h1>
                </div>
                <div className="botonera">
                    <div 
                        className={`btn-search ${searchActive && 'active'}`}
                    >
                        <div className='search-input'>
                            <input 
                                type={'text'}
                                placeholder={'Buscar por nombre'}
                                value={ searchTxt }
                                onChange={ handleSearchChange }
                            />
                            {
                                searchTxt.trim().length > 2 &&
                                <span className='cantidad'>
                                    { `5` }
                                </span>
                            }
                        </div>
                        {
                            searchTxt.trim().length < 1 &&
                            <div
                                className='icono'
                                onClick={ clickSearch }
                            >
                                <BuscarIcon
                                    color={ colores.colorPrincipal }
                                    className="icon-inverse"
                                />
                            </div>
                        }
                        {
                            searchTxt.trim().length >= 1 &&
                            <div
                                className='icono'
                                onClick={ clickDeleteSearch }
                            >
                                <CloseIcon
                                    color={ colores.colorPrincipal }
                                    className="icon-inverse"
                                />
                            </div>
                        }
                    </div>
                    <div 
                        className="btn-add"
                        style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                        onClick={ clickAdd }
                    >
                        <div className='icono'>
                            <AddIcon
                                color={ colores.colorPrincipal }
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
            <section className='idiomas-list'>
                {
                    idiomasList.map( idioma => (
                        <div
                            key={ idioma._id }
                            className='idioma-row'
                        >
                            <div className='idioma-circle'>{ idioma.slug }</div>
                            <div className='idioma-name'>{ idioma.nombre }</div>
                            <div className='idioma-file'>
                                <UploadImageIcon />
                                <p>documento.xls</p>
                            </div>
                        </div>
                    ))
                }
                <div className='idioma-row new-item'>
                    <div className='idioma-circle'>{ `${newIdioma.slug !== '' ? newIdioma.slug : '--'}` }</div>
                    <div className='idioma-name'>{ newIdioma.nombre }</div>
                    <div className='idioma-file'>
                        <UploadImageIcon />
                        <p>documento.xls</p>
                    </div>
                </div>
            </section>
        </div>
        {/* <ModalEliminarIdioma
            data = { dataModalEliminar }
            setModalResponse = { deleteIdiomaResponse }
            modalOpened = { modalEliminarIdiomaOpened }
            setModalOpened = { setModalEliminarIdiomaOpened }
        /> */}
        </>
    )
}
