import React, { useState } from 'react'
import { useSelector } from 'react-redux';

export const BonoServicio = () => {
    const { colores } = useSelector(state => state.ui);

    const [sellarManual, setSellarManual] = useState(false);
    
    const toggleSellar = () => {
        setSellarManual( !sellarManual );
    }

    return (
        <div className='bono-servicio'>
            <div className='bono-item' style={{ backgroundColor: colores.color6 }}>
                <div className='first-row'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ color: colores.colorPrincipal }}>Fecha compra</th>
                                <th style={{ color: colores.colorPrincipal }}>Bono servicio</th>
                                <th style={{ color: colores.colorPrincipal }}>Unidades</th>
                                <th style={{ color: colores.colorPrincipal }}>Precio</th>
                                <th style={{ color: colores.colorPrincipal }}>Duración</th>
                                <th style={{ color: colores.colorPrincipal }}>Renovación automática</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='fecha'>
                                    {/* <p><b>{ moment( comentario?.fecha ).format('D/M/YYYY') }</b> { moment( comentario?.fecha ).format('H:mm') }h</p> */}
                                    <p><b>{ `24/5/2022` }</b> { `10:33` }h</p>
                                </td>
                                <td className='servicio'>Barba + corte</td>
                                <td className='unidades'>10</td>
                                <td className='precio'>150€</td>
                                <td className='duracion'>Anual</td>
                                <td className='renovacion'>Activa</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='second-row'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ color: colores.colorPrincipal }}>Estado</th>
                                <th style={{ color: colores.colorPrincipal }}>Sellar manualmente</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='sellos'>
                                        <div 
                                            className="sello"
                                             style={{ backgroundColor: colores.colorPrincipal }}
                                        >1</div>
                                        <div 
                                            className="sello"
                                             style={{ backgroundColor: colores.colorSecundario }}
                                        >2</div>
                                        <div 
                                            className="sello"
                                             style={{ backgroundColor: colores.colorSecundario }}
                                        >3</div>
                                        <div 
                                            className="sello"
                                             style={{ backgroundColor: colores.colorSecundario }}
                                        >4</div>
                                        <div 
                                            className="sello"
                                             style={{ backgroundColor: colores.colorSecundario }}
                                        >5</div>
                                        <div 
                                            className="sello"
                                             style={{ backgroundColor: colores.colorSecundario }}
                                        >6</div>
                                        <div 
                                            className="sello"
                                             style={{ backgroundColor: colores.colorSecundario }}
                                        >7</div>
                                        <div 
                                            className="sello"
                                             style={{ backgroundColor: colores.colorSecundario }}
                                        >8</div>
                                        <div 
                                            className="sello"
                                             style={{ backgroundColor: colores.colorSecundario }}
                                        >9</div>
                                        <div 
                                            className="sello"
                                             style={{ backgroundColor: colores.colorSecundario }}
                                        >10</div>
                                    </div>
                                </td>
                                <td className='toggle'>
                                    <div 
                                        className={ `ylm-toggle ${ sellarManual && 'active'}`}
                                        onClick={ toggleSellar }
                                    >
                                        <div className={ `toggle-item`}></div>
                                        <p></p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
