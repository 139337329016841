import { useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModalBottom } from '../../../../actions/ui';

import { ChevronDownIcon } from '../../../../assets/svg/iconos';
import useClickOutside from '../../../../hooks/useClickOutside';

export const SelectSucursal = ({objPromocion, dataChange, editMode}) => {

    const dispatch = useDispatch();
    const { sucursales } = useSelector(state => state.sucursales);

    const [selectText, setSelectText] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        // console.log( sucursales );
        // console.log( objPromocion );
        if( objPromocion?.tipo ){
            let seleccionadas = 0;
            sucursales.forEach( sucursal => {
                if( objPromocion.sucursales.includes( sucursal._id ) && !sucursal.deleted ){
                    seleccionadas++;
                }
            });
            // console.log( seleccionadas );
            if( seleccionadas === 0 ){
                setSelectText( 'Ninguna' );
            } else if( seleccionadas === sucursales.length ){
                setSelectText( 'Todas' );
            } else {
                setSelectText( 'Algunas' );
            }
        } else {
            setSelectText( 'Ninguna' );
        }
    }, [objPromocion, sucursales]);
    

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    const clickSucursal = ( idSucursal ) => {
        if( editMode ){
            let newSucursales = [];
            if( objPromocion.sucursales.includes( idSucursal ) ){
                newSucursales = objPromocion.sucursales.filter( suc => {
                    return suc !== idSucursal;
                });
                // console.log( newSucursales );
            } else {
                newSucursales = [
                    ...objPromocion.sucursales,
                    idSucursal
                ]
            }
            dataChange('sucursales', newSucursales );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    return (
        <div 
            className='ylm-sucursal-select promocion-selector'
        >
            <div 
                className='select-main'
                onClick={ clickSelect }
            >
                <p>{ selectText }</p>
                <div 
                    className='btn-chevron'
                    style={{ transform: isOpen && 'rotate(180deg)'}}
                >
                    <ChevronDownIcon
                        color={ '#000000' }
                    />
                </div>
            </div>
            {
                isOpen &&
                <ul 
                    className='select-options' 
                    ref={popover}
                >
                    {
                        sucursales.map( sucursal => (
                            !sucursal.deleted &&
                            <li
                                key={ sucursal._id }
                                onClick={ () => clickSucursal( sucursal._id )}
                            >
                                <div 
                                    className={`checkbox-bg ${objPromocion?.sucursales.includes( sucursal._id ) ? 'active' : ''}`}
                                >
                                    {
                                        objPromocion?.sucursales.includes( sucursal._id ) &&
                                        <span 
                                            className='checkbox-item'
                                        ></span>
                                    }
                                </div>
                                <p>{ sucursal.nombre }</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
