import { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useOutsideClick } from "./useOutsideClick";

export const MultiSelectionVariantes = ({ variantes, selectedServicio, selectedVariantes, handleVariantesChanged }) => {
  const { colores } = useSelector(state => state.ui);
  const [isLoaded, setIsLoaded] = useState(false);
  const [visibility, setVisibility] = useState(false);
  //const variantesBySelectedServices = variantes.filter(v => selectedServicio?.selected?.variantes.includes(v._id));
  let variantesBySelectedServices = variantes.filter((variante) => {
        // Verificamos si algún servicio en la variante tiene un _id presente en la lista de servicios
        return variante.servicios.some((servicioId) =>
          selectedServicio.some((servicio) => servicio._id === servicioId)
        );
  });

  let nombrePorServicioId = [];

  useEffect(() => {
    // Crear un mapeo de descripciones de servicios
    selectedServicio.forEach((servicio) => {
        nombrePorServicioId[servicio._id] = servicio.nombre;
    });

    // Asignar las descripciones a las variantes
    variantesBySelectedServices.forEach((variante) => {
        variante.servicios.forEach((servicioId) => {
          if (nombrePorServicioId[servicioId]) {
              let splitName = variante.nombre.split("-");
              if(splitName.length>1) variante.nombre = variante.nombre.split(" - ")[0];
              variante.nombre = variante.nombre + ' - ' + nombrePorServicioId[servicioId];
          }
        });
    });
  
  }, [selectedServicio])
  
  const dropdownMenuRef = useRef();

  const toggleVisibility = () => {
    setVisibility(!visibility);
  }

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    if (!isLoaded) return;

    handleVariantesChanged([]);
  }, [selectedServicio])

  useOutsideClick(dropdownMenuRef, () => setVisibility(!visibility), visibility);

  const handleOnChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      handleVariantesChanged([...selectedVariantes, variantes.find(v => v._id === value)]);
    } else {
      handleVariantesChanged(selectedVariantes.filter(v => v._id !== value));
    }
  }

  const isChecked = (variante) => {
    return selectedVariantes?.some(v => v._id === variante._id)
  }

  return (
    <div className="brb-select">
      <div
        className={`brb-select-container ${visibility ? 'active' : ''}`}
        onClick={toggleVisibility}
      >
        <p>{selectedVariantes.length ? selectedVariantes.map(v => v.nombre).join(", ") : "Seleccione una variante"}</p>
        <div className="right-zone">
          <span
            className="select-arrow select-arrow-down"
          ></span>
        </div>
      </div>
      <div ref={dropdownMenuRef} className={`brb-select-options select-servicio ${visibility ? 'active' : ''}`}>

        {variantesBySelectedServices ?
          variantesBySelectedServices.map((variante) => {
            return (
              <div key={variante._id} className="brb-select-option" style={{ backgroundColor: colores.color6 }}>
                <input type="checkbox" id={variante._id} name={variante._id} value={variante._id} checked={isChecked(variante)} onChange={handleOnChange} />
                <label htmlFor={variante._id}>{variante.nombre}</label>
              </div>
            )
          })
		  :
		  <div>Selecciona algún servicio...</div>
        }
      </div>
    </div>
  )
};

