
import { types } from "../types/types";

const initialState = {
    clienteSaldos: [],
    active: null
}

export const clienteSaldosReducer = ( state = initialState, action ) => {

    switch (action.type) {

        case types.clienteSaldosLoad:
            return {
                ...state,
                clienteSaldos: [ ...action.payload ]
            }
        
        default:
            return state;
    }
}