import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startNewSucursal, startSaveSucursal, sucursalStartDelete } from '../../../actions/sucursales';
import { openModalBottom } from '../../../actions/ui';
import { ArrowBackIcon, TrashIcon } from '../../../assets/svg/iconos'
import { newSucursal } from '../../../helpers/helpers';
import { AvatarProfesional } from './AvatarProfesional';
import { Festivos } from './Festivos';
import { ModalEliminarSucursal } from './modales/ModalEliminarSucursal';
import { Semanal } from './Semanal';
import { Vacacional } from './Vacacional';

export const Sucursal = ({ history }) => {

    const dispatch = useDispatch();
    
    const { profesionales } = useSelector(state => state.profesionales);
    const { colores, miniLateral } = useSelector(state => state.ui);
    const { empresa } = useSelector(state => state.auth);

    const [currentSucursal, setCurrentSucursal] = useState( history.location.state.sucursal || undefined );
    const [currentScreen] = useState( (history.location.state.sucursal !== 'new') ? 'edit' : 'new' );

    const [objSucursal, setObjSucursal] = useState({});
    const [iniciado, setIniciado] = useState(false);
    const [cambiado, setCambiado] = useState(false);
    const [merchantCambiado, setMerchantCambiado] = useState(false);

    const [activeTab, setActiveTab] = useState( 'semanal' );

    const [editMode, setEditMode] = useState(true); 
    
    // const [idiomaActive, setIdiomaActive] = useState('ES');
    const [objErrores, setObjErrores] = useState({});  
    const [profs, setProfs] = useState([]);

    const [modalEliminarSucursalOpened, setModalEliminarSucursalOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar sucursal?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    useEffect(() => {
      if( currentSucursal !== 'new' ){
        // console.log( currentSucursal );
        setObjSucursal( currentSucursal );
        setIniciado( true );
      } else {
        // console.log( 'nueva' );
        setEditMode( true );
        setCambiado(true);
        const nuevo = newSucursal( empresa );
        setCurrentSucursal( nuevo );
        setObjSucursal( nuevo );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSucursal]);

    useEffect(() => {
        // console.log( 'objSucursal', objSucursal );
        if( currentSucursal ){
            const servicioOriginal = { ...currentSucursal };
            if( iniciado ){
                let igual = true;
                for( const propiedad in servicioOriginal ){
                    //console.log( `${nEmpresa[propiedad]} !== ${objEmpresa[propiedad]}`)
                    if( servicioOriginal[propiedad] !== objSucursal[propiedad] ){
                    igual = false;
                    console.log(`Cambio en ${propiedad}`);
                    }
                }
                setCambiado( !igual );
            }
        }
    }, [iniciado, currentSucursal, objSucursal]);   

    useEffect(() => {
        setProfs(
            profesionales.filter( prof => {
                return prof.sucursales.includes( currentSucursal._id ) && !prof.deleted;
            })
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profesionales]);    
    

    const backClick = (e) => {
      e.preventDefault();
      history.push({ pathname: `/sucursales` });
    }

    const cancelar = () => {
        console.log( currentScreen );
        if( currentScreen !== 'new' ){
            setObjSucursal( currentSucursal );
        } else {
            history.goBack();
        }
    }
    
    const onFormChange = (e) => {
        let correcto = true;
        if( e.target.name === 'precio' ){
            const validNumber = new RegExp(/^\d*\.?\d*$/);
            if( !validNumber.test( e.target.value ) ){
                correcto = false;
            }
        }
        if(e.target.name==='merchantId'){
            setMerchantCambiado(true);
            console.log("merchantCambiado...", merchantCambiado);
        }
        if( correcto ){
            dataChange( e.target.name, e.target.value );
        }
    }

    const dataChange = (name, value) => {
        if( editMode ){
            setObjSucursal({
                ...objSucursal,
                [name]: value
            });
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    const changeHorario = ( newHorario ) => {
        setObjSucursal({
            ...objSucursal,
            horarioTxt: newHorario
        });
    }

    const guardar = () => {
        // console.log( 'currentScreen', currentScreen );
        let correcto = true;
        const newObjErrores = {};
        if( objSucursal?.nombre.trim() === '' ){ 
            correcto = false;
            newObjErrores.nombre = 'Debe escribir un nombre';
        }
        
        //console.log( objServicio );
        //console.log('Correcto:', correcto );
        //console.log('Errores:', newObjErrores );
        setObjErrores( newObjErrores );
        let newObjeto = { ...objSucursal };
        if( correcto ){
            //console.log('correcto');            
            if( currentScreen === 'new' ){
                console.log( 'start new' );
                dispatch( startNewSucursal( newObjeto ) );
                history.push({ pathname: `/sucursales` });
            } else {
                console.log( 'start save' );
                newObjeto.empresa = empresa;
                dispatch( startSaveSucursal( newObjeto ) );
                setCambiado( false );
                /* setObjServicio( newObjeto );
                setCurrentServicio( newObjeto ); */
            }

            
        } else {
            console.log( newObjErrores );
        }
    }

    const deleteSucursalModal = () => {
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar la sucursal ${objSucursal?.nombre}?`]
        })
        setModalEliminarSucursalOpened( true );
    };

    const deleteSucursalResponse = ( value ) => {
        if( value === 1 ){
          console.log( 'value = 1' );
          let selectedId = objSucursal?._id;

          console.log( 'Borrar id:', selectedId );
          dispatch( sucursalStartDelete( selectedId, empresa ) );
          history.push({ pathname: `/sucursales` })
        }
    }

    return (
        <>
        <div 
            className='sucursal'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className='sucursal-wrapper'>
                <div className='single-sucursal'>
                    <div className={`header ${!miniLateral && 'compress'}`}>
                        <div 
                            className='nav-back'
                            onClick={ (e) =>  backClick(e) }
                        >
                            <ArrowBackIcon color={ colores.colorSecundario } />
                            <p style={{ color: colores.colorSecundario }}>Sucursales</p>
                        </div>

                        <div className='botonera'>
                            {/* {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-editar'
                                    style={{ backgroundColor: editMode ? colores.colorPrincipal : colores.color6 }}
                                    onClick={ () => setEditMode( !editMode ) }
                                >
                                    <EditarIcon color={ editMode ? colores.color6 : colores.colorPrincipal } />
                                </button>
                            } */}
                            {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-eliminar'
                                    style={{ backgroundColor: colores.color6 }}
                                    onClick={ deleteSucursalModal }
                                >
                                    <TrashIcon color={ colores.colorPrincipal } />
                                </button>
                            }
                            {
                            cambiado || merchantCambiado &&
                                <button 
                                    className='btn btn-crear'
                                    style={{ backgroundColor: colores.colorPrincipal }}
                                    onClick={ guardar }
                                >
                                    {`${currentScreen === 'new' ? 'Crear' : 'Guardar'}`}
                                </button>
                            }
                            {
                            cambiado || merchantCambiado &&
                                <button 
                                    className='btn btn-cancelar'
                                    style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
                                    onClick={ cancelar }
                                >
                                    Cancelar
                                </button>
                            }
                        </div>
                    </div>
                    <div className='sucursal-body'>
                        <h1>
                            <input
                                style={{ 
                                    backgroundColor: editMode ? '#ffffff' : 'transparent',
                                    border: `${objErrores.nombre ? '2px solid ' + colores.color5 : 'none'}`
                                }}
                                autoComplete='off'
                                type="text"
                                placeholder='Introduzca nombre'
                                name="nombre"
                                disabled={ !editMode }
                                value={ objSucursal?.nombre || '' }
                                onChange={ (e) => onFormChange( e )}
                            />
                        </h1>
                        <div className='sucursal-main'>
                            <div className='map'>
                                <div className='bg-map'></div>
                                <iframe title="Mapa" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={`https://www.openstreetmap.org/export/embed.html?bbox=${ objSucursal?.long }%2C${ objSucursal?.lat }%2C${ objSucursal?.long }%2C${ objSucursal?.lat }&amp;layer=mapnik`}></iframe>
                            </div>
                            <div className='data'>
                                <div className='contacto-sucursal'>
                                    <h3 style={{ color: colores.colorSecundario }}>Datos de contacto</h3>
                                    <div className='address'>
                                        <input
                                            style={{ 
                                                backgroundColor: editMode ? '#ffffff' : 'transparent',
                                                border: `${objErrores.direccion ? '2px solid ' + colores.color5 : 'none'}`
                                            }}
                                            autoComplete='off'
                                            type="text"
                                            placeholder='Introduzca dirección'
                                            name="direccion"
                                            disabled={ !editMode }
                                            value={ objSucursal?.direccion || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        />
                                    </div>
                                    <div className='city'>
                                        <input
                                            style={{ 
                                                backgroundColor: editMode ? '#ffffff' : 'transparent',
                                                border: `${objErrores.poblacion ? '2px solid ' + colores.color5 : 'none'}`
                                            }}
                                            autoComplete='off'
                                            type="text"
                                            placeholder='Introduzca población'
                                            name="poblacion"
                                            disabled={ !editMode }
                                            value={ objSucursal?.poblacion || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        />
                                    </div>
                                    <div className='telephone'>
                                        Teléfono de contacto:
                                        <input
                                            style={{ 
                                                backgroundColor: editMode ? '#ffffff' : 'transparent',
                                                border: `${objErrores.poblacion ? '2px solid ' + colores.color5 : 'none'}`
                                            }}
                                            autoComplete='off'
                                            type="text"
                                            placeholder='Introduzca teléfono'
                                            name="telefono"
                                            disabled={ !editMode }
                                            value={ objSucursal?.telefono || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        />
                                    </div>
                                    <div className='email'>
                                        Dirección de correo electrónico: 
                                        <input
                                            style={{ 
                                                backgroundColor: editMode ? '#ffffff' : 'transparent',
                                                border: `${objErrores.poblacion ? '2px solid ' + colores.color5 : 'none'}`
                                            }}
                                            autoComplete='off'
                                            type="email"
                                            placeholder='Sin e-mail'
                                            name="email"
                                            disabled={ !editMode }
                                            value={ objSucursal?.email || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        />
                                    </div>
                                    <div className='latitud'>
                                        Latitud GPS:
                                        <input
                                            style={{ 
                                                backgroundColor: editMode ? '#ffffff' : 'transparent',
                                                border: `${objErrores.latitud ? '2px solid ' + colores.color5 : 'none'}`
                                            }}
                                            autoComplete='off'
                                            type="text"
                                            placeholder='Introduzca latitud GPS'
                                            name="lat"
                                            disabled={ !editMode }
                                            value={ objSucursal?.lat || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        />
                                    </div>
                                    <div className='longitud'>
                                        Longitud GPS:
                                        <input
                                            style={{ 
                                                backgroundColor: editMode ? '#ffffff' : 'transparent',
                                                border: `${objErrores.longitud ? '2px solid ' + colores.color5 : 'none'}`
                                            }}
                                            autoComplete='off'
                                            type="text"
                                            placeholder='Introduzca longitud GPS'
                                            name="long"
                                            disabled={ !editMode }
                                            value={ objSucursal?.long || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        />
                                    </div>
                                    <div className='longitud'>
                                        Merchant Id:
                                        <input
                                            style={{ 
                                                backgroundColor: editMode ? '#ffffff' : 'transparent',
                                                border: `'2px solid ' + colores.color5`
                                            }}
                                            autoComplete='off'
                                            type="text"
                                            placeholder='Código comercio'
                                            name="merchantId"
                                            disabled={ !editMode }
                                            value={ objSucursal?.merchantId || '' }
                                            onChange={ (e) => onFormChange( e )}
                                        />
                                    </div>
                                </div>
                                <div className='profesionales-sucursal'>
                                    <h3 style={{ color: colores.colorSecundario }}>Profesionales</h3>
                                    <div className='profesionals-row'>
                                        {
                                            profs.map( prof => (
                                                <AvatarProfesional
                                                    key={ prof._id }
                                                    prof={ prof }
                                                    clase={ `prof-item` }
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h3 className='rotulo' style={{ color: colores.colorSecundario }}>Horarios</h3>
                        <div className='sucursal-tabs'>
                            <div 
                                className={ `sucursal-tab ${ activeTab === 'semanal' && 'active' }` }
                                style={{ 
                                    color: colores.colorPrincipal,
                                    backgroundColor: activeTab === 'semanal' ? colores.colorSecundario : colores.color6
                                }}
                                onClick= { () => setActiveTab('semanal') }
                            >
                                <span className='tab-icon'></span>
                                <p>Semanal</p>
                            </div>                            
                            <div 
                                className={ `sucursal-tab ${ activeTab === 'vacacional' && 'active' }` }
                                style={{ 
                                    color: colores.colorPrincipal,
                                    backgroundColor: activeTab === 'vacacional' ? colores.colorSecundario : colores.color6
                                }}
                                onClick= { () => setActiveTab('vacacional') }
                            >
                                <span className='tab-icon'></span>
                                <p>Periodo vacacional</p>
                            </div>                            
                            <div 
                                className={ `sucursal-tab ${ activeTab === 'festivos' && 'active' }` }
                                style={{ 
                                    color: colores.colorPrincipal,
                                    backgroundColor: activeTab === 'festivos' ? colores.colorSecundario : colores.color6
                                }}
                                onClick= { () => setActiveTab('festivos') }
                            >
                                <span className='tab-icon'></span>
                                <p>Festivos</p>
                            </div>                            
                        </div>
                        <div className='sucursal-secondary'>
                            {
                                activeTab === 'semanal' &&
                                <Semanal
                                    editMode={ editMode }
                                    horario={ objSucursal?.horarioTxt }
                                    changeHorario={ changeHorario }
                                />
                            }
                            {
                                activeTab === 'vacacional' &&
                                <Vacacional
                                    editMode={ editMode }
                                    sucursal={ objSucursal }
                                />
                            }
                            {
                                activeTab === 'festivos' &&
                                <Festivos
                                    editMode={ editMode }
                                    sucursal={ objSucursal }
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalEliminarSucursal
            data = { dataModalEliminar }
            setModalResponse = { deleteSucursalResponse }
            modalOpened = { modalEliminarSucursalOpened }
            setModalOpened = { setModalEliminarSucursalOpened }
        />
        </>
    )
}
