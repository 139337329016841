import React from 'react';
import { useSelector } from 'react-redux';
import { AvatarProfesional } from './AvatarProfesional';
import { SucursalRowSelect } from './SucursalRowSelect'

export const SucursalRowItem = ({sucursal, index, editSucursal, deleteSucursal, profesionales }) => {
    const { colores } = useSelector(state => state.ui); 
    
    const clickEdit = (id) => {
        editSucursal( sucursal );
    };

    const clickDelete = (id) => {
        deleteSucursal(id, sucursal.nombre);
    };

    return (
        <>
        {/* <tr style={{ backgroundColor: colores.color6 }}>
            <td>
                <div className='cell-container'>
                    <p className='title'>Sucursal</p>
                    <div className='content'>{ index + 1 }</div>
                </div>
            </td>
            <td>
                <div className='cell-container'>
                    <p className='title'>Localización</p>
                    <div className='content'>{ sucursal.direccion } - { sucursal.poblacion }</div>
                </div>
            </td>
            <td>
                <div className='cell-container'>
                    <p className='title'>Horarios</p>
                    <div className='content'>MA, MI, JU, VI ,SA <span className='horarios' style={{ color: colores.colorPrincipal }}>9h-14h y 16h-20h</span></div>
                </div>
            </td>
            <td>
                <div className='cell-container'>
                    <p className='title'>Profesionales</p>
                    <div className='content'>
                        <div className='profesionals'>
                            {
                                profesionales.map( prof => (
                                    <AvatarProfesional
                                        key={ prof._id }
                                        prof={ prof }
                                        clase={ `profesional` }
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <div className='cell-container'>
                    <SucursalRowSelect
                        sucursalId={sucursal._id}
                        clickEdit={clickEdit}
                        clickDelete={clickDelete}
                    />
                </div>
            </td>
        </tr> */}
        <div 
            className='table-row'
            key={ index + 1 }
            style={{ backgroundColor: colores.color6 }}
            onClick={ () => clickEdit( sucursal._id )}
        >
            <div className='table-item'>
                <div className='item-title'>Sucursal</div>
                <div className='item-content'>
                    <p>{ sucursal.nombre }</p>
                </div>
            </div>
            <div className='table-item'>
                <div className='item-title'>Localización</div>
                <div className='item-content'>
                    <p>{ sucursal.direccion } - { sucursal.poblacion }</p>
                </div>
            </div>
            <div className='table-item'>
                <div className='item-title'>Horarios</div>
                <div className='item-content'>
                    <p>MA, MI, JU, VI, SA <span className='horarios' style={{ color: colores.colorPrincipal }}>9h-14h y 16h-20h</span></p>
                </div>
            </div>
            <div className='table-item'>
                <div className='item-title'>Profesionales</div>
                <div className='item-content'>
                    <div className='profesionals'>
                    {
                        profesionales.map( prof => (
                            <AvatarProfesional
                                key={ prof._id }
                                prof={ prof }
                                clase={ `profesional` }
                            />
                        ))
                    }
                    </div>
                </div>
            </div>
            <div className='table-item table-btn'>
                <div className='item-title'></div>
                <div className='item-content'>
                    <SucursalRowSelect
                        sucursalId={sucursal._id}
                        clickEdit={clickEdit}
                        clickDelete={clickDelete}
                    />
                </div>
            </div>
        </div>
        </>
    )
}
