import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { startLoadingVacacionesSucursal, startNewVacaciones, vacacionesStartDelete } from '../../../actions/vacaciones';
import { SaveIcon, TrashIcon } from '../../../assets/svg/iconos';
import { VacacionalCalendar } from '../../profesionales/components/descansos/VacacionalCalendar';

export const Vacacional = ({editMode, sucursal}) => {
    
    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { vacaciones } = useSelector(state => state.vacaciones);
    const { colores } = useSelector(state => state.ui);

    let initDate = new Date();
    let endDate = new Date();
    initDate.setHours(0,0,0,0);
    endDate.setHours(23,59,0,0);

    const [newItem, setNewItem] = useState(false);
    const [delValue, setDelValue] = useState([initDate, endDate]);

    useEffect(() => {
        dispatch( startLoadingVacacionesSucursal( empresa, sucursal ) );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, empresa]);

    const resetForm = () => {
        initDate = new Date();
        endDate = new Date(); 
        initDate.setHours(0,0,0,0);
        endDate.setHours(23,59,0,0);
        setDelValue([initDate, endDate]);
    }

    const cancelNewItem = () => {
        initDate.setHours(0,0,0,0);
        endDate.setHours(23,59,0,0);
        setNewItem( false );
        setDelValue( [initDate, endDate] );
    }

    const saveNewItem = () => {
        setNewItem( false );
        resetForm();
        const newVacaciones = {
            empresa,
            sucursal: sucursal._id,
            fechaInicio: moment.utc( delValue[0] ).toISOString(),
            fechaFin: moment.utc( delValue[1] ).toISOString(),
        }
        dispatch( startNewVacaciones( newVacaciones ) );
    };

    const deleteItem = ( id ) => {
        console.log( id );
        dispatch( vacacionesStartDelete( id, empresa ) );
    }

    return (
        <div className='vacacional'>
            {
                vacaciones.map( vacacion => (
                    (!vacacion.deleted && !vacacion.festivo) &&
                    <div
                        key={ vacacion._id }
                        className='vacacional-item' style={{ backgroundColor: colores.color6 }}>
                        <div className='new-item-row'>
                            <VacacionalCalendar
                                editMode={ false }
                                value={ [vacacion.fechaInicio, vacacion.fechaFin] }
                                onChange={ setDelValue }
                            />
                            <div className='botonera'>
                                {
                                    editMode &&
                                    <div
                                        className='btn delete'
                                        onClick={ () => deleteItem( vacacion._id ) }
                                    ><TrashIcon /></div>
                                }
                            </div>
                        </div>
                    </div>                
                ))
            }

            {
                newItem 
                ?
                <div className={`vacacional-item`} style={{ backgroundColor: colores.color6 }}>
                    <div className='new-item-row'>
                        <VacacionalCalendar
                            editMode={ true }
                            value={ delValue }
                            onChange={ setDelValue }
                        />
                        <div className='botonera'>
                            <div 
                                className='btn save'
                                onClick={ saveNewItem }
                            ><SaveIcon /></div>   
                            <div
                                className='btn delete'
                                onClick={ cancelNewItem }
                            ><TrashIcon /></div>
                        </div>
                    </div>
                </div>
                :
                <div className='vacacional-item' style={{ backgroundColor: colores.color6 }}>
                    <p
                        className='new-item-txt'
                         style={{ color: colores.colorPrincipal }}
                        onClick={ () => setNewItem(true) }
                    >+ Añadir periodo vacacional</p>
                </div>
            }
            
        </div>
    )
}
