import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startSavePedido } from '../../../actions/pedidos';
import { ModalEntregarProducto } from '../modales/ModalEntregarProducto';
import { ProductosViewRow } from './ProductosViewRow';

export const ProductosView = () => {
    const dispatch = useDispatch();
    const { pedidos } = useSelector(state => state.pedidos);

    const [modalEntregarProductoOpened, setModalEntregarProductoOpened] = useState(false);
    const [dataModalEntregarProducto, setDataModalEntregarProducto] = useState({
        title: 'Producto entregado',
        text: ['Has seleccionado entregar el producto X del producto XX.', 'En caso de que todo el pedido haya sido entregado desaparecerá de tu lista de pedidos', '¿Quieres confirmar esta acción?'],
        buttonText: 'Confirmar',
        button2Text: 'Cancelar'
    });  

    const [pedidoSelectedId, setPedidoSelectedId] = useState('');
    const [productoSelectedId, setProductoSelectedId] = useState('');

    const entregarProductoModal = ( pedido, producto ) => {
        console.log( producto );
        setPedidoSelectedId( pedido._id );
        setProductoSelectedId( producto._id );
        if( !producto.entregado ){
            setDataModalEntregarProducto({
                ...dataModalEntregarProducto,
                text: [`Has seleccionado entregar el producto ${ producto.titulo } del pedido nº ${ pedido.numeroPedido }.`, 'En caso de que todo el pedido haya sido entregado, desaparecerá de tu lista de pedidos', '¿Quieres confirmar esta acción?'],
            })
            setModalEntregarProductoOpened( true );
        }
    };

    const entregarProductoResponse = ( value ) => {
        if( value === 1 && productoSelectedId !== '' && pedidoSelectedId !== '' ){

            const pedido = pedidos.filter( pedido => pedido._id === pedidoSelectedId )[0];           

            const newLineas = pedido.productos.map( pedidoLinea => {
                if( productoSelectedId === pedidoLinea.producto._id ){
                    if( !pedidoLinea.entregado ) {
                        pedidoLinea.entregado = !pedidoLinea.entregado;
                    }
                }
                return pedidoLinea;
            });
            const newPedido = {
                ...pedido,
                productos: newLineas
            }
            console.log( newPedido );
            newPedido.usuario = pedido.usuario._id;
            dispatch( startSavePedido( newPedido ) );
        }
    }

    return (
        <>
        <div className='reservas-productos'>
            <div className='productos-table'>
                <div className='headers-row'>
                    <div className='col col-pedido'>Pedido</div>
                    <div className='col col-cliente'>Cliente</div>
                    <div className='col col-producto'>Producto</div>
                    <div className='col col-referencia'>Referencia</div>
                    <div className='col col-unidades center'>Unidades</div>
                    <div className='col col-total'>Total</div>
                    <div className='col col-pago center'>Pago</div>
                    <div className='col col-entregado center'>Entregado</div>
                    <div className='col col-btns'></div>
                </div>
                {
                    pedidos.map( pedido => (
                        pedido.productos.length > 0 &&
                        <ProductosViewRow
                            key={ pedido._id }
                            pedido={ pedido }
                            entregarProducto={ entregarProductoModal }
                        />
                    ))
                }
            </div>
        </div>
        <ModalEntregarProducto
            data = { dataModalEntregarProducto }
            setModalResponse = { entregarProductoResponse }
            modalOpened = { modalEntregarProductoOpened }
            setModalOpened = { setModalEntregarProductoOpened }
        />
        </>
    )
}
