import { useCallback, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';
import useClickOutside from '../../../../hooks/useClickOutside';

export const SelectVariante = ({objServicio, dataChange, big = false, top = false, left = false}) => {
    const { variantes } = useSelector(state => state.variantes);

    const [selectText, setSelectText] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        // console.log( variantes );
        // console.log( objServicio );
        if( variantes.length > 0 ){
            if( objServicio?.variantes ){
                let seleccionadas = 0;
                variantes.forEach( variante => {
                    if( objServicio.variantes.includes( variante._id ) || variante.deleted ){
                        seleccionadas++;
                    }
                });
                if( seleccionadas === 0 ){
                    setSelectText( 'Ninguna' );
                } else if( seleccionadas === variantes.length ){
                    setSelectText( 'Todas' );
                } else {
                    setSelectText( 'Varias' );
                }
            }
        } else {
            setSelectText( 'No existen' );
        }
    }, [objServicio, variantes]);
    

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    const clickVariante = ( idVariante ) => {
        let newVariantes = [];
        if( objServicio.variantes.includes( idVariante ) ){
            newVariantes = objServicio.variantes.filter( suc => {
                return suc !== idVariante;
            });
        } else {
            newVariantes = [
                ...objServicio.variantes,
                idVariante
            ]
        }
        dataChange('variantes', newVariantes );
    }

    return (
        <div 
            className='ylm-sucursal-select'
        >
            <div 
                className='select-main'
                onClick={ clickSelect }
            >
                <p>{ selectText }</p>
                <ChevronDownIcon color={ '#000000' }/>
            </div>
            {
                isOpen &&
                <ul 
                    className={`select-options std-ul ${big && 'big'} ${top && 'top'} ${left && 'left'}`}
                    ref={popover}
                >
                    {
                        variantes.map( sucursal => (
                            !sucursal.deleted &&
                            <li
                                key={ sucursal._id }
                                className={`std-li ${ objServicio?.variantes?.includes( sucursal._id ) ? 'active' : ''}`}
                                onClick={ () => clickVariante( sucursal._id )}
                            >
                                <div className='checkbox-bg'>
                                    <span className='checkbox-item'></span>
                                </div>
                                <p>{ sucursal.nombre }</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
