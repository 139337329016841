import React, { useRef, useState } from 'react'
import { ListManager } from 'react-beautiful-dnd-grid';
import { useDispatch, useSelector } from 'react-redux';
import { productosReorder, productoStartDelete } from '../../../actions/productos';
import { DndIcon } from '../../../assets/svg/iconos';
import { ModalEliminarProducto } from './modales/ModalEliminarProducto';
import { ProductoCard } from './productos/ProductoCard';

export const Productos = ({ history, productosList, setProductosList }) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { colores } = useSelector(state => state.ui);
    const wrapper = useRef();

    const [modalEliminarProductoOpened, setModalEliminarProductoOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar servicio?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [selectedId, setSelectedId] = useState('');
    const [columnas, ] = useState(5);

    const [activeDnd, setActiveDnd] = useState(false);
    
    const editProducto = ( producto ) => {
        history.push({
            pathname: `/producto/${producto._id}`,
            state: {
                producto
            }
        });
    };

    /* const deleteProducto = ( producto ) => {
        console.log( 'deleteProducto', producto );
    }  */   

    const deleteProductoModal = ( producto ) => {
        setSelectedId( producto._id || producto._id );
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar ${producto.titulo}?`]
        })
        setModalEliminarProductoOpened( true );
    };

    const deleteProductoResponse = ( value ) => {
        if( value === 1 && selectedId !== '' ){
            console.log( 'Borrar id:', selectedId );
            dispatch( productoStartDelete( selectedId, empresa ) );
        }
    }

    const noop = (oldPos, newPos) => {
        const itemMoved = productosList[oldPos];
        const newList = productosList.map( producto => {
            if( producto._id === itemMoved._id ){
                producto.orden = newPos;
            }
            if( newPos < oldPos ){
                if( producto._id !== itemMoved._id && producto.orden < oldPos && producto.orden >= newPos ){
                    producto.orden = producto.orden + 1;
                }
            }
            if( newPos > oldPos ){
                if( producto._id !== itemMoved._id && producto.orden > oldPos && producto.orden <= newPos ){
                    producto.orden = producto.orden - 1;
                }
            }
            return producto;
        });

        const sortByOrden = (a,b) => {
            if( a.orden > b.orden ) {
                return 1;
            }
            if( a.orden < b.orden ) {
                return -1;
            } else {
                return 0;
            }
        }

        newList.sort( sortByOrden );
        setProductosList( newList );
        dispatch( productosReorder( newList, empresa ));
    };

    const toggleDnd = () => {
        setActiveDnd( !activeDnd );
    }

    return (
        <>
        {
            !activeDnd &&
            <div 
                className='productos'
                style={{
                    '--colorPrincipal': colores.colorPrincipal,
                    '--colorSecundario': colores.colorSecundario,
                    '--color3': colores.color3,
                    '--color4': colores.color4,
                    '--color5': colores.color5,
                    '--color6': colores.color6,
                    '--color7': colores.color7,
                }}
            >
                <div className='productos-grid'>
                    {
                        productosList.map( producto => (
                            !producto.deleted &&
                            <ProductoCard
                                key={ producto._id }
                                producto={ producto }
                                editProducto={ editProducto }
                                deleteProducto={ deleteProductoModal }
                            />
                        ))
                    }
                </div>
            </div>
        }
        {
            activeDnd
            &&
            <div className='productos'>
                <div className='productos-grid dnd' ref={wrapper}>
                    <ListManager
                        items={productosList}
                        direction="horizontal"
                        maxItems={columnas}
                        render={producto => !producto.deleted && 
                            <ProductoCard
                                key={ producto._id }
                                producto={ producto }
                                editProducto={ editProducto }
                                deleteProducto={ deleteProductoModal }
                            />
                        }
                        onDragEnd={noop}
                    />
                </div>
            </div>
        }
        <div 
            className={ `toggle-dnd ${activeDnd ? 'active' : 'unactive'}`}
            onClick={ toggleDnd }
        ><DndIcon color={ activeDnd ? 'white' : colores.colorPrincipal } /><p>Drag & Drop</p></div>
        <ModalEliminarProducto
            data = { dataModalEliminar }
            setModalResponse = { deleteProductoResponse }
            modalOpened = { modalEliminarProductoOpened }
            setModalOpened = { setModalEliminarProductoOpened }
        />
        </>
    )
}
