import { coloresBase } from "../helpers/colors";
import { types } from "../types/types";

const modalResetData = {
    title: '',
    text: [],
    buttonText: ''
}

const modalBottomResetData = {
    text: []
}

const initialState = {
    loading: false,
    darkHeader: false,
    adminHeader: true,
    msgError: null,
    menuOpened: false,
    menuLateral: 'reservas',
    miniLateral: true,
    loginOpened: false,
    registerOpened: false,
    renewOpened: false,
    modalOpened: false,
    loadingOpened: false,
    modalData: modalResetData,
    modalBottomOpened: false,
    modalBottomData: modalBottomResetData,
    date: new Date(),
    language: 'es',
    modalVisible: false,
    buttonVisible: true,
    bodyClass: '',
    version: '1.0.0',
    colores: coloresBase
}

export const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.uiSetTitle:
            return {
                ...state,
                title: action.payload
            }

        case types.uiBodyClass:
            return {
                ...state,
                bodyClass: action.payload
            }

        case types.uiSetDarkHeader:
            return {
                ...state,
                darkHeader: action.payload
            }

        case types.uiSetAdminHeader:
            return {
                ...state,
                adminHeader: action.payload
            }

        case types.uiSetError:
            return {
                ...state,
                msgError: action.payload
            }

        case types.uiRemoverror:
            return {
                ...state,
                msgError: null
            }

        case types.uiStartLoading:
            return {
                ...state,
                loading: true
            }

        case types.uiFinishLoading:
            return {
                ...state,
                loading: false
            }

        case types.uiToggleMenu:
            return {
                ...state,
                menuOpened: action.payload
            }

        case types.uiOpenLogin:
            return {
                ...state,
                loginOpened: true
            }

        case types.uiCloseLogin:
            return {
                ...state,
                loginOpened: false
            }

        case types.uiOpenRegister:
            return {
                ...state,
                registerOpened: true
            }

        case types.uiCloseRegister:
            return {
                ...state,
                registerOpened: false
            }

        case types.uiOpenRenew:
            return {
                ...state,
                renewOpened: true
            }

        case types.uiCloseRenew:
            return {
                ...state,
                renewOpened: false
            }

        case types.uiOpenModal:
            return {
                ...state,
                modalOpened: true,
                modalData: action.payload
            }

        case types.uiCloseModal:
            return {
                ...state,
                modalOpened: false,
                modalData: modalResetData
            }

        case types.uiOpenLoading:
            return {
                ...state,
                loadingOpened: true
            }

        case types.uiCloseLoading:
            return {
                ...state,
                loadingOpened: false
            }

        case types.uiOpenModalBottom:
            return {
                ...state,
                modalBottomOpened: true,
                modalBottomData: action.payload
            }

        case types.uiCloseModalBottom:
            return {
                ...state,
                modalBottomOpened: false,
                modalBottomData: modalBottomResetData
            }

        case types.uiClockUpdate:
            return {
                ...state,
                date: action.payload
            }

        case types.uiSetLanguage:
            return {
                ...state,
                language: action.payload
            }

        case types.uiSetMenuLateral:
            return {
                ...state,
                menuLateral: action.payload
            }

        case types.uiSetMiniLateral:
            return {
                ...state,
                miniLateral: action.payload
            }

        case types.uiSetVersion:
            return {
                ...state,
                version: action.payload
            }

        case types.uiModalShow:
            return {
                ...state,
                modalVisible: action.payload
            }

        case types.uiSetColores:
            return {
                ...state,
                colores: initialState.colores
            }


        default:
            return state;
    }
}