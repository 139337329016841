import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startSaveEmpresa } from '../../actions/empresas';
import { setMenuLateral } from '../../actions/ui';
import { Ayuda } from './components/Ayuda';
import { General } from './components/General'
import { Idiomas } from './components/Idiomas';
import { Lateral } from './components/Lateral'
import { Legal } from './components/Legal';
import { Personalizacion } from './components/Personalizacion';
import { Reservas } from './components/Reservas';

export const Configuracion = ({ history }) => {
    const dispatch = useDispatch();
    const { empresas } = useSelector(state => state.empresas);
    const { colores } = useSelector(state => state.ui);

    const [objEmpresa, setObjEmpresa] = useState({});
    const [activeComponent, setActiveComponent] = useState('general');
    const [iniciado, setIniciado] = useState(false);
    const [cambiado, setCambiado] = useState(false);

    useEffect(() => {
        if (empresas.length > 0) {
            setObjEmpresa(empresas[0]);
            setIniciado(true);
        }
    }, [empresas]);

    useEffect(() => {
        const nEmpresa = { ...empresas[0] };
        if (iniciado) {
            let igual = true;
            for (const propiedad in objEmpresa) {
                if (nEmpresa[propiedad] !== objEmpresa[propiedad]) {
                    igual = false;
                    //console.log(`Cambio en ${propiedad}`);
                }
            }
            setTimeout(()=>setCambiado(!igual),2000);
        }

    }, [iniciado, empresas, objEmpresa]);


    useEffect(() => {
        dispatch(setMenuLateral('configuracion'));
    }, [dispatch]);

    const guardar = () => {
        const objToSave = { ...objEmpresa };
        delete objToSave.smtpPass;
        dispatch(startSaveEmpresa(objToSave));
    }
    const cancelar = () => {
        setObjEmpresa(empresas[0]);
    }

    return (
        <div
            className='configuracion'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <Lateral
                setActiveComponent={setActiveComponent}
            />
            <div className='principal'>
                {activeComponent === 'general' &&
                    <General
                        objEmpresa={objEmpresa}
                        setObjEmpresa={setObjEmpresa}
                        cambiado={cambiado}
                        guardar={guardar}
                        cancelar={cancelar}
                    />
                }
                {activeComponent === 'reservas' &&
                    <Reservas
                        objEmpresa={objEmpresa}
                        setObjEmpresa={setObjEmpresa}
                        cambiado={cambiado}
                        guardar={guardar}
                        cancelar={cancelar}
                    />
                }
                {activeComponent === 'personalizacion' &&
                    <Personalizacion
                        objEmpresa={objEmpresa}
                        setObjEmpresa={setObjEmpresa}
                        cambiado={cambiado}
                        guardar={guardar}
                        cancelar={cancelar}
						setCambiado={setCambiado}
                    />
                }
                {activeComponent === 'idiomas' &&
                    <Idiomas
                        objEmpresa={objEmpresa}
                        setObjEmpresa={setObjEmpresa}
                        cambiado={cambiado}
                        guardar={guardar}
                        cancelar={cancelar}
                    />
                }
                {activeComponent === 'ayuda' &&
                    <Ayuda
                        objEmpresa={objEmpresa}
                        setObjEmpresa={setObjEmpresa}
                        cambiado={cambiado}
                        guardar={guardar}
                        cancelar={cancelar}
                    />
                }
                {activeComponent === 'legal' &&
                    <Legal
                        objEmpresa={objEmpresa}
                        setObjEmpresa={setObjEmpresa}
                        cambiado={cambiado}
                        guardar={guardar}
                        cancelar={cancelar}
                    />
                }
            </div>
        </div>
    )
}
