import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { AddSucursalSelect } from './horario/AddSucursalSelect'
import { SucursalItem } from './horario/SucursalItem';
import { openModalBottom } from '../../../actions/ui';
//import { procesaHorarios } from './horario/helpersHorario';

const timezone = process.env.REACT_APP_TIMEZONE;

export const Horario = ({ currentProfesional, addSucursalValue, setAddSucursalValue, addSucursal, deleteSucursal, objProfesional, setObjProfesional, horasOcupadas, setHorasOcupadas, editMode }) => {
  const dispatch = useDispatch();

  const { colores } = useSelector(state => state.ui);
  const [sucursalesProf, setSucursalesProf] = useState([]);
  const [showHide, setShowHide] = useState([]);

  useEffect(() => {
    if (objProfesional?.horarioSucursales) {
      setSucursalesProf([...objProfesional.horarioSucursales]);
    }
  }, [objProfesional]);

  const showSucursal = (id) => {
    if (showHide.includes(id)) {
      const newArray = showHide.filter(item => item !== id);
      setShowHide(newArray);
    } else {
      setShowHide([...showHide, id]);
    }
  }

  const addHorario = (newHorario) => {
    // console.log( 'newHorario', newHorario );
    if (editMode) {
      const newHoras = objProfesional.horarioSucursales.map((horarioSucursal) => {
        const idSucursal = horarioSucursal.sucursal._id ? horarioSucursal.sucursal._id : horarioSucursal.sucursal;
        if (newHorario.sucursal === idSucursal) {
          horarioSucursal.horarios.map(horarioDia => {
            if (newHorario.diasSemana === horarioDia.diasSemana) {
              horarioDia.horas = newHorario.newHoras;
            }
            return horarioDia;
          });
        }
        return horarioSucursal;
      });

      setObjProfesional({
        ...objProfesional,
        horarioSucursales: newHoras
      });
    } else {
      dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
    }
  }

  const deleteHorario = (idSucursal, idxDia, idxHora) => {
    // console.log( idSucursal, idxDia, idxHora );
    if (editMode) {
      const newHoras = objProfesional.horarioSucursales.map((horarioSucursal) => {
        const idxSucursal = horarioSucursal.sucursal._id ? horarioSucursal.sucursal._id : horarioSucursal.sucursal;
        if (idxSucursal === idSucursal) {
          horarioSucursal.horarios[idxDia].horas.splice(idxHora, 1);
        }
        return horarioSucursal;
      });

      setHorarioSucursales(newHoras);
    } else {
      dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
    }
  };

  const setHorarioSucursales = (horario) => {
    setObjProfesional({
      ...objProfesional,
      horarioSucursales: horario
    });
  };

  const changeHoras = (arrayHoras) => {
    //console.log( 'changeHoras arrayHoras', arrayHoras );
    const idxDia = arrayHoras.idxDay;
    const idxHora = arrayHoras.idxHora;
    let newHorarioSucursales = _.clone(objProfesional.horarioSucursales);
    newHorarioSucursales.map((sucursal) => {
      let newSucursalHorarios = [];
      const idCurrentSucursal = sucursal.sucursal._id ? sucursal.sucursal._id : sucursal.sucursal;
      if (arrayHoras.sucursal === idCurrentSucursal) {
        newSucursalHorarios = sucursal.horarios.map((horario, idxDia2) => {
          let newHorario = {
            diasSemana: horario.diasSemana
          };
          let newHoras = horario.horas.map((hora, idxHora2) => {
            let newHora = hora;
            if (idxDia === idxDia2 && idxHora2 === idxHora) {
              const stringHoraInicio = moment.tz(hora.horaInicio, timezone).format('YYYY-MM-DD');
              const stringHoraFin = moment.tz(hora.horaFin, timezone).format('YYYY-MM-DD');
              newHora = {
                horaInicio: moment.tz(`${stringHoraInicio} ${arrayHoras.newHoras[0]}`, timezone).toISOString(),
                horaFin: moment.tz(`${stringHoraFin} ${arrayHoras.newHoras[1]}`, timezone).toISOString()
              }
            }
            return newHora;
          });
          if (idxDia === idxDia2) {
            //console.log( 'newHoras', newHoras );
          }
          newHorario.horas = newHoras;
          return idxDia === idxDia2 ? newHorario : horario;
        });
        sucursal.horarios = newSucursalHorarios;
      }
      return sucursal;
    });

    setObjProfesional({
      ...objProfesional,
      horarioSucursales: newHorarioSucursales
    });
  }


  return (
    <div className='horario'>
      {
        editMode &&
        <div
          className='add-row'
          style={{ backgroundColor: colores.color6 }}
        >
          <p style={{ color: colores.colorPrincipal }}>Añadir sucursal</p>
          <AddSucursalSelect
            sucursalesProf={sucursalesProf}
            setAddSucursalValue={setAddSucursalValue}
            addSucursal={addSucursal}
          />
        </div>
      }
      <div className='horario-sucursales'>
        {
          objProfesional?.horarioSucursales?.map((item) => (
            !item.sucursal?.deleted &&
            <SucursalItem
              key={item.sucursal?._id || item.sucursal}
              horario={item}
              sucursal={item.sucursal}
              showHide={showHide}
              showSucursal={showSucursal}
              deleteSucursal={deleteSucursal}
              addHorario={addHorario}
              deleteHorario={deleteHorario}
              editMode={editMode}
              objProfesional={objProfesional}
              setObjProfesional={setObjProfesional}
              horasOcupadas={horasOcupadas}
              setHorasOcupadas={setHorasOcupadas}
              changeHoras={changeHoras}
            />
          ))
        }
      </div>
    </div>
  )
}
