import React from 'react';
import { EmpresaRowSelect } from './EmpresaRowSelect';

export const EmpresaRowItem = ({empresa, editCliente, deleteCliente}) => {
    
    const clickEdit = (id) => {
        editCliente(empresa);
    };

    const clickDelete = (id) => {
        deleteCliente(id, empresa.nombre);
    };    
    

  return (
    <div
        className='table-row cliente-row'
        onClick={ () => clickEdit( empresa._id )}
    >
        <div className='column name'>
            <p>{ empresa.nombre }</p>
        </div>
        <div className='column phone'>
            <p>{ empresa.telefono }</p>
        </div>
        <div className='column email'>
            <p>{ empresa.email }</p>
        </div>
        <div className='column sector'>
            <p>{ empresa.sector.nombre }</p>
        </div>
        <div className='column plan'>
            <p>{ empresa.plan.nombre }</p>
        </div>
        <div className='column btns'>
            <EmpresaRowSelect
                clienteId={empresa._id}
                clickEdit={clickEdit}
                clickDelete={clickDelete}
            />
        </div>
    </div>
  )
}
