import React, { useEffect, useRef, useState } from 'react';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const IdiomaDropdown = ({ currentLang, langName, classes, businessLangs, onChange }) => {

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const showDropdown = () => {
        if( !currentLang.fav ) {
            setVisibility(true);
        }
        
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = ( selected ) => {
        hideDropdown();
        onChange( currentLang, selected );
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

    return (
        <div className={ `ylm-select ${classes}`}>
            <div 
                className={`ylm-select-header ${ currentLang.active ? 'active' : 'unactive' } ${currentLang.fav && 'no-click'}`}
                onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
            >
                <p>{ currentLang.active ? 'Publicado' : 'Borrador' }</p>
                <span><ChevronDownIcon /></span>
            </div>
            <div ref={dropdownMenuRef} className={`ylm-select-options ${ visibility ? 'active' : '' }`}>
                <div 
                    className="select-option"
                    onClick={ () => selectItem( true ) }
                >
                    <p>Publicado</p>
                </div>
                <div 
                    className="select-option"
                    onClick={ () => selectItem( false ) }
                >
                    <p>Borrador</p>
                </div>
            </div>
        </div>
    )
}
