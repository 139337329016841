import { useCallback, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';
import useClickOutside from '../../../../hooks/useClickOutside';

export const SelectSucursal = ({objServicio, dataChange, editMode, big = false, top = false, left = false}) => {
    const { sucursales } = useSelector(state => state.sucursales);

    const [selectText, setSelectText] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        /* console.log( sucursales );
        console.log( objServicio ); */
        if( objServicio?.sucursales ){
            let seleccionadas = 0;
            sucursales.forEach( sucursal => {
                if( objServicio.sucursales.includes( sucursal._id ) || sucursal.deleted ){
                    seleccionadas++;
                }
            });
            if( seleccionadas === 0 ){
                setSelectText( 'Ninguna' );
            } else if( seleccionadas === sucursales.length ){
                setSelectText( 'Todas' );
            } else {
                setSelectText( 'Varias' );
            }
        }
    }, [objServicio, sucursales]);
    

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    const clickSucursal = ( idSucursal ) => {
        let newSucursales = [];
        if( objServicio.sucursales.includes( idSucursal ) ){
            newSucursales = objServicio.sucursales.filter( suc => {
                return suc !== idSucursal;
            });
        } else {
            newSucursales = [
                ...objServicio.sucursales,
                idSucursal
            ]
        }
        dataChange('sucursales', newSucursales );
    }

    return (
        <div 
            className='ylm-sucursal-select'
        >
            <div 
                className='select-main'
                onClick={ clickSelect }
            >
                <p>{ selectText }</p>
                <ChevronDownIcon color={ '#000000' }/>
            </div>
            {
                isOpen &&
                <ul
                    className={`select-options std-ul ${big && 'big'} ${top && 'top'} ${left && 'left'}`}
                    ref={popover}
                >
                    {
                        sucursales.map( sucursal => (
                            !sucursal.deleted &&
                            <li
                                key={ sucursal._id }
                                className={`std-li ${objServicio?.sucursales.includes( sucursal._id ) ? 'active' : ''}`}
                                onClick={ () => clickSucursal( sucursal._id )}
                            >
                                <div className='checkbox-bg'>
                                    <span className='checkbox-item'></span>
                                </div>
                                <p>{ sucursal.nombre }</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
