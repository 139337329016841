
import { types } from "../types/types";

const initialState = {
    horarios: [],
    active: null
}

export const horariosReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.horariosActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.horariosUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.horariosAddNew:
            return {
                ...state,
                horarios: [ ...state.horarios, action.payload ]
            }

        case types.horariosLoad:
            return {
                ...state,
                horarios: [ ...action.payload ]
            }
        
        case types.horariosUpdated:
            return {
                ...state,
                horarios: state.horarios.map(
                    horario => horario._id === action.payload._id
                        ? action.payload
                        : horario
                )
            }

        case types.horariosDelete:
            return {
                ...state,
                active: null,
                horarios: state.horarios.filter( horario => horario._id !== action.payload )
            }
    
        case types.horariosLogoutCleaning:
            return {
                ...state,
                active: null,
                horarios: []
            }
    

        default:
            return state;
    }
}