import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModalBottom } from '../../../actions/ui';
import { ChevronDownIcon, DelayIcon } from '../../../assets/svg/iconos';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const DelaySelect = ({ delayType, delay, setDelay, editMode }) => {

    const dispatch = useDispatch();

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();    

    const showDropdown = () => {
        if( editMode ){ 
            setVisibility(true);
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = ( cmd ) => {
        if( cmd === 'Sin delay' ){
            setDelay( 'time', '0' );
        }
        if( cmd === 'Minutos' ){
            setDelay( 'time', '5' );
        }
        if( cmd === 'Porcentaje' ){
            setDelay( 'percent', '10' );
        }
        
        hideDropdown();
        
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );
  return (
      <>
    
    <div 
        className={ `delay`}
        onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
    >
        <DelayIcon />
        <div className='delay-select'>
            <p
                className={ `${(delayType === 'time' && delay === '0') && 'underline'}`}
            >{ (delayType === 'time' && delay === '0') ? 'Añadir delay' : delayType === 'time' ? 'Minutos' : 'Porcentaje'}</p>
            <span><ChevronDownIcon color={ '#000000' } /></span>
            <ul
                ref={ dropdownMenuRef }
                className={`delay-options ${ visibility ? 'active' : '' }`}
            >
                <li
                    onClick={ () => selectItem('Sin delay')}
                ><span className={`option-check ${(delayType === 'time' && delay === '0') ? 'active' : ''}`}><span></span></span><p>Sin delay</p></li>
                <li
                    onClick={ () => selectItem('Minutos')}
                ><span className={`option-check ${(delayType === 'time' && delay !== '0') ? 'active' : ''}`}><span></span></span><p>Minutos</p></li>
                <li
                    onClick={ () => selectItem('Porcentaje')}
                ><span className={`option-check ${(delayType === 'percent' && delay !== '0') ? 'active' : ''}`}><span></span></span><p>Porcentaje</p></li>
            </ul>
        </div>
    </div>
    </>
  )
}
