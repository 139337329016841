import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';

import { AccordionCloseIcon, DeleteIcon } from '../../../../assets/svg/iconos';
import { getDia } from './helpersHorario';
import { HorarioProfesionalInput } from '../selectores/HorarioProfesionalInput';
// import { comparaFechas } from '../../../../helpers/horarios';

const timezone = process.env.REACT_APP_TIMEZONE;

export const SucursalItem = ({ horario, showHide, showSucursal, deleteSucursal, addHorario, deleteHorario, editMode, objProfesional, horasOcupadas, setHorasOcupadas, changeHoras }) => {
  const { sucursales } = useSelector(state => state.sucursales);
  const { colores } = useSelector(state => state.ui);
  const { horarios } = useSelector(state => state.horarios);

  const [, setDataSucursal] = useState({});
  const [dataHorarios, setDataHorarios] = useState([]);

  const procesaHorariosSucursal = async () => {
    let objHorarios = {};
    await horarios.forEach(horarioItem => {
      const profesionalId = horarioItem.profesional._id ? horarioItem.profesional._id : horarioItem.profesional;
      if (profesionalId === objProfesional?._id && horarioItem.sucursal === horario.sucursal?._id) {
        horarioItem.diasSemana.forEach(dia => {
          objHorarios[dia] = {
            ...objHorarios[dia],
            horario: horarioItem._id,
            horas: horarioItem.horas
          };
        });
      }
    });
    setDataHorarios(objHorarios);
  };

  useEffect(() => {
    let maxHeight = 0;
    const elements = document.querySelectorAll('.dias');
    elements.forEach(element => {
      const elHeight = element.offsetHeight;
      maxHeight = elHeight >= maxHeight ? elHeight : maxHeight;
    });
    elements.forEach(element => {
      element.style.height = `${maxHeight}px`;
    });
  }, [dataHorarios]);


  useEffect(() => {
    setDataSucursal(sucursales.filter(item => item._id === (horario.sucursal?._id || horario.sucursal))[0]);
    procesaHorariosSucursal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horario, sucursales, horarios]);



  const getNombreSucursal = (idSucursal) => {
    let nombre = '';
    sucursales.forEach(sucursal => {
      if (sucursal._id === idSucursal) {
        nombre = sucursal.nombre;
      }
    });
    return nombre;
  }

  const newHorario = (day) => {
    const horaInicio = getPrimeraHoraDisponible(day);

    const currentHoraInicio = moment(horaInicio).toISOString();
    const currentHoraFin = moment(horaInicio).add(60, 'minutes').milliseconds(0).toISOString();
    addHorario(
      {
        sucursal: horario.sucursal?._id ? horario.sucursal?._id : horario.sucursal,
        diasSemana: day.diasSemana,
        newHoras: [
          ...day.horas,
          {
            horaInicio: currentHoraInicio,
            horaFin: currentHoraFin,
          }
        ]
      }
    );
  }

  const getPrimeraHoraDisponible = (day) => {
    let retorno = moment().tz(timezone).year(2021).month(2).date(19).hours(0).minutes(0).seconds(0).milliseconds(0).toISOString();
    let retornoLogrado = false;
    const arrayHorasDelDia = horasOcupadas[parseInt(day.diasSemana) - 1].horas.sort(comparaFechas);

    if (arrayHorasDelDia.length) {
      let ultimaHora = moment().tz(timezone).year(2021).month(2).date(19).hours(0).minutes(0).seconds(0).milliseconds(0);
      arrayHorasDelDia.forEach(item => {
        const horaInicio = moment(item.horaInicio);
        const horaFin = moment(item.horaFin);
        if (!retornoLogrado) {
          if (horaInicio.diff(ultimaHora, 'minutes') >= 60) {
            retorno = ultimaHora.toISOString();
            retornoLogrado = true;
          } else {
            ultimaHora = horaFin;
          }
        }
      });
    } else {
      if (arrayHorasDelDia.length > 0) {
        if (!retornoLogrado) {
          retorno = moment(horasOcupadas[0].horaFin).toISOString();
          retornoLogrado = true;
        }
      } else {
        if (!retornoLogrado) {
          retorno = moment().tz(timezone).year(2021).month(2).date(19).hours(8).minutes(0).seconds(0).milliseconds(0).toISOString();
          retornoLogrado = true;
        }
      }
    }

    return retorno;
  }

  const comparaFechas = (a, b) => {
    if (moment.tz(a.horaInicio, timezone).isSameOrBefore(moment.tz(b.horaInicio, timezone))) { return -1; }
    if (moment.tz(b.horaInicio, timezone).isBefore(moment.tz(a.horaInicio, timezone))) { return 1; }
    return 0;
  };

  return (
    <div
      className={`sucursal-item ${showHide.includes(horario.sucursal?._id ? horario.sucursal?._id : horario.sucursal) ? '' : 'show'}`}
    >
      <div
        className='name'
        style={{ backgroundColor: colores.colorPrincipal }}
        onClick={() => showSucursal(horario.sucursal?._id ? horario.sucursal?._id : horario.sucursal)}
      >
        <p style={{ color: colores.color6 }}>{horario.sucursal?._id ? horario.sucursal?.nombre : getNombreSucursal(horario.sucursal)}</p>
        {
          editMode &&
          <div
            className='delete-btn'
            onClick={(e) => deleteSucursal(e, horario.sucursal?._id ? horario.sucursal?._id : horario.sucursal)}
          >
            <AccordionCloseIcon color={colores.colorPrincipal} />
          </div>
        }
      </div>
      <AnimateHeight
        duration={500}
        height={(showHide.includes(horario.sucursal?._id ? horario.sucursal?._id : horario.sucursal)) ? 0 : 'auto'}
      >
        <div className='horarios'>
          <div className='horarios-days-columns-row'>
            {
              horario.horarios.map((day, idxDay) => (
                <div
                  key={`188754677${idxDay}`}
                  className='horario-day-column'
                >
                  <div className='horario-column-header'>{getDia(day.diasSemana)}</div>
                  {
                    day?.horas.map((hora, idxHora) => (
                      <div
                        className='horario-item'
                        key={`1994677${idxHora}`}
                      >
                        <HorarioProfesionalInput
                          dia={day}
                          hora={hora}
                          idx={idxHora}
                          idxDay={idxDay}
                          idxHora={idxHora}
                          changeHoras={changeHoras}
                          intMinutos={1}
                          editMode={editMode}
                          newHorario={false}
                          sucursal={horario.sucursal?._id ? horario.sucursal?._id : horario.sucursal}
                          horasOcupadas={horasOcupadas}
                        />
                        {
                          editMode &&
                          <div
                            className='horario-delete'
                            onClick={() => deleteHorario(horario.sucursal?._id ? horario.sucursal?._id : horario.sucursal, idxDay, idxHora)}
                          >
                            <DeleteIcon
                              color={'#ffffff'}
                              background={colores.color5}
                            />
                          </div>
                        }
                      </div>
                    ))
                  }
                  {
                    editMode &&
                    <div
                      className='horario-item empty'
                      onClick={() => newHorario(day)}
                    >
                      <p>+</p>
                    </div>
                  }
                </div>
              ))
            }
          </div>
        </div>
      </AnimateHeight>
    </div>
  )
}
