import React, { useCallback, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import useClickOutside from '../../hooks/useClickOutside';
import { useDispatch } from 'react-redux';
import { openModalBottom } from '../../actions/ui';

const timezone = process.env.REACT_APP_TIMEZONE;

export const FinalizaSelect = ({editMode, value = 'no', onChange}) => {

    const dispatch = useDispatch();
    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);
    
    //const [hideHours, setHideHours] = useState( true );    

    const changing = ( value ) => {
        console.log( value );
        onChange( value );
    }    

    /* const changeHoraInicio = ( value ) =>  {
        console.log( value )
    };

    const checkBoxChange = () => {
        setHideHours( !hideHours );
    } */

    const clickCalendar = () => {
        if( editMode ){
            toggle(true);
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }
  
    return (
        <>
        <div className={`finaliza-select`}>
            <div className='date-row del'>
                <div 
                    className='date-input'
                    onClick={ clickCalendar }
                >
                    {
                        value === 'no'
                        ?
                        'Nunca'
                        :
                        moment.tz(value, timezone).format('DD/MM/YYYY')
                    }
                    {
                        isOpen && (
                            <div
                                className='vacacional-calendar' ref={popover}
                            >
                                <Calendar
                                    minDetail={ `month` }
                                    onChange={changing}
                                    value={ value === 'no' ? new Date() : value }
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
        </>
    )
}
