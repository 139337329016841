import React, { useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const NavIdiomaDropdown = ({ values, classes, onChange }) => {

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const showDropdown = () => {
        setVisibility(true);
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = (value, index) => {
        hideDropdown();
        setSelectedIndex(index);
        onChange( value );
    }

    const [defaultText, setDefaultText] = useState('');

    useEffect(() => {
      setDefaultText( values.find( lang => lang.navDefault ).lang.name )
    }, [values])
    

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

    return (
        <div className={`ylm-select ${ classes }`}>
            <div
                className={`ylm-select-header`}
                onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
            >
                <p>{ (selectedIndex > -1) ? values[selectedIndex].lang.name : defaultText }</p>
                <span><ChevronDownIcon /></span>
            </div>
            <div ref={dropdownMenuRef} className={`ylm-select-options ${ visibility ? 'active' : '' }`}>
                {
                    values.map( (value, idx) => (
                        <div 
                            className="select-option" 
                            key={ uuid() }
                            onClick={ () => selectItem(value, idx) }
                        >
                            <p>{ value.lang.name }</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
