import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { EyeIcon, ServicePauseIcon, ServicePriceIcon, ServiceTimeIcon, TrashIcon } from '../../../../assets/svg/iconos';
import { formatCurrency } from '../../../../helpers/helpers';
import useClickOutside from '../../../../hooks/useClickOutside';

export const ServicioCard = ({servicio, editServicio, deleteServicio}) => {
    const { colores } = useSelector(state => state.ui);
    const { sucursales } = useSelector(state => state.sucursales);

    const [imageUrl, setImageUrl] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const [sucursalesText, setSucursalesText] = useState('');

    const clickSelect = () => {
        toggle( !isOpen );
    }    

    useEffect(() => {
        if( servicio?._id ){
            // console.log( servicio );
            if( servicio.img.length > 0 ){
                getImagenPrincipal( `${servicio.img[0]}` );
            }
            let seleccionadas = 0;
            sucursales.forEach( sucursal => {
                if( servicio.sucursales.includes( sucursal._id ) ){
                    seleccionadas++;
                }
            });
            if( seleccionadas === 0 ){
                setSucursalesText( 'Ninguna' );
            } else if( seleccionadas === sucursales.length ){
                setSucursalesText( 'Todas' );
            } else {
                setSucursalesText( 'Varias' );
            }
        }

        return () => {
            setImageUrl('');
          }
        
    }, [servicio, sucursales]);
    
    const getImagenPrincipal = async( nombre ) => {
        setImageUrl( { url: nombre } );
    }
    
    return (
        <div 
            className='servicio-card'
            onClick={ clickSelect }
        >
            <div className='card-main'>
                <div 
                    className='servicio-img'
                    style={{ backgroundImage: `${imageUrl.url ? 'url('+ imageUrl.url +'?'+ imageUrl.url +')' : 'none'}` }}
                ></div>
                <div className='servicio-title' style={{ color: colores.colorPrincipal }}>
                    <p>{ servicio.nombre }</p>
                </div>
                <div className='servicio-description'>
                    <p>{ servicio.descripcion }</p>
                </div>
                <div className='servicio-sucursales'>
                    <p>Sucursales: <span>{ sucursalesText }</span></p>
                </div>
            </div>
            <footer
                style={{ backgroundColor: colores.colorPrincipal }}
            >
                <div className='footer-left'>
                    <div className='time'>
                        <ServiceTimeIcon />
                        { servicio.duracion }'
                    </div>
                    {
                        servicio.pausa &&
                        <div className='pauses'>
                            <ServicePauseIcon />
                            45'
                        </div>
                    }
                </div>
                <div className='footer-right'>
                    <div className='price'>
                        <ServicePriceIcon />
                        { formatCurrency(servicio.precio) }
                    </div>
                    {
                        servicio.descuento &&
                        <div 
                            className='discount'
                            style={{ color: colores.colorSecundario }}
                        >
                            -10%
                        </div>
                    }
                </div>
            </footer>
            {
                isOpen && (
                    <div className='servicio-overlay' ref={popover}>
                        <div className='overlay-buttons'>
                            <button 
                                className='eye'
                                onClick={ () => editServicio( servicio ) }
                            >
                                <EyeIcon color={ colores.colorPrincipal } />
                            </button>
                            <button 
                                className='trash'
                                onClick={ () => deleteServicio( servicio ) }
                            >
                                <TrashIcon color={ colores.colorPrincipal } />
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
