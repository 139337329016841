import LoadingLogo from '../../assets/img/loading-logo.png';

export const Loading = () => {
  return (
    <div className='loading-layer'>
        <div className='loading-bg'></div>
        <div className='loading-content'>
            <p className='rotating'><img src={ LoadingLogo } alt="Cargando" /></p>
        </div>
    </div>
  )
}
