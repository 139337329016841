import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setMenuLateral } from '../../actions/ui';
import { AddIcon, BuscarIcon, DownloadIcon } from '../../assets/svg/iconos';
import { removeAccents } from '../../helpers/helpers';
import { Categorias } from './components/Categorias';
import { Productos } from './components/Productos';
import { Servicios } from './components/Servicios';
import { Variantes } from './components/Variantes';

export const PuntoVenta = ({history}) => {

    const dispatch = useDispatch();
    const { colores, miniLateral } = useSelector(state => state.ui);
    const { servicios } = useSelector(state => state.servicios);
    const { productos } = useSelector(state => state.productos);
    const { variantes } = useSelector(state => state.variantes);
    const { categorias } = useSelector(state => state.categorias);

    const { tab } = useParams();

    const [searchActive, setSearchActive] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [contentList, setContentList] = useState([]);

    const [activeTab] = useState(tab || 'servicios');


    useEffect(() => {
        dispatch( setMenuLateral('punto-venta') );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect( () => {
        if( searchTxt.trim().length < 3 ) {
            let list = [];
            if( activeTab === 'servicios' ){ list = [ ...servicios ] }
            if( activeTab === 'productos' ){ list = [ ...productos ] }
            if( activeTab === 'variantes' ){ list = [ ...variantes ] }
            if( activeTab === 'categorias' ){ list = [ ...categorias ] }
            let newList = [];
            list.forEach((item, idx) => {
                let existe = false;
                newList.forEach(ser => {
                    if( ser._id === item._id ){
                        existe = true;
                    }
                });
                if( !existe && !item.deleted ){
                    item.orden = idx;
                    newList.push( item );
                }
            });
            setContentList( newList );
        } else {
            let list = [];
            if( activeTab === 'servicios' ){ list = [ ...servicios ] }
            if( activeTab === 'productos' ){ list = [ ...productos ] }
            if( activeTab === 'variantes' ){ list = [ ...variantes ] }
            if( activeTab === 'categorias' ){ list = [ ...categorias ] }
            let newList = [];
            const filterData = async() => {
                newList = await list.filter( item => {
                    let field = '';
                    if( activeTab === 'servicios' ){ field = item.nombre }
                    if( activeTab === 'productos' ){ field = item.titulo }
                    if( activeTab === 'variantes' ){ field = item.nombre }
                    if( activeTab === 'categorias' ){ field = item.nombre }

                    let retorno;
                    retorno = !item.deleted && removeAccents(field).toLowerCase().includes( removeAccents(searchTxt.toLowerCase()) );
                    return retorno;
                });
                setContentList( newList );
            }
            filterData();
        }
    }, [servicios, productos, variantes, categorias, searchTxt, activeTab]);

    const clickSearch = () => {
        setSearchActive( !searchActive );
    };

    const handleSearchChange = (e) => {
        setSearchTxt( e.target.value );
    }

    const clickAdd = () => {
        let pathname = '';
        if( activeTab === 'servicios' ){ pathname = '/servicio/new' }
        if( activeTab === 'productos' ){ pathname = '/producto/new' }
        if( activeTab === 'variantes' ){ pathname = '/variante/new' }
        if( activeTab === 'categorias' ){ pathname = '/categoria/new' }
        if( pathname !== ''){
            history.push({
                pathname,
                state: {
                    activeTab: 'ultima'
                }
            });
        }
    };
    
    const clickUpload = () => {
        console.log( 'clickUpload' );
    };

    const navigateTo = ( tab ) => {
        if( tab === 'servicios' ){ history.push({ pathname: `/punto-venta/servicios` }) };
        if( tab === 'productos' ){ history.push({ pathname: `/punto-venta/productos` }) };
        if( tab === 'variantes' ){ history.push({ pathname: `/punto-venta/variantes` }) };
        if( tab === 'categorias' ){ history.push({ pathname: `/punto-venta/categorias` }) };
    }

  return (
    <div 
        className='punto-venta'
        style={{
            '--colorPrincipal': colores.colorPrincipal,
            '--colorSecundario': colores.colorSecundario,
            '--color3': colores.color3,
            '--color4': colores.color4,
            '--color5': colores.color5,
            '--color6': colores.color6,
            '--color7': colores.color7,
        }}
    >
        <div className={`preheader ${!miniLateral && 'compress'}`}>
            <div className="title">
                <h1 style={{color: colores.colorPrincipal}}>Punto de venta</h1>
            </div>
            <div className='tabs'>
                <div
                    className={ `tab ${activeTab === 'servicios' ? 'active' : ''}` } onClick={ () => navigateTo('servicios') }
                    style={{ backgroundColor: activeTab === 'servicios' ? colores.colorSecundario : 'transparent'}}
                >
                    <p style={{ color: activeTab === 'servicios' ? colores.colorPrincipal : colores.colorSecundario}}>Servicios</p>
                </div>
                <div
                    className={ `tab ${activeTab === 'productos' ? 'active' : ''}` } onClick={ () => navigateTo('productos') }
                    style={{ backgroundColor: activeTab === 'productos' ? colores.colorSecundario : 'transparent'}}
                >
                    <p style={{ color: activeTab === 'productos' ? colores.colorPrincipal : colores.colorSecundario}}>Productos</p>
                </div>
                
                <div
                    className={ `tab ${activeTab === 'variantes' ? 'active' : ''}` } onClick={ () => navigateTo('variantes') }
                    style={{ backgroundColor: activeTab === 'variantes' ? colores.colorSecundario : 'transparent'}}
                >
                    <p style={{ color: activeTab === 'variantes' ? colores.colorPrincipal : colores.colorSecundario}}>Variantes</p>
                </div>
                
                <div
                    className={ `tab ${activeTab === 'categorias' ? 'active' : ''}` } onClick={ () => navigateTo('categorias') }
                    style={{ backgroundColor: activeTab === 'categorias' ? colores.colorSecundario : 'transparent'}}
                >
                    <p style={{ color: activeTab === 'categorias' ? colores.colorPrincipal : colores.colorSecundario}}>Categorías</p>
                </div>
            </div>
            <div className="botonera">
                <div 
                    className={`btn-search ${searchActive && 'active'}`}
                    style={{ backgroundColor: colores.color6 }}
                >
                    <div className='search-input'>
                        <input 
                            type={'text'}
                            placeholder={'Buscar por nombre'}
                            value={ searchTxt }
                            onChange={ handleSearchChange }
                            style={{ background: colores.color6 }}
                        />
                        {
                            searchTxt.trim().length > 2 &&
                            <span className='cantidad'>
                                ({ contentList.length })
                            </span>
                        }
                    </div>
                    <div
                        className='icono'
                        onClick={ clickSearch }
                    >
                        <BuscarIcon
                            color={ colores.colorPrincipal }
                            className="icon-inverse"
                        />
                    </div>
                </div>
                <div 
                    className="btn-add"
                    style={{ backgroundColor: colores.color6 }}
                    onClick={ clickAdd }
                >
                    <div className='icono'>
                        <AddIcon
                            color={ colores.colorPrincipal }
                        />
                    </div>
                    
                </div>
                <div 
                    className="btn-upload"
                    style={{ backgroundColor: colores.color6 }}
                    onClick={ clickUpload }
                >
                    <div className='icono'>
                        <DownloadIcon color={ colores.colorPrincipal }/>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className='tabs'>
            {
                activeTab === 'servicios'
                &&
                <Servicios
                    history={ history }
                    serviciosList={ contentList }
                    setServiciosList={ setContentList }
                />
            }
            {
                activeTab === 'productos'
                &&
                <Productos
                    history={ history }
                    productosList={ contentList }
                    setProductosList={ setContentList }
                />
            }
            {
                activeTab === 'variantes'
                &&
                <Variantes
                    history={ history }
                    variantesList={ contentList }
                    setVariantesList={ setContentList }
                />
            }
            {
                activeTab === 'categorias'
                &&
                <Categorias
                    history={ history }
                    categoriasList={ contentList }
                    setCategoriasList={ setContentList }
                />
            }
        </div>
    </div>
  )
}
