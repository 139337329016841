import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { SelectArrowIcon, FiltersIcon } from '../../../assets/svg/iconos';
import { formatColor } from '../helpers/helpersReservas';

export const Filters = ({ arrSucursales, setArrSucursales, arrProfesionales, setArrProfesionales, miniLateral, setMiniLateral, miniFilters, setMiniFilters, agendaActive }) => {

    const { colores } = useSelector(state => state.ui);

    const [accordion1Open, setAccordion1Open] = useState(false);
    const [accordion2Open, setAccordion2Open] = useState(false);

    const sucursalSelected = ( sucursal, e ) => {
        e.stopPropagation();
        let newArray = [];
        arrSucursales.forEach( item => {
            let newItem = {
                sucursal: item.sucursal
            };
            if( newItem.sucursal._id === sucursal.sucursal._id ){
                newItem.selected = !sucursal.selected;
            } else {
                newItem.selected = item.selected;
            }
            newArray = [ ...newArray, newItem ];
        });
        setArrSucursales( newArray );
    };

    const profSelected = ( profesional, e ) => {
        e.stopPropagation();
        let newArray = [];
        arrProfesionales.forEach( item => {
            let newItem = {
                profesional: item.profesional,
                idx: item.idx
            };
            if( newItem.profesional._id === profesional.profesional._id ){
                newItem.selected = !profesional.selected;
            } else {
                newItem.selected = item.selected;
            }
            newArray = [ ...newArray, newItem ];
        });
        setArrProfesionales( newArray );
    };

    const minimize = () => {
        setMiniFilters(!miniFilters);
    }

    return (
        <div 
            className={ `filters ${(miniFilters ? 'mini' : '')} ${ agendaActive ? '' : 'hide' }`}
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div
                className={`btn-minimize`}
                style={{ backgroundColor: colores.colorSecundario }}
                onClick={ minimize }
            >
                <SelectArrowIcon />
            </div>
            <div className="filters-header">
                <h3 style={{ color: colores.colorPrincipal }}>Filtros</h3>
                <span onClick={ minimize }>
                    <FiltersIcon color={ colores.colorPrincipal } />
                </span>
            </div>
            <div className="filters-list">
                <div 
                    className={`filters-section ${(accordion1Open) ? 'opened' : ''}`}
                >
                    <div 
                        className={`section-header`}
                        onClick={ () => setAccordion1Open( !accordion1Open )}
                    >
                        <h2 style={{ color: colores.colorPrincipal }}>Local</h2>
                        <SelectArrowIcon color={ colores.colorPrincipal } />
                    </div>
                    <AnimateHeight
                        duration = { 500 }
                        height={ (accordion1Open) ? 'auto' : 0 }
                    >
                        <div 
                            className="filter-options"
                            style={{ backgroundColor: colores.color6 }}
                        >
                        {
                            arrSucursales.map ( item => (
                                !item.sucursal.deleted &&
                                <div
                                    key={ item.sucursal._id }
                                    className={ `filter filter-sucursal ${(item.selected) ? 'selected' : ''}` }
                                    style={{ backgroundColor: colores.color6 }}
                                >
                                    <label
                                        className="container"
                                        style={{ color: colores.colorPrincipal }}
                                    >{ item.sucursal.nombre }
                                        <input 
                                            type="checkbox"
                                            defaultChecked={ item.selected }
                                            onClick={ (e) => sucursalSelected( item, e ) } 
                                        />
                                        <span 
                                            className="checkmark-bg"
                                            style={{ backgroundColor: 'white' }}
                                        >
                                            {
                                                item.selected &&
                                                <span 
                                                    className='checkmark-item'
                                                    style={{ borderColor: colores.colorPrincipal }}
                                                ></span>
                                            }
                                            
                                        </span>
                                    </label>
                                </div>
                            ))
                        }
                        </div>
                    </AnimateHeight>
                </div>
                <div 
                    className={`filters-section ${(accordion2Open) ? 'opened' : ''}`}
                >
                    <div 
                        className={`section-header`}
                        onClick={ () => setAccordion2Open( !accordion2Open )}
                    >
                        <h2 style={{ color: colores.colorPrincipal }}>Equipo</h2>
                        <SelectArrowIcon color={ colores.colorPrincipal } />
                    </div>
                    <AnimateHeight
                        duration = { 500 }
                        height={ (accordion2Open) ? 'auto' : 0 }
                    >
                        <div 
                            className="filter-options"
                            style={{ backgroundColor: colores.color6 }}
                        >
                        {
                            arrProfesionales.map ( item => (
                                <div
                                    key={ item.profesional._id } 
                                    className={ `filter filter-prof ${(item.selected) ? 'selected' : ''} ${(item.profesional.deleted) ? 'deleted' : ''}` }
                                    style={{ backgroundColor: colores.color6 }}
                                >
                                    <label 
                                        className="container"
                                        style={{ color: colores.colorPrincipal }}
                                    >{ item.profesional.nombre }
                                        <input 
                                            type="checkbox"
                                            defaultChecked={ item.selected }
                                            onClick={ (e) => profSelected( item, e ) }
                                        />
                                        <span 
                                            className="checkmark-bg"
                                            style={{ backgroundColor: 'white' }}
                                        >
                                            {
                                                item.selected &&
                                                <span 
                                                    className='checkmark-item'
                                                    style={{ borderColor: colores.colorPrincipal }}
                                                ></span>
                                            }
                                            
                                        </span>
                                    </label>
                                    <span
                                        style={{backgroundColor: `${formatColor(item.profesional.color)}`}}
                                        className={ `indicator`}></span>
                                </div>
                            ))
                        }
                        </div>
                    </AnimateHeight>
                </div>
            </div>
        </div>
    )
}
