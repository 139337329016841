/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/useForm';

import poblaciones from '../../data/cp.json';
import { isDateBars, isEmail } from '../../helpers/forms';
import { startRegisterBusiness } from '../../actions/auth';
import { startLoadingClientesEmpresa, startSearchClientesEmpresa } from '../../actions/usuarios';

const initForm = {
    nombre: '',
	apellidos:'',
    fechaNac: '',
    email: '',
    telefono: '',
    cp: '',
    localizacion: false,
    notificaciones: false
};

const initErrores = {
    nombre: { active: false, msg: 'Debe introducir un nombre' },
	apellidos:{ active: false, msg: 'Debe introducir un apellido' },
    fechaNac: { active: false, msg: 'Debe introducir una fecha con formato DD/MM/AAAA' },
    email: { active: false, msg: 'Debe introducir un email' },
    telefono: { active: false, msg: 'Debe introducir un número de teléfono' },
    cp: { active: false, msg: 'Debe introducir un código postal' }
};

const useOutsideClick = (ref, callback, when) => {
    const savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    }, [when]);
}

export const DropdownClientes = ({ texto, handleUsuarioSelected, selectUsuario, editCita = false }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const { loading, colores } = useSelector(state => state.ui);
    const { usuarios, total: totalClientes, active: usuarioActive } = useSelector(state => state.usuarios);
    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const [usuariosFiltered, setUsuariosFiltered] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [messageVisible, setMessageVisible] = useState(false);
    const [errores, setErrores] = useState(initErrores);
    const [formAddCliente, setFormAddCliente] = useState(false);

    const [formValues, handleInputChange, reset] = useForm(initForm);

    const { nombre, apellidos, fechaNac, email, telefono, cp } = formValues;

    const vistaClientes = useRef(null);
    const [initialSearch, setInitialSearch] = useState(false);

    const [pagNumber, setPagNumber] = useState(1);
    const LIMITE_USUARIOS = 10;

    useEffect(() => {
        if (!usuarioActive) {
            setSearchValue('');
        } else {
            setSearchValue(usuarioActive.nombre)
        }
    }, [usuarioActive]);

    useEffect(() => {
        setUsuariosFiltered(usuarios);
    }, [usuarios]);


    const showDropdown = () => {
        if (selectUsuario.visible) {
            if (usuariosFiltered.length === 0) {
                //console.log( 'voy a cargar usuarios' );
                dispatch(startLoadingClientesEmpresa(auth.empresa, pagNumber | 1, LIMITE_USUARIOS));
            }
            setVisibility(true);
        }
    }

    const hideDropdown = () => {
        setFormAddCliente(false);
        setVisibility(false);
    }

    /* const cargarMasUsuarios = () => {
        dispatch( startLoadingClientesEmpresa( auth.empresa, pagNumber + 1, LIMITE_USUARIOS ));
        setPagNumber( pagNumber + 1);
        vistaClientes.current.scrollTo( 0, 0 );
    }; */

    const selectItem = (usuario) => {
        hideDropdown();
        setSearchValue(usuario.nombre);
        handleUsuarioSelected(usuario);
    }

    useOutsideClick(dropdownMenuRef, hideDropdown, visibility);


    const handleSearchChange = ({ target }) => {
        setSearchValue(target.value);
        showDropdown();
        /* if( target.value.trim().length > 0 ){
            const newClientes = usuarios.filter( item => {
                let retorno;
                retorno = removeAccents(item.nombre).toLowerCase().includes( removeAccents(target.value.toLowerCase()) && !item.deleted );
                return retorno;
            });
            setUsuariosFiltered( newClientes );
        } else {
            setUsuariosFiltered( usuarios );
        } */
        if (target.value.trim().length < 3) {
            if (!initialSearch) {
                setPagNumber(1);
                dispatch(startLoadingClientesEmpresa(auth.empresa, 1, LIMITE_USUARIOS));
            }
        } else {
            dispatch(startSearchClientesEmpresa(auth.empresa, target.value.trim(), pagNumber | 1, LIMITE_USUARIOS));
            setInitialSearch(false);
        }
    };

    const paginacion = (valor) => {
        if (searchValue.trim().length < 3) {
            if (valor === -1000) {
                setPagNumber(1);
                dispatch(startLoadingClientesEmpresa(auth.empresa, 1, LIMITE_USUARIOS));
            } else if (valor === 1) {
                setPagNumber(pagNumber + 1);
                dispatch(startLoadingClientesEmpresa(auth.empresa, pagNumber + 1, LIMITE_USUARIOS));
            } else if (valor === 1000000000) {
                setPagNumber(Math.ceil(totalClientes / LIMITE_USUARIOS));
                dispatch(startLoadingClientesEmpresa(auth.empresa, Math.ceil(totalClientes / LIMITE_USUARIOS), LIMITE_USUARIOS));
            } else {
                if (pagNumber <= 1) return;
                setPagNumber(pagNumber - 1);
                dispatch(startLoadingClientesEmpresa(auth.empresa, pagNumber - 1, LIMITE_USUARIOS));
            }
        } else {
            if (valor === -1000) {
                setPagNumber(1);
                dispatch(startSearchClientesEmpresa(auth.empresa, searchValue.trim(), 1, LIMITE_USUARIOS));
            } else if (valor === 1) {
                setPagNumber(pagNumber + 1);
                dispatch(startSearchClientesEmpresa(auth.empresa, searchValue.trim(), pagNumber + 1, LIMITE_USUARIOS));
            } else if (valor === 1000000000) {
                setPagNumber(Math.ceil(totalClientes / LIMITE_USUARIOS));
                dispatch(startSearchClientesEmpresa(auth.empresa, searchValue.trim(), Math.ceil(totalClientes / LIMITE_USUARIOS), LIMITE_USUARIOS));
            } else {
                if (pagNumber <= 1) return;
                setPagNumber(pagNumber - 1);
                dispatch(startSearchClientesEmpresa(auth.empresa, searchValue.trim(), pagNumber - 1, LIMITE_USUARIOS));
            }
        }

        vistaClientes.current.scrollTo(0, 0);
    }

    /* const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }  */

    const inputClick = ({ target }) => {
        target.select();
        showDropdown();
    };

    const inputBlur = ({ target }) => {
        if (usuarioActive) {
            setSearchValue(usuarioActive.nombre);
        }
    }

    const handleRegister = (e) => {
        e.preventDefault();
        formValues.empresa = auth.empresa;
        if (formValues.cp.trim().length > 0) {
            if (!isNaN(parseInt(formValues.cp))) {
                const pueblo = poblaciones.filter(item => (item.codigo_postal === parseInt(formValues.cp)));
                formValues.poblacion = pueblo[0].municipio_nombre;
            }
        }

        let hayErrores = false;
        const objErrores = {
            nombre: { active: false, msg: 'Debe introducir un nombre' },
			apellidos: { active: false, msg: 'Debe introducir un apellido' },
            //fechaNac: { active: false, msg: 'Debe introducir una fecha con formato DD/MM/AAAA' },
            email: { active: false, msg: 'Debe introducir un email' },
            telefono: { active: false, msg: 'Debe introducir un número de teléfono' },
            //cp: { active: false, msg: 'Debe introducir un código postal' },
            password: { active: false, msg: 'La contraseña debe tener 6 o más caracteres' }
        };

        if (formValues.nombre.trim().length === 0) {
            hayErrores = true;
            objErrores.nombre.active = true;
        }
		if (formValues.apellidos.trim().length === 0) {
            hayErrores = true;
            objErrores.apellidos.active = true;
        }
        // if (!isDateBars(formValues.fechaNac)) {
        //     hayErrores = true;
        //     objErrores.fechaNac.active = true;
        // }
        if (!isEmail(formValues.email)) {
            hayErrores = true;
            objErrores.email.active = true;
        }
        if (formValues.telefono.trim().length === 0) {
            hayErrores = true;
            objErrores.telefono.active = true;
        }
        // if (formValues.cp.trim().length === 0) {
        //     hayErrores = true;
        //     objErrores.cp.active = true;
        // }
        if (!hayErrores) {
            setErrores(initErrores);
            dispatch(startRegisterBusiness(formValues));
            hideDropdown();
            reset();
            setMessageVisible(true);
        } else {
            setErrores(objErrores);
        }
    };

    return (
        <div
            className="brb-select"
        >
            <div
                className={`brb-select-container ${selectUsuario.visible ? 'visible' : ''} ${visibility ? 'active' : ''}`}
                onClick={() => visibility ? hideDropdown() : showDropdown()}
            >
                <input
                    type="text"
                    placeholder={texto}
                    autoComplete="disabled"
                    value={searchValue}
                    onBlur={inputBlur}
                    onClick={inputClick}
                    onChange={handleSearchChange}
                />
                <div className="right-zone">
                    <p></p>
                    <span
                        className="select-arrow select-arrow-down"
                    ></span>
                </div>
            </div>
            <div
                ref={dropdownMenuRef}
                className={`brb-select-options select-usuario ${visibility ? 'active' : ''} ${(formAddCliente) ? 'long' : ''}`}
            >
                <div
                    className={`scroll-zone`}
                    ref={vistaClientes}
                >
                    {
                        usuariosFiltered.length > 0 ?
                            usuariosFiltered.map((usuario, idx) => (
                                !usuario.deleted &&
                                <div
                                    className="brb-select-option"
                                    key={usuario._id}
                                    style={{ backgroundColor: colores.color6 }}
                                    onClick={() => selectItem(usuario)}
                                >
                                    <p>{`${usuario.nombre} ${usuario.apellidos}`}</p>
                                </div>
                            ))
                            :
                            <div
                                className='loading'
                            >Cargando usuarios</div>
                    }
                    {
                        usuariosFiltered.length > 0 && totalClientes > usuariosFiltered.length &&
                        <div
                            className='brb-select-load'
                            style={{
                                backgroundColor: colores.colorSecundario,
                                color: colores.colorPrincipal,
                                display: pagNumber < Math.ceil(totalClientes / LIMITE_USUARIOS) ? 'flex' : 'none'
                            }}
                            onClick={() => paginacion(1)}
                        >Cargar más</div>
                    }
                </div>
                <div className={`add-user-form`}>
                    <div
                        className={`active-button ${(formAddCliente) ? '' : 'active'}`}
                        style={{ backgroundColor: colores.colorPrincipal }}
                        onClick={() => setFormAddCliente(true)}
                    >
                        <p>+ Añadir cliente</p>
                    </div>
                    <div className={`new-user-form ${(formAddCliente) ? 'active' : ''}`}>
                        <form
                            style={{ backgroundColor: colores.colorPrincipal }}
                            onSubmit={handleRegister}
                        >
                            <div className="form-item">
                                <input
                                    type="text"
                                    placeholder="Nombre cliente"
                                    name="nombre"
                                    className="auth__input"
                                    autoComplete="off"
                                    style={{
                                        color: colores.color6,
                                        borderColor: colores.color6,
                                        placeholder: colores.color6
                                    }}
                                    value={nombre}
                                    onChange={handleInputChange}
                                />
                                <p
                                    className={`error-text ${(errores.nombre.active) ? 'active' : ''}`}
                                    style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                                >
                                    {errores.nombre.msg}
                                </p>
                            </div>

							<div className="form-item">
                                <input
                                    type="text"
                                    placeholder="Apellidos cliente"
                                    name="apellidos"
                                    className="auth__input"
                                    autoComplete="off"
                                    style={{
                                        color: colores.color6,
                                        borderColor: colores.color6,
                                        placeholder: colores.color6
                                    }}
                                    value={apellidos}
                                    onChange={handleInputChange}
                                />
                                <p
                                    className={`error-text ${(errores.apellidos.active) ? 'active' : ''}`}
                                    style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                                >
                                    {errores.apellidos.msg}
                                </p>
                            </div>

                            <div className="form-item">
                                <input
                                    type="text"
                                    placeholder="Teléfono"
                                    name="telefono"
                                    className="auth__input"
                                    value={telefono}
                                    onChange={handleInputChange}
                                />
                                <p
                                    className={`error-text ${(errores.telefono.active) ? 'active' : ''}`}
                                    style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                                >
                                    {errores.telefono.msg}
                                </p>
                            </div>

                            <div className="form-item">
                                <input
                                    type="email"
                                    placeholder="E-mail"
                                    name="email"
                                    className="auth__input"
                                    value={email}
                                    onChange={handleInputChange}
                                />
                                <p
                                    className={`error-text ${(errores.email.active) ? 'active' : ''}`}
                                    style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                                >
                                    {errores.email.msg}
                                </p>
                            </div>

                            <div className="form-item">
                                <input
                                    type="text"
                                    placeholder="Fecha de nacimiento (DD/MM/AAAA)"
                                    name="fechaNac"
                                    className="auth__input"
                                    value={fechaNac}
                                    onChange={handleInputChange}
                                />
                                {/* <p
                                    className={`error-text ${(errores.fechaNac.active) ? 'active' : ''}`}
                                    style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                                >
                                    {errores.fechaNac.msg}
                                </p> */}
                            </div>

                            <div className="form-item">
                                <input
                                    type="text"
                                    placeholder="Código postal"
                                    name="cp"
                                    className="auth__input"
                                    value={cp}
                                    onChange={handleInputChange}
                                />
                                {/* <p
                                    className={`error-text ${(errores.cp.active) ? 'active' : ''}`}
                                    style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                                >
                                    {errores.cp.msg}
                                </p> */}
                            </div>

                            <div className={`form-messages ${(messageVisible) ? 'active' : ''}`}>
                                <p>
                                    Te hemos enviado un e-mail con un enlace para confirmar tu cuenta. Por favor, haz clic en el enlace y quedarás registrado para anotar tu reserva.
                                </p>
                                <p className="no-email">Si no recibes el e-mail, <span>haz clic aquí</span></p>
                            </div>

                            <div>
                                {
                                    (!messageVisible)
                                    &&
                                    <button
                                        type="submit"
                                        style={{ color: colores.colorPrincipal }}
                                        disabled={loading}
                                    >
                                        Añadir cliente
                                    </button>
                                }
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
