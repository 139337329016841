import moment from 'moment-timezone';
const timezone = process.env.REACT_APP_TIMEZONE;

const weekDayNames = [
    { number: 1, name: 'Lunes' },
    { number: 2, name: 'Martes' },
    { number: 3, name: 'Miércoles' },
    { number: 4, name: 'Jueves' },
    { number: 5, name: 'Viernes' },
    { number: 6, name: 'Sábado' },
    { number: 7, name: 'Domingo' }
];

const monthNames = [
    { number: 1, name: 'Enero' },
    { number: 2, name: 'Febrero' },
    { number: 3, name: 'Marzo' },
    { number: 4, name: 'Abril' },
    { number: 5, name: 'Mayo' },
    { number: 6, name: 'Junio' },
    { number: 7, name: 'Julio' },
    { number: 8, name: 'Agosto' },
    { number: 9, name: 'Septiembre' },
    { number: 10, name: 'Octubre' },
    { number: 11, name: 'Noviembre' },
    { number: 12, name: 'Diciembre' },
]

export const setWeekDayName = (number) => {
    let retorno = '';
    weekDayNames.forEach( day => {
        if( day.number === number ) {
            retorno = day.name;
        }
    });
    return retorno;
};

export const setMonthName = (number) => {
    let retorno = '';
    monthNames.forEach( month => {
        if( month.number === number ) {
            retorno = month.name;
        }
    });
    return retorno;
};

export const barberiaNow = () => {
    return moment().tz(timezone).format('YYYY-MM-DD HH:mm');
};