import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ value, onChange, column }) => {
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
      setMounted(true);
    }, []);
    const modules = {
      toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean']
      ],
    };
    if (!mounted) return <p>Cargando editor...</p>;

    const onChangeValue = (value)=>{
        onChange(column, value);
    }
   
    return (
        <ReactQuill
            theme="snow"
            value={value}
            onChange={onChangeValue}
            modules={modules}
            className="h-[400px] mb-12" // Ajusta la altura según necesites
        />
    )
}

export default TextEditor


