import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usuarioChangePassword } from '../../../actions/usuarios';

export const ModalCambiarPassword = ( { data, setModalResponse, modalCambiarPasswordOpened, setModalCambiarPasswordOpened } ) => {
    const dispatch = useDispatch();
    const { colores } = useSelector(state => state.ui);
    const { empresa } = useSelector(state => state.auth);

    const formInitialState = {
        newPassword: '',
        repeatPassword: ''
    };

    const [newPasswordData, setNewPasswordData] = useState( formInitialState );

    // useEffect(() => {
    //   console.log( newPasswordData );
    // }, [newPasswordData])
    

    const clickCloseModal = ( fromButton = false ) => {
        let hasErrors = false;
        let errorLogs = {};
        if( fromButton ) {
            const { newPassword, repeatPassword } = newPasswordData;
            if( newPassword.trim().length < 6 ){
                hasErrors = true;
                errorLogs.passwordCorto = 'La contraseña debe tener un mínimo de 6 caracteres';
            }
            if( newPassword !== repeatPassword ) {
                hasErrors = true;
                errorLogs.diferentes = 'Las contraseñas no coinciden'
            }
            if( !hasErrors ){
                dispatch( usuarioChangePassword( empresa, newPassword ) );
            }
        }
        if( !hasErrors ){
            setModalCambiarPasswordOpened( false );
            setNewPasswordData( formInitialState );
        } else {
            console.log( errorLogs );
        }
    };

    const inputChange = (e) => {
        setNewPasswordData({
            ...newPasswordData,
            [e.target.name]: e.target.value
        })
    }    

    return (
        <div className={`modal-ylm admin-password ${(modalCambiarPasswordOpened) ? 'active' : ''}`}>
            <div className="modal-bg"></div>
            <div className="modal-wrapper">
                <div className="modal-title">
                    <h3 style={{ color: colores.colorPrincipal }}>{ `Cambiar password` }</h3>
                </div>
                <div className="modal-body">
                    <div className='form-input'>
                        <input 
                            type={`password`}
                            name='newPassword'
                            placeholder={`Nueva contraseña`}
                            style={{
                                '--admin-placeholder-color': colores.colorPrincipal
                            }}
                            value={ newPasswordData.newPassword }
                            onChange={ inputChange } 
                        />
                    </div>
                    <div className='form-input'>
                        <input 
                            type={`password`}
                            name='repeatPassword'
                            placeholder={`Repetir nueva contraseña`}
                            style={{
                                '--admin-placeholder-color': colores.colorPrincipal
                            }}
                            value={ newPasswordData.repeatPassword }
                            onChange={ inputChange } 
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="buttons">
                        <div 
                            className="btn btn-confirm"
                            style={{ backgroundColor: colores.colorPrincipal }}
                            onClick={ () => clickCloseModal( true ) }
                        >{ `Cambiar` }</div>
                        <div 
                            className="btn btn-cancel"
                            style={{ backgroundColor: '#F2F2F2', color: '#393D40' }}
                            onClick={ () => clickCloseModal() }
                        >{ `Cancelar` }</div>
                    </div>
                </div>
                <div
                    className="modal-close"
                    onClick={ () => clickCloseModal() }
                ><span></span></div>
            </div>
        </div>
    )
}
