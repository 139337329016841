import React, { useCallback, useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';

import useClickOutside from '../../../hooks/useClickOutside';
import { openModalBottom } from '../../../actions/ui';

const timezone = process.env.REACT_APP_TIMEZONE;

export const FechaNac = ({editMode = false, objCliente, dataChange}) => {

    const dispatch = useDispatch();
    const { colores } = useSelector(state => state.ui);

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const [value, setValue] = useState( new Date() );

    useEffect(() => {
      if( objCliente && objCliente?.fechaNac !== '' ){
        // console.log( objCliente?.fechaNac );
        let dateArray = [];
        if( objCliente?.fechaNac?.search('/') > -1 ){
            dateArray = objCliente?.fechaNac?.split('/');
        } else if( objCliente?.fechaNac?.search('-') > -1 ){
            dateArray = objCliente?.fechaNac?.split('-');
        }
        if( dateArray.length > 0 ) {
            const dateString = `${dateArray[2]}-${parseInt(dateArray[1]) < 10 ? '0' + dateArray[1] : dateArray[1]}-${parseInt(dateArray[0]) < 10 ? '0' + dateArray[0] : dateArray[0]}`;
            // console.log( dateString );
            // console.log( moment.tz( `${dateString} 12:00`, timezone ).toDate() );
            setValue( moment.tz( `${dateString} 12:00`, timezone ).toDate() );
        }
      }
    }, [objCliente])
    

    const changing = ( val ) => {
        const dateString = moment.tz( val, timezone ).format('YYYY-MM-DD');
        setValue( moment.tz( `${dateString} 12:00`, timezone ).toDate() );
        const newFechaNac = `${ moment.tz( val, timezone ).hours(12).format('D/M/YYYY')}`;
        dataChange( 'fechaNac', newFechaNac );
    }

    const clickCalendar = () => {
        if( editMode ){
            toggle(true);
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    return (
        <div className='contact-input fecha'>
            <div 
                className='date-input'
                style={{ backgroundColor: `${ editMode ? '#FFFFFF' : colores.color6}` }}
                onClick={ clickCalendar }
            >
                { moment( value ).format('D/M/YYYY') }
                {
                        isOpen && (
                            <div
                                className='fechanac-calendar' ref={popover}
                            >
                                <Calendar
                                    minDetail={ `decade` }
                                    onChange={ changing }
                                    value={ value }
                                />
                            </div>
                        )
                    }
            </div>
        </div>
    )
}
