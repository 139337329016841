import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import uuid from 'react-uuid';

import { compareDates } from '../helpers/customCalendar';
import { activeCita, unactiveCita } from '../../../actions/citas';
import { activeUsuario } from '../../../actions/usuarios';
import { activeServicio } from '../../../actions/servicios';
import { activeSucursal } from '../../../actions/sucursales';
import { activeProfesional } from '../../../actions/profesionales';
//const CitasListItem = lazy(()=>import('./CitasListItem'));
const CitasListItem = lazy( async () => (await import('./CitasListItem') ));
//import { CitasListItem } from './CitasListItem';

//const timezone = process.env.REACT_APP_TIMEZONE;

export const CitasList = ({ citas, arrSucursales, arrProfesionales, selectedDate, currentMonth, setActiveModalDeleteCita, setEditCita, viewValue }) => {

    const dispatch = useDispatch();
    const { active:citaActiva } = useSelector(state => state.citas);
    const { usuarios, active:usuarioActive } = useSelector(state => state.usuarios);
    const [newCitas, setNewCitas] = useState([]);
    const [citasFiltered, setCitasFiltered] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const [primeroMes, setPrimeroMes] = useState( moment( selectedDate ));
    const [ultimoMes, setUltimoMes] = useState( moment( selectedDate ));

    useEffect(() => {
        let newCitas = [];
        citas.forEach( cita => {
            const elUsuario = usuarios.filter( item => item._id === cita.usuario);
            const newUsuario = (elUsuario.length > 0) ? elUsuario[0] : cita.usuario;
            cita.usuario = newUsuario;
            if(viewValue === 'day' && moment( selectedDate ).format('D/M/YYYY') === moment(cita.fecha).format('D/M/YYYY')) {
                newCitas = [ ...newCitas, cita ];
            }
            if(viewValue === 'month' && moment( cita.fecha ).isSameOrAfter( primeroMes ) && moment( cita.fecha ).isSameOrBefore( ultimoMes )) {
                newCitas = [ ...newCitas, cita ];
            }
        });
        if( newCitas.length > 0 ){
            setNewCitas(newCitas.sort( compareDates ));
            setCitasFiltered(newCitas.sort( compareDates ));
        } else {
            setNewCitas([]);
            setCitasFiltered([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [citas, viewValue, primeroMes, ultimoMes])

    useEffect(() => {
        const diasMes = parseInt( moment( currentMonth ).daysInMonth() );
        setPrimeroMes( moment( currentMonth ).date(1).hour(0).minutes(0).seconds(0));
        setUltimoMes( moment( currentMonth ).date( diasMes ).hour(23).minutes(59).seconds(59));
    }, [currentMonth]);

    const clickItem = ( cita ) => {
        //console.log('editCita', editCita);
        if( citaActiva ){
            if( citaActiva._id === cita._id ){
                dispatch( unactiveCita() );
            } else {
                dispatch( activeCita( cita._id, cita ) );
            }
        } else {
            dispatch( activeCita( cita._id, cita ) );
        }

        /* const elUsuario = usuarios.filter( item => (cita.usuario._id) ? item._id === cita.usuario._id : item._id === cita.usuario );
        if( elUsuario.length > 0 ){
            dispatch( activeUsuario( elUsuario[0]._id, elUsuario[0] ) );
            dispatch( activeServicio( cita.servicio._id, cita.servicio ));
            dispatch( activeSucursal( cita.sucursal._id, cita.sucursal ));
            dispatch( activeProfesional( cita.profesional._id, cita.profesional ));
            setEditCita( true );
        } */
        dispatch( activeUsuario( cita.usuario._id, cita.usuario ) );
        dispatch( activeServicio( cita.servicio._id, cita.servicio ));
        dispatch( activeSucursal( cita.sucursal._id, cita.sucursal ));
        dispatch( activeProfesional( cita.profesional._id, cita.profesional ));
        setEditCita( true );
        /* if( citaActiva ){
            if( citaActiva._id === cita._id ){
                dispatch( unactiveCita() );
            } else {
                dispatch( activeCita( cita._id, cita ) );
                dispatch( activeUsuario( cita.usuario._id, cita.usuario ) );
                dispatch( activeServicio( cita.servicio._id, cita.servicio ));
                dispatch( activeSucursal( cita.sucursal._id, cita.sucursal ));
                dispatch( activeProfesional( cita.profesional._id, cita.profesional ));
            }
        } else {
            dispatch( activeCita( cita._id, cita ) );
            dispatch( activeUsuario( cita.usuario._id, cita.usuario ) );
            dispatch( activeServicio( cita.servicio._id, cita.servicio ));
            dispatch( activeSucursal( cita.sucursal._id, cita.sucursal ));
            dispatch( activeProfesional( cita.profesional._id, cita.profesional ));
        } */
    };

    const clickButton = (e, tipo, cita ) => {
        e.stopPropagation();
        if( tipo === 'delete' ){
            dispatch( activeCita( cita._id, cita ) );
            setActiveModalDeleteCita( true );
        }
        if( tipo === 'edit' ){
            setEditCita( true );
        }
    };

    const handleSearchChange = ({target}) => {
        setSearchValue( target.value );
        if( target.value.trim().length > 0 ){
            const nuevasCitas = newCitas.filter( item => {
                let retorno;
                if( item.usuario ){
                    retorno = item.usuario.nombre.toLowerCase().includes( target.value.toLowerCase() ) || item.usuario.email.toLowerCase().includes( target.value.toLowerCase() );
                } else {
                    retorno = false;
                }
                return retorno;
            });
            setCitasFiltered( nuevasCitas );
        } else {
            setCitasFiltered( newCitas );
        }
    };

    const inputClick = ({ target }) => {
        target.select();
    };

    return (
        <div className="citas-list">
            <div className="citas-filter">
                <input 
                    type="text"
                    placeholder="Buscar nombre o email"
                    autoComplete="disabled"
                    value={ searchValue }
                    onClick={ inputClick }
                    onChange={ handleSearchChange }
                />
                <span></span>
            </div>
            <Suspense fallback={<p>Cargando ...</p>}>
            {
                (citasFiltered.length > 0)
                &&
                citasFiltered.map( (cita, citaIdx) => (
                    <div key={ uuid() }>
                    {
                        arrSucursales.map( sucursal => (
                            arrProfesionales.map( profesional => (
                                ( sucursal.selected && (sucursal.sucursal._id === cita.sucursal._id) && profesional.selected && (profesional.profesional._id === cita.profesional._id) )
                                &&
                                    <CitasListItem
                                        key={ uuid() }
                                        usuarioActive={usuarioActive}
                                        citaActiva={citaActiva}
                                        cita={cita}
                                        clickItem={clickItem}
                                        profesional={profesional}
                                        clickButton={clickButton}
                                    />
                            ))
                        ))
                    }
                    </div>
                ))
            }
            </Suspense>
        </div>
    )
}
