import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import { openModalBottom } from '../../../../actions/ui';
import PeriodSelectDate from './PeriodSelectDate';
import PeriodSelectTime from './PeriodSelectTime';

const timezone = process.env.REACT_APP_TIMEZONE;

export const PeriodSelect = ({ editMode, descanso, saveChanges }) => {

    const dispatch = useDispatch();

    const [minutesGap, ] = useState(5);

    const [fechaCalendarSimple, setFechaCalendarSimple] = useState( new Date() );
    const [fechaCalendarCompuesta, setFechaCalendarCompuesta] = useState( [new Date(), new Date()] );
    const [allDaySelected, setAllDaySelected] = useState(false);

    const [initDate, setInitDate] = useState( new Date() );
    const [endDate, setEndDate] = useState(new Date() );

    useEffect(() => {
        const newInitDate = moment.tz( descanso.fechaInicio , timezone).toDate(); 
        const newEndDate = moment.tz( descanso.fechaFin , timezone).toDate(); 
        setInitDate( newInitDate );
        setEndDate( newEndDate );
        setFechaCalendarSimple( newInitDate );
        setFechaCalendarCompuesta( [newInitDate, newEndDate] );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descanso]);

    useEffect(() => {        
        const inicioCompleto = moment( initDate ).format('H:mm:ss') === '0:00:00';
        const finCompleto = moment( endDate ).format('H:mm:ss') === '23:59:00';
        if( inicioCompleto && finCompleto ){
            setAllDaySelected( true );
        } else {
            setAllDaySelected( false );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initDate, endDate]); 

    const dateChange = (e) => {
        // console.log( 'dateChange' );
        // console.log( 'e', e );

        const horasInicio = initDate.getHours();
        const minutosInicio = initDate.getMinutes();
        const horasFin = endDate.getHours();
        const minutosFin = endDate.getMinutes();
        // console.log(`${horasInicio}:${minutosInicio}`);
        // console.log(`${horasFin}:${minutosFin}`);

        if( editMode ){
            //console.log( e );
            if( descanso.tipo === 'ausencia' ){
                setFechaCalendarSimple( e );
                const hora = e;
                const horaInicio = moment.tz(hora.setHours(horasInicio, minutosInicio, 0, 0), timezone).toDate();
                const horaFin = moment.tz(hora.setHours(horasFin, minutosFin, 0, 0), timezone).toDate();
                setInitDate( horaInicio );
                setEndDate( horaFin );
                
                saveChanges( horaInicio, horaFin );
            } else {
                setFechaCalendarCompuesta(e);
                const hora1 = e[0];
                const hora2 = e[1];
                const horaInicio = moment.tz(hora1.setHours(horasInicio, minutosInicio, 0, 0), timezone).toDate();
                const horaFin = moment.tz(hora2.setHours(horasFin, minutosFin, 0, 0), timezone).toDate();
                setInitDate( horaInicio );
                setEndDate( horaFin );
                
                saveChanges( horaInicio, horaFin );
            }
            
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    };

    const toggleDaySelected = (e) => {
        e.stopPropagation();
        // console.log( fechaCalendarSimple );
        // console.log( fechaCalendarCompuesta );
        let horaInicio = moment( initDate ).hours(0).minutes(0).seconds(0).toDate();
        let horaFin = moment( endDate ).hours(23).minutes(59).seconds(0).toDate();
        if( editMode ){
            if( allDaySelected ){
                horaInicio = moment( initDate ).hours(8).minutes(0).seconds(0).toDate();
                horaFin = moment( endDate ).hours(20).minutes(0).seconds(0).toDate();
            }

            setInitDate( horaInicio );
            setEndDate( horaFin );
                
            saveChanges( horaInicio, horaFin );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    };

    const changeHours = ( operation, from = true ) => {
        let newHour;
        if( operation === 'add' ){
            newHour = moment( from ? initDate : endDate ).add(1, 'hours' ).toDate();
        } else {
            newHour = moment( from ? initDate : endDate ).subtract(1, 'hours' ).toDate();
        }
        if( from ){
            setInitDate( newHour );
            saveChanges( newHour, endDate );
        } else {
            setEndDate( newHour );
            saveChanges( initDate, newHour );
        }
    }
    
    const changeMinutes = ( operation, from = true ) => {
        let newHour;
        newHour = moment( from ? initDate : endDate ).add(minutesGap, 'minutes' ).toDate();
        if( operation === 'add' ){
            newHour = moment( from ? initDate : endDate ).add(minutesGap, 'minutes' ).toDate();
        } else {
            newHour = moment( from ? initDate : endDate ).subtract(minutesGap, 'minutes' ).toDate();
        }
        if( from ){
            setInitDate( newHour );
            saveChanges( newHour, endDate );
        } else {
            setEndDate( newHour );
            saveChanges( initDate, newHour );
        }
    }
    
  return (
    <div 
        className={ `vacacional-period`}
    >
        <div 
            className='ylm-select vacacional-period-select'
        >
            <div
                className='selectors'
            >                
                <PeriodSelectDate 
                    initDate={initDate}
                    setInitDate={setInitDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    dateChange={ dateChange }
                    descanso={ descanso }
                    fechaCalendarSimple = { fechaCalendarSimple }
                    fechaCalendarCompuesta= { fechaCalendarCompuesta }
                />
                <PeriodSelectTime
                    initDate={ initDate }
                    endDate={ endDate }
                    changeHours={ changeHours }
                    changeMinutes={ changeMinutes }
                    allDaySelected={ allDaySelected }
                    toggleDaySelected={ toggleDaySelected }
                />
                
            </div>
        </div>
    </div>
  )
}
