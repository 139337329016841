import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ChevronDownIcon } from '../../../assets/svg/iconos';
import { getSegmentacion } from '../../../helpers/helpers';
import useClickOutside from '../../../hooks/useClickOutside';

const segmentacionOptions = [
    { _id: '1', nombre: 'poblacion', text: 'Población' },
    { _id: '2', nombre: 'opcion2', text: 'Opción 2' },
]

export const SelectSegmentacion = ({objNotificacion, dataChange, editMode}) => {
    // const { sucursales } = useSelector(state => state.sucursales);

    const [selectText, setSelectText] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        // console.log( segmentacionOptions );
        // console.log( objNotificacion?.lista );
        if( objNotificacion.lista?.segmentacion ){
            setSelectText( getSegmentacion( objNotificacion.lista.segmentacion )  );
        }
    }, [objNotificacion, /* sucursales */]);
    

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    const clickOption = ( option ) => {
        console.log( option );
        let newLista = {
            ...objNotificacion.lista,
        }
        newLista.segmentacion = option.nombre;
        dataChange('lista', newLista );
        close();
    }

    return (
        <div 
            className='ylm-difusion-select'
        >
            <div 
                className='select-main'
                onClick={ clickSelect }
            >
                <p>{ selectText }</p>
                <ChevronDownIcon color={ '#000000' }/>
            </div>
            {
                isOpen &&
                <ul 
                    className='select-options' 
                    ref={popover}
                >
                    {
                        segmentacionOptions.map( option => (
                            <li
                                key={ option._id }
                                onClick={ () => clickOption( option )}
                            >
                                <p>{ option.text }</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
