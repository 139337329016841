import React, { useRef, useState } from 'react'
import { ListManager } from 'react-beautiful-dnd-grid';
import { useDispatch, useSelector } from 'react-redux';
import { categoriasReorder, categoriaStartDelete } from '../../../actions/categorias';
import { DndIcon } from '../../../assets/svg/iconos';
import { ModalEliminarCategoria } from './modales/ModalEliminarCategoria';
import { CategoriaRowItem } from './categorias/CategoriaRowItem';

export const Categorias = ({ history, categoriasList, setCategoriasList }) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { colores } = useSelector(state => state.ui);
    const wrapper = useRef();

    const [modalEliminarCategoriaOpened, setModalEliminarCategoriaOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar categoría?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [selectedId, setSelectedId] = useState('');
    const [columnas, ] = useState(5);

    const [activeDnd, setActiveDnd] = useState(false);
    
    const editCategoria = ( categoria ) => {
        history.push({
            pathname: `/categoria/${categoria._id}`,
            state: {
                categoria
            }
        });
    };

    /* const deleteCategoria = ( categoria ) => {
        console.log( 'deleteCategoria', categoria );
    }  */   

    const deleteCategoriaModal = ( categoria ) => {
        setSelectedId( categoria._id );
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar ${categoria.nombre}?`]
        })
        setModalEliminarCategoriaOpened( true );
    };

    const deleteCategoriaResponse = ( value ) => {
        if( value === 1 && selectedId !== '' ){
            console.log( 'Borrar id:', selectedId );
            dispatch( categoriaStartDelete( selectedId, empresa ) );
        }
    }

    const noop = (oldPos, newPos) => {
        const itemMoved = categoriasList[oldPos];
        const newList = categoriasList.map( categoria => {
            if( categoria._id === itemMoved._id ){
                categoria.orden = newPos;
            }
            if( newPos < oldPos ){
                if( categoria._id !== itemMoved._id && categoria.orden < oldPos && categoria.orden >= newPos ){
                    categoria.orden = categoria.orden + 1;
                }
            }
            if( newPos > oldPos ){
                if( categoria._id !== itemMoved._id && categoria.orden > oldPos && categoria.orden <= newPos ){
                    categoria.orden = categoria.orden - 1;
                }
            }
            return categoria;
        });

        const sortByOrden = (a,b) => {
            if( a.orden > b.orden ) {
                return 1;
            }
            if( a.orden < b.orden ) {
                return -1;
            } else {
                return 0;
            }
        }

        newList.sort( sortByOrden );
        setCategoriasList( newList );
        dispatch( categoriasReorder( newList, empresa ));
    };

    const toggleDnd = () => {
        setActiveDnd( !activeDnd );
    }

    return (
        <>
        {
            !activeDnd &&
            <div 
                className='categorias'
                style={{
                    '--colorPrincipal': colores.colorPrincipal,
                    '--colorSecundario': colores.colorSecundario,
                    '--color3': colores.color3,
                    '--color4': colores.color4,
                    '--color5': colores.color5,
                    '--color6': colores.color6,
                    '--color7': colores.color7,
                }}
            >
                <div className='categorias-table'>
                  {
                    categoriasList.map( categoria => (
                      <CategoriaRowItem
                        key={ categoria._id }
                        categoria={ categoria }
                        editCategoria={ editCategoria }
                        deleteCategoria={deleteCategoriaModal}
                      />
                    ))
                  }
                </div>
            </div>
        }
        {
            activeDnd
            &&
            <div className='categorias'>
                <div className='categorias-grid dnd' ref={wrapper}>
                    <ListManager
                        items={categoriasList}
                        direction="horizontal"
                        maxItems={columnas}
                        render={categoria => !categoria.deleted && 
                            <></>
                        }
                        onDragEnd={noop}
                    />
                </div>
            </div>
        }
        <div 
            className={ `toggle-dnd ${activeDnd ? 'active' : 'unactive'}`}
            onClick={ toggleDnd }
        ><DndIcon color={ activeDnd ? 'white' : colores.colorPrincipal } /><p>Drag & Drop</p></div>
        <ModalEliminarCategoria
            data = { dataModalEliminar }
            setModalResponse = { deleteCategoriaResponse }
            modalOpened = { modalEliminarCategoriaOpened }
            setModalOpened = { setModalEliminarCategoriaOpened }
        />
        </>
    )
}
