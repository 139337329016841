import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export const Lateral = ({ setActiveComponent }) => {
    const { colores } = useSelector(state => state.ui);

    const initialItems = [
        { _id: 'general', active: true, name: 'General' },
        { _id: 'reservas', active: false, name: 'Reservas' },
        { _id: 'personalizacion', active: false, name: 'Personalización' },
        { _id: 'idiomas', active: false, name: 'Idiomas' },
        { _id: 'ayuda', active: false, name: 'Ayuda' },
        { _id: 'legal', active: false, name: 'Legal' },
    ]

    const [items, setItems] = useState( initialItems );

    const handleClick = ({ _id }) => {
        setActiveComponent( _id );
        setItems(
            items.map( item => {
                ( item._id === _id ) ? item.active = true : item.active = false; 
                return item;
            })
        )
    };

  return (
    <div
        className='lateral'
        style={{ backgroundColor: colores.color6 }}
    >
        <div className='list'>
            <div 
                className='list-item list-header'
                style={{ color: colores.colorPrincipal }}
            >Configuración</div>
            { items.map( (item) => (
                <div
                    key={ item._id }
                    className={ `list-item ${ item.active && 'active' }` }
                    style={{ 
                        color: item.active ? 'white' : colores.colorPrincipal,
                        backgroundColor: item.active ? colores.colorPrincipal : 'transparent'
                    }}
                    onClick={ () => handleClick( item )}
                >{ item.name }</div>
            ))}
        </div>
    </div>
  )
}
