import { types } from "../types/types";
import { fetchConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewCategoria = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'categorias', values, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                /* const datos = {
                    title: 'Creado',
                    text: [ values.nombre ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) ); */
                const datos = {
                    text: [ `La categoría ${ values.nombre } ha sido creada` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewCategoria( body.categoria._id, body.categoria ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activeCategoria = ( _id, categoria ) => ({
    type: types.categoriasActive,
    payload: {
        _id,
        ...categoria
    }
});

export const unactiveCategoria = () => ({
    type: types.categoriasUnactive
});

export const addNewCategoria = ( _id, categoria ) => ({
    type: types.categoriasAddNew,
    payload: {
        _id,
        ...categoria
    }
})

export const startLoadingCategoria = ( id, empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `categorias/categoria/${ id }`, {empresa}, 'POST' );
            const body = await resp.json();
            if( body.categoria ){
                dispatch( activeCategoria( body.categoria._id, body.categoria ));
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingCategorias = ( empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'categorias/empresa', {empresa}, 'POST' );
            const body = await resp.json();
            dispatch( setCategorias( body.categorias ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setCategorias = ( categorias ) => ({
    type: types.categoriasLoad,
    payload: categorias
})

export const startSaveCategoria = ( categoria, swal = true ) => {
    return async( dispatch, getState ) => {
        
        try {
            const resp = await fetchConToken( `categorias/${ categoria._id }`, categoria, 'PUT' );
            const body = await resp.json();
            if ( body.ok ) {
                dispatch( refreshCategoria( categoria._id, body.categoria ) );
                const datos = {
                    text: [ `Categoría guardada` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            } else {
                console.log( body );
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshCategoria = ( id, categoria ) => ({
    type: types.categoriasUpdated,
    payload: {
        ...categoria
    }
});

export const categoriaStartDelete = ( id, empresa ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `categorias/${ id }`, {empresa}, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                dispatch( categoriaDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

export const categoriasReorder = ( categoriasList, empresa ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( `categorias/reorder`, {categoriasList, empresa}, 'POST' );
            const body = await resp.json();
            if ( body.ok ) {
                console.log( 'Reordered' );
            }
            
        } catch (error) {
            console.log(error);            
        }
    }
};

const categoriaDeleted = ( id ) => ({
    type: types.categoriasDelete,
    payload: id
});

export const categoriaLogout = () => ({
    type: types.categoriasLogoutCleaning
});
