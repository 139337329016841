import { getDifusion } from "../../../helpers/helpers";
import { RowSelect } from "./RowSelect";

const NotificacionesRowItem = ({notificacion, editNotificacion, deleteNotificacion}) => {
    
    const clickEdit = (id) => {
        editNotificacion(notificacion);
    };

    const clickDelete = (id, name) => {
        deleteNotificacion(id, name);
    };

    return (
        <div 
            className="notificacion-row"
            onClick={ () => editNotificacion( notificacion )}
        >
            <div className="col-nombre noti-nombre">{ notificacion.nombre }</div>
            <div className="col-mensaje noti-mensaje">
                { notificacion.mensaje.substring(0, 67) }
                {
                    notificacion.mensaje.length > 70 &&
                    <span>...</span>
                }
                
            </div>
            <div className="col-list noti-list">
                <span>{ `${notificacion.segmentacion ? 'Segmentada: ' : 'Todos'}` }</span>
                {
                    notificacion.segmentacion &&
                    <p>{ getDifusion(notificacion.lista.difusion) }</p>
                }
            </div>
            <div className="col-btn">
                <RowSelect
                    profId = { notificacion._id }
                    profName= { notificacion.nombre }
                    clickEdit = { clickEdit }
                    clickDelete = { clickDelete }
                />
            </div>
        </div>
    )
}

export default NotificacionesRowItem