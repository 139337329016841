export const LogoExora = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="112" height="24.757" viewBox="0 0 112 24.757">
            <defs>
                <clipPath id="clip-path">
                <rect id="Rectángulo_1205" data-name="Rectángulo 1205" width="112" height="24.757" fill="none"/>
                </clipPath>
            </defs>
            <g id="Grupo_1613" data-name="Grupo 1613" transform="translate(0 0)">
                <g id="Grupo_1612" data-name="Grupo 1612" transform="translate(0 0)" clipPath="url(#clip-path)">
                <path id="Trazado_904" data-name="Trazado 904" d="M55.529,21.753H44.721a3.481,3.481,0,0,0,1.094,2.187,3.383,3.383,0,0,0,2.234.7,3.71,3.71,0,0,0,1.917-.444,2.179,2.179,0,0,0,1-1.3l4.374,1.394a5.327,5.327,0,0,1-2.583,3.09,9.6,9.6,0,0,1-4.706,1.062,8.713,8.713,0,0,1-6.355-2.155,8.123,8.123,0,0,1-2.2-6.085,8.232,8.232,0,0,1,2.155-6.086,8.276,8.276,0,0,1,6.117-2.154,7.82,7.82,0,0,1,5.879,2.107,8.139,8.139,0,0,1,2.044,5.911,8.2,8.2,0,0,1-.158,1.775M44.69,18.837h5.959q-.381-3.106-2.916-3.106-2.568,0-3.043,3.106" transform="translate(-12.525 -3.793)" fill="#fff"/>
                <path id="Trazado_905" data-name="Trazado 905" d="M76.046,28.271,72.94,22.756l-3.169,5.515H63.812l5.705-8.019-5.483-7.828h6.116l2.916,5.292,2.979-5.292H82l-5.515,7.86,5.641,7.987Z" transform="translate(-20.238 -3.94)" fill="#fff"/>
                <path id="Trazado_906" data-name="Trazado 906" d="M105.961,14.131a8.138,8.138,0,0,1,2.2,6.069,8.137,8.137,0,0,1-2.2,6.069A8.4,8.4,0,0,1,99.8,28.44a8.4,8.4,0,0,1-6.164-2.171,8.134,8.134,0,0,1-2.2-6.069,8.135,8.135,0,0,1,2.2-6.069A8.4,8.4,0,0,1,99.8,11.96a8.407,8.407,0,0,1,6.165,2.171m-8.415,2.71a6,6,0,0,0-.729,3.36,6,6,0,0,0,.729,3.36,2.945,2.945,0,0,0,4.5,0,6.005,6.005,0,0,0,.729-3.36,6.007,6.007,0,0,0-.729-3.36,2.943,2.943,0,0,0-4.5,0" transform="translate(-28.997 -3.793)" fill="#fff"/>
                <path id="Trazado_907" data-name="Trazado 907" d="M131.988,12.308l-.57,4.564a3.355,3.355,0,0,0-.856-.333,4.449,4.449,0,0,0-1.14-.143,3.533,3.533,0,0,0-2.536.967,3.651,3.651,0,0,0-1.014,2.773v7.987h-5.388V12.277h4.754l.222,3.422a5.552,5.552,0,0,1,1.79-2.773,4.725,4.725,0,0,1,3.027-.966,3.566,3.566,0,0,1,1.711.348" transform="translate(-38.211 -3.793)" fill="#fff"/>
                <path id="Trazado_908" data-name="Trazado 908" d="M156.025,24.923l-.412,3.011a4.4,4.4,0,0,1-2.155.507q-3.422,0-4.088-2.44-1.427,2.441-5.642,2.44a5.711,5.711,0,0,1-3.644-1.014,3.55,3.55,0,0,1-1.268-2.947,4.136,4.136,0,0,1,1.442-3.28A10.479,10.479,0,0,1,145,19.281l3.582-.7v-.57a2.388,2.388,0,0,0-.6-1.712,2.168,2.168,0,0,0-1.648-.634,3.186,3.186,0,0,0-1.965.57,2.812,2.812,0,0,0-.983,1.87l-4.374-1.268a5.309,5.309,0,0,1,2.282-3.6,8.768,8.768,0,0,1,5.039-1.283,8.973,8.973,0,0,1,5.642,1.521,5.452,5.452,0,0,1,1.933,4.563v5.768q0,1.236,1.141,1.236a3.172,3.172,0,0,0,.983-.126m-8.336-.587a1.9,1.9,0,0,0,.888-1.632V21.341l-2.568.6a3.649,3.649,0,0,0-1.442.618,1.281,1.281,0,0,0-.427,1.03,1.256,1.256,0,0,0,.412,1.015,1.713,1.713,0,0,0,1.141.348,3.414,3.414,0,0,0,2-.618" transform="translate(-44.025 -3.793)" fill="#fff"/>
                <path id="Trazado_909" data-name="Trazado 909" d="M25.006,3.8A3.8,3.8,0,1,0,28.8,0a3.8,3.8,0,0,0-3.8,3.8" transform="translate(-7.931 0)" fill="#ff768a"/>
                <path id="Trazado_910" data-name="Trazado 910" d="M15.7,13.268H0a15.7,15.7,0,0,0,15.7,15.7" transform="translate(0 -4.208)" fill="#fff"/>
                </g>
            </g>
        </svg>
    )
}