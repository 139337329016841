import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingComentariosCliente } from '../../../actions/comentarios';
import { SaveIcon, TrashIcon } from '../../../assets/svg/iconos';
import { openModalBottom } from '../../../actions/ui';

export const Comentarios = ({objCliente, dataChange, editMode}) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { colores } = useSelector(state => state.ui);

    const [nuevoComentario, setNuevoComentario] = useState(false);
    const [comentarioText, setComentarioText] = useState('');

    useEffect(() => {
        //dispatch( startLoadingComentariosCliente( empresa, objCliente._id) );
    	// eslint-disable-next-line react-hooks/exhaustive-deps
		console.log('objCliente.comentarios', objCliente.comentarios)
    }, [objCliente]);

    const saveComentario = () => {
        if( comentarioText.length > 0 ){
            const newComentarios = [
                ...objCliente.comentarios,
                {
                    fecha: new Date(),
                    texto: comentarioText
                }
            ];
			objCliente.comentarios = newComentarios;
            dataChange( 'comentarios', newComentarios );
            setNuevoComentario( false );
            setComentarioText( '' );
        }
    }

    const deleteComentario = ( idx ) => {
        if( editMode ){
            const newComentarios = objCliente.comentarios.filter( (item, index) => {
                return index !== idx;
            });
			objCliente.comentarios = newComentarios;
            dataChange( 'comentarios', newComentarios );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    return (
        <div className='comentarios'>
            {
                objCliente.comentarios &&
                objCliente.comentarios.map( (comentario, idx) => (
                    <div 
                        key={ idx }
                        className='comentario-row'
                        style={{ backgroundColor: colores.color6 }}
                    >
                        <div className='comentario-date'>
                            <p><b>{ moment( comentario?.fecha ).format('D/M/YY') }</b> { moment( comentario?.fecha ).format('H:mm') }h</p>
                        </div>
                        <div className='comentario-text'>
                            <p>{ comentario.texto }</p>
                        </div>
                        {
                            editMode &&
                            <button
                                onClick={ () => deleteComentario( idx ) }
                                style={{ backgroundColor: colores.color6 }}
                            >
                                <TrashIcon color={ colores.colorPrincipal } />
                            </button>
                        }
                    </div>
                ))
            }
            {
                editMode &&
                <div className='new-comentario' style={{ backgroundColor: colores.color6 }}>
                    {
                        nuevoComentario
                        ?
                        <div className='comentario-text'>
                            <input
                                type="text"
                                name="comentarioText"
                                placeholder={`Introduce el nuevo comentario`}
                                value={ comentarioText }
                                onChange={ (e) => setComentarioText( e.target.value ) }
                            />
                            <button
                                onClick={ saveComentario }
                            >
                                <SaveIcon />
                            </button>
                        </div>
                        :
                        <p
                            onClick={ () => setNuevoComentario( true ) }
                            style={{ color: colores.colorPrincipal }}
                        >+ Escribir comentario</p>
                    }
                </div>
            }
        </div>
    )
}
