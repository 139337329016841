import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openModal } from '../../actions/ui';
import { isEmail } from '../../helpers/forms';
import { useForm } from '../../hooks/useForm';

import bgImage from '../../assets/img/bg_login_exora.jpg';
import appLogo from '../../assets/svg/logo-exora.svg';
import { Link } from 'react-router-dom';
import { fetchSinToken } from '../../helpers/fetch';

const initErrores = {
    email: { active: false, msg: 'Debe introducir un email correcto.'}
}

export const RecoverPassword = () => {
    
    const dispatch = useDispatch();
    const { loading } = useSelector( state => state.ui );
    const { uid } = useSelector(state => state.auth);

    const [ formValues, handleInputChange, reset ] = useForm({
        email: ''
    });

    // eslint-disable-next-line no-unused-vars
    const [messageVisible, setMessageVisible] = useState(true);
    const [errores, setErrores] = useState( initErrores );

    const { email } = formValues;

    const getFormErrors = () => {
        let hayErrores = false;
        const objErrores = { ...errores };

        if( !isEmail( email ) ){
            hayErrores = true;
            objErrores.email.active = true; 
        } else {
            objErrores.email.active = false; 
        }

        setErrores( objErrores );
        return hayErrores;
    }

    const handleChange = (e) => {
        e.preventDefault();
        const hayErrores = getFormErrors();

        if( !hayErrores ){
            console.log( 'Enviar petición' );
            cambiar( email );
            reset();
        }
    };

    const cambiar = async( email ) => {
        const resp = await fetchSinToken( 'auth/business-reset-url', { email }, 'POST');
        const body = await resp.json();
        if( body.ok ){
            console.log( body );
        } else {
            const datos = {
                title: 'Error',
                text: ( Array.isArray( body.msg ) ) ? body.msg : [body.msg],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    };

    return (
        <div className={`login-container recover ${(!uid) ? 'active' : ''}`} style={{ backgroundImage: `url('${bgImage}`}}>
            <div className="form-container">
                <div className="form-header">
                    <Link to="/"><img src={appLogo} alt="logotipo"></img></Link>                    
                </div>
                <h1>Recuperación contraseña</h1>
                <form onSubmit={ handleChange }>
                    <div className="form-item">
                        <input 
                            type="email"
                            placeholder="Tu email"
                            name="email"
                            className="auth__input"
                            autoComplete="disabled"
                            value={ email }
                            onChange={ handleInputChange }
                        />
                        <p className={`error-text ${(errores.email.active) ? 'active' : ''}`}>
                            { errores.email.msg }
                        </p>
                    </div>

                    <div>
                        <button
                            className={ `btn-iniciar-sesion visible`}
                            type="submit"
                            disabled={ loading }
                        >
                            Recuperar
                        </button>
                    </div>

                    <div className="form-messages recover">
                        <p
                            className="remember-password"
                            // onClick={ rememberPassword }
                        >
                            Te enviaremos un email para crear una nueva contraseña
                        </p>
                    </div>
                    <Link to="/">Volver a la página de inicio</Link>
                </form>
            </div>
        </div>
    )
}

