import React, { useEffect, useState } from 'react';

import avatar from '../../../assets/img/list-avatar.png';
import { Rating } from '../../../components/tools/Rating';

import { DescansosIcon, PermisosIcon, ServiciosIcon, TimeIcon } from '../../../assets/svg/iconos';
import { RowSelect } from './RowSelect';
import { formatColor } from '../../reservas/helpers/helpersReservas';
import { useSelector } from 'react-redux';
import { fetchSinToken } from '../../../helpers/fetch';

export const ProfesionalesRowItem = ({ setActiveTab, profesional, editProfesional, deleteProfesional}) => {

    const { colores } = useSelector(state => state.ui);

    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
      //console.log( 'profesional', profesional );
      if( profesional.img.length > 0 ){
        getImagenPrincipal( `${profesional.img[0]}` );
    }
    }, [profesional]);
    

    const botonClicked = ( e, boton ) => {
        e.stopPropagation();
        setActiveTab( boton );
        editProfesional( profesional, boton );
    }
    
    const clickEdit = (id) => {
        editProfesional(profesional);
    };

    const clickDelete = (id, name) => {
        deleteProfesional(id, name);
    };
    
    const getImagenPrincipal = async( nombre ) => {
        setImageUrl( { url: nombre } );
    }

    return (
        <div 
            key={ profesional._id }
            className={ `row-profesionales row-${ profesional._id }` }
            style={{ backgroundColor: colores.color6 }}
            onClick={ () => clickEdit( profesional._id )}
        >
            <div className="color" style={{ backgroundColor: `${formatColor(profesional.color)}` }}></div>
            <div 
                className="avatar"
                style={{ backgroundImage: `${imageUrl.url ? 'url('+ imageUrl.url +'?'+ imageUrl.url +')' : 'none'}` }}
            >
                {
                    !imageUrl &&
                    <img src={ avatar } alt="Avatar usuario"/>
                }
                
            </div>
            <div className="nombre">{ profesional.nombre }</div>
            <div className="stars">
                <div className="titulo">Valoraciones</div>
                <Rating ratingValue={parseInt(profesional.valoracion)} />
            </div>
            <div className="botonera">
                <div 
                    className="boton btn-horarios"
                    style={{ backgroundColor: colores.colorSecundario }}
                    onClick={ (e) => botonClicked( e, 'horarios') }
                ><TimeIcon /></div>
                <div
                    className="boton btn-descansos"
                    style={{ backgroundColor: colores.colorSecundario }}
                    onClick={ (e) => botonClicked( e, 'descansos') }
                ><DescansosIcon /></div>
                <div 
                    className="boton btn-servicios"
                    style={{ backgroundColor: colores.colorSecundario }}
                    onClick={ (e) => botonClicked( e, 'servicios') }
                ><ServiciosIcon /></div>
                <div 
                    className="boton btn-permisos"
                    style={{ backgroundColor: colores.colorSecundario }}
                    onClick={ (e) => botonClicked( e, 'permisos') }
                ><PermisosIcon /></div>
            </div>
            <RowSelect
                profId = { profesional._id }
                profName= { profesional.nombre }
                clickEdit = { clickEdit }
                clickDelete = { clickDelete }
            />
        </div>
    )
}
