
import { types } from "../types/types";

const initialState = {
    categorias: [],
    active: null
}

export const categoriasReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.categoriasActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.categoriasUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.categoriasAddNew:
            return {
                ...state,
                categorias: [ ...state.categorias, action.payload ]
            }

        case types.categoriasLoad:
            return {
                ...state,
                categorias: [ ...action.payload ]
            }
        
        case types.categoriasUpdated:
            return {
                ...state,
                categorias: state.categorias.map(
                    categoria => categoria._id === action.payload._id
                        ? action.payload
                        : categoria
                )
            }

        case types.categoriasDelete:
            return {
                ...state,
                active: null,
                categorias: state.categorias.filter( categoria => categoria._id !== action.payload )
            }
    
        case types.categoriasLogoutCleaning:
            return {
                ...state,
                active: null,
                categorias: []
            }
    

        default:
            return state;
    }
}