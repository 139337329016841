import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startSaveProfesional } from '../../../actions/profesionales';
import { openModalBottom } from '../../../actions/ui';
import { DeleteIcon } from '../../../assets/svg/iconos';
import { AddServicioSelect } from './servicios/AddServicioSelect'

export const Servicios = ({ objProfesional, setObjProfesional, editMode }) => {

    const dispatch = useDispatch();
    const { colores } = useSelector(state => state.ui);
    const { servicios } = useSelector(state => state.servicios);

    const [serviciosProf, setServiciosProf] = useState([]);    
    const [serviciosList, setServiciosList] = useState([]);

    useEffect(() => {
        if( objProfesional?.nombre ){
            setServiciosProf( [...objProfesional.servicios] );
            let newList = [];
            objProfesional.servicios.forEach( profServicio => {
                servicios.forEach(servicio => {
                    if( profServicio === servicio._id && !servicio.deleted ){
                        newList = [
                            ...newList,
                            servicio
                        ]
                    }
                });
            });
            setServiciosList( newList );
        }
    }, [objProfesional, servicios]);

   /*  useEffect(() => {
      console.log( serviciosProf );
      console.log( servicios );
      console.log( serviciosList );
    }, [serviciosProf, servicios, serviciosList]); */
    

    const updateServicio = ( servicio ) => {
        if( editMode ){
            // console.log( 'click' );
            const newProfesional = { ...objProfesional };
            let newServicios = [];
            if( objProfesional.servicios.includes( servicio._id ) ){
                newServicios = objProfesional.servicios.filter( (item) => item !== servicio._id );
            } else {
                newServicios = [
                    ...objProfesional.servicios,
                    servicio._id
                ]
            }
            newProfesional.servicios = newServicios;
            setObjProfesional( newProfesional );
            dispatch( startSaveProfesional( newProfesional ) );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    };

    return (
        <div className='tab-servicios'>
            <div 
                className='add-servicio'
                style={{ 
                    backgroundColor: colores.color6,
                    color: colores.colorPrincipal
                }}
            >
                <div className='row-1'>
                    <AddServicioSelect
                        serviciosProf = { serviciosProf }
                        updateServicio = { updateServicio }
                    />
                    {/* <div className='botonera'>
                        <div className='button btn-add'><SaveIcon color={ colores.colorPrincipal } /></div>
                        <div className='button btn-delete'><TrashIcon color={ colores.colorPrincipal } /></div>
                    </div> */}
                </div>
                <div className='row-2'>
                    <div className='servicios-list'>
                        {
                            serviciosList.map( servicio => (
                                <div
                                    key={ servicio._id }
                                    className='servicio-list-item'
                                >
                                    <p>{ servicio.nombre }</p>
                                    {
                                        editMode &&
                                        <div 
                                            className='btn-delete'                                            
                                            onClick={ () => updateServicio( servicio ) }
                                        >
                                            <DeleteIcon 
                                                color={ colores.colorPrincipal } 
                                                background={ colores.color6 }
                                            />
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
