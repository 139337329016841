import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListManager } from "react-beautiful-dnd-grid";

import { serviciosReorder, servicioStartDelete } from '../../../actions/servicios';
import { ModalEliminarServicio } from './modales/ModalEliminarServicio';
import { ServicioCard } from './servicios/ServicioCard';
import { DndIcon } from '../../../assets/svg/iconos';

export const Servicios = ({ history, serviciosList, setServiciosList }) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { colores } = useSelector(state => state.ui);
    const wrapper = useRef();
    const [modalEliminarServicioOpened, setModalEliminarServicioOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar servicio?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [selectedId, setSelectedId] = useState('');
    const [columnas, setColumnas] = useState(5);

    const [activeDnd, setActiveDnd] = useState(false);

    useEffect(() => {
        const columns = Math.floor(wrapper.current.clientWidth / 180);        
        setColumnas( columns );
    }, []);

    // useEffect(() => {
    //   console.log( estadoInicial );
    // }, [estadoInicial]);
    
    const editServicio = ( servicio ) => {
        history.push({
            pathname: `/servicio/${servicio._id}`,
            state: {
                servicio
            }
        });
    };

    const deleteServicioModal = ( servicio ) => {
        setSelectedId( servicio._id );
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar ${servicio.nombre}?`]
        })
        setModalEliminarServicioOpened( true );
    };

    const deleteServicioResponse = ( value ) => {
        if( value === 1 && selectedId !== '' ){
            console.log( 'Borrar id:', selectedId );
            dispatch( servicioStartDelete( selectedId, empresa ) );
        }
    }

    const noop = (oldPos, newPos) => {
        const itemMoved = serviciosList[oldPos];
        const newList = serviciosList.map( servicio => {
            if( servicio._id === itemMoved._id ){
                servicio.orden = newPos;
            }
            if( newPos < oldPos ){
                if( servicio._id !== itemMoved._id && servicio.orden < oldPos && servicio.orden >= newPos ){
                    servicio.orden = servicio.orden + 1;
                }
            }
            if( newPos > oldPos ){
                if( servicio._id !== itemMoved._id && servicio.orden > oldPos && servicio.orden <= newPos ){
                    servicio.orden = servicio.orden - 1;
                }
            }
            return servicio;
        });

        const sortByOrden = (a,b) => {
            if( a.orden > b.orden ) {
                return 1;
            }
            if( a.orden < b.orden ) {
                return -1;
            } else {
                return 0;
            }
        }

        newList.sort( sortByOrden );
        setServiciosList( newList );
        dispatch( serviciosReorder( newList, empresa ));
    };

    const toggleDnd = () => {
        setActiveDnd( !activeDnd );
    }

    return (
        <>
        {
            !activeDnd
            &&
            <div 
                className='servicios'
                style={{
                    '--colorPrincipal': colores.colorPrincipal,
                    '--colorSecundario': colores.colorSecundario,
                    '--color3': colores.color3,
                    '--color4': colores.color4,
                    '--color5': colores.color5,
                    '--color6': colores.color6,
                    '--color7': colores.color7,
                }}
            >
                <div className='servicios-grid' ref={wrapper}>
                    {
    
                        serviciosList.map( servicio => (
                            !servicio.deleted &&
                            <ServicioCard
                                key={ servicio._id }
                                servicio={ servicio }
                                editServicio={ editServicio }
                                deleteServicio={ deleteServicioModal }
                            />
                        ))
                    }
                </div>
            </div>
        }
        {
            activeDnd
            &&
            <div className='servicios'>
                <div className='servicios-grid dnd' ref={wrapper}>
                    <ListManager
                        items={serviciosList}
                        direction="horizontal"
                        maxItems={columnas}
                        render={servicio => 
                            !servicio.deleted &&
                            <ServicioCard
                                key={ servicio._id }
                                servicio={ servicio }
                                editServicio={ editServicio }
                                deleteServicio={ deleteServicioModal }
                            />
                        }
                        onDragEnd={noop}
                    />
                </div>
            </div>
        }
        <div 
            className={ `toggle-dnd ${activeDnd ? 'active' : 'unactive'}`}
            onClick={ toggleDnd }
        ><DndIcon color={ activeDnd ? 'white' : colores.colorPrincipal } /><p>Drag & Drop</p></div>

        <ModalEliminarServicio
            data = { dataModalEliminar }
            setModalResponse = { deleteServicioResponse }
            modalOpened = { modalEliminarServicioOpened }
            setModalOpened = { setModalEliminarServicioOpened }
        />
        </>
    )
}
