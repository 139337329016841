import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModalBottom } from '../../../../actions/ui';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const TypeSelect = ({ tipo, setTipo, editMode }) => {

    const dispatch = useDispatch();

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const showDropdown = () => {
        if( editMode ){
            setVisibility(true);
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = ( valor ) => {
        setTipo( valor );
        hideDropdown();
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );
    
  return (
    <div 
        className={ `vacacional-type`}
        onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
    >
        <div 
            className='ylm-select vacacional-type-select'
        >
            <div className='selector'>
                <p>{ `${ tipo === 'ausencia' ? 'Ausencia' : tipo === 'vacaciones' ? 'Vacaciones' : 'Traslado' }` }</p>
                <span><ChevronDownIcon color={ `#000000` } /></span>
            </div>
            <ul
                ref={ dropdownMenuRef }
                className={`ylm-select-options vacacional-type-options ${ visibility ? 'active' : '' }`}
            >
                <li
                    key={ `type1` }
                    className={ `select-option check-option ${ tipo === 'ausencia' && 'active' }` }
                    onClick={ () => selectItem('ausencia')}
                >{ `Ausencia` }</li>
                <li
                    key={ `type2` }
                    className={ `select-option check-option ${ tipo === 'vacaciones' && 'active' }` }
                    onClick={ () => selectItem('vacaciones')}
                >{ `Vacaciones` }</li>
                {/* <li
                    key={ `type3` }
                    className={ `select-option check-option ${ tipo === 'traslado' && 'active' }` }
                    onClick={ () => selectItem('traslado')}
                >{ `Traslado` }</li> */}
            </ul>
        </div>
    </div>
  )
}
