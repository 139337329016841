import React, { useState, useEffect } from 'react';
import { startNewTextLegal, startSaveTextLegal, startLoadTextLegal } from '../../../actions/textLegal';
import { openModal } from '../../../actions/ui';
import { useDispatch, useSelector } from 'react-redux';
import { UploadImageIcon } from '../../../assets/svg/iconos';
import TextEditor from './editor/TextEditor';

export const Legal = () => {
  const dispatch = useDispatch();

  const { empresas } = useSelector(state => state.empresas);
  const { colores } = useSelector(state => state.ui);
  const { textLegal } = useSelector(state => state.textLegal);
  const [activeTab, setActiveTab] = useState('avisoLegal');
  const [avisoLegal, setAvisoLegal] = useState("");
  const [politicaPrivacidad, setPoliticaPrivacidad] = useState("");
  const [politicaPrivacidadRRSS, setPoliticaPrivacidadRRSS] = useState("");
  const [politicaCookies, setPoliticaCookies] = useState("");
  const [terminosYCondiciones, setTerminosYCondiciones] = useState("");

  const validateFields = () => {
    const emptyFields = [];
    
    if (!avisoLegal || avisoLegal === '<p></p>' || avisoLegal === '<p><br></p>') {
      emptyFields.push('Aviso Legal');
    }
    if (!terminosYCondiciones || terminosYCondiciones === '<p></p>' || terminosYCondiciones === '<p><br></p>') {
      emptyFields.push('Términos y Condiciones');
    }    
    if (!politicaPrivacidad || politicaPrivacidad === '<p></p>' || politicaPrivacidad === '<p><br></p>') {
      emptyFields.push('Política de Privacidad');
    }
    if (!politicaPrivacidadRRSS || politicaPrivacidadRRSS === '<p></p>' || politicaPrivacidadRRSS === '<p><br></p>') {
      emptyFields.push('Política de Privacidad RRSS');
    }
    if (!politicaCookies || politicaCookies === '<p></p>' || politicaCookies === '<p><br></p>') {
      emptyFields.push('Política de Cookies');
    }

    if (emptyFields.length > 0) {
      const datos = {
        title: 'Campos obligatorios',
        text: [`Los siguientes campos son obligatorios: ${emptyFields.join(', ')}`],
        buttonText: 'Cerrar'
      }
      dispatch(openModal(datos));
      return false;
    }

    return true;
  };

  const saveChanges = () => {
    if (!validateFields()) {
      return;
    }

    const objTextLegal = {
      avislegal: avisoLegal,
      politicaprivadesa: politicaPrivacidad,
      politicaprivadesaxarxessocials: politicaPrivacidadRRSS,
      politicacookies: politicaCookies,
      terminosycondiciones: terminosYCondiciones,
      empresa: empresas[0]._id
    }
    
    if (textLegal === undefined) {
      dispatch(startNewTextLegal(objTextLegal));
    } else {
      dispatch(startSaveTextLegal(empresas[0]._id, objTextLegal));
    }
  }

  useEffect(() => {
    dispatch(startLoadTextLegal(empresas[0]._id));
  }, [empresas]);
  
  useEffect(() => {
    if (textLegal) {
      if (textLegal.avislegal) {
        try {
          setAvisoLegal(textLegal.avislegal);
        } catch (e) {
          setAvisoLegal(textLegal.avislegal);
        }
      } else {
        setAvisoLegal("");
      }

      if (textLegal.terminosycondiciones) {
        try {
          setTerminosYCondiciones(textLegal.terminosycondiciones);
        } catch (e) {
          setTerminosYCondiciones(textLegal.terminosycondiciones);
        }
      } else {
        setTerminosYCondiciones("");
      }

  
      if (textLegal.politicaprivadesa) {
        try {
          setPoliticaPrivacidad(textLegal.politicaprivadesa);
        } catch (e) {
          setPoliticaPrivacidad(textLegal.politicaprivadesa);
        }
      } else {
        setPoliticaPrivacidad("");
      }
  
      if (textLegal.politicaprivadesaxarxessocials) {
        try {
          setPoliticaPrivacidadRRSS(textLegal.politicaprivadesaxarxessocials);
        } catch (e) {
          setPoliticaPrivacidadRRSS(textLegal.politicaprivadesaxarxessocials);
        }
      } else {
        setPoliticaPrivacidadRRSS("");
      }
  
      if (textLegal.politicacookies) {
        try {
          setPoliticaCookies(textLegal.politicacookies);
        } catch (e) {
          setPoliticaCookies(textLegal.politicacookies);
        }
      } else {
        setPoliticaCookies("");
      }

      
    }
  }, [textLegal]);
  
  const onChangeEditor = (column, editorState) => { 
    switch (activeTab) { 
      case 'avisoLegal': 
        setAvisoLegal(editorState); 
        break; 
      case 'terminosYCondiciones': 
        setTerminosYCondiciones(editorState); 
        break; 
      case 'politicaPrivacidad': 
        setPoliticaPrivacidad(editorState); 
        break; 
      case 'politicaPrivacidadRRSS': 
        setPoliticaPrivacidadRRSS(editorState); 
        break; 
      case 'politicaCookies': 
        setPoliticaCookies(editorState); 
        break; 
      default: break; 
    }
  };

  return (
    <div className='configuracion-wrapper'>
      <div className='header'>
        <h1 style={{ color: colores.colorPrincipal }}>Legal</h1>
        <div className='botonera'>
          <button
            className='btn btn-primary'
            style={{ color: colores.color6, backgroundColor: colores.colorPrincipal }}
            onClick={saveChanges}
          >
            Aplicar
          </button>
          <button
            className='btn btn-secondary'
            style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
          >
            Cancelar
          </button>
        </div>
      </div>
      
      <div className='customize-body'>
        <div className='left legal-tabs'>
          <div className='tabs'>
            <div
              className={`tab ${activeTab === 'avisoLegal' && 'active'}`}
              style={{
                color: activeTab === 'avisoLegal' ? colores.colorPrincipal : colores.colorSecundario
              }}
              onClick={() => setActiveTab('avisoLegal')}
            >
              Aviso Legal
            </div>
            <div
              className={`tab ${activeTab === 'terminosYCondiciones' && 'active'}`}
              style={{
                color: activeTab === 'terminosYCondiciones' ? colores.colorPrincipal : colores.colorSecundario
              }}
              onClick={() => setActiveTab('terminosYCondiciones')}
            >
              Términos y Condiciones
            </div>
            <div
              className={`tab ${activeTab === 'politicaPrivacidad' && 'active'}`}
              style={{
                color: activeTab === 'politicaPrivacidad' ? colores.colorPrincipal : colores.colorSecundario
              }}
              onClick={() => setActiveTab('politicaPrivacidad')}
            >
              Política de privacidad
            </div>
            <div
              className={`tab ${activeTab === 'politicaPrivacidadRRSS' && 'active'}`}
              style={{
                color: activeTab === 'politicaPrivacidadRRSS' ? colores.colorPrincipal : colores.colorSecundario
              }}
              onClick={() => setActiveTab('politicaPrivacidadRRSS')}
            >
              Política de privacidad RRSS
            </div>
            <div
              className={`tab ${activeTab === 'politicaCookies' && 'active'}`}
              style={{
                color: activeTab === 'politicaCookies' ? colores.colorPrincipal : colores.colorSecundario
              }}
              onClick={() => setActiveTab('politicaCookies')}
            >
              Política de cookies
            </div>
          </div>

          <div className='panel-body left-body'>
            {activeTab === 'avisoLegal' && (
              <TextEditor 
                value={avisoLegal} 
                onChange={onChangeEditor} 
                column={1}
                />
            )}
            {activeTab === 'terminosYCondiciones' && (
              <TextEditor 
                  value={terminosYCondiciones} 
                  onChange={onChangeEditor} 
                  column={5}
              />
            )}
            {activeTab === 'politicaPrivacidad' && (
              <TextEditor 
                value={politicaPrivacidad} 
                onChange={onChangeEditor} 
                column={2}
              />
            )}
            {activeTab === 'politicaPrivacidadRRSS' && (
              <TextEditor 
                value={politicaPrivacidadRRSS} 
                onChange={onChangeEditor} 
                column={3}
              />
            )}
            {activeTab === 'politicaCookies' && (
              <TextEditor 
                value={politicaCookies} 
                onChange={onChangeEditor} 
                column={4}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
