import { types } from "../types/types";
import { fetchConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewSector = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'sectores', values, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                const datos = {
                    text: [ `Sector creado` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewSector( body.sector._id, body.sector ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activeSectores = ( _id, sectores ) => ({
    type: types.sectoresActive,
    payload: {
        _id,
        ...sectores
    }
});

export const unactiveSectores = () => ({
    type: types.sectoresUnactive
});

export const addNewSector = ( _id, sectores ) => ({
    type: types.sectoresAddNew,
    payload: {
        _id,
        ...sectores
    }
});

export const startLoadingSectores = () => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'sectores', {} );
            const body = await resp.json();
            dispatch( setSectores( body.sectores ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setSectores = ( sectores ) => ({
    type: types.sectoresLoad,
    payload: sectores
});

export const sectoresStartDelete = ( id ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `sectores/${ id }`, {}, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                dispatch( sectorDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

const sectorDeleted = ( id ) => ({
    type: types.sectoresDelete,
    payload: id
});

export const sectorLogout = () => ({
    type: types.sectoresLogoutCleaning
});