import { types } from "../types/types";
import { fetchConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewVacaciones = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'vacaciones', values, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                const datos = {
                    text: [ `Vacaciones creadas` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewVacaciones( body.vacaciones._id, body.vacaciones ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activeVacaciones = ( _id, vacaciones ) => ({
    type: types.vacacionesActive,
    payload: {
        _id,
        ...vacaciones
    }
});

export const unactiveVacaciones = () => ({
    type: types.vacacionesUnactive
});

export const addNewVacaciones = ( _id, vacaciones ) => ({
    type: types.vacacionesAddNew,
    payload: {
        _id,
        ...vacaciones
    }
});

export const startLoadingVacacionesEmpresa = ( empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'vacaciones/empresa', { empresa }, 'POST' );
            const body = await resp.json();
            dispatch( setVacaciones( body.vacaciones ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingVacacionesProfesional = ( empresa, profesional ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'vacaciones/profesional', {empresa, profesional: profesional._id }, 'POST' );
            const body = await resp.json();
            dispatch( setVacaciones( body.vacaciones ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingVacacionesSucursal = ( empresa, sucursal ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'vacaciones/sucursal', {empresa, sucursal: sucursal._id }, 'POST' );
            const body = await resp.json();
            dispatch( setVacaciones( body.vacaciones ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setVacaciones = ( vacaciones ) => ({
    type: types.vacacionesLoad,
    payload: vacaciones
});

export const vacacionesStartDelete = ( id, empresa ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `vacaciones/${ id }`, { empresa }, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                dispatch( vacacionesDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

const vacacionesDeleted = ( id ) => ({
    type: types.vacacionesDelete,
    payload: id
});