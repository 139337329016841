import Swal from 'sweetalert2';

import { types } from "../types/types";
import { fetchConToken, archivoConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewServicio = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'servicios', { values, empresa: values.empresa }, 'POST' );
            const body = await resp.json();
            console.log( body );
            if( body.ok ) {
                const datos = {
                    text: [ `Servicio creado correctamente` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewServicio( body.servicio._id, body.servicio ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activeServices = ( _id, services ) => ({
    type: types.serviciosActive,
    payload: {
        ...services
    }
});

export const activeServicio = ( _id, servicio ) => ({
    type: types.serviciosActive,
    payload: {
        _id,
        ...servicio
    }
});

export const unactiveServicio = () => ({
    type: types.serviciosUnactive
});

export const addNewServicio = ( _id, servicio ) => ({
    type: types.serviciosAddNew,
    payload: {
        _id,
        ...servicio
    }
})

export const startLoadingServicios = ( empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'servicios/empresa', {empresa}, 'POST' );
            const body = await resp.json();
            dispatch( setServicios( body.servicios ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setServicios = ( servicios ) => ({
    type: types.serviciosLoad,
    payload: servicios
})

export const startSaveServicio = ( servicio, swal = true ) => {
    return async( dispatch, getState ) => {
        
        try {
            const resp = await fetchConToken( `servicios/${ servicio._id }`, servicio, 'PUT' );
            const body = await resp.json();
            if ( body.ok ) {
                // console.log( body.servicio );
                dispatch( refreshServicio( body.servicio._id, body.servicio ) );
                const datos = {
                    text: [ `${ servicio.nombre } guardado correctamente` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            } else {
                console.log( body );
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshServicio = ( id, servicio ) => ({
    type: types.serviciosUpdated,
    payload: {
        ...servicio
    }
});

export const startUploading = ( archivo, servicio, nombre, previa ) => {

    return async ( dispatch, getState ) => {
        try {           
            const formData = new FormData();
            // console.log( archivo );
            formData.append('archivo', archivo);
            formData.append('empresa', servicio.empresa);
            formData.append('nombre', nombre);
            formData.append('previa', previa);
            formData.append('file_name', 'archivo');

            //console.log( formData );
    
            await archivoConToken( `servicios/upload/${ servicio._id }`, formData, 'POST' );
        } catch (error) {
            throw new Error();
        }  

        Swal.close();
    }
};

export const servicioStartDelete = ( id, empresa ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `servicios/${ id }`, {empresa}, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                console.log( 'servicioDeleted', id );
                dispatch( servicioDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

export const serviciosReorder = ( serviciosList, empresa ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( `servicios/reorder`, {serviciosList, empresa}, 'POST' );
            const body = await resp.json();
            if ( body.ok ) {
                console.log( 'Reordered' );
            }
            
        } catch (error) {
            console.log(error);            
        }
    }
};

const servicioDeleted = ( id ) => ({
    type: types.serviciosDelete,
    payload: id
});

export const servicioLogout = () => ({
    type: types.serviciosLogoutCleaning
});
