import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { EditarIcon } from '../../../assets/svg/iconos';


export const UltimaReserva = ({ cita, createCita, editCita, setActiveCita}) => {
    const { colores } = useSelector(state => state.ui);
    const { variantes } = useSelector(state => state.variantes);
    const { usuarios, active: usuarioActivo } = useSelector(state => state.usuarios);
    const { citas } = useSelector(state => state.citas);

    const getVariante = (id) => {
        return variantes.find(v => v._id === id);
    }

    const editarCita = (cita)=>{
        setActiveCita(cita);
        editCita(cita);
    }

    // const variantesInCita = (cita)=>{
    //     return cita?.variantes.map(v => getVariante(v)?.nombre).join(",");
    // }

    const variantesInCita = cita?.variantes.map(v => getVariante(v)?.nombre).join(",");

    console.log("Citas: ", citas);

    return (
        <div className='ultima-reserva'>
            {citas && citas.map(cita => (
                <div className='cita-row' key={cita.id}  style={{ backgroundColor: colores.color6 }}>
                    <div className='cita-date'>
                        <p><b>{moment(cita?.fecha).format('D/M/YYYY')}</b> {moment(cita?.fecha).format('H:mm')}h</p>
                    </div>
                    <div className='cita-servicio'>
                        {/* <p>{cita?.servicios.map(s => `${s.nombre} ${variantesInCita(cita)? `(${variantesInCita(cita)})` : ""}`).join(",")}</p> */}
                        <p>{cita?.servicios.map(s => `${s.nombre} ${variantesInCita? `(${variantesInCita})` : ""}`).join(",")}</p>
                    </div>
                    <div className='cita-profesional'>
                        <p>{cita?.profesional.nombre}</p>
                    </div>
                    <div className='cita-sucursal'>
                        <p>{cita?.sucursal.nombre}</p>
                    </div>
                    <div className='cita-activa'>
                        <p>{moment(cita?.fecha).isBefore(moment())?cita.estado==='Activa'||cita.estado==''?'Pasada':cita.estado:cita.estado}</p>
                    </div>
                    <div className={`cita - indicador ${moment(cita?.fecha).isAfter(moment()) ? 'active' : ''}`}></div>
                    { moment(cita?.fecha).isAfter(moment()) && (cita.estado == "Activa" || cita.estado === "") &&
                        <button
                                onClick={()=>editarCita(cita)}
                                style={{ backgroundColor: colores.color6 }}
                        >
                                <EditarIcon color={ colores.colorPrincipal } width="12" height='12'/>
                        </button> 
                    }
                </div>
            ))}
            <div className='new-cita' style={{ backgroundColor: colores.color6 }}>
                <p
                    onClick={() => createCita()}
                    style={{ color: colores.color3 }}
                >+ Nueva reserva</p>
            </div>
        </div>
    )
}
