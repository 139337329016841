import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import useClickOutside from '../../../../hooks/useClickOutside';
import { DescuentoAlertIcon, DescuentoStarIcon, EyeIcon, TrashIcon } from '../../../../assets/svg/iconos';
import { startSavePromocion } from '../../../../actions/promociones';
import { formatCurrency } from '../../../../helpers/helpers';

const timezone = process.env.REACT_APP_TIMEZONE;

export const DescuentoCard = ({descuento, editDescuento, deleteDescuento}) => {
    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { colores } = useSelector(state => state.ui);

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    const clickActivar = (e) => {
        e.stopPropagation();
        if( empresa ){
            const newDescuento = {
                _id: descuento._id,
                empresa,
                activo: !descuento.activo
            }
            dispatch( startSavePromocion( newDescuento ) );
        }
    }

    return (
        <div 
            className='descuento-card'
            onClick={ clickSelect }
        >
            <div 
                className='card-main'
                style={{ backgroundColor: colores.color6 }}
            >
                <div className='card-row'>
                    <div className='descuento-type' style={{ color: colores.colorPrincipal }}>
                        <p>{ descuento.tipo }</p>
                    </div>
                    <div className='descuento-star'>
                        <DescuentoStarIcon color={ colores.colorPrincipal } />
                    </div>
                </div>
                <div className='descuento-title' style={{ color: colores.colorPrincipal }}>
                    <p>{ descuento.titulo }</p>
                </div>
                <div className='card-row row-descuento'>
                    <div className='descuento-amount' style={{ color: colores.colorPrincipal }}>
                        {
                            descuento.cifra > 0 &&
                            <p>-{ formatCurrency(descuento.cifra) }<span>€</span></p>
                        }
                        {
                            descuento.porcentaje > 0 &&
                            <p>-{ formatCurrency(descuento.porcentaje) }<span>%</span></p>
                        }
                    </div>
                    <div className='descuento-active'>
                        <div 
                            className={ `ylm-toggle ${ descuento.activo && 'active'}`}
                            onClick={ (e) => clickActivar(e) }
                        >
                            <div 
                                className={ `toggle-bg`}
                                style={{ backgroundColor: descuento.activo ? colores.colorSecundario : '#C9C5C5' }}
                            >
                                <span
                                    className={`toggle-item`}
                                    style={{ backgroundColor: descuento.activo ? colores.colorPrincipal : '#7B7B7B' }}
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className='descuento-expiration'
            >
                {
                    // La fecha tiene que ser formato YYYY-MM-DD
                    moment.tz( descuento.caducidad, timezone ).isBefore( moment('3000-12-12') )
                    ?
                    <p style={{ 
                        backgroundColor: `${moment.tz( descuento.caducidad, timezone ).isBefore( moment() ) ? 'rgba(248,226,232,1)' : colores.color6}`,
                        color: `${moment.tz( descuento.caducidad, timezone ).isBefore( moment() ) ? 'rgba(246,127,140,1)' : colores.colorPrincipal}`,
                        borderColor: `${moment.tz( descuento.caducidad, timezone ).isBefore( moment() ) ? 'rgba(246,127,140,1)' : colores.colorPrincipal}`
                    }}>
                        <DescuentoAlertIcon color={ `${moment.tz( descuento.caducidad, timezone ).isBefore( moment() ) ? 'rgba(246,127,140,1)' : colores.colorPrincipal} `} />{ moment.tz( descuento.caducidad, timezone ).format('D/M/YYYY')}
                    </p>
                    :
                    <p style={{ 
                        backgroundColor: colores.color6,
                        color: colores.colorPrincipal,
                        borderColor: colores.colorPrincipal 
                    }}>No caduca</p>
                }
            </div>
            {
                isOpen && (
                    <div className='descuento-overlay' ref={popover}>
                        <div className='overlay-buttons'>
                            <button 
                                className='eye'
                                onClick={ () => editDescuento( descuento ) }
                            >
                                <EyeIcon color={ colores.colorPrincipal } />
                            </button>
                            <button 
                                className='trash'
                                onClick={ () => deleteDescuento( descuento ) }
                            >
                                <TrashIcon color={ colores.colorPrincipal } />
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
