import { useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModalBottom } from '../../../../actions/ui';

import { ChevronDownIcon } from '../../../../assets/svg/iconos';
import useClickOutside from '../../../../hooks/useClickOutside';

export const SelectProfesional = ({objPromocion, dataChange, editMode}) => {

    const dispatch = useDispatch();
    const { profesionales } = useSelector(state => state.profesionales);

    const [selectText, setSelectText] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        // console.log( profesionales );
        // console.log( objPromocion );
        if( objPromocion?.tipo && objPromocion?.profesionales ){
            let seleccionados = 0;
            let disponibles = 0;
            profesionales.forEach( profesional => {
                !profesional.deleted && disponibles++;
                if( objPromocion.profesionales.includes( profesional._id ) ){
                    seleccionados++;
                }
            });
            if( seleccionados === 0 && !objPromocion?.profAleatorio ){
                setSelectText( 'Ninguno' );
            } else if( seleccionados === disponibles && objPromocion?.profAleatorio ){
                setSelectText( 'Todos' );
            } else if( seleccionados === 0 && objPromocion?.profAleatorio ){
                setSelectText( 'Aleatorio' );
            } else {
                console.log( seleccionados, disponibles, profesionales.length, objPromocion?.profAleatorio );
                setSelectText( 'Varios' );
            }
        } else {
            setSelectText( 'Ninguno' );
        }
    }, [objPromocion, profesionales]);
    

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    const clickProfesional = ( idProfesional ) => {
        if( editMode ){
            let newProfesionales = [];
            if( idProfesional === 'aleatorio' ){
                const newProfAleatorio = !objPromocion.profAleatorio;
                console.log( newProfAleatorio );
                dataChange( 'profAleatorio', !objPromocion.profAleatorio );
            } else {
                if( objPromocion.profesionales.includes( idProfesional ) ){
                    newProfesionales = objPromocion.profesionales.filter( suc => {
                        return suc !== idProfesional;
                    });
                } else {
                    newProfesionales = [
                        ...objPromocion.profesionales,
                        idProfesional
                    ]
                }
            
                dataChange('profesionales', newProfesionales );
            }
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    return (
        <div 
            className='ylm-profesional-select promocion-selector'
        >
            <div 
                className='select-main'
                onClick={ clickSelect }
            >
                <p>{ selectText }</p>
                <div 
                    className='btn-chevron'
                    style={{ transform: isOpen && 'rotate(180deg)'}}
                >
                    <ChevronDownIcon
                        color={ '#000000' }
                    />
                </div>
            </div>
            {
                isOpen &&
                <ul 
                    className='select-options' 
                    ref={popover}
                >
                    <li
                        onClick={ () => clickProfesional( 'aleatorio' )}
                    >
                        <div 
                            className={`checkbox-bg ${objPromocion?.profAleatorio ? 'active' : ''}`}
                        >
                            {
                                objPromocion?.profAleatorio &&
                                <span 
                                    className='checkbox-item'
                                ></span>
                            }
                        </div>
                        <p>{ `Aleatorio` }</p>
                    </li>   
                    {
                        profesionales.map( profesional => (
                            !profesional.deleted &&
                            <li
                                key={ profesional._id }
                                onClick={ () => clickProfesional( profesional._id )}
                            >
                                <div 
                                    className={`checkbox-bg ${objPromocion?.profesionales.includes( profesional._id ) ? 'active' : ''}`}
                                >
                                    {
                                        objPromocion?.profesionales.includes( profesional._id ) &&
                                        <span 
                                            className='checkbox-item'
                                        ></span>
                                    }
                                </div>
                                <p>{ profesional.nombre }</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
