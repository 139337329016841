import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AutosizeInput from 'react-input-autosize';

import useClickOutside from '../../../../hooks/useClickOutside';
import { YlmRadio } from '../../../../components/forms/YlmRadio';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';
import { openModalBottom } from '../../../../actions/ui';

export const PriceSelect = ({objPromocion, setObjPromocion, precio, setPrecio, setPrecioReal, editMode, handlePrecioChange, typePrecio, setTypePrecio}) => {

    const dispatch = useDispatch();
    const { colores } = useSelector(state => state.ui);

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    // const changeCifra = ( value ) => {
    //     if( editMode ){
    //         setInputValue( value );
    //         setObjPromocion({
    //             ...objPromocion,
    //             cifra: isCifra ? value : null,
    //             porcentaje: !isCifra ? value : null
    //         })
    //     } else {
    //         dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
    //     }
    // };

    const clickRadioSelect = ( value ) => {
        if( editMode ){
            setTypePrecio( value );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    return (
        <div 
            className='price-select'
            style={{ backgroundColor: colores.colorSecundario }}
        >
            <div className='number'>
                <div 
                    className='number-row'
                    style={{ 
                        color: colores.colorPrincipal,
                        fontSize: `${ String(precio).length > 3 ? '30px' : '38px'  }`
                    }}
                >
                    <div className='only-number'>
                        {
                        objPromocion?.tipo === 'descuento' &&
                            <span>-</span>
                        }
                        <input
                            style={{
                                color: colores.colorPrincipal,
                                fontSize: `${ String(precio).length > 3 ? '30px' : '38px'  }`,
                                width: `${ String(precio).length > 4 ? '85px' : String(precio).length > 3 ? '60px' : '50px' }`
                            }}
                            autoComplete='off'
                            type="number"
                            placeholder='--'
                            name="stock"
                            disabled={ !editMode }
                            value={ precio }
                            onChange={ handlePrecioChange }
                        />
                    </div>
                    <div className='number-symbol'>
                        { `${ typePrecio === 'cifra' ? '€' : '%'}` }
                    </div>
                </div>
                <div 
                    className='open-btn'
                    onClick={ clickSelect }
                    >
                    <ChevronDownIcon color={ colores.colorPrincipal } />
                </div>
            </div>
            {
                isOpen && (
                    <ul className='type-selector' ref={popover}>
                        <li>
                            <YlmRadio
                                active={ typePrecio === 'porcentaje' }
                                clickFunction={ () => clickRadioSelect('porcentaje') }
                                text={`Porcentaje`}
                                color={ colores.colorPrincipal }
                                bgColor={ colores.colorSecundario }
                            />
                        </li>
                        <li>
                            <YlmRadio
                                active={ typePrecio === 'cifra' }
                                clickFunction={ () => clickRadioSelect('cifra') }
                                text={`Euros`}
                                color={ colores.colorPrincipal }
                                bgColor={ colores.colorSecundario }
                            />
                        </li>
                    </ul>
                )
            }
        </div>
    )
}
