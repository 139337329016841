import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeLogin } from '../../actions/ui';

import bgImage from '../../assets/img/bg_login_exora.jpg';
import appLogo from '../../assets/svg/logo-exora.svg';
import { DatosPersonales } from './components/DatosPersonales';
import { startLoadingPlanes } from '../../actions/planes';
import { newEmpresa } from '../../helpers/helpers';
import { Suscripcion } from './components/Suscripcion';
import { ModalPlanesRegister } from './modales/ModalPlanesRegister';
import { DatosPago } from './components/DatosPago';
import { Loading } from '../../components/loading/Loading';

export const AltaCliente = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector( state => state.ui );
    const { uid } = useSelector(state => state.auth);
    const { sectores } = useSelector(state => state.sectores);
    const { planes } = useSelector(state => state.planes);

    const [tab, setTab] = useState(1);
    const [empresa, setEmpresa] = useState( newEmpresa( sectores, planes ) );
    const [frameUrl, setFrameUrl] = useState('');

    const [modalPlanesOpened, setModalPlanesOpened] = useState(false);

    useEffect(() => {
      dispatch( startLoadingPlanes() );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    /* useEffect(() => {
      console.log( empresa );
    }, [empresa]) */
    

    useEffect(() => {
        if( uid ) {
            dispatch(closeLogin());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);

    return (
        <div className={`alta-cliente ${(!uid) ? 'active' : ''}`} style={{ backgroundImage: `url('${bgImage}`}}>
            <div className="form-container">
                <div className="form-header">
                    <img src={appLogo} alt="logotipo"></img>        
                </div>                     
                {/* <h1>Estás a 4 pasos de digitalizar tu negocio.<br />¡Crea tu cuenta ahora!</h1> */}
                <h1>Estás a 3 pasos de digitalizar tu negocio.<br />¡Crea tu cuenta ahora!</h1>
                <div className='steps-indicator'>
                    <div className='steps-line'></div>
                    {/* <div className='steps-container'>
                        <div className={`step ${tab === 1 ? 'active' : ''}`}><p>1</p></div>
                        <div className={`step ${tab === 2 ? 'active' : ''}`}><p>2</p></div>
                        <div className={`step ${tab === 3 ? 'active' : ''}`}><p>3</p></div>
                        <div className={`step ${tab === 4 ? 'active' : ''}`}><p>4</p></div>
                    </div> */}
                    <div className='steps-container'>
                        <div className={`step ${tab === 1 ? 'active' : ''}`}><p>1</p></div>
                        <div className={`step ${tab === 2 ? 'active' : ''}`}><p>2</p></div>
                        <div className={`step ${tab === 3 ? 'active' : ''}`}><p>3</p></div>
                    </div>
                </div>
                <div className='steps-screens'>
                    <DatosPersonales 
                        active={ tab === 1 }
                        setTab={ setTab }
                        empresa={ empresa }
                        setEmpresa={ setEmpresa }
                    />
                    <Suscripcion 
                        active={ tab === 2 }
                        setTab={ setTab }
                        empresa={ empresa }
                        setEmpresa={ setEmpresa }
                        setModalPlanesOpened = { setModalPlanesOpened }
                        frameUrl={frameUrl }
                        setFrameUrl={ setFrameUrl }
                    />
                    <DatosPago
                        active={ tab === 3 }
                        setTab={ setTab }
                        empresa={ empresa }
                        setEmpresa={ setEmpresa }
                        frameUrl={frameUrl }
                        setFrameUrl={ setFrameUrl }
                    />
                </div>
            </div>
            <ModalPlanesRegister
                modalPlanesOpened = { modalPlanesOpened }
                setModalPlanesOpened = { setModalPlanesOpened }
                currentEmpresa = { empresa }
            />
            {
               loading && 
               <Loading />
            }
        </div>
    )
}
