import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}



export const AddSeccionesSelect = ({ updateSecciones, objProfesional, secciones, checkAllSeccionesSelected }) => {
    const { colores } = useSelector( state => state.ui );

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const showDropdown = () => {
        setVisibility(true);
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = ( e, seccion ) => {
        e.stopPropagation();
        updateSecciones( seccion );
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );
    return (
        <div 
            className='ylm-select add-seccion-select'
            onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
        >
            { objProfesional.secciones?.length === 0 && <p>Configurar Secciones</p> }
            { (!checkAllSeccionesSelected() && objProfesional.secciones?.length > 0) && <p>Algunas</p> }
            { checkAllSeccionesSelected() && <p>Todas</p> }
            <span><ChevronDownIcon color={ colores.colorPrincipal } /></span>
            <ul
                ref={ dropdownMenuRef }
                className={`ylm-select-options add-seccion-options ${ visibility ? 'active' : '' }`}
            >
                <li
                    key={ 'all' }
                    className={ `select-option check-option ${ checkAllSeccionesSelected() && 'active' }` }
                    onClick={ (e) => selectItem(e, { key: 'todas' }) }
                ><span></span>{ `Todas las secciones` }</li>
                {
                    secciones.map( seccion => (
                        <li
                            key={ seccion.key }
                            className={ `select-option check-option ${ objProfesional.secciones?.includes( seccion.key ) && 'active' }` }
                            onClick={ (e) => selectItem(e, seccion)}
                        ><span></span>{ seccion.text }</li>
                    ))
                }
            </ul>
        </div>
  )
}
