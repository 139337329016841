import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

const timezone = process.env.REACT_APP_TIMEZONE;

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const HorarioDescuentoInput = ({ hora, idx, idxDay, idxHora, changeHoras, intMinutos = 5, editMode = false, newHorario }) => {
    const { colores } = useSelector( state => state.ui );

    const [horaInicioText, setHoraInicioText] = useState(9);
    const [minutosInicioText, setMinutosInicioText] = useState(0);
    
    const [horaFinText, setHoraFinText] = useState(9);
    const [minutosFinText, setMinutosFinText] = useState(0);

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const showDropdown = () => {
        setVisibility(true);
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

    useEffect(() => {
        updateStateHoras();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    

    useEffect(() => {
        //console.log( hora );
        updateStateHoras();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hora, horaInicioText, minutosInicioText, horaFinText, minutosFinText]);  

    const updateStateHoras = () => {
        const currentHoraInicio = moment().tz(timezone).hours(8).minutes(0);
        const currentHoraFin = moment().tz(timezone).hours(20).minutes(0);
        let inicioHoras = parseInt( currentHoraInicio.format('HH'));
        let inicioMinutos = parseInt( currentHoraInicio.format('mm'));
        let finHoras = parseInt( currentHoraFin.format('HH'));
        let finMinutos = parseInt( currentHoraFin.format('mm'));

        if( hora?.horaInicio ){
            inicioHoras = parseInt( moment.tz( hora.horaInicio, timezone ).format('HH'));
            inicioMinutos = parseInt( moment.tz( hora.horaInicio, timezone ).format('mm'));
            finHoras = parseInt( moment.tz( hora.horaFin, timezone ).format('HH'));
            finMinutos = parseInt( moment.tz( hora.horaFin, timezone ).format('mm'));
        }

        setHoraInicioText( inicioHoras );
        setMinutosInicioText( inicioMinutos ); 
        setHoraFinText( parseInt(finHoras) );
        setMinutosFinText( finMinutos );
    }

    const clickSelector = ( selector, operation ) => {
        let newHoraInicio = parseInt( horaInicioText );
        let newMinutosInicio = parseInt( minutosInicioText );
        let newHoraFin = parseInt( horaFinText );
        let newMinutosFin = parseInt( minutosFinText );

        if( selector === 'hours-inicio'){
            if( operation === 'add' ){
                if( newHoraInicio < 23 ){                    
                    setHoraInicioText( horaInicioText + 1 );
                    newHoraInicio++;
                } else {                    
                    setHoraInicioText( 0 );
                    newHoraInicio = 0;
                }
            } else {
                if( newHoraInicio > 0 ){                    
                    setHoraInicioText( horaInicioText - 1 );
                    newHoraInicio--;
                } else {                    
                    setHoraInicioText( 23 );
                    newHoraInicio = 23;
                }
            }
        }
        if( selector === 'minutes-inicio'){
            if( operation === 'add' ){
                if( newMinutosInicio < ( 60 - intMinutos ) ){                    
                    setMinutosInicioText( newMinutosInicio + intMinutos );
                    newMinutosInicio += intMinutos;
                } else {                    
                    setMinutosInicioText( 0 );
                    newMinutosInicio = 0;
                }
            } else {
                if( newMinutosInicio > 0 ){                    
                    setMinutosInicioText( newMinutosInicio - intMinutos );
                    newMinutosInicio -= intMinutos;
                } else {                    
                    setMinutosInicioText( 60 - intMinutos );
                    newMinutosInicio = 60 - intMinutos;
                }
            }
        }
        if( selector === 'hours-fin'){
            if( operation === 'add' ){
                if( newHoraFin < 23 ){
                    setHoraFinText( horaFinText + 1 );
                    newHoraFin++;
                } else {
                    setHoraFinText( 0 );
                    newHoraFin = 0;
                }
            } else {
                if( newHoraFin > 0 ){
                    setHoraFinText( horaFinText - 1 );
                    newHoraFin--;
                } else {
                    setHoraFinText( 23 );
                    newHoraFin = 23;
                }
            }
        }
        if( selector === 'minutes-fin'){
            if( operation === 'add' ){
                if( newMinutosFin < ( 60 - intMinutos ) ){
                    setMinutosFinText( newMinutosFin + intMinutos );
                    newMinutosFin += intMinutos;
                } else {
                    setMinutosFinText( 0 );
                    newMinutosFin = 0;
                }
            } else {
                if( newMinutosFin > 0 ){
                    setMinutosFinText( newMinutosFin - intMinutos );
                    newMinutosFin -= intMinutos;
                } else {
                    setMinutosFinText( 60 - intMinutos );
                    newMinutosFin = 60 - intMinutos;
                }
            }
        }
        changeHoras(
            {
                idx,
                idxDay,
                idxHora,
                newHoras: [
                    `${newHoraInicio < 10 ? `0${newHoraInicio}` : newHoraInicio}:${newMinutosInicio < 10 ? `0${newMinutosInicio}` : newMinutosInicio}`,
                    `${newHoraFin < 10 ? `0${newHoraFin}` : newHoraFin}:${newMinutosFin < 10 ? `0${newMinutosFin}` : newMinutosFin}`
                ]
            }
        );
    }    
    
    return (
        <div
            className='doble-hora-input'
        >
            <div 
                className={ `horario-label ${ visibility ? 'active' : '' }` }
                onClick={ () => (visibility) ? hideDropdown() : editMode && showDropdown() }
                style={{ backgroundColor: !newHorario ? '#B9FFC4' : 'transparent' }}
            >
                { 
                    newHorario
                    ?
                    <p>+</p>
                    :
                    <p>{ horaInicioText }:{minutosInicioText < 10 ? `0${minutosInicioText}` : minutosInicioText}h - { horaFinText }:{minutosFinText < 10 ? `0${minutosFinText}` : minutosFinText}h</p>
                }
            </div>
            <div 
                ref={ dropdownMenuRef }
                className={ `selector-wrapper ${ visibility ? 'active' : '' }` }
            >
                <div 
                    className='selectors'
                    style={{ color: colores.colorPrincipal, borderColor: colores.colorPrincipal }}
                >
                    <div className='selector'>
                        <div
                            className='btn btn-substract'
                            onClick={ () => clickSelector( 'hours-inicio', 'substract') }
                        >
                            -
                        </div>
                        <div className='selector-value'>
                            { horaInicioText }
                        </div>
                        <div
                            className='btn btn-add'
                            onClick={ () => clickSelector( 'hours-inicio', 'add') }
                        >
                            +
                        </div>
                    </div>
                    <div className='separator'>:</div>
                    <div className='selector'>
                        <div
                            className='btn btn-substract'
                            onClick={ () => clickSelector( 'minutes-inicio', 'substract') }
                        >
                            -
                        </div>
                        <div className='selector-value'>
                            { minutosInicioText < 10 ? `0${minutosInicioText}` : minutosInicioText }
                        </div>
                        <div
                            className='btn btn-add'
                            onClick={ () => clickSelector( 'minutes-inicio', 'add') }
                        >
                            +
                        </div>
                    </div>
                </div>
                <div
                    className='selectors'
                    style={{ color: colores.colorPrincipal, borderColor: colores.colorPrincipal }}
                >
                    <div className='selector'>
                        <div
                            className='btn btn-substract'
                            onClick={ () => clickSelector( 'hours-fin', 'substract') }
                        >
                            -
                        </div>
                        <div className='selector-value'>
                            { horaFinText }
                        </div>
                        <div
                            className='btn btn-add'
                            onClick={ () => clickSelector( 'hours-fin', 'add') }
                        >
                            +
                        </div>
                    </div>
                    <div className='separator'>:</div>
                    <div className='selector'>
                        <div
                            className='btn btn-substract'
                            onClick={ () => clickSelector( 'minutes-fin', 'substract') }
                        >
                            -
                        </div>
                        <div className='selector-value'>
                            { minutosFinText < 10 ? `0${minutosFinText}` : minutosFinText }
                        </div>
                        <div
                            className='btn btn-add'
                            onClick={ () => clickSelector( 'minutes-fin', 'add') }
                        >
                            +
                        </div>
                    </div>
                </div>
                {/* <button
                    onClick={ selectItem }
                >Guardar</button> */}
            </div>
        </div>
    )
}
