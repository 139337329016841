import React from 'react';
import moment from 'moment-timezone';
import { ClienteRowSelect } from './ClienteRowSelect';

export const ClienteRowItem = ({ usuario, editCliente, deleteCliente }) => {

    const clickEdit = (id) => {
        editCliente(usuario);
    };

    const clickDelete = (id) => {
        deleteCliente(id, usuario.nombre);
    };


    return (
        <div
            className='table-row cliente-row'
            onClick={() => clickEdit(usuario._id)}
        >
            <div className='column name'>
                <p>{usuario.nombre} {usuario.apellidos}</p>
            </div>
            <div className='column phone'>
                <p>{usuario.telefono}</p>
            </div>
            <div className='column email'>
                <p>{usuario.email}</p>
            </div>
            <div className='column birthdate'>
                <p>{usuario.fechaNac}</p>
            </div>
            <div className='column created'>
                <p>{moment(usuario.creacion).format('D/M/YYYY')}</p>
            </div>
            <div className='column last'>
                {
                    moment(usuario.ultimaReserva?.fecha).format('D/M/YYYY') !== '1/1/1970'
                        ?
                        <>
                            <p>{moment(usuario.ultimaReserva?.fecha).format('D/M/YYYY')}</p>-
                            <p>{usuario.ultimaReserva?.sucursal}</p>
                        </>
                        :
                        <p>-------</p>

                }
            </div>
            <div className='column btns'>
                <ClienteRowSelect
                    clienteId={usuario._id}
                    clickEdit={clickEdit}
                    clickDelete={clickDelete}
                />
            </div>
        </div>
    )
}
