
import { types } from "../types/types";

const initialState = {
    planes: [],
    active: null
}

export const planesReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.planesActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.planesUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.planesAddNew:
            return {
                ...state,
                planes: [ ...state.planes, action.payload ]
            }

        case types.planesLoad:
            return {
                ...state,
                planes: [ ...action.payload ]
            }
        
        case types.planesUpdated:
            return {
                ...state,
                planes: state.planes.map(
                    plan => plan._id === action.payload._id
                        ? action.payload
                        : plan
                )
            }

        case types.planesDelete:
            return {
                ...state,
                active: null,
                planes: state.planes.filter( plan => plan._id !== action.payload )
            }
    
        case types.planesLogoutCleaning:
            return {
                ...state,
                active: null,
                planes: []
            }
    

        default:
            return state;
    }
}