import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { finishLoading, openModal, startLoading } from "../../../actions/ui";
import { fetchSinToken } from "../../../helpers/fetch";
import { formatCurrency } from "../../../helpers/helpers";


export const Suscripcion = ({ active, setTab, empresa, setEmpresa, setModalPlanesOpened, frameUrl, setFrameUrl }) => {
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.ui);
    const { planes } = useSelector(state => state.planes);
    const [planSelected, setPlanSelected] = useState('');

    const setPlan = ( plan ) => {
        setPlanSelected(plan._id);
        setEmpresa({
            ...empresa,
            bloqueo: true,
            plan: plan._id
        });
    };

	/**/
    const forwardBtn = async() => {
        try {
            dispatch( startLoading() );
            if( planSelected !== '' ){
                const resp = await fetchSinToken( 'empresas/business/new', empresa, 'POST' );
                const body = await resp.json();
                if( body.ok ) {
                    const empresaCompleta = body.empresa;
                    setEmpresa( empresaCompleta );
                    const payData = {
                        empresa: empresaCompleta._id,
                        importe: empresaCompleta.plan.mensual,
                        descripcion: `Primer pago ${ empresaCompleta._id }`
                    }
                    const respPago = await fetchSinToken( 'pasarela/pago', payData, 'POST' );
                    const bodyPago = await respPago.json();
                    console.log( bodyPago );
                    if( bodyPago.ok ) {
                        setFrameUrl( bodyPago.payUrl );
                        setTab(3);
                    }
                }else{
                    const datos = {
                        title: 'Error',
                        text: [ body.msg ],
                        buttonText: 'Cerrar'
                    }
                    dispatch( openModal( datos ) );
                }
            }
            dispatch( finishLoading() );
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            dispatch( finishLoading() );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }

    return (    
        <div className={`steps-tab tab-suscripcion ${ active ? 'active' : ''}`}>
            <h2>{ loading ? 'Comprobando datos' : 'Suscripción'}</h2>

            <section className='section plan'>
                <div className='planes-row'>
                    {
                        planes.map( plan => (
                            <div
                                key={ plan._id }
                                className={`plan-item ${ (empresa?.plan._id === plan._id) && 'active' }`}
                            >
                                <div className='title-row'>
                                    <div className='title'>
                                        Plan { plan?.nombre }
                                    </div>
                                </div>
                                <div 
                                    className='price'
                                >
                                    { `${formatCurrency( plan.mensual )}` }<span> €/ mes</span>
                                </div>
                                {
                                    plan.recomendado &&
                                    <div className='recomendado'>Recomendado</div>
                                }
                                <div className="description">
                                    { plan?.excerpt }
                                </div>
                                <div className="plan-btn">
                                    <button
                                        className={`${plan._id === planSelected ? 'inverse' : ''}`}
                                        onClick={() => setPlan( plan )}
                                    >
                                        Este es mi plan
                                    </button>
                                </div>
                                <div 
                                    className="more-link"
                                    onClick={() => setModalPlanesOpened(true) }
                                >
                                    <p>Ver más</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {
                    planSelected !== '' &&
                    <div className='form-btn'>
                        <button
                            onClick={ () => forwardBtn() }
                        >
                            Siguiente
                        </button>
                    </div>
                }
            </section>
        </div>
    )
}