export const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const formatCurrency = ( number ) => {
    let retorno = number / 100;
    if( number <  0){
        retorno = 0.00;
    }
    if( !Number.isInteger( retorno ) ) {
        const arrNumber = retorno.toString().split('.');
        let strNumber = arrNumber[0];
        if( arrNumber.length > 1 ){
            strNumber += ',' + arrNumber[1];
            if( arrNumber[1].length < 2 ){
                strNumber += '0';
            }
        }
        retorno = strNumber;
    }
    const retornoStr = retorno.toString();
    return retornoStr;
};

export const formatMoney = ( number ) => {
    const arrNumber = number.toString().split('.');
    let cifra = arrNumber[0];
    let separadores = 0;
    if( cifra.length > 3 ){
        separadores = Math.floor( arrNumber[0].length / 3 );
    }
    let array_cifra = [];
    if( separadores > 0 ){
        for( let i = 0; i <= separadores; i++ ){
            if( cifra.length > 3 ){
                array_cifra.unshift( cifra.slice(-3) );
                array_cifra.unshift('.');
                cifra = cifra.slice(0,-3);
            } else {
                array_cifra.unshift( cifra );
            }
        }
    } else {
        array_cifra.push( cifra );
    }
    let numero = array_cifra.join('');
    if( arrNumber.length > 1 ){
        numero += ',' + arrNumber[1];
    }

    return numero;
}

export const unformatMoney = ( value ) => {
    const primerPaso = value.replace('.', '' );
    const segundoPaso = Number(primerPaso.replace(',', '.'));
    console.log( segundoPaso );
    return segundoPaso;
}

export const deepClone = ( object ) => {
    let clone = {};
    for( let key in object ) {
        let value = object[key];
        if ( typeof(value)  !== 'object' ){
            clone[key] = value;
        } else {
            clone[key] = deepClone(value);
        }
    }
    return clone;
}

export const newProfesional = ( empresa ) => {
    return {
        apellidos: '',
        color: '#535af1',
        delay: '0',
        delayType: 'time',
        deleted: false,
        empresa,
        horarioSucursales: [],
        horarioTxt: [],
        nombre: '',
        img: [],
        permisos: [],
        prioridad: '1000000',
        secciones: [],
        servicios: [],
        sucursales: [],
        valoracion: '5',
        visibleEnApp: true
    }
}

export const newCliente = ( empresa ) => {
    return {
        apellidos: '',
        comentarios: [],
        confirmado: false,
        corrientePago: true,
        cp: '',
        delay: '0',
        delayType: 'time',
        deletd: false,
        email: '',
        empresa,
        fechaNac: '',
        localizacion: false,
        nombre: '',
        notificaciones: false,
        online: false,
        pago: 'NO',
        pagoAdelantado: false,
        poblacion: '',
        telefono: '',
        ultimaReserva: {},
        img: []
    }
};

export const newServicio = ( empresa ) => {
    return {
        descripcion: '',
        desde: false,
        duracion: '0',
        duracion1: 0,
        duracion2: 0,
        empresa,
        img: [],
        nombre: '',
        pausa: 0,
        precio: 0,
        profesionales: [],
        sucursales: [],
        variantes: []
    }
}

export const newPromocion = ( empresa ) => {
    return {
        empresa,
        titulo: '',
        descripcion: '',
        condiciones: '',
        porcentaje: null,
        cifra: 0,
        tipo: 'descuento',
        destino: 'servicio',
        servicios: [],
        productos: [],
        sucursales: [],
        profesionales: [],
        profAleatorio: false,
        fechaInicio: '1970-01-31T23:00:00.000+00:00',
        caducidad: '3100-12-31T23:59:59.000+00:00',
        fechaNac: '3100-12-31T23:59:59.000+00:00',
        activo: false,
        horarioEspecial: false,
        horariosPromocion: [
            { diasSemana: '1', horas: [] },
            { diasSemana: '2', horas: [] },
            { diasSemana: '3', horas: [] },
            { diasSemana: '4', horas: [] },
            { diasSemana: '5', horas: [] },
            { diasSemana: '6', horas: [] },
            { diasSemana: '7', horas: [] },
        ],
        pagoAdelantado: false,
        difusion: '',
        segmentacion: '',
        poblacion: ''
    }
}

export const newProducto = ( empresa ) => {
    return {
        categorias: [],
        complementarios: [],
        descripcion: '',
        empleo: '',
        empresa,
        fueraStock: false,
        imagenPrincipal: '',
        imagenes: [],
        img: [],
        ingredientes: '',
        marca: '',
        moneda: 'EUR',
        precio: '0',
        prepago: false,
        proveedor: '',
        refProveedor: '',
        referencia: '',
        size: '',
        stock: 0,
        stockMinimo: 0,
        titulo: ''
    }
}

export const newVariante = ( empresa ) => {
    return {
        empresa,
        nombre: '',
        descripcion: '',
        tiempo: 0,
        valor: 0,
        valorType: 'money',
        servicios: [],
        productos: [],
        deleted: false
    }
}

export const newCategoria = ( empresa ) => {
    return {
        empresa,
        nombre: '',
        descripcion: '',
        servicios: [],
        productos: [],
        deleted: false
    }
}

export const newSucursal = ( empresa ) => {
    return {
        direccion: '',
        email: '',
        horarioTxt: [],
        horarios: [],
        empresa,
        lat: '',
        long: '',
        nombre: '',
        poblacion: '',
        telefono: '',
        merchantId: ''
    }
}

export const newEmpresa = ( sectores = [], planes = [] ) => {
    return {
        avisoCancelacion: false,
        bloqueo: false,
        color3: '#FDFEAE',
        color4: '#D2FFE9',
        color5: '#2DA3A3',
        color6: '#F8FBFF',
        color7: '#F8F8F8',
        colorPrincipal: '#38D3C1',
        colorSecundario: '#DDF2F1',
        comentarios: false,
        deleted: false,
        diasRecogida: '5',
        direcciones: [
            { 
                cp: '',
                direccion: '',
                horarioTxt: [],
                poblacion: '',
                telefono: ''
            }
        ],
        email: '',
        oldUsuario: '',
        emailSMTP: '',
        fuentes: [],
        horasCancelacion: '5',
        huecos: false,
        img: [],
        intervaloTiempo: '10',
        nombre: '',
        notificacionesEmail: false,
        ordenSeleccion: ['valoracion', 'profesional', 'sucursal'],
        pagoAdelantado: false,
        plan: planes.length > 0 ? planes[0] : {},
        recomendacionVentas: true,
        reservaMultiple: false,
        retornoApp: true,
        sector: sectores.length > 0 ? sectores[0] : {},
        smtp: '',
        smtpPass: '',
        smtpPort: '',
        social: [],
        telefono: '',
        url: '',
        usuario: '',
        valoraciones: false,
        merchantId: ""
    }
};

export const newPlan = () => {
    return {
        nombre: '',
        descripcion: '',
        mensual: 0,
        anual: 0,
        profesionales: 10,
        reservas: 150,
        clientes: 5,
        deleted: false
    }
}

export const newNotificacion = ( empresa ) => {
    return {
        nombre: '',
        mensaje: '',
        lista: {
            difusion: 'Todos'
        },
        segmentacion: false
    }
}
 

export const getDifusion = ( id ) => {
    switch (id) {
        case 'geografica':
            return 'Geográfica';
        case 'opcion2':
            return 'Opción 2';
        case 'todos':
            return 'Todos';
        default:
            return 'Sin opción';
    }
}

export const getSegmentacion = ( id ) => {
    switch (id) {
        case 'poblacion':
            return 'Población';
        case 'opcion2':
            return 'Opción 2';
        default:
            return 'Sin opción';
    }
}

export const getSector = ( sectores, idSector ) => {
    let retorno = { nombre: '' };
    sectores.forEach( sector => {
        if( sector._id === idSector ){
            retorno = sector;
        }
    });
    return retorno;
}

export const getPlan = ( planes, idPlan ) => {
    let retorno = { nombre: '' };
    planes.forEach( plan => {
        if( plan._id === idPlan ){
            retorno = plan;
        }
    });
    return retorno;
}

// export const getSector = ( sectores, sector ) => {
//     if( sector === 'barberia' ){
//         return 'Barbería';
//     }
// }

// export const getPlan = ( plan ) => {
//     switch (plan) {
//         case 'comercio':
//             return 'Comercio';
        
//         case 'emprendedor':
//             return 'Emprendedor';
        
//         case 'multinacional':
//             return 'Multinacional';
    
//         default:
//             break;
//     }
// }