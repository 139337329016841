import React, { useCallback, useEffect, useRef, useState } from 'react';

import { HexColorPicker } from "react-colorful";
import AutosizeInput from 'react-input-autosize';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { profesionalStartDelete, startNewProfesional, startSaveProfesional, startUploading, unactiveProfesional } from '../../../actions/profesionales';
import { openModal, openModalBottom } from '../../../actions/ui';

import { ArrowBackIcon, AvatarIcon, ConfigIcon, DescansosIcon, HorarioIcon, ServiciosIcon, TrashIcon } from '../../../assets/svg/iconos';
import { Rating } from '../../../components/tools/Rating';
import { newProfesional } from '../../../helpers/helpers';
import useClickOutside from '../../../hooks/useClickOutside';
import { DelaySelect } from './DelaySelect';
import { DelaySelectValue } from './DelaySelectValue';
import { Descansos } from './Descansos';
import { Horario } from './Horario';
import { ModalEliminarProfesional } from './modales/ModalEliminarProfesional';
import { Permisos } from './Permisos';
import { Servicios } from './Servicios';
import { fetchConToken } from '../../../helpers/fetch';
import { getHorariosOcupados, testHorarios } from '../../../helpers/horarios';
import { YlmModalTwoButtons } from '../../../components/main/YlmModalTwoButtons';

export const Profesional = ({ history }) => {
    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { colores, miniLateral } = useSelector(state => state.ui);
    const { sucursales } = useSelector(state => state.sucursales);
    const { active: profActive, profesionales } = useSelector(state => state.profesionales);
    const [profSaved, setProfSaved] = useState(true);

    const [activeTab, setActiveTab] = useState(history.location.state.activeTab || 'horarios');
    const [currentProfesional, setCurrentProfesional] = useState(history.location.state.profesional || null);
    const [currentScreen, setCurrentScreen] = useState((history.location.state.profesional) ? 'edit' : 'new');

    const [imageUrl, setImageUrl] = useState('');
    const [file, setFile] = useState(null);
    const [profImage, setProfImage] = useState(null);

    const [objProfesional, setObjProfesional] = useState({});
    const [iniciado, setIniciado] = useState(false);
    const [cambiado, setCambiado] = useState(false);
    const [cambiadoVacaciones, setCambiadoVacaciones] = useState(false);

    const [editMode, setEditMode] = useState(true);

    const [ratingValue, setRatingValue] = useState(history.location.state.profesional?.valoracion || '5');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const [addSucursalValue, setAddSucursalValue] = useState({});
    const [horasOcupadas, setHorasOcupadas] = useState([
        { diasSemana: '1', horas: [] },
        { diasSemana: '2', horas: [] },
        { diasSemana: '3', horas: [] },
        { diasSemana: '4', horas: [] },
        { diasSemana: '5', horas: [] },
        { diasSemana: '6', horas: [] },
        { diasSemana: '7', horas: [] }
    ]);

    const [descansosObj, setDescansosObj] = useState([]);
    const [descansosTemp, setDescansosTemp] = useState([]);

    const [dataModal, setDataModal] = useState({
        title: '¿Cambiar plan?',
        text: ['¿Está seguro de que desea actualizar al plan?'],
        buttonText: 'Estoy seguro',
        button2Text: 'Cancelar'
    });
    const [modalTwoButtonsOpened, setModalTwoButtonsOpened] = useState(false);
    const [modalPeriodoResponse, setModalPeriodoResponse] = useState(100);
    const [modalPeriodoWaiting, setModalPeriodoWaiting] = useState({});

    const [modalEliminarProfesionalOpened, setModalEliminarProfesionalOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar profesional?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const getImage = useCallback(
        async () => {
            // const imagen = await fetchSinToken(`imagen/profesional/${currentProfesional.img[0]}`);
            setImageUrl({ url: currentProfesional.img[0] });
        },
        [currentProfesional]
    )

    useEffect(() => {
        if (currentScreen !== "new")
            setCurrentProfesional(profesionales.find(p => p._id === history.location.state.profesional?._id));
    }, [profesionales, history])

    useEffect(() => {
        return () => {
            if (!profSaved) {
                alert('Salimos?');
            }
            dispatch(unactiveProfesional());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentProfesional) {
            setObjProfesional(currentProfesional);
            setIniciado(true);
            if (currentProfesional._id) {
                if (currentProfesional.img.length > 0) {
                    getImage();
                }
            }
        } else {
            setEditMode(true);
            setCambiado(true);
            const nuevo = newProfesional(empresa);
            setCurrentProfesional(nuevo);
            setObjProfesional(nuevo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProfesional]);

    const horariosOcupados = async (prof) => {
        const newHorasOcupadas = await getHorariosOcupados(prof, sucursales);
        setHorasOcupadas(newHorasOcupadas);
    };


    useEffect(() => {
        if (currentProfesional) {
            const profesionalOriginal = { ...currentProfesional };
            if (iniciado) {
                let igual = true;
                for (const propiedad in profesionalOriginal) {
                    if (profesionalOriginal[propiedad] !== objProfesional[propiedad]) {
                        igual = false;
                    }
                }
                setCambiado(!igual);
            }
        }
        if (profImage) {
            setCambiado(true);
        }

    }, [iniciado, currentProfesional, objProfesional, profImage]);

    useEffect(() => {
        if (objProfesional._id) {
            horariosOcupados(objProfesional);
            getVacacionesProfesional();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objProfesional]);

    useEffect(() => {
        if (modalPeriodoWaiting._id) {
            if (modalPeriodoResponse === 1) {
                deleteVacacionesFromDB(modalPeriodoWaiting._id);
            }
            setModalPeriodoWaiting({});
            setModalPeriodoResponse(100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalPeriodoResponse]);

    const getVacacionesProfesional = async () => {
        try {
            const resp = await fetchConToken('vacaciones/profesional', { empresa, profesional: objProfesional._id }, 'POST');
            const body = await resp.json();
            setDescansosObj(body.vacaciones);
            setDescansosTemp(body.vacaciones);
        } catch (error) {
            console.log(error);
        }
    }

    const compareObjs = () => {

        let igual = true;
        if (descansosObj.length !== descansosTemp.length) { igual = false; }
        descansosTemp.forEach((descanso, idx) => {
            for (const propiedad in descansosObj[idx]) {
                if (descansosObj[idx][propiedad] !== descanso[propiedad]) {
                    igual = false;
                }
            }
        });
        setCambiadoVacaciones(!igual);
    };

    useEffect(() => {
        compareObjs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descansosTemp]);

    const backClick = () => {
        history.goBack();
    }

    const changeTab = (tabName) => {
        if (objProfesional._id) {
            setActiveTab(tabName);
        } else {
            const datos = {
                text: ['Para añadir más datos, debes crear primero el profesional'],
                timeOut: 3
            }
            dispatch(openModalBottom(datos));
        }
    };

    const onFormChange = (e) => {
        dataChange(e.target.name, e.target.value);
    }

    const dataChange = (name, value) => {
        setObjProfesional({
            ...objProfesional,
            [name]: value
        });
    }

    const deleteProfesionalModal = () => {
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar a ${objProfesional.nombre}?`]
        })
        setModalEliminarProfesionalOpened(true);
    };

    const deleteProfesionalResponse = (value) => {
        if (value === 1) {
            let selectedId = objProfesional._id;
            if (!selectedId) {
                if (profActive._id) {
                    selectedId = profActive._id;
                    dispatch(profesionalStartDelete(selectedId, empresa));
                    history.push({ pathname: `/profesionales` })
                }
            } else {
                dispatch(profesionalStartDelete(selectedId, empresa));
                history.push({ pathname: `/profesionales` })
            }
        }
    }

    const clickPicker = () => {
        if (editMode) {
            toggle(true);
        } else {
            dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
        }
    }

    const setColor = (color) => {
        if (iniciado) { dataChange('color', color); }
    }

    const changeValoracion = (value) => {
        if (editMode) {
            setRatingValue(value);
        } else {
            dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
        }
    }

    const setDelay = (type, value) => {
        if (iniciado) {
            setObjProfesional({
                ...objProfesional,
                delayType: type,
                delay: value
            });
        }
    }

    const addSucursal = (sucursal) => {
        if (editMode) {
            const newSucursales = [
                ...objProfesional.sucursales,
                sucursal._id
            ]

            const newHorarioSucursales = [
                ...objProfesional.horarioSucursales,
                {
                    sucursal: sucursal._id,
                    horarios: [
                        { diasSemana: "1", horas: [] },
                        { diasSemana: "2", horas: [] },
                        { diasSemana: "3", horas: [] },
                        { diasSemana: "4", horas: [] },
                        { diasSemana: "5", horas: [] },
                        { diasSemana: "6", horas: [] },
                        { diasSemana: "7", horas: [] },
                    ],
                    horarioTxt: '',
                    prioridad: '1000000'
                }
            ]

            setObjProfesional({
                ...objProfesional,
                sucursales: newSucursales,
                horarioSucursales: newHorarioSucursales
            })
        } else {
            dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
        }
    };

    const deleteSucursal = (e, sucursal) => {
        e.stopPropagation();
        if (editMode) {
            const newSucursales = objProfesional.sucursales.filter(item => (item !== sucursal));
            const newHorarioSucursales = objProfesional.horarioSucursales.filter(horarioSuc => {
                let respuesta = true;
                const idHorarioSuc = horarioSuc.sucursal._id ? horarioSuc.sucursal._id : horarioSuc.sucursal;
                if (sucursal === idHorarioSuc) {
                    respuesta = false;
                }
                return respuesta;
            })

            setObjProfesional({
                ...objProfesional,
                sucursales: newSucursales,
                horarioSucursales: newHorarioSucursales
            })
        } else {
            dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
        }
    };

    const guardar = async () => {
        setEditMode(true);
        // 
        let newProfesional = {
            ...objProfesional
        };
        if (profImage) {
            let previa = '';
            if (currentProfesional.img.length > 0) {
                previa = currentProfesional.img[0];
            }
            const imgName = `${currentProfesional._id}-${new Date().getTime()}`;
            const archivo = file;
            const nombreCortado = archivo.name.split('.');
            const extension = nombreCortado[nombreCortado.length - 1];
            const nombreCompleto = `${imgName}.${extension}`;

            newProfesional = { ...newProfesional, img: [nombreCompleto] };

            dispatch(startUploading(file, newProfesional, nombreCompleto, previa));
        }

        const sinCoincidencias = testHorarios(horasOcupadas);

        if (cambiado) {
            if (sinCoincidencias) {
                if (currentScreen === 'edit') {
                    dispatch(startSaveProfesional(newProfesional));
                    setCambiado(false);
                }
                if (currentScreen === 'new') {
                    const id = await dispatch(startNewProfesional(newProfesional));

                    const newProfesionalSaved = {
                        ...objProfesional,
                        _id: id
                    };
                    setObjProfesional(newProfesionalSaved)

                    history.push({
                        pathname: `/profesional/${id}`, state: {
                            activeTab,
                            profesional: newProfesionalSaved
                        }
                    });

                    setCurrentScreen("edit");
                }
            } else {
                dispatch(openModal({
                    title: 'Error en los horarios',
                    text: ['Este profesional tiene franjas horarias que se repiten en diferentes locales durante el mismo día.', 'Por favor, revise los horarios.'],
                    buttonText: 'Aceptar'
                }))
            }
        }

        if (cambiadoVacaciones) {
            setCambiadoVacaciones(false);
            compruebaVacaciones();
        }
    }

    const cancelar = () => {
        if (currentScreen !== 'new') {
            if (cambiadoVacaciones) {
                resetVacaciones();
            }
            if (cambiado) {
                setEditMode(true);
                setProfImage(null);
                setFile(null);
                setObjProfesional(currentProfesional);
            }
        } else {
            history.goBack();
        }
    }

    const resetVacaciones = () => {
        setDescansosTemp([...descansosObj]);
    }

    const compruebaVacaciones = async () => {
        descansosTemp.forEach(desc => {
            if (desc._id.slice(0, 3) === 'new') {
                const newDescanso = { ...desc };
                delete newDescanso._id;
                console.log(newDescanso);
                newVacaciones(newDescanso);
            } else {
                const items = descansosObj.filter(d => d._id === desc._id);
                if (items.length > 0) {
                    const item = items[0];
                    for (const propiedad in item) {
                        if (item[propiedad] !== desc[propiedad]) {
                            saveVacaciones(desc);
                            break;
                        }
                    }
                }
            }
        });
    }

    const newVacaciones = async (descanso) => {
        try {
            const resp = await fetchConToken(`vacaciones`, { ...descanso }, 'POST');
            const body = await resp.json();
            if (body.ok) {
                await getVacacionesProfesional();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const saveVacaciones = async (descanso) => {
        try {
            const resp = await fetchConToken(`vacaciones/${descanso._id}`, { empresa, fechaInicio: descanso.fechaInicio, fechaFin: descanso.fechaFin, diasSemana: descanso.diasSemana }, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                await getVacacionesProfesional();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteVacaciones = (descanso) => {
        if (descanso._id.substring(0, 3) !== 'new') {
            setDataModal({
                title: '¿Borrar periodo vacacional?',
                text: [`¿Está seguro de que desea borrar este periodo vacacional?`],
                buttonText: 'Estoy seguro',
                button2Text: 'Cancelar'
            })
            setModalTwoButtonsOpened(true);
            setModalPeriodoWaiting(descanso);
        }
    }

    const deleteVacacionesFromDB = async (id) => {
        try {
            const resp = await fetchConToken(`vacaciones/${id}`, { empresa }, 'DELETE');
            const body = await resp.json();
            if (body.ok) {
                await getVacacionesProfesional();
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (iniciado) {
            dataChange('valoracion', ratingValue.toString());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ratingValue]);


    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        setFile(file);
        setProfImage(URL.createObjectURL(file));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objProfesional]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png",
        noKeyboard: true,
        onDrop
    });

    return (
        <>
            <div
                className='profesional'
                style={{
                    '--colorPrincipal': colores.colorPrincipal,
                    '--colorSecundario': colores.colorSecundario,
                    '--color3': colores.color3,
                    '--color4': colores.color4,
                    '--color5': colores.color5,
                    '--color6': colores.color6,
                    '--color7': colores.color7,
                }}
            >
                <div className='profesional-wrapper'>
                    <div className='single-profesional'>
                        <div className={`header ${!miniLateral && 'compress'}`}>
                            <div
                                className='nav-back'
                                onClick={backClick}
                            >
                                <ArrowBackIcon color={colores.colorSecundario} />
                                <p style={{ color: colores.colorSecundario }}>Profesionales</p>
                            </div>
                            <div className='botonera'>
                                {
                                    (!cambiado && !cambiadoVacaciones) &&
                                    <button
                                        className='btn-mini btn-eliminar'
                                        style={{ backgroundColor: colores.color6 }}
                                        onClick={deleteProfesionalModal}
                                    >
                                        <TrashIcon color={colores.colorPrincipal} />
                                    </button>
                                }
                                {
                                    (cambiado || cambiadoVacaciones) &&
                                    <button
                                        className='btn btn-crear'
                                        style={{ backgroundColor: colores.colorPrincipal }}
                                        onClick={guardar}
                                    >
                                        {`${currentScreen === 'new' ? 'Crear' : 'Guardar'}`}
                                    </button>
                                }
                                {
                                    (cambiado || cambiadoVacaciones) &&
                                    <button
                                        className='btn btn-cancelar'
                                        style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
                                        onClick={cancelar}
                                    >
                                        Cancelar
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='profesional-body'>
                            <h1>{`${objProfesional?.nombre ? objProfesional?.nombre : 'Nombre'}`}</h1>
                            <div className='prof-main'
                                style={{
                                    backgroundColor: colores.color6
                                }}>
                                <div className='main-left'>
                                    <div
                                        {...getRootProps({ className: `dropzone avatar` })}
                                        style={{
                                            backgroundImage: `url(${profImage ? profImage : imageUrl.url})`
                                        }}
                                    >
                                        <input {...getInputProps({ className: `${(!profImage && !imageUrl) ? 'no-image' : ''}` })} />
                                        {
                                            (!profImage && !imageUrl) &&
                                            <>
                                                <AvatarIcon />
                                                <div className='avatar-bg'>+</div>
                                            </>
                                        }
                                    </div>
                                    <div className='data'>
                                        <div className='name'>
                                            <AutosizeInput
                                                inputStyle={{ backgroundColor: editMode ? '#ffffff' : 'transparent' }}
                                                autoComplete='off'
                                                type="text"
                                                placeholder='Nombre'
                                                name="nombre"
                                                disabled={!editMode}
                                                value={objProfesional.nombre || ''}
                                                onChange={(e) => onFormChange(e)}
                                            />
                                            <div className="picker">
                                                <div
                                                    className="swatch"
                                                    style={{ backgroundColor: objProfesional.color || '#448FF3' }}
                                                    onClick={() => clickPicker()}
                                                />
                                                {isOpen && (
                                                    <div className="popover" ref={popover}>
                                                        <HexColorPicker color={objProfesional.color || '#448FF3'} onChange={setColor} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='rate'>
                                            <Rating
                                                ratingValue={objProfesional.valoracion || '5'}
                                                setRatingValue={(e) => changeValoracion(e)}
                                            />
                                            <div className='rates-number'>
                                                (34 Valoraciones)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className='col-item delays'>
                                        <DelaySelect
                                            delayType={objProfesional.delayType}
                                            delay={objProfesional.delay}
                                            setDelay={setDelay}
                                            editMode={editMode}
                                        />
                                        <DelaySelectValue
                                            delayType={objProfesional.delayType}
                                            delay={objProfesional.delay}
                                            setDelay={setDelay}
                                            editMode={editMode}
                                        />
                                    </div>
                                    <div
                                        className='col-item'
                                        style={{ backgroundColor: colores.color6 }}
                                    >
                                        <div className='toggle-text'>
                                            <p>Visible en App</p>
                                        </div>
                                        <div
                                            className={`ylm-toggle ${objProfesional.visibleEnApp && 'active'}`}
                                                onClick={() => dataChange('visibleEnApp', !objProfesional.visibleEnApp)}
                                        >
                                            <div
                                                className='toggle-chevron'
                                            >
                                                <div
                                                    className={`toggle-bg`}
                                                    style={{ backgroundColor: objProfesional.visibleEnApp ? '#FFFFFF' : '#C9C5C5' }}
                                                    >
                                                    <span
                                                        className={`toggle-item`}
                                                        style={{ backgroundColor: objProfesional.visibleEnApp ? colores.colorPrincipal : '#7B7B7B' }}
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='prof-tabs'>
                                <div
                                    className={`prof-tab ${activeTab === 'horarios' && 'active'}`}
                                    style={{
                                        backgroundColor: activeTab === 'horarios' ? colores.colorPrincipal : colores.color6,
                                        color: activeTab === 'horarios' ? 'white' : colores.colorPrincipal
                                    }}
                                    onClick={() => changeTab('horarios')}
                                >
                                    <span className='tab-icon'><HorarioIcon color={activeTab === 'horarios' ? 'white' : colores.colorPrincipal} /></span>
                                    <p>Horario y sucursales</p>
                                </div>
                                <div
                                    className={`prof-tab ${activeTab === 'descansos' && 'active'}`}
                                    style={{
                                        backgroundColor: activeTab === 'descansos' ? colores.colorPrincipal : colores.color6,
                                        color: activeTab === 'descansos' ? 'white' : colores.colorPrincipal
                                    }}
                                    onClick={() => changeTab('descansos')}
                                >
                                    <span className='tab-icon'><DescansosIcon color={activeTab === 'descansos' ? 'white' : colores.colorPrincipal} /></span>
                                    <p>Días especiales</p>
                                </div>
                                <div
                                    className={`prof-tab ${activeTab === 'servicios' && 'active'}`}
                                    style={{
                                        backgroundColor: activeTab === 'servicios' ? colores.colorPrincipal : colores.color6,
                                        color: activeTab === 'servicios' ? 'white' : colores.colorPrincipal
                                    }}
                                    onClick={() => changeTab('servicios')}
                                >
                                    <span className='tab-icon'><ServiciosIcon color={activeTab === 'servicios' ? 'white' : colores.colorPrincipal} /></span>
                                    <p>Servicios</p>
                                </div>
                                <div
                                    className={`prof-tab ${activeTab === 'permisos' && 'active'}`}
                                    style={{
                                        backgroundColor: activeTab === 'permisos' ? colores.colorPrincipal : colores.color6,
                                        color: activeTab === 'permisos' ? 'white' : colores.colorPrincipal,
                                        userSelect: 'none',
                                        cursor: "unset",
                                        textDecoration: 'none',
                                        opacity: "0.6"
                                    }}
                                /*onClick={() => changeTab('permisos')}*/
                                >
                                    <span className='tab-icon'><ConfigIcon color={activeTab === 'permisos' ? 'white' : colores.colorPrincipal} /></span>
                                    <p>Permisos</p>
                                </div>
                            </div>
                            <div className='prof-secondary'>
                                {
                                    activeTab === 'horarios' &&
                                    <Horario
                                        objProfesional={objProfesional}
                                        setObjProfesional={setObjProfesional}
                                        currentProfesional={currentProfesional}
                                        addSucursalValue={addSucursalValue}
                                        setAddSucursalValue={setAddSucursalValue}
                                        addSucursal={addSucursal}
                                        deleteSucursal={deleteSucursal}
                                        setProfSaved={setProfSaved}
                                        horasOcupadas={horasOcupadas}
                                        setHorasOcupadas={setHorasOcupadas}
                                        editMode={editMode}
                                    />
                                }
                                {
                                    activeTab === 'descansos' &&
                                    <Descansos
                                        objProfesional={objProfesional}
                                        setObjProfesional={setObjProfesional}
                                        descansosObj={descansosObj}
                                        descansosTemp={descansosTemp}
                                        setDescansosTemp={setDescansosTemp}
                                        deleteVacaciones={deleteVacaciones}
                                        editMode={editMode}
                                    />
                                }
                                {
                                    activeTab === 'servicios' &&
                                    <Servicios
                                        objProfesional={objProfesional}
                                        setObjProfesional={setObjProfesional}
                                        editMode={editMode}
                                    />
                                }
                                {
                                    activeTab === 'permisos' &&
                                    <Permisos
                                        objProfesional={objProfesional}
                                        setObjProfesional={setObjProfesional}
                                        editMode={editMode}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalEliminarProfesional
                data={dataModalEliminar}
                setModalResponse={deleteProfesionalResponse}
                modalOpened={modalEliminarProfesionalOpened}
                setModalOpened={setModalEliminarProfesionalOpened}
            />
            <YlmModalTwoButtons
                data={dataModal}
                setModalResponse={setModalPeriodoResponse}
                modalTwoButtonsOpened={modalTwoButtonsOpened}
                setModalTwoButtonsOpened={setModalTwoButtonsOpened}
            />
        </>
    )
}
