import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { startLoginBusiness } from '../../actions/auth';
import { closeLogin } from '../../actions/ui';
import { isEmail } from '../../helpers/forms';
import { useForm } from '../../hooks/useForm';

import bgImage from '../../assets/img/bg_login_exora.jpg';
import appLogo from '../../assets/svg/logo-exora.svg';

const initErrores = {
    email: { active: false, msg: 'Debe introducir un email'},
    password: { active: false, msg: 'La contraseña debe tener 6 o más caracteres'}
}

export const Login = () => {
    
    const dispatch = useDispatch();
    const { loading } = useSelector( state => state.ui );
    const { uid } = useSelector(state => state.auth);

    const [rememberCheckActive, setRememberCheckActive] = useState(false);

    const [ formValues, handleInputChange, reset ] = useForm({
        email: '',
        password: ''
    });

    // eslint-disable-next-line no-unused-vars
    const [messageVisible, setMessageVisible] = useState(false);
    const [errores, setErrores] = useState( initErrores );

    useEffect(() => {
      if( localStorage.getItem( 'barbgest-user' ) ){
        formValues.email = localStorage.getItem( 'barbgest-user' );
        setRememberCheckActive( true );
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    useEffect(() => {
        if( uid ) {
            dispatch(closeLogin());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);

    const { email, password } = formValues;

    const handleLogin = (e) => {
        e.preventDefault();
        let hayErrores = false;
        const objErrores = {
            email: { active: false, msg: 'Debe introducir un email'},
            password: { active: false, msg: 'La contraseña debe tener 6 o más caracteres'}
        };

        if( !isEmail( formValues.email ) ){
            hayErrores = true;
            objErrores.email.active = true; 
        }
        if( formValues.password.trim().length < 6 ){
            hayErrores = true;
            objErrores.password.active = true; 
        }
        if( !hayErrores ){
            setErrores( initErrores );
            if( rememberCheckActive ){
                localStorage.setItem( 'barbgest-user', email );
            } else {
                localStorage.removeItem( 'barbgest-user' );
            }
            dispatch( startLoginBusiness(email, password) );
            reset();
            setMessageVisible( true );
        } else {
            setErrores( objErrores );
        }
    };

    return (
        <div className={`login-container ${(!uid) ? 'active' : ''}`} style={{ backgroundImage: `url('${bgImage}`}}>
            <div className="form-container">
                <div className="form-header">
                    <Link to="/"><img src={appLogo} alt="logotipo"></img></Link>            
                </div>
                <form onSubmit={ handleLogin }>
                    <div className="form-item">
                        <input
                            type="email"
                            placeholder="Usuario"
                            name="email"
                            className="auth__input"
                            autoComplete="disabled"
                            value={ email }
                            onChange={ handleInputChange }
                        />
                        <p className={`error-text ${(errores.email.active) ? 'active' : ''}`}>
                            { errores.email.msg }
                        </p>
                    </div>
                    <div className="form-item">
                        <input 
                            type="password"
                            placeholder="Password"
                            name="password"
                            className="auth__input"
                            autoComplete="disabled"
                            value={ password }
                            onChange={ handleInputChange }
                        />
                        <p className={`error-text ${(errores.password.active) ? 'active' : ''}`}>
                            { errores.password.msg }
                        </p>
                    </div>

                    <div className="chk-recordarme">
                        <label
                            className="container">Recordarme
                            <input 
                                type="checkbox"
                                checked={ rememberCheckActive }
                                onChange={ (e) => setRememberCheckActive( !rememberCheckActive ) } 
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>

                    <div>
                        <button
                            className={ `btn-iniciar-sesion visible`}
                            type="submit"
                            disabled={ loading }
                        >
                            Identifícate
                        </button>
                    </div>

                    <div className="form-messages">
                        <p
                            className="remember-password"
                        >
                            <Link to="/auth/recover-password">¿Olvidaste tu contraseña?</Link>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    )
}
