
import { types } from "../types/types";

const initialState = {
    sucursales: [],
    active: null
}

export const sucursalesReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.sucursalesActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.sucursalesUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.sucursalesAddNew:
            return {
                ...state,
                sucursales: [ ...state.sucursales, action.payload ]
            }

        case types.sucursalesLoad:
            return {
                ...state,
                sucursales: [ ...action.payload ]
            }
        
        case types.sucursalesUpdated:
            return {
                ...state,
                sucursales: state.sucursales.map(
                    sucursal => sucursal._id === action.payload._id
                        ? action.payload
                        : sucursal
                )
            }

        case types.sucursalesDelete:
            return {
                ...state,
                active: null,
                sucursales: state.sucursales.filter( sucursal => sucursal._id !== action.payload )
            }
    
        case types.sucursalesLogoutCleaning:
            return {
                ...state,
                active: null,
                sucursales: []
            }
    

        default:
            return state;
    }
}