import { types } from "../types/types";
import { fetchConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewVariante = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'variantes', values, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                /* const datos = {
                    title: 'Creado',
                    text: [ values.nombre ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) ); */
                const datos = {
                    text: [ `La variante ${ values.nombre } ha sido creada` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewVariante( body.variante._id, body.variante ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activeVariante = ( _id, variante ) => ({
    type: types.variantesActive,
    payload: {
        _id,
        ...variante
    }
});

export const unactiveVariante = () => ({
    type: types.variantesUnactive
});

export const addNewVariante = ( _id, variante ) => ({
    type: types.variantesAddNew,
    payload: {
        _id,
        ...variante
    }
})

export const startLoadingVariante = ( id, empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `variantes/variante/${ id }`, {empresa}, 'POST' );
            const body = await resp.json();
            if( body.variante ){
                dispatch( activeVariante( body.variante._id, body.variante ));
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingVariantes = ( empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'variantes/empresa', {empresa}, 'POST' );
            const body = await resp.json();
            dispatch( setVariantes( body.variantes ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setVariantes = ( variantes ) => ({
    type: types.variantesLoad,
    payload: variantes
})

export const startSaveVariante = ( variante, swal = true ) => {
    return async( dispatch, getState ) => {
        
        try {
            const resp = await fetchConToken( `variantes/${ variante._id }`, variante, 'PUT' );
            const body = await resp.json();
            if ( body.ok ) {
                dispatch( refreshVariante( variante._id, body.variante ) );
                const datos = {
                    text: [ `Variante guardada` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            } else {
                console.log( body );
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshVariante = ( id, variante ) => ({
    type: types.variantesUpdated,
    payload: {
        ...variante
    }
});

export const varianteStartDelete = ( id, empresa ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `variantes/${ id }`, {empresa}, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                dispatch( varianteDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

export const variantesReorder = ( variantesList, empresa ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( `variantes/reorder`, {variantesList, empresa}, 'POST' );
            const body = await resp.json();
            if ( body.ok ) {
                console.log( 'Reordered' );
            }
            
        } catch (error) {
            console.log(error);            
        }
    }
};

const varianteDeleted = ( id ) => ({
    type: types.variantesDelete,
    payload: id
});

export const varianteLogout = () => ({
    type: types.variantesLogoutCleaning
});
