import { types } from "../types/types";
import { fetchConToken } from '../helpers/fetch';

export const startLoadingClienteSaldos = ( id ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `clientesaldo/listado/${ id }`, 'GET' );
            const body = await resp.json();
            console.log("Pasando por startLoadingClienteSaldos...");
            if( body.clienteSaldos ){
                dispatch( setClienteSaldos( body.clienteSaldos));
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const setClienteSaldos = ( clienteSaldos ) => ({
    type: types.clienteSaldosLoad,
    payload: clienteSaldos
});