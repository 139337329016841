import { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useOutsideClick } from "./useOutsideClick";

export const DropdownMultipleServicios = ({ services, selectedServices, handleServicesChanged, editCita = false }) => {

    // Script nuevo
    const { colores } = useSelector(state => state.ui);
    const [isLoaded, setIsLoaded] = useState(false);
    const [visibility, setVisibility] = useState(false);
    //const servicios = variantes.filter(v => selectedServicio?.selected?.variantes.includes(v._id));
    const dropdownMenuRef = useRef();

    const toggleVisibility = () => {
        setVisibility(!visibility);
    }
    
    useEffect(() => {
        setIsLoaded(true)
    }, [])
    
    // useEffect(() => {
    //     if (!isLoaded) return;
    //     handleServicesChanged([]);
    // }, [selectedServices])
    
    useOutsideClick(dropdownMenuRef, () => setVisibility(!visibility), visibility);

    const handleOnChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            handleServicesChanged([...selectedServices, services.find(v => v._id === value)]);
        } else {
            handleServicesChanged(selectedServices.filter(v => v._id !== value));
        }
      }
    
    const isChecked = (service) => {
        return selectedServices?.some(v => v._id === service._id)
    }

    return (
        <div className="brb-select">
            <div
                className={`brb-select-container ${visibility ? 'active' : ''}`}
                onClick={toggleVisibility}
            >
            {/* <div
                className={`brb-select-container ${(selectServicio.visible) ? 'visible' : ''} ${(visibility && !editCita) ? 'active' : ''}`}
                onClick={() => (selectServicio.visible && visibility) ? hideDropdown() : showDropdown()}
            > */}
                <p>{selectedServices.length ? selectedServices.map(v => v.nombre).join(", ") : "Seleccione servicios"}</p>
                <div className="right-zone">
                <span
                    className="select-arrow select-arrow-down"
                ></span>
                </div>
            </div>
            <div ref={dropdownMenuRef} className={`brb-select-options select-servicio ${visibility ? 'active' : ''}`}>
                {
                    services.map((service) => {
                        return (
                        <div key={service._id} className="brb-select-option" style={{ backgroundColor: colores.color6 }}>
                            <input type="checkbox" id={service._id} name={service._id} value={service._id} checked={isChecked(service)} onChange={handleOnChange} />
                            <label htmlFor={service._id}>{service.nombre}</label>
                        </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
