import React from 'react'
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import uuid from 'react-uuid';

import { CalendarIcon, DndIcon, ListIcon } from '../../../assets/svg/iconos';
import { formatColor } from '../helpers/helpersReservas';
import { DailyCalendar } from './DailyCalendar';
import { CitasList } from './CitasList';

export const AgendaView = ({ viewType, viewValue, changeMonth, monthName, 
                             currentMonth, changeDay, selectedDateCalendar, changeView, changeType, 
                             calendarDays, selectedDate, selectDate, arrSucursales, arrProfesionales, 
                             dailyItem, editCita, setEditCita, activeDnd, setActiveDnd, citas, 
                             setActiveModalDeleteCita, visibleDnd, toggleDnd }) => {
    const { colores } = useSelector(state => state.ui);

    return (
        <>
            <div className="calendar-header">
                {
                    (viewType === 'calendar' && viewValue === 'month')
                    &&
                    <div className="selector">
                        <div
                            className="btn btn-arrow arrow-left disable-select"
                            onClick={ () => changeMonth('subtract') }
                        ></div>
                        <div className="ylm-month-year">
                            <div className="ylm-month disable-select">{ monthName[0].name }</div>
                            <div className="ylm-year disable-select">{ currentMonth.format('YYYY')}</div>
                        </div>
                        <div
                            className="btn btn-arrow arrow-right disable-select"
                            onClick={ () => changeMonth('add') }
                        ></div>
                    </div>
                }
                {
                    (viewType === 'calendar' && viewValue === 'day')
                    &&
                    <div className="selector">
                        <div
                            className="btn btn-arrow arrow-left disable-select"
                            onClick={ () => changeDay('subtract') }
                        ></div>
                        <div className="ylm-month-year">
                            <div className="ylm-day disable-select">{ selectedDateCalendar.date() }</div>
                            <div className="ylm-month disable-select">{ monthName[0].name }</div>
                            <div className="ylm-year disable-select">{ selectedDateCalendar.format('YYYY')}</div>
                        </div>
                        <div
                            className="btn btn-arrow arrow-right disable-select"
                            onClick={ () => changeDay('add') }
                        ></div>
                    </div>
                }
                {
                    (viewType === 'list')
                    &&
                    <div className="list-title">
                        <h3>Fichas de reserva</h3>
                    </div>
                }
                <div className="right">
                    {
                        (viewType === 'calendar')
                        &&
                        <div className="month-selector">
                            <div 
                                className={`btn ${(viewValue === 'month') ? 'active' : ''}`}
                                style={{
                                    color: colores.colorPrincipal,
                                    backgroundColor: viewValue === 'month' ? colores.colorSecundario : colores.color6
                                }}
                                onClick={ () => changeView('month') }
                            >Mensual</div>
                            <div 
                                className={`btn ${(viewValue === 'day') ? 'active' : ''}`}
                                style={{
                                    color: colores.colorPrincipal,
                                    backgroundColor: viewValue === 'day' ? colores.colorSecundario : colores.color6
                                }}
                                onClick={ () => changeView('day') }
                            >Diario</div>
                        </div>
                    }
                    <div className="view-selector">
                        <div 
                            className={`btn btn-calendar ${(viewType === 'calendar') ? 'active' : ''}`}
                            style={{
                                color: viewType === 'calendar' ? colores.colorPrincipal : colores.colorSecundario,
                                backgroundColor: viewType === 'calendar' ? colores.colorSecundario : colores.color6
                            }}
                            onClick={ () => changeType('calendar') }
                        >
                            <CalendarIcon color={ colores.colorPrincipal} />
                        </div>
                        <div 
                            className={`btn btn-list ${(viewType === 'list') ? 'active' : ''}`}
                            style={{
                                color: viewType === 'list' ? colores.colorPrincipal : colores.colorSecundario,
                                backgroundColor: viewType === 'list' ? colores.colorSecundario : colores.color6
                            }}
                            onClick={ () => changeType('list') }
                        >
                            <ListIcon color={ colores.colorPrincipal} />
                        </div>
                    </div>
                </div>
            </div>
            {
                (viewType === 'calendar' && viewValue === 'month')
                &&
                <div className={ `month-view` }>
                <div className="week-days">
                    <div className="week-day disable-select"><span>Lunes</span></div>
                    <div className="week-day disable-select"><span>Martes</span></div>
                    <div className="week-day disable-select"><span>Miércoles</span></div>
                    <div className="week-day disable-select"><span>Jueves</span></div>
                    <div className="week-day disable-select"><span>Viernes</span></div>
                    <div className="week-day disable-select"><span>Sábado</span></div>
                    <div className="week-day disable-select"><span>Domingo</span></div>
                </div>
                <div className="ylm-dates">
                    {
                        calendarDays.map( day => (
                            <div 
                                key={ day._id } 
                                className={`ylm-date disable-select ${ (!day.current) ? 'no-current' : ''} ${(day.date.format('D-M-YYYY') === moment().format('D-M-YYYY')) ? 'today' : ''} ${(selectedDate.format('D-M-YYYY') === day.date.format('D-M-YYYY')) ? 'selected' : '' }`}
                                onClick={ () => selectDate(day) }
                            >
                                <div 
                                    className="ylm-day"
                                    style={{
                                        backgroundColor: selectedDate.format('D-M-YYYY') === day.date.format('D-M-YYYY') ? colores.colorPrincipal : 'transparent'
                                    }}
                                >{ day.date.date() }</div>
                                <div className="indicators">
                                    {
                                        day.calendarItems.map( row => (
                                            row.items.map( calendarItem => (
                                                arrSucursales.map( sucursal => (
                                                    arrProfesionales.map( profesional => (
                                                        ( sucursal.selected && (sucursal.sucursal._id === calendarItem.sucursal._id) && profesional.selected && (profesional.profesional._id === calendarItem.profesional._id) && !calendarItem.repetido )
                                                        &&
                                                        <div
                                                            key={ uuid() }
                                                            style={{backgroundColor: `${formatColor(profesional.profesional.color)}`}}
                                                            className={ `indicator` }
                                                        ></div>
                                                    ))
                                                ))
                                            ))
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            }
            {
                (viewType === 'calendar' && viewValue === 'day')
                &&
                <DailyCalendar
                    dailyItem = { dailyItem }
                    viewValue = { viewValue }
                    editCita = { editCita }
                    setEditCita = {setEditCita }
                    activeDnd = { activeDnd }
                    setActiveDnd={ setActiveDnd }
                />
            }
            {
                (viewType === 'list')
                &&
                <CitasList
                    citas = { citas }
                    arrSucursales = { arrSucursales }
                    arrProfesionales = { arrProfesionales }
                    selectedDate = { selectedDate }
                    currentMonth = { currentMonth }
                    setActiveModalDeleteCita = { setActiveModalDeleteCita }
                    setEditCita = {setEditCita }
                    viewValue = { viewValue }
                />
            }
            {
                (visibleDnd && viewType === 'calendar') &&
                <div 
                    className={ `toggle-dnd ${activeDnd && 'active'}`}
                    onClick={ toggleDnd }
                ><DndIcon color={ activeDnd ? 'white' : colores.colorPrincipal } /><p>Drag & Drop</p></div>
            }
        </>
    )
}
