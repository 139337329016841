import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { Login } from '../pages/auth/Login';
import { RecoverPassword } from '../pages/auth/RecoverPassword';
import { ResetPassword } from '../pages/auth/ResetPassword';
import { PublicRoute } from './PublicRoute';
import { AccountConfirmation } from '../pages/auth/AccountConfirmation';

export const AuthRouter = () => {
    return (
        <div className="auth__main">
            <div className="auth__box-container">
                <Switch>
                    <PublicRoute
                        exact
                        path="/auth/account-confirmation/:id" 
                        component={ AccountConfirmation }
                    />
                    <PublicRoute
                        exact
                        path="/auth/recover-password" 
                        component={ RecoverPassword }
                    />
                    <PublicRoute
                        exact
                        path="/auth/reset-password/:id" 
                        component={ ResetPassword }
                    />
                    <PublicRoute
                        exact
                        path="/auth/login"
                        component={ Login }
                    />
                    <PublicRoute
                        exact
                        path="/auth/account-confirmation/:id" 
                        component={ AccountConfirmation }
                    />

                    <Redirect to="/auth/login" />

                </Switch>
            </div>
        </div>
    )
}
