import Swal from 'sweetalert2';

import { types } from "../types/types";
import { fetchConToken, archivoConToken, uploadFile } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewEmpresa = (values, swal = true) => {
    return async (dispatch, getState) => {
        console.log('values', values);
        try {
            const resp = await fetchConToken('empresas', values, 'POST');
            const body = await resp.json();
            if (body.ok) {
                console.log(body);
                const datos = {
                    text: [`La empresa ${values.nombre} ha sido creada`],
                    timeOut: 3
                }
                //dispatch( openModalBottom( datos ) );
                dispatch(addNewEmpresa(body.empresa._id, body.empresa));
            } else {
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log(error);
            const datos = {
                title: 'Error',
                text: ['Ha habido un error'],
                buttonText: 'Cerrar'
            }
            dispatch(openModal(datos));
        }
    }
};

export const startNewPublicEmpresa = (values, swal = true) => {
    return async (dispatch, getState) => {
        console.log('values', values);
        try {
            const resp = await fetchConToken('empresas/business/new', values, 'POST');
            const body = await resp.json();
            if (body.ok) {
                console.log(body);
                const datos = {
                    text: [`La empresa ${values.nombre} ha sido creada`],
                    timeOut: 3
                }
                //dispatch( openModalBottom( datos ) );
                dispatch(addNewEmpresa(body.empresa._id, body.empresa));
            } else {
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log(error);
            const datos = {
                title: 'Error',
                text: ['Ha habido un error'],
                buttonText: 'Cerrar'
            }
            dispatch(openModal(datos));
        }
    }
};

export const activeEmpresa = (_id, empresa) => ({
    type: types.empresasActive,
    payload: {
        _id,
        ...empresa
    }
});

export const unactiveEmpresa = () => ({
    type: types.empresasUnactive
});

export const addNewEmpresa = (_id, empresa) => ({
    type: types.empresasAddNew,
    payload: {
        _id,
        ...empresa
    }
})

export const startLoadingEmpresas = (page = 1, size = 10, sort = 'nombre', asc = true) => {
    return async (dispatch) => {
        try {
            //console.log( page, size, sort, asc );
            const resp = await fetchConToken(`empresas?page=${page}&size=${size}&sort=${sort}&asc=${asc}`);
            const body = await resp.json();
            //console.log( body );
            dispatch(setEmpresas(body.empresas, body.total));
        } catch (error) {
            console.log(error);
        }
    }
};

export const startSearchEmpresas = (data, page = 1, size = 10, sort = 'creacion', asc = true) => {
    //console.log( 'startSearchEmpresas' );
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`empresas/buscar/${data}`, { page, size, sort, asc }, 'POST');
            const body = await resp.json();
            if (body.empresas) {
                dispatch(setEmpresas(body.empresas, body.total));
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingEmpresaById = (id) => {
    return async (dispatch) => {
        try {
            //console.log('startLoadingEmpresaById');
            const resp = await fetchConToken('empresas/empresa', { id }, 'POST');
            const body = await resp.json();
            dispatch(setEmpresas(body.empresas, body.total));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setEmpresas = (empresas, total = 0) => ({
    type: types.empresasLoad,
    payload: { empresas, total }
})

const uploadAllImages = async (empresa) => {
    const filesProp = ["appIcon", "icon", "corpImage", "img"];

    try {
        for (const fileProp of filesProp) {
            const file = empresa[fileProp];
			let counter = 0;

            if (Array.isArray(file)) {
                for (let i = 0; i < file.length; i++) {
                    if (file[i] instanceof Blob) {
                        const response = await uploadFile("empresas", file[i], `${fileProp}_${empresa._id}_${i}`);
                        const body = await response.json();

                        empresa[fileProp][counter] = body.path;
						counter++
                    }else{
						if (file[i] != null){
							empresa[fileProp][counter] = file[i];
							counter++
						}
					}
                }
            }
            else {
                if (file instanceof Blob) {
                    const response = await uploadFile("empresas", file, `${fileProp}_${empresa._id}`);
                    const body = await response.json();

                    empresa[fileProp] = body.path;
                }
            }
        }
    } catch (error) {

    }
}

export const startSaveEmpresa = (empresa, changePass = false, swal = true) => {
    return async (dispatch, getState) => {
        try {

            await uploadAllImages(empresa);

            const resp = await fetchConToken(`empresas/${empresa._id}`, { empresa, changePass }, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(refreshEmpresa(empresa._id, body.empresa));
                const datos = {
                    text: ['Se han guardado correctamente los cambios en la configuración.'],
                    timeOut: 3
                }
                dispatch(openModalBottom(datos));
            } else {
                console.log(body);

                const datos = {
                    text: [body.msg],
                    timeOut: 3
                }
                dispatch(openModalBottom(datos));
            }


        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshEmpresa = (id, empresa) => ({
    type: types.empresasUpdated,
    payload: {
        ...empresa
    }
});

export const startUploading = (archivo, empresa, nombre, previa) => {

    return async (dispatch, getState) => {
        try {
            const formData = new FormData();
            // console.log( archivo );
            formData.append('archivo', archivo);
            formData.append('empresa', empresa._id);
            formData.append('nombre', nombre);
            formData.append('previa', previa);
            formData.append('file_name', 'archivo');

            await archivoConToken(`empresas/upload/${empresa._id}`, formData, 'POST');

            //console.log( body );

            //Swal.close();
        } catch (error) {
            throw new Error();
        }

        Swal.close();
    }
};

export const empresaStartDelete = (id) => {
    return async (dispatch, getState) => {

        try {
            const resp = await fetchConToken(`empresas/${id}`, {}, 'DELETE');
            const body = await resp.json();

            if (body.ok) {
                // console.log( body, id );
                dispatch(empresaDeleted(id));
            } else {
                const datos = {
                    title: 'Error',
                    text: [body.msg],
                    buttonText: 'Cerrar'
                }
                dispatch(openModal(datos));
            }


        } catch (error) {
            console.log(error);
        }
    }
}

const empresaDeleted = (id) => ({
    type: types.empresasDelete,
    payload: id
});

export const empresaLogout = () => ({
    type: types.empresasLogoutCleaning
});
