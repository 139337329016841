import { types } from "../types/types";
import { fetchConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewPlan = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'planes', values, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                const datos = {
                    text: [ `Plan creado` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewPlan( body.plan._id, body.plan ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activePlanes = ( _id, planes ) => ({
    type: types.planesActive,
    payload: {
        _id,
        ...planes
    }
});

export const unactivePlanes = () => ({
    type: types.planesUnactive
});

export const addNewPlan = ( _id, planes ) => ({
    type: types.planesAddNew,
    payload: {
        _id,
        ...planes
    }
});

export const startLoadingPlanes = () => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'planes', {} );
            const body = await resp.json();
            dispatch( setPlanes( body.planes ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setPlanes = ( planes ) => ({
    type: types.planesLoad,
    payload: planes
});

export const startSavePlan = ( plan ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( `planes/${ plan._id }`, plan, 'PUT' );
            const body = await resp.json();
            if ( body.ok ) {
                dispatch( refreshPlan( plan._id, plan ) );
                const datos = {
                    text: [ 'Se han guardado correctamente los cambios en el plan.' ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            } else {
                console.log( body );

                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const refreshPlan = ( id, plan ) => ({
    type: types.planesUpdated,
    payload: {
        ...plan
    }
});

export const planesStartDelete = ( id ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `planes/${ id }`, {}, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                dispatch( planDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

const planDeleted = ( id ) => ({
    type: types.planesDelete,
    payload: id
});

export const planLogout = () => ({
    type: types.planesLogoutCleaning
});