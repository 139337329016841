import React from 'react'

export const YlmRadio = ({active, clickFunction, text, color, bgColor }) => {
  return (
    <div 
        className={`ylm-radio ${active && 'active'}`}
        onClick={ clickFunction }
    >
        <div
            className='radio-bg'
            style={{ backgroundColor: bgColor }}
        >
            <span
                className='radio-item'
                style={{ backgroundColor: color }}
            ></span>
        </div>
        <p>{ text }</p>
    </div>
  )
}
