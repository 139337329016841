import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

const PeriodSelectDate = ({initDate, endDate, dateChange, descanso, fechaCalendarSimple, fechaCalendarCompuesta}) => {
    const { colores } = useSelector( state => state.ui );

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();


    const showDropdown = () => {
        setVisibility(true);
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

    return (
        <>
        <div 
            className='selector init-date'
            style = {{ 
                backgroundColor: colores.color6,
                borderColor: colores.colorPrincipal,
                padding: descanso.tipo === 'ausencia' ? '10px 25px' : '10px 11px'
            }}
        >
            {/* <span><PeriodCalendarIcon /></span> */}
            <div 
                className='date-text'                
                onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
            >
                <p>{ moment(initDate).format('D-M-YY') }</p>
                { descanso.tipo !== 'ausencia' &&
                    <><span>-</span><p>{ moment(endDate).format('D-M-YY') }</p></>
                }
            </div>
            <div
                className={`ylm-select-options vacacional-period-options ${ visibility ? 'active' : '' }`}>
                <div
                    ref={ dropdownMenuRef }
                    style={{
                        backgroundColor: colores.color6
                    }}
                    className='period-calendar'
                >
                    <Calendar
                        //onClickDay={ () => dayClicked() }
                        onChange={ dateChange }
                        value={ descanso.tipo === 'ausencia' ? fechaCalendarSimple : fechaCalendarCompuesta }
                        selectRange={ descanso.tipo === 'ausencia' ? false : true }
                        locale={`es-ES`}
                    />
                </div>
            </div>
        </div>
        </>
    )
}

export default PeriodSelectDate