import React from 'react'
import { CartIcon, FooterAdd, FooterBonos, FooterHome, FooterProductos, FooterReservas, UserIcon } from '../../../../assets/svg/iconos'

export const CustomScreen2 = ({ colorThemeSelected, fontThemeSelected }) => {
  return (
        <div className='slider-item'>
            <div 
                className='second-header'
                style={{
                    color: colorThemeSelected.main
                }}
            >
                <div 
                    className='header-item'
                    style={{
                        color: '#FFFFFF',
                        backgroundColor: colorThemeSelected.main 
                    }}
                >Promos</div>
                <div className='header-item'>Bonos</div>
                <div className='header-user'>
                    <UserIcon color={ colorThemeSelected.main } width={15} height={12}/>
                </div>
            </div>
            <div className='shop-items'>
                <div 
                    className='shop-item'
                    style={{
                        backgroundColor: colorThemeSelected.secondary[0]
                    }}
                >
                    <div className='row-1'><h4 style={{ color: colorThemeSelected.main }}>Servicio</h4></div>
                    <div className='row-2'>
                        <div className='row-icon'><FooterBonos color={ colorThemeSelected.main } width={11} height={11} /></div>
                        <div className='row-title'>
                            <h2 style={{
                                color: colorThemeSelected.main,
                                fontFamily: `${fontThemeSelected}, sans-serif`
                            }}>Horario mañanas</h2>
                        </div>
                        <div 
                            className='row-btn'
                            style={{
                                borderColor: colorThemeSelected.main
                            }}
                        ><CartIcon color={ colorThemeSelected.main } /></div>
                    </div>
                    <div 
                        className='row-3'
                        style={{
                            color: colorThemeSelected.main
                        }}
                    >
                        <div className='column promo-column'>
                            <h5>Promo</h5>
                            <p>-15%</p>
                        </div>
                        <div className='column'>
                            <h5>Caducidad</h5>
                            <p>15/12/21</p>
                        </div>
                    </div>
                </div>
                <div 
                    className='shop-item'
                    style={{
                        backgroundColor: colorThemeSelected.secondary[3]
                    }}
                >
                    <div className='row-1'><h4 style={{ color: colorThemeSelected.main }}>Pack</h4></div>
                    <div className='row-2'>
                        <div className='row-icon'><FooterBonos color={ colorThemeSelected.main } width={11} height={11} /></div>
                        <div className='row-title'>
                            <h2 style={{
                                color: colorThemeSelected.main,
                                fontFamily: `${fontThemeSelected}, sans-serif`
                            }}>Cumpleaños</h2>
                        </div>
                        <div 
                            className='row-btn'
                            style={{
                                borderColor: colorThemeSelected.main
                            }}
                        ><CartIcon color={ colorThemeSelected.main } /></div>
                    </div>
                    <div 
                        className='row-3'
                        style={{
                            color: colorThemeSelected.main
                        }}
                    >
                        <div className='column promo-column'>
                            <h5>Promo</h5>
                            <p>-15%</p>
                        </div>
                        <div className='column'>
                            <h5>Caducidad</h5>
                            <p>15/12/21</p>
                        </div>
                    </div>
                </div>
                <div 
                    className='shop-item'
                    style={{
                        backgroundColor: colorThemeSelected.secondary[5]
                    }}
                >
                    <div className='row-1'><h4 style={{ color: colorThemeSelected.main }}>Producto</h4></div>
                    <div className='row-2'>
                        <div className='row-icon'><FooterBonos color={ colorThemeSelected.main } width={11} height={11} /></div>
                        <div className='row-title'>
                            <h2 style={{
                                color: colorThemeSelected.main,
                                fontFamily: `${fontThemeSelected}, sans-serif`
                            }}>Champú</h2>
                        </div>
                        <div 
                            className='row-btn'
                            style={{
                                borderColor: colorThemeSelected.main
                            }}
                        ><CartIcon color={ colorThemeSelected.main } /></div>
                    </div>
                    <div 
                        className='row-3'
                        style={{
                            color: colorThemeSelected.main
                        }}
                    >
                        <div className='column promo-column'>
                            <h5>Promo</h5>
                            <p>-15%</p>
                        </div>
                        <div className='column'>
                            <h5>Caducidad</h5>
                            <p>15/12/21</p>
                        </div>
                    </div>
                </div>
            </div>
            <div 
            className='common-footer'
            style={{
                backgroundColor: colorThemeSelected.main
            }}
            >
                <div className='footer-btn'>
                    <FooterHome color={colorThemeSelected.secondary[0]} width={13} height={13} />
                </div>
                <div className='footer-btn'>
                    <FooterReservas color={colorThemeSelected.main} width={13} height={13} active={false}/>
                </div>
                <div className='footer-btn central'>
                    <div className='central-wrapper'>
                        <div 
                        className='central-btn'
                        style={{
                            backgroundColor: colorThemeSelected.secondary[1]
                        }}
                        ><FooterAdd color={ colorThemeSelected.main }/></div>
                        <div 
                        className='central-txt'
                        style={{
                            color: colorThemeSelected.secondary[1]
                        }}
                        >Reservar</div>
                    </div>
                </div>
                <div className='footer-btn'>
                    <FooterProductos color={colorThemeSelected.main} width={13} height={13} active={false}/>
                </div>
                <div className='footer-btn'>
                    <FooterBonos color={colorThemeSelected.main} width={13} height={13} active={false}/>
                </div>
            </div>
        </div>
  )
}
