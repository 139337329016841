import React from 'react';
import { useSelector } from 'react-redux';

export const BonoImporte = () => {
    const { colores } = useSelector(state => state.ui);


  return (
    <div className='bono-importe'>
        <table>
            <thead>
                <tr>
                    <th>Fecha de compra</th>
                    <th>Monedero</th>
                    <th>Unidades</th>
                    <th>Precio</th>
                    <th>Duración</th>
                    <th>Renovación automática</th>
                    <th>Estado</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr style={{ backgroundColor: colores.color6 }}>
                    <td className='fecha'>
                        {/* <p><b>{ moment( comentario?.fecha ).format('D/M/YYYY') }</b> { moment( comentario?.fecha ).format('H:mm') }h</p> */}
                        <p><b>{ `24/5/2022` }</b> { `10:33` }h</p>
                    </td>
                    <td className='monedero'>Bono 30</td>
                    <td className='unidades'>1</td>
                    <td className='precio'>30€</td>
                    <td className='duracion'>-</td>
                    <td className='renovacion'>Activa</td>
                    <td className='estado'>Activo</td>
                    <td className='color green'></td>
                </tr>
                <tr style={{ backgroundColor: colores.color6 }}>
                    <td className='fecha'>
                        {/* <p><b>{ moment( comentario?.fecha ).format('D/M/YYYY') }</b> { moment( comentario?.fecha ).format('H:mm') }h</p> */}
                        <p><b>{ `24/5/2022` }</b> { `10:33` }h</p>
                    </td>
                    <td className='monedero'>Bono regalo</td>
                    <td className='unidades'>1</td>
                    <td className='precio'>50€</td>
                    <td className='duracion'>Anual</td>
                    <td className='renovacion'>Inactiva</td>
                    <td className='estado'>Comprado</td>
                    <td className='color green'></td>
                </tr>
            </tbody>
        </table>
    </div>
  )
}
