export const procesaHorarios = ( horarios, profesional ) => {
    let horariosProf = [];

    console.log( horariosProf );
};

export const getDia = ( dia ) => {
    switch (dia) {
        case '1':
            return 'Lunes';
        case '2':
            return 'Martes';
        case '3':
            return 'Miércoles';
        case '4':
            return 'Jueves';
        case '5':
            return 'Viernes';
        case '6':
            return 'Sábado';
        case '7':
            return 'Domingo';
        default:
            break;
    }
}