import React, { useEffect, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { servicioStartDelete, startNewServicio, startSaveServicio, startUploading } from '../../../../actions/servicios';
import { ArrowBackIcon, CategoriesIcon, ComplementaryServicesIcon, DurationIcon, PrepayIcon, SucursalesFilledIcon, TrashIcon, VariantesIcon } from '../../../../assets/svg/iconos';
import { SelectDuracion } from './SelectDuracion';
import { SelectSucursal } from './SelectSucursal';
import { newServicio } from '../../../../helpers/helpers';
import { ModalEliminarServicio } from '../modales/ModalEliminarServicio';
import { openModalBottom } from '../../../../actions/ui';

import imagePlaceholder from '../../../../assets/img/service-image-placeholder.jpg';
import { SelectVariante } from './SelectVariante';
import { SelectComplementario } from './SelectComplementario';
import { SelectCategoria } from './SelectCategoria';

export const Servicio = ({history}) => {

    const dispatch = useDispatch();
    const { colores, miniLateral } = useSelector( state => state.ui );
    const { empresa } = useSelector(state => state.auth);

    const [currentServicio, setCurrentServicio] = useState( history.location.state.servicio || null );
    const [currentScreen] = useState( (history.location.state.servicio) ? 'edit' : 'new' );

    const [objServicio, setObjServicio] = useState({});
    const [iniciado, setIniciado] = useState(false);
    const [cambiado, setCambiado] = useState(false);

    const [editMode, setEditMode] = useState(true);

    const [imageUrl, setImageUrl] = useState('');
    const [file, setFile] = useState(null);
    const [serviceImage, setServiceImage] = useState(null);
    
    const [idiomaActive, ] = useState('ES');
    const [objErrores, setObjErrores] = useState({});

    const [modalEliminarServicioOpened, setModalEliminarServicioOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar servicio?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [precio, setPrecio] = useState(0);
    const [precioReal, setPrecioReal] = useState(0);

    useEffect(() => {
      if( currentServicio ){
        // console.log( currentServicio );
        setObjServicio( currentServicio );
        setPrecioInicial( currentServicio.precio );
        setIniciado( true );
        if( currentServicio._id && currentServicio.img.length > 0 ){
            getImagenPrincipal( `${currentServicio.img[0]}` );
        }
      } else {
        setEditMode( true );
        setCambiado(true);
        const nuevo = newServicio( empresa );
        setCurrentServicio( nuevo );
        setObjServicio( nuevo );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentServicio]);

    // useEffect(() => {
    //   console.log('serviceImage', serviceImage);
    //   console.log('imageUrl', imageUrl);
    // }, [serviceImage, imageUrl]);
    

    useEffect(() => {
        // console.log( 'objServicio', objServicio );
        if( currentServicio ){
            const servicioOriginal = { ...currentServicio };
            if( iniciado ){
                let igual = true;
                for( const propiedad in servicioOriginal ){
                    //console.log( `${nEmpresa[propiedad]} !== ${objEmpresa[propiedad]}`)
                    if( servicioOriginal[propiedad] !== objServicio[propiedad] ){
                        igual = false;
                        // console.log(`Cambio en ${propiedad}`);
                    }
                }
                if( precioReal !== currentServicio.precio ){
                    igual = false;
                }
                setCambiado( !igual );
            }
        }
        if( serviceImage ){
            setCambiado( true );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iniciado, currentServicio, objServicio, serviceImage, precioReal]);

    const backClick = () => {
        history.push({
            pathname: `/punto-venta/servicios`
        });
    }

    const setPrecioInicial = ( valor ) => {
        setPrecioReal( valor );
        const newValue = valor / 100;
        setPrecio(newValue);
    };

    const handlePrecioChange = (event) => {
        const newValue = event.target.value;
        setPrecioReal( Number(event.target.value) * 100 );
        if( newValue !== ''){
            setPrecio( Number(event.target.value) / 100 );
        } else {
            setPrecio(0);
        }
        setPrecio(newValue);
    };

    const guardar = () => {
        // console.log( 'currentScreen', currentScreen );
        let correcto = true;
        const newObjErrores = {};
        if( objServicio.nombre.trim() === '' ){ 
            correcto = false;
            newObjErrores.nombre = 'Debes escribir un nombre';
        }

        if( objServicio.descripcion.trim() === '' ){ 
            correcto = false;
            newObjErrores.telefono = 'Debes escribir una descripción';
        }
        
        if( objServicio.sucursales.length <= 0 ){ 
            correcto = false;
            newObjErrores.sucursales = 'Debes seleccionar alguna sucursal';
        }
        
        //console.log( objServicio );
        //console.log('Correcto:', correcto );
        //console.log('Errores:', newObjErrores );
        setObjErrores( newObjErrores );
        let newObjeto = { ...objServicio, precio: precioReal };
        if( correcto ){
            //console.log('correcto');
            if( serviceImage ){
                let previa = '';
                if( currentServicio.img.length > 0) {
                    previa = currentServicio.img[0];
                }
                const imgName = `${objServicio._id}-${new Date().getTime()}`;
                const archivo = file;
                const nombreCortado = archivo.name.split('.');
                const extension = nombreCortado[ nombreCortado.length -1 ];
                const nombreCompleto = `${imgName}.${extension}`;

                newObjeto = { ...newObjeto, img: [nombreCompleto]};

                dispatch( startUploading( file, newObjeto, nombreCompleto, previa ) );
            }
            
            if( currentScreen === 'new' ){
                dispatch( startNewServicio( newObjeto ) );
                history.push({ pathname: `/punto-venta/servicios` });
            } else {
                console.log( newObjeto );
                dispatch( startSaveServicio( newObjeto ) );
                setCambiado( false );
                /* setObjServicio( newObjeto );
                setCurrentServicio( newObjeto ); */
            }            
        } else {
            showModalErrores( newObjErrores );
        }
    }

    const showModalErrores = ( objErrores ) => {
        let arrayErrores = [];
        for (const key in objErrores) {
            arrayErrores = [ ...arrayErrores, objErrores[key] ];
        }
        //console.log( 'arrayErrores', arrayErrores );
        dispatch( openModalBottom({text: arrayErrores, timeOut: 5}) );
    }

    const cancelar = () => {
        if( currentScreen !== 'new' ){
            setPrecioReal( currentServicio.precio );
            setPrecio( currentServicio.precio / 100 );
            setObjServicio( currentServicio );
            setServiceImage(null);
            setFile(null);
        } else {
            history.goBack();
        }
    }
    
    const onFormChange = (e) => {
        console.log( e.target.value );
        let correcto = true;
        if( e.target.name === 'precio' ){
            const validNumber = new RegExp(/^\d*\.?\d*$/);
            if( !validNumber.test( e.target.value ) ){
                correcto = false;
            }
        }
        if( correcto ){
            dataChange( e.target.name, e.target.value );
        }
    }

    const dataChange = (name, value) => {
        if( editMode ){
            setObjServicio({
                ...objServicio,
                [name]: value
            });
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }
    
    const getImagenPrincipal = async( nombre ) => {
        setImageUrl( { url: nombre} );
    }

    const onDrop = useCallback( (acceptedFiles) => {
        // console.log( 'onDrop' );
        // console.log( 'objServicio', objServicio );
        // console.log( acceptedFiles );

        // dispatch( startUploading( acceptedFiles[0], objServicio._id, objServicio.empresa ));
        const file = acceptedFiles[0];
        setFile(file);
        setServiceImage(URL.createObjectURL(file));
        
        //dispatch( startUploading( file, objServicio ) );
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objServicio]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png",
        noKeyboard: true,
        onDrop
    });

    const deleteServicioModal = () => {
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar a ${objServicio.nombre}?`]
        })
        setModalEliminarServicioOpened( true );
    };

    const deleteServicioResponse = ( value ) => {
        if( value === 1 ){
          //console.log( 'value = 1' );
          let selectedId = objServicio._id;

          //console.log( 'Borrar id:', selectedId );
          dispatch( servicioStartDelete( selectedId, empresa ) );
          history.push({ pathname: `/punto-venta/servicios` })
        }
    }

    return (
        <>
        <div 
            className='servicio'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className='servicio-wrapper'>
                <div className='single-servicio'>
                    <div className={`header ${!miniLateral && 'compress'}`}>
                        <div 
                            className='nav-back'
                            onClick={ backClick }
                        >
                            <ArrowBackIcon color={ colores.colorSecundario } />
                            <p style={{ color: colores.colorSecundario }}>Servicios</p>
                        </div>
                        <div className='botonera'>
                            {/* {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-editar'
                                    style={{ backgroundColor: editMode ? colores.colorPrincipal : colores.color6 }}
                                    onClick={ () => setEditMode( !editMode ) }
                                >
                                    <EditarIcon color={ editMode ? colores.color6 : colores.colorPrincipal } />
                                </button>
                            } */}
                            {
                                !cambiado &&
                                <button
                                    className='btn-mini btn-eliminar'
                                    style={{ backgroundColor: colores.color6 }}
                                    onClick={ deleteServicioModal }
                                >
                                    <TrashIcon color={ colores.colorPrincipal } />
                                </button>
                            }
                            {
                            cambiado &&
                                <button 
                                    className='btn btn-crear'
                                    style={{ backgroundColor: colores.colorPrincipal }}
                                    onClick={ guardar }
                                >
                                    {`${currentScreen === 'new' ? 'Crear' : 'Guardar'}`}
                                </button>
                            }
                            {
                            cambiado &&
                                <button 
                                    className='btn btn-cancelar'
                                    style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
                                    onClick={ cancelar }
                                >
                                    Cancelar
                                </button>
                            }
                        </div>
                        
                    </div>
                    <div className='name'>
                        <input
                            style={{ 
                                backgroundColor: editMode ? '#ffffff' : 'transparent',
                                border: `${objErrores.nombre ? '2px solid ' + colores.color5 : 'none'}`
                            }}
                            autoComplete='off'
                            type="text"
                            placeholder='Nuevo servicio'
                            name="nombre"
                            disabled={ !editMode }
                            value={ objServicio.nombre || '' }
                            onChange={ (e) => onFormChange( e )}
                        />
                    </div>
                    <div className='servicio-main'>
                        <div className='servicio-left'>
                            <div
                                {...getRootProps({className: 'dropzone image-container'})}
                                //style={{ backgroundImage: `url(${imageUrl.url})` }}
                                style={{ backgroundImage: `url(${serviceImage ? serviceImage : ( imageUrl.url ? imageUrl.url + '?' + uuidv4() : imagePlaceholder) })`}}
                            >
                                <input {...getInputProps()} />
                            </div>
                        </div>
                        <div className='servicio-right'>
                            <div className='servicio-data'>
                                <div className='servicio-texts'>
                                    <div className='servicio-text'>
                                        <h3 style={{ color: colores.colorSecundario }}>Descripción</h3>
                                        <textarea
                                            style={{ 
                                                backgroundColor: editMode ? '#f7f7f7' : 'transparent',
                                                border: `${objErrores.nombre ? '2px solid ' + colores.color5 : 'none'}`
                                            }}
                                            name="descripcion"
                                            placeholder='Añadir descripción'
                                            disabled={ !editMode }
                                            value={ objServicio.descripcion }
                                            onChange={ (e) => onFormChange( e )}
                                        ></textarea>
                                        <div className='idiomas'>
                                            <div 
                                                className='btn-idioma'
                                                style={{ backgroundColor: idiomaActive === 'ES' ? colores.colorPrincipal : colores.colorSecundario}}
                                            >ES</div>
                                            <div 
                                                className='btn-idioma'
                                                style={{ backgroundColor: idiomaActive === 'CT' ? colores.colorPrincipal : colores.colorSecundario}}
                                            >CT</div>
                                        </div>
                                    </div>
                                    <div className='servicio-text condiciones'>
                                        <h3 style={{ color: colores.colorSecundario }}>Condiciones</h3>
                                        <textarea
                                            style={{ 
                                                backgroundColor: editMode ? '#f7f7f7' : 'transparent',
                                                border: `${objErrores.nombre ? '2px solid ' + colores.color5 : 'none'}`
                                            }}
                                            name="condiciones"
                                            placeholder='Añadir condiciones'
                                            disabled={ !editMode }
                                            value={ objServicio.condiciones }
                                            onChange={ (e) => onFormChange( e )}
                                        ></textarea>
                                        <div className='idiomas'>
                                            <div 
                                                className='btn-idioma'
                                                style={{ backgroundColor: idiomaActive === 'ES' ? colores.colorPrincipal : colores.colorSecundario}}
                                            >ES</div>
                                            <div 
                                                className='btn-idioma'
                                                style={{ backgroundColor: idiomaActive === 'CT' ? colores.colorPrincipal : colores.colorSecundario}}
                                            >CT</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='servicio-price'>
                                    <div 
                                        className='price-item'
                                        style={{ color: colores.colorPrincipal, backgroundColor: colores.colorSecundario }}
                                    >
                                        {/* <AutosizeInput
                                            inputStyle={{ 
                                                color: colores.colorPrincipal,
                                                fontSize: precio.length > 2 ? '25px' : '30px'
                                            }}
                                            autoComplete='off'
                                            type="number"
                                            placeholder='--'
                                            name="precio"
                                            disabled={ !editMode }
                                            value={ precio }
                                            onChange={ handlePrecioChange }
                                        /> */}
                                        <input 
                                            style={{ 
                                                color: colores.colorPrincipal,
                                                fontSize: precio.length > 2 ? '25px' : '30px'
                                            }}
                                            autoComplete='off'
                                            type="number"
                                            placeholder='--'
                                            name="precio"
                                            disabled={ !editMode }
                                            value={ precio }
                                            onChange={ handlePrecioChange }
                                        />
                                        <span
                                            style={{
                                                fontSize: precio.length > 2 ? '25px' : '30px'
                                            }}
                                        >€</span>
                                    </div>
                                </div>
                            </div>
                            <div className='servicio-selectors'>
                                <div 
                                    className='servicio-selector'
                                    style={{ 
                                        backgroundColor: colores.colorSecundario,
                                        border: `${objErrores.sucursales ? '2px solid ' + colores.color5 : 'none'}`
                                    }}
                                >
                                    <div className='left'>
                                        <div className='icon-bg'>
                                            <SucursalesFilledIcon color={ colores.colorPrincipal }/>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <div className='title' style={{ color: colores.colorPrincipal }}>Sucursales</div>
                                        <SelectSucursal
                                            editMode={ editMode }
                                            objServicio={ objServicio }
                                            dataChange={ dataChange }
                                            big={ true }
                                        />
                                    </div>
                                </div>
                                <div
                                    className='servicio-selector pauses'
                                    style={{ backgroundColor: colores.colorSecundario }}
                                >
                                    <div className='left'>
                                        <div className='icon-bg'>
                                            <DurationIcon color={ colores.colorPrincipal }/>
                                        </div>
                                    </div>
                                    <div className='right select-duracion'>
                                        <SelectDuracion 
                                            editMode={ editMode }
                                            objServicio={ objServicio }
                                            setObjServicio={ setObjServicio }
                                            dataChange={ dataChange }
                                        />
                                        
                                    </div>
                                </div>
                                <div
                                    className='servicio-selector'
                                    style={{ backgroundColor: colores.colorSecundario }}
                                >
                                    <div className='left'>
                                        <div className='icon-bg'>
                                            <ComplementaryServicesIcon color={ colores.colorPrincipal }/>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <div className='title' style={{ color: colores.colorPrincipal }}>Servicios complementarios</div>
                                        <SelectComplementario
                                            editMode={ editMode }
                                            objServicio={ objServicio }
                                            dataChange={ dataChange }
                                            big={ true }
                                        />
                                    </div>
                                </div>
                                <div 
                                    className='servicio-selector'
                                    style={{ backgroundColor: colores.colorSecundario }}
                                >
                                    <div className='left'>
                                        <div className='icon-bg'>
                                            <VariantesIcon color={ colores.colorPrincipal }/>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <div className='title' style={{ color: colores.colorPrincipal }}>Variantes</div>
                                        <SelectVariante
                                            editMode={ editMode }
                                            objServicio={ objServicio }
                                            dataChange={ dataChange }
                                            big={ true }
                                            top={ true }
                                            left={ true }
                                        />
                                    </div>
                                </div>
                                <div
                                    className='servicio-selector'
                                    style={{ backgroundColor: colores.colorSecundario }}
                                >
                                    <div className='left'>
                                        <div className='icon-bg'>
                                            <CategoriesIcon color={ colores.colorPrincipal }/>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <div className='title' style={{ color: colores.colorPrincipal }}>Categorías</div>
                                        <SelectCategoria
                                            editMode={ editMode }
                                            objServicio={ objServicio }
                                            dataChange={ dataChange }
                                            big={ true }
                                            top={ true }
                                            left={ true }
                                        />
                                    </div>
                                </div>
                                <div
                                    className='servicio-selector prepago'
                                    //style={{ backgroundColor: `#F7F7F7` }}
                                    style={{ backgroundColor: `${objServicio.prepago ? colores.colorSecundario : '#F7F7F7'}`}}
                                >
                                    <div className='left'>
                                        <div className='icon-bg'>
                                            <PrepayIcon color={ `${objServicio.prepago ? colores.colorPrincipal : '#7B7B7B'}` }/>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <div 
                                            className={ `ylm-toggle ${ objServicio.prepago && 'active'}`}
                                            onClick={ () => editMode && dataChange( 'prepago', !objServicio.prepago )}
                                        >
                                            <p style={{ color: objServicio.prepago ? colores.colorPrincipal: '#707070' }}>{ `Prepago` }</p>
                                            <div 
                                                className={ `toggle-bg`}
                                                style={{ backgroundColor: objServicio.prepago ? '#FFFFFF' : '#C9C5C5' }}
                                            >
                                                <span
                                                    className={`toggle-item`}
                                                    style={{ backgroundColor: objServicio.prepago ? colores.colorPrincipal : '#7B7B7B' }}
                                                ></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalEliminarServicio
            data = { dataModalEliminar }
            setModalResponse = { deleteServicioResponse }
            modalOpened = { modalEliminarServicioOpened }
            setModalOpened = { setModalEliminarServicioOpened }
        />
        </>
    )
}
