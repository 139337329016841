import Swal from 'sweetalert2';

import { types } from "../types/types";
import { fetchConToken, archivoConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewProfesional = (values, swal = true) => {
    return async (dispatch, getState) => {
        try {
            const resp = await fetchConToken('profesionales', values, 'POST');
            const body = await resp.json();
            if (body.ok) {
                const datos = {
                    text: [`${values.nombre} ha sido añadido/a a los profesionales.`],
                    timeOut: 3
                }
                dispatch(openModalBottom(datos));
                dispatch(addNewProfesional(body.profesional._id, body.profesional));
                dispatch(activeProfesional(body.profesional._id, body.profesional));

                return body.profesional._id;
            } else {
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log(error);
            const datos = {
                title: 'Error',
                text: ['Ha habido un error'],
                buttonText: 'Cerrar'
            }
            dispatch(openModal(datos));
        }
    }
};

export const activeProfesional = (_id, profesional) => ({
    type: types.profesionalesActive,
    payload: {
        _id,
        ...profesional
    }
});

export const unactiveProfesional = () => ({
    type: types.profesionalesUnactive
});

export const addNewProfesional = (_id, profesional) => ({
    type: types.profesionalesAddNew,
    payload: {
        _id,
        ...profesional
    }
})

export const startLoadingProfesionales = (empresa) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('profesionales/empresa', { empresa }, 'POST');
            const body = await resp.json();
            dispatch(setProfesionales(body.profesionales));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setProfesionales = (profesionales) => ({
    type: types.profesionalesLoad,
    payload: profesionales
})

export const startSaveProfesional = (profesional, swal = true) => {
    return async (dispatch, getState) => {

        try {
            const resp = await fetchConToken(`profesionales/${profesional._id}`, profesional, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(refreshProfesional(profesional._id, body.profesional));
                const datos = {
                    text: [`Cambios guardados`],
                    timeOut: 3
                }
                dispatch(openModalBottom(datos));
            } else {
                console.log(body);
                const datos = {
                    title: 'Error',
                    text: [body.msg],
                    buttonText: 'Cerrar'
                }
                dispatch(openModal(datos));
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshProfesional = (id, profesional) => ({
    type: types.profesionalesUpdated,
    payload: {
        ...profesional
    }
});

export const startUploading = (archivo, profesional, nombre, previa) => {

    return async (dispatch, getState) => {
        try {
            const formData = new FormData();
            formData.append('archivo', archivo);
            formData.append('empresa', profesional.empresa);
            formData.append('nombre', nombre);
            formData.append('previa', previa);
            formData.append('file_name', 'archivo');

            await archivoConToken(`profesionales/upload/${profesional._id}`, formData, 'POST');
        } catch (error) {
            throw new Error();
        }

        Swal.close();
    }
};

export const profesionalStartDelete = (id, empresa) => {
    return async (dispatch, getState) => {
        console.log('deleted');

        try {
            const resp = await fetchConToken(`profesionales/${id}`, { empresa }, 'DELETE');
            const body = await resp.json();

            if (body.ok) {
                dispatch(profesionalDeleted(id));
            } else {
                const datos = {
                    title: 'Error',
                    text: [body.msg],
                    buttonText: 'Cerrar'
                }
                dispatch(openModal(datos));
            }


        } catch (error) {
            console.log(error);
        }
    }
}

const profesionalDeleted = (id) => ({
    type: types.profesionalesDelete,
    payload: id
});

export const profesionalLogout = () => ({
    type: types.profesionalesLogoutCleaning
});
