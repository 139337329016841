import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import fondo_img from '../../../../assets/img/bg_personalizar.jpg';
import { DescriptionImageIcon, LinkImageIcon, TitleImageIcon, UpdateImageIcon } from '../../../../assets/svg/iconos';
import { RemoveImage } from './RemoveImage';

export const Identidad = ({ objEmpresa, setObjEmpresa, setCambiado }) => {
    const { colores } = useSelector(state => state.ui);
    const [bannerImages, setBannerImages] = useState(Array.from({ length: 8 }).map(() => null));

    useEffect(() => {
        for (let i = 0; i < 8; i++) {
            const file = objEmpresa["img"][i];

            bannerImages[i] = file
        }

        setBannerImages([...bannerImages]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log('objEmpresa', objEmpresa);
    }, [objEmpresa]);

    const changeValue = (e) => {
        setObjEmpresa({
            ...objEmpresa,
            [e.target.name]: e.target.value
        })
    };

    const changeFile = (e) => {
        setObjEmpresa({
            ...objEmpresa,
            [e.target.name]: e.target.files[0]
        })
    };

    const addFile = (e) => {
		
		let arrImages = [];
		arrImages.push(objEmpresa.img[0])
		for (let x=1; x < objEmpresa.img.length; x++){
			if (objEmpresa.img[x] !== null){
				arrImages.push(objEmpresa.img[x])
			}
		}		
		arrImages.push(e.target.files[0]);

		const updatedObjEmpresa = {
            ...objEmpresa,
            img: arrImages
        }

        for (let i = 0; i < 8; i++) {
            const file = updatedObjEmpresa[e.target.name][i]
            if (!file) continue;

            bannerImages[i] = file

            setBannerImages([...bannerImages]);
        }

        setObjEmpresa(updatedObjEmpresa)
    };

    const isFileUploaded = (file) => {
        if (!file) return false;

        return !(file instanceof Blob);
    }


    return (
        <div className='panel-container'>
            <div className='identidad-left'>
                <div className='galeria'>
                    <input type='file' name='img' id='upload-img-0' hidden onChange={(e) => {
						setCambiado(false);
                        setObjEmpresa({
                            ...objEmpresa,
                            img: [e.target.files[0], ...objEmpresa.img.slice(1)]
                        })
                    }} accept='image/jpg, image/jpeg, image/png, image/gif' />
                    <label for="upload-img-0" style={{
                        cursor: 'pointer'
                    }}>
                        <div className='main-img' style={{
                            backgroundImage:
                                isFileUploaded(objEmpresa.img[0]) ? `url('${objEmpresa.img[0]}')` : objEmpresa.img[0] ? `url('${URL.createObjectURL(objEmpresa.img[0])}'` : `url('${fondo_img}')`
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: "flex-end",
                                height: "20px",
                            }}>
                                {objEmpresa.img[0] && <span
                                    onClick={(e => {
                                        e.preventDefault();

                                        setObjEmpresa({
                                            ...objEmpresa,
                                            img: [null, ...objEmpresa.img.slice(1)]
                                        })
                                    })}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '20px',
                                        height: '20px',
                                        zIndex: 99,
                                        color: "white",
                                        backgroundColor: colores.colorPrincipal,
                                    }}
                                >X</span>}
                            </div>
                            {!objEmpresa.img[0] && <p>imagen de 330 x 370 pixeles</p>}
                        </div>
                    </label>
                    <div className='img-grid'>
                        <input type='file' name='img' id='upload-img' hidden onChange={addFile} accept='image/jpg, image/jpeg, image/png, image/gif' />
                        {bannerImages.slice(1)?.map((img, i) => (

                            <div className='img-item'
                                style={{
                                    backgroundImage: isFileUploaded(img) ? `url('${img}')` : img ? `url('${URL.createObjectURL(img)}'` : "",
                                    backgroundSize: 'cover',
                                }}
                            >
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "flex-end",
                                    height: "20px",
                                }}>
                                    {img && <span
                                        onClick={(e => {
                                            e.preventDefault();

                                            setObjEmpresa({
                                                ...objEmpresa,
                                                img: [objEmpresa.img[0], ...objEmpresa.img.slice(1).filter((_, index) => index !== i)]
                                            })

                                            setBannerImages(bannerImages.map((_, index) =>
                                                index === i + 1 ? null : bannerImages[index]
                                            ));
                                        })}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '20px',
                                            height: '20px',
                                            zIndex: 99,
                                            color: "white",
                                            backgroundColor: colores.colorPrincipal,
                                        }}
                                    >X</span>}
                                </div>
                            </div>
                        ))}
                        <label for="upload-img">
                            <div className='img-item add-button'>
                                <span>+</span>
                            </div>
                        </label>
                    </div>
                </div>
            </div >
            <div className='identidad-right'>
                <div className='identidad-input icon'>
                    <div className='icon'><UpdateImageIcon /></div>
                    {
                        isFileUploaded(objEmpresa.icon) ?
                            <RemoveImage
                                objEmpresa={objEmpresa}
                                setObjEmpresa={setObjEmpresa}
                                keyToRemove='icon'
                                colorPrincipal={colores.colorPrincipal}
                            >
                                <img src={objEmpresa.icon} alt='logo'
                                    width="35px"
                                    height="35px"
                                />
                            </RemoveImage>
                            :
                            <input type='text' disabled placeholder='Subir icono' value={objEmpresa.icon?.name} />
                    }
                    <input type='file' name='icon' id='upload-icon' hidden onChange={changeFile} accept='image/jpg, image/jpeg, image/png, image/gif' />
                    <label for="upload-icon"
                        className='btn-upload-icon'
                        style={{ backgroundColor: colores.colorPrincipal }}
                    >
                        <span>Subir</span>
                    </label>
                </div>
                <div className='identidad-input link'>
                    <div className='icon'><LinkImageIcon /></div>
                    <input type='text' name="bannerLink" value={objEmpresa.bannerLink}
                        onChange={changeValue}
                        placeholder='link imagen' />
                </div>
                <div className='identidad-input title'>
                    <div className='icon'><TitleImageIcon /></div>
                    <input
                        type='text'
                        name='titulo'
                        placeholder='Título de 30 carácteres'
                        value={objEmpresa.titulo}
                        onChange={changeValue}
                    />
                </div>
                <div className='identidad-input description'>
                    <div className='icon'><DescriptionImageIcon /></div>
                    <textarea
                        name='descripcion'
                        placeholder='Descripción de 100 carácteres'
                        value={objEmpresa.descripcion}
                        onChange={changeValue}
                    >
                    </textarea>
                </div>
                <button
                    className='identidad-btn'
                    style={{
                        borderColor: colores.colorPrincipal,
                        color: colores.colorPrincipal
                    }}
                >Eliminar</button>
            </div>
        </div >
    )
}
