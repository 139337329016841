import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startSaveEmpresa } from '../../actions/empresas';
import { openModalBottom, setMenuLateral } from '../../actions/ui';
import { startLoadingUsuario, usuarioLogout } from '../../actions/usuarios';
import { ContactIcon, DownloadIcon, MessagesIcon, SectorIcon, UploadImageIcon } from '../../assets/svg/iconos';
import { YlmModalTwoButtons } from '../../components/main/YlmModalTwoButtons';
import { formatCurrency } from '../../helpers/helpers';
import { ModalCambiarPassword } from './components/ModalCambiarPassword';
import { ModalEmailNotificaciones } from './components/ModalEmailNotificaciones';
import { ModalPlanes } from './components/ModalPlanes';

export const Admin = () => {
    const dispatch = useDispatch();
    const { colores, miniLateral } = useSelector(state => state.ui);
    const { usuarios } = useSelector(state => state.usuarios);
    const { uid, empresa } = useSelector(state => state.auth);
    const { empresas } = useSelector(state => state.empresas);
    const { planes } = useSelector(state => state.planes);

    const [editMode, ] = useState(false);

    const [currentUsuario, setCurrentUsuario] = useState( null );
    const [currentEmpresa, setCurrentEmpresa] = useState( null );
    const [objUsuario, setObjUsuario] = useState({});
    const [iniciado, ] = useState(false);
    const [, setCambiado] = useState(false);

    const [dataModal, setDataModal] = useState({
        title: '¿Cambiar plan?',
        text: ['¿Está seguro de que desea actualizar al plan?'],
        buttonText: 'Estoy seguro',
        button2Text: 'Cancelar'
    });
    const [modalTwoButtonsOpened, setModalTwoButtonsOpened] = useState(false);
    const [modalPlanResponse, setModalPlanResponse] = useState(100);
    const [modalPlanWaiting, setModalPlanWaiting] = useState({});

    /* useEffect(() => {
        return () => {
            dispatch( usuarioLogout() );
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) */
    
    useEffect(() => {
        dispatch( setMenuLateral('admin') );
        if( uid && empresa ){
            //console.log( 'startLoadingUsuario' );
            dispatch( startLoadingUsuario( uid, empresa ) );
        }
        return () => {
            dispatch( usuarioLogout() );
          }
    }, [ dispatch, uid, empresa ]);

    useEffect(() => {
      if( empresas.length > 0 ){
        setCurrentEmpresa( empresas[0]);
      }
    }, [empresas]);   
    
    useEffect(() => {
      if( usuarios.length > 0 ){
        setCurrentUsuario( usuarios[0]);
      }
    }, [usuarios]);

    useEffect(() => {
      if( currentUsuario?._id ){
        setObjUsuario( currentUsuario );
      }
    }, [currentUsuario]);   

    useEffect(() => {
        //console.log( 'objUsuario', objUsuario );
        if( currentUsuario ){
            const servicioOriginal = { ...currentUsuario };
            if( iniciado ){
                let igual = true;
                for( const propiedad in servicioOriginal ){
                    //console.log( `${nEmpresa[propiedad]} !== ${objEmpresa[propiedad]}`)
                    if( servicioOriginal[propiedad] !== objUsuario[propiedad] ){
                    igual = false;
                    //console.log(`Cambio en ${propiedad}`);
                    }
                }
                setCambiado( !igual );
            }
        }
    }, [iniciado, currentUsuario, objUsuario]);    

    useEffect(() => {
        if( modalPlanWaiting._id ){
            if( modalPlanResponse === 1 ){
                const query = {
                    _id: currentEmpresa._id,
                    plan: modalPlanWaiting._id
                }
                dispatch( startSaveEmpresa( query ) );
                setModalPlanesOpened( false );
            }
            setModalPlanWaiting({});
            setModalPlanResponse( 100 );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalPlanResponse]);
    

    const onFormChange = (e) => {
        let correcto = true;
        if( e.target.name === 'precio' ){
            const validNumber = new RegExp(/^\d*\.?\d*$/);
            if( !validNumber.test( e.target.value ) ){
                correcto = false;
            }
        }
        if( correcto ){
            dataChange( e.target.name, e.target.value );
        }
    }

    const dataChange = (name, value) => {
        if( editMode ){
            setObjUsuario({
                ...objUsuario,
                [name]: value
            });
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    const [showDownloadButton, setShowDownloadButton] = useState(false);
    // const [currentPlan, setCurrentPlan] = useState( 'comercio' );
    const [modalCambiarPasswordOpened, setModalCambiarPasswordOpened] = useState(false);
    const [modalEmailNotificacionesOpened, setModalEmailNotificacionesOpened] = useState(false);
    const [modalPlanesOpened, setModalPlanesOpened] = useState(false);

    const [ pagos,  ] = useState([
        { _id: 'pg1', plan: 'Emprendedor', facturado: '22/01/22', cuota: 39, pago: 'Pendiente', factura: '123456.pdf' },
        { _id: 'pg2', plan: 'Emprendedor', facturado: '22/12/21', cuota: 39, pago: 'Pagado', factura: '123456.pdf' },
        { _id: 'pg3', plan: 'Emprendedor', facturado: '22/11/21', cuota: 39, pago: 'Pagado', factura: '123456.pdf' },
        { _id: 'pg4', plan: 'Emprendedor', facturado: '22/10/21', cuota: 39, pago: 'Pagado', factura: '123456.pdf' },
    ]);

    const changePlan = ( newPlan ) => {
        setDataModal({
            title: '¿Cambiar plan?',
            text: [`¿Está seguro de que desea actualizar al plan ${newPlan.nombre}?`],
            buttonText: 'Estoy seguro',
            button2Text: 'Cancelar'
        })
        setModalTwoButtonsOpened( true );
        setModalPlanWaiting( newPlan );
        //setCurrentPlan( newPlan._id );
    }
    
    return (
        <div 
            className='admin-tab'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className='admin-wrapper'>
                <div className={`header ${ !miniLateral ? 'compress' : '' }`}>
                    <h1 style={{color: colores.colorPrincipal}}>Cuenta usuario</h1>
                    <div className='botonera'>
                        <div
                            className={ `slide-button ${showDownloadButton && 'show'}`}
                        >
                            <div className='slide-item'>
                                <div className='slide-data'>
                                    <UploadImageIcon color={ colores.colorPrincipal } />
                                    <p 
                                        style={{
                                            color: colores.colorPrincipal
                                        }}
                                    >Descargar contrato</p>
                                </div>
                            </div>
                        </div>
                        <button 
                            className={ `btn btn-download ${ showDownloadButton && 'active'}`}
                            style={{
                                backgroundColor: colores.colorPrincipal
                            }}
                            onClick={ () => setShowDownloadButton( !showDownloadButton )}
                        >
                            <span className='reverse-icon'>
                                <DownloadIcon color={ colores.color6 }/>
                            </span>
                        </button>
                    </div>
                </div>
                <div className='section datos'>
                    <h3 style={{ color: colores.colorSecundario }}>Datos</h3>
                    <div className='datos-row row-1'>
                        <div className='datos-col col-1'>
                            <div className='data-item nombre'>
                                <label>Nombre y apellidos</label>
                                <input
                                    autoComplete='off'
                                    type="text"
                                    placeholder='Nombre'
                                    name='nombre'
                                    value={ objUsuario?.nombre || '' }
                                    onChange={ (e) => onFormChange( e )}
                                    disabled={ !editMode }
                                />
                            </div>
                            <div className='data-item telefono'>
                                <label>Teléfono</label>
                                <input 
                                    type='text'
                                    name='telefono'
                                    placeholder={`+34 600 000 000`}
                                    defaultValue={ objUsuario?.telefono || '' }
                                    disabled={ !editMode }
                                />
                            </div>
                        </div>
                        <div className='datos-col col-2'>
                            <div className='data-item email'>
                                <label>Email</label>
                                <input 
                                    type='email'
                                    name='email'
                                    placeholder={`email@email.com`}
                                    defaultValue={ objUsuario?.email || '' }
                                    disabled={ true }
                                />
                            </div>
                            <div className='data-item password'>
                                <label>Password</label>
                                <input type='password' defaultValue={`Password`} disabled />
                            </div>
                            <div className='data-item button'>
                                <label></label>
                                <button 
                                    className='datos-btn'
                                    style={{ color: colores.colorPrincipal }}
                                    onClick={ () => setModalCambiarPasswordOpened( true ) }
                                >
                                    Cambiar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='datos-row row-2'>
                        <div className='datos-col col-3'>
                            <div className='data-item icono'>
                                <MessagesIcon />
                            </div>
                            <div className='data-item notificaciones'>
                                <label style={{ color: colores.colorPrincipal }}>Envío notificaciones</label>
                                <input type='email' defaultValue={ currentEmpresa?.emailSMTP || '' } disabled />
                            </div>
                            <button 
                                className='datos-btn'
                                style={{ color: colores.colorPrincipal }}
                                onClick={ () => setModalEmailNotificacionesOpened( true ) }
                            >
                                Cambiar
                            </button>
                        </div>
                        <div className='datos-col col-4'>
                            <div className='data-item icono'>
                                <ContactIcon />
                            </div>
                            <div className='data-item permisos'>
                                <label style={{ color: colores.colorPrincipal }}>Permisos</label>
                                <input type='text' defaultValue={`Ver y editar (Admin)`} disabled />
                            </div>
                        </div>
                        <div className='datos-col col-5'>
                            <div className='data-item icono'>
                                <SectorIcon />
                            </div>
                            <div className='data-item sector'>
                                <label style={{ color: colores.colorPrincipal }}>Sector</label>
                                <input type='text' defaultValue={ currentEmpresa?.sector.nombre } disabled />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section plan'>
                    <h3 style={{ color: colores.colorSecundario }}>Tu plan</h3>
                    <div className='planes-row'>
                        {
                            planes.map( plan => (
                                <div
                                    key={ plan._id }
                                    className={`plan-item ${ (currentEmpresa?.plan._id === plan._id) && 'active' }`}
                                    onClick={ () => setModalPlanesOpened( true )}
                                >
                                    <div className='title-row'>
                                        <div className='title'>
                                            { plan?.nombre }
                                        </div>
                                        {
                                            (currentEmpresa?.plan._id === plan._id) && (
                                                <p><i>Plan actual</i></p>
                                            )
                                        }
                                        {/* {
                                            currentEmpresa?.plan._id !== plan._id && (
                                                <button className='more-btn' style={{ color: colores.colorPrincipal }}>Ver más</button>
                                            )
                                        } */}
                                    </div>
                                    <div 
                                        className='price'
                                    >
                                        { `${formatCurrency( plan.mensual )}€ ` }<span>/ mes</span>
                                    </div>
                                    {
                                        plan._id === '63993962b36591afb71f5340' &&
                                        <div className='recomendado'>Recomendado</div>
                                    }
                                    {/* {
                                        currentEmpresa?.plan._id === plan._id
                                        ?
                                        (
                                            <p><i>Plan actual</i></p>
                                        )
                                        :
                                        (
                                            <button
                                                className='update-btn'
                                                style={{ borderColor: colores.colorPrincipal, color: colores.colorPrincipal }}
                                                onClick={ () => changePlan( plan ) }
                                            >Actualiza</button>
                                        )
                                    } */}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <section className='section pagos'>
                    <h3 style={{ color: colores.colorSecundario }}>Pagos</h3>
                    <div className='pagos-table'>
                        <div className='pagos-row headers'>
                            <div className='plan'>Plan</div>
                            <div className='facturado'>Facturado</div>
                            <div className='cuota'>Cuota</div>
                            <div className='pago'>Pago</div>
                            <div className='factura'>Factura</div>
                        </div>
                        {
                            pagos.map( pago => (
                                <div
                                    key={ pago._id }
                                    className='pagos-row'
                                >
                                    <div className='plan'>{ pago.plan }</div>
                                    <div className='facturado'>{ pago.facturado }</div>
                                    <div className='cuota'>{ pago.cuota }€</div>
                                    <div className={`pago ${ pago.pago === 'Pendiente' && 'pendiente'}`}>{ pago.pago }</div>
                                    <div className='factura'><span className='reverse-icon'><DownloadIcon /></span></div>
                                </div>
                            ))
                        }
                    </div>
                </section>
            </div>
            <ModalCambiarPassword
                modalCambiarPasswordOpened = { modalCambiarPasswordOpened }
                setModalCambiarPasswordOpened = { setModalCambiarPasswordOpened }
            />
            <ModalEmailNotificaciones
                modalEmailNotificacionesOpened = { modalEmailNotificacionesOpened }
                setModalEmailNotificacionesOpened = { setModalEmailNotificacionesOpened }
            />
            <ModalPlanes
                modalPlanesOpened = { modalPlanesOpened }
                setModalPlanesOpened = { setModalPlanesOpened }
                currentEmpresa = { currentEmpresa }
                changePlan = { changePlan }
            />
            <YlmModalTwoButtons
                data = { dataModal }
                setModalResponse = { setModalPlanResponse }
                modalTwoButtonsOpened = { modalTwoButtonsOpened }
                setModalTwoButtonsOpened = { setModalTwoButtonsOpened }
            />
        </div>
    )
}
