import React from 'react';
import { Vacacional } from './descansos/Vacacional';

export const Descansos = ({objProfesional, setObjProfesional, descansosObj, descansosTemp, setDescansosTemp, editMode, deleteVacaciones}) => {

    /* useEffect(() => {
      console.log( 'objProfesional', objProfesional );
    }, [objProfesional]) */
    

    return (
        <div className='descansos'>
            {
                <Vacacional
                    objProfesional={ objProfesional }
                    setObjProfesional={ setObjProfesional }
                    editMode={ editMode }
                    descansosObj={ descansosObj }
                    descansosTemp={ descansosTemp }
                    setDescansosTemp={ setDescansosTemp }
                    deleteVacaciones={ deleteVacaciones }
                />
            }
        </div>
    )
}
