import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setMenuLateral } from '../../actions/ui';
import { AddIcon, BuscarIcon } from '../../assets/svg/iconos';
import { YlmModalTwoButtons } from '../../components/main/YlmModalTwoButtons';
import NotificacionesRowItem from './components/NotificacionesRowItem';

const notificacionesTest = [
  { _id: '1', nombre: 'Nombre 1', mensaje: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit', lista: { difusion: 'todos', segmentacion: 'poblacion', poblacion: ['Nombre ciudad'] }, segmentacion: false, destacar: false, enviar: false },
  { _id: '2', nombre: 'Nombre 2', mensaje: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit', lista: { difusion: 'geografica', segmentacion: 'poblacion', poblacion: ['Nombre ciudad']}, segmentacion: true, destacar: false, enviar: false },
  { _id: '3', nombre: 'Nombre 3', mensaje: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit', lista: { difusion: 'geografica', segmentacion: 'poblacion', poblacion: ['Nombre ciudad']}, segmentacion: true, destacar: false, enviar: false },
  { _id: '4', nombre: 'Nombre 4', mensaje: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit', lista: { difusion: 'geografica', segmentacion: 'poblacion', poblacion: ['Nombre ciudad']}, segmentacion: true, destacar: false, enviar: false },
  { _id: '5', nombre: 'Nombre 5', mensaje: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit', lista: { difusion: 'geografica', segmentacion: 'poblacion', poblacion: ['Nombre ciudad']}, segmentacion: true, destacar: false, enviar: false },
  { _id: '6', nombre: 'Nombre 6', mensaje: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit', lista: { difusion: 'geografica', segmentacion: 'poblacion', poblacion: ['Nombre ciudad']}, segmentacion: true, destacar: false, enviar: false },
];

export const Notificaciones = ({history}) => {
    const dispatch = useDispatch();

    const { colores } = useSelector(state => state.ui);

    const [searchActive, setSearchActive] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [notificacionesList, ] = useState( notificacionesTest );
    const [enConstruccion, ] = useState(true);

    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar notificación?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });
    const [modalTwoButtonsOpened, setModalTwoButtonsOpened] = useState(false);

    const [selectedId, setSelectedId] = useState('');

    useEffect(() => {
        dispatch( setMenuLateral('notificaciones') );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clickSearch = () => {
        setSearchActive( !searchActive );
    };


    const handleSearchChange = (e) => {
        setSearchTxt( e.target.value );
    }

    const clickAdd = () => {
        let pathname = '/notificacion/new';
        if( pathname !== ''){
            history.push({
                pathname,
                state: {
                    activeTab: 'ultima'
                }
            });
        }
    };
        
    const editNotificacion = ( notificacion ) => {
        history.push({
            pathname: `/notificacion/${notificacion._id}`,
            state: {
                notificacion
            }
        });
    };

    const deleteNotificacionModal = ( profId, profName ) => {
        setSelectedId( profId );
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar a ${profName}?`]
        })
        setModalTwoButtonsOpened( true );
    };

    const deleteNotificacionResponse = ( value ) => {
        if( value === 1 && selectedId !== '' ){
            console.log( 'Borrar _id:', selectedId );
            // dispatch( profesionalStartDelete( selectedId, empresa ) );
        }
    }

    return (
        <>
        <div 
            className='notificaciones'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className="preheader">
                <div className="title">
                    <h1 style={{color: colores.colorPrincipal}}>Notificaciones</h1>
                </div>
                <div className="botonera">
                    <div 
                        className={`btn-search ${searchActive && 'active'}`}
                    >
                        <div className='search-input'>
                            <input 
                                type={'text'}
                                placeholder={'Buscar por nombre'}
                                value={ searchTxt }
                                onChange={ handleSearchChange }
                            />
                            {
                                searchTxt.trim().length > 2 &&
                                <span className='cantidad'>
                                    ({ notificacionesList.length })
                                </span>
                            }
                        </div>
                        <div
                            className='icono'
                            onClick={ clickSearch }
                        >
                            <BuscarIcon
                                color={ colores.colorPrincipal }
                                className="icon-inverse"
                            />
                        </div>
                    </div>
                    <div 
                        className="btn-add"
                        onClick={ clickAdd }
                    >
                        <AddIcon
                            color={ colores.colorPrincipal } 
                        />
                    </div>
                </div>
            </div>
            {
                !enConstruccion &&
                <div className="table-notificaciones">
                    <div className='table-headers'>
                    <div className='col-nombre'>Notificación</div>
                    <div className='col-mensaje'>Mensaje</div>
                    <div className='col-list'>Lista de difusión</div>
                    <div className='col-btn'></div>
                    </div>
                    <div className='table-body'>
                        {
                        notificacionesList.map( notificacion => (
                            <NotificacionesRowItem
                            key={ notificacion._id }
                            notificacion={ notificacion }
                            editNotificacion={ editNotificacion }
                            deleteNotificacion={ deleteNotificacionModal}
                            />
                        ))
                        }     
                    </div> 
                </div>
            }
        </div>
        <YlmModalTwoButtons
            data = { dataModalEliminar }
            setModalResponse = { deleteNotificacionResponse }
            modalTwoButtonsOpened = { modalTwoButtonsOpened }
            setModalTwoButtonsOpened = { setModalTwoButtonsOpened }
        />
        </>
    )
}
