import React, { useCallback, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';

import useClickOutside from '../../../../hooks/useClickOutside';
import { HoraInput } from '../horario/HoraInput';
import { YlmCheckbox } from '../../../../components/forms/YlmCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { openModalBottom } from '../../../../actions/ui';

const timezone = process.env.REACT_APP_TIMEZONE;

export const VacacionalCalendar = ({editMode = true, value, onChange}) => {

    const dispatch = useDispatch();
    const { colores } = useSelector(state => state.ui);

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);
    
    const [hideHours, setHideHours] = useState( moment.tz( value[0], timezone ).format('HH:mm') === '00:00' && moment.tz( value[1], timezone ).format('HH:mm') === '23:59');

    const changing = ( val ) => {
        console.log( val );
        onChange( val );
    }

    const changeHoraInicio = ( val ) =>  {
        const arr = val.hora.split(':');
        let newValue = [ ...value ];
        let horaInicio = moment.tz( newValue[0], timezone );
        horaInicio.hour( arr[0] );
        horaInicio.minute( arr[1] );
        newValue[0] = horaInicio.toDate();
        onChange( newValue );
    };
    
    const changeHoraFin = ( val ) =>  {
        const arr = val.hora.split(':');
        let newValue = [ ...value ];
        let horaFin = moment.tz( newValue[1], timezone );
        horaFin.hour( arr[0] );
        horaFin.minute( arr[1] );
        newValue[1] = horaFin.toDate();
        onChange( newValue );
    };

    const checkBoxChange = () => {
        if( editMode ){
            setHideHours( !hideHours );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    const clickCalendar = () => {
        if( editMode ){
            toggle(true);
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }
  
    return (
        <>
        <div className={`calendar-row ${ hideHours && 'hide-hours'}`}>
            <div className='date-row del'>
                <span style={{ color: colores.colorSecundario }}>Del</span>
                <div 
                    className='date-input'
                    style={{ backgroundColor: colores.color6 }}
                    onClick={ clickCalendar }
                >
                    { moment.tz(value[0], timezone).format('DD/MM/YYYY') }
                    {
                        isOpen && (
                            <div
                                className='vacacional-calendar' ref={popover}
                            >
                                <Calendar
                                    minDetail={ `month` }
                                    onChange={ changing }
                                    returnValue={`range`}
                                    selectRange={ true }
                                    value={ value }
                                />
                            </div>
                        )
                    }
                </div>
                <div 
                    className='time-input'
                    style={{ backgroundColor: colores.color6 }}
                >
                    <HoraInput
                        tipo={ 'horaInicio' }
                        hora={ moment.tz(value[0], timezone).format('HH:mm') }
                        changeHora={ changeHoraInicio }
                        intMinutos={ 1 }
                    />
                </div>
            </div>
            <div className='date-row al'>
                <span style={{ color: colores.colorSecundario }}>al</span>
                <div 
                    className='date-input'
                    style={{ backgroundColor: colores.color6 }}
                    onClick={ clickCalendar }
                >
                    { moment.tz(value[1], timezone).format('DD/MM/YYYY') }
                </div>
                <div 
                    className='time-input'
                    style={{ backgroundColor: colores.color6 }}
                >
                    <HoraInput
                        tipo={ 'horaFin' }
                        hora={ moment.tz(value[1], timezone).format('HH:mm') }
                        changeHora={ changeHoraFin }
                        intMinutos={ 1 }
                    />
                </div>
            </div>
        </div>
        <YlmCheckbox
            text={'Toda la jornada'}
            value={ hideHours }
            onChange={ checkBoxChange }
        />
        </>
    )
}
