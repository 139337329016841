import { useCallback, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';
import useClickOutside from '../../../../hooks/useClickOutside';

export const SelectComplementario = ({ objServicio, dataChange, big = false, top = false, left = false }) => {
    const { servicios: complementarios } = useSelector(state => state.servicios);

    const [selectText, setSelectText] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        if (complementarios.length > 0) {
            if (objServicio?.complementarios) {
                let seleccionadas = 0;
                complementarios.forEach(complementario => {
                    if (objServicio.complementarios.includes(complementario._id) || complementario.deleted) {
                        seleccionadas++;
                    }
                });
                if (seleccionadas === 0) {
                    setSelectText('Ninguno');
                } else if (seleccionadas === complementarios.length - 1) {
                    setSelectText('Todos');
                } else {
                    setSelectText('Varios');
                }
            }
        } else {
            setSelectText('No existen');
        }
    }, [objServicio, complementarios]);


    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle(!isOpen);
    }

    const clickComplementario = (idComplementario) => {
        let newComplementarios = [];

        if (!objServicio.complementarios) {
            objServicio.complementarios = [];
        }

        if (objServicio.complementarios.includes(idComplementario)) {
            newComplementarios = objServicio.complementarios.filter(suc => {
                return suc !== idComplementario;
            });
        } else {
            newComplementarios = [
                ...objServicio.complementarios,
                idComplementario
            ]
        }
        dataChange('complementarios', newComplementarios);
    }

    return (
        <div
            className='ylm-sucursal-select'
        >
            <div
                className='select-main'
                onClick={clickSelect}
            >
                <p>{selectText}</p>
                <ChevronDownIcon color={'#000000'} />
            </div>
            {
                isOpen &&
                <ul
                    className={`select-options std-ul ${big && 'big'} ${top && 'top'} ${left && 'left'}`}
                    ref={popover}
                >
                    {
                        complementarios.map(complementario => (
                            (!complementario.deleted && complementario._id !== objServicio._id) &&
                            <li
                                key={complementario._id}
                                className={`std-li ${objServicio?.complementarios?.includes(complementario._id) ? 'active' : ''}`}
                                onClick={() => clickComplementario(complementario._id)}
                            >
                                <div className='checkbox-bg'>
                                    <span className='checkbox-item'></span>
                                </div>
                                <p>{complementario.nombre}</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
