/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import uuid from 'react-uuid';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    }, [when]);
}

export const DropdownProfesionales = ( { selectSucursal, selectServicio, profesionales, profesionalesDisabled,
    texto, handleProfesionalSelected, selectProfesional, fromGestion = false, citaActive = {} } ) => {
    const { colores } = useSelector(state => state.ui);

    const [selectedIndex, setSelectedIndex] = useState((citaActive?.isProfesionalRandom) ? 0 : -1);
    const [visibility, setVisibility] = useState(false);
    const [profesionalesAll, setProfesionalesAll] = useState([]);
    const dropdownMenuRef = useRef();

    let profesionalesTemp = [];

    const showDropdown = () => {
        if( selectProfesional.visible ){
            setVisibility(true);
        }
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    const selectItem = (index) => {
        if( !selectProfesional.cambiado ){
            selectProfesional.cambiado = true;
        }
        hideDropdown();
        setSelectedIndex(index);
        handleProfesionalSelected(profesionalesAll[index]);
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

    useEffect(() => {
        if( profesionales.length > 0 &&  selectSucursal.selected){
            const idSucursal = String(selectSucursal.selected._id);
            profesionales.map( item => {
                if( item.horarioSucursales ) {
                    let prioridadSelected = 1000;
                    item.horarioSucursales.map( horario => {
                        if( horario.sucursal === idSucursal ){
                            prioridadSelected = horario.prioridad;
                        }
                        return horario; // ES Lint
                    });
                    item.prioridad = prioridadSelected;
                }
                return item;
            })
            profesionales.sort( (a,b) => {
                if( a.prioridad > b.prioridad ){
                    return 1;
                }
                if( a.prioridad < b.prioridad ){
                    return -1;
                }
                return 0;
            });
            const profesionalesSucursal = profesionales.filter(profesional => !profesional.sucursales || profesional.sucursales.includes(idSucursal));
            profesionalesDisabled.map( item => {
                if( item.horarioSucursales ) {
                    let prioridadSelected = 1000;
                    item.horarioSucursales.map( horario => {
                        if( horario.sucursal === idSucursal ){
                            prioridadSelected = horario.prioridad;
                        }
                        return horario; // ES Lint
                    });
                    item.prioridad = prioridadSelected;
                }
                return item;
            })
            profesionalesDisabled.sort( (a,b) => {
                if( a.prioridad > b.prioridad ){
                    return 1;
                }
                if( a.prioridad < b.prioridad ){
                    return -1;
                }
                return 0;
            });
            const profdisabled = profesionalesDisabled.filter(profesional => profesional.sucursales.includes(idSucursal));
            const profTemp = [...profesionalesSucursal, ...profdisabled];
            profesionalesTemp = [...new Set(profTemp)];
            setProfesionalesAll([...new Set(profTemp)]);
            if(profesionalesAll.length==1 && !selectProfesional.selected) selectItem(0);
        }
        if( !selectProfesional.selected ) {
            setSelectedIndex(-1);
        } else {
            if( citaActive && citaActive.isProfesionalRandom && !selectProfesional.cambiado ){
                setSelectedIndex(0);
            } else {
                profesionalesTemp.map( (profesional, idx) => {
                    if( profesional._id === selectProfesional.selected._id ){
                        setSelectedIndex(idx);
                    }
                });
            }
        }
    }, [selectProfesional, profesionales, citaActive]);

    const existsProfesional = (profesional)=> {
        return profesionales.some((prof) => prof._id === profesional._id);
    } 

    return (
        <div className="brb-select">
            <div 
                className={`brb-select-container ${ selectProfesional.visible ? 'visible' : '' } ${ visibility ? 'active' : '' }`}
                onClick={ () => visibility ? hideDropdown() : showDropdown() }
            >
                <p>{ (selectedIndex > -1) ? profesionalesAll[selectedIndex]?.nombre : texto }</p>
                {/* {
                    (fromGestion)
                    ?
                        <p>{ (selectedIndex > -1) ? profesionales[selectedIndex]?.nombre : texto }</p>
                    :
                    (aleatorio)
                    ?
                        <p>Profesional aleatorio</p>
                    :
                        <p>{ (selectedIndex > -1) ? profesionales[selectedIndex]?.nombre : texto }</p>
                } */}
                
                <div className="right-zone">
                    <p></p>
                    <span
                        className="select-arrow select-arrow-down"
                    ></span>
                </div>
            </div>
            <div ref={dropdownMenuRef} className={`brb-select-options select-profesional ${ visibility ? 'active' : '' }`}>
                {
                    //profesionales.map( (profesional, idx) => (
                    profesionalesAll.map( (profesional, idx) => (
                        existsProfesional(profesional)?
                            <div 
                                className={`brb-select-option ${(profesional.promo) ? 'gold' : ''}`}  
                                style={{ backgroundColor: colores.color6 }}
                                key={ profesional._id }
                                onClick={ () => selectItem(idx) }
                            >
                                {/* <p style={{ color: profesionales.filter(x => x._id === profesional._id) ? 'black' : 'blue' }}>{ profesional.nombre }</p> */}
                                <p>{ profesional.nombre }</p>
                                
                                <div className="right-container">
                                    <div className="price"></div>
                                    {
                                        (profesional.horarioSucursales?.length > 0)
                                        ?
                                            profesional.horarioSucursales.map( horarioSucursal => (
                                                (horarioSucursal.sucursal === selectSucursal.selected?._id)
                                                &&
                                                <div key={ uuid() } className="horario">
                                                    {
                                                        horarioSucursal.horarioTxt.map( (itemHorario, idx) => (
                                                            <p key={idx}>{ itemHorario } </p>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        :
                                            (profesional.horarioTxt.length > 0)
                                            &&
                                            (
                                                <div className="horario">
                                                    {
                                                        profesional.horarioTxt.map( (itemHorario, idx) => (
                                                            <p key={idx}>{ itemHorario } </p>
                                                        ))
                                                    }
                                                </div>
                                            )
                                    }
                                    <div className="promo">
                                        {
                                            (profesional.promo)
                                            &&
                                            <div className="promo-container gastromond">
                                                <div>-<span>{ profesional.promo.texto}</span>{(profesional.promo.tipo === 'cifra') ? '€' : '%'}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        : 
                            <div 
                                className={`brb-select-option ${(profesional.promo) ? 'gold' : ''}`}  
                                style={{ backgroundColor: colores.color6 , color: 'lightgray'}}
                                key={ profesional._id }
                            >
                                {/* <p style={{ color: profesionales.filter(x => x._id === profesional._id) ? 'black' : 'blue' }}>{ profesional.nombre }</p> */}
                                <p>{ profesional.nombre }</p>
                            
                                <div className="right-container">
                                    <div className="price"></div>
                                    {
                                        (profesional.horarioSucursales && profesional.horarioSucursales?.length > 0)
                                        ?
                                            profesional.horarioSucursales.map( horarioSucursal => (
                                                (horarioSucursal.sucursal === selectSucursal.selected?._id)
                                                &&
                                                <div key={ uuid() } className="horario">
                                                    {
                                                        horarioSucursal.horarioTxt.map( (itemHorario, idx) => (
                                                            <p key={idx}>{ itemHorario } </p>
                                                        ))
                                                    }
                                                </div>
                                            ))
                                        :
                                            (profesional.horarioTxt.length > 0)
                                            &&
                                            (
                                                <div className="horario">
                                                    {
                                                        profesional.horarioTxt.map( (itemHorario, idx) => (
                                                            <p key={idx}>{ itemHorario } </p>
                                                        ))
                                                    }
                                                </div>
                                            )
                                    }
                                    <div className="promo">
                                        {
                                            (profesional.promo)
                                            &&
                                            <div className="promo-container gastromond">
                                                <div>-<span>{ profesional.promo.texto}</span>{(profesional.promo.tipo === 'cifra') ? '€' : '%'}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                    ))
                }
            </div>
        </div>
    )
}
