import { useCallback, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModalBottom } from '../../../../actions/ui';

import { ChevronDownIcon } from '../../../../assets/svg/iconos';
import useClickOutside from '../../../../hooks/useClickOutside';

export const SelectServicio = ({objPromocion, dataChange, editMode}) => {

    const dispatch = useDispatch();
    const { servicios } = useSelector(state => state.servicios);

    const [selectText, setSelectText] = useState('');

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    useEffect(() => {
        /* console.log( servicios );
        console.log( objPromocion ); */
        if( objPromocion?.tipo ){
            let seleccionadas = 0;
            servicios.forEach( servicio => {
                if( objPromocion.servicios.includes( servicio._id ) || servicio.deleted ){
                    seleccionadas++;
                }
            });
            if( seleccionadas === 0 ){
                setSelectText( 'Ninguno' );
            } else if( seleccionadas === servicios.length ){
                setSelectText( 'Todos' );
            } else {
                setSelectText( 'Algunos' );
            }
        } else {
            setSelectText( 'Ninguno' );
        }
    }, [objPromocion, servicios]);
    

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle( !isOpen );
    }

    const clickServicio = ( idServicio ) => {
        if( editMode ){
            let newServicios = [];
            if( objPromocion.servicios.includes( idServicio ) ){
                newServicios = objPromocion.servicios.filter( suc => {
                    return suc !== idServicio;
                });
                console.log( newServicios );
            } else {
                newServicios = [
                    ...objPromocion.servicios,
                    idServicio
                ]
            }
            dataChange('servicios', newServicios );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    return (
        <div 
            className='ylm-servicio-select promocion-selector'
        >
            <div 
                className='select-main'
                onClick={ clickSelect }
            >
                <p>{ selectText }</p>
                <div 
                    className='btn-chevron'
                    style={{ transform: isOpen && 'rotate(180deg)'}}
                >
                    <ChevronDownIcon
                        color={ '#000000' }
                    />
                </div>
            </div>
            {
                isOpen &&
                <ul 
                    className={`select-options opt-servicios`}
                    ref={popover}
                >
                    {
                        servicios.map( servicio => (
                            !servicio.deleted &&
                            <li
                                key={ servicio._id }
                                onClick={ () => clickServicio( servicio._id )}
                            >
                                <div  
                                    className={`checkbox-bg ${objPromocion?.servicios.includes( servicio._id ) ? 'active' : ''}`}
                                >
                                    {
                                        objPromocion?.servicios.includes( servicio._id ) &&
                                        <span 
                                            className='checkbox-item'
                                        ></span>
                                    }
                                </div>
                                <p>{ servicio.nombre }</p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}
