import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModalBottom } from '../../../../actions/ui';
import { ChevronDownIcon } from '../../../../assets/svg/iconos';
import useClickOutside from '../../../../hooks/useClickOutside';

export const SelectDuracion = ({ objServicio, setObjServicio, dataChange, editMode }) => {

    const dispatch = useDispatch();
    const { colores } = useSelector(state => state.ui);
    const [descansos, setDescansos] = useState(objServicio.duracion1 > 0 || objServicio.pausa > 0 || objServicio.duracion2 > 0);

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    const clickSelect = () => {
        toggle(!isOpen);
    }

    useEffect(() => {
        if (objServicio.duracion1 > 0 || objServicio.pausa > 0 || objServicio.duracion2 > 0) {
            setDescansos(true);
        }
    }, [objServicio])


    const changeDescansos = () => {
        if (editMode) {
            if (descansos) {
                const newDuracion = parseInt(objServicio.duracion1) + parseInt(objServicio.pausa) + parseInt(objServicio.duracion2);
                setObjServicio({
                    ...objServicio,
                    duracion1: 0,
                    pausa: 0,
                    duracion2: 0,
                    duracion: newDuracion
                });
                setDescansos(false);
            } else {
                setObjServicio({
                    ...objServicio,
                    duracion1: objServicio.duracion,
                    pausa: 0,
                    duracion2: 0
                });
                setDescansos(true);
            }
        } else {
            dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
        }
    }

    const changeDuracion = (item, value) => {
        let newDuracion = 0;
        if (item === 'duracion1' && !isNaN(parseInt(value)) && parseInt(value) > 0) {
            newDuracion = parseInt(value) + parseInt(objServicio.pausa) + parseInt(objServicio.duracion2);
        }
        if (item === 'pausa' && !isNaN(parseInt(value)) && parseInt(value) > 0) {
            newDuracion = parseInt(objServicio.duracion1) + parseInt(value) + parseInt(objServicio.duracion2);
        }
        if (item === 'duracion2' && !isNaN(parseInt(value)) && parseInt(value) > 0) {
            newDuracion = parseInt(objServicio.duracion1) + parseInt(objServicio.pausa) + parseInt(value);
        }
        setObjServicio({
            ...objServicio,
            [item]: value,
            duracion: newDuracion
        });
    };

    return (
        <>
            <div className='right-block'>
                <div className='title' style={{ color: colores.colorPrincipal }}>Duración</div>
                <div className='values' onClick={clickSelect}>
                    <p>{`${objServicio.duracion !== '' ? objServicio.duracion + "'" : '- -'}`}</p>
                </div>
            </div>
            <div className='right-block'>
                <div className='title' style={{ color: colores.colorPrincipal }}>Pausas</div>
                <div className='values' onClick={clickSelect}>
                    <p>{`${objServicio.pausa > 0 || objServicio.duracion2 > 0 ? 'Si' : '- -'}`}</p>
                    <span><ChevronDownIcon color="#000000" /></span>
                </div>
            </div>
            {
                isOpen &&
                <div
                    className='select-options'
                    style={{ backgroundColor: colores.color6 }}
                    ref={popover}
                >
                    <div className='duracion'>
                        <h5 style={{ color: colores.colorPrincipal }}>Duración (en minutos)</h5>
                        <input
                            type={`text`}
                            style={{ borderColor: colores.colorPrincipal }}
                            placeholder="Minutos totales"
                            disabled={!editMode || (objServicio.duracion1 > 0 || objServicio.pausa > 0 || objServicio.duracion2 > 0)}
                            value={objServicio.duracion}
                            onChange={(e) =>
                                dataChange('duracion', parseInt(e.target.value) > 0 ? parseInt(e.target.value).toString() : "0")
                            }
                        />
                    </div>
                    <div className='descansos'>
                        <p style={{ color: colores.colorPrincipal }}>Activar descansos entre-medio</p>
                        <div
                            className={`ylm-toggle ${descansos && 'active'}`}
                            onClick={() => changeDescansos()}
                        >
                            <div
                                className={`toggle-bg`}
                                style={{ backgroundColor: descansos ? colores.colorSecundario : '#C9C5C5' }}
                            >
                                <span
                                    className={`toggle-item`}
                                    style={{ backgroundColor: descansos ? colores.colorPrincipal : '#7B7B7B' }}
                                ></span>
                            </div>
                        </div>
                    </div>
                    {
                        descansos &&
                        <div className='descansos-form'>
                            <div className='descansos-form-item'>
                                <h5 style={{ color: colores.colorPrincipal }}>Principio</h5>
                                <input
                                    type={`text`}
                                    style={{ borderColor: colores.colorPrincipal }}
                                    placeholder="Minutos"
                                    disabled={!editMode}
                                    value={objServicio.duracion1}
                                    onChange={(e) => changeDuracion('duracion1', e.target.value)}
                                />
                            </div>
                            <div className='descansos-form-item'>
                                <h5 style={{ color: colores.colorPrincipal }}>Pausa</h5>
                                <input
                                    type={`text`}
                                    style={{ borderColor: colores.colorPrincipal }}
                                    placeholder="Minutos"
                                    disabled={!editMode}
                                    value={objServicio.pausa}
                                    onChange={(e) => changeDuracion('pausa', e.target.value)}
                                />
                            </div>
                            <div className='descansos-form-item'>
                                <h5 style={{ color: colores.colorPrincipal }}>Final</h5>
                                <input
                                    type={`text`}
                                    style={{ borderColor: colores.colorPrincipal }}
                                    placeholder="Minutos"
                                    disabled={!editMode}
                                    value={objServicio.duracion2}
                                    onChange={(e) => changeDuracion('duracion2', e.target.value)}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}
