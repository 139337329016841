import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../actions/ui';

export const YlmModal = () => {

    const dispatch = useDispatch();
    const { modalOpened, modalData, colores } = useSelector(state => state.ui);

    const { title, text, buttonText, updateAvailable = false, buttonVisible = true } = modalData;

    const clickCloseModal = ( fromButton = false ) => {
        if( updateAvailable ) {
            window.location.reload();
        }
        dispatch( closeModal() );
    };

    return (
        <div className={`modal-ylm ${(modalOpened) ? 'active' : ''}`}>
            <div className="modal-bg"></div>
            <div className="modal-wrapper">
                <div className="modal-title">
                    <h3 style={{ color: colores.colorPrincipal }}>{ title }</h3>
                </div>
                <div className="modal-body">
                    {
                        text.map( (linea, idx) => (
                            <p key={ idx }>{ linea }</p>
                        ))
                    }
                </div>
                
                {
                    (buttonVisible)
                    &&
                    <div className="modal-footer">
                        <div className="buttons">
                            <div 
                                className="btn btn-confirm"
                                style={{ backgroundColor: colores.colorPrincipal }}
                                onClick={ () => clickCloseModal( true ) }
                            >{ buttonText }</div>
                        </div>
                    </div>
                }

                {
                    (buttonVisible)
                    &&
                    <div
                        className="modal-close"
                        onClick={ clickCloseModal }
                    ><span></span></div>
                }
            </div>
        </div>
    )
}
