import React, { useEffect, useState } from 'react';
import { RegisterFormError } from '../../../assets/svg/iconos';
import { fetchSinToken } from '../../../helpers/fetch';
import { isEmail } from '../../../helpers/forms';
import { useForm } from '../../../hooks/useForm';

const initErrores = {
    nombreEmpresa: { active: false, msg: 'Debe introducir el nombre de la empresa' },
    nombre: { active: false, msg: 'Debe introducir su nombre' },
    apellidos: { active: false, msg: 'Debe introducir sus apellidos' },
    telefono: { active: false, msg: 'Debe introducir su teléfono' },
    email: { active: false, msg: 'Debe introducir un correo electrónico válido'},
    direccion: { active: false, msg: 'Debe introducir su dirección' },
    cif: { active: false, msg: 'Debe introducir su cif' }
}

export const DatosPersonales = ({ active, setTab, empresa, setEmpresa }) => {

    const [ formValues, handleInputChange ] = useForm({
        nombreEmpresa: '',
        nombre: '',
        apellidos: '',
        email: '',
        telefono: '',
        direccion: '',
        cif: ''
    });

    // eslint-disable-next-line no-unused-vars
    const [objErrores, setObjErrores] = useState(initErrores);
    const [errores, setErrores] = useState( initErrores );

    const { nombreEmpresa, nombre, apellidos, email, telefono, direccion, cif } = formValues;

    // useEffect(() => {
    //   console.log( 'objErrores', objErrores );
    // }, [objErrores]);

    const checkEmail = async() => {      
        const newObjErrores = { ...objErrores };  
        if( isEmail( email ) ){
            delete newObjErrores['invalidEmail'];
            const resp = await fetchSinToken( 'auth/test-email-admin', { email }, 'POST');
            const body = await resp.json();
            if( !body.ok ) {
                console.log('Email exists');
                newObjErrores.emailExists = 'El email solicitado ya existe';
            } else {
                if( objErrores.emailExists ){
                    delete newObjErrores['emailExists'];
                }
            }     
            
            //return Object.keys(newObjErrores).length;
        } else {
            newObjErrores.invalidEmail = 'Debe contener un email válido';
        }
        setObjErrores( newObjErrores );
    };

    const handleStep1Submit = (e) => {
        e.preventDefault();
        let hayErrores = false;
        const objErrores = { ...initErrores };

        if( !isEmail( email ) ){
            hayErrores = true;
            objErrores.email.active = true; 
        } else {
            objErrores.email.active = false; 
        }
        if( nombreEmpresa.trim() === '' ){
            hayErrores = true;
            objErrores.nombreEmpresa.active = true; 
        } else {
            objErrores.nombreEmpresa.active = false; 
        }
        if( nombre.trim() === '' ){
            hayErrores = true;
            objErrores.nombre.active = true; 
        } else {
            objErrores.nombre.active = false; 
        }
        if( apellidos.trim() === '' ){
            hayErrores = true;
            objErrores.apellidos.active = true; 
        } else {
            objErrores.apellidos.active = false; 
        }
        if( telefono.trim() === '' ){
            hayErrores = true;
            objErrores.telefono.active = true; 
        } else {
            objErrores.telefono.active = false; 
        }
        if( direccion.trim() === '' ){
            hayErrores = true;
            objErrores.direccion.active = true; 
        } else {
            objErrores.direccion.active = false; 
        }
        if( cif.trim() === '' ){
            hayErrores = true;
            objErrores.cif.active = true; 
        } else {
            objErrores.cif.active = false; 
        }
        if( !hayErrores ){
            setErrores( initErrores );
            setEmpresa({
                ...empresa,
                nombre: nombreEmpresa,
                email,
                telefono,
                direcciones: [{ direccion }],
                cif
            });
            setTab(2);
        } else {
            console.log( objErrores );
            setErrores( objErrores );
        }
    };


    return (    
        <div className={`steps-tab tab-datos ${ active ? 'active' : ''}`}>
            <h2>Datos Personales</h2>
            <form onSubmit={ handleStep1Submit }>
                <div className='form-row'>
                    <div className='form-input'>
                        <label htmlFor='nombreEmpresa'>Nombre de tu negocio</label>
                        <input
                            type="text"
                            autoComplete="disabled"
                            id="nombreEmpresa"
                            name="nombreEmpresa"
                            value={ nombreEmpresa }
                            onChange={handleInputChange}
                        />
                        {
                            (objErrores.nombreEmpresa.active) &&
                            <div className='error-indicator'>
                                <RegisterFormError />
                            </div>
                        }
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-input'>
                        <label htmlFor='nombre'>Nombre</label>
                        <input
                            type="text"
                            autoComplete="disabled"
                            id="nombre"
                            name="nombre"
                            value={ nombre }
                            onChange={handleInputChange}
                        />
                        {
                            (objErrores.nombre.active) &&
                            <div className='error-indicator'>
                                <RegisterFormError />
                            </div>
                        }
                    </div>
                    <div className='form-input'>
                        <label htmlFor='apellidos'>Apellidos</label>
                        <input
                            type="text"
                            autoComplete="disabled"
                            id="apellidos"
                            name="apellidos"
                            value={ apellidos }
                            onChange={handleInputChange}
                        />
                        {
                            (objErrores.apellidos.active) &&
                            <div className='error-indicator'>
                                <RegisterFormError />
                            </div>
                        }
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-input'>
                        <label htmlFor='email'>Correo electrónico</label>
                        <input
                            type="email"
                            autoComplete="disabled"
                            id="email"
                            name="email"
                            onBlur={ checkEmail }
                            value={ email }
                            onChange={handleInputChange}
                        />
                        {
                            (objErrores.emailExists || objErrores.invalidEmail || objErrores.email.active ) &&
                            <div className='error-indicator'>
                                <RegisterFormError />
                            </div>
                        }
                    </div>
                    <div className='form-input'>
                        <label htmlFor='telefono'>Teléfono</label>
                        <input
                            type="text"
                            autoComplete="disabled"
                            id="telefono"
                            name="telefono"
                            value={ telefono }
                            onChange={handleInputChange}
                        />
                        {
                            (objErrores.telefono.active) &&
                            <div className='error-indicator'>
                                <RegisterFormError />
                            </div>
                        }
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-input'>
                        <label htmlFor='direccion'>Dirección</label>
                        <input
                            type="text"
                            autoComplete="disabled"
                            id="direccion"
                            name="direccion"
                            value={ direccion }
                            onChange={handleInputChange}
                        />
                        {
                            (objErrores.direccion.active) &&
                            <div className='error-indicator'>
                                <RegisterFormError />
                            </div>
                        }
                    </div>
                    <div className='form-input'>
                        <label htmlFor='cif'>CIF / NIF</label>
                        <input
                            type="text"
                            autoComplete="disabled"
                            id="cif"
                            name="cif"
                            value={ cif }
                            onChange={handleInputChange}
                        />
                        {
                            (objErrores.cif.active) &&
                            <div className='error-indicator'>
                                <RegisterFormError />
                            </div>
                        }
                    </div>
                </div>
                <div className='form-btn'>
                    <button
                        type='submit'
                    >
                        Siguiente
                    </button>
                </div>
            </form>
        </div>
    )
}
