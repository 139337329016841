
import { types } from "../types/types";

const initialState = {
    empresas: [],
    total: 0,
    active: null
}

export const empresasReducer = ( state = initialState, action ) => {
    switch (action.type) {

        case types.empresasActive:
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
        
        case types.empresasUnactive:
            return {
                ...state,
                active: null
            }
        
        case types.empresasAddNew:
            return {
                ...state,
                empresas: [ ...state.empresas, action.payload ]
            }

        case types.empresasLoad:
            return {
                ...state,
                empresas: [ ...action.payload.empresas ],
                total: action.payload.total
            }
        
        case types.empresasUpdated:
            return {
                ...state,
                empresas: state.empresas.map(
                    empresa => empresa._id === action.payload._id
                        ? action.payload
                        : empresa
                )
            }

        case types.empresasDelete:
            return {
                ...state,
                active: null,
                empresas: state.empresas.filter( empresa => empresa._id !== action.payload )
            }
    
        case types.empresasLogoutCleaning:
            return {
                ...state,
                active: null,
                empresas: []
            }
    

        default:
            return state;
    }
}