import React from 'react'

const PermisoItem = ({ propiedad, nombre, valor, toggleFunctionality }) => {
  return (
    <div className={`permiso permiso-profesionales ${valor > 0 ? 'active' : ''}`}> 
        <div className='permiso-qt'>
          {
            valor > 0 &&
            <p>{ valor }<span>{ `${propiedad === 'formacion' ? 'h.' : '' }` }</span></p>
          }
        </div>
        <div className='permiso-data'>
          <p>{ nombre }</p>
          <div 
            className={`permiso-toggle`}
            onClick={() => toggleFunctionality( propiedad, valor ) }
          ><span className={`${valor > 0 ? 'active' : ''}`}></span></div>
        </div>
    </div>
  )
}

export default PermisoItem