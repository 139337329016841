import React from 'react'
import { useSelector } from 'react-redux';

export const YlmLoading = () => {
    const { loadingOpened } = useSelector(state => state.ui);

    return (
        <div className={`loading-ylm ${(loadingOpened) ? 'active' : ''}`}>
            <div className="loading-bg"></div>
            <div className="loading-wrapper">
                <h3>Cargando ...</h3>
            </div>
        </div>
    )
}
