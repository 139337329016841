import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { openModal } from '../../actions/ui';
import { fetchSinToken } from '../../helpers/fetch';
import { useForm } from '../../hooks/useForm';

import bgImage from '../../assets/img/bg_login_exora.jpg';
import appLogo from '../../assets/svg/logo-exora.svg';

const initErrores = {
    password: { active: false, msg: 'La contraseña debe tener 6 o más caracteres'},
    passwords: { active: false, msg: 'Las contraseñas no coinciden'}
}

export const ResetPassword = ( props ) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const [confirmed, setConfirmed] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [changed, setChanged] = useState(false);


    useEffect(() => {
        if( !confirmed ){
            comprobarToken( id );
        }
    }, [id, confirmed]);

    const comprobarToken = async( token ) => {
        try {
            const resp = await fetchSinToken( 'auth/test-token', { token }, 'POST');
            const body = await resp.json();
            if( body.ok ){
                setConfirmed(true);
            } else {
                setRejected( true );
            }
        } catch (error) {
            console.log('Hay un error');
            console.log( error );
        }
    };

    // Funciones reset-pwd
    const [errores, setErrores] = useState( initErrores );

    const [ formValues, handleInputChange ] = useForm({
        password: '',
        password2: ''
    });

    const { password, password2 } = formValues;

    const getFormErrors = () => {
        let hayErrores = false;
        const objErrores = { ...errores };

        if( formValues.password.trim().length < 6 ){
            hayErrores = true;
            objErrores.password.active = true; 
        } else {
            objErrores.password.active = false; 
        }
        if( formValues.password !== formValues.password2 ){
            hayErrores = true;
            objErrores.passwords.active = true; 
        } else {
            objErrores.passwords.active = false; 
        }

        setErrores( objErrores );
        return hayErrores;
    }

    const handleChange = (e) => {
        e.preventDefault();
        const hayErrores = getFormErrors();

        if( !hayErrores ){
            cambiar( id, password );
        }
    };

    const cambiar = async( token, password ) => {
        const resp = await fetchSinToken( 'auth/reset', { token, password }, 'POST');
        const body = await resp.json();
        if( body.ok ){
            setChanged( true );
        } else {
            const datos = {
                title: 'Error',
                text: ( Array.isArray( body.msg ) ) ? body.msg : [body.msg],
                buttonText: 'Cerrar'
            }
            setRejected( true );
            dispatch( openModal( datos ) );
        }
    };

    return (
        <>
        {
            (confirmed && !rejected && !changed )
            ?
            <div className={`login-container reset-pwd active`} style={{ backgroundImage: `url('${bgImage}`}}>
                <div className="form-container">
                    <div className="form-header">
                        <Link to="/"><img src={appLogo} alt="logotipo"></img></Link>                    
                    </div>
                    <h1>{`Configura tu nueva contraseña`}</h1>

                    <form onSubmit={ handleChange }>
                        <div className="form-item">
                            <input 
                                type="password"
                                placeholder="Contraseña"
                                name="password"
                                className="auth__input"
                                autoComplete="disabled"
                                value={ password }
                                onChange={ handleInputChange }
                                onBlur={ getFormErrors }
                            />
                            <p className={`error-text  ${(errores.password.active) ? 'active' : ''}`}>
                                { errores.password.msg }
                            </p>
                        </div>
                        <div className="form-item">
                            <input 
                                type="password"
                                placeholder="Repite contraseña"
                                name="password2"
                                className="auth__input"
                                autoComplete="disabled"
                                value={ password2 }
                                onChange={ handleInputChange }
                                onBlur={ getFormErrors }
                            />
                            <p className={`error-text  ${(errores.passwords.active) ? 'active' : ''}`}>
                                { errores.passwords.msg }
                            </p>
                        </div>
                        <div>
                            <button
                                className={ `btn-iniciar-sesion visible`}
                                type="submit"
                            >
                                Cambiar contraseña
                            </button>
                        </div>
                        <Link to="/">Volver a la página de inicio</Link>
                    </form>
                </div>
            </div>
            :
            <div className={`account-confirmation`} style={{ backgroundImage: `url('${bgImage}`}}>
                    <div className="form-header">
                        <Link to="/"><img src={appLogo} alt="logotipo"></img></Link>                    
                    </div>
                {
                    (confirmed && changed)
                    ?
                    <>
                        <h1>¡Contraseña modificada!</h1>
                        <div className="link">
                            <Link to="/"><button>Log in</button></Link>
                        </div>
                        <p className='message'>Ya puedes acceder a la app con tu usuario y tu nueva contraseña.</p>
                    </>
                    :
                    (rejected)
                    &&
                    <>
                        <h1>No se puede confirmar tu cuenta</h1>
                        <div className="text">
                            <p>El enlace no es válido o ha caducado</p>
                            <br />
                            <p>Para confirmar tu cuenta ve al apartado "¿Olvidaste tu contraseña?" dentro de la página Iniciar sesión, introduce tu email y sigue las instrucciones del correo que te llegará.</p>
                        </div>
                        <div className="link">
                            <Link to="/"><button>Ir a la página de inicio</button></Link>
                        </div>
                    </>
                }
            </div>
        }
        </>
    )
}
