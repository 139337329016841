/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { YlmPromocionSelectCheckbox } from './YlmPromocionSelectCheckbox';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    }, [when]);
}

export const DropdownPromociones = ( { promocionesFiltradas, selectPromocion, selectSucursal, selectTime, editCita = false } ) => {
    const { colores } = useSelector(state => state.ui);

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [visibility, setVisibility] = useState(false);
    const [txtSelect, setTxtSelect] = useState('Sin descuentos');
    const [promocionesSucursal, setPromocionesSucursal] = useState([]);
    const dropdownMenuRef = useRef();

    useEffect(() => {
        if( selectSucursal.selected?._id ){
            const promosSucursal = promocionesFiltradas.filter( promo => {
                if( promo.sucursales.includes( selectSucursal.selected?._id ) ){
                    return true;
                }
                return false;
            });
            setPromocionesSucursal( promosSucursal );
        }
    }, [ promocionesFiltradas, selectSucursal ])

    const showDropdown = () => {
        if( selectPromocion.visible && selectTime.touched ){
            setVisibility(true);
        }
    }

    const hideDropdown = () => {
        setVisibility(false);
    }

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

    useEffect(() => {
        //console.log( promocionesSucursal );
        let seleccionadas = false;
        promocionesSucursal.forEach(promo => {
            if( promo.selected ){
                seleccionadas = true;
            }
        });
        if( seleccionadas ){
            setTxtSelect( 'Con descuentos aplicados' );
        } else {
            if( promocionesSucursal.length > 0 ){
                setTxtSelect( 'Sin descuentos aplicados' );
            } else {
                setTxtSelect( 'Sin descuentos disponibles' );
            }
        }

    }, [promocionesSucursal])

    useEffect(() => {
        if( selectPromocion.visible ){
            if( !selectPromocion.selected ) {
                setSelectedIndex(-1);
            } else {
                promocionesSucursal.map( (promocion, idx) => {
                    if( promocion._id === selectPromocion.selected._id ){
                        setSelectedIndex(idx);
                    }
                    return promocion;
                });
            }
        } else {
            setSelectedIndex(-1);
            setPromocionesSucursal([]);
        }
    }, [selectPromocion]);

    const promocionSelected = (promocion, e) => {
        const newPromociones = promocionesSucursal.map( item => {
            if( item._id === promocion._id ) {
                (item.selected) ? item.selected = false : item.selected = true;
            }
            return item;
        });
        setPromocionesSucursal( newPromociones );
    };

    return (
        <div className={`brb-select`}>
            <div
                className={`brb-select-container ${ (selectPromocion.visible) ? 'visible' : '' } ${ (visibility && !editCita) ? 'active' : '' }`}
                onClick={ () => (selectPromocion.visible && visibility) ? hideDropdown() : showDropdown() }
            >
                <p>{ txtSelect }</p>
                <div className="right-zone">
                    <p>{ (selectedIndex > -1) ?  promocionesSucursal[selectedIndex].precio + ' €' :  '' }</p>
                    <span
                        className="select-arrow select-arrow-down"
                    ></span>
                </div>
            </div>
            <div ref={dropdownMenuRef} className={`brb-select-options select-promocion ${ visibility ? 'active' : '' }`}>
                {
                    promocionesSucursal.map( (promocion, idx) => (
                        <div
                            className="brb-select-option"
                            style={{ backgroundColor: colores.color6 }}
                            key={ promocion._id }
                        >
                            <YlmPromocionSelectCheckbox
                                promocion={ promocion }
                                clickFunction={ (e) => promocionSelected( promocion, e ) }
                            />
                            {/* <label
                                className={`ylm-select-checkbox ${(promocion.selected) ? 'checked' : ''}`}
                                onClick={ (e) => promocionSelected( promocion, e ) } 
                            >{ `${promocion.titulo} - ${(promocion.cifra) ? promocion.cifra + ' €' : promocion.porcentaje + ' %'}` }
                                
                                <span className="checkmark"></span>
                            </label> */}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
