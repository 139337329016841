import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import { UploadImageIcon } from '../../../assets/svg/iconos';
import { Colores } from './personalizacion/Colores';
import { CustomScreen } from './personalizacion/CustomScreen';
import { CustomScreen2 } from './personalizacion/CustomScreen2';
import { Identidad } from './personalizacion/Identidad';
import { Tipografia } from './personalizacion/Tipografia';
import { RemoveImage } from './personalizacion/RemoveImage';


export const Personalizacion = ({ objEmpresa, setObjEmpresa, cambiado, guardar, cancelar, setCambiado }) => {
  const { colores } = useSelector(state => state.ui);

  const coloresList = [
    {
      _id: '1',
      name: 'theme1',
      main: '#535AF1',
      secondary: ['#D6E8FF', '#FDFFAE', '#D2FAFF', '#F67F8C', '#F8FBFF', '#F4EFEF']
    },
    {
      _id: '2',
      name: 'theme2',
      main: '#1D1D1B',
      secondary: ['#BFA074', '#D4CDBE', '#E1DFD6', '#424242', '#EDD8BB', '#FFF6EA']
    },
    {
      _id: '3',
      name: 'theme3',
      main: '#BC4F16',
      secondary: ['#ED986C', '#FFD9C6', '#FFEDE3', '#424242', '#FFA373', '#EDE7E4']
    },
    {
      _id: '4',
      name: 'theme4',
      main: '#38D3C1',
      secondary: ['#DDF2F1', '#FDFEAE', '#D2FFE9', '#2DA3A3', '#F8FBFF', '#F8F8F8']
    },
    {
      _id: '5',
      name: 'theme5',
      main: '#009DFF',
      secondary: ['#B4DFFF', '#4889B9', '#F4EFEF', '#424242', '#E0F2FF', '#F0F4F7']
    },
    {
      _id: '6',
      name: 'theme6',
      main: '#1D1D1B',
      secondary: ['#F1BF80', '#F2EDE4', '#E1DFD6', '#1D1D1B', '#FFD6A3', '#D4CDBE']
    },
    {
      _id: '7',
      name: 'theme7',
      main: '#000000',
      secondary: ['#F2F2F2', '#BABABA', '#FAFAFA', '#5D5D5D', '#F7F7F7', '#C7C7C7']
    },
  ];

  const getThemeSelected = (objEmpresa) => {
    const currentTheme = coloresList.filter(theme => 
      theme.main === objEmpresa.colorPrincipal && 
      [
        objEmpresa.colorSecundario,
        objEmpresa.color3,
        objEmpresa.color4,
        objEmpresa.color5,
        objEmpresa.color6,
        objEmpresa.color7
      ].every(color => theme.secondary.includes(color))
    );
    return currentTheme[0];
    //return coloresList.find(theme => theme.main === colorPrincipal);
  }

  const fontsList = ['Poppins', 'Fraunces', 'Work Sans'];

  const [activeTab, setActiveTab] = useState('identidad');
  const [colorThemeSelected, setColorThemeSelected] = useState(coloresList[0]);
  const [fontThemeSelected, setFontThemeSelected] = useState(fontsList[0]);

  useEffect(() => {
    let themeSelected = '1';
    if (colores) {
      //themeSelected = getThemeSelected(objEmpresa.colorPrincipal);
      themeSelected = getThemeSelected(objEmpresa);
    }
    setColorThemeSelected(themeSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settings = {
    arrows: false,
    autoplay: false,
    customPaging: function (i) {
      return (<span className='custom-dot'></span>)
    },
    dots: true,
    fade: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const changeFile = (e) => {
    setObjEmpresa({
      ...objEmpresa,
      [e.target.name]: e.target.files[0]
    })
  };

  const isFileUploaded = (key) => {
    const file = objEmpresa[key];

    if (!file) return;

    return !(file instanceof Blob);
  }

  return (
    <div className='configuracion-wrapper'>
      <div className='header'>
        <h1 style={{ color: colores.colorPrincipal }}>Diseño</h1>
        {
          cambiado &&
          <div className='botonera'>
            <button
              className='btn btn-primary'
              style={{ color: colores.color6, backgroundColor: colores.colorPrincipal }}
              onClick={guardar}
            >
              Aplicar
            </button>
            <button
              className='btn btn-secondary'
              style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
              onClick={cancelar}
            >
              Cancelar
            </button>
          </div>
        }
      </div>
      <div className='customize-body'>
        <div className='left'>
          <div className='tabs'>
            <div
              className={`tab ${activeTab === 'identidad' && 'active'}`}
              style={{
                color: activeTab === 'identidad' ? colores.colorPrincipal : colores.colorSecundario,
                backgroundColor: activeTab === 'identidad' ? colores.color6 : 'transparent'
              }}
              onClick={() => setActiveTab('identidad')}
            >
              Identidad
            </div>
            <div
              className={`tab ${activeTab === 'colores' && 'active'}`}
              style={{
                color: activeTab === 'colores' ? colores.colorPrincipal : colores.colorSecundario,
                backgroundColor: activeTab === 'colores' ? colores.color6 : 'transparent'
              }}
              onClick={() => setActiveTab('colores')}
            >
              Colores
            </div>
            <div
              className={`tab ${activeTab === 'tipografia' && 'active'}`}
              style={{
                color: activeTab === 'tipografia' ? colores.colorPrincipal : colores.colorSecundario,
                backgroundColor: activeTab === 'tipografia' ? colores.color6 : 'transparent'
              }}
              onClick={() => setActiveTab('tipografia')}
            >
              Tipografía
            </div>
          </div>
          <div className='panel-body left-body'>
            {activeTab === 'identidad' &&
              <Identidad
                objEmpresa={objEmpresa}
                setObjEmpresa={setObjEmpresa}
				setCambiado={setCambiado}
              />
            }
            {activeTab === 'colores' &&
              <Colores
                objEmpresa={objEmpresa}
                setObjEmpresa={setObjEmpresa}
                cambiado={cambiado}
                guardar={guardar}
                cancelar={cancelar}
                coloresList={coloresList}
                setColorThemeSelected={setColorThemeSelected}
              />
            }
            {activeTab === 'tipografia' &&
              <Tipografia
                setFontThemeSelected={setFontThemeSelected}
              />
            }
          </div>
        </div>
        <div className='right'>
          <div className='tabs'>
            <div
              className={`tab`}
              style={{
                color: colores.colorSecundario
              }}
            >
              Previsualización
            </div>
          </div>
          <div className='panel-body right-body'>
            <div className='prev-canvas'>
              <div className='screen'>
                <Slider {...settings}>
                  <CustomScreen
                    objEmpresa={objEmpresa}
                    colorThemeSelected={colorThemeSelected}
                    fontThemeSelected={fontThemeSelected}
                  />
                  <CustomScreen2
                    colorThemeSelected={colorThemeSelected}
                    fontThemeSelected={fontThemeSelected}
                  />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        activeTab === 'identidad' &&
        (
          <div className='custom-row'>
            <div className='input-container'>
              <h5 style={{ color: colores.colorSecundario }}>Imagen corporativa</h5>
              <div className='customize-input icon'>
                <div className='icon'><UploadImageIcon /></div>
                {isFileUploaded("corpImage") ?
                  <RemoveImage
                    objEmpresa={objEmpresa}
                    setObjEmpresa={setObjEmpresa}
                    keyToRemove='corpImage'
                    colorPrincipal={colores.colorPrincipal}
                  >
                    <img src={objEmpresa.corpImage} alt='logo'
                      width="35px"
                      height="35px"
                    />
                  </RemoveImage>
                  :
                  <input type='text' disabled placeholder='Subir logotipo de altura máxima 50 pixeles' value={objEmpresa.corpImage?.name} />
                }
                <input type='file' name='corpImage' id='upload-corp-image' hidden onChange={changeFile} accept='image/jpg, image/jpeg, image/png, image/gif' />
                <label for="upload-corp-image"
                  className='btn-upload-icon'
                  style={{ backgroundColor: colores.colorPrincipal }}
                >
                  <span>Subir</span>
                </label>
              </div>
            </div>
            <div className='input-container'>
              <h5 style={{ color: colores.colorSecundario }}>Icono aplicación</h5>
              <div className='customize-input icon'>
                <div className='icon'><UploadImageIcon /></div>
                {
                  isFileUploaded("appIcon") ?
                    <RemoveImage
                      objEmpresa={objEmpresa}
                      setObjEmpresa={setObjEmpresa}
                      keyToRemove='appIcon'
                      colorPrincipal={colores.colorPrincipal}
                    >
                      <img src={objEmpresa.appIcon} alt='logo'
                        width="35px"
                        height="35px"
                      />
                    </RemoveImage>
                    :
                    <input type='text' disabled placeholder='Subir logotipo de altura máxima 50 pixeles' value={objEmpresa.appIcon?.name} />
                }
                <input type='file' name='appIcon' id='upload-app-icon' hidden onChange={changeFile} accept='image/jpg, image/jpeg, image/png, image/gif' />
                <label for="upload-app-icon"
                  className='btn-upload-icon'
                  style={{ backgroundColor: colores.colorPrincipal }}
                >
                  <span>Subir</span>
                </label>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
