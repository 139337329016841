import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import { citaLogout } from "./citas";
import { comentarioLogout } from "./comentarios";
import { empresaLogout } from "./empresas";
import { horarioLogout } from "./horarios";
import { productoLogout } from "./productos";
import { profesionalLogout } from "./profesionales";
import { promocionLogout } from "./promociones";
import { servicioLogout } from "./servicios";
import { sucursalLogout } from "./sucursales";
import { openModal } from "./ui";
import { activeUsuario, addNewUsuario, usuarioLogout } from "./usuarios";


export const startLogin = ( email, password ) => {
    return async( dispatch ) => {
        //console.log('startLogin');
        const resp = await fetchSinToken( 'auth', { email, password }, 'POST');
        const body = await resp.json();

        if( body.ok ) {
            localStorage.setItem( 'barbgest-token', body.token );
            localStorage.setItem( 'barbgest-token-init-date', new Date().getTime() );

            dispatch( login({
                uid: body.uid,
                nombre: body.nombre,
                role: body.role,
                empresa: body.empresa,
                plan: body.plan,
                profesional: body.profesional
            }));
        } else {
            const datos = {
                title: 'Error',
                text: ( Array.isArray( body.msg ) ) ? body.msg : [body.msg],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }

    }
}

export const startLoginBusiness = ( email, password ) => {
    return async( dispatch ) => {
        //console.log('startLogin');
        const resp = await fetchSinToken( 'auth/login', { email, password }, 'POST');
        const body = await resp.json();

        if( body.ok ) {
            localStorage.setItem( 'barbgest-token', body.token );
            localStorage.setItem( 'barbgest-token-init-date', new Date().getTime() );

            dispatch( login({
                uid: body.uid,
                nombre: body.nombre,
                role: body.role,
                empresa: body.empresa,
                plan: body.plan,
                profesional: body.profesional
            }));
        } else {
            const datos = {
                title: 'Error',
                text: ( Array.isArray( body.msg ) ) ? body.msg : [body.msg],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }

    }
}

export const startRegister = ( usuario ) => {
    return async( dispatch ) => {
        const resp = await fetchSinToken( 'auth/new', usuario, 'POST');
        const body = await resp.json();

        if( !body.ok ) {
            if( body.code ){
                console.log( body.code );
            }
            const datos = {
                title: 'Error',
                text: [ body.msg ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
}

export const startRegisterBusiness = ( usuario ) => {
    return async( dispatch ) => {
        const resp = await fetchConToken( 'auth/new-user', usuario, 'POST');
        const body = await resp.json();

        if( body.ok ) {
            dispatch( addNewUsuario( body.usuario._id, body.usuario ) );
            dispatch( activeUsuario( body.usuario._id, body.usuario ) );
        } else {
            if( body.code === 'exists' ){
                console.log( body.code );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }
        }
    }
}

export const startChecking = () => {
    return async( dispatch ) => {
        //console.log('startChecking');
        const resp = await fetchConToken( 'auth/renew' );
        const body = await resp.json();

        if( body.ok ) {
            //console.log( 'startChecking', body.token );
            localStorage.setItem( 'barbgest-token', body.token );
            localStorage.setItem( 'barbgest-token-init-date', new Date().getTime() );

            const obj = {
                uid: body.uid,
                nombre: body.nombre,
                role: body.role,
                empresa: body.empresa,
                plan: body.plan,
                profesional: body.profesional
            }; 

            dispatch( login(obj) );
        } else {
            dispatch( checkingFinish() );
        }
    }
};

const checkingFinish = () => ({ type: types.authCheckingFinish })

const login = ( user ) => ({
    type: types.authLogin,
    payload: user
});

export const sendNewPasswordToken =( email, empresa ) => {
    return async( dispatch ) => {
        const resp = await fetchSinToken( 'auth/reset-url', { email, empresa }, 'POST');
        const body = await resp.json();

        if( body.ok ) {
            const datos = {
                title: 'Enviado',
                text: ['Sigue las instrucciones del e-mail para cambiar tu contraseña'],
                buttonText: 'Aceptar'
            }
            dispatch( openModal( datos ) );
        } else {
            console.log( body );
        }
    }
};

export const changePassword = ( token, password ) => {
    return async( dispatch ) => {
        const resp = await fetchSinToken( 'auth/reset', { token, password }, 'POST');
        const body = await resp.json();

        if( body.ok ) {
            console.log( 'Ok' );
        } else {
            const datos = {
                title: 'Error',
                text: ( Array.isArray( body.msg ) ) ? body.msg : [body.msg],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }

    }
};

export const startLogout = ( history ) => {
    return ( dispatch, getState ) => {
        localStorage.removeItem('barbgest-token');
        localStorage.removeItem('barbgest-token-init-date'); 
        dispatch( usuarioLogout() );
        dispatch( empresaLogout() );
        dispatch( sucursalLogout() );
        dispatch( citaLogout() );
        dispatch( servicioLogout() );
        dispatch( horarioLogout() );
        dispatch( profesionalLogout() );
        dispatch( comentarioLogout() );
        dispatch( promocionLogout() );
        dispatch( productoLogout() );
        dispatch( logout() );
        if( history ){
            history.replace('/auth/login');
        }
    }
};

const logout = () => ({ type: types.authLogout });