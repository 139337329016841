import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const useOutsideClick = ( ref, callback, when ) => {
    const savedCallback = useRef( callback );

    useEffect(() => {
        savedCallback.current = callback;
    });

    const handler = (e) => {
        if( ref.current && !ref.current.contains(e.target)) {
            savedCallback.current();
        }
    };

    useEffect(() => {
        if (when) {
            document.addEventListener('click', handler);
            return () => document.removeEventListener('click', handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
}

export const HoraInput = ({ tipo, hora, changeHora, intMinutos = 5 }) => {
    const { colores } = useSelector(state => state.ui);

    const [horaText, setHoraText] = useState(9);
    const [minutosText, setMinutosText] = useState(0);
    const [horaTempText, setHoraTempText] = useState(9);
    const [minutosTempText, setMinutosTempText] = useState(0);

    //const [horasArray, setHorasArray] = useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]);

    const [visibility, setVisibility] = useState(false);
    const dropdownMenuRef = useRef();

    const showDropdown = () => {
        setVisibility(true);
    }

    const hideDropdown = () => {
        updateTemps();
        setVisibility(false);
    }

    /* const selectItem = ( sucursal ) => {
        setHoraText( horaTempText );
        setMinutosText( minutosTempText );
        changeHora({
            tipo,
            hora: `${horaTempText < 10 ? `0${horaTempText}` : horaTempText}:${minutosTempText < 10 ? `0${minutosTempText}` : minutosTempText}`
        });
        hideDropdown();
    } */    

    useOutsideClick( dropdownMenuRef, hideDropdown, visibility );

    useEffect(() => {
        updateStateHora();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //updateTemps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [horaText, minutosText]);

    const updateTemps = () => {
        setHoraTempText( horaText );
        setMinutosTempText( minutosText );
    }    

    const updateStateHora = () => {
        const explotado = hora.split(':');
        setHoraText( parseInt(explotado[0]) );
        setMinutosText( parseInt(explotado[1]) );
        setHoraTempText( parseInt(explotado[0]) );
        setMinutosTempText( parseInt(explotado[1]) );
    }

    const clickSelector = ( selector, operation ) => {
        let newHora = parseInt( horaTempText );
        let newMinutos = parseInt( minutosTempText );

        if( selector === 'hours'){
            if( operation === 'add' ){
                if( newHora < 23 ){
                    setHoraTempText( horaTempText + 1 );
                    setHoraText( horaTempText );
                    newHora++;
                } else {
                    setHoraTempText( 0 );
                    setHoraText( 0 );
                    newHora = 0;
                }
            } else {
                if( newHora > 0 ){
                    setHoraTempText( horaTempText - 1 );
                    setHoraText( horaTempText - 1 );
                    newHora--;
                } else {
                    setHoraTempText( 23 );
                    setHoraText( 23 );
                    newHora = 23;
                }
            }
        }
        if( selector === 'minutes'){
            if( operation === 'add' ){
                if( newMinutos < ( 60 - intMinutos ) ){
                    setMinutosTempText( newMinutos + intMinutos );
                    setMinutosText( newMinutos + intMinutos );
                    newMinutos += intMinutos;
                } else {
                    setMinutosTempText( 0 );
                    setMinutosText( 0 );
                    newMinutos = 0;
                }
            } else {
                if( newMinutos > 0 ){
                    setMinutosTempText( newMinutos - intMinutos );
                    setMinutosText( newMinutos - intMinutos );
                    newMinutos -= intMinutos;
                } else {
                    setMinutosTempText( 60 - intMinutos );
                    setMinutosText( 60 - intMinutos );
                    newMinutos = 60 - intMinutos;
                }
            }
        }
        changeHora({
            tipo,
            hora: `${newHora < 10 ? `0${newHora}` : newHora}:${newMinutos < 10 ? `0${newMinutos}` : newMinutos}`
        });
    }
    
    return (
        <div
            className='hora-input'
        >
            <div
                className={`h-input ${ visibility ? 'active' : '' }`}
                style={{ borderColor: visibility ? colores.colorPrincipal : 'transparent' }}
                onClick={ () => (visibility) ? hideDropdown() : showDropdown() }
            >
                { horaText }:{ minutosText < 10 ? `0${minutosText}` : minutosText }h
            </div>
            <div 
                ref={ dropdownMenuRef }
                className={ `selector-wrapper ${ visibility ? 'active' : '' }` }
                style={{ borderColor: colores.colorPrincipal }}
            >
                <div 
                    className='selectors'
                    style={{ color: colores.colorPrincipal }}
                >
                    <div 
                        className='selector'
                    >
                        <div
                            className='btn btn-substract'
                            onClick={ () => clickSelector( 'hours', 'substract') }
                        >
                            -
                        </div>
                        <div className='selector-value'>
                            { horaTempText }
                        </div>
                        <div
                            className='btn btn-add'
                            onClick={ () => clickSelector( 'hours', 'add') }
                        >
                            +
                        </div>
                    </div>
                    <div className='separator'>:</div>
                    <div className='selector'>
                        <div
                            className='btn btn-substract'
                            onClick={ () => clickSelector( 'minutes', 'substract') }
                        >
                            -
                        </div>
                        <div className='selector-value'>
                            { minutosTempText < 10 ? `0${minutosTempText}` : minutosTempText }
                        </div>
                        <div
                            className='btn btn-add'
                            onClick={ () => clickSelector( 'minutes', 'add') }
                        >
                            +
                        </div>
                    </div>
                </div>
                {/* <button
                    onClick={ selectItem }
                >Guardar</button> */}
            </div>
        </div>
    )
}
