import React from 'react';
import { StarIcon } from '../../assets/svg/iconos';


export const Rating = ({ratingValue, setRatingValue = null }) => {

    const starsCount = [1,2,3,4,5];

    const itemClicked = ( idx ) => {
        if( setRatingValue ) {
            setRatingValue( idx + 1 );
        }
    }

    return (
        <div className="rating">
            {
                starsCount.map( (item, idx) => (
                    <div 
                        key={ idx } 
                        className={`rating-item ${(idx + 1 <= ratingValue) ? 'active' : ''} ${ setRatingValue && 'clickable'}`}
                        onClick={ () => itemClicked( idx ) }
                    >
                        <StarIcon />
                    </div>
                ))
            }
            
        </div>
    )
}
