import React, { useEffect, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCitas } from '../actions/citas';
import { closeLoading, openModal } from '../actions/ui';
import { useSocket } from '../hooks/useSocket'

export const SocketContext = createContext();
const usarSockets = process.env.REACT_SOCKETS;


export const SocketProvider = ({ children }) => {

    const { socket, online, conectarSocket, desconectarSocket } = useSocket( process.env.REACT_APP_API_URL );
    const dispatch = useDispatch();
    const { uid } = useSelector(state => state.auth);

    useEffect(() => {
        if( uid ){
            //conectarSocket();
        }
    }, [ uid, conectarSocket ]);

    useEffect(() => {
        if( !uid ){
            desconectarSocket();
        }
    }, [ uid, desconectarSocket ]);

    // Escuchar los cambios en las citas
    useEffect(() => {
        if( usarSockets ){
            socket?.on( 'lista-citas', ( citas ) => {
                //console.log( citas );
                dispatch( closeLoading() );
                dispatch( setCitas( citas ) );
            });
            socket?.on( 'error', ( error, uidRecibido ) => {
                console.log('Recibido error!', error );
                const myUid = uidRecibido || '';
                if( uid === myUid ){
                    const datos = {
                        title: 'Error',
                        text: [ error ],
                        buttonText: 'Cerrar'
                    }
                    dispatch( closeLoading() );
                    dispatch( openModal( datos ) );
                }
            });
        }
    }, [ socket, uid, dispatch ]);
    
    return (
        <SocketContext.Provider value={{ socket, online }}>
            { children }
        </SocketContext.Provider>
    )
}