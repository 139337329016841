import Swal from 'sweetalert2';

import { types } from "../types/types";
import { fetchConToken, archivoConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewComentario = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'comentarios', values, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                const datos = {
                    text: [ 'Se ha enviado el comentario' ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewComentario( body.comentario._id, body.comentario ) );
                dispatch( startLoadingComentarios( values.empresa ));
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activeComentario = ( _id, comentario ) => ({
    type: types.comentariosActive,
    payload: {
        _id,
        ...comentario
    }
});

export const unactiveComentario = () => ({
    type: types.comentariosUnactive
});

export const addNewComentario = ( _id, comentario ) => ({
    type: types.comentariosAddNew,
    payload: {
        _id,
        ...comentario
    }
})

export const startLoadingComentarios = ( empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'comentarios/empresa', {empresa}, 'POST' );
            const body = await resp.json();
            dispatch( setComentarios( body.comentarios ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingComentariosCita = ( empresa, citaId ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `comentarios/cita/${citaId}`, {empresa}, 'POST' );
            const body = await resp.json();
            dispatch( setComentarios( body.comentarios ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingComentariosCliente = ( empresa, clienteId ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `comentarios/cliente/${clienteId}`, {empresa}, 'POST' );
            const body = await resp.json();
            dispatch( setComentarios( body.comentarios ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setComentarios = ( comentarios ) => ({
    type: types.comentariosLoad,
    payload: comentarios
})

export const startSaveComentario = ( comentario, swal = true ) => {
    return async( dispatch, getState ) => {
        
        try {
            const resp = await fetchConToken( `comentarios/${ comentario._id }`, comentario, 'PUT' );
            const body = await resp.json();
            if ( body.ok ) {
                dispatch( refreshComentario( comentario._id, body.comentario ) );
                const datos = {
                    title: 'Guardado',
                    text: [ comentario.nombre ],
                    buttonText: 'Aceptar'
                }
                dispatch( openModal( datos ) );
            } else {
                console.log( body );
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshComentario = ( id, comentario ) => ({
    type: types.comentariosUpdated,
    payload: {
        ...comentario
    }
});

export const startUploading = ( archivo, idComentario ) => {

    return async ( dispatch, getState ) => {
        try {
            Swal.fire({
                title: 'Subiendo...',
                text: 'Por favor, espere...',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading();
                }
            });

            const formData = new FormData();
            formData.append('archivo', archivo);
            formData.append('file_name', 'archivo');
    
            const resp = await archivoConToken( `comentarios/upload/${ idComentario }`, formData, 'POST' );
            const body = await resp.json();

            console.log( body );

            Swal.close();
        } catch (error) {
            throw new Error();
        }  

        Swal.close();
    }
};

export const comentarioStartDelete = ( id ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `comentarios/${ id }`, {}, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                console.log( body, id );
                dispatch( comentarioDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

const comentarioDeleted = ( id ) => ({
    type: types.comentariosDelete,
    payload: id
});

export const comentarioLogout = () => ({
    type: types.comentariosLogoutCleaning
});
