import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { activeServices, activeServicio, unactiveServicio } from '../../../actions/servicios';
import { openModalBottom, setDarkHeader } from '../../../actions/ui';
import { fetchAvailableSlots, getProfesionales, getProfesionalesDisabled, getSucursales } from '../../../helpers/horarios';
import { activeCita, startNewCita, startSaveCita, unactiveCita } from '../../../actions/citas';
import { DropdownServicios } from '../../../components/forms/DropdownServicios';
import { DropdownMultipleServicios } from '../../../components/forms/DropdownMultipleServicios';
import { DropdownSucursales } from '../../../components/forms/DropdownSucursales';
import { DropdownProfesionales } from '../../../components/forms/DropdownProfesionales';
import { DropdownDate } from '../../../components/forms/DropdownDate';
import { DropdownTime } from '../../../components/forms/DropdownTime';
import { DropdownClientes } from '../../../components/forms/DropdownClientes';
import { activeUsuario, unactiveUsuario, usuarioLogout } from '../../../actions/usuarios';
import { unactiveProfesional } from '../../../actions/profesionales';
import { unactiveSucursal } from '../../../actions/sucursales';
import { SocketContext } from '../../../context/SocketContext';
import { AlertasIcon, CheckIcon, ClientesIcon, ClockAlertIcon, CloseIcon, CommentsFilledIcon, DelayIcon, EditarIcon, EuroIcon, FingerDownIcon, FingerUpIcon, ObservacionesIcon, PagoAdelantadoIcon, PreciosEspecialesIcon, ProfesionalesIcon, RandomIcon, ReservasIcon, ServiciosIcon, SucursalesIcon, TelephoneIcon, TrashIcon, VariantesIcon } from '../../../assets/svg/iconos';
import { DropdownPromociones } from '../../../components/forms/DropdownPromociones';
import { MultiSelectionVariantes } from '../../../components/forms/MultiSelectionVariantes';
import { startLoadingClientesEmpresa} from '../../../actions/usuarios';
import { startLoadingSucursales } from '../../../actions/sucursales';
import { startLoadingServicios } from '../../../actions/servicios';
import { startLoadingProfesionales } from '../../../actions/profesionales';
import { startLoadingHorarios } from '../../../actions/horarios';
import { startLoadingPromociones } from '../../../actions/promociones';
import { startLoadingProductos } from '../../../actions/productos';

// const timezone = process.env.REACT_APP_TIMEZONE;
const usarSockets = process.env.REACT_SOCKETS;

export const ModalReserva = ({ history, citaActive, activeModalReserva, 
    setActiveModalReserva, setActiveModalDeleteCita, setEstadoCita, editCita, 
    setEditCita, setDataModal, modalResponse, setModalResponse, setModalTwoButtonsOpened, 
    modalWaiting, setModalWaiting, fromGestion = true, deleteCita = null, fromCliente = false, 
    usuarioActive = {}, selectedDate }) => {
    const dispatch = useDispatch();
    const { uid, empresa } = useSelector(state => state.auth);
    const { colores } = useSelector(state => state.ui);
    let { usuarios, active: usuarioActivo } = useSelector(state => state.usuarios);
    const { servicios, active: servicioActive } = useSelector(state => state.servicios);
    const { vacaciones } = useSelector(state => state.vacaciones);
    const { empresas } = useSelector(state => state.empresas);
    const { sucursales: rdSucursales, active: sucursalActive } = useSelector(state => state.sucursales);
    const { profesionales: rdProfesionales, active: profesionalActive } = useSelector(state => state.profesionales);
    const { horarios } = useSelector(state => state.horarios);
    const { citas, active: citaActiva } = useSelector(state => state.citas);
    const { promociones } = useSelector(state => state.promociones);
    const { variantes } = useSelector(state => state.variantes);

    const [descuentosHorario, setDescuentosHorario] = useState(0);
    const [intervaloTiempo, setIntervaloTiempo] = useState(5);
    const [ordenSeleccion, setOrdenSeleccion] = useState([]);
    const [formErrores, setFormErrores] = useState({});
    const { socket } = useContext(SocketContext);
    const [hasAlertas, setHasAlertas] = useState(false);
    const [alertasActive, setAlertasActive] = useState(false);
    const [firstProfesional, setFirstProfesional] = useState('');

    const [registerFormOk, setRegisterFormOk] = useState(false);

    const [selectUsuario, setSelectUsuario] = useState({
        selected: null,
        visible: true,
        active: false,
    });
    const [selectServicio, setSelectServicio] = useState({
        selected: null,
        visible: true,
        active: false,
    });
    const [selectSucursal, setSelectSucursal] = useState({
        selected: null,
        visible: false,
        active: false,
    });
    const [selectProfesional, setSelectProfesional] = useState({
        selected: null,
        visible: false,
        active: false,
        cambiado: (fromGestion) ? true : false
    });
    const [selectDate, setSelectDate] = useState({
        date: new Date(),
        visible: false,
        active: false,
    });
    const [selectTime, setSelectTime] = useState({
        date: new Date(),
        visible: false,
        active: false,
        touched: false
    });
    const [selectPromocion, setSelectPromocion] = useState({
        promociones: [],
        visible: false,
        active: false
    });
    
    const [selectedServices, setSelectedServices] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);
    const [selectedVariantes, setSelectedVariantes] = useState([]);

    const [fechaCalendar, setFechaCalendar] = useState(new Date());
    const [timeCalendar, setTimeCalendar] = useState(new Date(1972, 10, 13, 0, 0));
    const [profSelected, setProfSelected] = useState('');

    const [sucursales, setSucursales] = useState([]);
    const [profesionales, setProfesionales] = useState([]);
    const [profesionalesDisabled, setProfesionalesDisabled] = useState([]);
    const [promocionesFiltradas, setPromocionesFiltradas] = useState([]);

    const [morning, setMorning] = useState([]);
    const [afternoon, setAfternoon] = useState([]);

    const [txtComentario, setTxtComentario] = useState('');
    const [comentariosCita, setComentariosCita] = useState([]);
    const [comentariosRespondidos, setComentariosRespondidos] = useState(false);
    const [grabaComentarios, setGrabaComentarios] = useState(false);
    const [respuestaAutomatica, setRespuestaAutomatica] = useState(true);
    const [editActive, setEditActive] = useState(false);

    if(citaActive===undefined) citaActive = citaActiva; 
	const [usersLocal, setUsersLocal] = useState([]);

    if(editCita && usuarioActivo==null) usuarioActivo = usuarioActive;

    useEffect(() => {
		if(usuarios.length==0){
            dispatch( startLoadingClientesEmpresa( empresa, 0, 0, 'creacion' ) );
        }

        dispatch(startLoadingSucursales(empresa));
        dispatch(startLoadingServicios(empresa));
        dispatch(startLoadingProfesionales(empresa));
        dispatch(startLoadingHorarios(empresa));
        dispatch(startLoadingPromociones(empresa));
        dispatch(startLoadingProductos(empresa));
    }, [usuarioActivo]);

    useEffect(() => {
        if (servicioActive) {
            handleServicioSelected(servicioActive);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (empresas[0]) {
            //setIntervaloTiempo(parseInt(empresas[0].intervaloTiempo));
            setOrdenSeleccion(empresas[0].ordenSeleccion);
        }
    }, [empresas]);

	useEffect(()=>{
		if(usersLocal.length==0){
			setUsersLocal(usuarios)
		}
	},[usuarios])
    // Divido comentarios en business y user
    useEffect(() => {	
		if(usersLocal.length==0){
			dispatch(startLoadingClientesEmpresa( empresa, 0, 0, 'creacion' ) );			
		}	
        if (citaActive) {
            let conAlertas = false;
            if (citaActive?.usuarioDelay !== "0") { conAlertas = true; }
            if (citaActive?.profesionalDelay !== "0") { conAlertas = true; }
            if (citaActive?.pagada) { conAlertas = true; }
            if (citaActive?.comentarios?.length > 0) { conAlertas = true; }
            if (usuarioActivo?.comentarios?.length > 0) { conAlertas = true; }
            setHasAlertas(conAlertas);
        }
        if (citaActive && citaActive?.comentarios?.length > 0) {
            if (citaActive?.comentarios[citaActive?.comentarios?.length - 1]?.business === true) {
                setTxtComentario(citaActive?.comentarios[citaActive?.comentarios?.length - 1]?.texto);
                setComentariosRespondidos(true);
            }
            setComentariosCita([...citaActive?.comentarios]);
        }
    }, [citaActive, usuarioActivo]);

    useEffect(() => {
        if (grabaComentarios) {
            setGrabaComentarios(false);
            clickReservar(true);
            dispatch(openModalBottom({ text: ['Comentarios guardados correctamente'], timeOut: 3 }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grabaComentarios]);

    useEffect(() => {
        if (editCita) {
            setActiveModalReserva(true);
            if (servicioActive) {
                if(citaActive){
                    handleServicioSelected(servicioActive);
                    handleVariantesChanged(getVariantes(citaActive));
                    handleSucursalSelected(sucursalActive);
                    setFirstProfesional(profesionalActive._id);
                    handleProfesionalSelected(profesionalActive);
                    setSelectDate({ ...selectDate, visible: true, date: citaActive.fecha });
                    setFechaCalendar(new Date(citaActive.fecha));
                    setSelectTime({ ...selectTime, visible: true, date: citaActive.fecha, touched: true });
                    setTimeCalendar(new Date(citaActive.fecha));
                    recalculaPromociones();
                }
            } else {
                if(citaActive){
                    handleServicioSelected(citaActive.servicios[0]);
                    handleVariantesChanged(getVariantes(citaActive));
                    handleSucursalSelected(citaActive.sucursal);
                    setFirstProfesional(citaActive.profesional._id);
                    handleProfesionalSelected(citaActive.profesional);
                    setSelectDate({ ...selectDate, visible: true, date: citaActive.fecha });
                    setFechaCalendar(new Date(citaActive.fecha));
                    setSelectTime({ ...selectTime, visible: true, date: citaActive.fecha, touched: true });
                    setTimeCalendar(new Date(citaActive.fecha));
                    recalculaPromociones();
                }
            }
        } else {
            const promocionesValidas = promociones.filter(promo => moment(promo.fechaInicio).isSameOrBefore(moment()) && moment(promo.caducidad).isSameOrAfter(moment()));
            promocionesValidas.forEach(item => {
                item.selected = false;
            });
            setPromocionesFiltradas(promocionesValidas);
            //setSelectDate({ ...selectDate, visible: true, date: selectedDate });
            //console.log("selectedDate: ", selectedDate);
            //console.log("selectDate: ", selectDate);
        }

        //setSelectedVariantes(citaActive?.variantes ?? [])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editCita, promociones]);

    useEffect(() => {
        if (usuarioActivo) {
            setSelectUsuario({
                _id: usuarioActivo._id,
                selected: usuarioActivo,
                visible: true,
                active: false
            });
        }
    }, [usuarioActivo]);

    useEffect(() => {
        dispatch(setDarkHeader(true));
    }, [dispatch]);

    useEffect(() => {
        recalculaPromociones();
        getMorningAfternoon();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectProfesional.selected, fechaCalendar, profesionales, selectedVariantes, selectServicio.selected]);

    useEffect(() => {
        if (selectedServices && selectSucursal.selected) {
            getProfesionales(selectedServices, selectSucursal.selected, promociones, rdProfesionales, rdSucursales)
                .then(result => {
                    setProfesionales(result);
                    if(result.length>1){
                        getProfesionalesDisabled(rdProfesionales, result).then(profesionalsDisabled =>{
                            setProfesionalesDisabled(profesionalsDisabled);
                        });
                    }else{
                        if(result.length==1) handleProfesionalSelected(result[0]);
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectSucursal.selected]);

    useEffect(() => {
        setPromocionesFiltradas(promociones);
    }, [promociones]);

    useEffect(() => {
        let errores = false;
        if (!usuarioActivo) {
            errores = true;
        }
        if (!selectServicio.selected) {
            errores = true;
        }
        if (!selectSucursal.selected) {
            errores = true;
        }
        if (!selectProfesional.selected) {
            errores = true;
        }
        if (timeCalendar.toISOString() === '1972-11-12T23:00:00.000Z') {
            errores = true;
        }

        setRegisterFormOk(!errores);

    }, [usuarioActivo, selectServicio, selectSucursal, selectProfesional, timeCalendar]);

    useEffect(() => {
        if (selectTime.touched) {
            recalculaPromociones();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectTime]);
    
    useEffect(() => {
        if (editCita && modalWaiting._id) {
            if (modalResponse === 1) {
                dispatch(startSaveCita(modalWaiting)).then(() => {
                    setModalResponse(100);
					setSelectedServices([]);
                    if(fromCliente)
					    dispatch(activeUsuario(history.location.state.usuario._id, history.location.state.usuario));
                })
            } else {
                setModalResponse(200);
            }

            setModalWaiting({});
            closeModal(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalResponse]);

    useEffect(() => {
        if (servicios.length > 0 && selectedServices.length==0) {
            setFilteredServices(servicios);
        }
    }, [servicios, selectedServices]);

    useEffect(() => {
        if (selectedServices.length>0) {
            filterServices();
        }
    }, [selectedServices]);

    useEffect(() => {
      setFechaCalendar(moment(selectedDate).toDate());
    }, [selectedDate])
    

    const getVariantes = (cita)=>{
        if (Array(cita?.variantes) && (typeof cita?.variantes[0] === 'string')){
            // Transformar el número en un objeto
            const variante = variantes.filter(x=>x._id === cita?.variantes[0]);
            return variante;
        }
        return cita?.variantes ?? [];
    }

    const filterServices = () => {
        let selectedServicesTotal = [];
        if (selectedServices.length) {
            selectedServicesTotal = [
                ...selectedServices
            ];

            if(selectedServicesTotal[selectedServices.length - 1].complementarios){
                selectedServicesTotal[selectedServices.length - 1].complementarios.forEach(complementario => {
                    const newService = getServicio(complementario);
                    
                    if (!newService) return;
                    
                    if (selectedServicesTotal.some(service => service._id === newService._id)) return;
                    
                    selectedServicesTotal = [
                        ...selectedServicesTotal,
                        newService
                    ]
                });
            }
        }

        setFilteredServices(selectedServicesTotal);
    };

    const getMorningAfternoon = async () => {
        // Sumar Duración de los servicios seleccionados.
        const sumaDuracion = selectedServices.reduce((total, service) => {
                            return total + parseInt(service.duracion, 10);
                         }, 0);
        const servicesIds = selectedServices.flatMap(service => service._id);
        let variantes = selectedVariantes.reduce((acc, v) => acc + parseInt(v.tiempo), 0);
        if(isNaN(variantes)) variantes = 0;

        if (selectProfesional.selected) {
            const params = {
                month: fechaCalendar.getMonth(),
                year: fechaCalendar.getFullYear(),
                empresa,
                profesionalId: selectProfesional.selected._id === "333" ? "random" : selectProfesional.selected._id,
                sucursalId: selectSucursal.selected._id,
                sucursales: rdSucursales,
                //serviciosId: [selectServicio.selected._id],
                serviciosId: servicesIds,
                totalTime: sumaDuracion + variantes,
                intervaloTiempo,
				citaIdExcluir: citaActive?._id
            }

            const result = await fetchAvailableSlots(params)

            const selectedDay = moment(fechaCalendar).format("YYYY-MM-DD")

            const slots = result[selectedDay]?.map(slot => {
                const date = moment(slot.start, "hh:mm A");
                date.date(fechaCalendar.getDate());
                date.month(fechaCalendar.getMonth());
                date.year(fechaCalendar.getFullYear());

                return {
                    available: true,
                    date: date.toDate(),
                    profesional: profesionales.find(p => p._id === slot.profesional),
                    promocion: false
                }
            }) ?? [];

            setMorning(slots);
            setAfternoon([]);
        }
    }

    const resetForm = () => {
        if (!fromCliente) {
            dispatch(unactiveUsuario());
        }
        dispatch(unactiveServicio());
        setSelectUsuario({
            ...selectUsuario,
            selected: null,
            active: false
        });
        setSelectServicio({
            ...selectServicio,
            selected: null,
            active: false
        });
        setSelectedServices([]);
        setSelectSucursal({
            ...selectSucursal,
            selected: null,
            visible: false,
        });
        setSelectProfesional({
            ...selectProfesional,
            selected: null,
            visible: false,
            cambiado: (fromGestion) ? true : false
        });
        setSelectDate({
            ...selectDate,
            visible: false
        });
        setSelectTime({
            ...selectTime,
            visible: false,
            touched: false
        });
        setSelectPromocion({
            promociones: [],
            visible: false,
            active: false
        });
        //setFechaCalendar(new Date());
        setFechaCalendar(moment(selectedDate).toDate());
        setTimeCalendar(new Date(1972, 10, 13, 0, 0));
        setPromocionesFiltradas(promociones);
    };

    const handleUsuarioSelected = usuario => {
        dispatch(activeUsuario(usuario._id, usuario));

        setSelectUsuario({
            _id: usuario._id,
            selected: usuario,
            visible: true,
            active: false
        });
    };

    const handleServicioSelected = servicio => {
        dispatch(activeServicio(servicio._id, servicio));
        const service = servicios.filter(x=>x._id===servicio._id);
        setSelectedServices(service);
        setSelectServicio({
            _id: servicio._id,
            selected: servicio,
            visible: true,
            active: false
        });
        setSelectSucursal({
            ...selectSucursal,
            selected: null,
            visible: true,
        });
        setSelectProfesional({
            ...selectProfesional,
            selected: null,
            visible: false
        });
        setSelectDate({
            ...selectDate,
            visible: false
        });
        setSelectTime({
            ...selectTime,
            visible: false,
            touched: false
        });
        setSelectPromocion({
            promociones: [],
            visible: false,
            active: false
        });

        getSucursales(servicio, rdProfesionales, horarios, rdSucursales)
            .then(result => {
                setSucursales(result);
        });

		
    };

    const handleServicesChanged = (services) => {
        //handleComplementServices(services);
        setSelectedServices(services);
        if(rdSucursales.length>1 || services.length==0){
            setSelectSucursal({
                ...selectSucursal,
                selected: null,
                visible: true,
            });
            setSelectProfesional({
                ...selectProfesional,
                selected: null,
                visible: false
            });
        }
        setSelectDate({
            ...selectDate,
            visible: false
        });
        setSelectTime({
            ...selectTime,
            visible: false,
            touched: false
        });
        setSelectPromocion({
            promociones: [],
            visible: false,
            active: false
        });

        getSucursales(services, rdProfesionales, horarios, rdSucursales)
            .then(result => {
                setSucursales(result);
        });

		//TODO: (TONNY) Aqui deberíamos filtrar el array "servicios" para mostrar solamente los
		//servicios relacionados con los servicios seleccionados.
		//En el array de servicios deben quedar aquellos servicios que son complementarios de todos 
		//los servicios seleccionados.
    }

    const getServicio = (id) => {
        let retorno = null;
        servicios.forEach(servicio => {
            if (servicio._id === id) {
                retorno = servicio;
            }
        });
        return retorno;
    }

    const handleComplementServices = (selectedServices)=>{
        if(selectedServices.length>0){
            selectedServices[selectedServices.length - 1].complementarios.forEach(complementario => {
                const newService = getServicio(complementario);

                if (!newService) return;

                if (selectedServices.some(service => service._id === newService._id)) return;

                selectedServices = [
                    ...selectedServices,
                    newService
                ]
            });
        }
    }

    const handleVariantesChanged = (variantes) => {
        setSelectedVariantes(variantes);
        if(rdSucursales.length>1){
            setSelectSucursal({
                ...selectSucursal,
                selected: null,
                visible: true,
            });
            setSelectProfesional({
                ...selectProfesional,
                selected: null,
                visible: false
            });
            setSelectDate({
                ...selectDate,
                visible: false
            });
            setSelectTime({
                ...selectTime,
                visible: false,
                touched: false
            });
            setSelectPromocion({
                promociones: [],
                visible: false,
                active: false
            });
        }

    }

    const handleSucursalSelected = sucursal => {
        setSelectSucursal({
            ...selectSucursal,
            visible: true,
            selected: sucursal,
            active: false,
        });
        setSelectProfesional({
            ...selectProfesional,
            selected: null,
            visible: true
        });
        setSelectDate({
            ...selectDate,
            visible: false
        });
        setSelectTime({
            ...selectTime,
            visible: false,
            touched: false
        });
        setSelectPromocion({
            promociones: [],
            visible: false,
            active: false
        });
    };

    const handleProfesionalSelected = profesional => {         
        setSelectProfesional({
            ...selectProfesional,
            visible: true,
            selected: profesional,
            active: false
        });
        setSelectDate({
            ...selectDate,
            visible: true
        });
        setSelectTime({
            ...selectTime,
            visible: true,
            touched: false
        });
        setSelectPromocion({
            promociones: [],
            visible: true,
            active: false
        });
        setTimeCalendar(new Date(1972, 10, 13, 0, 0));
    };

    const handlePromocionSelected = promocion => {

    };

    const closeModal = (e, deleteCita = false) => {
        const valorCambiado = (fromGestion) ? true : false;
        const newProfesional = {
            ...selectProfesional,
            cambiado: valorCambiado
        };
        if(fromGestion){
            setSelectProfesional(newProfesional);
            setFormErrores({});
            
            resetForm();
            setPromocionesFiltradas(promociones);
            if (!deleteCita && !fromCliente) {
                dispatch(unactiveCita());
                dispatch(usuarioLogout());
            }
            dispatch(unactiveServicio());
            dispatch(unactiveSucursal());
            dispatch(unactiveProfesional());
            setTxtComentario('');
            setComentariosCita([]);
            setAlertasActive(false);
            setEditActive(false);
        }
		setEditCita(false);		
		setEditActive(false);
        setActiveModalReserva(false);
    };

    const handleEditCita = () => {
        
        const fecha = moment(fechaCalendar).format('DD MM YYYY');
        const hora = moment(timeCalendar).format('HH:mm');

        const fechaDef = moment(`${fecha} ${hora}`, 'DD MM YYYY HH:mm').toISOString();

        const newCualquiera = ((editCita && firstProfesional === selectProfesional.selected._id) ? citaActive.isProfesionalRandom : (selectProfesional.selected._id !== '333') ? false : true);

		const sumaDuracion = selectedServices.reduce((total, service) => {
			return total + parseInt(service.duracion, 10);
		}, 0);
		
		const userId = citaActive?.usuario?._id ? citaActive?.usuario._id : citaActive?.usuario;

        //const variantesSelected = variantes	

        // Convertir el tiempo a número y sumar
        const totalVariantes = selectedVariantes.reduce((total, varianteSelected) => {
            const variante = variantes.find(v => v._id === varianteSelected._id);
                return total + (variante ? parseInt(variante.tiempo, 10) : 0);
        }, 0);

        const reserva = {
            empresa,
            ...citaActive,
			usuario: userId,			
            sucursal: selectSucursal.selected._id,
            profesional: (selectProfesional.selected._id !== '333' ? selectProfesional.selected._id : profSelected._id),                  
			servicios: selectedServices.map(servicio => servicio._id),            
            fecha: fechaDef,
            isProfesionalRandom: newCualquiera,
            internal: true,
            duracion: parseInt(sumaDuracion) + totalVariantes,
			//duracion: parseInt(sumaDuracion) + selectedVariantes.reduce((acc, v) => acc + parseInt(v.tiempo), 0),
        };

        let descuentos = [];
		promocionesFiltradas.forEach(item => {
			if (item.selected && item.sucursales.includes(reserva.sucursal)) {
				const newDescuento = {
					_id: item._id,
					descripcion: item.titulo,
					importe: calculaImporteDescuentos(item, selectedServices),					
				}
				descuentos = [
					...descuentos,
					newDescuento
				]
			}
		});
		
		let precioReserva = selectedServices.reduce((total, service) => {
			return total + parseInt(service.precio, 10);
			}, 0);
		descuentos.forEach(descuento => {
			precioReserva -= parseFloat(descuento.importe);
		});

		selectedVariantes.forEach(v => {
			precioReserva += v.valor;
		});

        reserva.variantes = selectedVariantes.map(v => v._id);

        reserva.descuentos = descuentos;
        reserva.comentarios = comentariosCita;
        reserva.importe = precioReserva / 100;
		reserva.uid = uid;

        if (usarSockets) {
            socket?.emit('grabar-cita', { uid, reserva, internal: true });
        } else {
            setModalWaiting(reserva);
            setModalTwoButtonsOpened(true);
        }
		
    }

    const clickReservar = (editar) => {

        //if (!registerFormOk) return;
        const fecha = moment(fechaCalendar).format('DD MM YYYY');
        const hora = moment(timeCalendar).format('HH:mm');

        const fechaDef = moment(`${fecha} ${hora}`, 'DD MM YYYY HH:mm').toISOString();

        let reservaOk = true;
        let errores = {
            cliente: false,
            servicio: false,
            sucursal: false,
            profesional: false,
            hora: false
        };

        if (!usuarioActivo && fromGestion) {
            errores.cliente = true;
            reservaOk = false;
        }
        //if (!selectServicio.selected) {
        if (!selectedServices.length>0) {
            errores.servicio = true;
            reservaOk = false;
        }
        if (!selectSucursal.selected) {
            errores.sucursal = true;
            reservaOk = false;
        }
        if (!selectProfesional.selected) {
            errores.profesional = true;
            reservaOk = false;
        }
        if (timeCalendar.toISOString() === '1972-11-12T23:00:00.000Z') {
            errores.hora = true;
            reservaOk = false;
        }
        if (reservaOk) {
            const newCualquiera = ((editCita && firstProfesional === selectProfesional.selected._id) ? citaActive.isProfesionalRandom : (selectProfesional.selected._id !== '333') ? false : true);
            const sumaDuracion = selectedServices.reduce((total, service) => {
                return total + parseInt(service.duracion, 10);
            }, 0);
            
            const reserva = {
                empresa,
                sucursal: selectSucursal.selected._id,
                profesional: (selectProfesional.selected._id !== '333' ? selectProfesional.selected._id : profSelected._id),
                usuario: usuarioActivo._id,                
                servicios: selectedServices.map(servicio => servicio._id),
                fecha: fechaDef,
                isProfesionalRandom: newCualquiera,
                internal: true,                
                duracion: parseInt(sumaDuracion) + selectedVariantes.reduce((acc, v) => acc + parseInt(v.tiempo), 0),
            };
            let descuentos = [];
            promocionesFiltradas.forEach(item => {
                if (item.selected && item.sucursales.includes(reserva.sucursal)) {
                    const newDescuento = {
                        _id: item._id,
                        descripcion: item.titulo,
                        importe: calculaImporteDescuentos(item, selectedServices),                        
                    }
                    descuentos = [
                        ...descuentos,
                        newDescuento
                    ]
                }
            });

            //let precioReserva = parseFloat(selectServicio.selected.precio);
            let precioReserva = selectedServices.reduce((total, service) => {
                return total + parseInt(service.precio, 10);
             }, 0);
            descuentos.forEach(descuento => {
                precioReserva -= parseFloat(descuento.importe);
            });

            selectedVariantes.forEach(v => {
                precioReserva += v.valor;
            });

            reserva.variantes = selectedVariantes.map(v => v._id);

            reserva.descuentos = descuentos;
            reserva.comentarios = comentariosCita;
            reserva.importe = precioReserva / 100;

            if (reserva.profesional !== '333') {
                if (usarSockets) {
                    socket?.emit('crear-cita', { uid, reserva, internal: true });
                } else {
                    dispatch(startNewCita(reserva)).then(() => {
                        setModalResponse(100);
                    })
                }
                closeModal(null);
            }
        } else {
            setFormErrores(errores);
        }
    }

    const clickEliminar = (cita) => {
        dispatch(activeCita(cita._id, cita));
        //if (citaActive && fromGestion) {
        setEstadoCita('');
        setActiveModalDeleteCita(true);
        closeModal(null, true);
        /*}
        if (citaActive && !fromGestion) {
            deleteCita(citaActive);
            closeModal(null, true);
        }*/
    }

    const clickNoPresentado = () => {
        //if (citaActive && fromGestion) {
            setEstadoCita('No presentado');
            setActiveModalDeleteCita(true);
            closeModal(null, true);
        /*}
        if (citaActive && !fromGestion) {
            deleteCita(citaActive);
            closeModal(null, true);
        }*/
    }

    const calculaImporteDescuento = (promocion, servicio) => {
        let retorno = '0';
        if (promocion.cifra) {
            retorno = promocion.cifra;
        } else {
            const precioServicio = parseFloat(servicio.precio);
            if (!isNaN(precioServicio)) {
                retorno = (precioServicio * ((promocion.porcentaje / 100) / 100)).toString()
            }
        }
        return retorno;
    };

    const calculaImporteDescuentos = (promocion, servicios) => {
        let retorno = '0';
        if (promocion.cifra) {
            retorno = promocion.cifra;
        } else {
            let acumRetorno = 0;
            for (let i = 0; i < servicios.length; i++) {
                const precioServicio = parseFloat(servicios[i].precio);
                if (!isNaN(precioServicio)) {
                    acumRetorno += (precioServicio * ((promocion.porcentaje / 100) / 100))
                }    
            }
            retorno = acumRetorno.toString();
        }
        return retorno;
    };

    const sendRespuesta = () => {
        if (txtComentario.trim() === '') {
            alert('Debe escribir en el campo comentario');
            return;
        }
        const newComentario = {
            texto: txtComentario,
            leido: false,
            usuario: citaActive.usuario._id,
            business: true
        }
        setComentariosCita([
            ...comentariosCita,
            newComentario
        ]);
        setGrabaComentarios(true);
    };

    const recalculaPromociones = () => {
        if (servicioActive && selectTime.touched) {
            let promocionesValidas = promociones.filter(promo => {
                const fechaInicio = moment(promo.fechaInicio);
                const caducidad = moment(promo.caducidad);
                let fechaCita = moment(fechaCalendar);
                if (citaActive) {
                    fechaCita = moment(citaActive.fecha);
                }

                const valida = !promo.deleted && promo.activo && fechaInicio.isSameOrBefore(fechaCita) && caducidad.isSameOrAfter(fechaCita) && promo.servicios.includes(selectServicio.selected?._id) && promo.sucursales.includes(selectSucursal.selected?._id) && (promo.profesionales.includes(selectProfesional.selected?._id) || (promo.profAleatorio && selectProfesional.selected?._id === '333')) && compruebaHorariosPromocion(promo);

                return valida;
            });

            if (citaActive) {
                let nuevosIds = [];
                citaActive.descuentos.forEach(descuento => {
                    let existe = false;
                    promocionesValidas.forEach(promo => {
                        if (promo._id === descuento._id) {
                            existe = true;
                        }
                    });
                    if (!existe) {
                        if (!nuevosIds.includes(descuento._id)) {
                            nuevosIds = [...nuevosIds, descuento._id];
                        }
                    }
                });
                if (nuevosIds.length > 0) {
                    nuevosIds.forEach(id => {
                        const nuevaPromo = promociones.filter(promo => promo._id === id);
                        if (nuevaPromo.length > 0) {
                            promocionesValidas = [...promocionesValidas, nuevaPromo[0]];
                        }
                    });
                }
            }

            let newPromociones = [];
            if (citaActive?.descuentos && citaActive?.descuentos.length > 0 && fromGestion) {
                promocionesValidas.forEach(item => {
                    let promSelected = false;
                    citaActive.descuentos.forEach(descuento => {
                        if (descuento._id === item._id) {
                            promSelected = true;
                        }
                    });
                    item.selected = promSelected;
                    newPromociones = [
                        ...newPromociones,
                        item
                    ]
                });
            } else {
                newPromociones = promocionesValidas;
            }
            setPromocionesFiltradas(newPromociones);
        }
    }

    const compruebaHorariosPromocion = (promo) => {
        let estaEnHorario = true;
        let fechaCita = moment(timeCalendar);
        const weekDay = fechaCita.weekday().toString();
        fechaCita = fechaCita.set({ 'year': 2021, 'month': 2, 'date': 19 });

        if (promo.horarioEspecial) {
            estaEnHorario = false;
            const horarios = promo.horariosPromocion;
            horarios.forEach(dia => {
                const mismoDia = dia.diasSemana === weekDay;
                if (mismoDia && dia.horas.length > 0) {
                    estaEnHorario = dia.horas.filter(hora => {
                        const horaInicio = moment(hora.horaInicio).set({ 'year': 2021, 'month': 2, 'date': 19 });
                        const horaFin = moment(hora.horaFin).set({ 'year': 2021, 'month': 2, 'date': 19 });
                        const respuesta = fechaCita.isSameOrAfter(horaInicio) && fechaCita.isBefore(horaFin);
                        return respuesta;
                    }).length > 0 ? true : false;
                };
            });
        }

        return estaEnHorario;
    }
	const historyPath = ()=> { 		
			if(!usuarioActivo) return		
			return {pathname: `/cliente/${usuarioActivo._id}`, state: { usuario: usersLocal.find(x=>x._id == usuarioActivo._id) }
		}
	}

    return (
        <div
            className={`modal-reserva ${(activeModalReserva) ? 'active' : ''}`}
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div
                className="modal-wrapper"
                style={{ backgroundColor: alertasActive ? '#282B2E' : '#343434' }}
            >
                <div className="modal-title">
                    <div
                        className='btn-title'
                        onClick={() => setAlertasActive(false)}
                    >
                        <h3 style={{ color: colores.colorPrincipal }}>{`${(editCita) ? 'Reserva' : 'Nueva reserva'}`}</h3>
                    </div>
                    {
                        hasAlertas &&
                        <div
                            className='btn-alerts'
                            style={{ backgroundColor: alertasActive ? '#343434' : '#282B2E' }}
                            onClick={() => setAlertasActive(true)}
                        >
                            <AlertasIcon color={colores.colorPrincipal} />
                        </div>
                    }
                    <div
                        className="btn-close"
                        style={{ backgroundColor: `#282B2E` }}
                        onClick={closeModal}
                    >
                        <CloseIcon color={colores.colorPrincipal} />
                    </div>
                </div>
                {
                    (!editActive && editCita && citaActive) &&
                    <div className='modal-info-body'>
                        <div className='modal-info-rows'>
                            <div className='modal-info-row'>
                                <div className='icon'><ClientesIcon color="white" /></div>
                                <p className='title'>Cliente:</p>
                                {usersLocal.length>0 && <Link to={historyPath()}>
                                    <p className='info'>{usuarioActivo?.nombre}</p>
                                    <p className='info'>{usuarioActivo?.apellidos}</p>
                                </Link>}
                            </div>
                            <div className='modal-info-row'>
                                <div className='icon'><TelephoneIcon color="white" width='15' height='15' /></div>
                                <p className='title'>Teléfono:</p>
                                <p className='info'><a href={`tel:${usuarioActivo?.telefono}`} style={{color: 'white'}}>{usuarioActivo?.telefono}</a></p>
                            </div>
                            <div className='modal-info-row'>
                                <div className='icon'><ServiciosIcon color="white" width='15' height='17' /></div>
                                {citaActive.servicios.length > 1 && <p className='title'>Servicios:</p>}
                                {citaActive.servicios.length === 1 && <p className='title'>Servicio:</p>}
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}>
                                    {
                                        citaActive.servicios.map((servicio) => (
                                            <p key={servicio._id} className='info'>
                                                {citaActive.servicios.length > 1 && "• "}{servicio.nombre}
                                            </p>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className='modal-info-row'>
                                <div className='icon'><VariantesIcon color="white" width='15' height='17' /></div>
                                {citaActive.variantes.length > 1 && <p className='title'>Variantes:</p>}
                                {citaActive.variantes.length <= 1 && <p className='title'>Variante:</p>}
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}>
                                    {
                                        citaActive.variantes.map((variante) => (
                                            <p key={variante._id} className='info'>
                                                {citaActive.variantes.length > 1 && "• "}{variante.nombre}
                                            </p>
                                        ))
                                    }
                                    {!citaActive.variantes.length && <p className='info'>No hay variantes.</p>}
                                </div>
                            </div>
                            <div className='modal-info-row'>
                                <div className='icon'><SucursalesIcon color="white" width='15' height='17' /></div>
                                <p className='title'>Sucursal:</p>
                                <p className='info'>{citaActive.sucursal.nombre}</p>
                                {/* <p className='info'>{sucursalActive.nombre}</p> */}
                            </div>
                            <div className='modal-info-row'>
                                <div className='icon'><ProfesionalesIcon color="white" width='15' height='17' /></div>
                                <p className='title'>Profesional:</p>
                                <p className='info'>{citaActive.profesional.nombre}</p>
                                {/* <p className='info'>{profesionalActive.nombre}</p> */}
                            </div>
                            <div className='modal-info-row'>
                                <div className='icon'><ReservasIcon color="white" width='15' height='17' /></div>
                                <p className='title'>Fecha:</p>
                                <p className='info'>{moment(citaActive.fecha).format('DD/MM/YYYY')}</p>
                            </div>
                            <div className='modal-info-row'>
                                <div className='icon'><ClockAlertIcon color="white" width='15' height='17' /></div>
                                <p className='title'>Hora:</p>
                                <p className='info'>{moment(citaActive.fecha).format('H:mm')}h.</p>
                            </div>
                            <div className='modal-info-row'>
                                <div className='icon'><PreciosEspecialesIcon color="white" /></div>
                                <p className='title'>Descuentos:</p>
                                {
                                    citaActive.promocion.length > 0 ?
                                        promociones.filter(p => citaActive.promocion.includes(p._id)).map((promocion) => (
                                            <p key={promocion._id} className='info'>
                                                {promocion.titulo} -{promocion.porcentaje ? promocion.porcentaje / 100 + '%' : promocion.cifra / 100 + '€'}
                                            </p>
                                        ))
                                        :
                                        <p className='info'>No</p>
                                }
                            </div>
                        </div>
                        <footer className='modal-info-footer'>
                            <div className='btn-row multiple'>
                                {/* <div className='info-btn pay'><EuroIcon width="12" height='16'/></div> */}
                                <div
                                    className='info-btn'
                                    onClick={() => setEditActive(true)}
                                ><EditarIcon color="white" width="12" height='16' /></div>
                                <div
                                    className='info-btn'
                                    onClick={() => clickEliminar(citaActive)}
                                >
                                    <TrashIcon color="white" width="17" height='18' />
                                </div>
                            </div>
                            <div className='btn-row single'>
                                <div
                                    className='no-date'
                                    onClick={() => clickNoPresentado()}
                                >No se ha presentado a la cita</div>
                            </div>
                        </footer>
                    </div>
                }
                {(editActive || !editCita) &&
                    <div className="modal-body">
                        {
                            (fromGestion)
							?
                            <DropdownClientes
                                usuarios={usuarios}
                                texto="Selecciona cliente"
                                handleUsuarioSelected={handleUsuarioSelected}
                                selectUsuario={selectUsuario}
                                editCita={editCita}
                            />
							:
							<div className='modal-info-row'>                                
                                <p className='title'>Cliente:</p>
                                {usersLocal.length>0 && <Link to={historyPath()}>
                                    <p className='info'>&nbsp;{usuarioActivo?.nombre} {usuarioActivo?.apellidos}</p>                                    
                                </Link>}
                            </div>
                        }
                        {                            
                            <div className="brb-text">
                                <a href={`tel:+34${usuarioActivo?.telefono}`}>{usuarioActivo?.telefono ? usuarioActivo?.telefono : "Teléfono del cliente"}</a>
                            </div>
                        }
                        <DropdownMultipleServicios
                            services={filteredServices}
                            texto="Selecciona un servicio"
                            handleServicesChanged={handleServicesChanged}
                            selectedServices={selectedServices}
                            descuentosServicio={0}
                            descuentosProfesional={0}
                            descuentosHorario={0}
                            editCita={editCita}
                        />

                        <MultiSelectionVariantes
                            variantes={variantes}
                            //selectedServicio={selectServicio}
                            selectedServicio={selectedServices}
                            selectedVariantes={selectedVariantes}
                            handleVariantesChanged={handleVariantesChanged}
                        />

                        {
                            citaActive?.pagada &&
                            <div className='cita-pagada'>
                                <PagoAdelantadoIcon />
                                <p>Pagado por adelantado</p>
                            </div>
                        }
                        <DropdownSucursales
                            //selectServicio={selectServicio}
                            selectServicio={selectedServices}
                            sucursales={sucursales}
                            texto="Selecciona la sucursal"
                            handleSucursalSelected={handleSucursalSelected}
                            selectSucursal={selectSucursal}
                            editCita={editCita}
                        />
                        <DropdownProfesionales
                            selectServicio={selectedServices}
                            selectSucursal={selectSucursal}
                            profesionales={profesionales}
                            profesionalesDisabled={profesionalesDisabled}
                            texto="Selecciona profesional"
                            handleProfesionalSelected={handleProfesionalSelected}
                            selectProfesional={selectProfesional}
                            citaActive={citaActive}
                            fromGestion={fromGestion}
                        />
                        <DropdownDate
                            selectDate={selectDate}
                            setSelectDate={setSelectDate}
                            fechaCalendar={fechaCalendar}
                            setFechaCalendar={setFechaCalendar}
                            timeCalendar={timeCalendar}
                            setTimeCalendar={setTimeCalendar}
                        />
                        <DropdownTime
                            activeModalReserva={activeModalReserva}
                            selectTime={selectTime}
                            setSelectTime={setSelectTime}
                            timeCalendar={timeCalendar}
                            setTimeCalendar={setTimeCalendar}
                            selectServicio={selectServicio}
                            selectSucursal={selectSucursal}
                            profesionales={profesionales}
                            setProfesionales={setProfesionales}
                            selectProfesional={selectProfesional}
                            setProfSelected={setProfSelected}
                            fechaCalendar={fechaCalendar}
                            setFechaCalendar={setFechaCalendar}
                            descuentosHorario={descuentosHorario}
                            setDescuentosHorario={setDescuentosHorario}
                            morning={morning}
                            afternoon={afternoon}
                            editCita={editCita}
                            fromAdmin={true}
                        />
                        {
                            (fromGestion)
                            &&
                            <DropdownPromociones
                                promocionesFiltradas={promocionesFiltradas}
                                setPromocionesFiltradas={setPromocionesFiltradas}
                                handlePromocionSelected={handlePromocionSelected}
                                selectPromocion={selectPromocion}
                                selectSucursal={selectSucursal}
                                selectTime={selectTime}
                                editCita={editCita}
                                citaActive={citaActive}
                            />
                        }
                        {
                            (editCita)
                                ?
                                <>
                                    <div
                                        className="btn btn-reservar"
                                        style={{ backgroundColor: colores.colorPrincipal }}
                                        onClick={handleEditCita}
                                    >
                                        <p>Cambiar reserva</p>
                                    </div>
                                    <div
                                        className="btn btn-eliminar"
                                        style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                                        onClick={() => clickEliminar()}
                                    >
                                        <p>Eliminar reserva</p>
                                    </div>
                                </>
                                :
                                // <div
                                //     className="btn btn-editar"
                                //     style={{
                                //         backgroundColor: colores.colorPrincipal,
                                //         opacity: registerFormOk ? 1 : 0.5,
                                //         cursor: registerFormOk ? 'pointer' : 'inherit'
                                //     }}
                                //     onClick={() => clickReservar(false)}
                                // >
                                //     <p>Crear reserva nueva</p>
                                // </div>
                                <div>
                                    <button type="button" className="btn btn-editar"
                                        style={{
                                            backgroundColor: colores.colorPrincipal,
                                            opacity: registerFormOk ? 1 : 0.5,
                                            cursor: registerFormOk ? 'pointer' : 'inherit'
                                        }}
                                        onClick={() => clickReservar(false)}> Crear reserva nueva
                                    </button>
                                </div>
                        }
                        <div
                            className="errores"
                        >
                            <div style={{ backgroundColor: colores.colorPrincipal }} className={`error ${(formErrores.cliente) ? 'active' : ''}`}>Selecciona un cliente</div>
                            <div style={{ backgroundColor: colores.colorPrincipal }} className={`error ${(formErrores.servicio) ? 'active' : ''}`}>Selecciona un servicio</div>
                            <div style={{ backgroundColor: colores.colorPrincipal }} className={`error ${(formErrores.sucursal) ? 'active' : ''}`}>Selecciona una barbería</div>
                            <div style={{ backgroundColor: colores.colorPrincipal }} className={`error ${(formErrores.profesional) ? 'active' : ''}`}>Selecciona un profesional</div>
                            <div style={{ backgroundColor: colores.colorPrincipal }} className={`error ${(formErrores.hora) ? 'active' : ''}`}>Selecciona una hora</div>
                        </div>
                    </div>
                }
                {
                    alertasActive &&
                    <div
                        className='alerts-body'
                        style={{ backgroundColor: !alertasActive ? '#282B2E' : '#343434' }}
                    >
                        {
                            citaActive?.isProfesionalRandom &&
                            <div className='alert-item random'>
                                <div className='alert-icon'>
                                    <RandomIcon color={'#ffffff'} bgColor={'#343434'} />
                                </div>
                                <div className='alert-content'>
                                    <p>Profesional aleatorio: <span style={{ color: colores.colorSecundario }}>{citaActive?.profesional?.nombre}</span></p>
                                </div>
                            </div>
                        }
                        {
                            citaActive?.usuarioDelay !== "0" &&
                            <div className='alert-item client-delay'>
                                <div className='alert-icon'>
                                    <DelayIcon color={'#ffffff'} bgColor={'#343434'} />
                                </div>
                                <div className='alert-content'>
                                    <p>Cliente con delay asociado: <span style={{ color: colores.colorSecundario }}>{citaActive?.usuarioDelay} {`${citaActive?.usuarioDelayType === 'time' ? 'min.' : '%'}`}</span></p>
                                </div>
                            </div>
                        }
                        {
                            citaActive?.profesionalDelay !== "0" &&
                            <div className='alert-item prof-delay'>
                                <div className='alert-icon'>
                                    <DelayIcon color={'#ffffff'} bgColor={'#343434'} />
                                </div>
                                <div className='alert-content'>
                                    <p>Profesional con delay asociado: <span style={{ color: colores.colorSecundario }}>{citaActive?.profesionalDelay} {`${citaActive?.profesionalDelayType === 'time' ? 'min.' : '%'}`}</span></p>
                                </div>
                            </div>
                        }
                        {
                            usuarioActivo?.comentarios?.length > 0 &&
                            <div className='alert-item observaciones'>
                                <div className='alert-icon'>
                                    <ObservacionesIcon color={'#ffffff'} />
                                </div>
                                <div className='alert-content'>
                                    <p>Observaciones del cliente:</p>
                                    {
                                        usuarioActivo?.comentarios.map(comentario => (
                                            <p key={comentario._id}><span style={{ color: colores.colorSecundario }}>{comentario.texto}</span></p>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                        {
                            citaActive.pagada &&
                            <div className='alert-item pagado'>
                                <div className='alert-icon'>
                                    <EuroIcon color={'#ffffff'} />
                                </div>
                                <div className='alert-content'>
                                    <p>Estado:
                                        <span className='ok-pagado' style={{ color: `#96F8D2` }}>
                                            Pagado <CheckIcon />
                                        </span>
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            comentariosCita.length > 0 &&
                            <div className='alert-item comments'>
                                <div className='alert-icon'>
                                    <CommentsFilledIcon color={'#ffffff'} background={'#343434'} />
                                </div>
                                <div className='alert-content'>
                                    <p>Comentarios de la reserva:</p>
                                    {
                                        (comentariosCita).map((comentario, index) => {
                                            if (!comentario.business) {
                                                return <p key={index}><span style={{ color: colores.colorSecundario }}>{comentario.texto}</span></p>
                                            }
                                            return false;
                                        })
                                    }
                                    <div className='comments-respuesta'>
                                        <p>Respuesta:</p>
                                        {
                                            comentariosRespondidos &&
                                            <textarea
                                                style={{
                                                    backgroundColor: `#343434`,
                                                    color: colores.colorSecundario
                                                }}
                                                value={txtComentario}
                                                placeholder="Escribir comentario"
                                                disabled={true}
                                                onChange={(e) => setTxtComentario(e.target.value)}
                                            ></textarea>
                                        }
                                        {
                                            !comentariosRespondidos &&
                                            <>
                                                <div className='respuesta-item'>
                                                    <div
                                                        className='check-row'
                                                        onClick={() => setRespuestaAutomatica(true)}
                                                    >
                                                        <div
                                                            className='respuesta-check'
                                                            style={{ backgroundColor: `#ffffff` }}
                                                        >
                                                            {
                                                                respuestaAutomatica &&
                                                                <div
                                                                    className='check-selected'
                                                                    style={{ backgroundColor: colores.colorPrincipal }}
                                                                ></div>}
                                                        </div>
                                                        <div className='respuesta-text'>
                                                            <p>Respuesta automática</p>
                                                        </div>
                                                    </div>
                                                    <div className='botonera'>
                                                        <div
                                                            className='botonera-item'
                                                            style={{
                                                                backgroundColor: colores.colorPrincipal,
                                                                color: colores.colorSecundario
                                                            }}
                                                            onClick={(e) => respuestaAutomatica && setTxtComentario(e.target.innerText)}
                                                        ><FingerUpIcon color={colores.colorSecundario} />Ok, lo tenemos en cuenta, ¡gracias!</div>
                                                        <div
                                                            className='botonera-item down'
                                                            style={{
                                                                backgroundColor: colores.colorSecundario,
                                                                color: colores.colorPrincipal
                                                            }}
                                                            onClick={(e) => respuestaAutomatica && setTxtComentario(e.target.innerText)}
                                                        ><FingerDownIcon color={colores.colorPrincipal} />Lo siento, no podrá ser, ¡disculpa!</div>
                                                    </div>
                                                </div>
                                                <div className='respuesta-item'>
                                                    <div
                                                        className='check-row'
                                                        onClick={() => {
                                                            setTxtComentario('');
                                                            setRespuestaAutomatica(false);
                                                        }}
                                                    >
                                                        <div
                                                            className='respuesta-check'
                                                            style={{ backgroundColor: `#ffffff` }}
                                                        >
                                                            {
                                                                !respuestaAutomatica &&
                                                                <div
                                                                    className='check-selected'
                                                                    style={{ backgroundColor: colores.colorPrincipal }}
                                                                ></div>
                                                            }
                                                        </div>
                                                        <div className='respuesta-text'>
                                                            <p>Respuesta personalizada</p>
                                                        </div>
                                                    </div>
                                                    <div className='text-area'>
                                                        <textarea
                                                            style={{
                                                                backgroundColor: `#343434`,
                                                                color: colores.colorSecundario
                                                            }}
                                                            value={txtComentario}
                                                            placeholder="Escribir comentario"
                                                            onChange={(e) => setTxtComentario(e.target.value)}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div
                                                    className='btn-responder'
                                                    style={{
                                                        backgroundColor: colores.colorPrincipal,
                                                        color: '#ffffff'
                                                    }}
                                                    onClick={() => sendRespuesta()}
                                                >
                                                    <p>Enviar respuesta</p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div >
    )
}
