import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sectoresStartDelete, sectorLogout } from '../../actions/sectores';
import { openModalBottom, setMenuLateral } from '../../actions/ui';

import { AddIcon, BuscarIcon, CloseIcon } from '../../assets/svg/iconos';

export const Sectores = ({history}) => {

    const dispatch = useDispatch();
    const { sectores } = useSelector(state => state.sectores);
    const { colores } = useSelector(state => state.ui);

    const [searchActive, setSearchActive] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [sectoresList, setSectoresList] = useState([]);

    const [modalEliminarSectorOpened, setModalEliminarSectorOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar sector?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [selectedId, setSelectedId] = useState('');

    useEffect(() => {
        dispatch( setMenuLateral('sectores') );

        return () => {
            dispatch( sectorLogout() );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    useEffect(() => {
        procesaSectores( sectores, false );
    }, [sectores])
    

    useEffect( () => {
        /* if( searchTxt.trim().length > 3 ){
            dispatch( startSearchEmpresas( searchTxt.trim(), pagNumber | 1, LIMITE_EMPRESAS, orderBy, asc ) );
            setInitialSearch( false );
        } */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTxt]);

    const procesaSectores = ( sectores, filtro = true ) => {
        let newSectores = [];
        //console.log( 'procesaSectores', sectores );
        //console.log( 'totalSectores', totalSectores );
        let contador = 0;
        sectores.forEach( sector => {
            if( !sector.deleted ){
                newSectores = [ ...newSectores, sector ];
                contador++;
            }
            if( filtro ){
                if( contador > 14 ){
                    return;
                }
            }
        });
        // console.log( 'newEmpresas', newEmpresas );
        setSectoresList( newSectores );
    }

    const clickSearch = () => {
        if( searchActive ){
            setSearchTxt('');
        }
        setSearchActive( !searchActive );
    };

    const clickDeleteSearch = () => {
        setSearchTxt('');
    }

    const handleSearchChange = (e) => {
        setSearchTxt( e.target.value );
    }

    const clickAdd = () => {
        console.log('add sector');
    };
    
    const editSector = ( sector ) => {
        console.log('edit sector');
    };

    const deleteSectorModal = ( sectorId, name ) => {
        setSelectedId( sectorId );
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar el sector ${name}?`]
        })
        setModalEliminarSectorOpened( true );
    };

    const deleteSectorResponse = ( value ) => {
        if( value === 1 && selectedId !== '' ){
            // console.log( 'Borrar id:', selectedId );
            dispatch( sectoresStartDelete( selectedId ) );
        }
    }

    return (
        <>
        <div 
            className='empresas'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className="preheader">
                <div className="title">
                    <h1 style={{color: colores.colorPrincipal}}>Sectores</h1>
                </div>
                <div className="botonera">
                    {/* <div 
                        className={`btn-search ${searchActive && 'active'}`}
                    >
                        <div className='search-input'>
                            <input 
                                type={'text'}
                                placeholder={'Buscar por nombre'}
                                value={ searchTxt }
                                onChange={ handleSearchChange }
                            />
                            {
                                searchTxt.trim().length > 2 &&
                                <span className='cantidad'>
                                    ({ sectoresList.length })
                                </span>
                            }
                        </div>
                        {
                            searchTxt.trim().length < 1 &&
                            <div
                                className='icono'
                                onClick={ clickSearch }
                            >
                                <BuscarIcon
                                    color={ colores.colorPrincipal }
                                    className="icon-inverse"
                                />
                            </div>
                        }
                        {
                            searchTxt.trim().length >= 1 &&
                            <div
                                className='icono'
                                onClick={ clickDeleteSearch }
                            >
                                <CloseIcon
                                    color={ colores.colorPrincipal }
                                    className="icon-inverse"
                                />
                            </div>
                        }
                    </div> */}
                    {/* <div 
                        className="btn-add"
                        style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                        onClick={ clickAdd }
                    >
                        <div className='icono'>
                            <AddIcon
                                color={ colores.colorPrincipal }
                            />
                        </div>
                        
                    </div> */}
                </div>
            </div>
            <div className='sectores-list'>
                {
                    sectoresList.map( sector => (
                        <div
                            key={ sector._id } 
                            className='sector-item'
                        >
                            <p>{ sector.nombre }</p>
                        </div>
                    ))
                }
            </div>
        </div>
        {/* <ModalEliminarSector
            data = { dataModalEliminar }
            setModalResponse = { deleteClienteResponse }
            modalOpened = { modalEliminarSectorOpened }
            setModalOpened = { setModalEliminarSectorOpened }
        /> */}
        </>
    )
}
