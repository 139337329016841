import React, { useEffect } from 'react';
import { 
    BrowserRouter as Router,
    Switch,
    Redirect
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';

//import { Gestion } from '../components/gestion/Gestion';
import { AuthRouter } from './AuthRouter';
import { DashboardRoutes } from './DashboardRoutes';
import { YlmModal } from '../components/main/YlmModal';
import { startChecking } from '../actions/auth';
import { YlmModalBottom } from '../components/main/YlmModalBottom';
import { YlmModalTwoButtons } from '../components/main/YlmModalTwoButtons';
import { PublicRouter } from './PublicRouter';
//import { Navbar } from '../components/main/Navbar';
//import { Profesionales } from '../pages/profesionales/Profesionales';

export const AppRouter = () => {

    const dispatch = useDispatch();
    const { uid, empresa, checking } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch( startChecking() );
    }, [empresa, dispatch]);

    if( checking ){
        return <h1>Espere, por favor</h1>
    }

    // TODO: Hay que solucionar lo del router. No acepta rutas escritas a mano
    return (
        <>
        <YlmModal></YlmModal>
        <YlmModalTwoButtons></YlmModalTwoButtons>
        <YlmModalBottom></YlmModalBottom>
        <Router>
            {
                <div 
                    className={`wrapper`}
                >
                    <Switch>
                        <PublicRoute
                            path="/auth" 
                            isAuthenticated={ uid !== undefined }
                            component={ AuthRouter }
                        />
                        <PublicRoute
                            path="/public" 
                            isAuthenticated={ uid !== undefined }
                            component={ PublicRouter }
                        />
                        <PrivateRoute
                            path="/" 
                            isAuthenticated={ uid !== undefined }
                            component={ DashboardRoutes }
                        />
                        <Redirect to="/auth/login" />
                    </Switch>
                </div>
            }
        </Router>
        </>
    )
}
