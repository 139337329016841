import Swal from 'sweetalert2';

import { types } from "../types/types";
import { fetchConToken, archivoConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewProducto = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( 'productos', values, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                /* const datos = {
                    title: 'Creado',
                    text: [ values.nombre ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) ); */
                const datos = {
                    text: [ `El producto ${ values.nombre } ha sido creado` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewProducto( body.producto._id, body.producto ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activeProducto = ( _id, producto ) => ({
    type: types.productosActive,
    payload: {
        _id,
        ...producto
    }
});

export const unactiveProducto = () => ({
    type: types.productosUnactive
});

export const addNewProducto = ( _id, producto ) => ({
    type: types.productosAddNew,
    payload: {
        _id,
        ...producto
    }
})

export const startLoadingProducto = ( id, empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `productos/producto/${ id }`, {empresa}, 'POST' );
            const body = await resp.json();
            if( body.producto ){
                dispatch( activeProducto( body.producto._id, body.producto ));
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingProductos = ( empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'productos/empresa', {empresa}, 'POST' );
            const body = await resp.json();
            dispatch( setProductos( body.productos ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const setProductos = ( productos ) => ({
    type: types.productosLoad,
    payload: productos
})

export const startSaveProducto = ( producto, swal = true ) => {
    return async( dispatch, getState ) => {
        
        try {
            const resp = await fetchConToken( `productos/${ producto._id }`, producto, 'PUT' );
            const body = await resp.json();
            if ( body.ok ) {
                dispatch( refreshProducto( producto._id, body.producto ) );
                const datos = {
                    text: [ `Producto guardado` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            } else {
                console.log( body );
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshProducto = ( id, producto ) => ({
    type: types.productosUpdated,
    payload: {
        ...producto
    }
});

export const startUploading = ( archivo, producto, nombre, previa ) => {

    return async ( dispatch, getState ) => {
        try {           
            const formData = new FormData();
            console.log( archivo );
            formData.append('archivo', archivo);
            formData.append('empresa', producto.empresa);
            formData.append('nombre', nombre);
            formData.append('previa', previa);
            formData.append('file_name', 'archivo');

            //console.log( formData );
    
            await archivoConToken( `productos/upload/${ producto._id }`, formData, 'POST' );
            //const body = await resp.json();

            //console.log( body );

            //Swal.close();
        } catch (error) {
            throw new Error();
        }  

        Swal.close();
    }
};

export const productoStartDelete = ( id, empresa ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `productos/${ id }`, {empresa}, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                dispatch( productoDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

export const productosReorder = ( productosList, empresa ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( `productos/reorder`, {productosList, empresa}, 'POST' );
            const body = await resp.json();
            if ( body.ok ) {
                console.log( 'Reordered' );
            }
            
        } catch (error) {
            console.log(error);            
        }
    }
};

const productoDeleted = ( id ) => ({
    type: types.productosDelete,
    payload: id
});

export const productoLogout = () => ({
    type: types.productosLogoutCleaning
});
