import React, { useCallback, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';

import useClickOutside from '../../../hooks/useClickOutside';
import { HoraInput } from '../../profesionales/components/horario/HoraInput';
import { YlmCheckbox } from '../../../components/forms/YlmCheckbox';
import { useDispatch } from 'react-redux';
import { openModalBottom } from '../../../actions/ui';

const timezone = process.env.REACT_APP_TIMEZONE;

export const FestivoCalendar = ({editMode, value, onChange}) => {

    const dispatch = useDispatch();

    const popover = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);
    
    const [hideHours, setHideHours] = useState( true );

    const changing = ( value ) => {
        console.log( value );
        onChange( value );
    }    

    const changeHoraInicio = ( value ) =>  {
        console.log( value )
    };

    const checkBoxChange = () => {
        if( editMode ){
            setHideHours( !hideHours );
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }

    const clickCalendar = () => {
        if( editMode ){
            toggle(true);
        } else {
            dispatch( openModalBottom({text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3}) );
        }
    }
  
    return (
        <>
        <div className={`calendar-row ${ hideHours && 'hide-hours'}`}>
            <div className='date-row del'>
                <div 
                    className='date-input'
                    onClick={ clickCalendar }
                >
                    { moment.tz(value, timezone).format('DD/MM/YYYY') }
                    {
                        isOpen && (
                            <div
                                className='vacacional-calendar' ref={popover}
                            >
                                <Calendar
                                    minDetail={ `month` }
                                    onChange={changing}
                                    value={ value }
                                />
                            </div>
                        )
                    }
                </div>
                <div className='time-input'>
                    <HoraInput
                        tipo={ 'horaInicio' }
                        hora={ moment.tz(value, timezone).format('HH:mm') }
                        changeHora={ changeHoraInicio }
                        intMinutos={ 1 }
                    />
                </div>
            </div>
        </div>
        <YlmCheckbox
            text={'Toda la jornada'}
            value={ hideHours }
            disabled={ !editMode }
            onChange={ checkBoxChange }
        />
        </>
    )
}
