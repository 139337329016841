import React from 'react';
import { Provider } from 'react-redux';
import { SocketProvider } from './context/SocketContext';

import { AppRouter } from './routers/AppRouter';
import { store } from './store/store';
/*deploy*/

export const BarberiaApp = () => {
    return (
        <Provider store={ store }>
            <SocketProvider>
                <AppRouter />
            </SocketProvider>
        </Provider>
    )
}
