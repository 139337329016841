import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from '../../../context/SocketContext';

import { getCalendarDays, getMonthName } from '../helpers/customCalendar';
import { AddIcon } from '../../../assets/svg/iconos';
import { closeModal, openModalBottom } from '../../../actions/ui';

import { citaLogout, startLoadingCitasMes } from '../../../actions/citas';
import { AgendaView } from './AgendaView';
import { ProductosView } from './ProductosView';

//const timezone = process.env.REACT_APP_TIMEZONE;
const usarSockets = process.env.REACT_SOCKETS;

export const CustomCalendar = ({ agendaActive, setAgendaActive, citas, arrSucursales, arrProfesionales, selectedDate, setSelectedDate, setActiveModalReserva, setActiveModalDeleteCita, editCita, setEditCita, isLoading, setIsLoading, isRecharging, setIsRecharging }) => {
    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { colores } = useSelector(state => state.ui);
    const { avisos } = useSelector(state => state.pedidos);

    const [currentMonth, setCurrentMonth] = useState(moment());
    const [monthName, setMonthName] = useState(getMonthName(moment()));
    const [calendarDays, setCalendarDays] = useState([]);
    const [viewType, setViewType] = useState('calendar');
    const [viewValue, setViewValue] = useState('month');
    const [dailyItem, setDailyItem] = useState(null);

    const [selectedDateCalendar, setSelectedDateCalendar] = useState(moment(selectedDate));
    const [activeDnd, setActiveDnd] = useState(false);
    const [visibleDnd, setVisibleDnd] = useState(false);

    const { socket } = useContext(SocketContext);

    const [myTimeOut, setMyTimeOut] = useState(null);

    useEffect(() => {
        if (!currentMonth) {
            setCurrentMonth(selectedDate);
            setMonthName(getMonthName(selectedDate));
            setCalendarDays(getCalendarDays(selectedDate, citas, arrSucursales, arrProfesionales));
        } else {
            setMonthName(getMonthName(currentMonth));
            setCalendarDays(getCalendarDays(currentMonth, citas, arrSucursales, arrProfesionales));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [citas, currentMonth, arrSucursales, arrProfesionales]);

    useEffect(() => {
        if (citas.length === 0) {
            if (!isRecharging) {
                setIsLoading(true);
                setIsRecharging(true);
            }

            setMyTimeOut(
                setTimeout(() => {
                    if (isLoading) {
                        dispatch(openModalBottom({ text: ['No se han encontrado eventos de calendario o ha expirado el tiempo de espera'], timeOut: 4 }));
                    }
                    setIsLoading(false);
                }, 3000)
            )
        } else {
            clearTimeout(myTimeOut);
            setTimeout(null);
        }

        return () => {
            clearTimeout(myTimeOut);
            setTimeout(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [citas]);

    // useEffect(() => {console.log('Cambia selectedDate');}, [selectedDate])
    // useEffect(() => {console.log('Cambia currentMonth');}, [currentMonth])
    // useEffect(() => {console.log('Cambia citas');}, [citas])
    // useEffect(() => {console.log('Cambia arrSucursales');}, [arrSucursales])
    // useEffect(() => {console.log('Cambia arrProfesionales');}, [arrProfesionales])
    // useEffect(() => {console.log('Cambia isLoading', isLoading );}, [isLoading])

    useEffect(() => {
        if (calendarDays.length > 0) {
            //console.log( calendarDays );
            updateDailyItem();
        }

        return () => {
            clearTimeout(myTimeOut);
            setTimeout(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarDays]);

    useEffect(() => {
        if (viewValue === 'day') {
            setVisibleDnd(true);
        } else {
            setVisibleDnd(false);
        }
    }, [viewValue])


    const updateDailyItem = () => {
        const theDate = (!dailyItem) ? moment() : selectedDate;
        calendarDays.forEach(day => {
            if (day.date.format('D-M-YYYY') === theDate.format('D-M-YYYY')) {
                //console.log('updateDailyItem');
                setDailyItem(day);
                dispatch(closeModal());
            }
        });
        if (citas.length > 0) {
            setIsLoading(false);
        }
    }

    const changeDay = (operation) => {
        let newDate;
        let newMonth;
        if (operation === 'add') {
            newDate = moment(selectedDate).add(1, 'days');
            newMonth = moment(currentMonth).add(1, 'days');
        } else {
            newDate = moment(selectedDate).subtract(1, 'days');
            newMonth = moment(currentMonth).subtract(1, 'days');
        }
        if (currentMonth.format('MM') !== newMonth.format('MM')) {
            dispatch(startLoadingCitasMes(empresa, newDate.toISOString()));
        }
        setSelectedDate(newDate);
        setSelectedDateCalendar(newDate);
        setCurrentMonth(newMonth);
    };

    const changeMonth = (operation) => {
        dispatch(citaLogout());
        let newDate;
        if (operation === 'add') {
            newDate = moment(currentMonth).add(1, 'months');
        } else {
            newDate = moment(currentMonth).subtract(1, 'months');
        }
        if (usarSockets) {
            socket?.emit('obtener-citas-mes', { empresa, fecha: newDate.toISOString() });
        } else {
            setIsRecharging(false);
            dispatch(startLoadingCitasMes(empresa, newDate.toISOString()));
        }
        setSelectedDate(newDate);
        setCurrentMonth(newDate);
    };

    const selectDate = (date) => {
        setSelectedDate(date.date);
        setSelectedDateCalendar(date.date);
        setCurrentMonth(date.date);
        changeView('day');
        setDailyItem(date);
    }

    const changeView = (selectedItem) => {
        setViewValue(selectedItem);
    };

    const changeType = (selectedItem) => {
        setViewType(selectedItem);
    };

    const clickAdd = () => {
        setEditCita(false);
        setActiveModalReserva(true);
    }

    const toggleDnd = () => {
        setActiveDnd(!activeDnd);
    }

    return (
        <div className={`ylm-calendar ${agendaActive ? '' : 'big'}`}>
            <div className="calendar-preheader">
                <div className="title">
                    <h1 style={{ color: colores.colorPrincipal }}>Reservas</h1>
                </div>
                <div className="selector">
                    <p
                        className={`${agendaActive ? 'active' : ''}`}
                        style={{
                            color: agendaActive ? colores.colorPrincipal : colores.colorSecundario,
                            backgroundColor: agendaActive ? colores.colorSecundario : 'transparent'
                        }}
                        onClick={() => setAgendaActive(true)}
                    >Agenda</p>
                    <p
                        className={`${!agendaActive ? 'active' : ''}`}
                        style={{
                            color: agendaActive ? colores.colorSecundario : colores.colorPrincipal,
                            backgroundColor: !agendaActive ? colores.colorSecundario : 'transparent'
                        }}
                        onClick={() => setAgendaActive(false)}
                    >
                        Productos
                        {
                            avisos > 0 &&
                            <span className="badge" style={{ backgroundColor: colores.colorPrincipal }}>{avisos}</span>
                        }

                    </p>
                </div>
                <div className="botonera">
                    <div
                        className="btn-add"
                        onClick={clickAdd}
                    >
                        <AddIcon
                            color={colores.colorPrincipal}
                        />
                    </div>
                </div>
            </div>
            {
                agendaActive ?
                    <AgendaView
                        viewType={viewType}
                        viewValue={viewValue}
                        changeMonth={changeMonth}
                        monthName={monthName}
                        currentMonth={currentMonth}
                        changeDay={changeDay}
                        selectedDateCalendar={selectedDateCalendar}
                        changeView={changeView}
                        changeType={changeType}
                        calendarDays={calendarDays}
                        selectedDate={selectedDate}
                        selectDate={selectDate}
                        arrSucursales={arrSucursales}
                        arrProfesionales={arrProfesionales}
                        dailyItem={dailyItem}
                        editCita={editCita}
                        setEditCita={setEditCita}
                        activeDnd={activeDnd}
                        setActiveDnd={setActiveDnd}
                        citas={citas}
                        setActiveModalDeleteCita={setActiveModalDeleteCita}
                        visibleDnd={visibleDnd}
                        toggleDnd={toggleDnd}
                    />
                    :
                    <ProductosView />
            }
        </div>
    )
}
